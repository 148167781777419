import React, { Component } from "react";
import FadeIn from "react-fade-in";
import { Row, Col } from "react-flexbox-grid";
import {
  Card,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  BarChart,
  Loader,
  Status,
} from "ui-kit-ck-consultant";

import axiosConfig from "../../../../utils/axiosConfig";

export default class CardCOVEA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      search: null,
      periods: null,
      isLoad: false
    };
  }

  componentDidMount() {
    this.getPerformance();
  }

  componentDidUpdate = prevProps => {
    if (
      JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data) &&
      parseInt(this.props.data.company) === 2
    ) {
      this.getPerformance();
    }
  };

  getPerformance = () => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("/company-performance/covea", {
        data: {
          dateStart: this.props.data.dateStart,
          dateEnd: this.props.data.dateEnd,
          network: this.props.data.network,
          group: this.props.data.group,
          region: this.props.data.region,
          agency: this.props.data.agency,
          user: this.props.data.user,
          company: this.props.data.company
        },
      }).then(res => {
        if (res.data.success) {
          const periods = [];
          let lastPeriodIndex = res.data.data.foldersNb.length - 1;
          while (!res.data.data.foldersNb[lastPeriodIndex]) lastPeriodIndex--;
          for (let i = lastPeriodIndex - 3; i <= lastPeriodIndex; i++) {
            if (i > 2) {
              periods.push({ ...res.data.search[i], index: i });
            }
          }
          this.setState({
            data: res.data.data,
            search: res.data.search,
            periods: periods,
            isLoad: false            
          });
        }
      });
    });
  };

  getActivityData = () => {
    const data = {
      activityAmount: {
        previousYear: this.state.data.activityAmount.slice(7, 11),
        currentYear: this.state.data.activityAmount.slice(11)
      },
      foldersNb: {
        previousYear: this.state.data.foldersNb.slice(7, 11),
        currentYear: this.state.data.foldersNb.slice(11)
      }
    }

    const labels = [
      {
        id: "activityAmount",
        title: "Cumul montant réparables et VRADE"
      },
      {
        id: "foldersNb",
        title: "Cumul nombre rapport expertise"
      }
    ]

    const activityData = labels.map(label => (
      {
        title: label.title,
        total: ` : ${Math.round(data[label.id].currentYear.reduce((acc, currValue) => acc + currValue, 0))} ${label.id === "activityAmount" ? " € TTC" : ""}`,
        data: [
          {
            label: 'N-1',
            type: 'line',
            color: 'red',
            data: data[label.id].previousYear
          },
          {
            label: 'N',
            type: 'bar',
            color: 'gray',
            data: data[label.id].currentYear
          }
        ]
      }
    ));

    return activityData;
  }

  getPerformanceData = () => {
    const headers = this.props.data.user ? (
      ['titre', 'Utilisateur', '% Evol N-1']
    ) : this.props.data.agency ? (
      ['titre', 'Agence', '% Evol N-1']
    ) : this.props.data.region ? (
      ['titre', 'Région', '% Evol N-1']
    ) : (
      ['titre', 'Compagnie', '% Evol N-1']
    );

    const tables = [
      {
        title: "Coût",
        rows: [{
          id: "globalCost",
          label: "CM observé",
          unit: "€"
        }]
      },
      {
        title: "Honoraire",
        rows: [{
          id: "honoraryAmount",
          label: "Montant",
          unit: "€"
        }]
      },
      {
        title: "Qualité",
        rows: [
          {
            id: "examDuration",
            label: "DELAI EXAMEN",
            unit: "j."
          }, 
          {
            id: "reparableDuration",
            label: "DELAI DEPOT RE REPARABLES",
            unit: "j."
          }, 
          {
            id: "sd29Duration",
            label: "DELAI SD29",
            unit: "j."
          }, 
          {
            id: "unreparableDuration",
            label: "DELAI DEPOT RE IRREPARABLES",
            unit: "j."
          }
        ]
      }
    ];

    const performanceData = tables.map(table => ({
      title: table.title,
      table: {
        headers: headers,
        data: table.rows.map(row => ([
          row.label,
          `${
            this.state.data[row.id][2] ? this.state.data[row.id][2] : "-"
          } ${
            this.state.data[row.id][2] && row.unit ? row.unit : ""
          }`,
          this.state.data[row.id][1] 
          ? <Status className={
            this.state.data[row.id][1] - this.state.data[row.id][2] < 0 
            ? "bg-green" 
            : this.state.data[row.id][1] - this.state.data[row.id][2] > 0 
            ? "bg-red" 
            : "bg-blue"
          } text={
            `${(((this.state.data[row.id][1] - this.state.data[row.id][2]) / this.state.data[row.id][2]) * 100).toFixed(2)} %`
          }/> 
          : "-",
        ]))
      }
    }));

    return performanceData;
  }

  getCostDataArray = () => {
    const costDataArr = {
      headers: ['Période ', 'Nb RE analysé ', '% Evol N-1', 'CM Global TTC (Rep+PT)', '% Evol N-1', 'CM Réparable HT', 'VRADE TTC', '% Irrep', 'Hono moy. TTC'],
      data: this.state.periods.map(line => ([
        `${line.year} T${line.trimester[0]}`,
        this.state.data.foldersNb[line.index],
        line.index - 4 > 2 && this.state.data.foldersNb[line.index - 4] 
          ? <Status className={
            this.state.data.foldersNb[line.index] - this.state.data.foldersNb[line.index - 4] > 0 
            ? "bg-green" 
            : this.state.data.foldersNb[line.index] - this.state.data.foldersNb[line.index - 4] < 0 
            ? "bg-red" 
            : "bg-blue"
          } text={
            `${(((this.state.data.foldersNb[line.index] - this.state.data.foldersNb[line.index - 4]) / this.state.data.foldersNb[line.index - 4]) * 100).toFixed(2)} %`
          }/> 
          : "-",
        `${this.state.data.globalCost[line.index]} €`,
        line.index - 4 > 2 && this.state.data.globalCost[line.index - 4] 
          ? <Status className={
              this.state.data.globalCost[line.index] - this.state.data.globalCost[line.index - 4] < 0 
              ? "bg-green" 
              : this.state.data.globalCost[line.index] - this.state.data.globalCost[line.index - 4] > 0 
              ? "bg-red" 
              : "bg-blue"
            } text={
              `${(((this.state.data.globalCost[line.index] - this.state.data.globalCost[line.index - 4]) / this.state.data.globalCost[line.index - 4]) * 100).toFixed(2)} %`
            }/> 
          : "-",
        `${this.state.data.reparableCost[line.index]} €`,
        `${this.state.data.unreparableCost[line.index]} €`,
        `${this.state.data.rateUnreparable[line.index]} %`,
        `${this.state.data.honoraryAmount[line.index]} €`
      ]))
    };

    return costDataArr;
  }

  getCostDataCharts = () => {
    const chartsLabels = [
      {
        title: "Évolution Coût Moyen Global (Trimestre)",
        id: "globalCost"
      },
      {
        title: "Évolution Coût Moyen Réparables (Trimestre)",
        id: "reparableCost"
      },
      {
        title: "Évolution Coût Moyen VRADE (Trimestre)",
        id: "unreparableCost"
      },
      {
        title: "Évolution Coût Moyen Global (Année Glissante)",
        id: "globalCost"
      },
      {
        title: "Évolution Coût Moyen Réparables (Année Glissante)",
        id: "reparableCost"
      },
      {
        title: "Évolution Coût Moyen VRADE (Année Glissante)",
        id: "unreparableCost"
      }
    ]

    const costDataCharts = chartsLabels.map((chart, idx) => ({
      title: chart.title,
      labels: idx < 3 
        ? this.state.periods.map(period => `${period.year} T${period.trimester[0]}`) 
        : this.state.search.slice(0,3).map(period => period.year),
      dataSet: [
        {
          type: 'line',
          color: 'blue',
          data: idx < 3
            ? this.state.periods.map(period => this.state.data[chart.id][period.index])
            : this.state.search.slice(0,3).map((period, index) => this.state.data[chart.id][index])
        }
      ]
    }));

    return costDataCharts;
  }

  getDurationDataArray = () => {
    const durationDataArr = {
      headers: ['Période ', 'Délai Global ', 'Ecart N-1', 'Délai SD29', 'Ecart N-1', 'Délai Irrép', 'Ecart N-1'],
      data: this.state.periods.map(line => ([
        `${line.year} T${line.trimester[0]}`,
        this.state.data.globalDuration[line.index],
        line.index - 4 > 2 && this.state.data.globalDuration[line.index - 4] 
          ? <Status className={
              this.state.data.globalDuration[line.index] - this.state.data.globalDuration[line.index - 4] < 0 
              ? "bg-green" 
              : this.state.data.globalDuration[line.index] - this.state.data.globalDuration[line.index - 4] > 0 
              ? "bg-red" 
              : "bg-blue"
            } text={
              (this.state.data.globalDuration[line.index] - this.state.data.globalDuration[line.index - 4]).toFixed(2)
            }/>
          : "-",
        this.state.data.sd29Duration[line.index],
          line.index - 4 > 2 && this.state.data.sd29Duration[line.index - 4] 
            ? <Status className={
                this.state.data.sd29Duration[line.index] - this.state.data.sd29Duration[line.index - 4] < 0 
                ? "bg-green" 
                : this.state.data.sd29Duration[line.index] - this.state.data.sd29Duration[line.index - 4] > 0 
                ? "bg-red" 
                : "bg-blue"
              } text={
                (this.state.data.sd29Duration[line.index] - this.state.data.sd29Duration[line.index - 4]).toFixed(2)
              }/>
            : "-",
        this.state.data.unreparableDuration[line.index],
          line.index - 4 > 2 && this.state.data.unreparableDuration[line.index - 4] 
            ? <Status className={
                this.state.data.unreparableDuration[line.index] - this.state.data.unreparableDuration[line.index - 4] < 0 
                ? "bg-green" 
                : this.state.data.unreparableDuration[line.index] - this.state.data.unreparableDuration[line.index - 4] > 0 
                ? "bg-red" 
                : "bg-blue"
              } text={
                (this.state.data.unreparableDuration[line.index] - this.state.data.unreparableDuration[line.index - 4]).toFixed(2)
              }/>
            : "-"
      ]))
    };

    return durationDataArr;
  }

  getDurationDataCharts = () => {
    const chartsLabels = [
      {
        title: "Évolution Délai Global (Trimestre)",
        id: "globalDuration"
      },
      {
        title: "Évolution Délai SD29 (Trimestre)",
        id: "sd29Duration"
      },
      {
        title: "Évolution Délai Irréparables (Trimestre)",
        id: "unreparableDuration"
      },
      {
        title: "Évolution Délai Global (Année Glissante)",
        id: "globalDuration"
      },
      {
        title: "Évolution Délai SD29 (Année Glissante)",
        id: "sd29Duration"
      },
      {
        title: "Évolution Délai Irréparables (Année Glissante)",
        id: "unreparableDuration"
      }
    ]

    const durationDataCharts = chartsLabels.map((chart, idx) => ({
      title: chart.title,
      labels: idx < 3 
        ? this.state.periods.map(period => `${period.year} T${period.trimester[0]}`) 
        : this.state.search.slice(0,3).map(period => period.year),
      dataSet: [
        {
          type: 'line',
          color: 'blue',
          data: idx < 3
            ? this.state.periods.map(period => this.state.data[chart.id][period.index])
            : this.state.search.slice(0,3).map((period, index) => this.state.data[chart.id][index])
        }
      ]
    }));

    return durationDataCharts;
  }

  getDetailsData = () => {
    const detailsDataArr1 = {
      headers: [
        ['Période ', 'Véhicule Réparable', "E.A.D."],
        ['Période ', 'Nb RE', 'Coût Moyen', '% Evol N-1', '% dos 0 à 1000 €', '% > à 2500 €', '% Ev. Natur', '% Agréé', '% dossiers', 'CM H.T.', '% Evol']
      ],
      data: this.state.periods.map(line => ([
        `${line.year} T${line.trimester[0]}`,
        this.state.data.reparableFoldersNb[line.index],
        `${this.state.data.reparableCost[line.index]} €`,
        line.index - 4 > 2 && this.state.data.reparableCost[line.index - 4] 
          ? <Status className={
              this.state.data.reparableCost[line.index] - this.state.data.reparableCost[line.index - 4] < 0 
              ? "bg-green" 
              : this.state.data.reparableCost[line.index] - this.state.data.reparableCost[line.index - 4] > 0 
              ? "bg-red" 
              : "bg-blue"
            } text={
              `${(((this.state.data.reparableCost[line.index] - this.state.data.reparableCost[line.index - 4]) / this.state.data.reparableCost[line.index - 4]) * 100).toFixed(2)} %`
            }/> 
          : "-",
        `${this.state.data.rateReparable1000[line.index]} %`,
        `${this.state.data.rateReparable2500[line.index]} %`,
        "",
        `${this.state.data.rateApprovedReparable[line.index]} %`,
        `${this.state.data.rateEad[line.index]} %`,
        `${this.state.data.eadCost[line.index]} €`,
        line.index - 4 > 2 && this.state.data.eadCost[line.index - 4] 
          ? <Status className={
              this.state.data.eadCost[line.index] - this.state.data.eadCost[line.index - 4] < 0 
              ? "bg-green" 
              : this.state.data.eadCost[line.index] - this.state.data.eadCost[line.index - 4] > 0 
              ? "bg-red" 
              : "bg-blue"
            } text={
              `${(((this.state.data.eadCost[line.index] - this.state.data.eadCost[line.index - 4]) / this.state.data.eadCost[line.index - 4]) * 100).toFixed(2)} %`
            }/> 
          : "-",
      ]))
    };

    const detailsDataArr2 = {
      headers: [
        ['Période ', 'Véhicule Réparable', "Véhicule Non Réparable"],
        ['Période ', 'Nb Heure MO', 'Nb Heures Peint.', 'CM MO', 'Poids MO', 'CM Pièces', '% Evol N-1', 'Poids Pièces', 'CM IP', 'Nb RE', 'VRADE N', '% Evol N-1']
      ],
      data: this.state.periods.map(line => ([
        `${line.year} T${line.trimester[0]}`,
        this.state.data.reparableHourM[line.index],
        this.state.data.reparableHourP[line.index],
        `${this.state.data.reparableCostM[line.index]} €`,
        `${this.state.data.reparableCostMWeight[line.index]} %`,
        `${this.state.data.reparablePiecesCost[line.index]} €`,
        line.index - 4 > 2 && this.state.data.reparablePiecesCost[line.index - 4] 
          ? <Status className={
              this.state.data.reparablePiecesCost[line.index] - this.state.data.reparablePiecesCost[line.index - 4] < 0 
              ? "bg-green" 
              : this.state.data.reparablePiecesCost[line.index] - this.state.data.reparablePiecesCost[line.index - 4] > 0 
              ? "bg-red" 
              : "bg-blue"
            } text={
              `${(((this.state.data.reparablePiecesCost[line.index] - this.state.data.reparablePiecesCost[line.index - 4]) / this.state.data.reparablePiecesCost[line.index - 4]) * 100).toFixed(2)} %`
            }/>  
          : "-",
        `${this.state.data.reparablePiecesCostWeight[line.index]} %`,
        `${this.state.data.reparablePeintCost[line.index]} €`,
        this.state.data.unreparableFoldersNb[line.index],
        `${this.state.data.unreparableCostDutyFree[line.index]} €`,
        line.index - 4 > 2 && this.state.data.unreparableCostDutyFree[line.index - 4] 
          ? <Status className={
              this.state.data.unreparableCostDutyFree[line.index] - this.state.data.unreparableCostDutyFree[line.index - 4] < 0 
              ? "bg-green" 
              : this.state.data.unreparableCostDutyFree[line.index] - this.state.data.unreparableCostDutyFree[line.index - 4] > 0 
              ? "bg-red" 
              : "bg-blue"
            } text={
              `${(((this.state.data.unreparableCostDutyFree[line.index] - this.state.data.unreparableCostDutyFree[line.index - 4]) / this.state.data.unreparableCostDutyFree[line.index - 4]) * 100).toFixed(2)} %` 
            }/> 
          : "-"
      ]))
    };

    return [detailsDataArr1, detailsDataArr2];
  }

  render() {
    return (
      <FadeIn>
        {this.state.data && this.state.search && this.state.periods ? (
          <>
            <Card title={"Critères"} className={"mt-10 mt-30-xl "}>
              <div style={{ fontSize: "12px" }}>
                <p>Genre : VP / CTTE</p>
              </div>
            </Card>

            <Card title={"Activité"} className={"mt-10 mt-30-xl "} classNameChildren={"overflow-x-auto"}>
              <Row>
                {this.getActivityData().map((chart, idx) => {
                  return (
                    <Col key={idx} xs={12} md={6}>
                          <BarChart
                            title={chart.title + chart.total}
                            labels={["T1", "T2", "T3", "T4"]}
                            legendDisplay
                            dataSet={chart.data}
                            yAxes={[
                              {
                                stacked: true,
                                display: false
                              }
                            ]}
                            xAxes={[
                              {
                                stacked: true
                              }
                            ]}
                          />
                    </Col>
                  )
                })}
              </Row>
            </Card>

            <Card title={"Performance"} className={"mt-10 mt-30-xl "}  classNameChildren={"overflow-x-auto"}>
              <Row>
                <Col xs={12} md={6} lg={6} className={'p-10 pr-30'}>
                  {this.getPerformanceData().slice(0, 2).map((item, idx) => (
                    <div key={idx}>
                      <h4>{item.title}</h4>
                      <Table>
                        <Thead>
                          <Tr>
                            {item.table.headers.map((element, id) => {
                              if (id === 0) return <Th hide></Th>
                              if (id === 1) return <Th radiusTopLeft >{element}</Th>  
                              return <Th key={id}>{element}</Th> 
                            })}
                          </Tr>
                        </Thead>
                        <Tbody>
                          {item.table.data.map((row, index) => {
                            return <Tr key={index}>
                              {row.map((element, id) => {
                                if (index === 0 && id === 0) return <Th key={id} radiusTopLeft>{element}</Th>
                                else if (id === 0) return <Th key={id}>{element}</Th>
                                else return <Td key={id} className="nowrap">{element}</Td>
                              })}
                            </Tr>
                          })}
                        </Tbody>
                      </Table>
                    </div>
                  ))}
                </Col>
                <Col xs={12} md={6} lg={6} className={'p-10 pl-30'}>
                  {this.getPerformanceData().slice(2).map((item, idx) => (
                    <div key={idx}>
                      <h4>{item.title}</h4>
                      <Table>
                        <Thead>
                          <Tr>
                            {item.table.headers.map((element, id) => {
                              if (id === 0) return <Th hide></Th>
                              if (id === 1) return <Th radiusTopLeft >{element}</Th>  
                              return <Th key={id}>{element}</Th> 
                            })}
                          </Tr>
                        </Thead>
                        <Tbody>
                          {item.table.data.map((row, index) => {
                            return <Tr key={index}>
                              {row.map((element, id) => {
                                if (index === 0 && id === 0) return <Th key={id} radiusTopLeft>{element}</Th>
                                else if (id === 0) return <Th key={id}>{element}</Th>
                                else return <Td key={id} className="nowrap">{element}</Td>
                              })}
                            </Tr>
                          })}
                        </Tbody>
                      </Table>
                    </div>
                  ))}
                </Col>
              </Row>
            </Card>

            <Card title={"Synthèse des Coûts"} className={"mt-10 mt-30-xl"} classNameChildren="overflow-x-auto">
              <Row className="p-10">
                <Table>
                  <Thead>
                    <Tr>
                      {this.getCostDataArray().headers.map((header, idx) => {
                        if (idx === 0) return <Th key={idx} hide></Th>
                        else if (idx === 1) return <Th key={idx} radiusTopLeft>{header}</Th>
                        else return <Th key={idx} >{header}</Th>
                      })}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {this.getCostDataArray().data.map((row, idx) => {
                      return <Tr key={idx}>
                      {row.map((element, id) => {
                        if (idx === 0 && id === 0) return <Th key={id} radiusTopLeft className="nowrap">{element}</Th>
                        else if (id === 0) return <Th key={id} className="nowrap">{element}</Th>
                        else return <Td key={id} className="nowrap">{element}</Td>
                      })}
                    </Tr>
                    })}
                  </Tbody>
                </Table>
              </Row>
              <Row className={'mt-30'}>
                {this.getCostDataCharts().map((chart, idx) => 
                  <Col key={idx} xs={12} md={4} lg={4} className={'mt-30'}>
                    <BarChart 
                      title={chart.title}
                      labels={chart.labels}
                      dataSet={chart.dataSet}
                      yAxes={[
                        {
                          ticks: {
                            display: false,
                            beginAtZero: true
                          }
                        }
                      ]}
                      dataLabels2={[
                        {
                          display: true,
                          align: 'top',
                          anchor: 'end',
                          clamp: true,
                          color: "#db2828",
                          backgroundColor: 'white',
                          borderColor:"#db2828",
                          borderWidth: 1,
                          borderRadius: 50,
                          font: {
                            size: '10'
                          },
                          formatter: (value) => Math.round(value)
                        }
                      ]}
                    />
                  </Col>
                )}
              </Row>
            </Card>

            <Card title={"Analyse des Délais"} className={"mt-10 mt-30-xl "} classNameChildren={"overflow-x-auto"}>
              <Row className="p-10">
                <Table>
                  <Thead>
                    <Tr>
                      {this.getDurationDataArray().headers.map((header, idx) => {
                        if (idx === 0) return <Th key={idx} hide></Th>
                        else if (idx === 1) return <Th key={idx} radiusTopLeft>{header}</Th>
                        else return <Th key={idx} >{header}</Th>
                      })}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {this.getDurationDataArray().data.map((row, idx) => {
                      return <Tr key={idx}>
                      {row.map((element, id) => {
                        if (idx === 0 && id === 0) return <Th key={id} radiusTopLeft className="nowrap">{element}</Th>
                        else if (id === 0) return <Th key={id} className="nowrap">{element}</Th>
                        else return <Td key={id} className="nowrap">{element}</Td>
                      })}
                    </Tr>
                    })}
                  </Tbody>
                </Table>
              </Row>
              <Row className={'mt-30'}>
                {this.getDurationDataCharts().map((chart, idx) => 
                  <Col key={idx} xs={12} md={4} lg={4} className={'mt-30'}>
                    <BarChart 
                      title={chart.title}
                      labels={chart.labels}
                      dataSet={chart.dataSet}
                      yAxes={[
                        {
                          ticks: {
                            display: false,
                            // suggestedMax: 10000,
                            beginAtZero: true
                          }
                        }
                      ]}
                      dataLabels2={[
                        {
                          display: true,
                          align: 'top',
                          anchor: 'end',
                          clamp: true,
                          color: "#db2828",
                          backgroundColor: 'white',
                          borderColor:"#db2828",
                          borderWidth: 1,
                          borderRadius: 50,
                          font: {
                            size: '10'
                          },
                          formatter: (value) => Math.round(value)
                        }
                      ]}
                    />
                  </Col>
                )}
              </Row>
            </Card>

            <Card title={"Détails"} className={"mt-10 mt-30-xl mb-30"} classNameChildren={"overflow-x-auto"}>
              <Row className="p-10">
                {this.getDetailsData().map((table, tableIndex) => {
                  return (
                        <Table key={tableIndex} className={'mt-30'}>
                          <Thead>
                            {table.headers.map((row, rowIndex) => (
                              <Tr key={rowIndex}>
                                {row.map((cell, cellIndex) => {
                                  if (cellIndex === 0) return <Th key={cellIndex} hide></Th>
                                  else if (tableIndex === 0 && rowIndex === 0 && cellIndex === 1) return <Th key={cellIndex} colSpan={7} spaceRight radiusTopLeft>{cell}</Th>
                                  else if (tableIndex === 0 && rowIndex === 0 && cellIndex === 2) return <Th key={cellIndex} colSpan={3} spaceLeft>{cell}</Th>
                                  else if (tableIndex === 0 && cellIndex === 7) return <Th key={cellIndex} spaceRight>{cell}</Th>
                                  else if (tableIndex === 0 && cellIndex === 8) return <Th key={cellIndex} spaceLeft>{cell}</Th>
                                  else if (tableIndex === 1 && rowIndex === 0 && cellIndex === 1) return <Th key={cellIndex} colSpan={8} spaceRight radiusTopLeft>{cell}</Th>
                                  else if (tableIndex === 1 && rowIndex === 0 && cellIndex === 2) return <Th key={cellIndex} colSpan={3} spaceLeft>{cell}</Th>
                                  else if (tableIndex === 1 && cellIndex === 8) return <Th key={cellIndex} spaceRight>{cell}</Th>
                                  else if (tableIndex === 1 && cellIndex === 9) return <Th key={cellIndex} spaceLeft>{cell}</Th>
                                  else return <Th key={cellIndex} >{cell}</Th>
                                })}
                              </Tr>
                            ))}
                          </Thead>
                          <Tbody>
                            {table.data.map((row, idx) => {
                              return <Tr key={idx}>
                              {row.map((element, id) => {
                                if (idx === 0 && id === 0) return <Th key={id} radiusTopLeft className="nowrap">{element}</Th>
                                else if (id === 0) return <Th key={id} className="nowrap">{element}</Th>
                                else if (id === 7) return <Td key={id} spaceRight className="nowrap">{element}</Td>
                                else if (id === 8) return <Td key={id} spaceLeft className="nowrap">{element}</Td>
                                else return <Td key={id} className="nowrap">{element}</Td>
                              })}
                            </Tr>
                            })}
                          </Tbody>
                        </Table>
                      ) 
                })}
              </Row>
            </Card>
          </>
        ) : null}

        <Loader show={this.state.isLoad} />
      </FadeIn>
    );
  }
}
