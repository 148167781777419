import React from "react";
import { Col, Row } from "react-flexbox-grid";
import { Card } from "ui-kit-ck-consultant";
import natureExpertise from "../../utils/natureExpertise";

export default class Folder extends React.Component {
  render() {
    return (
      <Row>
        <Col xs={12} md={8} className="col_full_height">
          <Card title={"État du dossier"} className={"mb-10 mb-30-xl nowrap"}>
            <p>
              N° de dossier : <b>{this.props.folder.reference}</b>
            </p>
            <p>
              Code état du dossier : <b>{this.props.folder.folderState.code}</b>
            </p>
            <p>
              Nature de l'expertise :{" "}
              <b>
                {natureExpertise(this.props.folder.folderState.natureExpertise)}
              </b>
            </p>
            <p>
              Date expertise :{" "}
              <b>{this.props.folder.folderState.dateExpertise}</b>
            </p>
            <p>
              Procédure VGE : <b>{this.props.folder.folderState.process}</b>
            </p>
            <p>
              Montant des Réparations :{" "}
              <b>{this.props.folder.folderState.cost}</b>
            </p>
            <p>
              VRADE : <b>{this.props.folder.folderState.VRADE}</b>
            </p>
            <p>
              Date de dépôt : <b>{this.props.folder.folderState.dateDepot}</b>
            </p>
            <p>
              Techniquement réparable :{" "}
              <b>{this.props.folder.folderState.technicalyRepairable}</b>
            </p>
            <p>
              Économiquement réparable :{" "}
              <b>{this.props.folder.folderState.economicalyRepairable}</b>
            </p>
          </Card>
        </Col>
        <Col xs={12} md={4} className="col_full_height">
          <Card title={`Mission`} className="mb-10 mb-30-xl">
            <Row middle="xs">
              <Col xs={12} md={12}>
                <p>
                  Date de mission :{" "}
                  <b>{this.props.folder.informations.dateMission}</b>
                </p>
                <p>
                  Date de sinistre :{" "}
                  <b>{this.props.folder.informations.dateAccident}</b>
                </p>
                <p>
                  Numéro de sinistre :{" "}
                  <b>{this.props.folder.informations.sinistre}</b>
                </p>
                <p>
                  Nature : <b>{this.props.folder.informations.nature}</b>
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={12} md={4} className="col_full_height">
          <Card title={`Expert`} className="mb-10 mb-30-xl nowrap">
            <Row middle="xs">
              <Col xs={12} md={12}>
                <p>
                  Nom : <b>{this.props.folder.expert.name}</b>
                </p>
                <p>
                  Téléphone : <b>{this.props.folder.expert.phone}</b>
                </p>
                <p>
                  Email : <b>{this.props.folder.expert.email}</b>
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={12} md={8} className="col_full_height">
          <Card title={`Agence`} className="mb-10 mb-30-xl">
            <Row middle="xs">
              <Col xs={12} md={6}>
                <p>
                  Nom : <b>{this.props.folder.agency.name}</b>
                </p>
                <p>
                  Téléphone : <b>{this.props.folder.agency.phone}</b>
                </p>
                <p>
                  Email : <b>{this.props.folder.agency.email}</b>
                </p>
              </Col>
              <Col xs={12} md={6}>
                <p>
                  Adresse : <b>{this.props.folder.agency.address}</b>
                </p>
                <p>
                  Code Postal : <b>{this.props.folder.agency.zipCode}</b>
                </p>
                <p>
                  Ville : <b>{this.props.folder.agency.city}</b>
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={12} md={6} className="col_full_height">
          <Card title={`Réparateur`} className="mb-10 mb-30-xl">
            <Row middle="xs">
              <Col xs={12} md={6}>
                <p>
                  Nom : <b>{this.props.folder.garage.name}</b>
                </p>
                <p>
                  Téléphone : <b>{this.props.folder.garage.phone}</b>
                </p>
                <p>
                  Email : <b>{this.props.folder.garage.email}</b>
                </p>
              </Col>
              <Col xs={12} md={6}>
                <p>
                  Adresse : <b>{this.props.folder.garage.address}</b>
                </p>
                <p>
                  Code Postal : <b>{this.props.folder.garage.zipCode}</b>
                </p>
                <p>
                  Ville : <b>{this.props.folder.garage.city}</b>
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={12} md={6} className="col_full_height">
          <Card title={`Compagnie`} className="mb-10 mb-30-xl">
            <Row middle="xs">
              <Col xs={12} md={6}>
                <p>
                  Nom : <b>{this.props.folder.company.name}</b>
                </p>
                <p>
                  Téléphone : <b>{this.props.folder.company.phone}</b>
                </p>
                <p>
                  Email : <b>{this.props.folder.company.email}</b>
                </p>
              </Col>
              <Col xs={12} md={6}>
                <p>
                  Adresse : <b>{this.props.folder.company.address}</b>
                </p>
                <p>
                  Code Postal : <b>{this.props.folder.company.zipCode}</b>
                </p>
                <p>
                  Ville : <b>{this.props.folder.company.city}</b>
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={12} md={6} className="col_full_height">
          <Card title={`Mandant`} className="mb-10 mb-30-xl">
            <Row middle="xs">
              <Col xs={12} md={6}>
                <p>
                  Nom : <b>{this.props.folder.principal.name} / {this.props.folder.principal.code}</b>
                </p>
                <p>
                  Téléphone : <b>{this.props.folder.principal.phone}</b>
                </p>
                <p>
                  Email : <b>{this.props.folder.principal.email}</b>
                </p>
              </Col>
              <Col xs={12} md={6}>
                <p>
                  Adresse : <b>{this.props.folder.principal.address}</b>
                </p>
                <p>
                  Code Postal : <b>{this.props.folder.principal.zipCode}</b>
                </p>
                <p>
                  Ville : <b>{this.props.folder.principal.city}</b>
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={12} md={6} className="col_full_height">
          <Card title={`Véhicule`} className="mb-10 mb-30-xl">
            <Row middle="xs">
              <Col xs={12} md={12}>
                <p>
                  Marque : <b>{this.props.folder.vehicule.marque}</b>
                </p>
                <p>
                  Modèle : <b>{this.props.folder.vehicule.model}</b>
                </p>
                <p>
                  Finition : <b>{this.props.folder.vehicule.finition}</b>
                </p>
                <p>
                  Immatriculation : <b>{this.props.folder.vehicule.immat}</b>
                </p>
                <p>
                  1 ère mise en circulation :{" "}
                  <b>{this.props.folder.vehicule.firstDate}</b>
                </p>
                <p>
                  Certificat d'immatriculation :{" "}
                  <b>{this.props.folder.vehicule.immatDate}</b>
                </p>
                <p>
                  Dernier contrôle technique :{" "}
                  <b>{this.props.folder.vehicule.CTDate}</b>
                </p>
                <p>
                  VIN : <b>{this.props.folder.vehicule.VIN}</b>
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={12} md={6} className="col_full_height">
          <Card title={`Client`} className="mb-10 mb-30-xl">
            <Row middle="xs">
              <Col xs={12} md={12}>
                <p>
                  Nom : <b>{this.props.folder.client.name}</b>
                </p>
                <p>
                  Adresse : <b>{this.props.folder.client.address1}</b>
                </p>
                <p>
                  Complément d'adresse :{" "}
                  <b>{this.props.folder.client.address2}</b>
                </p>
                <p>
                  Code postal : <b>{this.props.folder.client.zipCode}</b>
                </p>
                <p>
                  Ville : <b>{this.props.folder.client.city}</b>
                </p>
                <p>
                  Email : <b>{this.props.folder.client.email}</b>
                </p>
                <p>
                  Téléphone : <b>{this.props.folder.client.phone}</b>
                </p>
                <p>
                  Portable : <b>{this.props.folder.client.mobile}</b>
                </p>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    );
  }
}
