import React, { Component } from "react";
import { Card, BarChart, PieChart } from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";

export default class Statistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countAnomalyTypeExpertise: null,
      countAnomalyType: null,
      countAnomalyOrigin: null,
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.isPrint !== this.props.isPrint) {
      setTimeout(
        () =>
          this.setState({ chartLabelsLoaded: this.props.isPrint }, () =>
            this.props.onLabelsLoaded(this.state.chartLabelsLoaded)
          ),
        50
      );
    }
  };

  render() {
    let AnomalyExpert = 0;
    this.props.data.countAnomalyExpert.data.map((expert) => {
      if (expert > AnomalyExpert) {
        return (AnomalyExpert = expert);
      }
      return true;
    });

    let AnomalyCompany = 0;
    this.props.data.countAnomalyCompany.data.map((company) => {
      if (company > AnomalyCompany) {
        return (AnomalyCompany = company);
      }
      return true;
    });

    let AnomalyMonth = 0;
    this.props.data.countAnomalyMonth.data.map((Month) => {
      if (Month > AnomalyMonth) {
        return (AnomalyMonth = Month);
      }
      return true;
    });

    let AnomalyAgency = 0;
    this.props.data.countAnomalyAgency.data.map((agency) => {
      if (agency > AnomalyAgency) {
        return (AnomalyAgency = agency);
      }
      return true;
    });

    return (
      <Card className={this.props.className} title={"Statistiques"}>
        <Row>
          {this.props.data.countAnomalyExpert ? (
            <Col xs={12} md={12} className={"mt-10"}>
              <BarChart
                digits={0}
                title={"Nombre d'anomalies par expert"}
                labels={this.props.data.countAnomalyExpert.label}
                dataSet={[
                  {
                    label: "Nombre d'anomalies par expert",
                    data: this.props.data.countAnomalyExpert.data,
                    color: this.props.data.countAnomalyExpert.color,
                  },
                ]}
                widthBar={40}
                yAxes={[
                  {
                    ticks: {
                      display: false,
                      suggestedMax: AnomalyExpert + (AnomalyExpert / 100) * 30,
                      min: 0,
                      beginAtZero: true,
                    },
                    gridLines: {
                      display: true,
                    },
                  },
                ]}
                datalabels2={
                  this.props.isPrint
                    ? [
                        {
                          display: true,
                          align: "top",
                          anchor: "end",
                          clamp: true,
                          color: "#0162AD",

                          formatter: (value) => Math.round(value),
                        },
                      ]
                    : null
                }
                height={200}
              />
            </Col>
          ) : null}
          {this.props.data.countAnomalyMonth ? (
            <Col xs={12} md={6} className={"mt-10"}>
              <BarChart
                digits={0}
                title={"Nombre d'anomalies par mois"}
                labels={this.props.data.countAnomalyMonth.label}
                dataSet={[
                  {
                    label: "Nombre d'anomalies par mois",
                    data: this.props.data.countAnomalyMonth.data,
                    color: this.props.data.countAnomalyMonth.color,
                  },
                ]}
                yAxes={[
                  {
                    ticks: {
                      display: false,
                      suggestedMax: AnomalyMonth + (AnomalyMonth / 100) * 30,
                      min: 0,
                      beginAtZero: true,
                    },
                    gridLines: {
                      display: true,
                    },
                  },
                ]}
                datalabels2={
                  this.props.isPrint
                    ? [
                        {
                          display: true,
                          align: "top",
                          anchor: "end",
                          clamp: true,
                          color: "#0162AD",

                          formatter: (value) => Math.round(value),
                        },
                      ]
                    : null
                }
                widthBar={30}
              />
            </Col>
          ) : null}
          {this.props.data.countAnomalyCompany ? (
            <Col xs={12} md={6} className={"mt-10"}>
              <BarChart
                digits={0}
                title={"Nombre d'anomalies par compagnie"}
                labels={this.props.data.countAnomalyCompany.label}
                dataSet={[
                  {
                    label: "Nombre d'anomalies par compagnie",
                    data: this.props.data.countAnomalyCompany.data,
                    color: this.props.data.countAnomalyCompany.color,
                  },
                ]}
                widthBar={30}
                yAxes={[
                  {
                    ticks: {
                      display: false,
                      suggestedMax:
                        AnomalyCompany + (AnomalyCompany / 100) * 30,
                      min: 0,
                      beginAtZero: true,
                    },
                    gridLines: {
                      display: true,
                    },
                  },
                ]}
                datalabels2={
                  this.props.isPrint
                    ? [
                        {
                          display: true,
                          align: "top",
                          anchor: "end",
                          clamp: true,
                          color: "#0162AD",

                          formatter: (value) => Math.round(value),
                        },
                      ]
                    : null
                }
              />
            </Col>
          ) : null}
          {this.props.data.countAnomalyAgency ? (
            <Col xs={12} md={6} className={"mt-10"}>
              <BarChart
                digits={0}
                title={"Nombre d'anomalies par agence"}
                labels={this.props.data.countAnomalyAgency.label}
                dataSet={[
                  {
                    label: "Nombre d'anomalies par agence",
                    data: this.props.data.countAnomalyAgency.data,
                    color: this.props.data.countAnomalyAgency.color,
                  },
                ]}
                widthBar={30}
                yAxes={[
                  {
                    ticks: {
                      display: false,
                      suggestedMax: AnomalyAgency + (AnomalyAgency / 100) * 30,
                      min: 0,
                      beginAtZero: true,
                    },
                    gridLines: {
                      display: true,
                    },
                  },
                ]}
                datalabels2={
                  this.props.isPrint
                    ? [
                        {
                          display: true,
                          align: "top",
                          anchor: "end",
                          clamp: true,
                          color: "#0162AD",

                          formatter: (value) => Math.round(value),
                        },
                      ]
                    : null
                }
              />
            </Col>
          ) : null}

          {this.props.data.countAnomalyTypeExpertise ? (
            <Col xs={12} md={6}>
              <PieChart
                digits={0}
                title={"Type d'expertise"}
                legendDisplay={true}
                data={this.props.data.countAnomalyTypeExpertise}
              />
            </Col>
          ) : null}
          {this.props.data.countAnomalyType ? (
            <Col xs={12} md={6}>
              <PieChart
                digits={0}
                title={"Type d'anomalie"}
                legendDisplay={true}
                data={this.props.data.countAnomalyType}
              />
            </Col>
          ) : null}
          {this.props.data.countAnomalyOrigin ? (
            <Col xs={12} md={6}>
              <PieChart
                digits={0}
                title={"Origine des anomalies"}
                legendDisplay={true}
                data={this.props.data.countAnomalyOrigin}
              />
            </Col>
          ) : null}
        </Row>
      </Card>
    );
  }
}
