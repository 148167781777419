import React from "react";
import {
  Card,
  TabsCustom,
  TabsCustomHeader,
  TabsCustomMenu,
  TabsCustomBody,
  TabsCustomItem,
} from "ui-kit-ck-consultant";

export default class Parts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabsIndex: 0,
      countClick: 0,
    };
    this.iframeRef = React.createRef();
  }

  render() {
    return (
      <TabsCustom noCard>
        <TabsCustomHeader>
          {this.props.parts.map((element, idx) => {
            if (
              element["Ref_Constructeur"] &&
              parseInt(element["Prix_U"].replace(",", ".")) > 50
            ) {
              return (
                <TabsCustomMenu
                  key={idx}
                  title={element["Libelle"]}
                  active={this.state.tabsIndex === idx}
                  onClick={(e) => {
                    this.setState({
                      tabsIndex: idx,
                      countClick: this.state.countClick + 1,
                    });
                  }}
                />
              );
            } else {
              return null;
            }
          })}
        </TabsCustomHeader>
        <TabsCustomBody noCard>
          {this.props.parts.map((element, idx) => {
            if (
              element["Ref_Constructeur"] &&
              parseInt(element["Prix_U"].replace(",", ".")) > 50 &&
              this.state.tabsIndex === idx
            ) {
              return (
                <TabsCustomItem active={true} key={idx}>
                  <Card>
                    <iframe
                      key={this.state.countClick}
                      ref={this.iframeRef}
                      title={element["Ref_Constructeur"]}
                      style={{
                        width: "100%",
                        height: "100%",
                        minHeight: "700px",
                      }}
                      src={`https://www.google.com/search?igu=1&ei=&q=${element["Ref_Constructeur"]}`}
                      frameBorder="0"
                      allowFullScreen
                    />
                  </Card>
                </TabsCustomItem>
              );
            } else {
              return null;
            }
          })}
        </TabsCustomBody>
      </TabsCustom>
    );
  }
}
