import moment from "moment";
import axiosConfig from "../utils/axiosConfig";

const getNPS = (filters, callback, isDay = false) => {
  if (isDay) {
    filters = JSON.parse(JSON.stringify(filters));
    filters.dateStart = moment().format("YYYY-MM-DD");
    filters.dateEnd = moment().format("YYYY-MM-DD");
  }

  axiosConfig("/satisfaction/get-NPS", { data: filters })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getAverageGrade = (filters, callback, isDay = false) => {
  if (isDay) {
    filters = JSON.parse(JSON.stringify(filters));
    filters.dateStart = moment().format("YYYY-MM-DD");
    filters.dateEnd = moment().format("YYYY-MM-DD");
  }

  axiosConfig("/satisfaction/get-average-grade", { data: filters })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getResponseRate = (filters, callback, isDay = false) => {
  if (isDay) {
    filters = JSON.parse(JSON.stringify(filters));
    filters.dateStart = moment().format("YYYY-MM-DD");
    filters.dateEnd = moment().format("YYYY-MM-DD");
  }

  axiosConfig("/satisfaction/get-response-rate", { data: filters })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const getData = (filters, callback) => {
  axiosConfig("/satisfaction/get-data", { data: filters })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

const updateAnalyze = (id, data, callback) => {
  axiosConfig("/satisfaction/update-analyze", { data: { id, data } })
    .then((res) => {
      callback(res.data);
    })
    .catch(() => callback({ success: false }));
};

export { getNPS, getAverageGrade, getResponseRate, getData, updateAnalyze };
