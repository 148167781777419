import React, { Component } from "react";

import {
  Card,
  BarChart,
  HorizontalBarChart,
  PieChart,
  Loader,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";

import { getPercentageValue } from "../../../../utils/general";

import axiosConfig from "../../../../utils/axiosConfig";
export default class CardMATMUT extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      search: null,
      isLoad: false,
    };
  }

  componentDidMount() {
    this.getPerformance();
  }

  componentDidUpdate = (prevProps) => {
    if (
      JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data) &&
      parseInt(this.props.data.company) === 9
    ) {
      this.getPerformance();
    }
  };

  getPerformance = () => {
    this.setState({ isLoad: true, data: null }, () => {
      axiosConfig("/company-performance/matmut", {
        data: {
          dateStart: this.props.data.dateStart,
          dateEnd: this.props.data.dateEnd,
          network: this.props.data.network,
          group: this.props.data.group,
          region: this.props.data.region,
          agency: this.props.data.agency,
          user: this.props.data.user,
          company: this.props.data.company,
        },
      }).then((res) => {
        if (res.data.success) {
          this.setState({
            data: this.formatData(res.data.data),
            search: res.data.search,
            isLoad: false,
          });
        } else {
          this.setState({
            data: null,
            search: null,
            isLoad: false,
          });
        }
      });
    });
  };

  getEvol = (data) => {
    return (
      <>
        <div className={"evol nowrap"}>
          <div className={"nowrap"} style={{ fontWeight: "bold" }}>
            {data.data}
          </div>
        </div>
      </>
    );
  };

  getTwoLines = (data) => {
    return (
      <>
        <div className={"nowrap title-container"}>
          <span className={"title-chart"}>{data.title}</span>
        </div>
        <div className={"flex-container"}>
          <div className={"flex-column"}>
            <h3 style={{ color: data.color[0] }}>
              (N) {data.data[0] ? data.data[0] : "Pas de résultat"}
            </h3>
            <h3 style={{ color: data.color[1] }}>
              (N-1) {data.data[1] ? data.data[1] : "Pas de résultat"}
            </h3>
          </div>
          <div className={"nowrap w-100"}>
            <h3>{data.data[2] ? data.data[2] : "Pas de résultat"}</h3>
          </div>
        </div>
      </>
    );
  };

  getTreeLines = (data) => {
    return (
      <>
        <div className={"nowrap title-container mb-30"}>
          <span className={"title-chart"}>{data.title}</span>
        </div>
        <div className={"flex-container-2 mt-20"}>
          <div className={"flex-column"}>
            <div className={"flex-row"}>
              <div>{data.labels[0] + ": "}</div>
              <div style={{ color: data.color[0], fontWeight: "bold" }}>
                {data.data[0]}
              </div>
            </div>
            <div className={"flex-row"}>
              <div>{data.labels[1] + ": "}</div>
              <div style={{ color: data.color[1], fontWeight: "bold" }}>
                {data.data[1]}
              </div>
            </div>
            <div className={"flex-row"}>
              <div>{data.labels[2] + ": "}</div>
              <div style={{ fontWeight: "bold" }}>{data.data[2]}</div>
            </div>
          </div>
        </div>
      </>
    );
  };

  formatData(data) {
    for (const folder in data) {
      for (const row in data[folder]) {
        data[folder][row][2] = this.getPercentageValuePlus(
          data[folder][row][2],
          0
        );
      }
    }

    return data;
  }

  getPercentageValuePlus(value) {
    if (parseFloat(value) < 0) {
      value = getPercentageValue(value);
    } else {
      value = `+${getPercentageValue(value, 0)}`;
    }
    return value;
  }

  render() {
    const generalData = this.state.data
      ? [
          {
            title: "Nombre de dossiers gérés",
            labels: ["N", "N-1"],
            data: [
              {
                color: ["rgba(54,80,221,1)", "rgba(54,209,58,1)"],
                data: [
                  this.state.data.dataR.totalFiles[0],
                  this.state.data.dataR.totalFiles[1],
                ],
              },
            ],
          },
          {
            title: "Coût Moyen Brut HT (en €)",
            color: ["rgba(54,80,221,1)", "rgba(54,209,58,1)"],
            data: [
              this.state.data.dataR.averageCost[0],
              this.state.data.dataR.averageCost[1],
              this.state.data.dataR.averageCost[2],
            ],
          },
          {
            title: "Nombre de dossiers / An",
            labels: ["N", "N-1"],
            data: [
              {
                color: ["rgba(54,80,221,1)", "rgba(54,209,58,1)"],
                data: [
                  this.state.data.dataR.totalFilesVP[0],
                  this.state.data.dataR.totalFilesVP[1],
                ],
              },
            ],
          },
          {
            title: "Nombre de dossiers / Tranche",
            labels: [
              "< 250 € HT",
              "250 - 1000 € HT",
              "1001 - 2000 € HT",
              "> 2000 € HT",
            ],
            data: [
              {
                type: "bar",
                color: "rgba(54,80,221,1)",
                data: this.state.data.dataR.totalFilesVP[3].year,
              },
              {
                type: "bar",
                color: "rgba(54,209,58,1)",
                data: this.state.data.dataR.totalFilesVP[3].prevYear,
              },
            ],
          },
          {
            title: "Coût Moyen Brut HT / An (en €)",
            labels: ["N", "N-1"],
            data: [
              {
                color: ["rgba(54,80,221,1)", "rgba(54,209,58,1)"],
                data: [
                  this.state.data.dataR.averageCostVP[0],
                  this.state.data.dataR.averageCostVP[1],
                ],
              },
            ],
          },
          {
            title: "Coût Moyen Brut HT / Tranche (en €)",
            labels: [
              "< 250 € HT",
              "250 - 1000 € HT",
              "1001 - 2000 € HT",
              "> 2000 € HT",
            ],
            data: [
              {
                type: "line",
                color: "#0162AD",
                label: "évol. (%)",
                data: this.state.data.dataR.averageCostVP[3].evol,
              },
              {
                type: "bar",
                color: "rgba(54,80,221,1)",
                data: this.state.data.dataR.averageCostVP[3].year,
              },
              {
                type: "bar",
                color: "rgba(54,209,58,1)",
                data: this.state.data.dataR.averageCostVP[3].prevYear,
              },
            ],
          },
        ]
      : [];

    const detailData = this.state.data
      ? [
          {
            title: "Nb d'heures de Main d'Oeuvre  (en h.)",
            labels: [
              "< 250 € HT",
              "250 - 1000 € HT",
              "1001 - 2000 € HT",
              "> 2000 € HT",
            ],
            data: [
              {
                type: "line",
                color: "#0162AD",
                label: "évol. (%)",
                data: this.state.data.dataR.hoursMO[3].evol,
              },
              {
                type: "bar",
                color: "rgba(54,80,221,1)",
                data: this.state.data.dataR.hoursMO[3].year,
              },
              {
                type: "bar",
                color: "rgba(54,209,58,1)",
                data: this.state.data.dataR.hoursMO[3].prevYear,
              },
            ],
          },
          {
            title: "Taux Horaire Main d'Oeuvre  (en €)",
            labels: [
              "< 250 € HT",
              "250 - 1000 € HT",
              "1001 - 2000 € HT",
              "> 2000 € HT",
            ],
            data: [
              {
                type: "line",
                color: "#0162AD",
                label: "évol. (%)",
                data: this.state.data.dataR.rateMO[3].evol,
              },
              {
                type: "line",
                color: "rgba(54,80,221,1)",
                data: this.state.data.dataR.rateMO[3].year,
              },
              {
                type: "line",
                color: "rgba(54,209,58,1)",
                data: this.state.data.dataR.rateMO[3].prevYear,
              },
            ],
          },
          {
            title: "Nb d'heures d'Ingrédients Peinture  (en h.)",
            labels: [
              "< 250 € HT",
              "250 - 1000 € HT",
              "1001 - 2000 € HT",
              "> 2000 € HT",
            ],
            data: [
              {
                type: "line",
                color: "#0162AD",
                label: "évol. (%)",
                data: this.state.data.dataR.hoursIP[3].evol,
              },
              {
                type: "bar",
                color: "rgba(54,80,221,1)",
                data: this.state.data.dataR.hoursIP[3].year,
              },
              {
                type: "bar",
                color: "rgba(54,209,58,1)",
                data: this.state.data.dataR.hoursIP[3].prevYear,
              },
            ],
          },
          {
            title: "Taux Horaire Ingrédient Peinture (en €)",
            labels: [
              "< 250 € HT",
              "250 - 1000 € HT",
              "1001 - 2000 € HT",
              "> 2000 € HT",
            ],
            data: [
              {
                type: "line",
                color: "#0162AD",
                label: "évol. (%)",
                data: this.state.data.dataR.rateIP[3].evol,
              },
              {
                type: "line",
                color: "rgba(54,80,221,1)",
                data: this.state.data.dataR.rateIP[3].year,
              },
              {
                type: "line",
                color: "rgba(54,209,58,1)",
                data: this.state.data.dataR.rateIP[3].prevYear,
              },
            ],
          },
          [
            {
              title: "Taux d'interviention PRE (en %)",
              color: ["rgba(54,80,221,1)", "rgba(54,209,58,1)"],
              labels: ["Année N", "Année N-1", "Évolution"],
              data: this.state.data.dataR.txPRE,
            },
            {
              title: "Taux réparation plastique (en %)",
              color: ["rgba(54,80,221,1)", "rgba(54,209,58,1)"],
              labels: ["Année N", "Année N-1", "Évolution"],
              data: this.state.data.dataR.txRP,
            },
          ],
          {
            title: "Coût Moyen Brut Pièces HT / T (en €)",
            labels: [
              "< 250 € HT",
              "250 - 1000 € HT",
              "1001 - 2000 € HT",
              "> 2000 € HT",
            ],
            data: [
              {
                type: "line",
                color: "#0162AD",
                label: "évol. (%)",
                data: this.state.data.dataR.costPieces[3].evol,
              },
              {
                type: "bar",
                color: "rgba(54,80,221,1)",
                data: this.state.data.dataR.costPieces[3].year,
              },
              {
                type: "bar",
                color: "rgba(54,209,58,1)",
                data: this.state.data.dataR.costPieces[3].prevYear,
              },
            ],
          },
        ]
      : [];

    const qualityData = this.state.data
      ? [
          {
            title: "Délai moyen de dépot du RE (en j.)",
            labels: ["N", "N-1"],
            data: [
              {
                color: ["rgba(54,80,221,1)", "rgba(54,209,58,1)"],
                data: [
                  this.state.data.dataR.delayRE[0],
                  this.state.data.dataR.delayRE[1],
                ],
              },
            ],
          },
          {
            title: "Fréquence moyenne de SD 29 (en %)",
            color: ["rgba(54,80,221,1)", "rgba(54,209,58,1)"],
            labels: ["Année N", "Année N-1", "Évolution"],
            data: this.state.data.dataR.txSD29,
          },
          {
            title: "Délai moyen Exam-SD29 (en j.)",
            color: ["rgba(54,80,221,1)", "rgba(54,209,58,1)"],
            data: this.state.data.dataR.delayVisitSD29,
          },
          {
            title: "Délai moyen OM-SD29  (en j.)",
            color: ["rgba(54,80,221,1)", "rgba(54,209,58,1)"],
            data: this.state.data.dataR.delayOM,
          },
          {
            title: "Fréquence de RE avec lignes commentaires (en %)",
            color: ["rgba(54,80,221,1)", "rgba(54,209,58,1)"],
            data: this.state.data.dataR.txRemark,
          },
          {
            title: "Nb moyen de lignes de commentaires",
            color: ["rgba(54,80,221,1)", "rgba(54,209,58,1)"],
            data: this.state.data.dataR.averageRemark,
          },
          {
            title: "Taux moyen EAD N (en %)",
            labels: ["O", "N"],
            data: [
              {
                color: "#00e600",
                data: this.state.data.dataR.txEAD[1],
                labels: "EAD",
              },
              {
                color: "#004d99",
                data: 100 - this.state.data.dataR.txEAD[1],
                labels: "Autres",
              },
            ],
          },
          {
            title: "Taux moyen EAD N-1 (en %)",
            labels: ["O", "N"],
            data: [
              {
                color: "#00e600",
                data: this.state.data.dataR.txEAD[0],
                labels: "EAD",
              },
              {
                color: "#004d99",
                data: 100 - this.state.data.dataR.txEAD[0],
                labels: "Autres",
              },
            ],
          },
          {
            title: "Évolution",
            data: this.state.data.dataR.txEAD[2],
          },
          {
            title: "Taux moyen EAD agréés N (en %)",
            labels: ["O", "N"],
            data: [
              {
                color: "#00e600",
                data: this.state.data.dataR.txEADCertified[1],
                labels: "EAD",
              },
              {
                color: "#004d99",
                data: 100 - this.state.data.dataR.txEADCertified[1],
                labels: "Autres",
              },
            ],
          },
          {
            title: "Taux moyen EAD agréés N-1 (en %)",
            labels: ["O", "N"],
            data: [
              {
                color: "#00e600",
                data: this.state.data.dataR.txEADCertified[0],
                labels: "EAD",
              },
              {
                color: "#004d99",
                data: 100 - this.state.data.dataR.txEADCertified[0],
                labels: "Autres",
              },
            ],
          },
          {
            title: "Évolution",
            data: this.state.data.dataR.txEADCertified[2],
          },
        ]
      : [];

    const generalDataNR = this.state.data
      ? [
          {
            title: "Nombre de dossiers gérés",
            labels: ["N", "N-1"],
            data: [
              {
                color: ["rgba(54,80,221,1)", "rgba(54,209,58,1)"],
                data: [
                  this.state.data.dataNR.totalFiles[0],
                  this.state.data.dataNR.totalFiles[1],
                ],
              },
            ],
          },
          {
            title: "Évolution",
            data: this.state.data.dataNR.totalFiles[2],
          },
          {
            title: "Nombre de dossiers",
            color: ["rgba(54,80,221,1)", "rgba(54,209,58,1)"],
            labels: ["Année N", "Année N-1", "Évolution"],
            data: this.state.data.dataNR.totalFilesVP,
          },
          {
            title: "Nombre de dossiers RIV",
            color: ["rgba(54,80,221,1)", "rgba(54,209,58,1)"],
            labels: ["Année N", "Année N-1", "Évolution"],
            data: this.state.data.dataNR.nbRIV,
          },
          {
            title: "Coût Moyen VRADE TTC (en €)",
            color: ["rgba(54,80,221,1)", "rgba(54,209,58,1)"],
            data: this.state.data.dataNR.averageCost,
          },
          {
            title: "Cout moyen RIV",
            labels: ["N", "N-1"],
            data: [
              {
                color: ["rgba(54,80,221,1)", "rgba(54,209,58,1)"],
                data: [
                  this.state.data.dataNR.averageRIV[0],
                  this.state.data.dataNR.averageRIV[1],
                ],
              },
            ],
          },
          {
            title: "Évolution",
            data: this.state.data.dataNR.averageRIV[2],
          },
          {
            title: "Age moyen du véhicule (en ann.)",
            labels: ["N", "N-1"],
            data: [
              {
                color: ["rgba(54,80,221,1)", "rgba(54,209,58,1)"],
                data: [
                  this.state.data.dataNR.averageAgeVehicle[0],
                  this.state.data.dataNR.averageAgeVehicle[1],
                ],
              },
            ],
          },
          {
            title: "Évolution",
            data: this.state.data.dataNR.averageAgeVehicle[2],
          },
          {
            title: "Age moyen du véhicule (RIV) (en ann.)",
            labels: ["N", "N-1"],
            data: [
              {
                color: ["rgba(54,80,221,1)", "rgba(54,209,58,1)"],
                data: [
                  this.state.data.dataNR.averageAgeVehicleRIV[0],
                  this.state.data.dataNR.averageAgeVehicleRIV[1],
                ],
              },
            ],
          },
          {
            title: "Évolution",
            data: this.state.data.dataNR.averageAgeVehicle[2],
          },
        ]
      : [];

    const qualityDataNR = this.state.data
      ? [
          {
            title: "Répartition de dossiers N",
            labels: ["NR", "R"],
            data: [
              {
                color: "#99003d",
                data: this.state.data.dataNR.txVEI[1],
                labels: "VEI",
              },
              {
                color: "#00e600",
                data: this.state.data.dataNR.txRIV[1],
                labels: "RIV",
              },
              {
                color: "#004d99",
                data:
                  100 -
                  this.state.data.dataNR.txVEI[1] +
                  this.state.data.dataNR.txRIV[1],
                labels: "REP",
              },
            ],
          },
          {
            title: "Répartition de dossiers N-1",
            labels: ["NR", "R"],
            data: [
              {
                color: "#99003d",
                data: this.state.data.dataNR.txVEI[0],
                labels: "VEI",
              },
              {
                color: "#00e600",
                data: this.state.data.dataNR.txRIV[0],
                labels: "RIV",
              },
              {
                color: "#004d99",
                data:
                  100 -
                  this.state.data.dataNR.txVEI[0] +
                  this.state.data.dataNR.txRIV[0],
                labels: "REP",
              },
            ],
          },
          {
            title: "Délai moyen de dépot du RE (en j.)",
            labels: ["N", "N-1"],
            data: [
              {
                color: ["rgba(54,80,221,1)", "rgba(54,209,58,1)"],
                data: [
                  this.state.data.dataNR.delayRE[0],
                  this.state.data.dataNR.delayRE[1],
                ],
              },
            ],
          },
          {
            title: "Évolution",
            data: this.state.data.dataNR.delayRE[2],
          },
          {
            title: "Fréquence moyenne de SD 29 (en %)",
            color: ["rgba(54,80,221,1)", "rgba(54,209,58,1)"],
            labels: ["Année N", "Année N-1", "Évolution"],
            data: this.state.data.dataNR.txSD29,
          },
          {
            title: "Délai moyen exam-SD29 (en j.)",
            labels: ["N", "N-1"],
            data: [
              {
                color: ["rgba(54,80,221,1)", "rgba(54,209,58,1)"],
                data: [
                  this.state.data.dataNR.delaySD29[0],
                  this.state.data.dataNR.delaySD29[1],
                ],
              },
            ],
          },
          {
            title: "Évolution",
            data: this.state.data.dataNR.delaySD29[2],
          },
          {
            title: "Délai moyen OM-SD29 (en j.)",
            labels: ["N", "N-1"],
            data: [
              {
                color: ["rgba(54,80,221,1)", "rgba(54,209,58,1)"],
                data: [
                  this.state.data.dataNR.delayOM[0],
                  this.state.data.dataNR.delayOM[1],
                ],
              },
            ],
          },
          {
            title: "Évolution",
            data: this.state.data.dataNR.delayOM[2],
          },
          {
            title: "Fréquence de RE avec lignes commentaires (en %)",
            color: ["rgba(54,80,221,1)", "rgba(54,209,58,1)"],
            data: this.state.data.dataNR.txRemark,
          },
          {
            title: "Nb moyen de lignes de commentaires",
            color: ["rgba(54,80,221,1)", "rgba(54,209,58,1)"],
            data: this.state.data.dataNR.averageRemark,
          },
        ]
      : [];

    const dataR = {
      general: {
        agence: generalData,
      },
      detail: {
        agence: detailData,
      },
      quality: {
        agence: qualityData,
      },
    };

    const dataNR = {
      general: {
        agence: generalDataNR,
      },
      quality: {
        agence: qualityDataNR,
      },
    };

    const borderStyle = {
      border: "solid",
      borderRadius: "30px",
      borderColor: "grey",
      padding: "30px",
      height: "300px",
    };
    const borderPieStyle = {
      border: "solid",
      borderRadius: "30px",
      borderColor: "grey",
      padding: "30px",
      height: "fit-content",
    };
    const borderDoubleStyle = {
      border: "solid",
      borderRadius: "30px",
      borderColor: "grey",
      padding: "30px",
      height: "fit-content",
      minHeight: "500px",
    };

    const xAxes = [
      {
        barThickness: 50,
      },
    ];

    const captionColor = (
      <>
        <span
          className="ml-20"
          style={{ color: "rgba(54,80,221,1)", fontSize: "18px" }}
        >
          N
        </span>
        <span
          className="ml-20"
          style={{ color: "rgba(54,209,58,1)", fontSize: "18px" }}
        >
          N-1
        </span>
      </>
    );

    const dataLabels = [
      {
        display: true,
        align: "center",
        clamp: true,
        color: "#0162AD",
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        font: {
          weight: 'bold',
        }
      },
    ];

    let general = {};

    for (const property in dataR.general) {
      general[property] = (
        <>
          {dataR.general[property].map((chart, idx) => {
            if (idx === 0) {
              return (
                <Row key={idx} between="xs">
                  <Col md={12}>
                    <div style={{ fontSize: "14px" }} className="mb-20  mt-30">
                      <p>
                        Tous types de véhicules - Tous sinistres{captionColor}
                      </p>
                    </div>
                  </Col>
                  <Col md={8} className={"mt-30"}>
                    <div style={borderStyle}>
                      <HorizontalBarChart
                        title={chart.title}
                        labels={chart.labels}
                        dataSet={chart.data}
                        align={true}
                        widthBar={25}
                        datalabels2={dataLabels}
                        yAxes={[
                          {
                            ticks: {
                              display: false,
                              beginAtZero: true,
                            },
                          },
                        ]}
                      />
                    </div>
                  </Col>
                  <Col md={4} className={"mt-30"}>
                    <div style={borderStyle}>
                      {this.getTwoLines(dataR.general[property][idx + 1])}
                    </div>
                  </Col>
                </Row>
              );
            } else if (idx === 2 || idx === 4) {
              return (
                <Row key={idx}>
                  {idx === 2 ? (
                    <Col md={12}>
                      <div
                        style={{ fontSize: "14px" }}
                        className="mb-20  mt-30"
                      >
                        <p>VP/CTTE - Collision Vol Incendie{captionColor}</p>
                      </div>
                    </Col>
                  ) : null}
                  <Col md={4} className={"mt-30"}>
                    <div style={borderStyle}>
                      <BarChart
                        title={chart.title}
                        labels={chart.labels}
                        dataSet={chart.data}
                        widthBar={25}
                        yAxes={[
                          {
                            ticks: {
                              display: false,
                              beginAtZero: true,
                            },
                          },
                        ]}
                        xAxes={xAxes}
                        datalabels2={dataLabels}
                      />
                    </div>
                  </Col>
                  <Col md={8} className={"mt-30"}>
                    <div style={borderStyle}>
                      <BarChart
                        title={dataR.general[property][idx + 1].title}
                        labels={dataR.general[property][idx + 1].labels}
                        dataSet={dataR.general[property][idx + 1].data}
                        widthBar={20}
                        yAxes={[
                          {
                            ticks: {
                              display: false,
                              beginAtZero: true,
                            },
                          },
                        ]}
                        xAxes={xAxes}
                      />
                    </div>
                  </Col>
                </Row>
              );
            } else {
              return null;
            }
          })}
        </>
      );
    }

    const yAxes = [
      {
        ticks: {
          display: false,
          beginAtZero: true,
        },
      },
    ];
    let detail = {};
    for (const property in dataR.detail) {
      detail[property] = (
        <>
          {dataR.detail[property].map((chart, idx) => {
            if (idx === 4) {
              return (
                <Row key={idx}>
                  <Col xs={12} md={6} lg={6} className={"mt-30"}>
                    <div style={borderStyle}>
                      {chart.map((element, id) => {
                        return <div key={id}>{this.getTreeLines(element)}</div>;
                      })}
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={6} className={"mt-30"}>
                    <div style={borderStyle}>
                      <BarChart
                        title={dataR.detail[property][idx + 1].title}
                        labels={dataR.detail[property][idx + 1].labels}
                        dataSet={dataR.detail[property][idx + 1].data}
                        datalabels2={dataLabels}
                        xAxes={xAxes}
                      />
                    </div>
                  </Col>
                </Row>
              );
            } else if (idx === 0 || idx === 2) {
              return (
                <Row key={idx}>
                  {idx === 0 ? (
                    <Col md={12}>
                      <div
                        style={{ fontSize: "14px" }}
                        className="mb-20  mt-30"
                      >
                        <p>
                          VP/CTTE - Collision Vol Incendie / Selon Tranches de
                          coût
                          {captionColor}
                        </p>
                      </div>
                    </Col>
                  ) : null}
                  <Col
                    style={borderStyle}
                    xs={12}
                    md={6}
                    lg={6}
                    className={"mt-30"}
                  >
                    <BarChart
                      title={chart.title}
                      labels={chart.labels}
                      dataSet={chart.data}
                      datalabels2={dataLabels}
                      xAxes={[
                        {
                          barThickness: 50,
                        },
                      ]}
                    />
                  </Col>
                  <Col xs={12} md={6} lg={6} className={"mt-30"}>
                    <div style={borderStyle}>
                      <BarChart
                        title={dataR.detail[property][idx + 1].title}
                        labels={dataR.detail[property][idx + 1].labels}
                        dataSet={dataR.detail[property][idx + 1].data}
                        datalabels2={dataLabels}
                        xAxes={xAxes}
                      />
                    </div>
                  </Col>
                </Row>
              );
            } else {
              return null;
            }
          })}
        </>
      );
    }

    const quality = {};
    for (const property in dataR.quality) {
      quality[property] = (
        <Row>
          <Col md={12}>
            <div style={{ fontSize: "14px" }} className="mb-20  mt-30">
              <p>VP/CTTE - Collision Vol Incendie{captionColor}</p>
            </div>
          </Col>
          {dataR.quality[property].map((chart, idx) => {
            if (idx === 0) {
              return (
                <Col lg={4} md={12} xs={12} key={idx}>
                  <Row style={borderDoubleStyle} className={"mr-30"}>
                    <Col lg={12} className={"mt-30"}>
                      <BarChart
                        title={chart.title}
                        labels={chart.labels}
                        dataSet={chart.data}
                        widthBar={25}
                        yAxes={yAxes}
                        datalabels2={dataLabels}
                        xAxes={xAxes}
                      />
                    </Col>
                  </Row>
                </Col>
              );
            } else if (idx === 1) {
              return (
                <Col lg={8} md={12} xs={12} key={idx}>
                  <Row style={borderDoubleStyle}>
                    <Col lg={12} className={"mt-30"}>
                      {this.getTwoLines(dataR.quality[property][idx])}
                    </Col>
                    <Col lg={6} xs={12} className={"mt-30"}>
                      {this.getTwoLines(dataR.quality[property][idx + 1])}
                    </Col>
                    <Col lg={6} xs={12} className={"mt-30"}>
                      {this.getTwoLines(dataR.quality[property][idx + 2])}
                    </Col>
                  </Row>
                </Col>
              );
            } else if (idx === 4) {
              return (
                <Col md={12} key={idx} className={"mt-30"}>
                  <Row style={borderStyle}>
                    <Col lg={6} xs={12} className={"mt-30"}>
                      {this.getTwoLines(dataR.quality[property][idx])}
                    </Col>
                    <Col lg={6} xs={12} className={"mt-30"}>
                      {this.getTwoLines(dataR.quality[property][idx + 1])}
                    </Col>
                  </Row>
                </Col>
              );
            } else if (idx === 6 || idx === 9) {
              return (
                <Col lg={12} className={"mt-30"} key={idx}>
                  <Row style={borderPieStyle} className={"mt-30"}>
                    <Col lg={4} className={"mt-30"}>
                      <PieChart
                        title={chart.title}
                        format="percentage"
                        legendDisplay={true}
                        height={250}
                        data={chart.data}
                      />
                    </Col>
                    <Col lg={4} className={"mt-30"}>
                      <PieChart
                        title={dataR.quality[property][idx + 1].title}
                        format="percentage"
                        legendDisplay={true}
                        height={250}
                        data={dataR.quality[property][idx + 1].data}
                      />
                    </Col>
                    <Col lg={4} className={"mt-30"}>
                      {this.getEvol(dataR.quality[property][idx + 2])}
                    </Col>
                  </Row>
                </Col>
              );
            }
            return null;
          })}
        </Row>
      );
    }

    const generalNR = {};
    for (const property in dataNR.general) {
      generalNR[property] = (
        <Row>
          <Col md={12}>
            <div style={{ fontSize: "14px" }} className="mb-20  mt-30">
              <p>Tous types de véhicules - Tous sinistres{captionColor}</p>
            </div>
          </Col>
          {dataNR.general[property].map((chart, idx) => {
            if (idx === 0) {
              return (
                <Col key={idx} xs={10} md={8} lg={8} className={"mt-30"}>
                  <div style={borderStyle}>
                    <HorizontalBarChart
                      align
                      title={chart.title}
                      labels={chart.labels}
                      dataSet={chart.data}
                      widthBar={25}
                      yAxes={yAxes}
                      datalabels2={dataLabels}
                      xAxes={[
                        {
                          barThickness: 50,
                        },
                      ]}
                    />
                  </div>
                </Col>
              );
            } else if (idx === 1) {
              return (
                <Col key={idx} xs={2} md={4} lg={4} className={"mt-30"}>
                  <div style={borderStyle}>{this.getEvol(chart)} </div>
                </Col>
              );
            } else if (idx === 2 || idx === 3) {
              return (
                <>
                  {idx === 2 ? (
                    <Col md={12}>
                      <div
                        style={{ fontSize: "14px" }}
                        className="mb-20  mt-30"
                      >
                        <p>VP/CTTE - Collision Vol Incendie{captionColor}</p>
                      </div>
                    </Col>
                  ) : null}
                  <Col key={idx} xs={12} md={6} lg={6} className={"mt-30"}>
                    <div style={borderStyle}> {this.getTreeLines(chart)} </div>
                  </Col>
                </>
              );
            } else if (idx === 4) {
              return (
                <Col key={idx} xs={12} md={6} lg={6} className={"mt-30"}>
                  <div style={borderStyle}>{this.getTwoLines(chart)}</div>
                </Col>
              );
            } else if (idx === 5 || idx === 7 || idx === 9) {
              return (
                <Col key={idx} xs={12} md={6} lg={6} className={"mt-30"}>
                  <Row style={borderStyle} className={"ml-0 mr-5"}>
                    <Col xs={10} md={6} lg={6}>
                      <BarChart
                        title={chart.title}
                        labels={chart.labels}
                        dataSet={chart.data}
                        widthBar={25}
                        yAxes={yAxes}
                        datalabels2={dataLabels}
                        xAxes={xAxes}
                      />
                    </Col>
                    <Col xs={2} md={6} lg={6}>
                      {this.getEvol(dataNR.general[property][idx + 1])}
                    </Col>
                  </Row>
                </Col>
              );
            } else {
              return null;
            }
          })}
        </Row>
      );
    }

    const qualityNR = {};
    for (const property in dataNR.quality) {
      qualityNR[property] = (
        <Row>
          <Col md={12}>
            <div style={{ fontSize: "14px" }} className="mb-20  mt-30">
              <p>Tous types de véhicules - Tous sinistres{captionColor}</p>
            </div>
          </Col>
          {dataNR.quality[property].map((chart, idx) => {
            if (idx === 0 || idx === 1) {
              return (
                <Col
                  style={borderPieStyle}
                  key={idx}
                  xs={12}
                  md={6}
                  lg={5}
                  className={"mt-30 ml-30"}
                >
                  <PieChart
                    title={chart.title}
                    format="percentage"
                    legendDisplay={true}
                    height={250}
                    data={chart.data}
                  />
                </Col>
              );
            } else if (idx === 4 || idx === 11) {
              return (
                <Col
                  style={borderStyle}
                  key={idx}
                  xs={12}
                  md={5}
                  lg={5}
                  className={"mt-30 ml-30"}
                >
                  {this.getTreeLines(chart)}
                </Col>
              );
            } else if (idx === 9 || idx === 10) {
              return (
                <Col key={idx} xs={12} md={5} lg={5} className={"mt-30 ml-30"}>
                  <div style={borderStyle}>
                    {this.getTwoLines(dataNR.quality[property][idx])}
                  </div>
                </Col>
              );
            } else if (idx === 12) {
              return (
                <Row>
                  <Col
                    key={idx}
                    xs={12}
                    md={6}
                    lg={6}
                    className={"mt-30 ml-30"}
                  >
                    <div style={borderStyle}>{this.getTwoLines(chart)}</div>
                  </Col>
                </Row>
              );
            } else if (idx === 2 || idx === 5 || idx === 7) {
              return (
                <Col key={idx} xs={12} md={5} lg={5} className={"mt-30 ml-30"}>
                  <Row style={borderStyle}>
                    <Col md={8}>
                      <BarChart
                        title={chart.title}
                        labels={chart.labels}
                        dataSet={chart.data}
                        widthBar={25}
                        yAxes={yAxes}
                        datalabels2={dataLabels}
                        xAxes={xAxes}
                      />
                    </Col>
                    <Col md={4}>
                      {this.getEvol(dataNR.quality[property][idx + 1])}
                    </Col>
                  </Row>
                </Col>
              );
            } else {
              return null;
            }
          })}
        </Row>
      );
    }

    return (
      <div>
        <Loader show={this.state.isLoad} />
        <Card
          title={"Réparables Général"}
          className={"mt-10 mt-30-xl "}
          classNameChildren="overflow-x-auto"
        >
          <Row center="xs" top="xs">
            <Col xs={12} md={12} lg={10} className={"mt-30"}>
              {general.agence}
            </Col>
          </Row>
        </Card>

        <Card
          title={"Réparables Détail"}
          className={"mt-10 mt-30-xl "}
          classNameChildren="overflow-x-auto"
        >
          <Row center="xs" top="xs">
            <Col xs={12} md={12} lg={10} className={"mt-30"}>
              {detail.agence}
            </Col>
          </Row>
        </Card>

        <Card
          title={"Réparables Qualité Service"}
          className={"mt-10 mt-30-xl "}
          classNameChildren="overflow-x-auto"
        >
          <Row center="xs" top="xs">
            <Col xs={12} md={12} lg={10} className={"mt-30"}>
              {quality.agence}
            </Col>
          </Row>
        </Card>

        <Card
          title={"Non Réparables Géneral"}
          className={"mt-10 mt-30-xl "}
          classNameChildren="overflow-x-auto"
        >
          <Row center="xs" top="xs">
            <Col xs={12} md={12} lg={10} className={"mt-30"}>
              {generalNR.agence}
            </Col>
          </Row>
        </Card>

        <Card
          title={"Non Réparables Qualité Service"}
          className={"mt-10 mt-30-xl "}
          classNameChildren="overflow-x-auto"
        >
          <Row center="xs" top="xs">
            <Col xs={12} md={12} lg={10} className={"mt-30"}>
              {qualityNR.agence}
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}
