import React, { Component } from "react";
import { Modal, Loader } from "ui-kit-ck-consultant";
import { withRouter } from "react-router-dom";
import axiosConfig from "../../../utils/axiosConfig";
import moment from "moment";

class DetailsDeclare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      show: false,
      currentPlanningId: this.props.match.params.id,
      planning: null,
    };
  }

  componentDidMount() {
    this.props.match.params.id && this.getPlanning(this.props.match.params.id);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.id &&
      this.props.match.params.id !== prevProps.match.params.id
    ) {
      this.getPlanning(this.props.match.params.id);
    }
  }

  handleCloseClick = () => {
    this.setState({ currentPlanningId: null });
    this.props.history.push(`/planning`);
  };

  getPlanning = (id) => {
    this.setState({ isLoad: true }, () => {
      axiosConfig(`planning/get-planning/${id}`).then((res) => {
        this.setState({
          isLoad: false,
          planning: res.data.data,
          currentPlanningId: this.props.match.params.id,
        });
      });
    });
  };

  render() {
    if (this.state.planning) {
      const {
        type,
        start,
        end,
        typeStart,
        typeEnd,
        agency,
        comment,
        adress,
        organizationName,
        organizationType,
        nameOrganization,
        nameFormation,
      } = this.state.planning;
      
      return (
        <>
          <Modal
            show={!!this.state.currentPlanningId?.length}
            onClose={this.handleCloseClick}
            title={this.props.title}
          >
            {this.state.planning && (
              <>
                {type !== "Formation" && (
                  <div>
                    <p>Type: {type}</p>
                    <p>Agence: {agency}</p>
                    <p>Date de départ: {moment(start).format("DD-MM-YYYY")}</p>
                    <p>Date de retour: {moment(end).format("DD-MM-YYYY")}</p>
                    <p>Type de départ: {typeStart}</p>
                    <p>Type de retour: {typeEnd}</p>
                    <p>Commentaire: {comment}</p>
                  </div>
                )}
                {type === "Formation" && (
                  <div>
                    <p>Type: {type}</p>
                    <p>Agence: {agency}</p>
                    <p>Date de départ: {moment(start).format("DD-MM-YYYY")}</p>
                    <p>Date de retour: {moment(end).format("DD-MM-YYYY")}</p>
                    <p>Adresse: {adress}</p>
                    <p>
                      Organisation:{" "}
                      {organizationName === "AUTRE"
                        ? nameOrganization
                        : organizationName}
                    </p>
                    <p>
                      Type de formation:{" "}
                      {organizationName === "AUTRE"
                        ? nameFormation
                        : organizationType}
                    </p>
                  </div>
                )}
              </>
            )}
          </Modal>
          <Loader show={this.state.isLoad} />
        </>
      );
    } else return null;
  }
}

export default withRouter(DetailsDeclare);
