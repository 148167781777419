import React, { Component } from "react";
import { Col, Row } from "react-flexbox-grid";
import {
  Card,
  Signature,
  FormInput,
  ButtonIcon,
  Button,
} from "ui-kit-ck-consultant";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Buffer } from "buffer";

export class SignatureCard extends Component {
  constructor() {
    super();
    this.state = {
      newSignature: {
        active: false,
      },
      signature: null,
    };
  }

  handleChangeForm(event) {
    let name = event.target.value;
    this.setState((prevState) => ({
      newSignature: {
        active: prevState.newSignature.active,
        name: name,
      },
    }));
  }

  handleFirm = (element) => {
    if (element && element.signature) {
      if (typeof element.signature === "string") {
        return element.signature;
      } else {
        let signature =
          "data:" +
          element.signature.type +
          ";base64," +
          new Buffer.from(element.signature.data).toString("base64");
        return signature;
      }
    } else {
      return null;
    }
  };

  render() {
    return (
      <Card
        title={"Signature"}
        className={"mt-10 mt-30-xl  " + this.props.className}
      >
        <Row>
          {this.props.signatures && this.props.signatures.length > 0 ? (
            this.props.signatures.map((element, idx) => {
              return (
                <Col xs={12} md={6} key={idx}>
                  <div style={{ display: "flex", flexDirection: "raw" }}>
                    <h2>{element.name}</h2>
                    {this.props.disableEdition ? null : (
                      <ButtonIcon
                        style={{ color: "#6067ae" }}
                        onClick={() => this.props.removeSign(idx)}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </ButtonIcon>
                    )}
                  </div>
                  <Signature
                    className="mt-10"
                    signature={this.handleFirm(element)}
                    onAdd={() => this.props.onAdd(idx)}
                    onRemove={() => this.props.onRemove(idx)}
                    disabled={this.props.disableEdition}
                  />
                </Col>
              );
            })
          ) : this.props.disableEdition ? (
            <span>Aucune signature</span>
          ) : null}
          {this.state.newSignature.active ? (
            <div
              style={{
                display: "flex",
                flexDirection: "raw",
                alignItems: "flex-end",
              }}
            >
              <FormInput
                title="Nom"
                type={"text"}
                onChange={(event) => this.handleChangeForm(event)}
              />
              <ButtonIcon
                style={{ color: "#6067ae" }}
                onClick={() => {
                  let newSign = this.state.newSignature;
                  newSign.active = false;
                  this.setState(
                    { newSignature: newSign },
                    this.props.addNewSignature(this.state.newSignature.name)
                  );
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
              </ButtonIcon>
            </div>
          ) : null}
          {this.props.disableEdition ? null : (
            <Button
              text={"Ajouter un signature"}
              className={"mt-10 "}
              onClick={() => {
                if (!this.state.newSignature.active) {
                  this.setState((prevState) => ({
                    newSignature: {
                      active: true,
                    },
                  }));
                }
              }}
            />
          )}
        </Row>
      </Card>
    );
  }
}

export default SignatureCard;
