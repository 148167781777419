import React from "react";

import {
  Modal,
  FormInput,
  Button,
  FormSelect,
  FormTextarea,
} from "ui-kit-ck-consultant";
export default class ModalForm extends React.Component {
  render() {
    return (
      <Modal
        show={this.props.show}
        onClose={this.props.onClose}
        title="Ajout d'une idée à la boite à idée"
      >
        <FormInput
          className="mb-20"
          title="Nom"
          name="title"
          value={this.props.fields.title}
          onChange={(e) => this.props.onChange(e)}
        />
        <FormTextarea
          className="mb-20"
          title="Message"
          name="content"
          value={this.props.fields.content}
          onChange={(e) => this.props.onChange(e)}
        />
        <FormSelect
          className="mb-20"
          title="Catégorie"
          name="typeForm"
          value={this.props.type ? this.props.type : 0}
          options={this.props.typeOptions}
          onChange={(e) => this.props.onChange(e)}
        />
        <FormSelect
          className="mb-20"
          title="Sous-catégorie"
          name="categoryForm"
          value={this.props.category ? this.props.category : 0}
          options={this.props.categoryOptions}
          onChange={(e) => this.props.onChange(e)}
          disabled={this.props.categoryDisabled}
        />
        <FormSelect
          className="mb-20"
          title="Priorité"
          name="priority"
          value={this.props.priority ? this.props.priority : 0}
          options={[
            { text: "Faible", value: 0 },
            { text: "Normal", value: 1 },
            { text: "Urgent", value: 2 },
          ]}
          onChange={(e) => this.props.onChange(e)}
        />
        <Button text={"Valider"} onClick={this.props.valid} />
      </Modal>
    );
  }
}
