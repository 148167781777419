import React, { Component } from "react";
import FadeIn from "react-fade-in";
import { Col, Row } from "react-flexbox-grid";
import { BarChart, Card, PieChart } from "ui-kit-ck-consultant";

import { getCurrencyValue, getPercentageValue } from "../../../utils/general";

export class GeneralCharts extends Component {
  randomColor = () => {
    let randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return randomColor;
  };

  render() {
    const datalabels2 = this.props.isPrint
      ? [
          {
            display: true,
            align: "top",
            anchor: "end",
            clamp: true,
            color: "#0162AD",
            backgroundColor: "white",
            borderColor: "#0162AD",
            borderWidth: 1,
            borderRadius: 50,
            formatter: (value) => Math.round(value),
          },
          {
            display: true,
            align: "top",
            anchor: "end",
            clamp: true,
            color: "#0162AD",
            formatter: (value) => Math.round(value),
          },
          {
            display: true,
            align: "top",
            anchor: "end",
            clamp: true,
            color: "#0162AD",
            formatter: (value) => Math.round(value),
          },
        ]
      : null;

    const yAxes = [
      {
        id: "A",
        type: "linear",
        position: "left",
        display: false,
        gridLines: {
          drawBorder: false,
        },
      },
      {
        id: "B",
        display: false,
        gridLines: {
          drawBorder: false,
        },
      },
    ];

    return (
      <FadeIn>
        {this.props.data &&
          Object.keys(this.props.data).map((keyCompany, idx) => {
            return (
              <Card key={idx} className="mb-20" title={keyCompany}>
                <Row middle="md">
                  <Col xs={12} md={6} lg={3}>
                    {
                      <PieChart
                        full
                        title={
                          keyCompany === "TOUTES COMPAGNIES"
                            ? "Répartition compagnies"
                            : "Type de sinistre"
                        }
                        legendDisplay={true}
                        format="percentage"
                        digits={0}
                        data={Object.keys(this.props.data[keyCompany].type).map(
                          (keyEvent) => {
                            return {
                              data: Math.round(
                                (this.props.data[keyCompany].type[keyEvent] /
                                  this.props.data[keyCompany].totalYear[1]) *
                                  100
                              ),
                              labels: keyEvent,
                              color: "#" + this.randomColor(),
                            };
                          }
                        )}
                      />
                    }
                  </Col>
                  <Col xs={12} md={6} lg={3}>
                    <BarChart
                      title="Nombre de mission N / N-1"
                      widthBar={10}
                      digits={0}
                      dataSet={[
                        {
                          type: "line",
                          data: this.props.data[keyCompany].total[2],
                          label: "Évolution (%)",
                          color: "#7D43DF",
                          yAxisID: "B",
                        },
                        {
                          type: "bar",
                          data: this.props.data[keyCompany].total[0],
                          label: "N-1",
                          color: "red",
                          yAxisID: "A",
                        },
                        {
                          type: "bar",
                          data: this.props.data[keyCompany].total[1],
                          label: "N",
                          color: "green",
                          yAxisID: "A",
                        },
                      ]}
                      datalabels2={datalabels2}
                      yAxes={yAxes}
                      isLegend={true}
                    />
                  </Col>
                  <Col xs={12} md={4} lg={2}>
                    <h2>CA cumulé N / N-1</h2>
                    <p>
                      N :{" "}
                      {getCurrencyValue(
                        this.props.data[keyCompany].totalCACumul[1][
                          this.props.data[keyCompany].totalCACumul[2].length - 1
                        ]
                      )}
                    </p>
                    <p>
                      N - 1 :{" "}
                      {getCurrencyValue(
                        this.props.data[keyCompany].totalCACumul[0][
                          this.props.data[keyCompany].totalCACumul[2].length - 1
                        ]
                      )}
                    </p>
                    <p>
                      évol % :{" "}
                      {getPercentageValue(
                        this.props.data[keyCompany].totalCACumul[2][
                          this.props.data[keyCompany].totalCACumul[2].length - 1
                        ]
                      )}
                    </p>
                  </Col>
                  <Col xs={12} md={4} lg={2}>
                    <h2>Missions reçues cumulé N / N-1</h2>
                    <p>
                      N :{" "}
                      {
                        this.props.data[keyCompany].totalCumul[1][
                          this.props.data[keyCompany].totalCumul[2].length - 1
                        ]
                      }
                    </p>
                    <p>
                      N - 1 :{" "}
                      {
                        this.props.data[keyCompany].totalCumul[0][
                          this.props.data[keyCompany].totalCumul[2].length - 1
                        ]
                      }
                    </p>
                    <p>
                      évol % :{" "}
                      {getPercentageValue(
                        this.props.data[keyCompany].totalCumul[2][
                          this.props.data[keyCompany].totalCumul[2].length - 1
                        ]
                      )}
                    </p>
                  </Col>
                  <Col xs={12} md={4} lg={2}>
                    <h2>Missions déposées cumulé N / N-1</h2>
                    <p>
                      N :{" "}
                      {
                        this.props.data[keyCompany].totalCloseCumul[1][
                          this.props.data[keyCompany].totalCloseCumul[2].length - 1
                        ]
                      }
                    </p>
                    <p>
                      N - 1 :{" "}
                      {
                        this.props.data[keyCompany].totalCloseCumul[0][
                          this.props.data[keyCompany].totalCloseCumul[2].length - 1
                        ]
                      }
                    </p>
                    <p>
                      évol % :{" "}
                      {getPercentageValue(
                        this.props.data[keyCompany].totalCloseCumul[2][
                          this.props.data[keyCompany].totalCloseCumul[2].length - 1
                        ]
                      )}
                    </p>
                  </Col>
                </Row>
              </Card>
            );
          })}
      </FadeIn>
    );
  }
}

export default GeneralCharts;
