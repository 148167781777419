import React, { Component } from "react";
import FadeIn from "react-fade-in";
import {
  Nav,
  TabsCustom,
  TabsCustomBody,
  TabsCustomHeader,
  TabsCustomItem,
  TabsCustomMenu,
  PaginationClass,
  Loader,
} from "ui-kit-ck-consultant";

import ShowOptionsNGRA from "../../../components/general/ShowOptionsNGRA-v2";
import GlobalMonitoring from "../../../components/performances/reparator/GlobalMonitoring";
import TargetedMonitoring from "../../../components/performances/reparator/TargetedMonitoring";
import PrintClass from "../../../components/general/Print";

import axiosConfig from "../../../utils/axiosConfig";
import { alertError } from "../../../utils/errorMessage";

import AuthContext from "../../../context/AuthContext";

export class ReparatorPerformance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabsIndex: 0,
      tabsMenu: ["Suivi Global", "Suivi Ciblé"],
      network: null,
      group: null,
      region: null,
      agency: null,
      company: null,
      user: null,
      garageApproved: "all",
      natureEvent: null,
      garage: null,
      garageIds: {},
      // pagination
      tabLength: 20,
      total: 0,
      start: 0,
      end: 20,
      // LOADER
      isLoad: false,
      // PRINT
      isPrint: false,
      logo: null,
    };

    this.dataPrint = {
      count: 0,
      header: null,
      reparator: null,
    };

    this.refPrintReparatorGlobal = React.createRef();
    this.refPrintReparatorTarget = React.createRef();
    this.refHeader = React.createRef();
  }
  static contextType = AuthContext;

  getGroupIcon = () => {
    if (this.context.group) {
      let logo = localStorage.getItem("logoGroup");
      let group = localStorage.getItem("idGroup");
      if (!logo || parseInt(group) !== parseInt(this.context.group)) {
        axiosConfig("/auth/get-logo").then((res) => {
          if (res.data.success === true) {
            localStorage.setItem("logoGroup", res.data.data);
            localStorage.setItem("idGroup", this.context.group);
            this.setState({ logo: res.data.data });
          }
        });
      } else if (logo) {
        this.setState({ logo });
      }
    }
  };

  initSearch = () => {
    this.setState({ activePage: 1, isLoad: true }, () => {
      this.PaginationClass.init(axiosConfig, this.getData(), (result) => {
        if (result.success === true) {
          this.setState(
            { total: result.total ? result.total : 0, isLoad: true },
            () => {
              this.PaginationClass.get(
                axiosConfig,
                this.getData(),
                (result) => {
                  this.setData(result);
                }
              );
            }
          );
        }
      });
    });
  };

  setData = (result) => {
    if (result.success === true) {
      this.setState({ dataList: result.tabData, isLoad: false });
    } else {
      this.setState({ dataList: [], isLoad: false });
      alertError(result.error);
    }
  };

  getData = () => {
    return {
      network: this.state.network,
      group: this.state.group,
      region: this.state.region,
      agency: this.state.agency,
      user: this.state.user,
      userType:
        this.context.type === 5 || this.context.role === 2 ? [5] : [2, 3],
      dateStart: this.state.dateStart,
      dateEnd: this.state.dateEnd,
      company: this.state.company,
      garageApproved: this.state.garageApproved,
      garage: this.state.garage,
      natureEvent: this.state.natureEvent,
      orderName: this.state.order ? this.state.order.name : null,
      orderDesc: this.state.order ? this.state.order.desc : null,
    };
  };

  handlePageChange = (activePage) => {
    let end = activePage * this.state.tabLength;
    let start = activePage * this.state.tabLength - this.state.tabLength;

    this.PaginationClass.page(activePage, () =>
      this.setState(
        { activePage: activePage, isLoad: true, start, end },
        () => {
          this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
            if (result.success === true) {
              this.setData(result);
            }
          });
        }
      )
    );
  };

  componentDidMount = () => {
    this.PaginationClass = new PaginationClass(
      "performances/reparatorData/count",
      "performances/reparatorData/pagination",
      this.state.tabLength
    );
    this.getGroupIcon();
  };

  handleDataChange = (data) => {
    this.setState(
      {
        network: data.network,
        group: data.group,
        region: data.region,
        agency: data.agency,
        company: data.company,
        user: data.user,
        dateStart: data.dateStart,
        dateEnd: data.dateEnd,
        garageApproved: data.garageApproved,
        garage: data.garage,
        natureEvent: data.natureEvent,
      },
      () => {
        this.getFollowedGaragesIds();
        this.initSearch();
      }
    );
  };

  getFollowedGaragesIds = () => {
    axiosConfig("performances/reparatorData/get-followed-ids", {
      data: {
        agencies: this.state.agency,
      },
    }).then((res) => {
      if (res.data.success) {
        this.setState({ garageIds: res.data.garageIds }, () => {
          if (
            this.state.garageIds &&
            this.state.garageIds["expert"] &&
            this.state.garageIds["expert"].length
          ) {
            this.getFollowedGaragesData(
              this.state.garageIds["expert"],
              "expert"
            );
          }
          if (
            this.state.garageIds &&
            this.state.garageIds["assistant"] &&
            this.state.garageIds["assistant"].length
          ) {
            this.getFollowedGaragesData(
              this.state.garageIds["assistant"],
              "assistant"
            );
          }
        });
      }
    });
  };

  getFollowedGaragesData = (ids, type) => {
    let data = this.getData();
    data.ids = ids;

    axiosConfig("performances/reparatorData/get-followed-data", {
      data,
    }).then((res) => {
      if (res.data.success) {
        if (type === "expert") {
          this.setState({ followedData_expert: res.data.data });
        } else {
          this.setState({ followedData_assistant: res.data.data });
        }
      } else {
        if (type === "expert") {
          this.setState({ followedData_expert: null });
        } else {
          this.setState({ followedData_assistant: null });
        }
      }
    });
  };

  handleSortClick = (order) => {
    this.setState({ order }, () => {
      this.initSearch();
    });
  };

  handleFollowGarage = (id, group) => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("performances/reparatorData/update-followed-garages", {
        data: {
          id: id.toString(),
          group,
          agencies: this.state.agency,
        },
      }).then((res) => {
        if (res.data.success) {
          this.setState({ garageIds: res.data.garageIds, isLoad: false });
        } else {
          this.setState({ isLoad: false });
        }
      });
    });
  };

  getWidthPrint() {
    return {
      width: this.state.isPrint ? "2800px" : "auto",
    };
  }

  render() {
    return (
      <FadeIn>
        <Nav title="Performance Réparateur" className="mb-10 mb-20-xl" />
        <ShowOptionsNGRA
          isChange={true}
          onDataChange={this.handleDataChange}
          isDateStart={true}
          isDateEnd={true}
          isCompany={true}
          visibilityRole={this.props.visibilityRole}
          visibilityType={this.props.visibilityType}
          preloadRole={this.props.preloadRole}
          preloadType={this.props.preloadType}
          limitUserType={this.props.limitUserType}
          limitUserRole={this.props.limitUserRole}
          isNatureEvent={true}
          defaultNatureEvent={[
            1, 2, 3, 4, 6, 7, 8, 9, 10, 11, 12, 13, 15, 16, 17, 18,
          ]}
          isAll={true}
          notAllAgencies={true}
          isFake={this.context.fake}
          userType={
            this.context.fake && this.context.role === 1
              ? [2, 3]
              : this.context.fake && this.context.role === 2
              ? [5]
              : null
          }
          isGarageApproved={true}
          isGarage={true}
        />

        <TabsCustom className={"mt-10 mt-20-xl"}>
          <TabsCustomHeader>
            {this.state.tabsMenu.map((element, idx) => (
              <TabsCustomMenu
                key={idx}
                title={element}
                active={this.state.tabsIndex === idx}
                disabled={idx === 1 && !this.state.agency}
                onClick={() => {
                  this.setState({
                    tabsIndex: idx === 1 && this.state.agency ? idx : 0,
                  });
                }}
              />
            ))}
          </TabsCustomHeader>
          <TabsCustomBody noCard>
            <TabsCustomItem active={this.state.tabsIndex === 0}>
              <div
                ref={this.refPrintReparatorGlobal}
                style={this.getWidthPrint()}
              >
                <GlobalMonitoring
                  data={
                    this.state.dataList &&
                    this.state.dataList.slice(this.state.start, this.state.end)
                  }
                  activePage={this.state.activePage}
                  tabLength={this.state.tabLength}
                  total={this.state.total}
                  onSortClick={this.handleSortClick}
                  onPageChange={this.handlePageChange}
                  onFollowGarage={this.handleFollowGarage}
                  garageIds={this.state.garageIds}
                  agency={this.state.agency}
                  isPrint={this.state.isPrint}
                />
              </div>
            </TabsCustomItem>
            <TabsCustomItem active={this.state.tabsIndex === 1}>
              <div
                ref={this.refPrintReparatorTarget}
                style={this.getWidthPrint()}
              >
                <TargetedMonitoring
                  followedData_expert={this.state.followedData_expert}
                  followedData_assistant={this.state.followedData_assistant}
                />
              </div>
            </TabsCustomItem>
          </TabsCustomBody>
        </TabsCustom>
        <PrintClass
          refs={
            this.state.isPrint
              ? this.state.tabsIndex === 0
                ? [this.refPrintReparatorGlobal]
                : [this.refPrintReparatorTarget]
              : null
          }
          title={"Performance réparateur"}
          fileName={"performance_reparateur"}
          onPrint={(print) => this.setState({ isPrint: print, isLoad: print })}
          width={2800}
        />
        <Loader show={this.state.isLoad} />
      </FadeIn>
    );
  }
}

export default ReparatorPerformance;
