import React, { Component } from "react";
import {
  Card,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  ButtonIcon,
  Loader,
  Pagination,
} from "ui-kit-ck-consultant";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
  faPen,
  faTrash,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import axiosConfig from "../../../utils/axiosConfig";
import handleSortClick from "../../../utils/handleSortClick";
import { getCurrencyValue } from "../../../utils/general";

import ModalVVNR from "./ModalVVNR";

import AuthContext from "../../../context/AuthContext";

export class ListVVNR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalUpdate: false,
      dataModal: {},
      idModal: null,
      isLoad: false,
    };

    this.sortRef = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
    ];
  }

  static contextType = AuthContext;

  handleSortClick = (desc, column, sortRef) => {
    this.setState({ order: handleSortClick(sortRef, desc, column) }, () => {
      this.props.onSortClick(this.state.order);
    });
  };

  getColorStatus(value, label) {
    if (value > 90) {
      return (
        <Td className="nowrap bg-green-light black text-center pl-10 pr-10">{`${value} % ${label}`}</Td>
      );
    } else if (value > 80) {
      return (
        <Td className="nowrap bg-orange-light black text-center pl-10 pr-10">{`${value} % ${label}`}</Td>
      );
    } else {
      return (
        <Td className="nowrap bg-red-light black text-center pl-10 pr-10">{`${value} % ${label}`}</Td>
      );
    }
  }

  delete = (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cet élément ?")) {
      axiosConfig("specialties/VVNR_VVR/delete-VVNR", {
        data: {
          id,
        },
      }).then((res) => {
        if (res.data.success) {
          this.props.getDataList();
        } else {
          alert("Une erreur est survenue");
        }
      });
    }
  };

  getPDF = (id) => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("specialties/VVNR_VVR/get-PDF", {
        data: {
          id,
        },
      }).then((res) => {
        if (res.data.success) {
          this.setState({ isLoad: false }, () => {
            let pdfWindow = window.open("");
            pdfWindow.document.write(
              "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                encodeURI(res.data.data.base64) +
                "'></iframe>"
            );
          });
        } else {
          this.setState({ isLoad: false }, () => {
            alert("Une erreur est survenue");
          });
        }
      });
    });
  };

  render() {
    const tableHead = [
      {
        title: "#",
        sort: false,
      },
      {
        name: "date",
        title: "Date",
        nameSort: "v_date",
        sort: true,
      },
      {
        name: "name",
        title: "Agence",
        nameSort: "a_name",
        sort: true,
      },
      {
        name: "expert",
        title: "Expert",
        nameSort: "u_name",
        sort: true,
      },
      {
        name: "reference",
        title: "Référence",
        nameSort: "fg_reference",
        sort: true,
      },
      {
        title: "Statut",
        sort: false,
      },
      {
        name: "brand",
        title: "Véhicule",
        nameSort: "fi_builder",
        sort: true,
      },
      {
        name: "client",
        title: "Lésé",
        nameSort: "fi_name_client",
        sort: true,
      },
      {
        title: "Label",
        sort: false,
      },
      {
        title: "VRADE HT",
        sort: false,
      },
      {
        title: "Gain HT",
        sort: false,
      },
      {
        title: "Avis",
        sort: false,
      },
      {
        title: "Actions",
        sort: false,
      },
    ];

    return (
      <>
        <Card
          title="Liste"
          className="mb-10 mb-20-xl"
          classNameChildren="overflow-x-auto"
        >
          <Table>
            <Thead>
              <Tr>
                {tableHead.map((elem, idx) => (
                  <Th
                    className="pl-10 pr-10"
                    key={idx}
                    ref={
                      this.props.isAction
                        ? this.sortRefAction[idx]
                        : this.sortRef[idx]
                    }
                    onSort={
                      elem.sort
                        ? (desc) =>
                            this.handleSortClick(
                              desc,
                              idx,
                              this.props.isAction
                                ? this.sortRefAction
                                : this.sortRef
                            )
                        : null
                    }
                    nameSort={elem.nameSort}
                    sort={elem.sort ? true : false}
                  >
                    {elem.title}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {this.props.data.map((element, idx) => (
                <Tr key={idx}>
                  <Th className="pl-10 pr-10 nowrap">{idx + 1}</Th>
                  <Td className="pl-10 pr-10 nowrap">{element.date}</Td>
                  <Td className="pl-10 pr-10 nowrap">{element.name}</Td>
                  <Td className="pl-10 pr-10 nowrap">{element.user}</Td>
                  <Td className="pl-10 pr-10 nowrap">{element.reference}</Td>
                  <Td className="pl-10 pr-10 nowrap">
                    {element.data.typeOwner === "1" ? "Particulier" : "Société"}
                  </Td>
                  <Td className="pl-10 pr-10 nowrap">
                    {element.brand} {element.model}
                  </Td>
                  <Td className="pl-10 pr-10">{element.client}</Td>
                  {this.getColorStatus(element.percentage, element.label)}
                  <Td className="pl-10 pr-10">
                    {getCurrencyValue(element.VRADEDutyFree)}
                  </Td>
                  <Td className="pl-10 pr-10">
                    {element.gainDutyFree
                      ? getCurrencyValue(element.gainDutyFree)
                      : ""}
                  </Td>
                  <Td className="pl-10 pr-10" style={{ minWidth: "240px" }}>
                    {element.data.avisDossier}
                  </Td>
                  <Td className="pl-10 pr-10 nowrap">
                    {this.context.id === element.userId ||
                    this.context.role === 4 ? (
                      <ButtonIcon
                        className="blue"
                        small
                        onClick={() =>
                          this.setState({
                            isModalUpdate: true,
                            dataModal: element.data,
                            idModal: element.id,
                          })
                        }
                      >
                        <FontAwesomeIcon icon={faPen} />
                      </ButtonIcon>
                    ) : null}
                    <ButtonIcon
                      className="green"
                      small
                      onClick={() => {
                        this.getPDF(element.id);
                      }}
                    >
                      <FontAwesomeIcon icon={faFilePdf} />
                    </ButtonIcon>

                    {this.context.id === element.userId ||
                    this.context.role === 4 ? (
                      <ButtonIcon
                        className="red"
                        small
                        onClick={() => this.delete(element.id)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </ButtonIcon>
                    ) : null}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Pagination
            activePage={this.props.activePage}
            itemsCountPerPage={this.props.tabLength}
            totalItemsCount={this.props.total}
            pageRangeDisplayed={4}
            onChange={this.props.onPageChange.bind(this)}
            firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
            lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
            prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
            nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
          />
        </Card>
        <ModalVVNR
          show={this.state.isModalUpdate}
          data={this.state.dataModal}
          id={this.state.idModal}
          isUpdate={true}
          onClose={() =>
            this.setState(
              {
                isModalUpdate: false,
                dataModal: {},
                idModal: null,
              },
              this.props.getDataList
            )
          }
        />
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}

export default ListVVNR;
