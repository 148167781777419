import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import {
  Card,
  DoughnutChart,
} from "ui-kit-ck-consultant";

import {
  getCurrencyValue,
  getValue,
} from "../../../utils/general";

import AuthContext from "../../../context/AuthContext";

export default class OverallDay extends Component {
  static contextType = AuthContext;

  render() {
    return (
      <Row>
        <Col xs={12} md={6} className={"mb-10 mb-20-xl"}>
          <Card title="Rendement du jour">
            <DoughnutChart
              className="m-auto"
              number={this.props.data.yield.yield}
              text={`${getValue(this.props.data.yield.closing, 0)} / ${getValue(
                this.props.data.yield.mission,
                0
              )}`}
            />
          </Card>
        </Col>
        <Col xs={12} md={6} className={"mb-10 mb-20-xl"}>
          <Card title="% d'atteinte du CA du jour">
            <DoughnutChart
              className="m-auto"
              number={this.props.data.CA.yield}
              text={`${getCurrencyValue(this.props.data.CA.total, 0)} / ${getCurrencyValue(
                this.props.data.CA.goal,
                0
              )}`}
            />
          </Card>
        </Col>
      </Row>
    );
  }
}
