const repairTimeTable = [
  {
    id: "repairTime",
    name: "1. DÉLAIS REPARATION (EAD + REP)",
    unit: "dossier",
    title: true,
    evolReversed: true,
  },
  {
    id: "orientationRate",
    name: "% d'orientation gge agréé",
    unit: "%",
    evolReversed: true,
  },
  {
    id: "fieldExpertise",
    name: "Expertise terrain (REP)",
    unit: "dossier",
    title: true,
    evolReversed: true,
  },
  {
    id: "fieldExpertiseVisitMission",
    name: "Délai OM - Expertise",
    unit: "j.",
  },
  {
    id: "fieldExpertiseSD29Visit",
    name: "Expertise - 1er Chiffrage Expert (SD29)",
    unit: "j.",
  },
  {
    id: "fieldExpertiseBillSD29",
    name: "Délai 1er chiffrage - Réception de facture",
    unit: "j.",
  },
  {
    id: "fieldExpertiseClosingBill",
    name: "Délai Réception de facture - Dépôt du rapport",
    unit: "j.",
  },
  {
    id: "fieldExpertiseClosingMission",
    name: "Délai OM - Rapport",
    unit: "j.",
  },
  {
    id: "eadApproved",
    name: "EAD agréé",
    unit: "dossier",
    title: true,
    evolReversed: true,
  },
  {
    id: "eadApprovedReceivedMission",
    name: "Délai OM - Réception Photos",
    unit: "j.",
  },
  {
    id: "eadApprovedSD29Received",
    name: "Réception Photos - 1er Chiffrage Expert (SD29)",
    unit: "j.",
  },
  {
    id: "eadApprovedBillSD29",
    name: "Délai 1er chiffrage - Réception de facture",
    unit: "j.",
  },
  {
    id: "eadApprovedClosingBill",
    name: "Délai Réception de facture - Dépôt du rapport",
    unit: "j.",
  },
  {
    id: "eadApprovedClosingMission",
    name: "Délai OM - Rapport",
    unit: "j.",
  },
  {
    id: "eadApprovedGain",
    name: "Gain",
    unit: "€",
    evolReversed: true,
  },
  {
    id: "eadApprovedRate",
    name: "% EAD REP agréé",
    unit: "%",
    evolReversed: true,
  },
  {
    id: "eadUnapproved",
    name: "EAD non agréé",
    unit: "dossier",
    title: true,
    evolReversed: true,
  },
  {
    id: "eadUnapprovedReceivedMission",
    name: "Délai OM - Réception Photos",
    unit: "j.",
  },
  {
    id: "eadUnapprovedSD29Received",
    name: "Réception Photos - 1er Chiffrage Expert (SD29)",
    unit: "j.",
  },
  {
    id: "eadUnapprovedBillSD29",
    name: "Délai 1er chiffrage - Réception de facture",
    unit: "j.",
  },
  {
    id: "eadUnapprovedClosingBill",
    name: "Délai Réception de facture - Dépôt du rapport",
    unit: "j.",
  },
  {
    id: "eadUnapprovedClosingMission",
    name: "Délai OM - Rapport",
    unit: "j.",
  },
  {
    id: "eadUnapprovedGain",
    name: "Gain",
    unit: "€",
    evolReversed: true,
  },
  {
    id: "eadUnapprovedRate",
    name: "% EAD non agréé",
    unit: "%",
    evolReversed: true,
  },
  {
    id: "ead",
    name: "EAD global (agréé + non agréé)",
    unit: "dossier",
    title: true,
    evolReversed: true,
  },
  {
    id: "eadReceivedMission",
    name: "Délai OM - Réception Photos",
    unit: "j.",
  },
  {
    id: "eadSD29Received",
    name: "Réception Photos - 1er Chiffrage Expert (SD29)",
    unit: "j.",
  },
  {
    id: "eadBillSD29",
    name: "Délai 1er chiffrage -  Réception de facture",
    unit: "j.",
  },
  {
    id: "eadClosingBill",
    name: "Délai Réception de facture - Dépôt du rapport",
    unit: "j.",
  },
  {
    id: "eadClosingMission",
    name: "Délai OM - Rapport",
    unit: "j.",
  },
  {
    id: "eadGain",
    name: "Gain",
    unit: "€",
    evolReversed: true,
  },
  {
    id: "eadRate",
    name: "% EAD global",
    unit: "%",
    evolReversed: true,
  },
];

const totalLossTimeTable = [
  {
    id: "totalLossTime",
    name: "2. DÉLAIS PERTE TOTALE NON REP (CONV - AO) ",
    unit: "dossier",
    title: true,
    evolReversed: true,
  },
  {
    id: "lossVisitMission",
    name: "Délai OM - Expertise",
    unit: "j.",
  },
  {
    id: "lossSD29Visit",
    name: "Expertise - 1er Chiffrage Expert (SD29)",
    unit: "j.",
  },
  {
    id: "lossConvention",
    name: "Convention",
    unit: "dossier",
    title: true,
    evolReversed: true,
  },
  {
    id: "lossConventionClosingSD29",
    name: "Délai 1er chiffrage - Rapport",
    unit: "j.",
  },
  {
    id: "lossConventionClosingMission",
    name: "Délai OM - Rapport",
    unit: "j.",
  },
  {
    id: "lossOffer",
    name: "Appel d'offre",
    unit: "dossier",
    title: true,
    evolReversed: true,
  },
  {
    id: "lossOfferClosingSD29",
    name: "Délai 1er chiffrage - Rapport",
    unit: "j.",
  },
  {
    id: "lossOfferClosingMission",
    name: "Délai OM - Rapport",
    unit: "j.",
  },
  {
    id: "lossGlobal",
    name: "Global (Convention + AO)",
    unit: "dossier",
    title: true,
    evolReversed: true,
  },
  {
    id: "lossGlobalClosingSD29",
    name: "Délai 1er chiffrage - Rapport",
    unit: "j.",
  },
  {
    id: "lossGlobalClosingMission",
    name: "Délai OM - Rapport",
    unit: "j.",
  },
  {
    id: "lossGlobalSD09Closing",
    name: "Délai Rapport - SD09 (indicateur client)",
    unit: "j.",
  },
];

const techniqueTable = [
  {
    id: "approvedRep",
    name: "REP agréé",
    unit: "dossier",
    title: true,
    evolReversed: true,
  },
  {
    id: "approvedRepPRE",
    name: "Tx de pièces de réemploi",
    unit: "%",
    evolReversed: true,
  },
  {
    id: "approvedRepRP",
    name: "Tx de réparations vs remplacements",
    unit: "%",
    evolReversed: true,
  },
  {
    id: "approvedRepHourlyRateM",
    name: "Coût moyen réparation M.O.",
    unit: "€",
  },
  {
    id: "approvedRepPieceDutyFree",
    name: "Coût moyen des pièces",
    unit: "€",
  },
  {
    id: "approvedRepHourlyRatePI",
    name: "Coût moyen peinture",
    unit: "€",
  },
  {
    id: "approvedRepHourlyRateMBill",
    name: "% M.O. sur facture ",
    unit: "%",
    evolReversed: true,
  },
  {
    id: "approvedRepPieceDutyFreeBill",
    name: "% Pièces sur facture",
    unit: "%",
  },
  {
    id: "approvedRepHourlyRatePIBill",
    name: "% IP sur facture",
    unit: "%",
  },
  {
    id: "approvedRepCancelReport",
    name: "Nbre de rapports en annule & remplace",
    unit: "r.",
  },
  {
    id: "approvedRepHourM",
    name: "Nbre heures M.O.",
    unit: "h.",
  },
  {
    id: "approvedRepHourIP",
    name: "Nbre heures IP",
    unit: "h.",
  },
  {
    id: "unapprovedRep",
    name: "REP non agréé",
    unit: "dossier",
    title: true,
    evolReversed: true,
  },
  {
    id: "unapprovedRepPRE",
    name: "Tx de pièces de réemploi",
    unit: "%",
    evolReversed: true,
  },
  {
    id: "unapprovedRepRP",
    name: "Tx de réparations vs remplacements",
    unit: "%",
    evolReversed: true,
  },
  {
    id: "unapprovedRepHourlyRateM",
    name: "Coût moyen réparation M.O.",
    unit: "€",
  },
  {
    id: "unapprovedRepPieceDutyFree",
    name: "Coût moyen des pièces",
    unit: "€",
  },
  {
    id: "unapprovedRepHourlyRatePI",
    name: "Coût moyen peinture",
    unit: "€",
  },
  {
    id: "unapprovedRepHourlyRateMBill",
    name: "% M.O. sur facture ",
    unit: "%",
    evolReversed: true,
  },
  {
    id: "unapprovedRepPieceDutyFreeBill",
    name: "% Pièces sur facture",
    unit: "%",
  },
  {
    id: "unapprovedRepHourlyRatePIBill",
    name: "% IP sur facture",
    unit: "%",
  },
  {
    id: "unapprovedRepCancelReport",
    name: "Nbre de rapports en annule & remplace",
    unit: "r.",
  },
  {
    id: "unapprovedRepHourM",
    name: "Nbre heures M.O.",
    unit: "h.",
  },
  {
    id: "unapprovedRepHourIP",
    name: "Nbre heures IP",
    unit: "h.",
  },
  {
    id: "globalRep",
    name: "Global (REP agréé & non agréé)",
    unit: "dossier",
    title: true,
    evolReversed: true,
  },
  {
    id: "globalRepPRE",
    name: "Tx de pièces de réemploi",
    unit: "%",
    evolReversed: true,
  },
  {
    id: "globalRepRP",
    name: "Tx de réparations vs remplacements",
    unit: "%",
    evolReversed: true,
  },
  {
    id: "globalRepHourlyRateM",
    name: "Coût moyen réparation M.O.",
    unit: "€",
  },
  {
    id: "globalRepPieceDutyFree",
    name: "Coût moyen des pièces",
    unit: "€",
  },
  {
    id: "globalRepHourlyRatePI",
    name: "Coût moyen peinture",
    unit: "€",
  },
  {
    id: "globalRepHourlyRateMBill",
    name: "% M.O. sur facture ",
    unit: "%",
    evolReversed: true,
  },
  {
    id: "globalRepPieceDutyFreeBill",
    name: "% Pièces sur facture",
    unit: "%",
  },
  {
    id: "globalRepHourlyRatePIBill",
    name: "% IP sur facture",
    unit: "%",
  },
  {
    id: "globalRepCancelReport",
    name: "Nbre de rapports en annule & remplace",
    unit: "r.",
  },
  {
    id: "globalRepHourM",
    name: "Nbre heures M.O.",
    unit: "h.",
  },
  {
    id: "globalRepHourIP",
    name: "Nbre heures IP",
    unit: "h.",
  },
];

const avgCostTable = [
  {
    id: "avgCostTotalReport",
    name: "Coût moyen réparation",
    unit: "€",
  },
  {
    id: "avgCostTotalReportEAD",
    name: "Coût moyen en réparation EAD",
    unit: "€",
  },
  {
    id: "avgCostTotalReportApproved",
    name: "Coût moyen en réparation Agréé",
    unit: "€",
  },
  {
    id: "avgCostTotalReportUnapproved",
    name: "Coût moyen en réparation Non Agréé",
    unit: "€",
  },
  {
    id: "avgCostTotalReportRange",
    name: "Coût moyen réparation 200 à 5000 € HT",
    unit: "€",
  },
  {
    id: "avgCostTotalReportRangeApproved",
    name: "Coût moyen réparation Agréé 200 à 5000 € HT",
    unit: "€",
  },
  {
    id: "avgCostTotalReportRangeUnapproved",
    name: "Coût moyen réparation Non Agréé 200 à 5000 € HT",
    unit: "€",
  },
  {
    id: "avgCostTotalReportVRADE12000",
    name: "Coût moyen VRADE écrétée à 12 000 € TTC",
    unit: "€",
  },
  {
    id: "avgCostTotalReportVRADE",
    name: "Coût moyen VRADE",
    unit: "€",
  },
  {
    id: "avgCostTotalReportTotalLossRate",
    name: "% VEI/REP",
    unit: "%",
  },
  {
    id: "avgCostTotalReportDutyFreeVRADE",
    name: "Coût moyen REP + PT",
    unit: "€",
  },
  {
    id: "avgCostTotalReportVehicleAge",
    name: "Âge moyen du parc expertisé (indicateur REP)",
    unit: "a.",
  },
];

export { repairTimeTable, totalLossTimeTable, techniqueTable, avgCostTable };
