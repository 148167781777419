import React, { Component } from 'react'
import { Col, Row } from 'react-flexbox-grid'
import { BarChart, DoughnutChart } from 'ui-kit-ck-consultant'

export class SatisfactionCard extends Component {
  render() {
    return (
      <>
      {this.props.data && this.props.data.answerRate && this.props.data.globalAverage && this.props.data.dataMonths ?
          <Row>
            <Col md={3} style={{display: "flex"}}>
              <DoughnutChart className="m-auto" number={this.props.data.globalAverage} text="Note globale" max={20} unit={'/20'} /><br />
            </Col>
            <Col md={3} style={{display: "flex"}}>
              <DoughnutChart className="m-auto" number={this.props.data.answerRate} text="Taux de reponse" max={100} /><br />
            </Col>
            <Col md={6}>
              <BarChart
                height={this.props.height}
                title={'Satisfaction Mois / Mois'} 
                digits={0}
                legendDisplay={false}
                dataSet={[
                  {
                    label: "Moyenne (/20)",
                    data: this.props.data.dataMonths,
                    type: "line",
                    color: '#6067AE',
                  },
                ]}
                datalabels2={[
                  {
                    display: true,
                    align: 'bottom',
                    anchor: 'end',
                    clamp: true,
                    color: '#0162AD',
                    font: {
                      size: '8',
                    },
                    formatter: (value) => Math.round(value)
                  }
                ]}
                yAxes = {[
                  {
                    display: false,
                    ticks: {
                      max: 22,
                      min: 0,
                      beginAtZero: true
                    },
                  }
                ]}
              />
            </Col>
          </Row>
      : null }
      </>
    )
  }
}

export default SatisfactionCard
