import React, { Component } from "react";
import { Nav, Loader } from "ui-kit-ck-consultant";
import FadeIn from "react-fade-in/lib/FadeIn";

import ShowOptionsNGRA from "../../../components/general/ShowOptionsNGRA-v2";

import MainFigures from "../../../components/specialties/caravaning/MainFigures";
import TreatmentDelays from "../../../components/specialties/caravaning/TreatmentDelays";
import AverageAgeOfCurrentCases from "../../../components/specialties/caravaning/AverageAgeOfCurrentCases";
import DetailOfVolumetriesAndCost from "../../../components/specialties/caravaning/DetailOfVolumetriesAndCost";
import SavingsElectric from "../../../components/specialties/caravaning/SavingsElectric";

import axiosConfig from "../../../utils/axiosConfig";

export class Electric extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      mainFigures: [],
      treatmentDelays: [],
      averageAgeOfCurrentCases: [],
      detailOfVolumetriesAndCost: [],
      savings: [],
    };
  }

  handleDataChange = (data) => {
    this.setState(
      {
        year: data.year,
        network: data.network,
        group: data.group,
        region: data.region,
        agency: data.agency,
        user: data.user,
        company: data.company,
      },
      this.getData
    );
  };

  getData = () => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        axiosConfig("specialties/electric/get-data", {
          data: {
            year: this.state.year,
            network: this.state.network,
            group: this.state.group,
            region: this.state.region,
            agency: this.state.agency,
            user: this.state.user,
            company: this.state.company,
          },
        })
          .then((res) => {
            if (res.data.success) {
              this.setState({
                isLoad: false,
                mainFigures: res.data.data.mainFigures,
                treatmentDelays: res.data.data.treatmentDelays,
                averageAgeOfCurrentCases:
                  res.data.data.averageAgeOfCurrentCases,
                detailOfVolumetriesAndCost:
                  res.data.data.detailOfVolumetriesAndCost,
                savings: res.data.data.savings,
              });
            } else {
              this.setState({
                isLoad: false,
                mainFigures: [],
                treatmentDelays: [],
                averageAgeOfCurrentCases: [],
                detailOfVolumetriesAndCost: [],
                savings: [],
              });
            }
          })
          .catch(() => {
            this.setState({
              isLoad: false,
              mainFigures: [],
              treatmentDelays: [],
              averageAgeOfCurrentCases: [],
              detailOfVolumetriesAndCost: [],
              savings: [],
            });
          });
      }
    );
  };

  render() {
    return (
      <>
        <FadeIn>
          <Nav title="Électrique" className="mb-10 mb-20-xl" />
          <ShowOptionsNGRA
            isChange={true}
            className="mb-10 mb-20-xl"
            onDataChange={this.handleDataChange}
            isYear={true}
            isUser={true}
            isCompany={true}
            visibilityRole={this.props.visibilityRole}
            visibilityType={this.props.visibilityType}
            preloadRole={this.props.preloadRole}
            preloadType={this.props.preloadType}
            limitUserType={this.props.limitUserType}
            limitUserRole={this.props.limitUserRole}
            userType={[1, 2, 3]}
            isDefaultAllUser={true}
          />
          <MainFigures
            year={this.state.year}
            data={this.state.mainFigures}
            isElectric={true}
          />
          <TreatmentDelays
            year={this.state.year}
            data={this.state.treatmentDelays}
          />
          <AverageAgeOfCurrentCases
            year={this.state.year}
            data={this.state.averageAgeOfCurrentCases}
          />
          <DetailOfVolumetriesAndCost
            year={this.state.year}
            data={this.state.detailOfVolumetriesAndCost}
            isElec={true}
          />
          <SavingsElectric year={this.state.year} data={this.state.savings} />
        </FadeIn>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}

export default Electric;
