import React, { Fragment } from "react";
import {
  Card,
  Nav,
  Table,
  Thead,
  Tbody,
  Th,
  Td,
  Tr,
  FormInput,
  Button,
  Loader,
  FormSelectMultiples,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import FadeIn from "react-fade-in";

import axiosConfig from "../../utils/axiosConfig";
import handleSortClick from "../../utils/handleSortClick";

import AuthContext from "../../context/AuthContext";

import { getCurrencyValue } from "../../utils/general";

const networks = [
  "Filiale ou succursale",
  "Concessionnaire de la marque",
  "Agent de la marque",
  "MRA (Mécanicien Réparateur Auto)",
  "Autres",
];

export default class CompetitiveCost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      reference: "",
      zipCode: "",
      distance: "20",
      garages: [],
      folderGarage: null,
      networks: [],
      order: {
        name: "name",
        desc: true,
      },
      isFull: false,
    };

    this.sortRef = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
    ];
  }

  static contextType = AuthContext;

  componentDidMount() {}

  handleSortClick = (desc, column) => {
    this.setState({ order: handleSortClick(this.sortRef, desc, column) });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  getGarages = () => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("competitive-cost/get-garages", {
        data: {
          reference: this.state.reference,
          zipCode: this.state.zipCode,
          distance: this.state.distance,
          networks: this.state.networks,
        },
      })
        .then((res) => {
          if (res.data.success === true) {
            this.setState({
              isLoad: false,
              garages: res.data.garages,
              folderGarage: res.data.folderGarage,
            });
          } else {
            this.setState(
              {
                isLoad: false,
                garages: [],
                folderGarage: null,
              },
              () => {
                if (res.data.message) {
                  alert(res.data.message);
                }
              }
            );
          }
        })
        .catch(() =>
          this.setState(
            {
              isLoad: false,
              garages: [],
            },
            () => alert("Une erreur est survenue")
          )
        );
    });
  };

  getCardValue = (name) => {
    let min_idx = -1;
    let max_idx = -1;
    let min = 0;
    let max = 0;
    let average = 0;
    let average20 = 0;
    let count = 0;

    this.state.garages.forEach((garage, idx) => {
      if (garage[name]) {
        let filterMin = 30;
        if (["T1", "T2", "T3", "M1", "M2", "M3", "P1", "P2"].includes(name)) {
          filterMin = 50;
        }

        if (garage[name] >= filterMin) {
          if (!min || min > garage[name]) {
            min_idx = idx;
            min = garage[name];
          }
          if (max < garage[name]) {
            max_idx = idx;
            max = garage[name];
          }
          average += garage[name];
          count++;
        }
      }
    });

    average = average / count;
    average20 = average * 1.2;

    return (
      <Col xs={12} md={4} lg={3}>
        <Card className="mb-10 mb-20-xl" title={name}>
          <p className="green">
            Le moins cher :{" "}
            {min_idx >= 0 ? this.state.garages[min_idx].name : ""} (
            {getCurrencyValue(min)})
          </p>
          <p className="red">
            Le plus cher :{" "}
            {max_idx >= 0 ? this.state.garages[max_idx].name : ""} (
            {getCurrencyValue(max)})
          </p>
          <p className="blue">Le prix moyen : ({getCurrencyValue(average)})</p>
          <p className="orange">
            Le prix moyen + 20% : ({getCurrencyValue(average20)})
          </p>
        </Card>
      </Col>
    );
  };

  render() {
    const isFull = this.state.isFull;
    let garages = this.state.garages;

    const folderGarage = this.state.folderGarage;

    garages.sort((a, b) => {
      let aX = a[this.state.order.name] || "";
      let bX = b[this.state.order.name] || "";

      if (typeof aX == "number" && typeof bX == "number") {
        return this.state.order.desc ? aX - bX : bX - aX;
      } else {
        aX = aX.toString();
        bX = bX.toString();
        return this.state.order.desc
          ? aX.normalize().localeCompare(bX.normalize())
          : bX.normalize().localeCompare(aX.normalize());
      }
    });

    return (
      <>
        <Nav className="mb-10 mb-30-xl" title="Coût concurrentiel" />
        <FadeIn>
          <Card className="mb-10 mb-20-xl">
            <Row>
              <Col xs={12} md={2}>
                <FormInput
                  title="Référence"
                  value={this.state.reference}
                  name="reference"
                  onChange={this.handleInputChange}
                />
              </Col>
              <Col xs={12} md={2}>
                <FormInput
                  title="Code postal"
                  value={this.state.zipCode}
                  name="zipCode"
                  onChange={this.handleInputChange}
                />
              </Col>
              <Col xs={12} md={2}>
                <FormInput
                  title="Distance"
                  type="number"
                  value={this.state.distance}
                  name="distance"
                  onChange={this.handleInputChange}
                />
              </Col>
              <Col xs={12} md={2}>
                <FormSelectMultiples
                  title="Réseaux"
                  value={this.state.networks}
                  default="Tous les réseaux"
                  options={networks.map((network, idx) => ({
                    value: idx,
                    text: network,
                  }))}
                  onChange={(value) =>
                    this.setState({
                      networks: [...value],
                    })
                  }
                />
              </Col>
              <Col xs={12} md={4} className="d-flex">
                <Button
                  className="mt-auto"
                  text="Rechercher"
                  onClick={() => this.getGarages()}
                />
              </Col>
            </Row>
          </Card>
          <Row>
            {[
              "M1",
              "M2",
              "M3",
              "T1",
              "T2",
              "T3",
              "P1",
              "P2",
              "ingOpaque",
              "ingVernis",
              "ingNacre",
            ].map((name, idx) => (
              <Fragment key={idx}>{this.getCardValue(name)}</Fragment>
            ))}
          </Row>
          <Card
            className="mb-10 mb-20-xl overflow-x-auto"
            title={`Réparateurs (${this.state.garages.length})`}
            action={
              <Button
                onClick={() => this.setState({ isFull: !isFull })}
                outline
                text={
                  isFull
                    ? "Minimiser les colonnes"
                    : "Afficher toutes les colonnes"
                }
              />
            }
          >
            <Table>
              <Thead>
                <Tr>
                  <Th
                    onSort={(desc) => this.handleSortClick(desc, 0)}
                    ref={this.sortRef[0]}
                    sort
                    nameSort="name"
                  >
                    Nom
                  </Th>
                  {isFull ? <Th>Adresse</Th> : null}
                  <Th>CP</Th>
                  <Th>Ville</Th>
                  {isFull ? <Th>E-mail</Th> : null}
                  {isFull ? <Th>Téléphone</Th> : null}
                  <Th
                    onSort={(desc) => this.handleSortClick(desc, 1)}
                    ref={this.sortRef[1]}
                    sort
                    nameSort="T1"
                  >
                    M1
                  </Th>
                  <Th
                    onSort={(desc) => this.handleSortClick(desc, 2)}
                    ref={this.sortRef[2]}
                    sort
                    nameSort="T2"
                  >
                    M2
                  </Th>
                  <Th
                    onSort={(desc) => this.handleSortClick(desc, 3)}
                    ref={this.sortRef[3]}
                    sort
                    nameSort="T3"
                  >
                    M3
                  </Th>
                  <Th
                    onSort={(desc) => this.handleSortClick(desc, 4)}
                    ref={this.sortRef[4]}
                    sort
                    nameSort="M1"
                  >
                    T1
                  </Th>
                  <Th
                    onSort={(desc) => this.handleSortClick(desc, 5)}
                    ref={this.sortRef[5]}
                    sort
                    nameSort="M2"
                  >
                    T2
                  </Th>
                  <Th
                    onSort={(desc) => this.handleSortClick(desc, 6)}
                    ref={this.sortRef[6]}
                    sort
                    nameSort="M3"
                  >
                    T3
                  </Th>
                  <Th
                    onSort={(desc) => this.handleSortClick(desc, 7)}
                    ref={this.sortRef[7]}
                    sort
                    nameSort="P1"
                  >
                    P1
                  </Th>
                  <Th
                    onSort={(desc) => this.handleSortClick(desc, 8)}
                    ref={this.sortRef[8]}
                    sort
                    nameSort="P2"
                  >
                    P2
                  </Th>
                  <Th sort>Ing. Opaque</Th>
                  <Th sort>Ing. Vernis</Th>
                  <Th sort>Ing. Nacre</Th>
                  {isFull ? <Th>Réseau</Th> : null}
                  <Th
                    onSort={(desc) => this.handleSortClick(desc, 9)}
                    ref={this.sortRef[9]}
                    sort
                    nameSort="distance"
                  >
                    Distance
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {folderGarage ? (
                  <Tr>
                    <Th className="bg-blue-light">{folderGarage.name}</Th>
                    {isFull ? (
                      <Th>
                        {folderGarage.address1} {folderGarage.address2}
                      </Th>
                    ) : null}
                    <Th className="bg-blue-light">{folderGarage.zipCode}</Th>
                    <Th className="bg-blue-light">{folderGarage.city}</Th>
                    {isFull ? (
                      <Th className="bg-blue-light">{folderGarage.email}</Th>
                    ) : null}
                    {isFull ? (
                      <Th className="bg-blue-light">{folderGarage.phone}</Th>
                    ) : null}
                    <Td className="bg-blue-light">
                      {getCurrencyValue(folderGarage.T1)}
                    </Td>
                    <Td className="bg-blue-light">
                      {getCurrencyValue(folderGarage.T2)}
                    </Td>
                    <Td className="bg-blue-light">
                      {getCurrencyValue(folderGarage.T3)}
                    </Td>
                    <Td className="bg-blue-light">
                      {getCurrencyValue(folderGarage.M1)}
                    </Td>
                    <Td className="bg-blue-light">
                      {getCurrencyValue(folderGarage.M2)}
                    </Td>
                    <Td className="bg-blue-light">
                      {getCurrencyValue(folderGarage.M3)}
                    </Td>
                    <Td className="bg-blue-light">
                      {getCurrencyValue(folderGarage.P1)}
                    </Td>
                    <Td className="bg-blue-light">
                      {getCurrencyValue(folderGarage.P2)}
                    </Td>
                    <Td className="bg-blue-light">
                      {getCurrencyValue(folderGarage.ingOpaque)}
                    </Td>
                    <Td className="bg-blue-light">
                      {getCurrencyValue(folderGarage.ingVernis)}
                    </Td>
                    <Td className="bg-blue-light">
                      {getCurrencyValue(folderGarage.ingNacre)}
                    </Td>
                    {isFull ? <Td>{networks[folderGarage.network]}</Td> : null}
                    <Td className="bg-blue-light">{folderGarage.distance}</Td>
                  </Tr>
                ) : null}
                {garages.map((garage, idx) => (
                  <Tr key={idx}>
                    <Th>{garage.name}</Th>
                    {isFull ? (
                      <Th>
                        {garage.address1} {garage.address2}
                      </Th>
                    ) : null}
                    <Th>{garage.zipCode}</Th>
                    <Th>{garage.city}</Th>
                    {isFull ? <Th>{garage.email}</Th> : null}
                    {isFull ? <Th>{garage.phone}</Th> : null}
                    <Td>{getCurrencyValue(garage.T1)}</Td>
                    <Td>{getCurrencyValue(garage.T2)}</Td>
                    <Td>{getCurrencyValue(garage.T3)}</Td>
                    <Td>{getCurrencyValue(garage.M1)}</Td>
                    <Td>{getCurrencyValue(garage.M2)}</Td>
                    <Td>{getCurrencyValue(garage.M3)}</Td>
                    <Td>{getCurrencyValue(garage.P1)}</Td>
                    <Td>{getCurrencyValue(garage.P2)}</Td>
                    <Td>{getCurrencyValue(garage.ingOpaque)}</Td>
                    <Td>{getCurrencyValue(garage.ingVernis)}</Td>
                    <Td>{getCurrencyValue(garage.ingNacre)}</Td>
                    {isFull ? <Td>{networks[garage.network]}</Td> : null}
                    <Td>{garage.distance}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Card>
        </FadeIn>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
