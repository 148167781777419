import React, { Component } from "react";

export default class Input extends Component {
  render() {
    return (
      <div>
        <input
          className="cursor-pointer"
          style={this.props.style}
          type={this.props.type}
          value={this.props.value}
          onClick={(e) => e.currentTarget.showPicker()}
          onChange={(event) => this.props.onChange(event.target.value)}
        />
      </div>
    );
  }
}
