import React, { Component } from "react";
import {
  TabsCustom,
  TabsCustomBody,
  TabsCustomHeader,
  TabsCustomItem,
  TabsCustomMenu,
} from "ui-kit-ck-consultant";
import CardEvolution from "../general/CardEvolution";
import CardEvolutionSixValue from "../general/CardEvolutionSixValue";

import {colors} from "../../utils/colors"

export default class EvolutionDisplay extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menuTabs: this.props.type === 3
        ? [
          "Évolution suivi statistique R",
          "Évolution suivi statistique NR",
        ]
        : this.props.type === 2
          ? [
            "Évolution suivi statistique R",
            "Évolution suivi précision R"
          ]
          : [
            "Évolution suivi statistique R",
            "Évolution suivi précision R",
            "Évolution suivi statistique NR",
          ],
      index: 0,
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.isPrint !== this.props.isPrint) {
      setTimeout(() => this.setState({chartLabelsLoaded :  this.props.isPrint}, () => this.props.onLabelsLoaded(this.state.chartLabelsLoaded)), 50)
    }
  }

  setData = () => {
    let data, dataTwo, dataThree, dataFour, dataFive, dataSix
    if (this.state.index === 0 && this.props.chart) {

      data = this.props.type === 2 ? null : {
        title: "Âge véhicule / Coût réparation",
        legendDisplay: true,
        data: [
          {
            type: "line",
            label: "Age véhicule",
            data: this.props.chart.evolution_reparable.reparation_age && this.props.chart.evolution_reparable.reparation_age.age,
            color: colors["red"][0],
            yAxisID: 'A'
          },
          {
            type: "bar",
            label: "Coût réparation en €",
            data: this.props.chart.evolution_reparable.reparation_age && this.props.chart.evolution_reparable.reparation_age.cout_moyen,
            color: colors["blue"][0],
            yAxisID: 'B'
          },
        ],
      }
      dataTwo = this.props.type === 3 || this.props.type === 2 ? null : {
        title: "Taux PRE / Coût pièces",
        legendDisplay: true,
        data: [
          {
            type: "line",
            label: "Taux PRE en %",
            data: this.props.chart.evolution_reparable["pieces-pre"] && this.props.chart.evolution_reparable["pieces-pre"].taux_pre,
            color: colors["red"][0],
            yAxisID: 'A'
          },
          {
            type: "bar",
            label: "Coût pièces en €",
            data: this.props.chart.evolution_reparable["pieces-pre"] && this.props.chart.evolution_reparable["pieces-pre"].cout_pieces,
            color: colors["blue"][0],
            yAxisID: 'B'
          },
        ],
      }
      dataThree = this.props.type === 3? null : {
        title: "Heures sans peinture / Heures de peinture",
        legendDisplay: true,
        data: [
          {
            type: "bar",
            label: "Heures sans peinture",
            data: this.props.chart.evolution_reparable['sans_peinture-peinture'] && this.props.chart.evolution_reparable['sans_peinture-peinture'].sans_peinture,
            color: colors["red"][0],
            yAxisID: 'B',
          },
          {
            type: "bar",
            label: "Heures de peinture",
            data: this.props.chart.evolution_reparable['sans_peinture-peinture'] && this.props.chart.evolution_reparable['sans_peinture-peinture'].peinture,
            color: colors["blue"][0],
            yAxisID: 'B',
          },
        ],
      }
      dataFour = this.props.type === 2 ? null : {
        title: "Délai mission-visite / visite-dépôt",
        legendDisplay: true,
        data: [
          {
            type: "line",
            label: "Délai mission dépôt",
            data: this.props.chart.evolution_reparable["delai-depot"] && this.props.chart.evolution_reparable["delai-depot"]["delai_depot"],
            color: colors["red"][0],
            yAxisID: 'A',
          },
          {
            type: "bar",
            label: "Délai mission visite",
            data: this.props.chart.evolution_reparable["delai-depot"] && this.props.chart.evolution_reparable["delai-depot"]["mission-visite"],
            color: colors["blue"][0],
            yAxisID: 'B',
          },
          {
            type: "bar",
            label: "Délai visite dépôt",
            data: this.props.chart.evolution_reparable["delai-depot"] && this.props.chart.evolution_reparable["delai-depot"]["visite-depot"],
            color: colors["green"][0],
            yAxisID: 'B',
          },
        ],
      }
      dataFive = this.props.type === 3
        ? {
          title: "Honoraires",
          legendDisplay: true,
          type: 'bar',
          data: [{
            type: "bar",
            label: "Honoraires en €(HT)",
            data: this.props.chart.evolution_reparable.honoraire.honoraire,
            yAxisID: 'B',
          }]
        }
        : this.props.type === 2 ? null : {
          title: "Pourcentage remise / Taux RP",
          format: 'percentage',
          legendDisplay: true,
          data: [
            {
              type: "line",
              label: "Pourcentage remise",
              data: this.props.chart.evolution_reparable['taux-rp'] && this.props.chart.evolution_reparable['taux-rp'].pourcentage_remise,
              color: colors["red"][0],
              yAxisID: 'A',
            },
            {
              type: "bar",
              label: "Taux RP",
              data: this.props.chart.evolution_reparable['taux-rp'] && this.props.chart.evolution_reparable['taux-rp'].taux_rp,
              color: colors["blue"][0],
              yAxisID: 'B',
            },
          ],
        }
      dataSix = this.props.type === 2 ? null : {
        notAlign: true,
        title: "Taux dossiers NR / Dossiers R / Dossiers NR",
        legendDisplay: true,
        data: [
          {
            type: "line",
            label: "Taux dossiers NR en %",
            data: this.props.chart.evolution_reparable["r-nr"].taux_dossiers_nr,
            color: colors["red"][0],
            yAxisID: 'A'
          },
          {
            type: "bar",
            label: "Dossiers NR",
            data: this.props.chart.evolution_reparable["r-nr"].dossiers_nr,
            color: colors["green"][0],
            yAxisID: 'B',
          },
          {
            type: "bar",
            label: "Dossiers R",
            data: this.props.chart.evolution_reparable["r-nr"].dossiers_r,
            color: colors["blue"][0],
            yAxisID: 'B',
          },
          
        ],
      }
      if (this.props.type === 2) {
        data =
        {
          title: "Coût / Gain",
          format: 'currency',
          isLegend: true,
          data: [
            {
              type: "line",
              label: "Coût",
              data: this.props.chart.evolution_reparable.cout_gain && this.props.chart.evolution_reparable.cout_gain.cout_moyen,
              color: colors["red"][0],
              yAxisID: 'A'
            },
            {
              type: "bar",
              label: "Gain",
              data: this.props.chart.evolution_reparable.cout_gain && this.props.chart.evolution_reparable.cout_gain.gain,
              color: colors["blue"][0],
              yAxisID: 'B'
            },
          ],
        }
        dataTwo =
        {
          title: "Délai mission-EAD / EAD-dépôt",
          isLegend: true,
          data: [
            {
              type: "line",
              label: "Délai mission dépôt",
              data: this.props.chart.evolution_reparable['mission-ead'] && this.props.chart.evolution_reparable['mission-ead']['mission-depot'],
              color: colors["red"][0],
              yAxisID: 'A'
            },
            {
              type: "bar",
              label: "Délai mission EAD",
              data: this.props.chart.evolution_reparable['mission-ead'] && this.props.chart.evolution_reparable['mission-ead']['mission-ead'],
              color: colors["blue"][0],
              yAxisID: 'B'
            },
            {
              type: "bar",
              label: "EAD dépôt",
              data: this.props.chart.evolution_reparable['mission-ead'] && this.props.chart.evolution_reparable['mission-ead']['ead-depot'],
              color: colors["green"][0],
              yAxisID: 'B'
            }
          ],
        }
        dataFour =
        {
          title: "Dossiers total / Dossiers EAD",
          isLegend: true,
          data: [
            {
              type: "line",
              label: "Dossiers Total",
              data: this.props.chart.evolution_reparable.dossier_total && this.props.chart.evolution_reparable.dossier_total.dossier_total,
              color: colors["red"][0],
              yAxisID: 'A'
            },
            {
              type: "bar",
              label: "Dossiers EAD",
              data: this.props.chart.evolution_reparable.dossier_total && this.props.chart.evolution_reparable.dossier_total.dossier_ead,
              color: colors["blue"][0],
              yAxisID: 'B'
            },
            {
              type: "bar",
              label: "Dossiers autre",
              data: this.props.chart.evolution_reparable.dossier_total && this.props.chart.evolution_reparable.dossier_total.dossier_autre,
              color: colors["green"][0],
              yAxisID: 'B'
            },

          ],
        }
      }
    } else if (this.state.index === 1 && this.props.chart) {
      if (this.props.type === 3) {
        data = {
          title: "Délai mission-visite / visite-SD29",
          legendDisplay: true,
          data: [
            {
              type: "line",
              label: "Délai mission SD29",
              data: this.props.chart.evolution_non_reparable && this.props.chart.evolution_non_reparable.mission_visite_SD29.delai_mission_sd29,
              color: colors["red"][0],
              yAxisID: 'A'
            },
            {
              type: "bar",
              label: "Délai mission-visite",
              data: this.props.chart.evolution_non_reparable && this.props.chart.evolution_non_reparable.mission_visite_SD29.delai_mission_visite,
              color: colors["blue"][0],
              yAxisID: 'B'
            },
            {
              type: "bar",
              label: "Délai visite-SD29",
              data: this.props.chart.evolution_non_reparable && this.props.chart.evolution_non_reparable.mission_visite_SD29.delai_visite_sd29,
              color: colors["green"][0],
              yAxisID: 'B'
            }
          ]
        }
        dataTwo = {
          title: "Délai mission-visite / visite-dépôt",
          legendDisplay: true,
          data: [
            {
              type: "line",
              label: "Délai mission dépôt",
              data: this.props.chart.evolution_non_reparable && this.props.chart.evolution_non_reparable.mission_visite_visite_depot.delai_mission_depot,
              color: colors["red"][0],
              yAxisID: 'A'
            },
            {
              type: "bar",
              label: "Délai mission-visite",
              data: this.props.chart.evolution_non_reparable && this.props.chart.evolution_non_reparable.mission_visite_visite_depot.delai_mission_visite,
              color: colors["blue"][0],
              yAxisID: 'B'
            },
            {
              type: "bar",
              label: "Délai visite-dépôt",
              data: this.props.chart.evolution_non_reparable && this.props.chart.evolution_non_reparable.mission_visite_visite_depot.delai_visite_depot,
              color: colors["green"][0],
              yAxisID: 'B'
            }
          ]
        }
      } else {
        data = {
          title: "Taux validation facture",
          legendDisplay: true,
          data: [
            {
              type: "line",
              label: "Taux validation en %",
              data: this.props.chart.evolution_precision_reparable && this.props.chart.evolution_precision_reparable.validation_facture.taux_validation,
              color: colors["red"][0],
              yAxisID: 'A'
            },
            {
              type: "bar",
              label: "Facture validée",
              data: this.props.chart.evolution_precision_reparable && this.props.chart.evolution_precision_reparable.validation_facture.facture_validees,
              color: colors["blue"][0],
              yAxisID: 'B'
            },
            {
              type: "bar",
              label: "Facture non validée",
              data: this.props.chart.evolution_precision_reparable && this.props.chart.evolution_precision_reparable.validation_facture.facture_non_validees,
              color: colors["green"][0],
              yAxisID: 'B'
            },
          ],
        }
        dataTwo = {
          title: "Différence rapport / facture",
          legendDisplay: true,
          data: [
            {
              type: "line",
              label: "Différence 1er rapport/facture",
              data: this.props.chart.evolution_precision_reparable && this.props.chart.evolution_precision_reparable.rapport_facture.difference_1er_rapport,
              color: colors["red"][0],
              yAxisID: 'A'
            },
            {
              type: "line",
              label: "Différence 2nd rapport/facture",
              data: this.props.chart.evolution_precision_reparable && this.props.chart.evolution_precision_reparable.rapport_facture.difference_2nd_rapport,
              color: colors["blue"][0],
              yAxisID: 'A'
            },
          ],
        }
        dataThree = {
          title: "Type de différence",
          legendDisplay: false,
          data: [
            {
              type: "line",
              label: "Type difference",
              data: this.props.chart.evolution_precision_reparable && this.props.chart.evolution_precision_reparable.type_difference,
              color: colors["red"][0],
              yAxisID: 'A'
            },
          ],
        }
      }
    } else if (this.state.index === 2 && this.props.chart) {
      data = {
        title: "Âge véhicule / Coût VRADE",
        legendDisplay: true,
        data: [
          {
            type: "line",
            label: "Age véhicule",
            data: this.props.chart.evolution_non_reparable.vrade_age && this.props.chart.evolution_non_reparable.vrade_age.age,
            color: colors["red"][0],
            yAxisID: 'A'
          },
          {
            type: "bar",
            label: "Coût VRADE en €",
            data: this.props.chart.evolution_non_reparable.vrade_age && this.props.chart.evolution_non_reparable.vrade_age.vrade_moyenne,
            color: colors["blue"][0],
            yAxisID: 'B'
          },
        ],
      }
      dataTwo = {
        title: "Délai mission-visite / visite-SD29",
        legendDisplay: true,
        data: [
          {
            type: "line",
            label: "Délai mission SD29",
            data: this.props.chart.evolution_non_reparable.mission_visite_sd29 && this.props.chart.evolution_non_reparable.mission_visite_sd29.delai_mission_sd29,
            color: colors["red"][0],
            yAxisID: 'A'
          },
          {
            type: "bar",
            label: "Délai visite-SD29",
            data: this.props.chart.evolution_non_reparable.mission_visite_sd29.delai_visite_sd29,
            color: colors["green"][0],
            yAxisID: 'B',
          },
          {
            type: "bar",
            label: "Délai mission-visite",
            data: this.props.chart.evolution_non_reparable.mission_visite_sd29.delai_mission_visite,
            color: colors["blue"][0],
            yAxisID: 'B',
          },
        ],
      }
      dataThree = {
        title: "Délai mission-visite / visite-dépôt",
        legendDisplay: true,
        data: [
          {
            type: "line",
            label: "Délai mission dépôt",
            data: this.props.chart.evolution_non_reparable.mission_visite_visite_depot.delai_mission_depot,
            color: colors["red"][0],
            yAxisID: 'A'
          },
          {
            type: "bar",
            label: "Délai mission-visite",
            data: this.props.chart.evolution_non_reparable.mission_visite_visite_depot.delai_mission_visite,
            color: colors["blue"][0],
            yAxisID: 'B',
          },
          {
            type: "bar",
            label: "Délai visite-dépôt",
            data: this.props.chart.evolution_non_reparable.mission_visite_visite_depot.delai_visite_depot,
            color: colors["green"][0],
            yAxisID: 'B',
          },
        ],
      }
    }
    return { data, dataTwo, dataThree, dataFour, dataFive, dataSix }
  }

  render() {
    const result = this.setData()
    return (
      <TabsCustom className={'mt-20'}>
        {this.props.chart ? (<>
          <TabsCustomHeader>
            {this.state.menuTabs.map((element, key) => (
              <TabsCustomMenu
                disabled={this.props.isLoader}
                key={key}
                title={element}
                active={this.state.index === key}
                onClick={(e) => {
                  this.setState({ index: key });
                }}
              />
            ))}
          </TabsCustomHeader>
          <TabsCustomBody noCard>
            {this.state.menuTabs.map((element, idx) => {
              return (
                <TabsCustomItem active={this.state.index === idx} key={idx}>
                  {this.state.index > 0
                    ? <CardEvolution
                      data={this.props.chart && result.data}
                      dataTwo={this.props.chart && result.dataTwo}
                      dataThree={this.props.chart && result.dataThree}
                      isPrint={this.props.isPrint}
                    />
                    : <CardEvolutionSixValue
                      data={this.props.chart && result.data}
                      dataTwo={this.props.chart && result.dataTwo}
                      dataThree={this.props.chart && result.dataThree}
                      dataFour={this.props.chart && result.dataFour}
                      dataFive={this.props.chart && result.dataFive}
                      dataSix={this.props.chart && result.dataSix}
                      isPrint={this.props.isPrint}
                      />}
                </TabsCustomItem>
              )
            })}
          </TabsCustomBody>
        </>) : null}
      </TabsCustom>
    );
  }
};