import React, { Component } from "react";
import { Card, Table, Thead, Tbody, Tr, Th, Td } from "ui-kit-ck-consultant";
import { ExportCSV } from "react-export-csv";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getCurrencyValue } from "../../../utils/general";

export class SavingsElectric extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDownload: false,
    };
  }

  render() {
    let data = [];

    if (this.props.data.length) {
      this.props.data[0].forEach((month, idxRow) => {
        data.push({});
        this.props.data.forEach((element, idxCol) => {
          data[idxRow][idxCol] = element[idxRow];
        });
      });
    }

    return (
      <>
        <Card
          title="Économies et honoraires"
          className="mb-10 mb-20-xl"
          classNameChildren="overflow-x-auto"
          action={<FontAwesomeIcon icon={faDownload} />}
          onClick={() => this.setState({ isDownload: true })}
        >
          <Table>
            <Thead>
              <Tr>
                <Th>Mois</Th>
                <Th style={{ width: "14%" }}>Somme des gains totaux TTC</Th>
                <Th style={{ width: "14%" }}>
                  Somme gains TTC liés gré-à-gré (+ value solution)
                </Th>
                <Th style={{ width: "14%" }}>Somme gains TTC BATTERIE</Th>
                <Th style={{ width: "14%" }}>Somme gains TTC TESLA</Th>
                <Th style={{ width: "14%" }}>Somme gains TTC SOUMISSION</Th>
                <Th style={{ width: "14%" }}>Somme gains TTC AUTRE</Th>
                <Th>Honoraires HT</Th>
              </Tr>
            </Thead>
            <Tbody>
              {this.props.data.length
                ? this.props.data[0].map((month, idxRow) => (
                    <Tr key={idxRow}>
                      {this.props.data.map((element, idxCol) => (
                        <Td key={idxCol}>
                          {[1, 2, 3, 4, 5, 6, 7].includes(idxCol)
                            ? getCurrencyValue(element[idxRow])
                            : element[idxRow]}
                        </Td>
                      ))}
                    </Tr>
                  ))
                : null}
            </Tbody>
          </Table>
        </Card>
        {this.state.isDownload ? (
          <ExportCSV
            header={[
              {
                name: "Mois",
                key: "0",
              },
              {
                name: "Somme des gains totaux TTC",
                key: "1",
              },
              {
                name: "Somme gains TTC liés gré-à-gré (+ value solution)",
                key: "2",
              },
              {
                name: "Somme gains TTC BATTERIE",
                key: "3",
              },
              {
                name: "Somme gains TTC TESLA",
                key: "3",
              },
              {
                name: "Somme gains TTC SOUMISSION",
                key: "3",
              },
              {
                name: "Somme gains TTC AUTRE",
                key: "3",
              },
              {
                name: "Honoraires HT",
                key: "4",
              },
            ]}
            data={data}
            separator={";"}
            callback={() =>
              this.setState({
                isDownload: false,
              })
            }
            isDownload={true}
            filename={`Économies ${this.props.year}.csv`}
          />
        ) : null}
      </>
    );
  }
}

export default SavingsElectric;
