import React from "react";

export default class Guyane extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <g transform="translate(610, 300)">
        <svg
          width="50"
          height="50"
          viewBox="0 0 27 31"
          fill={this.props.fill || "none"}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.69164 0.791772C5.61519 0.796792 5.55754 0.82058 5.53103 0.84533L4.04993 2.71899L2.76511 4.2001L1.28401 5.68121L1.49815 9.64272L1.39108 13.3009L2.6759 15.0854L3.94285 17.3516L4.67449 18.7435L3.94285 19.6357L3.08633 22.0091L3.19338 25.2746L2.14054 27.1483L0.445312 28.5402L0.962799 29.1291L2.24763 29.9142L3.40752 30.6102L5.10278 30.1105L6.90507 29.022L10.6168 28.9328L12.0086 29.9142L13.4898 30.0213L14.9708 30.3247L17.0944 28.6294L19.5391 24.3824L21.3414 20.7243L23.1437 18.0476L25.0531 15.585L26.1059 13.6935L25.2672 12.0161L24.6248 9.64272L23.2508 9.14306L21.6804 8.07241L20.3778 6.87679L18.6825 5.78829L17.5226 5.39568L14.1321 3.02236L11.9016 2.21935L9.77807 1.93383C9.77807 1.93383 8.82733 1.54276 8.29696 1.34496C7.83283 1.17194 6.22679 0.756632 5.69164 0.791772Z"
            fill={this.props.fill || "none"}
            stroke="#232323"
            strokeWidth={1}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <text x={"-2%"} y={"-1%"}>
          Guyane
        </text>
      </g>
    );
  }
}
