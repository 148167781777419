import React from "react";

import { faComment } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormTextarea, Button, ButtonIcon } from "ui-kit-ck-consultant";

import "../../style/commentArea.css";

import axiosConfig from "../../utils/axiosConfig";

import AuthContext from "../../context/AuthContext";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import ModalEditComment from "./ModalEditComment";
export default class CommentArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: "",
      modalEditComment: false,
      commentModalEditComment: {},
    };
  }
  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  addComment() {
    if (this.state.comment !== "") {
      this.setState({ isLoad: true, data: null }, () => {
        axiosConfig("/support/add-comment", {
          data: {
            comment: this.state.comment,
            idea: this.props.idea,
          },
        }).then(() => {
          this.setState(
            {
              isLoad: false,
              isOpenModal: false,
              comment: "",
            },
            () => {
              this.props.reload();
            }
          );
        });
      });
    } else {
      alert("Votre commentaire est vide");
    }
  }

  updateComment = (comment) => {
    if (comment !== "") {
      this.setState({ isLoad: true, data: null }, () => {
        axiosConfig("/support/update-comment", {
          data: {
            comment,
            id: this.state.commentModalEditComment.id,
          },
        }).then(() => {
          this.setState(
            {
              isLoad: false,
              modalEditComment: false,
              commentModalEditComment: {},
            },
            () => this.props.reload()
          );
        });
      });
    } else {
      alert("Votre commentaire est vide");
    }
  };

  render() {
    return (
      <>
        {this.props.comments ? (
          <>
            <div className="comment-holder mb-20 mt-30">
              {this.props.comments.map((comment, idx) => {
                return (
                  <div key={idx} className="mb-10">
                    <div className="d-flex">
                      <b className="user-name mt-auto mb-auto">
                        {comment.user}
                      </b>
                      <span className="date ml-10 mt-auto mb-auto">
                        Ajouté le {comment.date}
                      </span>
                      {comment.userId === this.context.id ? (
                        <ButtonIcon
                          small
                          className="blue mt-auto mb-auto"
                          onClick={() =>
                            this.setState({
                              modalEditComment: true,
                              commentModalEditComment: comment,
                            })
                          }
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </ButtonIcon>
                      ) : null}
                    </div>
                    <div className="comment-area ml-30">
                      {comment.comment.split("\n").map((element, idx) => (
                        <p key={idx}>{element}</p>
                      ))}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : null}
        {this.context.id === this.props.user || !this.context.network ? (
          <>
            <p className="mt-30">
              <span className="blue mr-10">
                <FontAwesomeIcon icon={faComment} />
              </span>
              <b>Commentaires:</b>
            </p>
            {this.props.status !== 2 ? (
              <>
                <FormTextarea
                  className="mt-20"
                  name="comment"
                  value={this.state.comment}
                  onChange={(e) => this.handleInputChange(e)}
                />
                <Button
                  className="mt-20"
                  text="Envoyer"
                  onClick={() => this.addComment()}
                />
              </>
            ) : null}
          </>
        ) : null}
        <ModalEditComment
          onClose={() =>
            this.setState({
              modalEditComment: false,
              commentModalEditComment: {},
            })
          }
          show={this.state.modalEditComment}
          comment={this.state.commentModalEditComment}
          updateComment={this.updateComment}
        />
      </>
    );
  }
}
