import React, { Component } from "react";
import { Card, Button } from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";

import AuthContext from "../../../context/AuthContext";

export default class Declare extends Component {
  static contextType = AuthContext;

  render() {
    return (
      <Card className={this.props.className}>
        <Row>
          <Col xs={12} md={3} className={"mb-10"}>
            <Button
              outline
              text="Nouveau audit interne technique terrain réparable"
              onClick={() =>
                window.open(
                  this.context.urlForm + "/audit-technique-expert-terrain.php",
                  "_blank"
                )
              }
            />
          </Col>
          <Col xs={12} md={3} className={"mb-10"}>
            <Button
              outline
              text="Nouveau audit interne technique EAD"
              onClick={() =>
                window.open(
                  this.context.urlForm + "/audit-technique-ead.php",
                  "_blank"
                )
              }
            />
          </Col>
          <Col xs={12} md={3} className={"mb-10"}>
            <Button
              outline
              text="Nouveau audit interne technique RIV/VEI"
              onClick={() =>
                window.open(
                  this.context.urlForm + "/audit-technique-riv-vei.php",
                  "_blank"
                )
              }
            />
          </Col>
          <Col xs={12} md={3} className={"mb-10"}>
            <Button
              outline
              text="Nouveau audit interne technique VE"
              onClick={() =>
                window.open(
                  this.context.urlForm + "/audit-technique-ve.php",
                  "_blank"
                )
              }
            />
          </Col>
          <Col xs={12} md={3} className={"mb-10"}>
            <Button
              outline
              text="Nouveau audit interne technique incohérence"
              onClick={() =>
                window.open(
                  this.context.urlForm + "/audit-technique-incoherence.php",
                  "_blank"
                )
              }
            />
          </Col>
          <Col xs={12} md={3} className={"mb-10"}>
            <Button
              outline
              text="Nouveau audit interne technique VVR"
              onClick={() =>
                window.open(
                  this.context.urlForm + "/audit-technique-vvr.php",
                  "_blank"
                )
              }
            />
          </Col>
          <Col xs={12} md={3} className={"mb-10"}>
            <Button
              outline
              text="Nouveau audit interne technique incendie"
              onClick={() =>
                window.open(
                  this.context.urlForm + "/audit-technique-incendie.php",
                  "_blank"
                )
              }
            />
          </Col>
        </Row>
      </Card>
    );
  }
}
