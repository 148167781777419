import React, { Component } from "react";
import FadeIn from "react-fade-in";
import { Card, Loader } from "ui-kit-ck-consultant";
import DataTable from "./DataTable";

import axiosConfig from "../../../../utils/axiosConfig";

import {
  repairTimeTable,
  totalLossTimeTable,
  techniqueTable,
  avgCostTable,
} from "./dataAbeille";

export class CardABEILLE extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      search: null,
      isLoad: false,
    };
  }

  componentDidMount() {
    this.getPerformance();
  }

  componentDidUpdate = (prevProps) => {
    if (
      JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data) &&
      parseInt(this.props.data.company) === 13
    ) {
      this.getPerformance();
    }
  };

  getPerformance = () => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("/company-performance/abeille", {
        data: {
          dateStart: this.props.data.dateStart,
          dateEnd: this.props.data.dateEnd,
          network: this.props.data.network,
          group: this.props.data.group,
          region: this.props.data.region,
          agency: this.props.data.agency,
          user: this.props.data.user,
          company: this.props.data.company,
        },
      }).then((res) => {
        if (res.data.success) {
          this.getTables(res.data.data, () => {
            this.setState({
              search: res.data.search,
              isLoad: false,
            });
          });
        }
      });
    });
  };

  getTables = (data, callback) => {
    const tables = [
      repairTimeTable,
      totalLossTimeTable,
      techniqueTable,
      avgCostTable,
    ];

    const tablesData = tables.map((table) => {
      const tableData = table.map((row) => ({
        id: row.id,
        name: row.name,
        data: this.getData(data[row.id]),
        unit: row.unit,
        title: row.title,
        evolReversed: row.evolReversed,
      }));
      return tableData;
    });

    this.setState({ data: tablesData }, () => callback());
  };

  getData = (rawData) => {
    return rawData.map((el, idx) => {
      if ((idx + 2) % 3 === 0) {
        return this.getEvolution(rawData[idx - 1], rawData[idx + 1]);
      } else {
        return el;
      }
    });
  };

  getEvolution = (current, old) => {
    let diff = 0;

    if (!old && !current) {
      diff = 0;
    } else if (!old) {
      diff = 100;
    } else if (!current) {
      diff = 100;
    } else {
      diff = parseInt(((old - current) / current) * 100);
    }

    if (diff > 0) {
      return "+" + diff;
    } else {
      return diff;
    }
  };

  render() {
    return (
      <FadeIn>
        <Card
          title="Qualité"
          className="mb-20"
          classNameChildren="overflow-x-auto"
        >
          <DataTable
            name="repairTime"
            data={
              this.state.data &&
              this.state.data.length > 0 &&
              this.state.data[0]
                ? this.state.data[0]
                : null
            }
            year={this.props.year}
          />
          <DataTable
            name="totalLossTime"
            data={
              this.state.data &&
              this.state.data.length > 0 &&
              this.state.data[1]
                ? this.state.data[1]
                : null
            }
            year={this.props.year}
          />
        </Card>

        {/* TECHNIQUE CARD */}
        <Card
          title="Technique (EAD + REP)"
          className="mb-20"
          classNameChildren="overflow-x-auto"
        >
          <DataTable
            name="technique"
            data={
              this.state.data &&
              this.state.data.length > 0 &&
              this.state.data[2]
                ? this.state.data[2]
                : null
            }
            year={this.props.year}
          />
        </Card>

        {/* AVERAGE COST CARD */}
        <Card
          title="Coût Moyen"
          className="mb-20"
          classNameChildren="overflow-x-auto"
        >
          <DataTable
            name="avgCost"
            data={
              this.state.data &&
              this.state.data.length > 0 &&
              this.state.data[3]
                ? this.state.data[3]
                : null
            }
            year={this.props.year}
          />
        </Card>

        <Loader show={this.state.isLoad} />
      </FadeIn>
    );
  }
}

export default CardABEILLE;
