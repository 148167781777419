import React from "react";

export default class Reunion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <g transform="translate(610, 380)">
        <svg
          width="50"
          height="50"
          viewBox="0 0 31 27"
          fill={this.props.fill || "none"}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.5529 0.959961L10.2218 1.00449L9.37541 1.42026L8.41025 2.16266L8.23207 2.32602H7.56389L6.15331 2.86053L5.54451 3.39511L3.59938 3.67722L3.42119 4.70175L2.88666 6.8399C2.88666 6.8399 2.14216 7.3367 1.92151 7.41901C1.70081 7.5013 0.46637 8.16144 0.46637 8.16144L0.243652 10.4332L0.778183 11.7844L2.23332 13.5069L2.9312 14.2938L3.49544 14.175L3.15393 14.3383L3.19847 17.4268L4.65361 19.2383L6.15331 20.6341L8.79631 22.1931L8.9745 22.0892L8.76663 22.2377L11.8996 24.1234L14.3199 24.9995L16.9184 25.445L18.9972 26.1874L20.3187 26.4398C20.3187 26.4398 22.3554 26.0241 22.6202 26.0241C22.885 26.0241 24.9959 25.6083 24.9959 25.6083L27.1638 24.9104L28.6189 23.5889L28.4853 21.2429L28.7525 19.7729L29.3316 18.9414L30.2968 17.6644L30.7868 16.0608L30.6532 14.5017L29.6286 13.9226L28.3071 13.3138L27.6983 13.2693L26.7183 11.8735C26.4977 11.709 25.3069 10.3866 25.2186 10.1808C25.1304 9.97508 24.3426 8.20596 24.3426 8.20596L24.0753 5.44418L22.5756 4.00388L20.8087 3.02386C20.8087 3.02386 19.3956 2.20386 19.1308 2.16266C18.866 2.12157 16.5307 1.62814 16.3541 1.62814C16.1776 1.62814 14.4532 1.75037 14.409 1.79147L14.1863 1.70239L12.5529 0.959961Z"
            fill={this.props.fill || "none"}
            stroke="#232323"
            strokeWidth={1}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <text x={"-2%"} y={"-1%"}>
          La Réunion
        </text>
      </g>
    );
  }
}
