import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import { faMapMarkerAlt, faUser } from "@fortawesome/free-solid-svg-icons";

import MapMarker from "./MapMarker";
import ModalMapGarage from "./ModalMapGarage";

export default class MapGarage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //Map
      key: "AIzaSyDxQiiLJaitFNPs4NCNbjB3DTaY_rWd_Fs",
      defaultLat: this.props.defaultLat ? this.props.defaultLat : 48.8534,
      defaultLong: this.props.defaultLong ? this.props.defaultLong : 2.3488,
      defaultZoom: 10,
      //Modal
      currentGarage: null,
      isGarageModal: false,
      distance: null,
    };
  }

  handleApiLoaded = (map, maps) => {
    this.setState({ googleMaps: maps });
  };

  handleDistance() {
    let maps = this.state.googleMaps;
    let directionsService = new maps.DirectionsService();
    let request = {
      origin: {
        lat: this.props.folder.latitudeClient,
        lng: this.props.folder.longitudeClient,
      },
      destination: {
        lat: this.state.currentGarage.latitude,
        lng: this.state.currentGarage.longitude,
      },
      travelMode: maps.DirectionsTravelMode.DRIVING,
    };
    directionsService.route(request, (response, status) => {
      if (status === maps.DirectionsStatus.OK) {
        let distance = Math.round(
          response.routes[0].legs[0].distance.value / 1000
        );
        this.setState({ distance });
      }
    });
  }

  handleOpenModal(e) {
    this.setState(
      {
        currentGarage: e,
        isGarageModal: !this.state.isGarageModal,
      },
      () => this.handleDistance(0, 0, 0, 0)
    );
  }

  render() {
    return (
      <div style={{ height: "600px" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: this.state.key }}
          center={{
            lat: this.state.defaultLat,
            lng: this.state.defaultLong,
          }}
          zoom={this.props.defaultZoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
        >
          {this.props.folder.latitudeClient ? (
            <MapMarker
              size={30}
              lat={this.props.folder.latitudeClient}
              lng={this.props.folder.longitudeClient}
              icon={faUser}
              color="red"
            />
          ) : null}
          {this.props.garages.map((garage, idx) => (
            <MapMarker
              key={idx}
              onOpenModal={() => this.handleOpenModal(garage)}
              size={30}
              lat={garage.latitude}
              lng={garage.longitude}
              icon={faMapMarkerAlt}
            />
          ))}
        </GoogleMapReact>
        {this.state.currentGarage ? (
          <ModalMapGarage
            folder={this.props.folder}
            isGarageModal={this.state.isGarageModal}
            distance={this.state.distance}
            currentGarage={this.state.currentGarage}
            onClose={() =>
              this.setState({ isGarageModal: !this.state.isGarageModal })
            }
          />
        ) : null}
      </div>
    );
  }
}
