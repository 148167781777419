import React, { Component } from "react";
import FadeIn from "react-fade-in/lib/FadeIn";
import { Table, Tbody, Td, Th, Thead, Tr } from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";

import { getCurrencyValue } from "../../../utils/general"

export class TableFolders extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  getColor(difference) {
    if (difference > 2) {
      return "bg-red-light"
    } else if (difference > 1) {
      return "bg-orange-light"
    } else {
      return "bg-green-light"
    }
  }

  render() {
    return (
      <>
        {/* {this.props.data ? */}
          <FadeIn>
            <h4>Dossiers en retard ({this.props.data && this.props.data.length})</h4>
            <div style={{
              maxHeight: "380px",
              overflowY: "auto"
            }}>
              <Table 
                className={'w-100'}
                empty={
                  this.props.data
                    ? ""
                    : <div>Aucun dossier en retard <br/><FontAwesomeIcon color='green' icon={faThumbsUp}/></div>
                }
              >
                <Thead className="white">
                  <Tr>
                    <Th className="nowrap pt-20 pb-20" >Date</Th>
                    <Th className="nowrap pt-20 pb-20" >Agence</Th>
                    <Th className="nowrap pt-20 pb-20" >NºDossier</Th>
                    <Th className="nowrap pt-20 pb-20" >Expert</Th>
                    <Th className="nowrap pt-20 pb-20" >Réparateur</Th>
                    <Th className="nowrap pt-20 pb-20" >Compagnie</Th>
                    <Th className="nowrap pt-20 pb-20" >Chiff. init. rép.</Th>
                    <Th className="nowrap pt-20 pb-20" >H. de Récep.</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {this.props.data && this.props.data.map((row, idx) => (
                    <Tr
                      key={idx}
                    >
                      <Td className={this.getColor(row.difference)}>{row.date}</Td>
                      <Td className={this.getColor(row.difference)}>{row.agency}</Td>
                      <Td className={this.getColor(row.difference)}>{row.reference}</Td>
                      <Td className={this.getColor(row.difference)}>{row.expert}</Td>
                      <Td className={this.getColor(row.difference)}>{row.garage}</Td>
                      <Td className={this.getColor(row.difference)}>{row.company}</Td>
                      <Td className={this.getColor(row.difference)}>{getCurrencyValue(row.initialRate)}</Td>
                      <Td className={this.getColor(row.difference)}>{row.hour}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </div>
          </FadeIn>
          {/* : null} */}
      </>
    );
  }
}

export default TableFolders;
