import React, { Component } from "react";
import { Col } from "react-flexbox-grid";
import { PieChart } from "ui-kit-ck-consultant";

import { getPercentageValue, getValue } from "../../utils/general";

export default class CardRound extends Component {
  render() {
    const { data } = this.props;

    return (
      <Col xs={12} md={12} lg={5}>
        <div
          className="mb-10 mb-20-xl"
          style={{
            position: "relative",
            borderRadius: "8px",
            backgroundColor: "var(--white)",
            color: "var(--black)",
            boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
            minHeight: "85px",
            display: "flex",
            overflow: "hidden",
            flexDirection: "column",
            padding: 15,
          }}
        >
          <h2
            style={{
              color: "#535374",
              fontSize: "13px",
              fontWeight: "700",
              margin: 0,
            }}
          >
            Détail des émissions de CO2e
          </h2>
          <div
            className="d-flex"
            style={{
              alignItems: "center",
            }}
          >
            <div>
              <PieChart
                legendDisplay={false}
                className={`m-auto`}
                height={260}
                data={data.map((element) => ({
                  labels: `${element.label} (kt CO2e)`,
                  data: element.value / 1000000,
                  color: element.color,
                }))}
              />
            </div>
            <div className="d-flex flex-column" style={{ gap: 10 }}>
              {data.map((element, idx) => (
                <div
                  key={idx}
                  className="d-flex"
                  style={{
                    alignItems: "center",
                    color: "#535374",
                    fontSize: "13px",
                  }}
                >
                  <div style={{ marginRight: 12 }}>
                    <img
                      src={element.icon}
                      width={24}
                      style={{ maxHeight: 30 }}
                      alt=""
                    />
                  </div>
                  <div className="flex-column">
                    <span style={{ fontWeight: "bold", alignItems: "center" }}>
                      {getPercentageValue(
                        (element.value * 100) / this.props.total
                      )}{" "}
                      | {getValue(element.average, 2)} kg
                    </span>
                    <span>{element.label}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Col>
    );
  }
}
