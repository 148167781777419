import React from "react";
import { Col, Row } from "react-flexbox-grid";
import {
  CardColor,
  Nav,
  TabsCustom,
  TabsCustomHeader,
  TabsCustomBody,
  TabsCustomItem,
  TabsCustomMenu,
} from "ui-kit-ck-consultant";
import FadeIn from "react-fade-in";
import moment from "moment";

import PrintClass from "../../components/general/Print";

import axiosConfig from "../../utils/axiosConfig";
import { months } from "../../utils/general";

import AuthContext from "../../context/AuthContext";
import { GroupTab } from "../../components/home/GroupTab";
// import { PodiumTab } from "../../components/home/PodiumTab";
import { UserTab } from "../../components/home/UserTab";

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //Expert data states
      caExpert: null,
      perfExpert: null,
      perfRSE: null,
      isLoadExpert: true,
      //Agency data states
      caAgency: null,
      perfAgency: null,
      perfRSEAgency: null,
      isLoadAgency: true,
      isLoadSatisfaction: true,
      nbSIM: 0,
      nbInterview: 0,
      nbUpdate: 0,
      nbAudit: 0,
      nbAuditPA: 0,
      //User's data
      userName: null,
      isLoadUser: false,
      dataSatisfaction: null,
      dataUser: null,
      dataUserMonth: null,
      //Group's data
      groupGlobal: null,
      groupYield: null,
      dataYield: null,
      isLoadGroup: true,
      //Tabs handling
      tabsIndex: 0,
      // tabsMenu: ["Groupe", "Podiums"],
      tabsMenu: ["Groupe"],
    };

    this.refPrintExpertG = React.createRef();
    this.refPrintExpertCA = React.createRef();
    this.refPrintAgencyG = React.createRef();
    this.refPrintAgencyCA = React.createRef();
  }

  static contextType = AuthContext;

  componentDidMount() {
    //get data experts
    // axiosConfig("home/data", {
    //   data: {
    //     type: 2,
    //   },
    // }).then((res) => {
    //   if (res.data.success === true) {
    //     this.setState({
    //       caExpert: res.data.array_performance.ca,
    //       perfExpert: res.data.array_performance.globale,
    //       perfRSE: res.data.array_performance.RSE,
    //       // dataUser: res.data.array_performance.user,
    //       // dataUserMonth: res.data.array_performance.userMonth,
    //       isLoadExpert: false,
    //     });
    //   } else {
    //     this.setState({ isLoadExpert: false });
    //   }
    // });

    //get data agencies
    // axiosConfig("home/data", {
    //   data: {
    //     type: 1,
    //   },
    // }).then((res) => {
    //   if (res.data.success === true) {
    //     this.setState({
    //       caAgency: res.data.array_performance.ca,
    //       perfAgency: res.data.array_performance.globale,
    //       perfRSEAgency: res.data.array_performance.RSE,
    //       isLoadAgency: false,
    //     });
    //   } else {
    //     this.setState({ isLoadAgency: false });
    //   }
    // });

    // // get data groups
    // axiosConfig("home/data", {
    //   data: {
    //     type: 4,
    //   },
    // }).then((res) => {
    //   if (res.data.success === true) {
    //     this.setState({
    //       groupGlobal: res.data.array_performance.globale,
    //       groupYield: res.data.array_performance.yield,
    //       isLoadGroup: false,
    //     });
    //   } else {
    //     this.setState({ isLoadGroup: false });
    //   }
    // });

    //Get number of Interview
    axiosConfig("home/count-interview").then((res) => {
      if (res.data.success === true) {
        this.setState({
          nbInterview: res.data.data,
        });
      }
    });

    //Get number of SIM1
    axiosConfig("home/count-sim").then((res) => {
      if (res.data.success === true) {
        this.setState({
          nbSIM1: res.data.data,
        });
      }
    });

    //Get number of updates
    axiosConfig("home/count-update").then((res) => {
      if (res.data.success === true) {
        this.setState({
          nbUpdate: res.data.data,
        });
      }
    });

    //Get number of audits
    axiosConfig("home/count-audit").then((res) => {
      if (res.data.success === true) {
        this.setState({
          nbAudit: res.data.data,
        });
      }
    });

    axiosConfig("home/count-audit-pa").then((res) => {
      if (res.data.success === true) {
        this.setState({
          nbAuditPA: res.data.data,
        });
      }
    });

    //Get satisfaction data
    axiosConfig("home/get-satisfaction").then((res) => {
      if (res.data.success === true) {
        this.setState({
          dataSatisfaction: res.data.data,
          isLoadSatisfaction: false,
        });
      } else {
        this.setState({ isLoadSatisfaction: false });
      }
    });

    // //get user's name
    // axiosConfig("home/get-user").then((res) => {
    //   if (res.data.success === true) {
    //     this.setState({
    //       userName: res.data.result.name,
    //       isLoadUser: false,
    //     });
    //   } else {
    //     this.setState({ isLoadUser: false });
    //   }
    // });
  }

  redirect(page) {
    let link = "";
    let type = "";

    if ([4, 5].includes(this.context.type)) {
      type = "assistant";
    } else if ([0, 1, 2].includes(this.context.type)) {
      type = "expert";
    } else {
      type = "expert-EAD";
    }

    switch (page) {
      case 0:
        link = `/entretien-${type}`;
        break;
      case 1:
        link = `/sim1-${type}`;
        break;
      case 2:
        link = "/library";
        break;
      case 3:
        link = "/update";
        break;
      case 4:
        link = "/audit-technical";
        break;
      case 5:
        link = "/result-plan-actions";
        break;
      default:
        link = null;
    }

    if (link) {
      document.location.href = link;
    }
  }

  render() {
    const date = `${months[moment().format("M") - 1]} ${moment().format(
      "YYYY"
    )}`;

    return (
      <FadeIn>
        <Nav className="mb-10 mb-30-xl" title="Tableau de bord" />
        <Row>
          <Col xs={12} md={4}>
            <CardColor
              title={"Entretien non clos cette année"}
              count={this.state.nbInterview}
              className={
                this.state.nbInterview === 0
                  ? "mb-10 mb-30-xl bg-green"
                  : "mb-10 mb-30-xl bg-orange"
              }
              onClick={() => this.redirect(0)}
            />
          </Col>
          <Col xs={12} md={4}>
            <CardColor
              title={"SIM1 non clos cette année"}
              count={this.state.nbSIM1}
              className={
                this.state.nbSIM1 === 0
                  ? "mb-10 mb-30-xl bg-green"
                  : "mb-10 mb-30-xl bg-orange"
              }
              onClick={() => this.redirect(1)}
            />
          </Col>
          <Col xs={12} md={4}>
            <CardColor
              title={"Mises à jours effectuées ce mois-ci"}
              className={"mb-10 mb-30-xl bg-green"}
              count={this.state.nbUpdate}
              onClick={() => this.redirect(3)}
            />
          </Col>
          <Col xs={12} md={6}>
            <CardColor
              title={"Audits réalisés cette année"}
              className={"mb-10 mb-30-xl bg-blue"}
              count={this.state.nbAudit}
              onClick={() => this.redirect(4)}
            />
          </Col>
          <Col xs={12} md={6}>
            <CardColor
              title={"Audits de plan d'action réalisés cette année"}
              className={"mb-10 mb-30-xl bg-blue"}
              count={this.state.nbAuditPA}
              onClick={() => this.redirect(5)}
            />
          </Col>
        </Row>
        <TabsCustom>
          <TabsCustomHeader>
            {[1, 2].includes(this.context.type)
              ? this.state.tabsMenu.map((element, idx) => (
                  <TabsCustomMenu
                    key={idx}
                    active={this.state.tabsIndex === idx}
                    title={element}
                    onClick={(e) => this.setState({ tabsIndex: idx })}
                  />
                ))
              : this.state.tabsMenu.map((element, idx) => {
                  if (element !== "Utilisateur") {
                    return (
                      <TabsCustomMenu
                        key={idx}
                        active={this.state.tabsIndex === idx}
                        title={element}
                        onClick={(e) => this.setState({ tabsIndex: idx })}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
          </TabsCustomHeader>
          <TabsCustomBody noCard>
            <TabsCustomItem active={this.state.tabsIndex === 0}>
              <GroupTab
                groupGlobal={this.state.groupGlobal}
                groupYield={this.state.groupYield}
                isLoadGroup={this.state.isLoadGroup}
                isLoadSatisfaction={this.state.isLoadSatisfaction}
                dataSatisfaction={this.state.dataSatisfaction}
                date={date}
                group={this.context.group}
                />
            </TabsCustomItem>
            <TabsCustomItem active={this.state.tabsIndex === 1}>
              {/* <PodiumTab
                perfExpert={this.state.perfExpert}
                caExpert={this.state.caExpert}
                perfAgency={this.state.perfAgency}
                caAgency={this.state.caAgency}
                isLoadExpert={this.state.isLoadExpert}
                isLoadAgency={this.state.isLoadAgency}
                isLoadGroup={this.state.isLoadGroup}
                perfRSE={this.state.perfRSE}
                perfRSEAgency={this.state.perfRSEAgency}
                date={date}
                userName={this.state.userName}
                group={this.context.group}
                /> */}
            </TabsCustomItem>
            <TabsCustomItem active={this.state.tabsIndex === 2}>
              {[1, 2].includes(this.context.type) ? (
                <UserTab
                  dataUser={this.state.dataUser}
                  dataUserMonth={this.state.dataUserMonth}
                  isLoadExpert={this.state.isLoadExpert}
                  date={date}
                  userName={this.state.userName}
                />
              ) : null}
            </TabsCustomItem>
          </TabsCustomBody>
        </TabsCustom>
        <PrintClass
          refs={
            this.state.isPrint
              ? [
                  this.refPrintExpertG,
                  this.refPrintExpertCA,
                  this.refPrintAgencyG,
                  this.refPrintAgencyCA,
                ]
              : []
          }
          title={"Tableau de Bord"}
          fileName={"Tableau de Bord"}
          onPrint={(print) =>
            this.setState({ isPrint: print, isLoad: print })
          }
          width={800}
        />
      </FadeIn>
    );
  }
}
