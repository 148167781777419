import React, { Component } from "react";
import FadeIn from "react-fade-in/lib/FadeIn";
import { Nav, Card, Loader } from "ui-kit-ck-consultant";

import axiosConfig from "../../../utils/axiosConfig";

import ShowOptionsNGRA from "../../../components/general/ShowOptionsNGRA-v2";
import PrintClass from "../../../components/general/Print";
import GraphCard from "../../../components/performances/selfcare/GraphCard";

export class Selfcare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      isPrint: false,
      network: 0,
      region: 0,
      group: 0,
      agency: 0,
      dateStart: 0,
      dateEnd: 0,
      company: 0,
      data: [],
    };

    this.refGraphCard = [];
  }

  handleDataChange = (data) => {
    this.setState(
      {
        network: data.network,
        group: data.group,
        region: data.region,
        agency: data.agency,
        dateStart: data.dateStart,
        dateEnd: data.dateEnd,
        company: data.company,
      },
      () => {
        this.getData();
      }
    );
  };

  getData = () => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        axiosConfig("performances/selfcare/get-data", {
          data: {
            network: this.state.network,
            group: this.state.group,
            region: this.state.region,
            agency: this.state.agency,
            dateStart: this.state.dateStart,
            dateEnd: this.state.dateEnd,
            company: this.state.company,
          },
        })
          .then((res) => {
            if (res.data.success) {
              this.setState({
                isLoad: false,
                data: res.data.data,
              });
            } else {
              this.setState({ isLoad: false, data: [] }, () =>
                alert("Une erreur est survenue")
              );
            }
          })
          .catch(() => {
            this.setState({ isLoad: false, data: [] }, () =>
              alert("Une erreur est survenue")
            );
          });
      }
    );
  };

  getAverage = (arr) => {
    let sum = 0;
    let length = 0;

    arr.forEach((value) => {
      if (value !== null) {
        length++;
        sum += value;
      }
    });

    const avg = sum / length;
    return Number(Math.round(avg + "e+2") + "e-2") || 0;
  };

  getSum = (arr) => {
    let sum = 0;

    arr.forEach((value) => {
      if (value !== null) {
        sum += value;
      }
    });

    return sum;
  };

  render() {
    return (
      <>
        <FadeIn>
          <Nav title="Selfcare" className="mb-10 mb-20-xl" />
          <ShowOptionsNGRA
            isChange={true}
            className="mb-10 mb-20-xl"
            onDataChange={this.handleDataChange}
            isCompany={true}
            isDateStart={true}
            isDateEnd={true}
            visibilityRole={this.props.visibilityRole}
            visibilityType={this.props.visibilityType}
            preloadRole={this.props.preloadRole}
            preloadType={this.props.preloadType}
            limitUserType={this.props.limitUserType}
            limitUserRole={this.props.limitUserRole}
            isAll={true}
          />
          {this.state.data.length > 0 ? (
            this.state.data.map((graph, idx) => (
              <div
                key={idx}
                ref={(ref) => {
                  this.refGraphCard[idx] = { current: ref };
                }}
              >
                <GraphCard
                  isPrint={this.state.isPrint}
                  company={this.state.company}
                  height={graph.height ? graph.height : null}
                  title={graph.title}
                  dataSet={graph.dataSet}
                  secondYAxis={idx !== 0 ? true : false}
                  goal={graph.goal ? graph.goal : null}
                  year={this.state.year}
                  average={
                    idx !== 0 && graph.dataSet[0].x && graph.dataSet[0].y
                      ? (this.getSum(graph.dataSet[0].x) /
                          this.getSum(graph.dataSet[0].y)) *
                        100
                      : null
                  }
                  total={
                    idx !== 0
                      ? graph.dataSet[1].data.reduce(
                          (acc, currValue) => acc + currValue,
                          0
                        )
                      : null
                  }
                />
              </div>
            ))
          ) : (
            <Card>
              <p>Aucune donnée n'est disponible</p>
            </Card>
          )}
        </FadeIn>
        <Loader show={this.state.isLoad} />
        <PrintClass
          refs={this.state.isPrint ? this.refGraphCard : []}
          title={"Selfcare"}
          fileName={"Selfcare"}
          onPrint={(print) => this.setState({ isPrint: print, isLoad: print })}
          width={1500}
        />
      </>
    );
  }
}

export default Selfcare;
