import React, { Component } from "react";

export default class MapMarker extends Component {
  getSize = (coefficient) => {
    return `${65 * coefficient}`;
  };

  render() {
    return (
      <div
        className="border-marker"
        style={{
          position:"absolute",
          height: `${this.getSize(this.props.coefficient)}px`,
          width: `${this.getSize(this.props.coefficient)}px`,
          top: `-${this.getSize(this.props.coefficient) / 2}px`,
          left: `-${this.getSize(this.props.coefficient) / 2}px`,
          borderRadius: "100%",
          backgroundColor: `rgba(255, 20, 20, 0.1)`,
          border: `solid 1px rgba(255, 20, 20, 1)`,
        }}
      />
    );
  }
}
