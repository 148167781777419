import React, { Component } from 'react'
import FadeIn from 'react-fade-in'
import { Col, Row } from 'react-flexbox-grid'
import { Card, Table, Tbody, Td, Th, Thead, Tr } from 'ui-kit-ck-consultant'

export class CardAGPM extends Component {
  render() {
    const data = [
      ['Delais'],
      ['Délai moyen Dépôt (Véhicules Rép. + Non Rép.)', ''],
      ['Expertise', ''],
      ['Intervention', ''],
      ['Délai moyen Dépôt (Véhicules Rép.)', ''],
      ['Expertise', ''],
      ['Intervention', ''],
      ['Délai moyen Dépôt (Véhicules Non Rép.)', ''],
      ['Expertise', ''],
      ['Intervention', ''],
      ['SD29 - OM', ''],
      
      ['NB'],
      ['Nombre OM (sans doublon)', ''],
      ['Nombre RE (sans doublon)', ''],
      ['  dont RE Annule et Remplace', ''],
      ['Nombre NH (sans doublon)', ''],
      
      ['Honoraire'],
      ['Nombre NH (sans doublon) épuré', ''],
      ['Moyenne montant TTC des NH ', ''],
      ['NH (expertise sur rép.)', ''],
      ['NH (non rép.)', ''],
      ['NH (carence)', ''],
      ['NH (VGA / suivi VEI, VGE)', ''],
      ['NH (Rapport en estimation)', ''],
      ['NH (Autres)', ''],
      ['% Nb NH expertise sur rép. / total NH', ''],
      ['NH non rép. / total NH', ''],
      ['NH carence / total NH', ''],
      ['NH VGA / suivi VEI, VGE / total NH', ''],
      ['NH Rapport en estimation / total NH', ''],
      ['NH Autres / total NH', ''],
      
      ['Tous RE'],
      ['Nb de RE sur Réparables', ''],
      ['Réseau Agréé', ''],
      ['Hors Réseau Agréé', ''],
      ['% Nb de RE Réseau Agréé / Total RE', ''],
      ['Coût Moyen Total HT', ''],
      ['Réseau Agréé', ''],
      ['Hors Réseau Agréé', ''],
      ['% Montant MO / Montant Total', ''],
      ['% Montant Pièces / Montant Total', ''],
      ['% Montant Forfaits / Montant Total', ''],
      ['% Montant Peinture / Montant Total', ''],
      ['% Montant Autres / Montant Total', ''],
      ['Nb de RE avec procédure VE', ''],
      
      ['EAD'],
      ['Nombre de RE', ''],
      ['Coût Moyen HT', ''],
      
      ['RRR'],
      ['Nombre de RE avec RRR', ''],
      ['Coût Moyen RRR (rép en ayant)', ''],
      ['Coût Moyen RRR (tous RRR)', ''],
      
      ['Main D\'Oeuvre'],
      ['Nombre de RE ayant de la MO', ''],
      ['Coût Moyen Main d\'oeuvre HT (rép en ayant)', ''],
      ['Réseau Agréé', ''],
      ['Hors Réseau Agréé', ''],
      ['Taux Moyen Horaire HT', ''],
      ['Réseau Agréé', ''],
      ['Hors Réseau Agréé', ''],
      ['Nb Moyen Heure (rép en ayant)', ''],
      ['Réseau Agréé', ''],
      ['Hors Réseau Agréé', ''],
      
      ['Pièces'],
      ['Nombre de RE ayant des pièces', ''],
      ['Coût Moyen Pièces HT (rép en ayant)', ''],
      ['Réseau Agréé', ''],
      ['Hors Réseau Agréé', ''],
      
      ['Forfaits'],
      ['Nombre de RE ayant un ou plusieurs forfaits', ''],
      ['Coût Moyen forfaits', ''],
      ['Nombre de forfaits réparation pièces plastiques', ''],
      ['Coût Moyen forfaits pièces plastiques', ''],

      ['ING Peinture'],
      ['Nombre de RE ayant de la peinture', ''],
      ['Coût Moyen Peinture HT (rép en ayant)', ''],
      ['Réseau Agréé', ''],
      ['Hors Réseau Agréé', ''],
      ['Nb Moyen d\'heures Peinture', ''],
      ['% Montant vernis / total ingrédients Peinture', ''],
      ['% Montant nacré / total ingrédients Peinture', ''],

      ['Irréparables'],
      ['Nombre de RE / NON Réparables', ''],
      ['Montant Moyen TTC des VRADE', ''],
      ['Nombre de RE ayant des valeurs de sauvetage', ''],
      ['Montant Moyen des valeurs de sauvetage', ''],
      ['% Montant des Valeurs de Sauvetage / Montant VRADE', ''],
      ['% Nb Irréparables / Total RE', ''],
      ['Nombre de RE avec procédure RE', ''],
    ]
    const dataRep = [
      ['Classes'],
      ['de 20 à 200€ (Nb)', ''],
      ['Moyenne', ''],
      ['de 200 à 500€ (Nb)', ''],
      ['Moyenne', ''],
      ['de 500 à 1000€ (Nb)', ''],
      ['Moyenne', ''],
      ['de 1000 à 1800€ (Nb)', ''],
      ['Moyenne', ''],
      ['de 1800 à 2500€ (Nb)', ''],
      ['Moyenne', ''],
      ['de 2500 à 5000€ (Nb)', ''],
      ['Moyenne', ''],
      ['de 5000 à 10000€ (Nb)', ''],
      ['Moyenne', ''],
      ['plus de 10000€ (Nb)', ''],
      ['Moyenne', ''],
      
      ['Marques'],
      ['RENAULT (Nb)', ''],
      ['Moyenne', ''],
      ['PEUGEOT (Nb)', ''],
      ['Moyenne', ''],
      ['CITROEN (Nb)', ''],
      ['Moyenne', ''],
      ['FORD (Nb)', ''],
      ['Moyenne', ''],
      ['VOLKSWAGEN (Nb)', ''],
      ['Moyenne', ''],
      ['OPEL (Nb)', ''],
      ['Moyenne', ''],
      ['FIAT (Nb)', ''],
      ['Moyenne', ''],
      ['SEAT (Nb)', ''],
      ['Moyenne', ''],
      ['AUDI (Nb)', ''],
      ['Moyenne', ''],
      ['BMW (Nb)', ''],
      ['Moyenne', ''],
      ['MERCEDES (Nb)', ''],
      ['Moyenne', ''],
      ['JAPONAIS (Nb)', ''],
      ['Moyenne', ''],
      ['AUTRES (Nb)', ''],
      ['Moyenne', ''],
      
      ['Event'],
      ['COLLISION (Nb)', ''],
      ['Moyenne', ''],
      ['VOL (Nb)', ''],
      ['Moyenne', ''],
      ['NATURELS (Nb)', ''],
      ['Moyenne', ''],
      ['Bris de Glace (Nb)', ''],
      ['Moyenne', ''],
      ['Autres (Nb)', ''],
      ['Moyenne', ''],
      ['Indeterminés (Nb)', ''],
      ['Moyenne', ''],
      
      ['Age'],
      ['Age Moyen véhicules réparables', ''],
      ['Age Moyen véhicules non réparables', ''],
      
      ['Écrêtement'],
      ['Nombre de RE / Réparables', ''],
      ['Coût Moyen Total', ''],
      ['Coût Moyen Pièces', ''],
      ['Coût Moyen MO', ''],
      ['Coût Moyen ingr. Peinture', ''],
      ['Nombre de RE / NON Réparables', ''],
      ['Montant Moyen TTC des VRADE', ''],
      ['Nombre de RE ayant des valeurs de sauvetage', ''],
      ['Montant Moyen des valeurs de sauvetage', ''],

      ['W NR'],
      ['Nombre de WNR', ''],
      ['Moyenne VRADE des WNR', ''],
      
      ['WRApD'],
      ['Nombre de WRApD', ''],
      ['Moyenne VRADE des WRApD', ''],

      ['RE M'],
      ['Nombre de RE ayant du remorquage', ''],
      ['Coût Moyen Remorquage (rép en ayant)', ''],
      
      ['Anomalies'],
      ['Nombre d\'Anomalies de type "Warning"', ''],
      ['% par rapport au nombre de RE', ''],
      ['Nombre d\'Anomalies de type "Rejet"', ''],
      ['% par rapport au nombre de RE', ''],
      ['Nb Modification du genre entre OM et RE', ''],
      ['Nb RE dont la nature d\'expertise <> périmètre', ''],
      ['Nb RE rejetés suite à la règle sur Annule et Remplace', ''],
      
      ['Pièces Plastiques Total'],
      ['Nb d\'Interventions (NI_Tot)', ''],
      ['% NI_Tot / Nb RE sur véhicules Rép.', ''],
      ['Nb de Remplacements (NR_Tot)', ''],
      ['% NR_Tot / NI_Tot', ''],
      ['Coût Moyen des pièces remplacées', ''],
      ['Nb de Réparation - Peinture (NP_Tot)', ''],
      ['% NP_Tot / NI_Tot', ''],
      ['Nb de Réparation - Ajout de Matière (NM_Tot)', ''],
      ['% NM_Tot / NI_Tot', ''],
      ['Nb RE n\'ayant que des rép. plastiques', ''],
      ['Coût Moyen MO de la réparation', ''],
      
      ['Pièces Plastiques avant'],
      ['Nb d\'Interventions (NI_Av)', ''],
      ['Nb de Remplacements (NR_Av)', ''],
      ['% NR_Av / NI_Av', ''],
      ['Nb de Réparation - Peinture (NP_Av)', ''],
      ['% NP_Av / NI_Av', ''],
      ['Nb de Réparation - Ajout de Matière (NM_Av)', ''],
      ['% NM_Av / NI_Av', ''],
      
      ['Pièces Plastiques arrière'],
      ['Nb d\'Interventions (NI_Av)', ''],
      ['Nb de Remplacements (NR_Av)', ''],
      ['% NR_Av / NI_Av', ''],
      ['Nb de Réparation - Peinture (NP_Av)', ''],
      ['% NP_Av / NI_Av', ''],
      ['Nb de Réparation - Ajout de Matière (NM_Av)', ''],
      ['% NM_Av / NI_Av', ''],
      
      ['Pièces Plastiques Autre'],
      ['Nb d\'Interventions (NI_Aut)', ''],
      ['% de réparation hors pièces plastiques', ''],
      ['% Pièces stratégiques suivies', ''],

    ]
    return (
      <FadeIn>
        <Row>
          <Col xs={12} lg={6}>
            <Card title={'Général'} className={'mt-10 mt-30-xl'}>
              <Table>
                <Thead>
                  <Tr>
                    <Th hide></Th>
                    <Th radiusTopLeft>Agence</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data.map((row, idx) => {
                    if (row.length > 1) {
                      return (
                        <Tr key={idx}>
                          {row.map((column, id) => id === 0 ? <Th radiusTopLeft={idx === 0 ? true : false} key={id}>{column}</Th> : <Td key={id}>{column}</Td>)}
                        </Tr>
                      )
                    } else {
                      return <Tr><Th colSpan={2}>{row[0]}</Th></Tr>
                    }
                  })}
                </Tbody>
              </Table>
              <div style={{ fontSize: "10px" }} className="mb-20">
                <p>Genre: VP</p>
              </div>
            </Card>
          </Col>

          <Col xs={12} lg={6}>
            <Card title={'Répartition'} className={'mt-10 mt-30-xl'}>
              <Table>
                <Thead>
                  <Tr>
                    <Th hide></Th>
                    <Th radiusTopLeft>Agence</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {dataRep.map((row, idx) => {
                    if (row.length > 1) {
                      return (
                        <Tr key={idx}>
                          {row.map((column, id) => id === 0 ? <Th radiusTopLeft={idx === 0 ? true : false} key={id}>{column}</Th> : <Td key={id}>{column}</Td>)}
                        </Tr>
                      )
                    } else {
                      return <Tr><Th colSpan={2}>{row[0]}</Th></Tr>
                    }
                  })}
                </Tbody>
              </Table>
              <div style={{ fontSize: "10px" }} className="mb-20">
                <p>Genre: VP</p>
              </div>
            </Card>
          </Col>
        </Row>
      </FadeIn>
    )
  }
}

export default CardAGPM
