import React, { Component } from "react";
import { Card, Button } from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";

import PlanningModal from "./PlanningModal";

import AuthContext from "../../../context/AuthContext";

export default class Declare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
    };
  }

  static contextType = AuthContext;

  getTypeModalLeave = () => {
    if (this.context.role >= 3) {
      return 0;
    } else if ([1, 2, 3].includes(this.context.type)) {
      if(this.context.role === 1){
        return 3
      }
      return 1;
    } else if ([4, 5].includes(this.context.type)) {
      if(this.context.role === 2){
        return 4
      }
      return 2;
    } else {
      return 5;
    }
  };

  render() {
    const titleModal = [
      "Ajouter une absence",
      "Demande de congés expert",
      "Demande de congés assistant(e)",
      "Ajouter une absence expert",
      "Ajouter une absence assistant(e)",
      "N.C.",
    ];
    const type = this.getTypeModalLeave();

    return (
      <>
        <PlanningModal
          userOptions={this.props.userOptions}
          title={titleModal[type]}
          type={type}
          show={this.state.isModalOpen}
          onClose={() => this.setState({ isModalOpen: false })}
        />
        <Card title="Formulaires" className={this.props.className}>
          <Row>
            <Col xs={12} md={12} className={"mb-10"}>
              <Button
                text={titleModal[type]}
                onClick={() => this.setState({ isModalOpen: true })}
              />
            </Col>
          </Row>
        </Card>
      </>
    );
  }
}
