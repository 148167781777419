import React, { Component } from "react";
import { Card, BarChart, DoughnutChart } from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import AuditListActionPlan from "./AuditListActionPlan";

export default class CardPerformanceAuditActionPlan extends Component {
  render() {
    console.log({
      type: "bar",
      label: "% d'atteinte",
      data: this.props.dataTabNbAudit.map((element) => [
        (element.total / element.count) * 100,
      ]),
      color: this.props.dataTabNbAudit.map((element) => [element.color]),
    });
    return (
      <div>
        <>
          <Card
            title={"Performance audit plan d'action"}
            className={"mt-10 mt-30-xl " + this.props.className}
          >
            <Row>
              <Col md={12} className={"my-10"}>
                <h3>Nombre d'audits / type</h3>

                <BarChart
                  digits={0}
                  notAlign
                  legendDisplay={true}
                  widthBar={6}
                  dataSet={
                    this.props.dataTabNbAudit ? this.props.dataTabNbAudit : []
                  }
                  yAxes={[
                    {
                      beginAtZero: true,
                      ticks: {
                        display: false,
                      },
                    },
                  ]}
                />
              </Col>
              <Col md={10} xs={12} className={"my-10"}>
                <h3>Pourcentage d'atteinte / Indicateurs</h3>
                <BarChart
                  digits={0}
                  format={"percentage"}
                  notAlign
                  legendDisplay={true}
                  widthBar={6}
                  dataSet={[
                    {
                      type: "bar",
                      label: "% d'atteinte",
                      data: this.props.dataTabNbAudit.map(
                        (element) => (element.total / element.count) * 100
                      ),
                      color: this.props.dataTabNbAudit.map(
                        (element) => element.color
                      ),
                    },
                  ]}
                  yAxes={[
                    {
                      beginAtZero: true,
                      ticks: {
                        display: false,
                      },
                    },
                  ]}
                  labels={this.props.dataTabNbAudit.map(
                    (element) => element.label
                  )}
                />
              </Col>
              <Col md={12} xl={2} className={"my-10"}>
                <h3>
                  {this.props.dataDoughnut && this.props.dataDoughnut.title}
                </h3>
                <div className={"d-flex"}>
                  <DoughnutChart
                    digits={0}
                    className={"m-auto"}
                    number={
                      this.props.dataDoughnut && this.props.dataDoughnut.number
                    }
                    text={
                      this.props.dataDoughnut && this.props.dataDoughnut.text
                    }
                  />
                </div>
              </Col>
              {this.props.dataTab && (
                <Col xl={12} className={"my-10 overflow-x-auto"}>
                  <AuditListActionPlan
                    dataTab={this.props.dataTab}
                    isPrint={this.props.isPrint}
                  />
                </Col>
              )}
            </Row>
          </Card>
        </>
      </div>
    );
  }
}
