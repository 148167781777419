import React, { Component } from "react";
import { Col, Row } from "react-flexbox-grid";
import { Card, Signature } from "ui-kit-ck-consultant";
import { Buffer } from "buffer";

export class SignaturesDisplayCloture extends Component {
  convertSignature = (signature) => {
    if (typeof signature === "string") {
      return signature;
    } else {
      return `data:${signature.type};base64,${new Buffer.from(
        signature.data
      ).toString("base64")}`;
    }
  };

  render() {
    return (
      <Card className={"mt-30"} title={"Signatures"}>
        <Row>
          {this.props.signatureData &&
            this.props.signatureData.map((sign, idx) => {
              return (
                <Col xs={12} md={6} key={idx}>
                  <h2>{sign.name}</h2>
                  <Signature
                    disabled
                    className="mt-10"
                    signature={
                      sign.signature
                        ? this.convertSignature(sign.signature)
                        : null
                    }
                  />
                </Col>
              );
            })}
        </Row>
      </Card>
    );
  }
}

export default SignaturesDisplayCloture;
