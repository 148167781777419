import React, { Component } from "react";
import {
  Loader,
  Nav,
  Card,
  Modal,
  FormCheckbox,
  Button,
} from "ui-kit-ck-consultant";
import FadeIn from "react-fade-in";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import ShowOptionsNGRA from "../../components/general/ShowOptionsNGRA-v2";

import axiosConfig from "../../utils/axiosConfig";

export default class Tracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: true,
      isModal: false,
      idxModal: null,
      // ShowOption state
      network: 0,
      group: 0,
      region: 0,
      agency: 0,
      user: 0,
      date: null,
      // data
      data: [],
      dataRoads: [],
      dataAgency: [],
      googleMaps: null,
      isAllFolder: false,
      isAgency: true,
      dateSync: false,
    };

    this.timeoutData = null;

    this.flightPaths = [];
  }

  // ShowOption data

  handleDataChange = (data) => {
    this.setState(
      {
        network: data.network,
        group: data.group,
        region: data.region,
        agency: data.agency,
        user: data.user,
        date: data.dateEnd,
      },
      () => {
        this.getData();
      }
    );
  };

  handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    this.setState({ [name]: checked });
  };

  hasValueDeep = (json, findValue) => {
    for (var key in json) {
      if (json[key]["lat"] === findValue) return true;
    }
    return false;
  };

  decodePolyline(encoded) {
    if (!encoded) {
      return [];
    }
    var poly = [];
    var index = 0,
      len = encoded.length;
    var lat = 0,
      lng = 0;

    while (index < len) {
      var b,
        shift = 0,
        result = 0;

      do {
        b = encoded.charCodeAt(index++) - 63;
        result = result | ((b & 0x1f) << shift);
        shift += 5;
      } while (b >= 0x20);

      var dlat = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
      lat += dlat;

      shift = 0;
      result = 0;

      do {
        b = encoded.charCodeAt(index++) - 63;
        result = result | ((b & 0x1f) << shift);
        shift += 5;
      } while (b >= 0x20);

      var dlng = (result & 1) !== 0 ? ~(result >> 1) : result >> 1;
      lng += dlng;

      var p = {
        lat: lat / 1e5,
        lng: lng / 1e5,
      };
      poly.push(p);
    }

    return poly;
  }

  getData = () => {
    if (this.timeoutData !== null) {
      clearInterval(this.timeoutData);
      this.timeoutData = null;
    }

    this.setState(
      {
        isLoad: true,
      },
      () => {
        axiosConfig("tracking/get-data", {
          data: {
            network: this.state.network,
            group: this.state.group,
            region: this.state.region,
            agency: this.state.agency,
            user: this.state.user,
            date: this.state.date,
          },
        })
          .then((res) => {
            if (this.flightPaths.length) {
              this.flightPaths.map((flightPath) => {
                flightPath.setMap(null);
                return null;
              });
              this.flightPaths = [];
            }

            if (res.data.success) {
              for (const key in res.data.dataRoads) {
                const element = res.data.dataRoads[key];

                element.points.forEach((test) => {
                  this.flightPaths.push(
                    new this.state.googleMaps.maps.Polyline({
                      path: this.decodePolyline(test),
                      geodesic: false,
                      strokeColor: element.color,
                      strokeOpacity: 1,
                      strokeWeight: 2,
                    })
                  );
                  this.flightPaths[this.flightPaths.length - 1].setMap(
                    this.state.googleMaps.map
                  );
                });
              }

              this.setState(
                {
                  data: res.data.data,
                  dataRoads: res.data.dataRoads,
                  dataAgency: res.data.dataAgency,
                  dateSync: moment(),
                  isLoad: false,
                },
                () => {
                  this.timeoutData = setInterval(() => {
                    this.getData();
                  }, 60 * 60 * 1000);
                }
              );
            } else {
              this.setState({ isLoad: false });
            }
          })
          .catch((res) => {
            this.setState({ isLoad: false });
          });
      }
    );
  };

  handleApiLoaded = (google) => {
    this.setState({ googleMaps: google });
  };

  handleOpenModal = (idx) => {
    this.setState({
      isModal: true,
      idxModal: idx,
    });
  };

  render() {
    return (
      <FadeIn>
        <Nav
          title={`Où est mon expert ${
            this.state.dateSync
              ? this.state.dateSync.format("DD/MM/YYYY HH:mm:ss")
              : ""
          }`}
          className="mb-10 mb-20-xl"
        />
        <ShowOptionsNGRA
          isChange={true}
          className="mb-10 mb-20-xl"
          onDataChange={this.handleDataChange}
          isUser={true}
          isDateEnd={true}
          dateEndName={"Date"}
          visibilityRole={this.props.visibilityRole}
          visibilityType={this.props.visibilityType}
          preloadRole={this.props.preloadRole}
          preloadType={this.props.preloadType}
          limitUserType={this.props.limitUserType}
          limitUserRole={this.props.limitUserRole}
          userType={[1, 2]}
          isDefaultAllUser={true}
          others={
            <Button text="Acutaliser" onClick={() => this.getData()}>
              <FontAwesomeIcon icon={faSync} />
            </Button>
          }
        />
        <Card title="Carte" className="mb-10 mb-20-xl">
          <FormCheckbox
            className="mb-10"
            name="isAllFolder"
            checked={this.state.isAllFolder}
            onChange={this.handleCheckboxChange}
            text="Afficher toutes les expertises"
          />
          <FormCheckbox
            className="ml-10 mb-10"
            name="isAgency"
            checked={this.state.isAgency}
            onChange={this.handleCheckboxChange}
            text="Afficher les agences"
          />
          <div style={{ height: "900px" }}>
            {/* <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyDxQiiLJaitFNPs4NCNbjB3DTaY_rWd_Fs",
              }}
              center={{
                lat: 46.227638,
                lng: 2.213749,
              }}
              zoom={7}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={(google) => this.handleApiLoaded(google)}
            >
              {Object.keys(this.state.dataRoads).map((key) => {
                return this.state.dataRoads[key].paths.map((path, pathIdx) => (
                  <MapMarker
                    key={`${key}-${pathIdx}`}
                    lat={path.lat}
                    lng={path.lng}
                    color={this.state.dataRoads[key].color}
                    expertColor={null}
                    icon={
                      this.state.dataRoads[key].locationCode === 1
                        ? faUserAlt
                        : this.state.dataRoads[key].locationCode === 3
                        ? faBuilding
                        : faMapMarkerAlt
                    }
                    onOpenModal={() => this.handleOpenModal(path.idx)}
                    text={pathIdx + 1}
                  />
                ));
              })}
              {this.state.isAllFolder
                ? this.state.data.map((element, idx) => {
                    if (
                      element.garageLatitude &&
                      element.garageLongitude &&
                      element.typeData === "2" &&
                      element.locationCode !== 1 &&
                      element.locationCode !== 3
                    ) {
                      return (
                        <MapMarker
                          key={idx}
                          lat={element.garageLatitude}
                          lng={element.garageLongitude}
                          color={element.expertColor}
                          expertColor={null}
                          icon={faMapMarkerAlt}
                          onOpenModal={() => this.handleOpenModal(idx)}
                        />
                      );
                    } else if (
                      element.clientLatitude &&
                      element.clientLongitude &&
                      element.typeData === "2" &&
                      element.locationCode === 1
                    ) {
                      return (
                        <MapMarker
                          key={idx}
                          lat={element.clientLatitude}
                          lng={element.clientLongitude}
                          color={element.expertColor}
                          expertColor={null}
                          icon={faUserAlt}
                          onOpenModal={() => this.handleOpenModal(idx)}
                        />
                      );
                    } else if (
                      element.agencyLatitude &&
                      element.agencyLongitude &&
                      element.typeData === "2" &&
                      element.locationCode === 3
                    ) {
                      return (
                        <MapMarker
                          key={idx}
                          lat={element.agencyLatitude}
                          lng={element.agencyLongitude}
                          color={element.expertColor}
                          expertColor={null}
                          icon={faBuilding}
                          onOpenModal={() => this.handleOpenModal(idx)}
                        />
                      );
                    } else {
                      return null;
                    }
                  })
                : null}
              {this.state.isAgency
                ? this.state.dataAgency.map((element, idx) => {
                    if (element.lat && element.lng) {
                      return (
                        <MapMarker
                          key={idx}
                          lat={element.lat}
                          lng={element.lng}
                          // color={element.expertColor}
                          // expertColor={null}
                          icon={faBuilding}
                          // id={element.id}
                          onOpenModal={() => {}}
                        />
                      );
                    } else {
                      return null;
                    }
                  })
                : null}
            </GoogleMapReact> */}
          </div>
        </Card>
        <Modal
          show={this.state.isModal}
          onClose={() => this.setState({ isModal: false, idxModal: null })}
          title="Expertise"
        >
          {this.state.idxModal !== null ? (
            <>
              <p>
                Date de passage :{" "}
                <b>{this.state.data[this.state.idxModal].date}</b>
              </p>
              <p>
                Référence :{" "}
                <b>{this.state.data[this.state.idxModal].reference}</b>
              </p>
              <p>
                Lieu RDV :{" "}
                <b>{this.state.data[this.state.idxModal].location}</b>
              </p>
              <p>
                Nature expertise :{" "}
                <b>{this.state.data[this.state.idxModal].natureExpertise}</b>
              </p>
              <p>
                Expert : <b>{this.state.data[this.state.idxModal].expert}</b>
              </p>
              {this.state.data[this.state.idxModal].locationCode !== 1 &&
              this.state.data[this.state.idxModal].locationCode !== 3 ? (
                <>
                  <p>
                    Réparateur :{" "}
                    <b>{this.state.data[this.state.idxModal].garageName}</b>
                  </p>
                  <p>
                    Adresse :{" "}
                    <b>
                      {this.state.data[this.state.idxModal].garageAddress1},{" "}
                      {this.state.data[this.state.idxModal].garageAddress2}
                    </b>
                  </p>
                  <p>
                    Code postal / Ville :{" "}
                    <b>
                      {this.state.data[this.state.idxModal].garageZipCode}{" "}
                      {this.state.data[this.state.idxModal].garageCity}
                    </b>
                  </p>
                </>
              ) : null}
              {this.state.data[this.state.idxModal].locationCode === 1 ? (
                <>
                  <p>
                    Adresse :{" "}
                    <b>
                      {this.state.data[this.state.idxModal].clientAddress1},{" "}
                      {this.state.data[this.state.idxModal].clientAddress2}
                    </b>
                  </p>
                  <p>
                    Code postal / Ville :{" "}
                    <b>
                      {this.state.data[this.state.idxModal].clientZipCode}{" "}
                      {this.state.data[this.state.idxModal].clientCity}
                    </b>
                  </p>
                </>
              ) : null}
            </>
          ) : null}
        </Modal>
        <Loader show={this.state.isLoad} />
      </FadeIn>
    );
  }
}
