import React, { Component } from "react";
import FadeIn from "react-fade-in";
import { Col, Row } from "react-flexbox-grid";
import {
  BarChart,
  Card,
  PieChart,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "ui-kit-ck-consultant";

import {colors} from "../../../../utils/colors";

import axiosConfig from "../../../../utils/axiosConfig";
export class CardGENERALI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      search: null,
      isLoad: false,
    };
  }
  componentDidMount() {
    this.getPerformance();
  }

  componentDidUpdate = (prevProps) => {
    if (
      JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data) &&
      parseInt(this.props.data.company) === 16
    ) {
      this.getPerformance();
    }
  };

  getPerformance = () => {
    this.setState({ isLoad: true, data: null }, () => {
      axiosConfig("/company-performance/generali", {
        data: {
          dateStart: this.props.data.dateStart,
          dateEnd: this.props.data.dateEnd,
          network: this.props.data.network,
          group: this.props.data.group,
          region: this.props.data.region,
          agency: this.props.data.agency,
          user: this.props.data.user,
          company: this.props.data.company,
        },
      }).then((res) => {
        if (res.data.success) {
          this.setState({
            data: res.data.result,
            search: res.data.search,
            isLoad: false,
          });
        } else {
          this.setState({
            data: null,
            search: null,
            isLoad: false,
          });
        }
      });
    });
  };

  render() {
    const generalData = this.state.data
      ? [
          {
            Labels: [
              "Coût Moyen Réparables",
              "Coût Moyen Notes d'honoraires",
              "Perte Totale",
            ],
            subLabels: [
              ["Total (€)", "Agrée (€)", "Non Agrée (€)"],
              ["Total (€)", "Rép (€)", "Non Rép (€)"],
              ["NB RE", "% VRADE", "VRADE (€)"],
            ],
            data: [
              {
                data: [
                  this.state.data.cost,
                  this.state.data.costApproved,
                  this.state.data.costNotApproved,
                  this.state.data.honorary,
                  this.state.data.honoraryApproved,
                  this.state.data.honoraryNotApproved,
                  this.state.data.nbFilesNR,
                  this.state.data.txNR,
                  this.state.data.VRADE,
                ],
              },
            ],
          },
          {
            Labels: ["Analyse des coûts Moyens en réparation"],
            subLabels: [
              [
                "NB RE",
                "NB RE/Agrée",
                "Tx H MO (%)",
                "NB H MO (heures)",
                "Coût Pièces (€)",
                "Coût Peint (€)",
                "%MO",
                "%Pièces",
                "%Ing",
              ],
            ],
            data: [
              {
                title: "Libéral",
                data: [
                  this.state.data.nbFiles,
                  this.state.data.nbFilesApproved,
                  this.state.data.rateMO,
                  this.state.data.hourMO,
                  this.state.data.costPiece,
                  this.state.data.costP,
                  this.state.data.txMO,
                  this.state.data.txPiece,
                  this.state.data.txIng,
                ],
              },
            ],
          },
        ]
      : [];

    const generalDataChart = this.state.data
      ? [
          {
            title: "Délai de dépôt RE/Rép",
            labels: [
              "T1 N-1",
              "T2 N-1",
              "T3 N-1",
              "T4 N-1",
              "T1 N",
              "T2 N",
              "T3 N",
              "T4 N",
            ],
            dataSet: [
              {
                type: "line",
                label: "",
                data: this.state.data.byMonth.delayRep,
                color: "DarkBlue",
              },
            ],
            color: [colors.blue[0]],
          },
          {
            title: "Délai de dépôt RE/Non Rép",
            labels: [
              "T1 N-1",
              "T2 N-1",
              "T3 N-1",
              "T4 N-1",
              "T1 N",
              "T2 N",
              "T3 N",
              "T4 N",
            ],
            dataSet: [
              {
                type: "line",
                label: "",
                data: this.state.data.byMonth.delayNotRep,
                color: "DarkBlue",
              },
            ],
            color: [colors.blue[0]],
          },
          {
            title: "Évolution CM Réparation",
            labels: [
              "T1 N-1",
              "T2 N-1",
              "T3 N-1",
              "T4 N-1",
              "T1 N",
              "T2 N",
              "T3 N",
              "T4 N",
            ],
            dataSet: [
              {
                type: "line",
                label: "",
                data: this.state.data.byMonth.CM,
                color: "DarkBlue",
              },
            ],
          },
          {
            title: "Évolution VRADE",
            labels: [
              "T1 N-1",
              "T2 N-1",
              "T3 N-1",
              "T4 N-1",
              "T1 N",
              "T2 N",
              "T3 N",
              "T4 N",
            ],
            dataSet: [
              {
                type: "line",
                label: "",
                data: this.state.data.byMonth.VRADE,
                color: colors.blue[0],
              },
            ],
          },
        ]
      : [];

    const analyseRNR = this.state.data
      ? [
          {
            title: "Répartition des âges dans le secteur",
            labels: [
              "a 0-2",
              "b 2-4",
              "c 4-6",
              "d 6-8",
              "e 8-10",
              "f 10-15",
              "g 15+",
            ],
            data: this.state.data.sliceAge,
            color: [
              colors.blue[1],
              colors.blue[0],
              colors.green[1],
              colors.green[0],
              colors.green[2],
              colors.red[0],
              colors.red[2],
            ],
          },
          {
            title: "Age Fréquence",
            labels: [
              "a 0-2",
              "b 2-4",
              "c 4-6",
              "d 6-8",
              "e 8-10",
              "f 10-15",
              "g 15+",
            ],
            data: [
              {
                label: "(Années)",
                data: this.state.data.sliceAge,
                color: colors.blue[0],
              },
            ],
          },
        ]
      : [];

    const analyseR = this.state.data
      ? [
          {
            title: "Répartition des tranches dans le secteur",
            labels: [
              "a 0-300",
              "b 300-500",
              "c 500-800",
              "d 800-1k",
              "e 1k-1.5k",
              "f 1.5k-2k",
              "g 2k-3k",
              "h 3k-5k",
              "i 5k+",
            ],
            data: this.state.data.sliceCost,
            color: [
              colors.blue[1],
              colors.blue[0],
              colors.blue[2],
              colors.green[1],
              colors.green[0],
              colors.green[2],
              colors.red[1],
              colors.red[0],
              colors.red[2],
            ],
          },
          {
            title: "CM / Age",
            labels: [
              "a 0-2",
              "b 2-4",
              "c 4-6",
              "d 6-8",
              "e 8-10",
              "f 10-15",
              "g 15+",
            ],
            data: [
              {
                type: "line",
                label: "",
                data: this.state.data.sliceAgeCost,
                color: colors.blue[0],
              },
            ],
          },
        ]
      : [];

      const centerStyle = { textAlign: "center" }

    return (
      <FadeIn>
        <Card
          title={"Géneral"}
          className={"mt-10 mt-30-xl "}
          classNameChildren={"overflow-x-auto"}
        >
          {generalData.map((tab) => {
            return (
              <Table className={"mt-30"}>
                <Thead>
                  <Tr>
                    <Th hide></Th>
                    {tab.Labels.map((label, idx) => {
                      return (
                        <>
                          {tab.subLabels[idx].map((item, id) => {
                            let middle =
                              Math.round(tab.subLabels[idx].length / 2) - 1;
                            if (id === 0) {
                              return (
                                <Th
                                  className={"nowrap"}
                                  radiusTopLeft={idx === 0 ? true : false}
                                  spaceLeft={id === 0 ? true : false}
                                ></Th>
                              );
                            } else if (middle === id) {
                              return (
                                <Th className={"text-center nowrap"}>
                                  {label}
                                </Th>
                              );
                            } else if (id === tab.subLabels[idx].length - 1) {
                              return <Th spaceRight></Th>;
                            } else {
                              return <Th></Th>;
                            }
                          })}
                        </>
                      );
                    })}
                  </Tr>
                </Thead>
                <Thead>
                  <Tr>
                    <Th hide></Th>
                    {tab.Labels.map((label, idx) => {
                      return tab.subLabels[idx].map((rep, id) => (
                        <Th spaceLeft spaceRight style={centerStyle} className={"text-center nowrap"}>
                          {rep}
                        </Th>
                      ));
                    })}
                  </Tr>
                </Thead>
                <Tbody>
                  {tab.data.map((row, idx) => {
                    return (
                      <Tr key={idx}>
                        <Th hide></Th>
                        {row.data.map((item) => {
                          return (
                            <Td
                              className={"nowrap"}
                              style={centerStyle}
                            >
                              {item}
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            );
          })}
          <Row className={"mt-30"}>
            {generalDataChart.map((chart, idx) => {
              if ([0, 1].includes(idx)) {
                return (
                  <Col xs={12} md={12} lg={12} className={"mt-30"}>
                    <BarChart
                      title={chart.title}
                      labels={chart.labels}
                      dataSet={chart.dataSet}
                      yAxes={[
                        {
                          ticks: {
                            beginAtZero: true,
                            suggestedMax: 100,
                          },
                        },
                      ]}
                    />
                  </Col>
                );
              } else {
                return (
                  <Col xs={12} md={12} lg={12} className={"mt-30"}>
                    <BarChart
                      title={chart.title}
                      labels={chart.labels}
                      dataSet={chart.dataSet}
                      yAxes={[
                        {
                          ticks: {
                            beginAtZero: true,
                            suggestedMax: 2500,
                          },
                        },
                      ]}
                    />
                  </Col>
                );
              }
            })}
          </Row>
          <div style={{ fontSize: "10px" }} className="mb-20">
            <p>Dossiers DARVA</p>
            <p>Hors EAD</p>
            <p>genre: VP / CTTE</p>
          </div>
        </Card>
        <Card
          title={"Analyse réparables et non réparables"}
          className={"mt-10 mt-30-xl "}
          classNameChildren={"overflow-x-auto"}
        >
          <Row>
            {analyseRNR.map((chart, id) => {
              if (id === 0) {
                return (
                  <Col xs={12} md={6} lg={6}>
                    <PieChart
                      digits={0}
                      title={chart.title}
                      full
                      legendDisplay={true}
                      data={chart.labels.map((item, id) => {
                        return {
                          labels: item,
                          color: chart.color[id],
                          data: chart.data[id],
                        };
                      })}
                    />
                  </Col>
                );
              } else {
                return (
                  <Col xs={12} md={6} lg={6}>
                    <BarChart
                      title={chart.title}
                      labels={chart.labels}
                      dataSet={chart.data}
                      yAxes={[
                        {
                          ticks: {
                            beginAtZero: true,
                            suggestedMax: 30,
                          },
                        },
                      ]}
                    />
                  </Col>
                );
              }
            })}
          </Row>
          <div style={{ fontSize: "10px" }} className="mb-20">
            <p>Dossiers DARVA</p>
            <p>Hors EAD</p>
            <p>genre: VP / CTTE</p>
          </div>
        </Card>
        <Card
          title={"Analyse réparables"}
          className={"mt-10 mt-30-xl "}
          classNameChildren={"overflow-x-auto"}
        >
          <Row>
            {analyseR.map((chart, id) => {
              if (id === 0) {
                return (
                  <Col xs={12} md={6} lg={6}>
                    <PieChart
                      digits={0}
                      title={chart.title}
                      full
                      legendDisplay={true}
                      data={chart.labels.map((item, id) => {
                        return {
                          labels: item,
                          color: chart.color[id],
                          data: chart.data[id],
                        };
                      })}
                    />
                  </Col>
                );
              } else {
                return (
                  <Col xs={12} md={6} lg={6}>
                    <BarChart
                      title={chart.title}
                      labels={chart.labels}
                      dataSet={chart.data}
                      yAxes={[
                        {
                          ticks: {
                            beginAtZero: true,
                            suggestedMax: 100,
                          },
                        },
                      ]}
                    />
                  </Col>
                );
              }
            })}
          </Row>
          <div style={{ fontSize: "10px" }} className="mb-20">
            <p>Dossiers DARVA</p>
            <p>Hors EAD</p>
            <p>genre: VP / CTTE</p>
          </div>
        </Card>
      </FadeIn>
    );
  }
}

export default CardGENERALI;
