import React from "react";
import { Col, Row } from "react-flexbox-grid";
import { Card, PieChart, BarChart } from "ui-kit-ck-consultant";

import { colors, getColors } from "../../utils/colors";

export default class Identification extends React.Component {
  render() {
    return (
      <Card className={this.props.className} title="Gestion du dossier">
        <Row>
          <Col xs={12} lg={4} className="d-flex">
            {this.props.generalReparableData ? (
              <PieChart
                className="m-auto"
                digits={0}
                title={"Réparabilité économique"}
                legendDisplay={true}
                data={[
                  {
                    data: this.props.generalReparableData[0],
                    labels: "Réparable",
                    color: colors.green[0],
                  },
                  {
                    data: this.props.generalReparableData[1],
                    labels: "Non réparable",
                    color: colors.red[0],
                  },
                ]}
              />
            ) : null}
          </Col>
          <Col xs={12} lg={4} className="d-flex">
            {this.props.generalTypesData ? (
              <BarChart
                className="w-100"
                title="Type de sinistre"
                labels={this.props.generalTypesData.labels}
                dataSet={[
                  {
                    type: "bar",
                    label: "Lieu d'expertise",
                    data: this.props.generalTypesData.data,
                    color: getColors(),
                  },
                ]}
              />
            ) : null}
          </Col>
          <Col xs={12} lg={4} className="d-flex">
            {this.props.generalLocationsData ? (
              <BarChart
                className="w-100"
                title="Lieu d'expertise"
                labels={this.props.generalLocationsData.labels}
                dataSet={[
                  {
                    type: "bar",
                    label: "Lieu d'expertise",
                    data: this.props.generalLocationsData.data,
                    color: getColors(),
                  },
                ]}
              />
            ) : null}
          </Col>
          <Col xs={12} className="d-flex">
            {this.props.generalCompaniesData ? (
              <BarChart
                className="w-100"
                title="Nombre de réponse par compagnie"
                labels={this.props.generalCompaniesData.labels}
                dataSet={[
                  {
                    type: "bar",
                    label: "Lieu d'expertise",
                    data: this.props.generalCompaniesData.data,
                    color: getColors(),
                  },
                ]}
              />
            ) : null}
          </Col>
        </Row>
      </Card>
    );
  }
}
