import React, { Component } from "react";
import { Col, Row } from "react-flexbox-grid";
import { Button, FormCheckbox } from "ui-kit-ck-consultant";
import axiosConfig from "../../utils/axiosConfig";

export class Agencies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agencies: null,
      agenciesNotIn: [],
    };
  }

  componentDidMount = () => {
    this.getAllAgencies();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.region !== prevProps.region ||
      this.props.year !== prevProps.year ||
      this.props.users !== prevProps.users
    ) {
      this.getAllAgencies();
    }
  };

  getAllAgencies = () => {
    axiosConfig("/general/get-all-agencies", {
      data: {
        users: this.props.users,
        year: this.props.year,
        type: this.props.type,
      },
    }).then((res) => {
      if (res.data.success) {
        let agencies = res.data.agencies;
        agencies.forEach((agency) => {
          if (
            this.props.agenciesNotIn &&
            this.props.agenciesNotIn.includes(agency.id)
          ) {
            agency.checked = false;
          }
        });
        this.setState({ agencies });
      }
    });
  };

  handleClick = (e) => {
    let agencies = this.state.agencies;
    const index = agencies.findIndex((agency) => agency.name === e.target.name);
    agencies[index].checked = agencies[index].checked === true ? false : true;
    this.setState({ agencies }, () => {
      let agenciesNotIn = [];
      this.state.agencies.map((agency) => {
        if (agency.checked === false) return agenciesNotIn.push(agency.id);
        return null;
      });
      this.setState({ agenciesNotIn });
    });
  };

  render() {
    const bouton = (
      <Row>
        <Button
          text="Actualiser"
          onClick={() =>
            this.props.handleAgenciesNotIn(this.state.agenciesNotIn)
          }
        />
      </Row>
    );

    return (
      <div>
        <Row className="checkBox">
          {this.state.agencies
            ? this.state.agencies.map((agency, idx) => {
                return (
                  <Col xs={4} md={2} lg={2} key={idx}>
                    <FormCheckbox
                      name={agency.name}
                      text={agency.name}
                      checked={agency.checked}
                      className={"checkBoxFont"}
                      onChange={this.handleClick}
                    />
                  </Col>
                );
              })
            : null}
        </Row>
        {this.state.agenciesNotIn ? bouton : null}
      </div>
    );
  }
}

export default Agencies;
