import React, { Component } from "react";
import { Col } from "react-flexbox-grid";

import earth from "../../images/earth.svg";
import plane from "../../images/plane.svg";
import caretUpReverse from "../../images/caret-up-reverse.svg";
import caretDownReverse from "../../images/caret-down-reverse.svg";
import animatonBg from "../../images/animation-vague.gif";

import { getValue, getPercentageValue } from "../../utils/general";

export default class CardProjectE extends Component {
  render() {
    const { total, currentAverage, oldAverage } = this.props;
    const evol = (currentAverage * 100) / oldAverage - 100;

    return (
      <Col
        xs={12}
        md={12}
        lg={7}
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div
          className="mb-10 mb-20-xl"
          style={{
            position: "relative",
            borderRadius: "8px",
            backgroundColor: "var(--white)",
            color: "var(--black)",
            boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
            minHeight: "85px",
            display: "flex",
            overflow: "hidden",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              background: `url(${animatonBg})`,
              backgroundSize: "cover",
              height: "100%",
              padding: 15,
            }}
          >
            <h2
              style={{
                color: "#535374",
                fontSize: "13px",
                fontWeight: "700",
                margin: 0,
              }}
            >
              Émission de CO2e globale
            </h2>
            <div className="d-flex mt-20">
              <span
                style={{
                  color: "#4FBC9D",
                  fontSize: "80px",
                  fontWeight: "700",
                  mixBlendMode: "multiply",
                }}
              >
                {getValue(total / 1000000, 2)}{" "}
                <span
                  style={{
                    fontSize: "62px",
                  }}
                >
                  kt
                </span>
              </span>
            </div>
            <div className="d-flex mb-10">
              <img src={earth} height={32} alt="" className="mr-10" />
              <span
                className="mt-auto mb-auto"
                style={{
                  color: "#4FBC9D",
                  fontSize: "18px",
                  mixBlendMode: "multiply",
                }}
              >
                Équivaut à {getValue(total / 7600, 2)} tour(s) du monde en
                voiture
              </span>
            </div>
            <div className="d-flex mb-10">
              <img src={plane} height={32} alt="" className="mr-10" />
              <span
                className="mt-auto mb-auto"
                style={{
                  color: "#4FBC9D",
                  fontSize: "18px",
                  mixBlendMode: "multiply",
                }}
              >
                Équivaut à {getValue(total / 1750, 2)} aller-retour
                Paris/New-York en avion
              </span>
            </div>

            <div className="d-flex mt-20">
              <span
                style={{
                  color: "#4FBC9D",
                  fontSize: "30px",
                  fontWeight: "700",
                  mixBlendMode: "multiply",
                }}
              >
                {getValue(currentAverage, 2)}{" "}
                <span style={{}}>kg (moyenne / dossier)</span>
              </span>
            </div>
            <div
              className="mt-auto d-flex"
              style={{
                color: evol < 0 ? "#4FBC9D" : "#EC4646",
                alignItems: "center",
                mixBlendMode: "multiply",
              }}
            >
              <img
                src={evol < 0 ? caretDownReverse : caretUpReverse}
                style={{ mixBlendMode: "multiply" }}
                height={15}
                className="mr-5"
                alt=""
              />
              <span>
                {getPercentageValue(evol)} par rapport au mois dernier (moyenne
                / dossier)
              </span>
            </div>
          </div>
        </div>
      </Col>
    );
  }
}
