import React, { Component } from "react";
import {
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Pagination,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import { getCurrencyValue, getPercentageValue } from "../../utils/general";

import handleSortClick from "../../utils/handleSortClickV2";

export default class TableRank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      pageLength: 50,
      name: "average",
      desc: false,
    };

    this.sortRef = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
    ];
  }

  onSort = (desc, column) => {
    const { name } = handleSortClick(this.sortRef, desc, column);

    this.setState({ name, desc });
  };

  compareValues = (a, b, key, desc = false) => {
    let valueA = a[key];
    let valueB = b[key];

    // Convertir les pourcentages en nombres pour la comparaison
    if (typeof valueA === "string" && valueA.endsWith("%")) {
      valueA = parseFloat(valueA.slice(0, -1));
    }
    if (typeof valueB === "string" && valueB.endsWith("%")) {
      valueB = parseFloat(valueB.slice(0, -1));
    }

    // Convertir les chaînes de caractères contenant des nombres en nombres
    if (typeof valueA === "string" && !isNaN(valueA)) {
      valueA = parseFloat(valueA);
    }
    if (typeof valueB === "string" && !isNaN(valueB)) {
      valueB = parseFloat(valueB);
    }

    // Comparaison des chaînes de caractères
    if (typeof valueA === "string" && typeof valueB === "string") {
      return desc ? valueB.localeCompare(valueA) : valueA.localeCompare(valueB);
    }

    // Comparaison des nombres (entiers et flottants)
    if (typeof valueA === "number" && typeof valueB === "number") {
      return desc ? valueB - valueA : valueA - valueB;
    }

    // Comparaison par défaut si les types ne correspondent pas
    return 0;
  };

  render() {
    const data = Object.keys(this.props.data).map((key, idx) => ({
      ...this.props.data[key],
    }));

    let tmpData = data.sort((a, b) =>
      this.compareValues(a, b, this.state.name, this.state.desc)
    );

    tmpData = JSON.parse(JSON.stringify(tmpData));

    return (
      <Card
        title={this.props.title}
        className="mb-10 mb-20-xl"
        classNameChildren="overflow-x-auto"
      >
        <Table isCSV>
          <Thead>
            <Tr>
              <Th colSpan={7}></Th>
              <Th colSpan={2}>MO</Th>
              <Th colSpan={3}>Trajet Expert</Th>
              <Th colSpan={3}>Trajet Client</Th>
              <Th colSpan={3}>PRE</Th>
              <Th colSpan={3}>pièces neuves</Th>
              <Th></Th>
              <Th></Th>
            </Tr>
            <Tr>
              <Th>#</Th>
              <Th>Nom</Th>
              <Th
                sort
                onSort={(desc) => this.onSort(desc, 0)}
                nameSort="average"
                ref={(ref) => (this.sortRef[0] = ref)}
              >
                Ém. CO2e moy. (kg)
              </Th>
              <Th
                sort
                onSort={(desc) => this.onSort(desc, 1)}
                nameSort="averagePRE"
                ref={(ref) => (this.sortRef[1] = ref)}
              >
                Tx PRE
              </Th>
              <Th
                sort
                onSort={(desc) => this.onSort(desc, 2)}
                nameSort="averageR"
                ref={(ref) => (this.sortRef[2] = ref)}
              >
                Tx. rép.
              </Th>
              <Th
                sort
                onSort={(desc) => this.onSort(desc, 3)}
                nameSort="averageCM"
                ref={(ref) => (this.sortRef[3] = ref)}
              >
                CM. rép
              </Th>
              <Th
                sort
                onSort={(desc) => this.onSort(desc, 4)}
                nameSort="averageEAD"
                ref={(ref) => (this.sortRef[4] = ref)}
              >
                Tx (EAD)
              </Th>
              <Th
                sort
                onSort={(desc) => this.onSort(desc, 5)}
                nameSort="averageH"
                ref={(ref) => (this.sortRef[5] = ref)}
              >
                Nb. M.
              </Th>
              <Th
                sort
                onSort={(desc) => this.onSort(desc, 6)}
                nameSort="averageEmissionH"
                ref={(ref) => (this.sortRef[6] = ref)}
              >
                Ém. CO2e M. (kg)
              </Th>
              <Th
                sort
                onSort={(desc) => this.onSort(desc, 7)}
                nameSort="averageExpert"
                ref={(ref) => (this.sortRef[7] = ref)}
              >
                Nb. M.
              </Th>
              <Th
                sort
                onSort={(desc) => this.onSort(desc, 8)}
                nameSort="averageEmissionExpert"
                ref={(ref) => (this.sortRef[8] = ref)}
              >
                Ém. CO2e M. (kg)
              </Th>
              <Th
                sort
                onSort={(desc) => this.onSort(desc, 9)}
                nameSort="averageMileageExpert"
                ref={(ref) => (this.sortRef[9] = ref)}
              >
                km M.
              </Th>
              <Th
                sort
                onSort={(desc) => this.onSort(desc, 10)}
                nameSort="averageClient"
                ref={(ref) => (this.sortRef[10] = ref)}
              >
                Nb. M.
              </Th>
              <Th
                sort
                onSort={(desc) => this.onSort(desc, 11)}
                nameSort="averageEmissionClient"
                ref={(ref) => (this.sortRef[11] = ref)}
              >
                Ém. CO2e M. (kg)
              </Th>
              <Th
                sort
                onSort={(desc) => this.onSort(desc, 12)}
                nameSort="averageMileageInsured"
                ref={(ref) => (this.sortRef[12] = ref)}
              >
                km M.
              </Th>
              <Th
                sort
                onSort={(desc) => this.onSort(desc, 13)}
                nameSort="averagePartPRE"
                ref={(ref) => (this.sortRef[13] = ref)}
              >
                Nb. M.
              </Th>
              <Th
                sort
                onSort={(desc) => this.onSort(desc, 14)}
                nameSort="averageEmissionPartPRE"
                ref={(ref) => (this.sortRef[14] = ref)}
              >
                Ém. CO2e M. (kg)
              </Th>
              <Th
                sort
                onSort={(desc) => this.onSort(desc, 15)}
                nameSort="averageWeightPRE"
                ref={(ref) => (this.sortRef[15] = ref)}
              >
                Poids M. (kg)
              </Th>
              <Th
                sort
                onSort={(desc) => this.onSort(desc, 16)}
                nameSort="averagePartE"
                ref={(ref) => (this.sortRef[16] = ref)}
              >
                Nb. M.
              </Th>
              <Th
                sort
                onSort={(desc) => this.onSort(desc, 17)}
                nameSort="averageEmissionPartE"
                ref={(ref) => (this.sortRef[17] = ref)}
              >
                Ém. CO2e M. (kg)
              </Th>
              <Th
                sort
                onSort={(desc) => this.onSort(desc, 18)}
                nameSort="averageWeightE"
                ref={(ref) => (this.sortRef[18] = ref)}
              >
                Poids M. (kg)
              </Th>
              <Th
                sort
                onSort={(desc) => this.onSort(desc, 19)}
                nameSort="count"
                ref={(ref) => (this.sortRef[19] = ref)}
              >
                Nb. dossier
              </Th>
              <Th
                sort
                onSort={(desc) => this.onSort(desc, 20)}
                nameSort="averagePredict"
                ref={(ref) => (this.sortRef[20] = ref)}
              >
                Coût moyen prédit
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {tmpData
              .slice(
                (this.state.activePage - 1) * this.state.pageLength,
                this.state.activePage * this.state.pageLength
              )
              .map((value, idx) => (
                <Tr key={idx}>
                  <Th>{idx + 1}</Th>
                  <Td>{value.name || "AUTRE"}</Td>
                  <Td>{value.average}</Td>
                  <Td>{getPercentageValue(value.averagePRE)}</Td>
                  <Td>{getPercentageValue(value.averageR)}</Td>
                  <Td>{getCurrencyValue(value.averageCM)}</Td>
                  <Td>{getPercentageValue(value.averageEAD)}</Td>
                  <Td>{value.averageH}</Td>
                  <Td>{value.averageEmissionH}</Td>
                  <Td>{value.averageExpert}</Td>
                  <Td>{value.averageEmissionExpert}</Td>
                  <Td>{value.averageMileageExpert}</Td>
                  <Td>{value.averageClient}</Td>
                  <Td>{value.averageEmissionClient}</Td>
                  <Td>{value.averageMileageInsured}</Td>
                  <Td>{value.averagePartPRE}</Td>
                  <Td>{value.averageEmissionPartPRE}</Td>
                  <Td>{value.averageWeightPRE}</Td>
                  <Td>{value.averagePartE}</Td>
                  <Td>{value.averageEmissionPartE}</Td>
                  <Td>{value.averageWeightE}</Td>
                  <Td>{value.count}</Td>
                  <Td>{value.averagePredict}</Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
        {data.length > this.state.pageLength && (
          <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={this.state.pageLength}
            totalItemsCount={data.length}
            pageRangeDisplayed={4}
            onChange={(activePage) => {
              this.setState({ activePage: activePage });
            }}
            firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
            lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
            prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
            nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
          />
        )}
      </Card>
    );
  }
}
