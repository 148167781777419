import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import {
  BarChart,
  Card,
  HorizontalBarChart,
  PieChart,
  TabsCustom,
  TabsCustomMenu,
  TabsCustomHeader,
  TabsCustomBody,
  TabsCustomItem,
} from "ui-kit-ck-consultant";

import MonthBarChart from "./MonthBarChart";

import {
  getCurrencyValue,
  getPercentageValue,
  getValue,
} from "../../../utils/general";
import { getColors } from "../../../utils/colors";

import AuthContext from "../../../context/AuthContext";

export default class Overall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabsCAId: 0,
      tabsCA: ["Région", "Agence"],
    };
  }

  static contextType = AuthContext;

  render() {
    return (
      <Row>
        <Col xs={12} md={3} className={"mb-10 mb-20-xl"}>
          <MonthBarChart
            title={`Taux d'EAD : ${getPercentageValue(
              this.props.data.year.EAD
            )}`}
            format="percentage"
            label="Taux d'EAD"
            data={this.props.data.month.EAD}
            labels={this.props.data.month.labels}
          />
        </Col>
        <Col xs={12} md={3} className={"mb-10 mb-20-xl"}>
          <MonthBarChart
            title={`Taux de RIV/VEI : ${getPercentageValue(
              this.props.data.year.RIV_VEI
            )}`}
            format="percentage"
            label="Taux de RIV/VEI"
            data={this.props.data.month.RIV_VEI}
            labels={this.props.data.month.labels}
          />
        </Col>
        <Col xs={12} md={3} className={"mb-10 mb-20-xl"}>
          <MonthBarChart
            title={`Taux de PRE : ${getPercentageValue(
              this.props.data.year.PRE
            )}`}
            format="percentage"
            label="Taux de PRE"
            data={this.props.data.month.PRE}
            labels={this.props.data.month.labels}
          />
        </Col>
        <Col xs={12} md={3} className={"mb-10 mb-20-xl"}>
          <MonthBarChart
            title={`Taux de RP : ${getPercentageValue(
              this.props.data.year.RP
            )}`}
            format="percentage"
            label="Taux de RP"
            data={this.props.data.month.RP}
            labels={this.props.data.month.labels}
          />
        </Col>
        <Col xs={12} md={4} className={"mb-10 mb-20-xl"}>
          <MonthBarChart
            title={`Coût moyen : ${getCurrencyValue(
              this.props.data.year.totalReport
            )} HT`}
            format="currency"
            label="Coût moyen"
            data={this.props.data.month.totalReport}
            labels={this.props.data.month.labels}
          />
        </Col>
        <Col xs={12} md={4} className={"mb-10 mb-20-xl"}>
          <MonthBarChart
            title={`VRADE moyenne : ${getCurrencyValue(
              this.props.data.year.VRADE
            )} HT`}
            format="currency"
            label="VRADE moyenne"
            data={this.props.data.month.VRADE}
            labels={this.props.data.month.labels}
          />
        </Col>
        <Col xs={12} md={4} className={"mb-10 mb-20-xl"}>
          <MonthBarChart
            title={`Délai moyen réparable: ${getValue(
              this.props.data.year.delayR
            )} jours`}
            label="Délai moyen réparable"
            data={this.props.data.month.delayR}
            labels={this.props.data.month.labels}
          />
        </Col>
        <Col xs={12} md={3} className={"mb-10 mb-20-xl"}>
          <MonthBarChart
            title={`Délai moyen non-réparable: ${getValue(
              this.props.data.year.delayNR
            )} jours`}
            labe="Délai moyen non-réparable"
            data={this.props.data.month.delayNR}
            labels={this.props.data.month.labels}
          />
        </Col>
        <Col xs={12} md={3} className={"mb-10 mb-20-xl"}>
          <MonthBarChart
            title={`Délai moyen SD29: ${getValue(
              this.props.data.year.delaySD29
            )} jours`}
            label="Délai moyen SD29"
            data={this.props.data.month.delaySD29}
            labels={this.props.data.month.labels}
          />
        </Col>
        <Col xs={12} md={6} className={"mb-10 mb-20-xl"}>
          <MonthBarChart
            title="Évolution stock dossiers"
            type="bar"
            label="stock dossiers"
            labelLine="Âge moyen des dossiers (jours)"
            data={this.props.data.month.stock}
            dataLine={this.props.data.month.ageStock}
            labels={this.props.data.month.labels}
          />
        </Col>
        <Col xs={12} md={6} className={"mb-10 mb-20-xl"}>
          <Card title="CA mois / mois">
            <BarChart
              format="currency"
              labels={this.props.data.month.labels}
              digits={0}
              widthBar={30}
              dataSet={[
                {
                  label: "CA",
                  data: this.props.data.month.CA.total,
                  color: "#026fc2",
                },
                {
                  label: "Atteinte",
                  data: this.props.data.month.CA.total.map(
                    (element, idx) =>
                      element - this.props.data.month.CA.goal[idx]
                  ),
                  color: this.props.data.month.CA.total.map((element, idx) =>
                    element - this.props.data.month.CA.goal[idx] > 0
                      ? "#238037"
                      : "#ea0000"
                  ),
                },
                {
                  label: "Objectif",
                  type: "line",
                  data: this.props.data.month.CA.goal,
                  color: "#026fc2",
                },
              ]}
              xAxes={[
                {
                  stacked: true,
                },
              ]}
              yAxes={[
                {
                  stacked: true,
                  ticks: {
                    display: false,
                  },
                },
              ]}
            />
          </Card>
        </Col>
        <Col xs={12} md={6} className={"mb-10 mb-20-xl"}>
          <Card
            title={`Rendement mois / mois -  (${this.props.data.year.totalMission} / ${this.props.data.year.totalClosing})`}
          >
            <BarChart
              type="bar"
              labels={this.props.data.month.labels}
              digits={0}
              widthBar={30}
              datalabels2={[
                {
                  display: true,
                  align: "bottom",
                  anchor: "start",
                  clamp: true,
                  color: "#0162AD",
                  backgroundColor: "white",
                  borderColor: "#0162AD",
                  borderWidth: 1,
                  borderRadius: 4,
                  font: {
                    size: "10",
                  },
                  formatter: (value) => Math.round(value) + "%",
                },
              ]}
              dataSet={[
                {
                  label: "Rendement (en %)",
                  type: "line",
                  data: this.props.data.month.yield.yield,
                  color: "#026fc2",
                  yAxisID: "A",
                },
                {
                  label: "Dossiers déposés",
                  data: this.props.data.month.yield.closing,
                  type: "bar",
                  color: "#51AE50",
                  yAxisID: "B",
                  xAxisID: "xB",
                },
                {
                  label: "Dossiers reçus",
                  data: this.props.data.month.yield.mission,
                  type: "bar",
                  color: "#E68A00",
                  yAxisID: "B",
                  xAxisID: "xA",
                },
              ]}
              xAxes={[
                {
                  id: "xA",
                  stacked: true,
                  display: true,
                  barThickness: 45,
                },
                {
                  id: "xB",
                  display: false,
                  stacked: true,
                  barThickness: 30,
                  type: "category",
                  categoryPercentage: 0.6,
                  barPercentage: 0.9,
                  gridLines: {
                    display: false,
                    offsetGridLines: true,
                  },
                  offset: true,
                },
              ]}
              yAxes={[
                {
                  id: "A",
                  display: false,
                  ticks: {
                    beginAtZero: true,
                  },
                },
                {
                  id: "B",
                  display: false,
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ]}
            />
          </Card>
        </Col>
        <Col xs={12} className={"mb-10 mb-20-xl"}>
          <Card
            title={`${this.props.year} - CA cumulé : ${getCurrencyValue(
              this.props.data.year.CA.total,
              0
            )} /  ${getCurrencyValue(this.props.data.year.CA.goal, 0)}`}
          >
            <TabsCustom>
              <TabsCustomHeader noCard>
                {this.state.tabsCA.map((element, key) => (
                  <TabsCustomMenu
                    noCard
                    key={key}
                    title={element}
                    active={this.state.tabsCAId === key}
                    onClick={(e) => {
                      this.setState({ tabsCAId: key });
                    }}
                  />
                ))}
              </TabsCustomHeader>
              <TabsCustomBody noCard>
                <TabsCustomItem active={this.state.tabsCAId === 0}>
                  <PieChart
                    height={380}
                    full
                    type="pie"
                    digits={0}
                    legendDisplay={true}
                    positionLegend="right"
                    format="currency"
                    data={this.props.data.year.CARegion.map((region, idx) => ({
                      labels: region.name
                        ? `${region.name} (${region.percentage}%)`
                        : `Autre (${region.percentage}%)`,
                      data: region.data,
                      color: getColors()[idx],
                    }))}
                  />
                </TabsCustomItem>
                <TabsCustomItem active={this.state.tabsCAId === 1}>
                  <PieChart
                    height={380}
                    full
                    type="pie"
                    digits={0}
                    legendDisplay={true}
                    positionLegend="right"
                    format="currency"
                    data={this.props.data.year.CAAgency.map((agency) => ({
                      labels: agency.name
                        ? `${agency.name} (${agency.percentage}%)`
                        : `Autre (${agency.percentage}%)`,
                      data: agency.data,
                      color: agency.color,
                    }))}
                  />
                </TabsCustomItem>
              </TabsCustomBody>
            </TabsCustom>
          </Card>
        </Col>
        <Col xs={12} md={12} lg={4} className={"mb-10 mb-20-xl"}>
          <Card title={`${this.props.year} - Dossiers en retard / cabinet`}>
            <HorizontalBarChart
              height={260}
              digits={0}
              format="percentage"
              align
              isLegend={true}
              xAxes={[
                {
                  ticks: {
                    display: false,
                    suggestedMax: 10,
                    min: 0,
                    beginAtZero: true,
                  },
                  offset: false,
                  scaleLabel: {
                    fontSize: 8,
                  },
                },
              ]}
              yAxes={[
                {
                  ticks: {
                    display: false,
                    suggestedMax: 10,
                    min: 0,
                    beginAtZero: true,
                  },
                  offset: false,
                  scaleLabel: {
                    fontSize: 8,
                  },
                },
              ]}
              labels={this.props.data.year.overdueFolderAgency.map(
                (agency) => agency.name
              )}
              dataSet={[
                {
                  label: "30j - 60j",
                  data: this.props.data.year.overdueFolderAgency.map(
                    (agency) => agency.data[30]
                  ),
                  color: "#268540",
                },
                {
                  label: "60j - 120j",
                  data: this.props.data.year.overdueFolderAgency.map(
                    (agency) => agency.data[60]
                  ),
                  color: "#facc02",
                },
                {
                  label: "+ 120j",
                  data: this.props.data.year.overdueFolderAgency.map(
                    (agency) => agency.data[120]
                  ),
                  color: "#db2828",
                },
              ]}
            />
          </Card>
        </Col>
        <Col xs={12} md={12} lg={4} className={"mb-10 mb-20-xl"}>
          <Card title={`${this.props.year} - Dossiers en retard / genre`}>
            <HorizontalBarChart
              height={260}
              digits={0}
              format="percentage"
              align
              isLegend={true}
              xAxes={[
                {
                  ticks: {
                    display: false,
                    suggestedMax: 10,
                    min: 0,
                    beginAtZero: true,
                  },
                  offset: false,
                  scaleLabel: {
                    fontSize: 8,
                  },
                },
              ]}
              yAxes={[
                {
                  ticks: {
                    display: false,
                    suggestedMax: 10,
                    min: 0,
                    beginAtZero: true,
                  },
                  offset: false,
                  scaleLabel: {
                    fontSize: 8,
                  },
                },
              ]}
              labels={this.props.data.year.overdueFolderKind.map(
                (kind) => kind.name
              )}
              dataSet={[
                {
                  label: "30j - 60j",
                  data: this.props.data.year.overdueFolderKind.map(
                    (kind) => kind.data[30]
                  ),
                  color: "#268540",
                },
                {
                  label: "60j - 120j",
                  data: this.props.data.year.overdueFolderKind.map(
                    (kind) => kind.data[60]
                  ),
                  color: "#facc02",
                },
                {
                  label: "+ 120j",
                  data: this.props.data.year.overdueFolderKind.map(
                    (kind) => kind.data[120]
                  ),
                  color: "#db2828",
                },
              ]}
            />
          </Card>
        </Col>
        <Col xs={12} md={12} lg={4} className={"mb-10 mb-20-xl"}>
          <Card title={`${this.props.year} - Dossiers en retard`}>
            <PieChart
              height={260}
              full
              type="pie"
              digits={0}
              legendDisplay={true}
              data={[
                {
                  labels: "30j - 60j",
                  data: this.props.data.year.overdueFolder[30],
                  color: "#268540",
                },
                {
                  labels: "60j - 120j",
                  data: this.props.data.year.overdueFolder[60],
                  color: "#facc02",
                },
                {
                  labels: "+ 120j",
                  data: this.props.data.year.overdueFolder[120],
                  color: "#db2828",
                },
              ]}
            />
          </Card>
        </Col>
        <Col xs={12} className={"mb-10 mb-20-xl"}>
          <Card
            title={`${this.props.year} - Répartition compagnie - (${this.props.data.year.totalMission} / ${this.props.data.year.totalClosing})`}
          >
            <PieChart
              height={260}
              full
              type="pie"
              digits={0}
              legendDisplay={true}
              positionLegend="right"
              data={this.props.data.year.folderCompany.map((company) => ({
                labels: company.name
                  ? `${company.name} (${company.percentage}%)`
                  : `Autre (${company.percentage}%)`,
                data: company.total,
                color: company.color,
              }))}
            />
          </Card>
        </Col>
      </Row>
    );
  }
}
