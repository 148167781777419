import React from "react";
import { Nav, Button, Modal } from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";

import CardUpdate from "../../components/update/CardUpdate";
import FormUpdate from "../../components/update/FormUpdate";

import axiosConfig from "../../utils/axiosConfig";

import AuthContext from "../../context/AuthContext";
export default class Update extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isModal: false,
      isEdit: false,
      isDelete: false,
      id: 0,
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.getUpdates();
  }

  getUpdates() {
    axiosConfig("updates/get-updates").then((res) => {
      if (res.data.success) {
        this.setState({ data: res.data.data });
      }
    });
  }

  onDelete(id) {
    this.setState({ isDelete: !this.state.isDelete, id });
  }

  onEdit(oldUpdate) {
    this.setState({ oldUpdate, isEdit: !this.state.isEdit });
  }

  delete() {
    axiosConfig("updates/delete", { data: { id: this.state.id } }).then(
      (res) => {
        if (res.data.success) {
          this.getUpdates();
          this.setState({ isDelete: false });
        } else {
          this.setState({ isDelete: false });
        }
      }
    );
  }

  render() {
    localStorage.setItem("lastUpdate", this.context.lastUpdate);
    
    return (
      <>
        <Nav className="mb-10 mb-30-xl" title="Mises à jour" />
        {this.context.administration && !this.context.network ? (
          <Button
            text={"Ajouter une mise à jour"}
            onClick={() => this.setState({ isModal: !this.state.isModal })}
          />
        ) : null}
        <Modal
          show={this.state.isModal}
          onClose={() => this.setState({ isModal: !this.state.isModal })}
          title={"Formulaire d'ajout Mise à jour"}
        >
          <FormUpdate
            refresh={() => this.getUpdates()}
            onClose={() => this.setState({ isModal: !this.state.isModal })}
          />
        </Modal>
        <Modal
          show={this.state.isEdit}
          onClose={() => this.setState({ isEdit: !this.state.isEdit })}
          title={"Formulaire de modfication Mise à jour"}
        >
          <FormUpdate
            refresh={() => this.getUpdates()}
            onClose={() => this.setState({ isEdit: !this.state.isEdit })}
            oldUpdate={this.state.oldUpdate}
          />
        </Modal>
        <Modal
          show={this.state.isDelete}
          onClose={() => this.setState({ isDelete: !this.state.isDelete })}
          title={"Voulez vous vraiment supprimer cette Mise à jour ?"}
        >
          <Row className={"mt-30"}>
            <Col lgOffset={1} lg={3}>
              <Button text={"Oui"} onClick={() => this.delete()} />
            </Col>
            <Col lgOffset={3} lg={3}>
              <Button
                text={"Non"}
                onClick={() =>
                  this.setState({ isDelete: !this.state.isDelete })
                }
              />
            </Col>
          </Row>
        </Modal>
        {this.state.data.length
          ? this.state.data.map((element, idx) => (
              <CardUpdate
                isAdmin={this.context.administration}
                key={idx}
                className={"my-30"}
                update={element}
                onDelete={(id) => this.onDelete(id)}
                onEdit={(id) => this.onEdit(id)}
              />
            ))
          : null}
      </>
    );
  }
}
