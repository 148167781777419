import React from "react";

export default class Martinique extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <g transform="translate(600, 230)">
        <svg
          width="50"
          height="50"
          viewBox="0 0 28 29"
          fill={this.props.fill || "none"}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.0744 0.504883C8.0744 0.504883 4.46214 0.592704 4.0208 0.757294C3.57944 0.921874 1.80838 2.15305 1.80838 2.15305L1.54111 2.22729L0.486877 3.80121L0.843239 5.85028L3.9317 8.89423L3.39716 9.72574V11.3739L4.71866 13.1854L8.96531 16.9718H12.7516H13.108L14.1622 16.8827H14.3404L14.1622 17.6251V18.2041L15.4095 18.5308L15.9292 18.8575C15.9292 18.8575 15.7551 20.26 16.1965 20.3423L14.875 20.4166L13.0189 19.8523L11.7865 19.5999L10.1977 20.2533L9.30682 21.3224L8.87621 22.5547L9.21774 23.802L10.1086 25.0344L11.252 25.6878C11.252 25.6878 12.3994 25.3623 12.8407 25.1977C13.2821 25.0331 13.6425 24.292 13.6425 24.292C13.6425 24.292 14.955 24.211 15.5728 24.1287C16.1907 24.0464 16.8201 24.8711 16.8201 24.8711L19.7304 25.272L21.0519 26.0144L21.8389 25.9402V25.1087L22.5516 24.7077L23.2643 25.1087L22.908 26.1035L22.1061 26.8459L22.4625 28.3159L23.5167 28.895L24.764 28.8208L25.9964 27.8259L26.3528 26.5193L27.5852 25.1977L26.7834 23.5496L26.2637 22.0647L25.6401 20.0899L25.0164 18.6941C25.0164 18.6941 23.5167 17.2118 23.5167 16.8827C23.5167 16.5535 22.1061 15.8136 22.1061 15.8136L21.928 14.4921C21.928 14.4921 22.2884 14.0925 22.7298 13.9278C23.1711 13.7632 22.4625 13.0221 22.4625 13.0221L21.5864 13.1854L20.2501 13.5121L19.9086 13.0963L19.374 12.5172L19.4631 11.7748L20.4283 11.953L21.2301 11.0472L20.0867 10.1415L19.1959 9.88907L18.9286 9.39905V8.81998L19.9086 8.40421L20.6064 8.98331L21.8389 9.4733L22.4625 8.73087V7.91422L22.997 7.26089L22.5516 6.75604L21.5864 6.35513L20.9628 6.91937L18.498 7.17181L17.6962 7.75089L16.6419 7.42422L15.4095 5.4494L12.5735 2.64304L10.9847 1.98972L8.0744 0.504883Z"
            fill={this.props.fill || "none"}
            stroke="#232323"
            strokeWidth={1}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <text x={"-2%"} y={"-1%"}>
          Martinique
        </text>
      </g>
    );
  }
}
