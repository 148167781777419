import React, { Component } from "react";

import { Col, Row } from "react-flexbox-grid";
import { BarChart, Card, DoughnutChart } from "ui-kit-ck-consultant";

import {colors} from "../../utils/colors";

export class CardSatisfactionGroup extends Component {
  render() {
    let arrayMonth = this.props.data ? this.props.data.byMonth.grade : [];
    arrayMonth = arrayMonth.filter(Number);
    const max = this.props.data ? Math.max(...arrayMonth) + 1 : 0;
    const min = this.props.data ? Math.min(...arrayMonth) - 1 : 0;

    return (
      <Card
        title="Groupe performance Satisfaction"
        className="mb-10 mb-30-xl"
        isLoader={this.props.isLoad}
      >
        <Row>
          {this.props.data ? (
            <>
              <Col xs={12} md={2}>
                <DoughnutChart
                  className={`mt-30`}
                  max={20}
                  unit={"/20"}
                  number={this.props.data.dayGrade}
                  text={"Note de satisfaction du jour"}
                />
              </Col>
              <Col xs={12} md={4}>
                <BarChart
                  digits={2}
                  title={"Note de satisfaction moyenne"}
                  dataSet={[
                    {
                      type: "line",
                      label: "Note de satisfaction moyenne",
                      data: this.props.data.byMonth.grade,
                      color: colors.blue[0],
                    },
                  ]}
                  yAxes={[
                    {
                      ticks: {
                        display: false,
                        beginAtZero: true,
                        suggestedMax: max,
                        min: min,
                      },
                    },
                  ]}
                  widthBar={30}
                />
              </Col>
              <Col xs={12} md={2}>
                <DoughnutChart
                  className={`mt-30 ml-20`}
                  number={this.props.data.dayRate}
                  text={"Taux de réponse du jour"}
                />
              </Col>
              <Col xs={12} md={4}>
                <BarChart
                  digits={2}
                  title={"Taux de réponse moyen (%)"}
                  dataSet={[
                    {
                      type: "line",
                      label: "Taux de réponse moyen (%)",
                      data: this.props.data.byMonth.rate,
                      color: colors.blue[0],
                    },
                  ]}
                  yAxes={[
                    {
                      ticks: {
                        display: false,
                        beginAtZero: true,
                        suggestedMax: 20,
                      },
                    },
                  ]}
                  widthBar={30}
                />
              </Col>
            </>
          ) : null}
        </Row>
      </Card>
    );
  }
}

export default CardSatisfactionGroup;
