import React, { Component } from "react";
import { Card, Table, Thead, Tbody, Tr, Th, Td } from "ui-kit-ck-consultant";
import { ExportCSV } from "react-export-csv";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getValue, getPercentageValue } from "../../../utils/general";

export class TreatmentDelays extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDownload: false,
    };
  }

  render() {
    let data = [];

    if (this.props.data.length) {
      this.props.data[0].forEach((month, idxRow) => {
        data.push({});
        this.props.data.forEach((element, idxCol) => {
          data[idxRow][idxCol] = element[idxRow];
        });
      });
    }

    return (
      <>
        <Card
          title="Délais de traitement"
          className="mb-10 mb-20-xl"
          classNameChildren="overflow-x-auto"
          action={<FontAwesomeIcon icon={faDownload} />}
          onClick={() => this.setState({ isDownload: true })}
        >
          <Table>
            <Thead>
              <Tr>
                <Th>Mois</Th>
                <Th>Nbre dossiers clôturés (au cours du mois)</Th>
                <Th>Age moyen dossiers clôturés (en nbre de jours)</Th>
                <Th>% dossiers clôturés en moins de 30 jrs</Th>
                <Th>% dossiers clôturés en moins de 60 jrs</Th>
                <Th>% dossiers clôturés en moins de 90 jrs</Th>
                <Th>% dossiers clôturés en plus de 90 jours</Th>
              </Tr>
            </Thead>
            <Tbody>
              {this.props.data.length
                ? this.props.data[0].map((month, idxRow) => (
                    <Tr key={idxRow}>
                      {this.props.data.map((element, idxCol) => (
                        <Td key={idxCol}>
                          {[3, 4, 5, 6].includes(idxCol)
                            ? getPercentageValue(element[idxRow])
                            : [2].includes(idxCol)
                            ? getValue(element[idxRow])
                            : element[idxRow]}
                        </Td>
                      ))}
                    </Tr>
                  ))
                : null}
            </Tbody>
          </Table>
        </Card>
        {this.state.isDownload ? (
          <ExportCSV
            header={[
              {
                name: "Mois",
                key: "0",
              },
              {
                name: "Nbre dossiers clôturés (au cours du mois)",
                key: "1",
              },
              {
                name: "Age moyen dossiers clôturés (en nbre de jours)",
                key: "2",
              },
              {
                name: "% dossiers clôturés en moins de 30 jrs",
                key: "3",
              },
              {
                name: "% dossiers clôturés en moins de 60 jrs",
                key: "4",
              },
              {
                name: "% dossiers clôturés en moins de 90 jrs",
                key: "5",
              },
              {
                name: "% dossiers clôturés en plus de 90 jours",
                key: "6",
              },
            ]}
            data={data}
            separator={";"}
            callback={() =>
              this.setState({
                isDownload: false,
              })
            }
            isDownload={true}
            filename={`Délais de traitement ${this.props.year}.csv`}
          />
        ) : null}
      </>
    );
  }
}

export default TreatmentDelays;
