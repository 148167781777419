import React, { Component } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Card,
  Pagination,
} from "ui-kit-ck-consultant";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AuthContext from "../../../context/AuthContext"
import natureExpertise from "../../../utils/natureExpertise";

export default class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: null
    };

    this.refColumnReference = React.createRef();
    this.refColumnAgency = React.createRef();
    this.refColumnExpert = React.createRef();
    this.refColumnNatureExpertise = React.createRef();
    this.refColumnLocation = React.createRef();
  }

  static contextType = AuthContext;

  handleSortClick = (desc, column) => {
    if (column !== 0) {
      this.refColumnReference.current.setState({ desc: false, sort: false });
    }
    if (column !== 1) {
      this.refColumnAgency.current.setState({ desc: false, sort: false });
    }
    if (column !== 2) {
      this.refColumnExpert.current.setState({ desc: false, sort: false });
    }
    if (column !== 3) {
      this.refColumnNatureExpertise.current.setState({ desc: false, sort: false });
    }
    if (column !== 4) {
      this.refColumnLocation.current.setState({ desc: false, sort: false });
    }
    let order = null;
    switch (column) {
      case 0:
        order = { name: "reference", desc: desc };
        break;
      case 1:
        order = { name: "agency", desc: desc };
        break;
      case 2:
        order = { name: "expert", desc: desc };
        break;
      case 3:
        order = { name: "natureExpertise", desc: desc };
        break;
      case 4:
        order = { name: "location", desc: desc };
        break;
      default:
        order = { name: "id", desc: desc };
        break;
    }
    this.setState({ order: order }, () => {
      this.props.onSortClick(order);
    });
  };

  render() {
    return (
      <Card
        title={`Liste des tournées (${this.props.total ? this.props.total : 0} / ${this.props.totalDailyAvailability})`}
        className={this.props.className}
        classNameChildren="overflow-x-auto"
      >
        <Table
          empty={
            this.props.data.length > 0
              ? ""
              : "Aucune donnée n'est disponible"
          }
        >
          <Thead>
            <Tr>
              <Th
                ref={this.refColumnReference}
                sort
                onSort={(desc) => this.handleSortClick(desc, 0)}
              >
                Reference
              </Th>
              <Th
                ref={this.refColumnAgency}
                sort
                onSort={(desc) => this.handleSortClick(desc, 1)}
              >
                Agence
              </Th>
              <Th
                ref={this.refColumnExpert}
                sort
                onSort={(desc) => this.handleSortClick(desc, 2)}
              >
                Expert
              </Th>
              <Th
                ref={this.refColumnNatureExpertise}
                sort
                onSort={(desc) => this.handleSortClick(desc, 3)}
              >
                Nature d'expertise
              </Th>
              <Th>
                Date de visite
              </Th>
              <Th
                ref={this.refColumnLocation}
                sort
                onSort={(desc) => this.handleSortClick(desc, 5)}
              >
                Lieu de visite
              </Th>
              <Th>
                Garage
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {!this.state.searchData && this.props.data.map((element, idx) => (
              <Tr key={idx}>
                <Td>{element.reference}</Td>
                <Td>{element.agency}</Td>
                <Td>{element.expert}</Td>
                <Td>{natureExpertise(element.natureExpertise)}</Td>
                <Td>{element.dateAppointment}</Td>
                <Td>{element.locationCode}</Td>
                <Td>{element.garage}</Td>
              </Tr>
            ))}
            {this.state.searchData && this.state.searchData.map((element, idx) => (
              <Tr key={idx}>
                <Td>{element.reference}</Td>
                <Td>{element.agency}</Td>
                <Td>{element.expert}</Td>
                <Td>{natureExpertise(element.natureExpertise)}</Td>
                <Td>{element.dateAppointment}</Td>
                <Td>{element.locationCode}</Td>
                <Td>{element.garage}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        {this.props.isPrint
        ? null 
        : 
          <Pagination
            activePage={this.props.activePage}
            itemsCountPerPage={this.props.tabLength}
            totalItemsCount={this.props.total}
            pageRangeDisplayed={4}
            onChange={this.props.onPageChange.bind(this)}
            firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
            lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
            prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
            nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
          />
        }
      </Card>
    );
  }
}