import React, { Component } from "react";
import {
  FormTextarea,
  FormSelect,
  FormInput,
  FormCheckbox,
  Modal,
  ButtonIcon,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-flexbox-grid";
import FadeIn from "react-fade-in";

export default class ActionPlanElement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorModal: false,
      id: null,
      indicator: "DEFAULT",
      indicatorOptions: [
        { value: "DEFAULT", text: "Choisir une option", disabled: true },
      ],
      indicator2: 0,
      indicator2Options: [
        { value: "DEFAULT", text: "Choisir une option", disabled: true },
      ],
      dateStart: "",
      dateEnd: "",
      checked: false,
      value: "",
      eventId: null,
    };
  }

  // DATA INIT
  componentDidMount() {
    this.setState(
      {
        indicatorOptions: [
          ...this.state.indicatorOptions,
          ...this.props.indicatorOptions.map((element) => ({
            value: element,
            text: element,
          })),
        ],
      },
      () => {
        if (this.props.plan) {
          this.setState({
            id: this.props.plan.id,
            checked: this.props.plan.checked,
            eventId: this.props.plan.simId
              ? this.props.plan.simId
              : this.props.plan.interviewId
              ? this.props.plan.interviewId
              : null,
            value: this.props.plan.value,
            dateStart: this.props.plan.dateStart,
            dateEnd: this.props.plan.dateEnd,
            indicator: this.props.plan.indicator
              ? this.props.plan.indicator
              : 0,
            indicator2: this.props.plan.indicator2
              ? this.props.plan.indicator2
              : 0,
            indicator2Options: this.props.plan.indicator
              ? [
                  { value: "DEFAULT", text: "Choisir une option", disabled: true },
                  ...this.get2ndIndicator(this.props.plan.indicator),
                ]
              : [{ value: 0, text: "Aucune option", disabled: true }],
          });
        }
      }
    );
  }

  // FORM INPUTS AND SELECT CHANGES
  handleFormChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      if (name === "indicator") {
        this.setState({
          indicator2: 0,
          indicator2Options: [
            { value: "DEFAULT", text: "Choisir une option", disabled: true },
            ...this.get2ndIndicator(this.state.indicator),
          ],
        });
      }
      this.props.editActionPlan(this.getData(), this.props.current);
    });
  };

  // FORM CHECKBOX CHANGES
  handleCheckboxChange = (event) => {
    if (!this.props.current || !this.props.disableEdition) {
      const { name, checked } = event.target;
      this.setState({ [name]: checked }, () => {
        this.props.editActionPlan(this.getData(), this.props.current);
      });
    }
  };

  // DATA TO SEND TO PARENT COMPOMENT
  getData = () => {
    const data = {
      id: this.state.id,
      checked: this.state.checked,
      dateStart: this.state.dateStart,
      dateEnd: this.state.dateEnd,
      indicator: this.state.indicator ? this.state.indicator : null,
      indicator2: this.state.indicator2 ? this.state.indicator2 : null,
      value: this.state.value,
    };

    if (this.state.eventId) {
      data.eventId = this.state.eventId;
    }
    if (this.props.name) {
      data.name = this.props.name;
    }

    return data;
  };

  // ACTION PLAN DELETE REQUEST
  handleDeleteRequest = () => {
    const message =
      "Êtes-vous sûrs de vouloir fermer ce plan d'action ? Cette action effacera les données initialisées dans ce plan d'action, elles seront perdues de manière définitive.";
    if (window.confirm(message)) {
      this.props.deleteActionPlan(this.state.id);
    }
  };

  // SECOND INDICATOR OPTIONS DEPENDING ON FIRST INDICATOR
  get2ndIndicator = (indicator) => {
    let indicatorOptions = [];
    let defaultIndicators = [
      "NR - Correctif selon marché HT dans bilan technique",
      "NR - Total +/- values  HT dans bilan technique",
      "R - % de préconisation PQE",
      "R - % de transformation PQE",
      "R - % de préconisation PRE",
      "R - % de transformation PRE",
    ];
    if (indicator.toUpperCase() === "COÛT") {
      indicatorOptions = [
        "R - Cout moyen réparable",
        "R - Taux de PRE",
        "R - Taux de jantes réparées",
        "R - Taux de pièces plastiques réparées",
        "R - Taux de boucliers réparés",
        "R - Taux de portes réparées",
        "R - Taux de radiateurs/condenseurs réparés",
        "R - Taux de pièces soudées réparées",
        "R - Taux de jupe arrière réparées",
        "R - Taux d'aile arrière réparées",
        "R - Taux d'aile avant réparées",
        "R - Taux feu réparés",
        "NR - Cout moyen VRADE",
        "NR - Résultats audit (uniquement VEI)",
      ];
    } else if (indicator.toUpperCase() === "DÉLAI") {
      indicatorOptions = [
        "R - Délai moyen dépôt",
        "R - Délai moyen mission - visite",
        "R - Délai moyen visite - dépôt",
        "R - Taux de dépôt rapport < 20j après réception mission",
        "R - Taux de dépôt rapport < 10j après expertise",
        "NR - Taux de SD29 < 8j après réception mission",
        "NR - Taux de SD29 < 2j après expertise",
        "NR - Délai moyen SD29",
        "NR - Délai moyen visite-SD29",
        "NR - Délai moyen mission - visite",
        "NR - Délai moyen visite - dépôt",
        "NR - Délai moyen dépôt",
        "NR - Taux de dépôt rapport < 20j après réception mission",
        "NR - Taux de dépôt rapport < 10j après expertise",
      ];
    } else if (indicator.toUpperCase() === "PRODUCTION") {
      indicatorOptions = [
        "Nombre de dossiers en Stock",
        "Productivité",
        "Honoraires",
      ];
    } else if (indicator.toUpperCase() === "QUALITÉ") {
      indicatorOptions = [
        "Résultats Précision",
        "Résultats Audit",
        "Résultats anomalies",
      ];
    }
    indicatorOptions = [...indicatorOptions, ...defaultIndicators];
    indicatorOptions = indicatorOptions.map((indic) => {
      return { value: indic, text: indic };
    });
    return indicatorOptions;
  };

  // ERROR MESSAGE
  errorMSG() {
    let error = [];
    if (this.state.dateStart === null) {
      error.push("Date de début");
    }
    if (this.state.dateEnd === null) {
      error.push("Date de fin");
    }
    if (this.state.indicator === null) {
      error.push("Indicateur");
    }
    if (this.state.value === null) {
      error.push("valeur");
    }
    let text = (
      <h2 style={{ color: "#ff0000" }}>
        Le ou les champs, {error.join(", ")} son manquant
      </h2>
    );
    return text;
  }

  render() {
    return (
      <div className={"mt-10 mt-30-xl "}>
        {this.props.name ? (
          <h4 className="mt-30 mb-10">{this.props.name} : </h4>
        ) : null}

        {/* ACTION PLAN FIRST LINE */}
        <Row>
          <Col xs={12} md={3} className="mt-10 mt-30-xl">
            <FormSelect
              title="Indicateur"
              name="indicator"
              options={this.state.indicatorOptions}
              value={this.state.indicator}
              onChange={this.handleFormChange}
              ignore={true}
              disabled={!this.props.current || this.props.disableEdition}
            />
          </Col>
          <Col xs={12} md={3} className="mt-10 mt-30-xl">
            <FormInput
              title="Date de début"
              type="date"
              name="dateStart"
              value={this.state.dateStart}
              onChange={this.handleFormChange}
              disabled={!this.props.current || this.props.disableEdition}
            />
          </Col>
          <Col xs={12} md={3} className="mt-10 mt-30-xl">
            <FormInput
              title="Date de fin"
              type="date"
              name="dateEnd"
              value={this.state.dateEnd}
              onChange={this.handleFormChange}
              min={new Date()}
              disabled={!this.props.current || this.props.disableEdition}
            />
          </Col>
          <Col
            xs={12}
            md={2}
            className="d-flex mt-10 mt-30-xl"
            style={{ flexDirection: "column" }}
          >
            <span style={{ fontSize: 14, fontWeight: "bold" }}>Valider</span>
            <FormCheckbox
              className="m-auto ml-0"
              name="checked"
              checked={this.state.checked}
              onChange={this.handleCheckboxChange}
            />
          </Col>
          <Col xs={12} md={1} className="d-flex">
            <ButtonIcon
              className="mt-10 mt-30-xl red"
              disabled={!this.props.current || this.props.disableEdition}
              onClick={this.handleDeleteRequest}
            >
              <FontAwesomeIcon icon={faTimes} />
            </ButtonIcon>
          </Col>
        </Row>

        {/* ACTION PLAN SECOND LINE (SECOND INDICATOR) */}
        {this.state.indicator ? (
          <FadeIn>
            <Row>
              <Col xs={12} md={3} className="mt-10 mt-30-xl">
                <FormSelect
                  title="Sous-Indicateur"
                  name="indicator2"
                  options={this.state.indicator2Options}
                  value={this.state.indicator2}
                  onChange={this.handleFormChange}
                  ignore={true}
                  disabled={!this.props.current || this.props.disableEdition}
                />
              </Col>
            </Row>
          </FadeIn>
        ) : null}

        {/* ACTION PLAN COMMENT */}
        <FormTextarea
          className="mt-10 mt-30-xl"
          rows={5}
          title="Commentaire"
          name="value"
          placeholder="Saisir votre texte ici"
          value={this.state.value}
          onChange={this.handleFormChange}
          disabled={!this.props.current || this.props.disableEdition}
        />

        {/* ERROR MODAL */}
        <Modal
          show={this.state.showErrorModal}
          onClose={() => this.setState({ showErrorModal: false })}
          title="Erreur"
        >
          <div>{this.errorMSG()}</div>
        </Modal>
      </div>
    );
  }
}
