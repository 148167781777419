import React, { Component } from "react";
import FadeIn from "react-fade-in";

import ActionPlanDisplayCloture from "../general/ActionPlanDisplayCloture";
import EvolutionDisplayCloture from "./EvolutionDisplayCloture";
import SignaturesDisplayCloture from "../general/SignaturesDisplayCloture";

export class SimCloturees extends Component {
  render() {
    return (
      <div>
        {this.props.evolutions &&
          this.props.evolutions.map((evol, idx) =>
            evol.length > 0 ? (
              <FadeIn key={idx}>
                <EvolutionDisplayCloture
                  evolutionData={evol}
                  evolutionType={idx}
                />
              </FadeIn>
            ) : null
          )}
        <FadeIn>
          {this.props.actionPlan ? (
            <ActionPlanDisplayCloture actionData={this.props.actionPlan} />
          ) : null}
          {this.props.signatures ? (
            <SignaturesDisplayCloture signatureData={this.props.signatures} />
          ) : null}
        </FadeIn>
      </div>
    );
  }
}

export default SimCloturees;
