import React, { Component } from "react";
import FadeIn from "react-fade-in/lib/FadeIn";
import {
  Card,
  TabsCustom,
  TabsCustomBody,
  TabsCustomHeader,
  TabsCustomItem,
  TabsCustomMenu,
  BarChart,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";

import GeneralCharts from "./GeneralCharts";
import TableQuality from "./TableQuality";
import TableReparable from "./TableReparable";
import TableUnreparable from "./TableUnreparable";
import TablePRE from "./TablePRE";

import { getColors } from "../../../utils/colors";

export class GeneralData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabsMenu: ["Géneral", "Réparables", "Non Réparables", "Qualité", "PRE"],
      // tabsIndex: 0,
      tabsData: [1, 1, 1, 1, 1],
    };
  }

  componentDidMount = () => {
    if (this.props.dataChart.length === 0 && this.props.tabsIndex === 0) {
      this.setState({ tabsData: [null, 1, 1, 1, 1] });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.isPrint !== this.props.isPrint) {
      setTimeout(
        () =>
          this.setState({ chartLabelsLoaded: this.props.isPrint }, () =>
            this.props.onLabelsLoaded(this.state.chartLabelsLoaded)
          ),
        50
      );
    }
  };

  filters = () => {
    if (parseInt(this.props.company) === 1) {
      // ALLIANZ
      if (this.props.tabsIndex === 1) {
        return (
          <div style={{ fontSize: "10px" }}>
            <p>genre : VP</p>
            <p>
              nature evenement : exclure événement climatique et vol non
              retrouvé
            </p>
            <p>réparations Ecrêtement : 20 à 10 000€ TTC</p>
            <p>nature expertise: exclure "I" et "3"</p>
          </div>
        );
      } else if (this.props.tabsIndex === 2) {
        return (
          <div style={{ fontSize: "10px" }}>
            <p>genre : VP</p>
            <p>
              nature evenement : exclure événement climatique et vol non
              retrouvé
            </p>
            <p>nature expertise: inclure "I" et "3"</p>
            <p>VRADE Ecrêtement : 20 à 10 000€ TTC</p>
          </div>
        );
      }
    } else if (parseInt(this.props.company) === 2) {
      // COVEA
      if (this.props.tabsIndex === 1) {
        return (
          <div style={{ fontSize: "10px" }}>
            <p>genre : VP CTTE </p>
            <p>nature évènement : hors CATNAT</p>
            <p>nature expertise: exclure "I" et "3"</p>
          </div>
        );
      } else if (this.props.tabsIndex === 2) {
        return (
          <div style={{ fontSize: "10px" }}>
            <p>genre : VP CTTE </p>
            <p>nature évènement : hors CATNAT</p>
            <p>nature expertise: inclure "I" et "3"</p>
          </div>
        );
      }
    } else if (parseInt(this.props.company) === 2) {
      // COVEA
      if (this.props.tabsIndex === 1) {
        return (
          <div style={{ fontSize: "10px" }}>
            <p>genre : VP CTTE </p>
            <p>nature expertise: exclure "I" et "3"</p>
          </div>
        );
      } else if (this.props.tabsIndex === 2) {
        return (
          <div style={{ fontSize: "10px" }}>
            <p>genre : VP CTTE </p>
            <p>nature expertise: inclure "I" et "3"</p>
          </div>
        );
      }
    } else if (
      parseInt(this.props.company) === 10 ||
      parseInt(this.props.company) === 11
    ) {
      // GAN - GROUPAMA
      if (this.props.tabsIndex === 1) {
        return (
          <div style={{ fontSize: "10px" }}>
            <p>genre : VP CTTE</p>
            <p>
              nature evenement : circulation, incendie, stationnement, vol et
              vandalisme
            </p>
            <p>nature expertise: exclure "I" et "3"</p>
            <p>réparations Ecrêtement : 230 € à 3 210 € HT</p>
          </div>
        );
      } else if (this.props.tabsIndex === 2) {
        return (
          <div style={{ fontSize: "10px" }}>
            <p>genre : VP CTTE</p>
            <p>
              nature evenement : circulation, incendie, stationnement, vol et
              vandalisme
            </p>
            <p>nature expertise: inclure "I" et "3"</p>
            <p>VRADE Ecrêtement : 604 € à 8 958 € HT</p>
          </div>
        );
      }
    } else if (parseInt(this.props.company) === 3) {
      // MACIF
      if (this.props.tabsIndex === 1) {
        return (
          <div style={{ fontSize: "10px" }}>
            <p>couts réparables :</p>
            <p>genre : VP CTTE</p>
            <p>nature évenement : circulation et stationnement</p>
            <p>nature expertise: exclure "I"</p>
            <p>
              réparations Ecrêtement : 21 € à 4 000 € HT / 21 € à 35 000 € HT
            </p>
            <p>Délai : </p>
            <p>tous les dossiers</p>
          </div>
        );
      } else if (this.props.tabsIndex === 2) {
        return (
          <div style={{ fontSize: "10px" }}>
            <p>Cout VRADE :</p>
            <p>genre : VP CTTE</p>
            <p>nature évenement : circulation et stationnement</p>
            <p>nature expertise: inclure "I"</p>
            <p>VRADE Ecrêtement : 100 € à 12 000 € TTC / 0 € à 35 000 € TTC </p>
            <p>Délai : </p>
            <p>tous les dossiers</p>
          </div>
        );
      }
    } else if (parseInt(this.props.company) === 8) {
      // MAIF
      if (this.props.tabsIndex === 1) {
        return (
          <div style={{ fontSize: "10px" }}>
            <p>Cout réparable :</p>
            <p>genre : VP CTTE </p>
            <p>
              Nature évenement : Collision, Voies de faits (hors CAT NAT), Vol,
              tentative de vol et vandalisme
            </p>
            <p>nature expertise: exclure "I" et "3"</p>
            <p>Réparation : Montant HT</p>
          </div>
        );
      } else if (this.props.tabsIndex === 2) {
        return (
          <div style={{ fontSize: "10px" }}>
            <p>Cout VRADE :</p>
            <p>genre : VP CTTE </p>
            <p>
              Nature évenement : Collision, Voies de faits (hors CAT NAT), Vol,
              tentative de vol et vandalisme
            </p>
            <p>nature expertise: inclure "I" et "3"</p>
            <p>
              {
                "VRADE : véhicules < 10 ans - 1 000 à 8 500 € HT ou véhicules >= 10 ans - 0 à 5 000 € HT"
              }
            </p>
          </div>
        );
      }
    } else if (parseInt(this.props.company) === 9) {
      // MATMUT
      if (this.props.tabsIndex === 1) {
        return (
          <div style={{ fontSize: "10px" }}>
            <p>genre : VP CTTE </p>
            <p>nature évènement : Collision, stationnement, vol et incendie</p>
            <p>nature expertise: exclure "I" et "3"</p>
          </div>
        );
      } else if (this.props.tabsIndex === 2) {
        return (
          <div style={{ fontSize: "10px" }}>
            <p>genre : VP CTTE </p>
            <p>nature évènement : Collision, stationnement, vol et incendie</p>
            <p>nature expertise: inclure "I" et "3"</p>
          </div>
        );
      }
    } else if (parseInt(this.props.company) === 20) {
      // AXA
      if (this.props.tabsIndex === 1) {
        return (
          <div style={{ fontSize: "10px" }}>
            <p>genre : VP CTTE </p>
            <p>nature évènement : exclure évènements climatiques et vol</p>
            <p>nature expertise: exclure "I" et "3"</p>
          </div>
        );
      } else if (this.props.tabsIndex === 2) {
        return (
          <div style={{ fontSize: "10px" }}>
            <p>genre : VP CTTE </p>
            <p>nature évènement : exclure évènements climatiques et vol</p>
            <p>nature expertise: inclure "I" et "3"</p>
          </div>
        );
      }
    } else if (parseInt(this.props.company) === 13) {
      // ABEILLE
      if (this.props.tabsIndex === 1) {
        return (
          <div style={{ fontSize: "10px" }}>
            <p>genre : VP CTTE </p>
            <p>nature évènement : exclure évènements climatiques et vol</p>
            <p>nature expertise: exclure "I" et "3"</p>
          </div>
        );
      } else if (this.props.tabsIndex === 2) {
        return (
          <div style={{ fontSize: "10px" }}>
            <p>genre : VP CTTE </p>
            <p>nature évènement : exclure évènements climatiques et vol</p>
            <p>nature expertise: inclure "I" et "3"</p>
          </div>
        );
      }
    } else if (parseInt(this.props.company) === 5) {
      // ACM
      if (this.props.tabsIndex === 1) {
        return (
          <div style={{ fontSize: "10px" }}>
            <p>genre : VP CTTE </p>
            <p>réparations entre 200 € et 4 600 € HT</p>
            <p>nature expertise: exclure "I" et "3"</p>
          </div>
        );
      } else if (this.props.tabsIndex === 2) {
        return (
          <div style={{ fontSize: "10px" }}>
            <p>genre : VP CTTE </p>
            <p>VRADE Ecrêtement 0 € à 13 000 € HT</p>
            <p>nature expertise: inclure "I" et "3"</p>
          </div>
        );
      }
    } else if (parseInt(this.props.company) === 18) {
      if (this.props.tabsIndex === 1) {
        return (
          <div style={{ fontSize: "10px" }}>
            <p>réparations {">"} 20 € HT HT</p>
            <p>nature expertise: exclure "I" et "3"</p>
          </div>
        );
      } else if (this.props.tabsIndex === 2) {
        return (
          <div style={{ fontSize: "10px" }}>
            <p>VRADE {">"} 20 € HT</p>
            <p>nature expertise: inclure "I" et "3"</p>
          </div>
        );
      }
    } else {
      if (this.props.tabsIndex === 1) {
        return (
          <div style={{ fontSize: "10px" }}>
            <p>genre : VP CTTE </p>
            <p>réparations {">"} 20 € HT HT</p>
            <p>nature expertise: exclure "I" et "3"</p>
          </div>
        );
      } else if (this.props.tabsIndex === 2) {
        return (
          <div style={{ fontSize: "10px" }}>
            <p>genre : VP CTTE </p>
            <p>VRADE {">"} 20 € HT</p>
            <p>nature expertise: inclure "I" et "3"</p>
          </div>
        );
      }
    }
  };

  getChart = (
    data,
    title,
    name,
    format,
    height = undefined,
    isLarge = false
  ) => {
    return (
      <Col xs={12} md={isLarge ? 12 : 6}>
        <BarChart
          title={title}
          format={format}
          height={height}
          dataSet={[
            {
              type: "line",
              data: data[name],
              label: "Isolé",
              color: "green",
            },
            {
              type: "line",
              data: data[`${name}_cumul`],
              label: "Cumulé",
              color: "red",
            },
          ]}
          isLegend={true}
          yAxes={[
            {
              display: true,
            },
          ]}
        />
      </Col>
    );
  };

  getChart2 = (dataSet, title, isLegend = true, isLarge = false) => {
    return (
      <Col xs={12} md={isLarge ? 12 : 6}>
        <BarChart
          title={title}
          format={"percentage"}
          dataSet={dataSet}
          isLegend={isLegend}
          yAxes={[
            {
              display: true,
            },
          ]}
          height={350}
        />
      </Col>
    );
  };

  render() {
    const filters = this.filters();
    const colors = getColors();

    const chartR = [
      {
        title: "Cout moyen réparable",
        name: "averageCost",
        format: "currency",
      },
      {
        title: "Cout moyen Pièces",
        name: "piecesCostGlobal",
        format: "currency",
      },
      { title: "Nombre heures", name: "totalHourGlobal" },
      { title: "Nombre heures peinture ", name: "totalHourPaintGlobal" },
      { title: "Tx de PRE", name: "PREGlobal", format: "percentage" },
      { title: "Tx de RP", name: "RPGlobal", format: "percentage" },
      { title: "Délai mission-depot", name: "missionDepotGlobal" },
      { title: "Âge véhicule", name: "ageVehicleGlobal" },
      { title: "Précisia", name: "predict", isLarge: true },
    ];

    const chartNR = [
      { title: "Cout moyen VRADE", name: "VRADE", format: "currency" },
      { title: "Délai SD29", name: "missionSD29Global" },
      { title: "Délai mission dépot", name: "missionDepotGlobal" },
      { title: "Taux de VEI", name: "VEI", format: "percentage" },
      {
        title: "Prix à neuf avec options HT",
        name: "vehicleTotalPriceDutyFree",
        format: "currency",
      },
      { title: "Kilométrage moyen", name: "averageMileage" },
      {
        title: "Taux de correctif selon marché HT dans bilan technique",
        name: "correctiveRateTechnicalReport",
        format: "percentage",
      },
      {
        title: "Taux de +/- values HT dans bilan technique",
        name: "resultTechnicalReportDutyFree",
        format: "percentage",
      },
      { title: "Âge véhicule", name: "ageVehicleGlobal" },
      { title: "Précisia", name: "predict" },
    ];

    return (
      <FadeIn>
        <TabsCustom>
          <TabsCustomHeader>
            {this.state.tabsMenu.map((element, idx) => (
              <TabsCustomMenu
                key={idx}
                title={element}
                active={this.props.tabsIndex === idx}
                disabled={!this.state.tabsData[idx]}
                onClick={(e) => {
                  this.state.tabsData[idx] && this.props.onChangeTab(idx);
                }}
              />
            ))}
          </TabsCustomHeader>
          <TabsCustomBody noCard>
            {this.state.tabsMenu.map((element, idx) => (
              <TabsCustomItem key={idx} active={this.props.tabsIndex === idx}>
                {this.props.tabsIndex === 0 ? (
                  <GeneralCharts
                    data={this.props.dataChart && this.props.dataChart}
                    isPrint={this.props.isPrint}
                  />
                ) : this.props.tabsIndex === 1 ? (
                  <>
                    <Card title="Graphiques" className="mb-20">
                      <Row>
                        {chartR.map((chart) =>
                          this.getChart(
                            this.props.data.chartR,
                            chart.title,
                            chart.name,
                            chart.format,
                            undefined,
                            chart.isLarge
                          )
                        )}
                      </Row>
                    </Card>
                    <Card title="Tableau">
                      {filters}
                      <TableReparable
                        data={this.props.data && this.props.data.R}
                        company={this.props.company}
                        performanceAgency={this.props.performanceAgency}
                        updatePerformanceAgency={
                          this.props.updatePerformanceAgency
                        }
                        leaveUpdatePerformanceAgency={
                          this.props.leaveUpdatePerformanceAgency
                        }
                      />
                    </Card>
                  </>
                ) : this.props.tabsIndex === 2 ? (
                  <>
                    <Card title="Graphiques" className="mb-20">
                      <Row>
                        {chartNR.map((chart) =>
                          this.getChart(
                            this.props.data.chartNR,
                            chart.title,
                            chart.name,
                            chart.format
                          )
                        )}
                      </Row>
                    </Card>
                    <Card title="Tableau">
                      {filters}
                      <TableUnreparable
                        data={this.props.data && this.props.data.NR}
                        company={this.props.company}
                        performanceAgency={this.props.performanceAgency}
                        updatePerformanceAgency={
                          this.props.updatePerformanceAgency
                        }
                        leaveUpdatePerformanceAgency={
                          this.props.leaveUpdatePerformanceAgency
                        }
                      />
                    </Card>
                  </>
                ) : this.props.tabsIndex === 3 ? (
                  <Card>
                    <TableQuality
                      data={this.props.data && this.props.data.quality}
                      company={this.props.company}
                      performanceAgency={this.props.performanceAgency}
                      updatePerformanceAgency={
                        this.props.updatePerformanceAgency
                      }
                      leaveUpdatePerformanceAgency={
                        this.props.leaveUpdatePerformanceAgency
                      }
                    />
                  </Card>
                ) : this.props.tabsIndex === 4 ? (
                  <>
                    <Card title="Graphiques" className="mb-20">
                      <Row>
                        {this.getChart(
                          this.props.data.chartR,
                          `${chartR[4].title} ${
                            !this.props.group ? "Réseau" : ""
                          }
                          ${
                            this.props.group && !this.props.region
                              ? "Groupe"
                              : ""
                          }
                          ${
                            this.props.region && !this.props.agency.length
                              ? "Région"
                              : ""
                          }
                          ${
                            this.props.agency.length === 1 && !this.props.expert
                              ? "Agence"
                              : ""
                          } ${this.props.expert ? "Expert" : ""}`,
                          chartR[4].name,
                          chartR[4].format,
                          350
                        )}
                        {this.props.region
                          ? null
                          : this.getChart2(
                              Object.keys(this.props.data.chartPRE.region).map(
                                (key, idx) => {
                                  return {
                                    type: "line",
                                    data: this.props.data.chartPRE.region[key]
                                      .data,
                                    label:
                                      this.props.data.chartPRE.region[key].name,
                                    color: colors[idx],
                                  };
                                }
                              ),
                              "Taux de PRE / région"
                            )}
                        {this.props.agency.length === 1
                          ? null
                          : this.getChart2(
                              Object.keys(this.props.data.chartPRE.agency).map(
                                (key) => {
                                  return {
                                    type: "line",
                                    data: this.props.data.chartPRE.agency[key]
                                      .data,
                                    label:
                                      this.props.data.chartPRE.agency[key].name,
                                    color:
                                      this.props.data.chartPRE.agency[key]
                                        .color,
                                  };
                                }
                              ),
                              "Taux de PRE / agence"
                            )}
                        {this.props.user
                          ? null
                          : this.getChart2(
                              Object.keys(this.props.data.chartPRE.expert).map(
                                (key) => {
                                  return {
                                    type: "line",
                                    data: this.props.data.chartPRE.expert[key]
                                      .data,
                                    label:
                                      this.props.data.chartPRE.expert[key].name,
                                    color:
                                      this.props.data.chartPRE.expert[key]
                                        .color,
                                  };
                                }
                              ),
                              "Taux de PRE / expert",
                              false,
                              this.props.region &&
                                this.props.agency.length !== 1
                            )}
                      </Row>
                    </Card>
                    <Card title="Tableau">
                      <TablePRE
                        data={this.props.data && this.props.data.R}
                        company={this.props.company}
                        performanceAgency={this.props.performanceAgency}
                        updatePerformanceAgency={
                          this.props.updatePerformanceAgency
                        }
                        leaveUpdatePerformanceAgency={
                          this.props.leaveUpdatePerformanceAgency
                        }
                      />
                    </Card>
                  </>
                ) : null}
              </TabsCustomItem>
            ))}
          </TabsCustomBody>
        </TabsCustom>
      </FadeIn>
    );
  }
}

export default GeneralData;
