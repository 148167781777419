import React from "react";
import { Col, Row } from "react-flexbox-grid";
import { Card } from "ui-kit-ck-consultant";

import PieChartSatisfaction from "./others/PieChartSatisfaction";

export default class Folder extends React.Component {
  render() {
    return (
      <Card className={this.props.className} title="Gestion du dossier">
        <Row>
          <Col xs={12} lg={4} className="d-flex">
            <PieChartSatisfaction
              title={"Clarté / pertinence"}
              data={
                this.props.generalSatisfactionData
                  ? this.props.generalSatisfactionData["gestion_information"]
                  : []
              }
            />
          </Col>
          <Col xs={12} lg={4} className="d-flex">
            <PieChartSatisfaction
              title={"Délai de réponse"}
              data={
                this.props.generalSatisfactionData
                  ? this.props.generalSatisfactionData["gestion_reponse"]
                  : []
              }
            />
          </Col>
          <Col xs={12} lg={4} className="d-flex">
            <PieChartSatisfaction
              title={"Amabilité"}
              data={
                this.props.generalSatisfactionData
                  ? this.props.generalSatisfactionData["gestion_amabilite"]
                  : []
              }
            />
          </Col>
        </Row>
      </Card>
    );
  }
}
