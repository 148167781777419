import React, { Component } from "react";
import { Modal, Button, FormRadio, Loader } from "ui-kit-ck-consultant";
import { ExportCSV } from "react-export-csv";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import removeAccents from "remove-accents";

export default class ModalExportCSV extends Component {
  constructor(props) {
    super(props);
    this.state = {
      radioExport: null,
      isDownload: false,
      newData: null,
    };
  }
  handleRadioChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleClick = (e) => {
    let data =
      this.props.data &&
      this.props.data.map((element) => {
        if (element.name) {
          element.name = removeAccents(element.name);
        }
        if (element.state) {
          element.name = removeAccents(element.state);
        }
        return element;
      });
    this.setState({ isDownload: true, newData: data });
  };

  render() {
    return (
      <>
        <Modal
          title={"Exportation CSV"}
          show={this.props.show}
          onClose={this.props.onClose}
        >
          <FormRadio
            name="radioExport"
            value={"xlsx"}
            text="Excel"
            onChange={this.handleRadioChange}
          />
          <FormRadio
            name="radioExport"
            value={"csv"}
            text="Sheets"
            onChange={this.handleRadioChange}
          />
          <Button
            className="mt-10"
            text={"Télécharger le fichier"}
            disabled={this.state.radioExport === null}
            onClick={this.handleClick}
          >
            <FontAwesomeIcon icon={faDownload} />
          </Button>
        </Modal>
        <Loader show={this.state.isDownload} />
        {this.state.isDownload ? (
          <ExportCSV
            header={this.props.header}
            data={this.state.newData}
            separator={this.state.radioExport === "csv" ? ";" : ","}
            callback={() => {
              this.setState(
                { isDownload: false, newData: null },
                this.props.onClose
              );
            }}
            isDownload={true}
            filename={`${this.props.filename}.${this.state.radioExport}`}
          />
        ) : null}
      </>
    );
  }
}
