import React, { Component } from "react";
import {
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Pagination,
  ButtonIcon,
  BarChart,
} from "ui-kit-ck-consultant";
import { Scatter } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from "chart.js";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExportCSV } from "react-export-csv";

import { getPercentageValue } from "../../../../../utils/general";
import { colors } from "../../../../../utils/colors";
import handleSortClick from "../../../../../utils/handleSortClickV2";

export default class AlphaScale extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLength: 20,
      activePage: 1,
      isDownload: false,
    };

    this.sortRef = [];
  }

  getEvolution = (name, id, idx) => {
    const result = this.props.dataOld.find((element) => element.id === id);

    if (result) {
      if (this.props.data[idx][name] && result[name]) {
        return (this.props.data[idx][name] * 100) / result[name] - 100;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  handleSortClick = (desc, column) => {
    this.setState({ order: handleSortClick(this.sortRef, desc, column) });
  };

  sortData = () => {
    let data = this.props.data;

    if (this.state.order && this.state.order.name) {
      data = data.sort((a, b) => {
        return this.state.order.desc
          ? b[this.state.order.name] - a[this.state.order.name]
          : a[this.state.order.name] - b[this.state.order.name];
      });
    }

    return data;
  };

  render() {
    ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

    const options = {
      scales: {
        y: {
          grid: {
            display: false,
          },
          ticks: {
            callback: function (value, index, ticks) {
              return `${value}%`;
            },
          },
        },
        x: {
          grid: {
            display: false,
          },
          ticks: {
            callback: function (value, index, ticks) {
              return `${value}%`;
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: function (context) {
              return [
                context.raw.name,
                `Connexion : ${getPercentageValue(context.raw.x)}`,
                `Substitution : ${getPercentageValue(context.raw.y)}`,
              ];
            },
          },
        },
      },
    };

    return (
      <>
        {this.props.dataMonth ? (
          <Card className="mb-10 mb-20-xl" title="Évolution">
            <BarChart
              format="percentage"
              labels={this.props.dataMonth.months}
              dataSet={[
                {
                  type: "line",
                  label: "Substitution",
                  data: this.props.dataMonth.substitutionRate,
                  color: colors.red[0],
                },
                {
                  label: "Connexion",
                  data: this.props.dataMonth.connectionRate,
                  color: colors.blue[0],
                },
              ]}
            />
          </Card>
        ) : null}
        <Card
          className="mb-10 mb-20-xl"
          title={`Graphique (Connexion : ${getPercentageValue(
            this.props.total.connectionRate
          )} | Substitution : ${getPercentageValue(
            this.props.total.substitutionRate
          )})`}
        >
          <div style={{ fontSize: "10px" }} className="mb-20">
            <p>Genre : "VP" et "CTTE"</p>
            <p>Exclure nature évènement : "Volé non retrouvé" et "Immersion"</p>
            <p>
              Exclure nature expertise : "Estimation sur pièces", "Véh.
              économmiquement irréparable (VEI)" et "Carence"
            </p>
            <p>Origine : "DARVA"</p>
          </div>

          <Scatter
            options={options}
            data={{
              datasets: [
                {
                  label: "",
                  data: this.props.data.map((element) => ({
                    x: element.connectionRate,
                    y: element.substitutionRate,
                    name: element.name,
                  })),
                  backgroundColor: this.props.data.map(
                    (element) => element.color
                  ),
                  borderColor: this.props.data.map((element) => element.color),
                },
                {
                  type: "line",
                  label: "Objectif connexion 85%",
                  data: [
                    {
                      x: 85,
                      y: 0,
                    },
                    {
                      x: 85,
                      y: 100,
                    },
                  ],
                  backgroundColor: null,
                  borderColor: colors.gray[0],
                },
                {
                  type: "line",
                  label: "Objectif substitution 35%",
                  data: [
                    {
                      x: 0,
                      y: 35,
                    },
                    {
                      x: 100,
                      y: 35,
                    },
                  ],
                  backgroundColor: null,
                  borderColor: colors.gray[0],
                },
              ],
            }}
          />
        </Card>
        <Card
          className="mb-10 mb-20-xl"
          title="Tableau"
          action={
            <ButtonIcon
              small
              className="blue"
              onClick={() => {
                this.setState({ isDownload: !this.state.isDownload });
              }}
            >
              <FontAwesomeIcon icon={faDownload} />
            </ButtonIcon>
          }
        >
          <Table>
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th ref={(ref) => (this.sortRef[0] = ref)}>Nom</Th>
                <Th
                  ref={(ref) => (this.sortRef[1] = ref)}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 1)}
                  nameSort="connectionRate"
                >
                  Tx. de connexion
                </Th>
                <Th ref={(ref) => (this.sortRef[2] = ref)}>Évol. N / N - 1</Th>
                <Th
                  ref={(ref) => (this.sortRef[3] = ref)}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 3)}
                  nameSort="substitutionRate"
                >
                  Tx. de substitution
                </Th>
                <Th ref={(ref) => (this.sortRef[4] = ref)}>Évol. N / N - 1</Th>
                <Th
                  ref={(ref) => (this.sortRef[5] = ref)}
                  sort
                  onSort={(desc) => this.handleSortClick(desc, 5)}
                  nameSort="total"
                >
                  Nbr. dossiers
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {(!this.props.isPrint
                ? this.sortData().slice(
                    (this.state.activePage - 1) * this.state.pageLength,
                    this.state.activePage * this.state.pageLength
                  )
                : this.sortData()
              ).map((element, idx) => (
                <Tr key={idx}>
                  <Td>
                    {(this.state.activePage - 1) * this.state.pageLength +
                      idx +
                      1}
                  </Td>
                  <Td>{element.name}</Td>
                  <Td>{getPercentageValue(element.connectionRate)}</Td>
                  <Td>
                    {getPercentageValue(
                      this.getEvolution("connectionRate", element.id, idx)
                    )}
                  </Td>
                  <Td>{getPercentageValue(element.substitutionRate)}</Td>
                  <Td>
                    {getPercentageValue(
                      this.getEvolution("substitutionRate", element.id, idx)
                    )}
                  </Td>
                  <Td>{element.total}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          {!this.props.isPrint ? (
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.pageLength}
              totalItemsCount={this.props.data.length}
              pageRangeDisplayed={4}
              onChange={(activePage) => {
                this.setState({ activePage: activePage });
              }}
              firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
              lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
              prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
              nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
            />
          ) : null}
        </Card>
        {this.state.isDownload ? (
          <>
            <ExportCSV
              header={[
                {
                  name: "Agence",
                  key: "agency",
                },
                {
                  name: "Expert",
                  key: "expert",
                },
                {
                  name: "Réparateur",
                  key: "garage",
                },
                {
                  name: "Département",
                  key: "department",
                },
                {
                  name: "Référence",
                  key: "reference",
                },
                {
                  name: "Total rapport HT",
                  key: "totalReportDutyFree",
                },
                {
                  name: "Marque",
                  key: "brand",
                },
                {
                  name: "Modèle",
                  key: "model",
                },
                {
                  name: "Connexion",
                  key: "isConnection",
                },
                {
                  name: "Substitution",
                  key: "isSubstitution",
                },
              ]}
              data={this.props.totalCSV}
              separator={";"}
              callback={() =>
                this.setState({
                  isDownload: !this.state.isDownload,
                })
              }
              isDownload={true}
              filename={`AlphaScale.csv`}
            />
          </>
        ) : null}
      </>
    );
  }
}
