import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMedal } from "@fortawesome/free-solid-svg-icons";

export default class Podium extends Component {
  getColor(rank) {
    switch (rank) {
      case 1:
        return "green";
      case 2:
        return "blue";
      case 3:
        return "purple";
      default:
        return "";
    }
  }

  getBackgroundColor(rank) {
    switch (rank) {
      case 1:
        return "bg-green-light";
      case 2:
        return "bg-blue-light";
      case 3:
        return "bg-purple-light";
      default:
        return "";
    }
  }

  render() {
    let heightPodium = 300;
    if (this.props.height) {
      heightPodium = (300 * this.props.height) / 100;
    }

    let format = "";
    if (this.props.format === "currency") {
      format = "€";
    } else if (this.props.format === "percentage") {
      format = "%";
    }

    return (
      <div
        className={this.props.className}
        style={{
          width: "100%",
          maxWidth: "120px",
        }}
      >
        <div
          style={{
            height: heightPodium,
            borderTopRightRadius: 15,
            borderTopLeftRadius: 15,
            display: "flex",
            flexDirection: "column",
          }}
          className={this.getBackgroundColor(this.props.rank)}
        >
          <FontAwesomeIcon
            style={{
              position: "relative",
              marginLeft: "auto",
              marginRight: "auto",
              fontSize: 30,
            }}
            icon={faMedal}
            className={this.getColor(this.props.rank)}
          />
          <span
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "20px",
            }}
            className={this.getColor(this.props.rank)}
          >
            {this.props.rank}
          </span>

          <span
            style={{
              textAlign: "center",
              marginTop: "auto",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: 5,
              fontFamily: "Arial",
              fontSize: 18,
              color: "var(--secondary-color)",
            }}
          >
            {this.props.dataNumber} {format}
          </span>

          <span
            style={{
              textAlign: "center",
              marginTop: "0",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: 10,
              fontFamily: "Arial",
              fontSize: 15,
              color: "var(--secondary-color)",
            }}
          >
            {this.props.subDataNumber}
          </span>
        </div>
        <div
          style={{
            borderBottomRightRadius: 15,
            borderBottomLeftRadius: 15,
            backgroundColor: "#5c5c5c",
            display: "flex",
            flexDirection: "column",
            fontSize: 12,
            color: "var(--secondary-color)",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <span
            style={{
              textAlign: "center",
              margin: "auto",
              color: "var(--white)",
              fontSize: 12,
            }}
          >
            {this.props.dataText}
          </span>
          {this.props.evolution ? <div className="m-auto mt-5 mb-0">
            {this.props.evolution}
          </div> : null}
        </div>
      </div>
    );
  }
}
