import React from "react";

import { ButtonIcon, Card, Status } from "ui-kit-ck-consultant";
import { faLock, faUnlock, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CommentArea from "./CommentArea";

export default class CardIdea extends React.Component {
  render() {
    const idea = this.props.idea;

    return (
      <Card
        title={`${idea.type} : ${idea.title ? idea.title : ""}`}
        className="mb-10 mb-20-xl"
        disableStyleAction={true}
        action={
          <div className="d-flex">
            <Status
              className={
                "m-auto " +
                (idea.status === 0
                  ? "bg-blue"
                  : idea.status === 1
                  ? "bg-orange"
                  : "bg-red")
              }
              text={
                idea.status === 0
                  ? "Faible"
                  : idea.status === 1
                  ? "Normal"
                  : "Urgent"
              }
            />
            <Status
              className={
                "m-auto ml-10 " +
                (idea.status === 0
                  ? "bg-red"
                  : idea.status === 1
                  ? "bg-orange"
                  : "bg-green")
              }
              text={
                idea.status === 0
                  ? "Nouveau"
                  : idea.status === 1
                  ? "En cours"
                  : "Cloturé"
              }
            />
            {!this.props.network ? (
              <>
                <ButtonIcon
                  small
                  onClick={() => this.props.onClickClose(idea.id, idea.status)}
                  className="m-auto"
                >
                  <FontAwesomeIcon
                    className={"black"}
                    icon={idea.status <= 1 ? faLock : faUnlock}
                  />
                </ButtonIcon>
                <ButtonIcon
                  small
                  onClick={() => this.props.onClickDelete(idea.id)}
                  className="m-auto"
                >
                  <FontAwesomeIcon className={"black"} icon={faTrash} />
                </ButtonIcon>
              </>
            ) : null}
          </div>
        }
      >
        <p>
          <b> Catégorie:</b> {idea.category}
        </p>
        <div>
          {idea.content.split("\n").map((element, idx) => (
            <p key={idx}>{element}</p>
          ))}
        </div>

        <i>
          Ajouté le {idea.date} par {idea.userName}
        </i>
        <CommentArea
          reload={this.props.reload}
          comments={idea.comments}
          user={idea.user}
          idea={idea.id}
          status={idea.status}
        />
      </Card>
    );
  }
}
