import React from "react";
import { FormTextarea, Modal, Button } from "ui-kit-ck-consultant";

export default class ModalEditComment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: "",
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.comment) !== "{}" &&
      JSON.stringify(prevProps.comment) !== JSON.stringify(this.props.comment)
    ) {
      this.setState({
        comment: this.props.comment.comment,
      });
    }
  }

  render() {
    return (
      <Modal
        onClose={this.props.onClose}
        show={this.props.show}
        title="Modifier le commentaire"
      >
        <FormTextarea
          value={this.state.comment}
          name={"comment"}
          title="Commentaire"
          onChange={this.handleInputChange}
        />
        <Button
          text="Modifier"
          onClick={() => this.props.updateComment(this.state.comment)}
        />
      </Modal>
    );
  }
}
