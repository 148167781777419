import React from "react";

export default class Guadeloupe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <g transform="translate(600, 150)">
        <svg
          width="50"
          height="50"
          viewBox="0 0 28 25"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill={this.props.fill || "none"}
            stroke="#232323"
            strokeWidth={1}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.2664 0.650391L13.7667 1.00674L12.3858 2.2243L12.4452 4.95642L13.5143 5.13458L13.5737 6.8273L12.5195 7.64398L12.3264 8.98032V9.2179L11.391 10.1979L11.3316 10.3761L9.26766 10.2573L9.01523 9.26245L7.51554 8.40121L4.84283 7.00549H1.90285L0.536804 9.09912L0.596201 12.1133L0.967409 13.3458L1.02681 13.5685L1.21983 15.8997L2.09588 20.2057L2.97194 22.4775C2.97194 22.4775 3.58953 23.0626 3.71436 23.3536C3.83919 23.6446 4.53102 24.5712 4.53102 24.5712L5.89707 24.7493L6.28313 24.0366L6.34253 24.1108H7.70857L9.7725 22.3587L11.0792 20.5621L11.0198 16.3748L10.2625 14.1624L10.5149 12.3509L11.9404 12.0688H11.9552L12.3858 12.5885L13.5737 12.8706L14.5091 13.2269L14.5982 13.1824L14.7022 13.5239L16.5137 13.6279L19.6318 13.0488L21.384 11.7718L24.4427 11.653L27.056 10.777L25.4376 9.85638L25.2445 8.98032L23.1806 7.64398L19.2606 7.58456L18.0728 5.55035V3.27853L17.2561 1.52643L15.2664 0.650391Z"
          />
        </svg>

        <text x={"-2%"} y={"-1%"}>
          Guadeloupe
        </text>
      </g>
    );
  }
}
