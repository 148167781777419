import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import getRoutesSidebar from "./permissions/routesSidebar";

import axiosConfig from "./utils/axiosConfig";
import { getCompanies } from "./utils/general";

import AuthContext from "./context/AuthContext";

import ReactGA from "react-ga4";
ReactGA.initialize("G-Q0WTW88Y4G");

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      connected: null,
      administration: null,
      id: null,
      network: null,
      group: null,
      region: null,
      agency: null,
      fake: null,
      type: null,
      role: null,
      name: null,
      manageAgency: null,
      roleStr: null,
      lastUpdate: null,
      urlForm: null,
      urlPdf: null,
      url: null,
      urlAdmin: null,
      picture: null,
      routes: [],
      sidebar: [],
      companyOptions: [],
      agencyLatitude: null,
      agencyLongitude: null,
    };

    this.hiddenPages = { expert: false, ead: false };
  }

  componentDidMount() {
    this.checkAuth(true);
    this.intervalToken = setInterval(() => {
      this.checkAuth();
    }, 30 * 60 * 1000);
  }

  checkAuth = (isInit = false) => {
    const pathArray = document.location.href.split("/");
    if (navigator.onLine) {
      var url = window.location.href;
      var arr = url.split("/");
      let token = null;
      if (pathArray.includes("success")) {
        token = pathArray[pathArray.length - 1];
      }
      axiosConfig("/auth/refresh-token", {
        data: {
          isInit,
          token: token,
          url: arr[0] + "//" + arr[2],
        },
      }).then((res) => {
        if (res.data.success === true) {
          if (res.data.redirect) {
            document.location.href = res.data.redirect;
          } else {
            if (pathArray.includes("success")) {
              document.location.href = "/";
            } else {
              let resultRoutesSidebar = getRoutesSidebar(
                res.data.data.type,
                res.data.data.role,
                res.data.data.network,
                res.data.data.group,
                res.data.data.region,
                res.data.data.agency,
                res.data.data.fake,
                this.hiddenPages
              );

              resultRoutesSidebar.sidebar.forEach((route, idx) => {
                if (
                  route.isRed &&
                  parseInt(localStorage.getItem("lastUpdate")) ===
                    parseInt(res.data.data.lastUpdate)
                ) {
                  resultRoutesSidebar.sidebar[idx].isRed = false;
                }
              });

              let newState = {
                isReady: true,
                connected: true,
                administration: res.data.data.administration,
                id: res.data.data.id,
                network: res.data.data.network,
                group: res.data.data.group,
                region: res.data.data.region,
                agency: res.data.data.agency,
                fake: res.data.data.fake,
                type: res.data.data.type,
                role: res.data.data.role,
                name: res.data.data.name,
                manageAgency: res.data.data.manageAgency,
                roleStr: res.data.data.roleStr,
                lastUpdate: res.data.data.lastUpdate,
                urlForm: res.data.urlForm,
                urlPdf: res.data.urlPdf,
                url: res.data.url,
                urlAdmin: res.data.urlAdmin,
                picture: isInit ? res.data.picture : this.state.picture,
                routes: [],
                sidebar: [],
                agencyLatitude: res.data.data.agencyLatitude,
                agencyLongitude: res.data.data.agencyLongitude,
              };

              if (this.oldState !== JSON.stringify(newState)) {
                this.oldState = JSON.stringify(newState);
                this.checkHiddenPages(
                  res.data.data.agency,
                  res.data.data.manageAgency,
                  () => {
                    getCompanies((companyOptions) => {
                      this.getNatureEvent((resNatureEvent) => {
                        this.getNatureExpertise((resNatureExpertise) => {
                          this.getBrand((resBrand) => {
                            newState.routes = resultRoutesSidebar.routes;
                            newState.sidebar = resultRoutesSidebar.sidebar;
                            newState.companyOptions = companyOptions;
                            newState.natureEventOptions = resNatureEvent.success
                              ? resNatureEvent.data
                              : [];
                            newState.natureExpertiseOptions =
                              resNatureExpertise.success
                                ? resNatureExpertise.data
                                : [];
                            newState.brandOptions = resBrand.success
                              ? resBrand.data
                              : [];
                            this.setState(newState);
                          });
                        });
                      });
                    });
                  },
                  true
                );
                this.setState(newState);
              }
            }
          }
        } else {
          this.setState({ connected: false }, () => {
            setTimeout(() => {
              document.location.href = res.data.redirect;
            }, 1000);
          });
        }
      });
    }
  };

  getNatureEvent = (callback) => {
    axiosConfig("/general/get-nature-event")
      .then((res) => {
        callback(res.data);
      })
      .catch(() => callback({ success: false }));
  };

  getNatureExpertise = (callback) => {
    axiosConfig("/general/get-nature-expertise")
      .then((res) => {
        callback(res.data);
      })
      .catch(() => callback({ success: false }));
  };

  getBrand = (callback) => {
    axiosConfig("/general/get-brand")
      .then((res) => {
        callback(res.data);
      })
      .catch(() => callback({ success: false }));
  };

  componentWillUnmount() {
    clearInterval(this.intervalToken);
  }

  checkHiddenPages = (agency, manageAgency, callback) => {
    let manageAgencyArray = manageAgency ? manageAgency.split(",") : [];
    manageAgencyArray.push(agency);

    axiosConfig({
      url: "/general/get-agencies",
      data: {
        network: 0,
        group: 0,
        region: 0,
        isAll: true,
        isFake: true,
        manageAgency,
        userType: [2],
      },
    }).then((res) => {
      let expertHidden = false;
      if (res.data.success) {
        if (
          res.data.agencies.filter(
            (el) => el.value && manageAgencyArray.includes(el.value.toString())
          ).length === 0
        ) {
          expertHidden = true;
        }
      }

      this.hiddenPages = { ...this.hiddenPages, expert: expertHidden };

      axiosConfig({
        url: "/general/get-agencies",
        data: {
          network: 0,
          group: 0,
          region: 0,
          isAll: true,
          isFake: true,
          manageAgency,
          userType: [3],
        },
      }).then((res) => {
        let eadHidden = false;
        if (res.data.success) {
          if (
            res.data.agencies.filter(
              (el) =>
                el.value && manageAgencyArray.includes(el.value.toString())
            ).length === 0
          ) {
            eadHidden = true;
          }
        }

        this.hiddenPages = { ...this.hiddenPages, ead: eadHidden };
        callback();
      });
    });
  };

  render() {
    if (!this.state.isReady) {
      return <></>;
    }

    return (
      <AuthContext.Provider
        value={{
          id: this.state.id,
          network: this.state.network,
          group: this.state.group,
          region: this.state.region,
          agency: this.state.agency,
          fake: this.state.fake,
          type: this.state.type,
          role: this.state.role,
          name: this.state.name,
          manageAgency: this.state.manageAgency,
          roleStr: this.state.roleStr,
          lastUpdate: this.state.lastUpdate,
          administration: this.state.administration,
          urlForm: this.state.urlForm,
          urlPdf: this.state.urlPdf,
          url: this.state.url,
          urlAdmin: this.state.urlAdmin,
          picture: this.state.picture,
          companyOptions: this.state.companyOptions,
          natureEventOptions: this.state.natureEventOptions,
          natureExpertiseOptions: this.state.natureExpertiseOptions,
          fiscalPowerOptions: [
            1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
            20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
            37, 38, 39, 40,
          ],
          brandOptions: this.state.brandOptions,
          agencyLatitude: this.state.agencyLatitude,
          agencyLongitude: this.state.agencyLongitude,
        }}
      >
        <Router basename={process.env.REACT_APP_BASENAME || ""}>
          <Switch>
            {this.state.routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  component={(props) => {
                    return (
                      <route.layout
                        {...props}
                        connected={this.state.connected}
                        checkAuth={this.checkAuth}
                        sidebar={this.state.sidebar}
                      >
                        <route.component
                          {...props}
                          checkAuth={this.checkAuth}
                          visibilityType={route.visibilityType}
                          visibilityRole={route.visibilityRole}
                          preloadType={route.preloadType}
                          preloadRole={route.preloadRole}
                          limitUserType={route.limitUserType}
                          limitUserRole={route.limitUserRole}
                          allUserRole={route.allUserRole}
                          type={route.type}
                        />
                      </route.layout>
                    );
                  }}
                />
              );
            })}
          </Switch>
        </Router>
      </AuthContext.Provider>
    );
  }
}
