import React, { Component } from "react";

import {
  Card,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  BarChart,
  Loader,
  Status,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";

import axiosConfig from "../../../../utils/axiosConfig";
import {
  getCurrencyValue,
  getPercentageValue,
} from "../../../../utils/general";

export default class CardMACIF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      search: null,
      isLoad: false,
    };
  }

  componentDidMount() {
    this.getPerformance();
  }

  componentDidUpdate = (prevProps) => {
    if (
      JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data) &&
      parseInt(this.props.data.company) === 3
    ) {
      this.getPerformance();
    }
  };

  getPerformance = () => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("/company-performance/macif", {
        data: {
          dateStart: this.props.data.dateStart,
          dateEnd: this.props.data.dateEnd,
          network: this.props.data.network,
          group: this.props.data.group,
          region: this.props.data.region,
          agency: this.props.data.agency,
          user: this.props.data.user,
          company: this.props.data.company,
          filter: this.props.data.filter,
        },
      }).then((res) => {
        if (res.data.success) {
          this.setState({
            data: this.formatData(res.data.data),
            search: res.data.search,
            isLoad: false,
          });
        } else {
          this.setState({
            data: null,
            search: null,
            isLoad: false,
          });
        }
      });
    });
  };

  formatData(data) {
    for (const folder in data) {
      if (folder === "byMonth") {
      } else {
        for (const row in data[folder]) {
          if (
            (row.includes("average") &&
              !row.includes("ours") &&
              !row.includes("Delay")) ||
            row === "VRADE" ||
            row.includes("Honorary")
          ) {
            data[folder][row][1] = getCurrencyValue(data[folder][row][1]);
            data[folder][row][0] = getCurrencyValue(data[folder][row][0]);
          }
          if (row.includes("ours")) {
            data[folder][row][1] = `${
              data[folder][row][1] ? data[folder][row][1].toFixed(2) : 0
            } heures`;
            data[folder][row][0] = `${
              data[folder][row][0] ? data[folder][row][0].toFixed(2) : 0
            } heures`;
          }
          if (
            row.includes("Age") ||
            row === "PRE" ||
            row === "selfcare" ||
            row === "targetPieces" ||
            (row.includes("delay") && !row.includes("delay8SD29")) ||
            (row.includes("tx") && !row.includes("txRectification"))
          ) {
            data[folder][row][1] = getPercentageValue(data[folder][row][1], 0);
            data[folder][row][0] = getPercentageValue(data[folder][row][0], 0);
          }
          if (
            row.includes("txRectification") ||
            row.includes("delay8SD29")
          ) {
            data[folder][row][1] = getPercentageValue(data[folder][row][1], 2);
            data[folder][row][0] = getPercentageValue(data[folder][row][0], 2);
          }
          if (row === "ageVehicle") {
            data[folder][row][1] = `${
              data[folder][row][1] ? data[folder][row][1].toFixed(0) : 0
            } ans`;
            data[folder][row][0] = `${
              data[folder][row][0] ? data[folder][row][0].toFixed(0) : 0
            } ans`;
          }
          if (!row.includes("ertified")) {
            data[folder][row][2] = getPercentageValue(data[folder][row][2], 0);
          }
          if (row.includes("ertified")) {
            data[folder][row][0] = getCurrencyValue(data[folder][row][0]);
            data[folder][row][1] = getPercentageValue(data[folder][row][1]);
            data[folder][row][2] = getCurrencyValue(data[folder][row][2]);
            data[folder][row][3] = getPercentageValue(data[folder][row][3]);
          }
          if (row.includes("IPPrecent")) {
            data[folder][row][1] = getPercentageValue(data[folder][row][1], 2);
            data[folder][row][0] = getPercentageValue(data[folder][row][0], 2);
          }
          if (row.includes("averageDelay") && !row.includes("averageDelaySD29")) {
            data[folder][row][1] = `${
              data[folder][row][1] ? data[folder][row][1].toFixed(0) : 0
            } jours`;
            data[folder][row][0] = `${
              data[folder][row][0] ? data[folder][row][0].toFixed(0) : 0
            } jours`;
          } else if(row.includes("averageDelaySD29")){
            data[folder][row][1] = `${
              data[folder][row][1] ? data[folder][row][1].toFixed(2) : 0
            } jours`;
            data[folder][row][0] = `${
              data[folder][row][0] ? data[folder][row][0].toFixed(2) : 0
            } jours`;
          }
        }
      }
    }
    return data;
  }

  getStatusColor(label, column) {
    const invertedStatus = [
      "Nombre de dossiers réparables",
      "Age moyen du véhicule",
      "% 6 - 9 ans",
      "% 9 ans et +",
      "% RE avec pièces de réemploi",
      "Remise moyenne HT",
      "Nombre de dossiers irréparables",
      "Nombre de missions prises en compte",
      "Nombre missions réparables",
      "Nombre missions irrréparables",
      "Nombre de suivi SD29 rentrés après 8 jours",
      "% RDD",
    ];
    let className = "";
    if (!invertedStatus.includes(label)) {
      parseInt(column) < 0
        ? (className = "bg-green")
        : parseInt(column) > 0
        ? (className = "bg-red")
        : (className = "bg-blue");
    } else {
      parseInt(column) > 0
        ? (className = "bg-green")
        : parseInt(column) < 0
        ? (className = "bg-red")
        : (className = "bg-blue");
    }
    return className;
  }

  render() {
    if (this.state.data) {
    }
    const dataR = [
      {
        headers: ["N-1", "N", "N/N-1 (%)"],
        data: this.state.data
          ? [
              ["Nombre de dossiers réparables"].concat(
                this.state.data.dataR.totalFiles
              ),
              ["Age moyen du véhicule"].concat(
                this.state.data.dataR.ageVehicle
              ),
              ["% 0 - 3 ans"].concat(this.state.data.dataR.countAge0),
              ["% 3 - 6 ans"].concat(this.state.data.dataR.countAge3),
              ["% 6 - 9 ans"].concat(this.state.data.dataR.countAge6),
              ["% 9 ans et +"].concat(this.state.data.dataR.countAge9),
            ]
          : [],
      },
      {
        headers: ["Par poste", "N-1", "N", "N/N-1 (%)"],
        data: this.state.data
          ? [
              ["CM MO HT"].concat(this.state.data.dataR.averageCostMO),
              ["CM IP HT"].concat(this.state.data.dataR.averageCostIP),
              ["CM Pièces HT"].concat(this.state.data.dataR.averagePieces),
              ["CM total HT"].concat(this.state.data.dataR.averageCost),
            ]
          : [],
      },
      {
        headers: [
          "Par famille",
          "Coût moyen N-1",
          "Taux N -1 (%)",
          "Coût moyen N",
          "Taux N (%)",
        ],
        data: this.state.data
          ? [
              ["Agréé"].concat(this.state.data.dataR.certified),
              ["Non Agréé"].concat(this.state.data.dataR.notCertified),
            ]
          : [],
      },
      {
        headers: ["N-1", "N", "N/N-1"],
        data: this.state.data
          ? [
              ["Heures MO"].concat(this.state.data.dataR.averageHoursMO),
              ["Nb Heures Carrosserie"].concat(
                this.state.data.dataR.averageHoursT
              ),
              ["Heures T1"].concat(this.state.data.dataR.averageHoursT1),
              ["Heures T2"].concat(this.state.data.dataR.averageHoursT2),
              ["Heures T3"].concat(this.state.data.dataR.averageHoursT3),
              ["Tarif Horaire MO"].concat(
                this.state.data.dataR.averageHourRate
              ),
              ["Tarif T1"].concat(this.state.data.dataR.averageHourRateT1),
              ["Tarif T2"].concat(this.state.data.dataR.averageHourRateT2),
              ["Tarif T3"].concat(this.state.data.dataR.averageHourRateT3),
              ["CM MO HT"].concat(this.state.data.dataR.averageCostMO),
              ["Heures IP"].concat(this.state.data.dataR.averageHoursIP),
              ["% IP1"].concat(this.state.data.dataR.IPPrecent1),
              ["% IP2"].concat(this.state.data.dataR.IPPrecent2),
              ["Tarif horaire IP"].concat(
                this.state.data.dataR.averageHourRateIP
              ),
              ["Tarif IP1"].concat(this.state.data.dataR.averageHourRateIP1),
              ["Tarif IP2"].concat(this.state.data.dataR.averageHourRateIP2),
              ["CM IP HT"].concat(this.state.data.dataR.averageCostIP),
              ["Heures MO + IP"].concat(this.state.data.dataR.hoursMOIP),
              ["CM Pieces HT"].concat(this.state.data.dataR.averagePieces),
              ["% RE avec pièces de réemploi"].concat(
                this.state.data.dataR.PRE
              ),
              ["Remise moyenne HT"].concat(
                this.state.data.dataR.averageDiscount
              ),
              ["% RDD"].concat(
                this.state.data.dataR.selfcare
              ),
              ["% pièces cibles réparées"].concat(this.state.data.dataR.targetPieces),
            ]
          : [],
      },
    ];

    const dataNR = [
      {
        headers: ["N-1", "N", "N/N-1 (%)"],
        data: this.state.data
          ? [
              ["Nombre de dossiers irréparables"].concat(
                this.state.data.dataNR.totalFiles
              ),
              ["Taux irréparables"].concat(this.state.data.dataNR.txNR),
              ["Age moyen du véhicule"].concat(
                this.state.data.dataNR.ageVehicle
              ),
              ["% 0 - 3 ans"].concat(this.state.data.dataNR.countAge0),
              ["% 3 - 6 ans"].concat(this.state.data.dataNR.countAge3),
              ["% 6 - 9 ans"].concat(this.state.data.dataNR.countAge6),
              ["% 9 ans et +"].concat(this.state.data.dataNR.countAge9),
              ["VRADE TTC"].concat(this.state.data.dataNR.VRADE),
              ["Ratio montant réparation / VRADE"].concat(
                this.state.data.dataNR.txRepairCostVRADE
              ),
            ]
          : [],
      },
    ];

    const dataHonorary = [
      {
        headers: ["N-1", "N", "N/N-1 (%)"],
        data: this.state.data
          ? [
              ["Nombre de missions prises en compte"].concat(
                this.state.data.dataTotal.nbFiles
              ),
              ["Hono + Frais moyens TTC"].concat(
                this.state.data.dataTotal.honoraryVGE
              ),
              ["Nombre missions hors VGE"].concat(
                this.state.data.dataTotal.nbNVGE
              ),
              ["Honoraires moyens TTC hors VGE"].concat(
                this.state.data.dataTotal.honoraryNVGE
              ),
              ["Nombre missions réparables"].concat(
                this.state.data.dataTotal.nbR
              ),
              ["Nombre missions irrréparables"].concat(
                this.state.data.dataTotal.nbNR
              ),
              ["Honoraires moyens TTC VGE"].concat(
                this.state.data.dataTotal.honoraryVGE
              ),
            ]
          : [],
      },
    ];

    const dataPerfGeneral = [
      {
        headers: ["N-1", "N", "N/N-1 (%)"],
        data: this.state.data
          ? [["CM Sinistre"].concat(this.state.data.dataTotal.averageHonorary)]
          : [],
      },
    ];

    const dataQuality = [
      {
        headers: ["N-1", "N", "N/N-1 (%)"],
        data: this.state.data
          ? [
              ["Taux de rectificatif"].concat(
                this.state.data.dataQuality.txRectification
              ),
              ["Délai rentrée RE réparable"].concat(
                this.state.data.dataQuality.averageDelayR
              ),
              ["Taux de RE réparable rentrés après 25 jours"].concat(
                this.state.data.dataQuality.delay25R
              ),
              ["Taux de RE réparable rentrés après 60 jours"].concat(
                this.state.data.dataQuality.delay60R
              ),
              ["Délai rentrée RE irréparable"].concat(
                this.state.data.dataQuality.averageDelayNR
              ),
              ["Taux de RE irréparable rentrés après 25 jours"].concat(
                this.state.data.dataQuality.delay25NR
              ),
              ["Taux de RE irréparable rentrés après 60 jours"].concat(
                this.state.data.dataQuality.delay60NR
              ),
              ["Délai rentrée suivi SD29"].concat(
                this.state.data.dataQuality.averageDelaySD29
              ),
              ["Nombre de suivi SD29 rentrés après 8 jours"].concat(
                this.state.data.dataQuality.nbDelay8SD29
              ),
              ["Taux de suivi SD29 rentrés après 8 jours"].concat(
                this.state.data.dataQuality.delay8SD29
              ),
            ]
          : [],
      },
    ];

    const dataChart = [
      {
        title: "CM Réparable",
        labels: this.state.data ? this.state.data.byMonth.labels : "",
        data: [
          {
            color: "#026fc2",
            data: this.state.data
              ? this.state.data.byMonth.data.averageCost
              : "",
          },
        ],
      },
      {
        title: "Nb Heures MO",
        labels: this.state.data ? this.state.data.byMonth.labels : "",
        data: this.state.data
          ? [
              {
                color: "#026fc2",
                data: this.state.data.byMonth.data.hoursMO,
              },
            ]
          : [],
      },
      {
        title: "Nb Heures IP",
        labels: this.state.data ? this.state.data.byMonth.labels : "",
        data: this.state.data
          ? [
              {
                color: "#026fc2",
                data: this.state.data.byMonth.data.hoursIP,
              },
            ]
          : [],
      },
      {
        title: "Montant Pièces",
        labels: this.state.data ? this.state.data.byMonth.labels : "",
        data: this.state.data
          ? [
              {
                color: "#026fc2",
                data: this.state.data.byMonth.data.averagePiecesCost,
              },
            ]
          : [],
      },
      {
        title: "VRADE",
        labels: this.state.data ? this.state.data.byMonth.labels : "",
        data: this.state.data
          ? [
              {
                color: "#026fc2",
                data: this.state.data.byMonth.data.averageVRADE,
              },
            ]
          : [],
      },
      {
        title: "Taux Irréparable",
        labels: this.state.data ? this.state.data.byMonth.labels : "",
        data: this.state.data
          ? [
              {
                color: "#026fc2",
                data: this.state.data.byMonth.data.txNR,
              },
            ]
          : [],
      },
    ];

    return (
      <div>
        <Loader show={this.state.isLoad} />
        {this.state.data ? (
          <>
            <Card
              title={"1. Les dossiers réparables"}
              className={"mt-10 mt-30-xl "}
              classNameChildren="overflow-x-auto"
            >
              <div
                style={{ fontSize: "10px", maxHeight: "400px" }}
                className="mb-5 ml-20 row"
              >
                <div className="col-lg-1">
                  <p>Genre du véhicule 4ROUES</p>
                  <p>Nature du dommage: Collision</p>
                </div>
                <div className="col-lg-1">
                  <p>REP - De 21 à 4000,99</p>
                </div>
              </div>

              {dataR.map((tab, idx) => {
                return (
                  <Table
                    classNameChildren="overflow-x-auto"
                    key={idx}
                    className={"mt-30"}
                  >
                    <Thead>
                      <Tr>
                        {tab.headers.length === 3 ? <Th hide></Th> : null}
                        {tab.headers.map((header, id) =>
                          id === 0 ? (
                            <Th radiusTopLeft key={id}>
                              {header}
                            </Th>
                          ) : (
                            <Th key={id}>{header}</Th>
                          )
                        )}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {tab.data.map((row, id) => {
                        return (
                          <Tr key={id}>
                            {row.map((column, ind) =>
                              ind === 0 ? (
                                <Th
                                  radiusTopLeft={
                                    id === 0 && tab.headers.length === 3
                                      ? true
                                      : false
                                  }
                                  key={ind}
                                >
                                  {column}
                                </Th>
                              ) : ind === 3 && row.length === 4 ? (
                                <Td
                                  key={ind}
                                  style={{ width: "275px", maxWidth: "500px" }}
                                >
                                  <Status
                                    className={this.getStatusColor(
                                      row[0],
                                      column
                                    )}
                                    text={column}
                                  />
                                </Td>
                              ) : row.length === 4 ? (
                                <Td
                                  style={{ width: "275px", maxWidth: "500px" }}
                                  key={ind}
                                >
                                  {column}
                                </Td>
                              ) : (
                                <Td
                                  style={{
                                    width: "206.25px",
                                    maxWidth: "500px",
                                  }}
                                  key={ind}
                                >
                                  {column}
                                </Td>
                              )
                            )}
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                );
              })}
            </Card>

            <Card
              title={"2. Les dossiers irréparables"}
              className={"mt-10 mt-30-xl "}
            >
              <div
                style={{ fontSize: "10px", maxHeight: "400px" }}
                className="mb-5 ml-20 row"
              >
                <div className="col-lg-1">
                  <p>Genre du véhicule 4ROUES</p>
                  <p>Nature du dommage: Collision</p>
                </div>
                <div className="col-lg-1">
                  <p>RIV et RSV - De 0 à 10000,99</p>
                </div>
              </div>
              {dataNR.map((tab, idx) => {
                return (
                  <Table
                    classNameChildren="overflow-x-auto"
                    className={"mt-30"}
                    key={idx}
                  >
                    <Thead>
                      <Tr>
                        {tab.headers.length === 3 ? <Th hide></Th> : null}
                        {tab.headers.map((header, id) =>
                          id === 0 ? (
                            <Th radiusTopLeft key={id}>
                              {header}
                            </Th>
                          ) : (
                            <Th key={id}>{header}</Th>
                          )
                        )}
                      </Tr>
                    </Thead>
                    <Tbody key={idx}>
                      {tab.data.map((row, id) => {
                        return (
                          <Tr key={id}>
                            {row.map((column, ind) =>
                              ind === 0 ? (
                                <Th
                                  radiusTopLeft={
                                    id === 0 && tab.headers.length === 3
                                      ? true
                                      : false
                                  }
                                  key={ind}
                                >
                                  {column}
                                </Th>
                              ) : ind === 3 ? (
                                <Td
                                  key={ind}
                                  style={{ width: "275px", maxWidth: "500px" }}
                                >
                                  <Status
                                    className={this.getStatusColor(
                                      row[0],
                                      column
                                    )}
                                    text={column}
                                  />
                                </Td>
                              ) : (
                                <Td
                                  key={ind}
                                  style={{ width: "275px", maxWidth: "500px" }}
                                >
                                  {column}
                                </Td>
                              )
                            )}
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                );
              })}
            </Card>

            <Card title={"3. Les honoraires"} className={"mt-10 mt-30-xl "}>
              <div
                style={{ fontSize: "10px", maxHeight: "400px" }}
                className="mb-5 ml-20 row"
              >
                <div className="col-lg-1">
                  <p>Genre du véhicule 4ROUES</p>
                  <p>Nature du dommage: Collision</p>
                </div>
                <div className="col-lg-1">
                  <p>REP - De 21 à 4000,99 </p>
                  <p>RIV et RSV - De 0 à 10000,99</p>
                </div>
              </div>
              {dataHonorary.map((tab, idx) => {
                return (
                  <Table
                    classNameChildren="overflow-x-auto"
                    className={"mt-30"}
                    key={idx}
                  >
                    <Thead>
                      <Tr>
                        {tab.headers.length === 3 ? <Th hide></Th> : null}
                        {tab.headers.map((header, id) =>
                          id === 0 ? (
                            <Th radiusTopLeft key={id}>
                              {header}
                            </Th>
                          ) : (
                            <Th key={id}>{header}</Th>
                          )
                        )}
                      </Tr>
                    </Thead>
                    <Tbody key={idx}>
                      {tab.data.map((row, id) => {
                        return (
                          <Tr key={id}>
                            {row.map((column, ind) =>
                              ind === 0 ? (
                                <Th
                                  radiusTopLeft={
                                    id === 0 && tab.headers.length === 3
                                      ? true
                                      : false
                                  }
                                  key={ind}
                                >
                                  {column}
                                </Th>
                              ) : ind === 3 ? (
                                <Td
                                  key={ind}
                                  style={{ width: "275px", maxWidth: "500px" }}
                                >
                                  <Status
                                    className={this.getStatusColor(
                                      row[0],
                                      column
                                    )}
                                    text={column}
                                  />
                                </Td>
                              ) : (
                                <Td
                                  key={ind}
                                  style={{ width: "275px", maxWidth: "500px" }}
                                >
                                  {column}
                                </Td>
                              )
                            )}
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                );
              })}
            </Card>

            <Card
              title={"4. Performance globale"}
              className={"mt-10 mt-30-xl "}
            >
              <div
                style={{ fontSize: "10px", maxHeight: "400px" }}
                className="mb-5 ml-20 row"
              >
                <div className="col-lg-1">
                  <p>Genre du véhicule 4ROUES</p>
                  <p>Nature du dommage: Collision</p>
                </div>
                <div className="col-lg-1">
                  <p>REP - De 21 à 4000,99</p>
                  <p>RIV et RSV - De 0 à 10000,99</p>
                </div>
              </div>
              {dataPerfGeneral.map((tab, idx) => {
                return (
                  <Table
                    classNameChildren="overflow-x-auto"
                    className={"mt-30"}
                    key={idx}
                  >
                    <Thead>
                      <Tr>
                        {tab.headers.length === 3 ? <Th hide></Th> : null}
                        {tab.headers.map((header, id) =>
                          id === 0 ? (
                            <Th radiusTopLeft key={id}>
                              {header}
                            </Th>
                          ) : (
                            <Th key={id}>{header}</Th>
                          )
                        )}
                      </Tr>
                    </Thead>
                    <Tbody key={idx}>
                      {tab.data.map((row, id) => {
                        return (
                          <Tr key={id}>
                            {row.map((column, ind) =>
                              ind === 0 ? (
                                <Th
                                  radiusTopLeft={
                                    id === 0 && tab.headers.length === 3
                                      ? true
                                      : false
                                  }
                                  key={ind}
                                >
                                  {column}
                                </Th>
                              ) : ind === 3 ? (
                                <Td
                                  key={ind}
                                  style={{ width: "275px", maxWidth: "500px" }}
                                >
                                  <Status
                                    className={this.getStatusColor(
                                      row[0],
                                      column
                                    )}
                                    text={column}
                                  />
                                </Td>
                              ) : (
                                <Td
                                  key={ind}
                                  style={{ width: "275px", maxWidth: "500px" }}
                                >
                                  {column}
                                </Td>
                              )
                            )}
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                );
              })}
            </Card>

            <Card title={"5. Suivi qualité"} className={"mt-10 mt-30-xl "}>
              <div
                style={{ fontSize: "10px", maxHeight: "400px" }}
                className="mb-5 ml-20 row"
              >
                <div className="col-lg-1">
                  <p>Toutes natures, tous genres, toutes tranches</p>
                </div>
              </div>
              {dataQuality.map((tab, idx) => {
                return (
                  <Table
                    classNameChildren="overflow-x-auto"
                    className={"mt-30"}
                    key={idx}
                  >
                    <Thead>
                      <Tr>
                        {tab.headers.length === 3 ? <Th hide></Th> : null}
                        {tab.headers.map((header, id) =>
                          id === 0 ? (
                            <Th radiusTopLeft key={id}>
                              {header}
                            </Th>
                          ) : (
                            <Th key={id}>{header}</Th>
                          )
                        )}
                      </Tr>
                    </Thead>
                    <Tbody key={idx}>
                      {tab.data.map((row, id) => {
                        return (
                          <Tr key={id}>
                            {row.map((column, ind) =>
                              ind === 0 ? (
                                <Th
                                  radiusTopLeft={
                                    id === 0 && tab.headers.length === 3
                                      ? true
                                      : false
                                  }
                                  key={ind}
                                >
                                  {column}
                                </Th>
                              ) : ind === 3 ? (
                                <Td
                                  key={ind}
                                  style={{ width: "275px", maxWidth: "500px" }}
                                >
                                  <Status
                                    className={this.getStatusColor(
                                      row[0],
                                      column
                                    )}
                                    text={column}
                                  />
                                </Td>
                              ) : (
                                <Td
                                  key={ind}
                                  style={{ width: "275px", maxWidth: "500px" }}
                                >
                                  {column}
                                </Td>
                              )
                            )}
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                );
              })}
            </Card>

            <Card title={"6. Graphiques"} className={"mt-10 mt-30-xl "}>
              <div
                style={{ fontSize: "10px", maxHeight: "400px" }}
                className="mb-5 ml-20 row"
              >
                <div className="col-lg-1">
                  <p>Genre du véhicule 4ROUES</p>
                  <p>Nature du dommage: Collision</p>
                </div>
                <div className="col-lg-1">
                  <p>REP - De 21 à 4000,99</p>
                  <p>RIV et RSV - De 0 à 10000,99</p>
                </div>
              </div>
              <Row>
                {dataChart.map((chart, idx) => {
                  return (
                    <Col xs={12} md={6} key={idx} className={"mt-30 "}>
                      <BarChart
                        yAxes={[{ ticks: { beginAtZero: true } }]}
                        title={chart.title}
                        labels={chart.labels}
                        dataSet={chart.data}
                        widthBar={35}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Card>
          </>
        ) : null}
      </div>
    );
  }
}
