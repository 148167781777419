import React, { PureComponent } from "react";
import { ButtonIcon, Loader } from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faCheck,
  faDownload,
  faPen,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";

import AuthContext from "../../../context/AuthContext";

import axiosConfig from "../../../utils/axiosConfig";

class ValidateButtons extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
    };
  }
  static contextType = AuthContext;

  download(file) {
    const a = document.createElement("a");
    a.href = file;
    a.download = "Absence document";
    a.click();
  }

  handleDelete(planningId) {
    this.setState({ isLoad: true }, () => {
      axiosConfig("planning/delete", {
        data: {
          planningId,
        },
      }).then((res) => {
        this.setState({ isLoad: false }, () => {
          if (res) {
            this.props.updatePlannings();
          }
        });
      });
    });
  }

  updatePlanning(planning) {
    this.setState({ isLoad: true }, () => {
      axiosConfig("planning/update", {
        data: {
          planning,
        },
      }).then((res) => {
        this.setState({ isLoad: false }, () => {
          if (res) {
            this.props.updatePlannings();
            this.props.setShowInput({
              showInput: false,
              currentPlanningId: planning.id,
            });
          }
        });
      });
    });
  }

  render() {
    const { planning, showInput } = this.props;

    return (
      <div className="nowrap text-center">
        <Loader show={this.state.isLoad} />
        {showInput ? (
          <>
            <ButtonIcon
              className="green pt-0 pb-0"
              small
              onClick={() => {
                this.updatePlanning({
                  ...planning,
                  isUpdate: true,
                });
              }}
            >
              <FontAwesomeIcon icon={faCheck} />
            </ButtonIcon>
            <ButtonIcon
              className={"red pt-0 pb-0"}
              small
              onClick={() => {
                this.props.setShowInput({
                  showInput: false,
                });
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </ButtonIcon>
          </>
        ) : (
          <>
            {[3].includes(this.context.role) && planning.status < 3 ? (
              <ButtonIcon
                className={"blue pt-0 pb-0"}
                small
                onClick={() =>
                  this.props.setShowInput({
                    showInput: true,
                    currentPlanningId: planning.id,
                  })
                }
              >
                <FontAwesomeIcon icon={faPen} />
              </ButtonIcon>
            ) : null}
            {([1, 2].includes(this.context.role) &&
              planning.status === 0 &&
              [
                this.context.agency,
                ...(this.context.manageAgency || "")
                  .split(",")
                  .map((element) => parseInt(element)),
              ].includes(planning.agencyId)) ||
            (([3].includes(this.context.role) ||
              ([4].includes(this.context.role) &&
                this.context.id === this.props.planning.userId)) &&
              planning.status === 1) ? (
              <>
                <ButtonIcon
                  className="green pt-0 pb-0"
                  small
                  onClick={() => {
                    this.updatePlanning({ ...planning, valid: true });
                  }}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </ButtonIcon>
                <ButtonIcon
                  className={"red pt-0 pb-0"}
                  small
                  onClick={() => {
                    if (window.confirm("Voulez-vous rejeter cette absence ?")) {
                      this.updatePlanning({
                        ...planning,
                        status: 3,
                        isRejecte: true,
                      });
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </ButtonIcon>
              </>
            ) : null}
            {[3].includes(this.context.role) && planning.status === 2 ? (
              <ButtonIcon
                className={"red pt-0 pb-0"}
                small
                onClick={() => {
                  if (window.confirm("Voulez-vous supprimer cette absence ?")) {
                    this.handleDelete(this.props.planning.id);
                  }
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </ButtonIcon>
            ) : null}
          </>
        )}
        {planning.file && !showInput && (
          <ButtonIcon
            small
            className={"blue pt-0 pb-0"}
            onClick={() => this.download(planning.file)}
          >
            <FontAwesomeIcon icon={faDownload} />
          </ButtonIcon>
        )}
        <ButtonIcon
          small
          className={"blue pt-0 pb-0"}
          onClick={() => this.props.history.push(`/planning/${planning.id}`)}
        >
          <FontAwesomeIcon icon={faEye} />
        </ButtonIcon>
      </div>
    );
  }
}

export default withRouter(ValidateButtons);
