import React from "react";

import { ButtonIcon, Card } from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AuthContext from "../../context/AuthContext";

export default class CardUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static contextType = AuthContext;

  render() {
    return (
      <Card className={this.props.className} title={this.props.update.title}>
        <Row>
          <Col xs={9} className={"ml-30"}>
            <p style={{ whiteSpace: "pre-line" }}>
              {this.props.update.content}
            </p>
          </Col>
          {this.props.isAdmin ? (
            <>
              <Col xlOffset={1} xs={1}>
                <ButtonIcon
                  small
                  onClick={() => this.props.onEdit(this.props.update)}
                >
                  <FontAwesomeIcon className={"blue"} icon={faPen} />
                </ButtonIcon>
              </Col>
              <Col xs={1}>
                <ButtonIcon
                  small
                  onClick={() => this.props.onDelete(this.props.update.id)}
                >
                  <FontAwesomeIcon className={"red"} icon={faTrash} />
                </ButtonIcon>
              </Col>
            </>
          ) : null}
        </Row>
        <Row>
          {this.props.isAdmin && !this.context.network ? (
            <>
              <p style={{ fontSize: "10px" }} className={"m-30"}>{`${
                this.props.update.networkNames ? "Réseaux concernés: " : ""
              } ${this.props.update.networkNames}`}</p>
              <p style={{ fontSize: "10px" }} className={"m-30"}>{` ${
                this.props.update.groupNames ? " Groupes concernés: " : ""
              } ${this.props.update.groupNames.join(" | ")}`}</p>
            </>
          ) : null}
          <p style={{ fontSize: "10px" }} className={"mt-30"}>
            {this.props.update.date}
          </p>
        </Row>
      </Card>
    );
  }
}
