import React, { Component } from 'react'
import { Card, Table, Tbody, Td, Th, Thead, Tr } from 'ui-kit-ck-consultant'

export class ActionPlanDisplayCloture extends Component {

  convertDate = (inputFormat) => {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat)
    return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
  }

  render() {
    return (
      <Card className={'mt-30'} title={"Plan d'action"}>
        <Table>
            <Thead>
              <Tr>
                <Th>Indicateur</Th>
                <Th>Sous-Indicateur</Th>
                <Th>Date de début</Th>
                <Th>Date de fin</Th>
                <Th>Validé</Th>
                <Th radiusTopRight>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {
                this.props.actionData.oldSims || this.props.actionData.selectedSim
                ? (
                  <>
                    {this.props.actionData && this.props.actionData.oldSims && this.props.actionData.oldSims.length > 0 && this.props.actionData.oldSims.map((element, idx) => {
                      return (<Tr key={idx}>
                        <Td>{element.indicator}</Td>
                        <Td>{element.indicator2}</Td>
                        <Td>{this.convertDate(element.dateStart)}</Td>
                        <Td>{this.convertDate(element.dateEnd)}</Td>
                        <Td>{element.checked ? 'Oui' : 'Non'}</Td>
                        <Td className={'w-100'}>{element.value}</Td>
                      </Tr>)
                    })}
                    {this.props.actionData && this.props.actionData.selectedSim && this.props.actionData.selectedSim.length > 0 && this.props.actionData.selectedSim.map((element, idx) => {
                      return (<Tr key={idx}>
                        <Td>{element.indicator}</Td>
                        <Td>{element.indicator2}</Td>
                        <Td>{this.convertDate(element.dateStart)}</Td>
                        <Td>{this.convertDate(element.dateEnd)}</Td>
                        <Td>{element.checked ? 'Oui' : 'Non'}</Td>
                        <Td className={'w-100'}>{element.value}</Td>
                      </Tr>)
                    })}
                  </>
                )
                : (
                  <>
                    {this.props.actionData.map((element, idx) => {
                      return (<Tr key={idx}>
                        <Td>{element.indicator}</Td>
                        <Td>{element.indicator2}</Td>
                        <Td>{this.convertDate(element.dateStart)}</Td>
                        <Td>{this.convertDate(element.dateEnd)}</Td>
                        <Td>{element.checked ? 'Oui' : 'Non'}</Td>
                        <Td className={'w-100'}>{element.value}</Td>
                      </Tr>)
                    })}
                  </>
                )
              }
            </Tbody>
          </Table>
      </Card>
    )
  }
}

export default ActionPlanDisplayCloture
