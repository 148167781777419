import React, { Component } from "react";
import {
  Nav,
  Loader,
  Modal,
  Button,
  FloatActionButton,
  FloatActionButtonItem,
} from "ui-kit-ck-consultant";
import { faBars, faSave, faFlag } from "@fortawesome/free-solid-svg-icons";
import { Prompt } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FadeIn from "react-fade-in";
import { Buffer } from "buffer";

import "../../../style/performance.css";

import Summary from "../../../components/general/Summary";
import CardFiles from "../../../components/general/CardFiles";
import CardActionPlan from "../../../components/general/CardActionPlan";
import NavPSC from "../../../components/general/NavPSC";
import ShowOptionsNGRA from "../../../components/general/ShowOptionsNGRA-v2";
import PerformanceDisplay from "../../../components/performances/PerformanceDisplay";
import SignatureCard from "../../../components/interviews/SignatureCard";
import Evolution from "../../../components/sim/Evolution";
import AuditDisplay from "../../../components/performances/AuditDisplay";
import PrintClass from "../../../components/general/Print";
import SimCloturees from "../../../components/sim/SimCloturees";

import buildingLogo from "../../../images/building.png";

import axiosConfig from "../../../utils/axiosConfig";
import {
  getNameTypePerformance,
  getUserTypePerformance,
} from "../../../utils/general";
import { alertError } from "../../../utils/errorMessage";

import AuthContext from "../../../context/AuthContext";
export default class Sim extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionPlanIndicator: ["Coût", "Délai", "Production", "Qualité"],
      selectedSim: null,
      oldSims: null,
      isLoad: false,
      manager: null,
      print: false,
      loader: false,
      show: false,
      error: null,
      newSignature: false,
      name: null,
      disableEdition: false,
      dataStart: "",
    };
    this.initialState = this.state;
    this.signatureRef = React.createRef();

    this.refPrintSimCloturees = React.createRef();
    this.refPrintSummary = React.createRef();
    this.refPrintPerformance = React.createRef();
    this.refPrintAudit = React.createRef();
    this.refPrintEvolution = React.createRef();
    this.refPrintActionPlan = React.createRef();
  }
  static contextType = AuthContext;

  componentDidMount() {
    if (
      this.context.role === 2 &&
      (this.props.type === 1 || this.props.type === 2)
    ) {
      this.setState({ disableEdition: true });
    }
  }

  handleDataChange = (data) => {
    this.setState(
      {
        network: data.network,
        group: data.group,
        region: data.region,
        agency: data.agency,
        user: data.user,
        year: data.year,
        sim: data.sim,
      },
      () => {
        /// GET NEW DATA AFTER CHANGES
        this.getSimData();
        this.getAgencyOrUserName();
      }
    );
  };

  getAgencyOrUserName = () => {
    const { network, group, region, agency, user, year } = this.state;
    axiosConfig({
      url: "/performances/1/get-name",
      data: { network, group, region, agency, user, year },
    }).then((res) => {
      if (res.data.success === true) {
        this.setState({
          name: res.data.data.name,
        });
      }
    });
  };

  convertDate = (inputFormat) => {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
  };

  getSimData() {
    let evolutions = [[], [], []];

    function getEvolution(evol, id, date) {
      if (Array.isArray(evol)) evolutions[id] = evol;
      else if (typeof evol === "string" && evol !== "")
        evolutions[id].push({ date, commentaire: evol });
    }

    axiosConfig({
      url: "/sim/get-sim-data",
      data: {
        network: this.state.network,
        agency: this.state.agency,
        simId: this.state.sim,
      },
    })
      .then((res) => {
        if (res.data.success) {
          // SelectedSim
          let signatures = res.data.selectedSim.dataText.signature;
          signatures.map((element) => {
            if (element.signature && element.signature.data) {
              element.signature =
                "data:image/png;base64," +
                new Buffer.from(element.signature).toString("base64");
            }
            return element;
          });
          if (res.data.selectedSim.status === 1) {
            getEvolution(
              res.data.selectedSim.dataText.evolution_fonctionnement_evolution,
              0,
              this.convertDate(res.data.selectedSim.dateEnd)
            );
            getEvolution(
              res.data.selectedSim.dataText
                .evolution_fonctionnement_information,
              1,
              this.convertDate(res.data.selectedSim.dateEnd)
            );
            getEvolution(
              res.data.selectedSim.dataText.evolution_fonctionnement_process,
              2,
              this.convertDate(res.data.selectedSim.dateEnd)
            );
          }
          let actionPlan = res.data.selectedSim.dataText.plans_action;
          const selectedSim = {
            simData: res.data.selectedSim,
            actionPlan,
          };
          // oldSims
          let oldSims = [];
          let actionPlanOldSims = [];
          if (res.data.oldSim && res.data.oldSim.length > 0) {
            res.data.oldSim &&
              res.data.oldSim.length > 0 &&
              res.data.oldSim.map((element) => {
                element.dataText.plans_action &&
                  element.dataText.plans_action.length > 0 &&
                  element.dataText.plans_action.map((action) =>
                    actionPlanOldSims.push({
                      ...action,
                      simId: element.id,
                      simName: this.convertDate(element.dateEnd),
                    })
                  );

                getEvolution(
                  element.dataText.evolution_fonctionnement_evolution,
                  0,
                  this.convertDate(element.dateEnd)
                );
                getEvolution(
                  element.dataText.evolution_fonctionnement_information,
                  1,
                  this.convertDate(element.dateEnd)
                );
                getEvolution(
                  element.dataText.evolution_fonctionnement_process,
                  2,
                  this.convertDate(element.dateEnd)
                );

                const sim = {
                  simData: element,
                };
                return oldSims.push(sim);
              });
          }
          evolutions.map((element) => {
            element.sort((a, b) =>
              a.date > b.date ? 1 : b.date > a.date ? -1 : 0
            );
            return true;
          });
          this.setState(
            {
              selectedSim,
              oldSims,
              evolutions,
              actionPlanOldSims,
              signatures,
            },
            () => {
              axiosConfig({
                url: "/sim/get-signatures",
                data: {
                  network: this.state.network,
                  agency: this.state.agency,
                  expertType: getUserTypePerformance(this.props.type),
                },
              }).then((signatures) => {
                if (signatures.data.success) {
                  let totalSignatures = this.state.signatures
                    ? this.state.signatures
                    : [];
                  signatures.data.data.map((item) =>
                    totalSignatures.push(item)
                  );
                  let signaturesFinal = [];
                  let namesAlreadyIn = [];
                  totalSignatures &&
                    totalSignatures.map((item) => {
                      let signatureItem = {};
                      signatureItem.name = item.name;
                      signatureItem.signature = item.signature
                        ? item.signature
                        : item.image
                        ? item.image
                        : null;
                      if (!namesAlreadyIn.includes(signatureItem.name)) {
                        signaturesFinal.push(signatureItem);
                        namesAlreadyIn.push(signatureItem.name);
                      } else if (
                        namesAlreadyIn.includes(signatureItem.name) &&
                        signatureItem.signature
                      ) {
                        let foundIndex = signaturesFinal.findIndex(
                          (x) => x.name === signatureItem.name
                        );
                        signaturesFinal[foundIndex] = signatureItem;
                      }
                      return item;
                    });
                  this.setState(
                    {
                      signatures: signaturesFinal,
                      isLoad: false,
                    },
                    () =>
                      this.setState({
                        dataStart: JSON.stringify(this.getData()),
                      })
                  );
                } else {
                  this.setState({
                    show: true,
                    error: signatures.data.err,
                    isLoad: false,
                  });
                }
              });
            }
          );
        } else {
          this.setState({
            error: res.data.err,
            selectedSim: null,
            oldSims: null,
            actionPlanOldSim: null,
            evolutions: null,
            signatures: null,
            isLoad: false,
          });
        }
      })
      .catch((err) => {
        this.setState({ show: true, error: err, isLoad: false });
        alertError(err);
      });
  }

  handleEditOldActionPlan = (simId, actionPlan, actionPlanArr) => {
    this.setState({ actionPlanOldSims: actionPlanArr }, () => {
      let dataText = this.state.oldSims.filter(
        (element) => element.simData.id === simId
      )[0].simData.dataText;
      dataText.plans_action.forEach((plan, idx) => {
        if (plan.id === actionPlan.id) {
          dataText.plans_action[idx] = actionPlan;
        }
      });
      axiosConfig({
        url: "/sim/update-old-sim-data",
        data: {
          oldSimDataText: JSON.stringify(dataText),
          oldSimId: simId,
        },
      }).then((res) => {
        if (res.data.success) {
          alert("Validation du plan d'action modifiée avec succès");
        } else {
          alert("Erreur lors de la validation du plan d'action");
        }
      });
    });
  };

  alertSave() {
    if (this.state.dataStart !== JSON.stringify(this.getData())) {
      return (
        <Prompt message="Les modifications que vous avez apportées ne seront peut-être pas enregistrées" />
      );
    }
  }

  handleModalSignatureClick(e) {
    if (this.state.modalSignature) {
      this.setState({ modalSignature: false });
    } else {
      this.setState({ modalSignature: true, manager: e });
    }
  }

  handleClearSignatureClick = () => {
    this.signatureRef.current.clear();
  };

  handleValidSignatureClick = () => {
    let idx = this.state.manager;
    let totalSignatures = this.state.signatures;
    if (idx >= this.state.signatures.length) {
      let item = {};
      item.name = null;
      item.signature = this.signatureRef.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      totalSignatures.push(item);
    } else {
      let signatures = this.state.signatures[idx];
      signatures.signature = this.signatureRef.current
        .getTrimmedCanvas()
        .toDataURL("image/png");
      totalSignatures[idx] = signatures;
    }
    this.setState({
      signatures: totalSignatures,
      modalSignature: false,
      newSignature: true,
    });
  };

  handleRemoveSignature = (idx) => {
    let signatures = this.state.signatures;
    signatures.splice(idx, 1);
    this.setState({ signatures });
  };

  handleSaveSimData = () => {
    let actions = this.state.selectedSim.actionPlan.map((plan) => {
      for (var key in plan) {
        if (key !== "checked" && key !== "id" && !plan[key]) return false;
      }
      return true;
    });

    if (actions.includes(false)) {
      alert("Un ou plusieurs champs du plan d'action n'ont pas été rempli.");
    } else {
      axiosConfig({
        url: "/sim/save-sim-data",
        data: this.getData(),
      }).then((res) => {
        if (res.data.success === true) {
          alert("SIM1 enregistrée");
        } else {
          alert("Une erreur c'est produite lors de l'enregistrement...");
        }
      });
    }
  };

  handleNewSignature(name) {
    let totalSignatures = this.state.signatures ? this.state.signatures : [];
    let item = {};
    item.name = name;
    item.signature = null;
    totalSignatures.push(item);
    this.setState({
      signatures: totalSignatures,
    });
  }

  handleRemoveSignatureUser(id) {
    let totalSignatures = this.state.signatures;
    totalSignatures.splice(id, 1);
    this.setState({
      signatures: totalSignatures,
    });
  }

  handleChangeEvolution(keyVal) {
    let selectedSim = this.state.selectedSim;
    selectedSim.simData.dataText[keyVal.name] = keyVal.value;
    this.setState({ selectedSim });
  }

  handleRemoveEvolution(id, evolutionType) {
    let evolutions = this.state.evolutions;
    evolutions[evolutionType].splice(id, 1);
    this.setState({ evolutions });
  }

  handleClotureSim = () => {
    let actions = this.state.selectedSim.actionPlan.map((plan) => {
      for (var key in plan) {
        if (key !== "checked" && key !== "id" && !plan[key]) return false;
      }
      return true;
    });

    if (actions.includes(false)) {
      alert("Un ou plusieurs champs du plan d'action n'ont pas été rempli.");
    } else {
      axiosConfig({
        url: "/sim/save-sim-data",
        data: this.getData(),
      }).then((res) => {
        if (res.data.success === true) {
          axiosConfig({
            url: "/sim/cloture-sim",
            data: {
              id: this.state.selectedSim.simData.id,
            },
          }).then((res) => {
            if (res.data.success === true) {
              let selectedSim = this.state.selectedSim;
              selectedSim.simData.status = 1;
              this.setState({ selectedSim });
              alert("SIM1 Cloturée");
            } else {
              alert("Une erreur c'est produite lors de l'enregistrement...");
            }
          });
        } else {
          alert("Une erreur c'est produite lors de l'enregistrement...");
        }
      });
    }
  };

  getData() {
    return {
      signatures: this.state.signatures,
      actionPlan: this.state.selectedSim.actionPlan,
      id: this.state.selectedSim.simData.id,
      dataText: this.state.selectedSim.simData.dataText,
    };
  }

  render() {
    console.log(this.state);

    return (
      <div id={"print-meeting-field-expert"}>
        <Loader show={this.state.loader} />
        {this.state.data === undefined ? (
          <FadeIn>
            <Nav title={`SIM ${getNameTypePerformance(this.props.type)}`} />
          </FadeIn>
        ) : (
          <FadeIn>
            <NavPSC
              title={`SIM ${getNameTypePerformance(this.props.type)}`}
              activate={
                this.state.data && this.state.data.status === 0 ? true : false
              }
              handelClickPrint={(value) => this.handelClickPrint(value)}
              handelClickSave={(value) => this.handelClickSave(value)}
              handelClickClose={(value) => this.handelClickClose(value)}
            />
          </FadeIn>
        )}
        <div className={this.state.print === true ? " for-print" : ""}>
          <div className="print-option">
            <FadeIn>
              <ShowOptionsNGRA
                isChange={true}
                className="mb-10 mb-20-xl"
                onDataChange={this.handleDataChange}
                isYear={true}
                isSim={true}
                visibilityRole={this.props.visibilityRole}
                visibilityType={this.props.visibilityType}
                preloadRole={this.props.preloadRole}
                preloadType={this.props.preloadType}
                limitUserType={this.props.limitUserType}
                limitUserRole={this.props.limitUserRole}
                userType={getUserTypePerformance(this.props.type)}
                type={this.props.type}
                isAll={true}
                notAllAgencies={true}
              />
            </FadeIn>
          </div>

          {this.state.selectedSim ? (
            <div>
              <FadeIn>
                <div ref={this.refPrintSummary}>
                  <div className="print-summary">
                    <Summary
                      className={"mt-10 mt-30-xl "}
                      imgProfil={buildingLogo}
                      nameProfil={this.state.name ? this.state.name : null}
                      isLoader={this.state.isPrint ? null : this.state.isLoad}
                      performanceRecap={
                        this.state.selectedSim &&
                        this.state.selectedSim.simData.data.performance
                          ? this.state.selectedSim.simData.data.performance
                              .recapitulatif
                          : null
                      }
                      label={
                        this.state.selectedSim &&
                        this.state.selectedSim.simData.data.performance &&
                        this.state.selectedSim.simData.data.performance
                          .performance_qualite.resultat_audit[2]
                      }
                      labelRSE={
                        this.state.selectedSim &&
                        this.state.selectedSim.simData.data.performance
                          ? this.state.selectedSim.simData.data.performance
                              .performance_RSE
                          : null
                      }
                      type={this.props.type}
                    />
                  </div>
                </div>
                <div ref={this.refPrintPerformance}>
                  <PerformanceDisplay
                    perf={
                      this.state.selectedSim &&
                      this.state.selectedSim.simData.data.performance
                        ? this.state.selectedSim.simData.data.performance
                        : null
                    }
                    isLoader={this.state.isPrint ? null : this.state.isLoad}
                    type={this.props.type}
                    isPrecisia={
                      this.state.selectedSim &&
                      this.state.selectedSim.simData &&
                      this.state.selectedSim.simData.data
                        ? this.state.selectedSim.simData.data.isPrecisia
                        : false
                    }
                  />
                </div>
                <div ref={this.refPrintAudit}>
                  {this.props.type !== 3 ? (
                    <AuditDisplay
                      auditData={
                        this.state.selectedSim.simData &&
                        this.state.selectedSim.simData.data.audit_data
                      }
                      audit={
                        this.state.selectedSim.simData &&
                        this.state.selectedSim.simData.data.audit
                      }
                      auditApData={
                        this.state.selectedSim.simData &&
                        this.state.selectedSim.simData.data_audit_AP
                      }
                      isPrint={this.state.isPrint}
                    />
                  ) : null}
                </div>
              </FadeIn>

              {this.state.selectedSim &&
              this.state.selectedSim.simData.status === 1 ? (
                <FadeIn>
                  <div ref={this.refPrintSimCloturees}>
                    <SimCloturees
                      evolutions={
                        this.state.evolutions && this.state.evolutions.length
                          ? this.state.evolutions
                          : []
                      }
                      actionPlan={{
                        selectedSim: this.state.selectedSim.actionPlan,
                        oldSims: this.state.actionPlanOldSims,
                      }}
                      signatures={
                        this.state.signatures && this.state.signatures
                      }
                    />
                  </div>
                </FadeIn>
              ) : (
                <>
                  {this.state.evolutions &&
                    this.state.evolutions.map((element, idx) => {
                      return (
                        <div key={idx} ref={this.refPrintEvolution}>
                          <Evolution
                            oldEvolutionData={element}
                            currentEvolutionData={
                              this.state.selectedSim && idx === 0
                                ? this.state.selectedSim.simData.dataText
                                    .evolution_fonctionnement_evolution
                                : idx === 1
                                ? this.state.selectedSim.simData.dataText
                                    .evolution_fonctionnement_information
                                : this.state.selectedSim.simData.dataText
                                    .evolution_fonctionnement_process
                            }
                            evolutionType={idx}
                            disableEdition={this.state.disableEdition}
                            handleChangeEvolution={(keyVal) =>
                              this.handleChangeEvolution(keyVal)
                            }
                            handleRemoveEvolution={(id, evolutionType) =>
                              this.handleRemoveEvolution(id, evolutionType)
                            }
                          />
                        </div>
                      );
                    })}

                  {this.state.selectedSim &&
                  this.state.selectedSim.actionPlan &&
                  this.state.signatures ? (
                    <>
                      <FadeIn>
                        <div ref={this.refPrintActionPlan}>
                          <CardFiles
                            id={
                              this.state.selectedSim &&
                              this.state.selectedSim.simData.id
                            }
                            disableEdition={this.state.disableEdition}
                          />
                          <CardActionPlan
                            indicator={this.state.actionPlanIndicator}
                            old={this.state.actionPlanOldSims}
                            current={this.state.selectedSim.actionPlan}
                            disableEdition={this.state.disableEdition}
                            handleEditCurrentActionPlan={(actionPlanArr) =>
                              this.setState({
                                selectedSim: {
                                  ...this.state.selectedSim,
                                  actionPlan: actionPlanArr,
                                },
                              })
                            }
                            handleEditOldActionPlan={
                              this.handleEditOldActionPlan
                            }
                          />
                          <SignatureCard
                            disableEdition={this.state.disableEdition}
                            signatures={this.state.signatures}
                            onAdd={(e) => this.handleModalSignatureClick(e)}
                            onRemove={(idx) => this.handleRemoveSignature(idx)}
                            newSignature={this.state.newSignature}
                            addNewSignature={(name) =>
                              this.handleNewSignature(name)
                            }
                            removeSign={(id) =>
                              this.handleRemoveSignatureUser(id)
                            }
                          />
                        </div>
                      </FadeIn>
                    </>
                  ) : null}
                </>
              )}
              <Modal
                title="Ajouter une signature"
                show={this.state.modalSignature}
                onClose={(e) => this.handleModalSignatureClick(e)}
              >
                <SignatureCanvas
                  ref={this.signatureRef}
                  penColor="black"
                  canvasProps={{
                    height: this.state.height,
                    width: this.state.width,
                    className: "sigCanvas",
                  }}
                />
                <Button
                  text="Rénitialiser"
                  className="m-0 mb-10 outline"
                  onClick={this.handleClearSignatureClick}
                />
                <Button
                  text="Valider"
                  className="m-0"
                  onClick={this.handleValidSignatureClick}
                />
              </Modal>
              <Loader show={this.state.isLoad} />
              {this.alertSave()}
            </div>
          ) : null}
        </div>
        {this.state.selectedSim &&
        this.state.selectedSim.simData.status === 1 ? (
          <PrintClass
            refs={
              this.state.isPrint
                ? [
                    this.refPrintSummary,
                    this.refPrintPerformance,
                    this.refPrintAudit,
                    this.refPrintSimCloturees,
                  ]
                : []
            }
            title={
              this.state.selectedSim && this.state.selectedSim.simData.name
            }
            fileName={
              this.state.selectedSim && this.state.selectedSim.simData.name
            }
            onPrint={(print) =>
              this.setState({ isPrint: print, isLoad: print })
            }
            width={1500}
          />
        ) : (
          <FloatActionButton icon={<FontAwesomeIcon icon={faBars} />}>
            {this.state.disableEdition ? null : (
              <FloatActionButtonItem
                text={"Sauvegarder"}
                icon={<FontAwesomeIcon icon={faSave} />}
                onClick={this.handleSaveSimData}
              />
            )}
            {this.state.disableEdition ? null : (
              <FloatActionButtonItem
                text={"Clôturer"}
                icon={<FontAwesomeIcon icon={faFlag} />}
                onClick={this.handleClotureSim}
              />
            )}
            <PrintClass
              refs={
                this.state.isPrint
                  ? [
                      this.refPrintSummary,
                      this.refPrintPerformance,
                      this.refPrintAudit,
                      this.refPrintEvolution,
                      this.refPrintActionPlan,
                    ]
                  : []
              }
              title={
                this.state.selectedSim && this.state.selectedSim.simData.name
              }
              fileName={
                this.state.selectedSim && this.state.selectedSim.simData.name
              }
              onPrint={(print) =>
                this.setState({ isPrint: print, isLoad: print })
              }
              width={1500}
              buttonItem
            />
          </FloatActionButton>
        )}
        <Modal
          show={this.state.show}
          onClose={() => this.setState({ show: false })}
          title="Error"
        >
          <p style={{ color: "red" }}>
            {this.state.error
              ? this.state.error
              : "Oups... Une erreur est survenue durant le chargement de la page."}
          </p>
        </Modal>
      </div>
    );
  }
}
