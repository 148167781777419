import React, { Component } from "react";

export default class Select extends Component {
  render() {
    return (
      <select
        className="cursor-pointer"
        style={this.props.style}
        value={this.props.value}
        onChange={(event) => this.props.onChange(event.target.value)}
      >
        {this.props.options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    );
  }
}
