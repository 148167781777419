import React, { Component } from "react";
import FadeIn from "react-fade-in/lib/FadeIn";
import { Row, Col } from "react-flexbox-grid";
import { Nav, Card, Loader, BarChart } from "ui-kit-ck-consultant";

import ShowOptionsNGRA from "../../../components/general/ShowOptionsNGRA-v2";
import PrintClass from "../../../components/general/Print";

import Chart from "../../../components/specialties/inconsistency/Chart";
import List from "../../../components/specialties/inconsistency/List";

import axiosConfig from "../../../utils/axiosConfig";
import { getCurrencyValue } from "../../../utils/general";
import { colors } from "../../../utils/colors";

export class Inconsistency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      isPrint: false,
      charts: {},
      data: [],
      gainTotal: null,
      gainMonths: null,
    };

    this.refPrintChart = React.createRef();
    this.refPrintList = React.createRef();
  }

  handleDataChange = (data) => {
    this.setState(
      {
        network: data.network,
        group: data.group,
        region: data.region,
        agency: data.agency,
        user: data.user,
        dateStart: data.dateStart,
        dateEnd: data.dateEnd,
        company: data.company,
        natureEvent: data.natureEvent,
        natureExpertise: data.natureExpertise,
        fiscalPower: data.fiscalPower,
        brand: data.brand,
      },
      () => {
        this.getData();
      }
    );
  };

  getData = () => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        axiosConfig("specialties/inconsistency/get-data", {
          data: {
            dateStart: this.state.dateStart,
            dateEnd: this.state.dateEnd,
            network: this.state.network,
            group: this.state.group,
            region: this.state.region,
            agency: this.state.agency,
            user: this.state.user,
            company: this.state.company,
            natureEvent: this.state.natureEvent,
            natureExpertise: this.state.natureExpertise,
            fiscalPower: this.state.fiscalPower,
            brand: this.state.brand,
          },
        })
          .then((res) => {
            if (res.data.success) {
              this.setState({
                isLoad: false,
                data: res.data.data,
                charts: res.data.charts,
                gainTotal: res.data.gainTotal,
                gainMonths: res.data.gainMonths,
              });
            } else {
              this.setState({
                isLoad: false,
                data: [],
                charts: {},
                gainTotal: null,
                gainMonths: null,
              });
            }
          })
          .catch(() => {
            this.setState({
              isLoad: false,
              data: [],
              charts: {},
              gainTotal: null,
              gainMonths: null,
            });
          });
      }
    );
  };

  render() {
    return (
      <>
        <FadeIn>
          <Nav title="Incohérence" className="mb-10 mb-20-xl" />
          <ShowOptionsNGRA
            isChange={true}
            className="mb-10 mb-20-xl"
            onDataChange={this.handleDataChange}
            isCompany={true}
            isUser={true}
            isDateStart={true}
            isDateEnd={true}
            visibilityRole={this.props.visibilityRole}
            visibilityType={this.props.visibilityType}
            preloadRole={this.props.preloadRole}
            preloadType={this.props.preloadType}
            limitUserType={this.props.limitUserType}
            limitUserRole={this.props.limitUserRole}
            userType={[1, 2, 3]}
            isDefaultAllUser={true}
            isNatureEvent={true}
            isNatureExpertise={true}
            isBrand={true}
            isFiscalPower={true}
            defaultNatureExpertise={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 15]}
            excludeNatureExpertise={[12, 13, 14, 16, 17, 18, 19]}
          />
          <div ref={this.refPrintChart}>
            <Card
              title={`Gain cumulé : ${getCurrencyValue(this.state.gainTotal)}`}
              className="mb-10 mb-20-xl"
              classNameChildren="overflow-x-auto"
            >
              <Row>
                <Col xs={12} md={6} lg={4} className="mb-10">
                  <Chart
                    title="Nature d'experise"
                    data={
                      this.state.charts.natureExpertise
                        ? this.state.charts.natureExpertise
                        : []
                    }
                  />
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-10">
                  <Chart
                    title="Nature d'évènement"
                    data={
                      this.state.charts.natureEvent
                        ? this.state.charts.natureEvent
                        : []
                    }
                  />
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-10">
                  <Chart
                    title="Marque"
                    data={
                      this.state.charts.brand ? this.state.charts.brand : []
                    }
                  />
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-10">
                  <Chart
                    title="Puissance fiscale"
                    data={
                      this.state.charts.fiscalPower
                        ? this.state.charts.fiscalPower
                        : []
                    }
                  />
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-10">
                  <Chart
                    title="Gain"
                    data={
                      this.state.charts.sliceGain
                        ? this.state.charts.sliceGain
                        : []
                    }
                  />
                </Col>
                <Col xs={12} md={6} lg={4} className="mb-10">
                  {this.state.gainMonths ? (
                    <BarChart
                      digits={0}
                      title={"Gain / Mois"}
                      format="currency"
                      dataSet={[
                        {
                          label: "Gain",
                          data: this.state.gainMonths.map((element) => element),
                          color: colors.blue[0],
                        },
                      ]}
                      datalabels2={
                        this.state.isPrint
                          ? [
                              {
                                display: true,
                                align: "top",
                                anchor: "end",
                                clamp: true,
                                color: "#0162AD",

                                formatter: (value) =>
                                  value ? getCurrencyValue(value) : null,
                              },
                            ]
                          : null
                      }
                    />
                  ) : null}
                </Col>
              </Row>
            </Card>
          </div>
          <div ref={this.refPrintList}>
            <List isPrint={this.state.isPrint} data={this.state.data} />
          </div>
        </FadeIn>
        <Loader show={this.state.isLoad || this.state.isPrint} />
        <PrintClass
          refs={
            this.state.isPrint ? [this.refPrintChart, this.refPrintList] : []
          }
          title={"Incohérence"}
          fileName={"Incoherence"}
          onPrint={(print) => this.setState({ isPrint: print })}
          width={1600}
        />
      </>
    );
  }
}

export default Inconsistency;
