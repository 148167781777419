import React, { Component } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Card,
  Pagination,
  ButtonIcon,
} from "ui-kit-ck-consultant";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
  faSearchPlus,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalAudit from "./Modal";

import AuthContext from "../../../context/AuthContext";

export default class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: null,
      modalIsOpen: false,
      dataItem: null,
    };

    this.refColumnDate = React.createRef();
    this.refColumnUser = React.createRef();
    this.refColumnUserAudit = React.createRef();
    this.refColumnAgency = React.createRef();
    this.refColumnReference = React.createRef();
    this.refColumnType = React.createRef();
    this.refColumnComment = React.createRef();
    this.refColumnValue = React.createRef();
  }

  static contextType = AuthContext;

  handleSortClick = (desc, column) => {
    if (column !== 0) {
      this.refColumnDate.current.setState({ desc: false, sort: false });
    }
    if (column !== 1) {
      this.refColumnUser.current.setState({ desc: false, sort: false });
    }
    if (column !== 2) {
      this.refColumnUserAudit.current.setState({ desc: false, sort: false });
    }
    if (column !== 3) {
      this.refColumnAgency.current.setState({ desc: false, sort: false });
    }
    if (column !== 4) {
      this.refColumnReference.current.setState({ desc: false, sort: false });
    }
    if (column !== 5) {
      this.refColumnType.current.setState({ desc: false, sort: false });
    }
    if (column !== 6) {
      this.refColumnComment.current.setState({ desc: false, sort: false });
    }
    if (column !== 7) {
      this.refColumnValue.current.setState({ desc: false, sort: false });
    }

    let order = null;
    switch (column) {
      case 0:
        order = { name: "date", desc: desc };
        break;
      case 1:
        order = { name: "user", desc: desc };
        break;
      case 2:
        order = { name: "userAudit", desc: desc };
        break;
      case 3:
        order = { name: "agency", desc: desc };
        break;
      case 4:
        order = { name: "reference", desc: desc };
        break;
      case 5:
        order = { name: "type", desc: desc };
        break;
      case 6:
        order = { name: "comment", desc: desc };
        break;
      case 7:
        order = { name: "value", desc: desc };
        break;
      default:
        order = { name: "id", desc: desc };
        break;
    }
    this.setState({ order: order }, () => {
      this.props.onSortClick(order);
    });
  };

  render() {
    return (
      <Card
        title={"Liste"}
        className={this.props.className}
        classNameChildren="overflow-x-auto"
      >
        <ModalAudit
          show={this.state.modalIsOpen}
          hide={() =>
            this.setState((prevState) => ({
              modalIsOpen: !prevState.modalIsOpen,
              dataItem: null,
            }))
          }
          item={this.state.dataItem}
        />
        <Table
          empty={
            this.props.data && this.props.data.length > 0
              ? ""
              : "Aucune donnée n'est disponible"
          }
        >
          <Thead>
            <Tr>
              <Th
                ref={this.refColumnDate}
                sort
                onSort={(desc) => this.handleSortClick(desc, 0)}
              >
                Date
              </Th>
              <Th
                ref={this.refColumnUser}
                sort
                onSort={(desc) => this.handleSortClick(desc, 1)}
              >
                Utilisateur
              </Th>
              <Th
                ref={this.refColumnUserAudit}
                sort
                onSort={(desc) => this.handleSortClick(desc, 2)}
              >
                Auditeur
              </Th>
              <Th
                ref={this.refColumnAgency}
                sort
                onSort={(desc) => this.handleSortClick(desc, 3)}
              >
                Agence
              </Th>
              <Th
                ref={this.refColumnReference}
                sort
                onSort={(desc) => this.handleSortClick(desc, 4)}
              >
                N° dossier
              </Th>
              <Th
                ref={this.refColumnType}
                sort
                onSort={(desc) => this.handleSortClick(desc, 5)}
              >
                Type d'audit
              </Th>
              <Th
                ref={this.refColumnComment}
                sort
                onSort={(desc) => this.handleSortClick(desc, 6)}
              >
                Commentaire
              </Th>
              <Th
                ref={this.refColumnValue}
                sort
                onSort={(desc) => this.handleSortClick(desc, 7)}
              >
                % d'atteinte
              </Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {this.props.data &&
              this.props.data.map((element, idx) => (
                <Tr
                  key={idx}
                  className={element.length === true ? "cursor-pointer " : ""}
                  onClick={(e) =>
                    element.length === true ? this.props.onTabClick(idx) : ""
                  }
                >
                  <Td
                    className={
                      element.value < 80
                        ? "bg-red-light "
                        : "" + element.value >= 90
                        ? "bg-green-light "
                        : "bg-orange-light"
                    }
                  >
                    {element.date}
                  </Td>
                  <Td
                    className={
                      element.value < 80
                        ? "bg-red-light "
                        : "" + element.value >= 90
                        ? "bg-green-light "
                        : "bg-orange-light"
                    }
                  >
                    {element.user}
                  </Td>
                  <Td
                    className={
                      element.value < 80
                        ? "bg-red-light "
                        : "" + element.value >= 90
                        ? "bg-green-light "
                        : "bg-orange-light"
                    }
                  >
                    {element.userAudit}
                  </Td>
                  <Td
                    className={
                      element.value < 80
                        ? "bg-red-light "
                        : "" + element.value >= 90
                        ? "bg-green-light "
                        : "bg-orange-light"
                    }
                  >
                    {element.agency}
                  </Td>
                  <Td
                    className={
                      element.value < 80
                        ? "bg-red-light "
                        : "" + element.value >= 90
                        ? "bg-green-light "
                        : "bg-orange-light"
                    }
                  >
                    {element.reference}
                  </Td>
                  <Td
                    className={
                      element.value < 80
                        ? "bg-red-light "
                        : "" + element.value >= 90
                        ? "bg-green-light "
                        : "bg-orange-light"
                    }
                  >
                    {element.type}
                  </Td>
                  <Td
                    className={
                      element.value < 80
                        ? "bg-red-light "
                        : "" + element.value >= 90
                        ? "bg-green-light "
                        : "bg-orange-light"
                    }
                  >
                    {element.length === false || element.active === true
                      ? element.comment
                      : element.shortComment}
                  </Td>
                  <Td
                    className={
                      element.value < 80
                        ? "bg-red-light "
                        : "" + element.value >= 90
                        ? "bg-green-light "
                        : "bg-orange-light"
                    }
                  >
                    {element.value}
                  </Td>
                  <Td
                    className={
                      element.value < 80
                        ? "bg-red-light "
                        : "" + element.value >= 90
                        ? "bg-green-light "
                        : "bg-orange-light"
                    }
                  >
                    {element.userId === this.context.id ||
                    this.context.role !== 0 ? (
                      <>
                        <ButtonIcon
                          className={"black"}
                          onClick={() => {
                            this.setState((prevState) => ({
                              modalIsOpen: !prevState.modalIsOpen,
                              dataItem: element,
                            }));
                          }}
                          small
                        >
                          <FontAwesomeIcon icon={faSearchPlus} />
                        </ButtonIcon>
                        <ButtonIcon
                          className={"red"}
                          onClick={() => {
                            if (
                              window.confirm(
                                "Êtes-vous sûr de vouloir supprimer cet audit ?"
                              )
                            ) {
                              this.props.onDeleteAudit(element.id);
                            }
                          }}
                          small
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </ButtonIcon>
                      </>
                    ) : null}
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>

        {this.props.isPrint ? null : (
          <Pagination
            activePage={this.props.activePage}
            itemsCountPerPage={this.props.tabLength}
            totalItemsCount={this.props.total}
            pageRangeDisplayed={4}
            onChange={this.props.onPageChange.bind(this)}
            firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
            lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
            prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
            nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
          />
        )}
      </Card>
    );
  }
}
