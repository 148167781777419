import React, { Component } from "react";
import { Card, Button } from "ui-kit-ck-consultant";
import ActionPlanElement from "./ActionPlanElement";
export default class CardActionPlan extends Component {
  createActionPlan = () => {
    let currentActionPlanArr = this.props.current;
    let id = currentActionPlanArr.length;

    currentActionPlanArr.push({
      id: id,
      indicator: null,
      indicator2: null,
      dateStart: "",
      dateEnd: "",
      value: "",
      checked: false
    });

    this.props.handleEditCurrentActionPlan(currentActionPlanArr);
  }

  editActionPlan = (actionPlan, current) => {
    if (current) {
      // CURRENT ACTION PLANS
      const newCurrActionPlans = this.props.current.map(element => {
        if (element.id === actionPlan.id) {
          return actionPlan;
        } else {
          return element;
        }
      });


      this.props.handleEditCurrentActionPlan(newCurrActionPlans);
      
    } else {
      // OLD ACTION PLANS
      const newOldActionPlans = this.props.old.map(element => {
        if (element.id === actionPlan.id) {
          return actionPlan;
        } else {
          return element;
        }
      });

      this.props.handleEditOldActionPlan(
        actionPlan.eventId, 
        {
          id: actionPlan.id,
          checked: actionPlan.checked,
          dateStart: actionPlan.dateStart,
          dateEnd: actionPlan.dateEnd,
          indicator: actionPlan.indicator,
          indicator2: actionPlan.indicator2,
          value: actionPlan.value
        }, 
        newOldActionPlans
      );
    }
  }

  deleteActionPlan = id => {   
    const newCurrActionPlans = this.props.current.filter(element => element.id !== id);
    this.props.handleEditCurrentActionPlan(newCurrActionPlans);
  }

  render() {
    return (
      <Card title="Plan d'action" className="mt-10 mt-30-xl">
        {/* EMPTY MESSAGE */}
        {this.props.disableEdition &&
          this.props.current.length === 0 &&
          this.props.old.length === 0 ? (
            <span>Aucun plan d'action</span>
        ) : null}

        {/* CURRENT ACTION PLANS */}
        {this.props.current.map(element => (
          <ActionPlanElement
            key={`current-${element.id}`}
            plan={element}
            indicatorOptions={this.props.indicator}
            current={true}
            disableEdtion={this.props.disableEdition}
            editActionPlan={this.editActionPlan}
            deleteActionPlan={this.deleteActionPlan}
          />
        ))}

        {/* OLD ACTION PLANS */}
        {this.props.old.map(element => (
          <ActionPlanElement
            key={`old-${element.id}`}
            plan={element}
            name={element.simName ? element.simName : null}
            indicatorOptions={this.props.indicator}
            current={false}
            disableEdtion={this.props.disableEdition}
            editActionPlan={this.editActionPlan}
            deleteActionPlan={this.deleteActionPlan}
          />
        ))}

        {/* ADD NEW ACTION PLAN BUTTON */}
        {this.props.disableEdition ? null : (
          <Button
            text="Ajouter un plan d'action"
            className="mt-10 mt-30-xl"
            onClick={this.createActionPlan}
          />
        )}
      </Card>
    );
  }
}
