import React, { Component } from 'react'
import { Col, Row } from 'react-flexbox-grid'
import { BarChart, DoughnutChart, Status } from 'ui-kit-ck-consultant'

export class AuditCard extends Component {

  setLabel(value) {
    if (value >= 90) {
      return "EXCELLENCE";
    } else if (value >= 80) {
      return "PREMIUM";
    } else {
      return "VIGILANCE";
    }
  }

  setLabelClass(value) {
    if (value >= 90) {
      return "bg-green";
    } else if (value >= 80) {
      return "bg-orange";
    } else {
      return "bg-red";
    }
  }


  render() {
    return (
      <>
      {/* {this.props.data ?  */}
          <Row>
            <Col md={3} style={{display: "flex"}}>
              <div className='d-flex' style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Status text={`${this.setLabel(this.props.data.atteinteGlobale)}`} className={`${this.setLabelClass(this.props.data.atteinteGlobale)}`} style={{ paddingRight: '5', paddingLeft: '5' }} /><br />
                <DoughnutChart className="m-auto" number={this.props.data.atteinteGlobale} text="d'atteinte" max={100} /><br />
              </div>
            </Col>
            <Col md={9}>
              <BarChart
                height={this.props.height}
                title={'Pourcentage d\'atteinte Mois / Mois'} 
                digits={0}
                legendDisplay={false}
                dataSet={[
                  {
                    label: "% d'atteinte",
                    data: this.props.data.months,
                    type: "bar",
                    color: '#6067AE',
                  },
                ]}
                datalabels2={[
                  {
                    display: true,
                    align: 'top',
                    anchor: 'end',
                    color: '#0162AD',
                    font: {
                      size: '8',
                    },
                    formatter: (value) => Math.round(value) + '%'
                  }
                ]}
                yAxes = {[
                  {
                    display: false,
                    ticks: {
                      max: 120,
                      min: 0,
                      beginAtZero: true
                    },
                  }
                ]}
              />
            </Col>
          </Row>
      {/* : null } */}
      </>
    )
  }
}

export default AuditCard
