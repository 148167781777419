const colors = {
  green: ["#0c8540", "#bcf5d5", "#01612a"],
  cyan: ["#00c0ba", "#befffd", "#026a67"],
  blue: ["#026fc2", "#bbe1fe", "#023f6d"],
  purple: ["#6a3ab3", "#dcc5fe", "#41236d"],
  pink: ["#b40c8d", "#ffbcf0", "#7a0e61"],
  red: ["#db2828", "#ffb8b8", "#9d0c0c"],
  orange: ["#f2711c", "#fecaa9", "#b94900"],
  yellow: ["#ffcc00", "#fbefc0", "#d5ab00"],
  gray: ["#d8d8d8", "#f3f3f3"],
  white: ["#ffffff"],
  black: ["#1e1f26"],
};
const indicatorsColors = {
  0: "red",
  1: "blue",
  2: "green",
  3: "yellow",
  4: "purple",
  5: "cyan",
  6: "magenta",
  7: "orange",
  8: "pink",
  9: "lime",
  10: "teal",
  11: "indigo",
  12: "maroon",
  13: "olive",
  14: "navy",
  15: "gray",
  16: "black",
  17: "silver",
  18: "white",
  19: "#FF5733",
  20: "#6A5ACD",
  21: "#00FF7F",
  22: "#FFD700",
  23: "#8B4513",
  24: "#228B22",
  25: "#FF69B4",
  26: "#D2691E",
  27: "#4682B4",
  28: "#2E8B57",
  29: "#FF6347",
  30: "#BA55D3",
  31: "#00CED1",
};

const getColors = () => {
  const filtered = Object.keys(colors).filter(
    (key) => colors[key].length === 3
  );

  let result = [];

  filtered.forEach((key) => {
    result.push(colors[key][0]);
  });
  filtered.forEach((key) => {
    result.push(colors[key][1]);
  });
  filtered.forEach((key) => {
    result.push(colors[key][2]);
  });

  return result;
};

export { colors, getColors, indicatorsColors };
