import React, { Component } from "react";
import { Col } from "react-flexbox-grid";

import caretUp from "../../images/caret-up.svg";
import caretDown from "../../images/caret-down.svg";
import caretUpReverse from "../../images/caret-up-reverse.svg";
import caretDownReverse from "../../images/caret-down-reverse.svg";

import { getValue } from "../../utils/general";

export default class CardScore extends Component {
  render() {
    const { title, value, currentValue, prevValue, digits, reverse, large } =
      this.props;

    const pre = (currentValue || value) - prevValue;

    let red = false;
    let icon = null;

    if (reverse) {
      if (pre < 0) {
        red = true;
        icon = caretDown;
      } else {
        icon = caretUp;
      }
    } else {
      if (pre > 0) {
        red = true;
        icon = caretUpReverse;
      } else {
        icon = caretDownReverse;
      }
    }

    return (
      <Col xs={12} md={6} lg={3}>
        <div
          className="mb-10 mb-20-xl"
          style={{
            position: "relative",
            borderRadius: "8px",
            backgroundColor: "var(--white)",
            color: "var(--black)",
            boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
            minHeight: "85px",
            display: "flex",
            overflow: "hidden",
            flexDirection: "column",
            padding: 15,
          }}
        >
          <h2
            style={{
              color: "#535374",
              fontSize: "13px",
              fontWeight: "700",
              margin: 0,
            }}
          >
            {title}
          </h2>
          <span
            style={{
              color: !red ? "#4FBC9D" : "#EC4646",
              fontSize: "45px",
              fontWeight: "700",
            }}
          >
            {getValue(large ? value / 100000 : value, digits || 0)}{" "}
            <span
              style={{
                fontSize: "17px",
              }}
            >
              {large ? "kt" : "kg"}
            </span>
          </span>
          <div
            className="d-flex"
            style={{
              alignItems: "center",
            }}
          >
            <img src={icon} height={15} alt="" className="mr-5" />
            <span
              style={{
                color: !red ? "#4FBC9D" : "#EC4646",
                fontSize: "13px",
                fontWeight: "400",
              }}
            >
              {getValue(large ? pre / 1000000 : pre, digits || 0)}{" "}
              {large ? "kt" : "kg"}{" "}
              <span
                style={{
                  color: "#535374",
                }}
              >
                par rapport au mois dernier
              </span>
            </span>
          </div>
        </div>
      </Col>
    );
  }
}
