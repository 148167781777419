import React, { Component, Fragment } from "react";
import { Modal, FormInput, Button, Switch } from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";

import axiosConfig from "../../../utils/axiosConfig";

const days = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi"];

export default class AvailabilityModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.resetState();
  }

  resetState = () => {
    this.setState({
      type: 0,
      startAM: "",
      endAM: "",
      startPM: "",
      endPM: "",
      startAM_0: "",
      endAM_0: "",
      startPM_0: "",
      endPM_0: "",
      startAM_1: "",
      endAM_1: "",
      startPM_1: "",
      endPM_1: "",
      startPM_2: "",
      endPM_2: "",
      startPM_3: "",
      endPM_3: "",
      startPM_4: "",
      endPM_4: "",
    });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id && this.props.id) {
      axiosConfig("planning/tour/get-time-availability", {
        data: { id: this.props.id },
      }).then((res) => {
        if (res.data.success === true) {
          this.setState({
            ...res.data.data,
          });
        }
      });
    }
  }

  handleValidClick = () => {
    axiosConfig("planning/tour/update-time-availability", {
      data: {
        id: this.props.id,
        data: this.state,
      },
    }).then((res) => {
      if (res.data.success === true) {
        this.resetState();
        this.props.onClose();
        this.props.onValidClick(true);
      } else {
        this.resetState();
        this.props.onClose();
        this.props.onValidClick(false);
      }
    });
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onClose={() => {
          this.resetState();
          this.props.onClose();
        }}
        title="Modifier les horaires"
      >
        <Switch
          className="mb-20"
          value1="Simplifié"
          value2="Détaillé"
          color1="#f2711c"
          color2="#026fc2"
          idxActive={this.state.type}
          onChange={(type) => this.setState({ type })}
        />
        {this.state.type === 0 ? (
          <Row>
            <Col xs={12} className={"mb-10"}>
              <h3 className="m-0">Matin</h3>
            </Col>
            <Col xs={12} md={6} className={"mb-10"}>
              <FormInput
                type="time"
                title="Début"
                placeholder="00:00"
                name="startAM"
                value={this.state.startAM}
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6} className={"mb-10"}>
              <FormInput
                type="time"
                title="Fin"
                placeholder="00:00"
                name="endAM"
                value={this.state.endAM}
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} className={"mb-10"}>
              <h3 className="m-0">Après-midi</h3>
            </Col>
            <Col xs={12} md={6} className={"mb-10"}>
              <FormInput
                type="time"
                title="Début"
                placeholder="00:00"
                name="startPM"
                value={this.state.startPM}
                onChange={this.handleInputChange}
              />
            </Col>
            <Col xs={12} md={6} className={"mb-10"}>
              <FormInput
                type="time"
                title="Fin"
                placeholder="00:00"
                name="endPM"
                value={this.state.endPM}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
        ) : (
          <Row>
            {days.map((day, idx) => (
              <Fragment key={idx}>
                <Col xs={12} className={"mb-10"}>
                  <h3 className="m-0">{day} Matin</h3>
                </Col>
                <Col xs={12} md={6} className={"mb-10"}>
                  <FormInput
                    type="time"
                    title="Début"
                    placeholder="00:00"
                    name={`startAM_${idx}`}
                    value={this.state[`startAM_${idx}`]}
                    onChange={this.handleInputChange}
                  />
                </Col>
                <Col xs={12} md={6} className={"mb-10"}>
                  <FormInput
                    type="time"
                    title="Fin"
                    placeholder="00:00"
                    name={`endAM_${idx}`}
                    value={this.state[`endAM_${idx}`]}
                    onChange={this.handleInputChange}
                  />
                </Col>
                <Col xs={12} className={"mb-10"}>
                  <h3 className="m-0">{day} Après-midi</h3>
                </Col>
                <Col xs={12} md={6} className={"mb-10"}>
                  <FormInput
                    type="time"
                    title="Début"
                    placeholder="00:00"
                    name={`startPM_${idx}`}
                    value={this.state[`startPM_${idx}`]}
                    onChange={this.handleInputChange}
                  />
                </Col>
                <Col xs={12} md={6} className={"mb-10"}>
                  <FormInput
                    type="time"
                    title="Fin"
                    placeholder="00:00"
                    name={`endPM_${idx}`}
                    value={this.state[`endPM_${idx}`]}
                    onChange={this.handleInputChange}
                  />
                </Col>
              </Fragment>
            ))}
          </Row>
        )}
        <Button text="Valider" onClick={this.handleValidClick} />
      </Modal>
    );
  }
}
