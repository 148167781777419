import React, { Component } from "react";
import {
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Modal,
} from "ui-kit-ck-consultant";

import AuthContext from "../../../context/AuthContext";

const days_arr = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi"];

export default class Availability extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalExpert: false,
      currentAgency: 0,
    };
  }
  static contextType = AuthContext;

  getColorColumn(value, max) {
    const percent = (value * 100) / max;
    let color = null;
    if (percent >= 100) {
      color = "bg-red-light";
    } else if (percent > 50) {
      color = "bg-orange-light";
    } else {
      color = "bg-green-light";
    }

    return <Td className={color}>{value + "/" + max}</Td>;
  }

  getTable(data, onOpenModal, days, isExpert = false) {
    return (
      <Table>
        <Thead>
          <Tr>
            {isExpert ? <Th>Expert</Th> : <Th>Agence</Th>}
            {days.map((element, idx) => (
              <Th key={idx}>{element}</Th>
            ))}
            {isExpert ? null : <Th>RDV agence</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((element, idx) => (
            <Tr key={idx}>
              <Th
                className="cursor-pointer"
                onClick={() =>
                  this.setState({
                    isModalExpert: true,
                    currentAgency: element.id,
                  })
                }
              >
                {element.name}
              </Th>
              {this.getColorColumn(element.data[0], element.count[0])}
              {this.getColorColumn(element.data[1], element.count[1])}
              {this.getColorColumn(element.data[2], element.count[2])}
              {this.getColorColumn(element.data[3], element.count[3])}
              {this.getColorColumn(element.data[4], element.count[4])}
              {isExpert ? null : (
                <Td
                  className={
                    [1, 2, 4].includes(this.context.role)
                      ? "cursor-pointer"
                      : null
                  }
                  onClick={() => {
                    if ([1, 2, 4].includes(this.context.role)) {
                      onOpenModal(element.id);
                    }
                  }}
                >
                  {(element.timeJSON.type || 0) === 0 ? (
                    <>
                      {element.timeJSON.startAM ? (
                        <span>
                          Matin - {element.timeJSON.startAM} -{" "}
                          {element.timeJSON.endAM}
                        </span>
                      ) : (
                        ""
                      )}
                      <br />
                      {element.timeJSON.startPM ? (
                        <span>
                          Après-midi - {element.timeJSON.startPM} -{" "}
                          {element.timeJSON.endPM}
                        </span>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <>
                      {days_arr.map((day, idx) => (
                        <span className="d-block" key={idx}>
                          {day} - {element.timeJSON[`startAM_${idx}`]} -{" "}
                          {element.timeJSON[`endAM_${idx}`]} |{" "}
                          {element.timeJSON[`startPM_${idx}`]} -{" "}
                          {element.timeJSON[`endPM_${idx}`]}
                        </span>
                      ))}
                    </>
                  )}
                </Td>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
    );
  }

  render() {
    return (
      <>
        <Card
          title={`Disponibilités / Agence (${
            this.props.total ? this.props.total : 0
          } / ${this.props.totalDailyAvailability})`}
          className={this.props.className}
          action={this.props.action}
          onClick={this.props.onClick}
          isLoad={this.props.isLoad}
        >
          {this.props.isAvailability === true
            ? this.getTable(
                this.props.data,
                this.props.onOpenModal,
                this.props.days
              )
            : null}
        </Card>
        <Modal
          show={this.state.isModalExpert}
          onClose={() =>
            this.setState({ isModalExpert: false, currentAgency: 0 })
          }
        >
          {this.props.isAvailability === true
            ? this.getTable(
                this.props.dataExpert.filter(
                  (expert) => expert.agencyId === this.state.currentAgency
                ),
                () => {},
                this.props.days,
                true
              )
            : null}
        </Modal>
      </>
    );
  }
}
