import React, { Component } from "react";
import FadeIn from "react-fade-in";
import {
  Pagination,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

export class AuditListActionPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      tabLength: 10,
      total: 0,
      dataList: [],
      order: null,
      dataItem: null,
      modalIsOpen: false,
    };
  }

  componentDidMount() {
    this.setState({
      total: this.props.dataTab.length,
      dataList: this.props.dataTab.slice(0, this.state.tabLength),
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dataTab.length !== this.props.dataTab.length) {
      this.setState({
        total: this.props.dataTab.length,
        dataList: this.props.dataTab.slice(0, this.state.tabLength),
      });
    }
  }

  onPageChange = (newPage) => {
    let startAt = (newPage - 1) * this.state.tabLength;
    let endAt = (newPage - 1) * this.state.tabLength + this.state.tabLength;
    if (endAt > this.state.total) endAt = this.state.total;
    this.setState({
      activePage: newPage,
      dataList: this.props.dataTab.slice(startAt, endAt),
    });
  };

  getClassLabel = (value) => {
    if (value) {
      return "bg-green-light";
    } else {
      return "bg-red-light";
    }
  };

  render() {
    return (
      <FadeIn>
        <Table
          empty={
            this.props.dataTab.length > 0
              ? ""
              : "Aucune donnée n'est disponible"
          }
        >
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>Date de clôture</Th>
              <Th>Agence</Th>
              <Th>N° dossier</Th>
              <Th>Expert</Th>
              <Th>Assistant(e)</Th>
              <Th>Indicateur</Th>
              <Th>Nature d'expertise</Th>
              <Th>Total rapport HT</Th>
              <Th>VRADE TTC</Th>
              <Th>Commentaire</Th>
              <Th>Conforme</Th>
            </Tr>
          </Thead>
          <Tbody>
            {this.state.dataList.map((el, idx) => (
              <Tr key={idx}>
                <Td className={" " + this.getClassLabel(el.value)}>
                  {el.date}
                </Td>
                <Td className={" " + this.getClassLabel(el.value)}>
                  {el.closingDate}
                </Td>
                <Td className={" " + this.getClassLabel(el.value)}>
                  {el.agency}
                </Td>
                <Td className={" " + this.getClassLabel(el.value)}>
                  {el.folder}
                </Td>
                <Td className={" " + this.getClassLabel(el.value)}>
                  {el.expert}
                </Td>
                <Td className={" " + this.getClassLabel(el.value)}>
                  {el.assistant}
                </Td>
                <Td className={" " + this.getClassLabel(el.value)}>
                  INDICATEUR {el.type}
                </Td>
                <Td className={" " + this.getClassLabel(el.value)}>
                  {el.natureExpertise}
                </Td>
                <Td className={" " + this.getClassLabel(el.value)}>
                  {el.totalReportDutyFree ? `${el.totalReportDutyFree} €` : ""}
                </Td>
                <Td className={" " + this.getClassLabel(el.value)}>
                  {el.vrade ? `${el.vrade} €` : ""}
                </Td>
                <Td className={" " + this.getClassLabel(el.value)}>
                  {el.comment}
                </Td>
                <Td className={" " + this.getClassLabel(el.value)}>
                  {el.value ? "OUI" : "NON"}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        {this.props.isPrint ? null : (
          <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={this.state.tabLength}
            totalItemsCount={this.state.total}
            pageRangeDisplayed={4}
            onChange={this.onPageChange}
            firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
            lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
            prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
            nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
          />
        )}
      </FadeIn>
    );
  }
}

export default AuditListActionPlan;
