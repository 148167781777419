
import { Status } from "ui-kit-ck-consultant";

function formatTable(data, type) {
  if (!data || data.title === undefined) {
    return [];
  }

  function checkZeroNull(value) {
    let bool = value === 0 || !value ? true : false;
    return bool;
  }

  function color(value, label) {
    if (value === 0) {
      return "bg-blue-light black w-100";
    }

    if (
      ![
        "Nombre de dossiers",
        "Âge véhicule (m)",
        "Remise (%)",
        "Dossiers 0/750 (%)",
        "Dossiers 750/1500 (%)",
        "RP (%)",
        "PRE (%)",
        "Validation facture (%)",
        "Précision (%)",
      ].includes(label)
    ) {
      if (value >= 0) {
        return "bg-red-light black w-100";
      } else if (value >= 10) {
        return "bg-orange-light black w-100";
      } else {
        return "bg-green-light black w-100";
      }
    } else {
      if (value >= 0) {
        return "bg-green-light black w-100";
      } else if (value >= -10) {
        return "bg-orange-light black w-100";
      } else {
        return "bg-red-light black w-100";
      }
    }
  }

  function getValue(value1, value2) {
    if (checkZeroNull(value1) || checkZeroNull(value2)) {
      return 0;
    } else {
      return (value1 / value2) * 100 - 100;
    }
  }

  let result = [];

  for (const [key, value] of Object.entries(data.title[type])) {
    let pourc1 = getValue(data.data[type][key], data.data_old[type][key]);
    let pourc2 = getValue(
      data.data_globale[type][key],
      data.data_globale_old[type][key]
    );
    let pourc3 = getValue(
      data.data_old[type][key],
      data.data_globale_old[type][key]
    );
    let pourc4 = getValue(data.data[type][key], data.data_globale[type][key]);

    result.push([
      value[0],
      data.data_old.r[key],
      data.data.r[key],
      <Status
        className={color(pourc1, value[0])}
        text={`${pourc1 !== 0 ? pourc1.toFixed(0) : pourc1} %`}
      />,
      data.data_globale_old[type][key],
      data.data_globale[type][key],
      <Status
        className={color(pourc2, value[0])}
        text={`${pourc2 !== 0 ? pourc2.toFixed(0) : pourc2} %`}
      />,
      <Status
        className={color(pourc3, value[0])}
        text={`${pourc3 !== 0 ? pourc3.toFixed(0) : pourc3} %`}
      />,
      <Status
        className={color(pourc4, value[0])}
        text={`${pourc4 !== 0 ? pourc4.toFixed(0) : pourc4} %`}
      />,
    ]);
  }

  return result;
}

export { formatTable };
