import React, { Component } from "react";
import { Col, Row } from "react-flexbox-grid";
import { BarChart, Card } from "ui-kit-ck-consultant";

export class TableDataGraphics extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getChart = (data, dataIsolate, title) => {
    let format = undefined;

    data = data.map((value) => {
      if (value && typeof value === "string" && value.includes("%")) {
        format = "percentage";
        return parseFloat(
          value.replace("%", "").replace(",", ".").replace(/\s/g, "")
        );
      } else if (value && typeof value === "string" && value.includes("€")) {
        format = "currency";
        return parseFloat(
          value.replace("€", "").replace(",", ".").replace(/\s/g, "")
        );
      } else {
        return value;
      }
    });

    return (
      <Col xs={12} md={6} lg={4}>
        <BarChart
          title={title}
          format={format}
          dataSet={[
            {
              type: "line",
              data: dataIsolate,
              label: "Isolé",
              color: "green",
            },
            {
              type: "line",
              data: data,
              label: "Cumulé",
              color: "red",
            },
          ]}
          isLegend={true}
          yAxes={[
            {
              display: true,
            },
          ]}
        />
      </Col>
    );
  };

  getIsolateData = (x1, x2) => {
    let result = Array(12).fill(null);

    result.forEach((element, key) => {
      if (!x1[key]) {
        result[key] = null;
      } else {
        if (!x1[key] || (key && x1[key] - x1[key - 1] === 0)) {
          result[key] = 0;
        } else {
          result[key] =
            (key > 0
              ? (x1[key] - x1[key - 1]) / (x2[key] - x2[key - 1])
              : x1[key] / x2[key]) * 100;
        }
      }
    });

    return result;
  };

  render() {
    if (!this.props.data) {
      return false;
    }

    const repairableIndicator = this.props.data.repairableIndicator;
    const unrepairableIndicator = this.props.data.unrepairableIndicator;

    return (
      <Card className={"mt-30 mb-30"} title={"Graphiques avancés"}>
        <h3>Réparable</h3>
        <Row>
          {Object.keys(repairableIndicator)
            .filter((key) => repairableIndicator[key])
            .map((key) =>
              this.getChart(
                repairableIndicator[key].data,
                this.getIsolateData(
                  repairableIndicator[key].x1,
                  repairableIndicator[key].x2
                ),
                repairableIndicator[key].name
              )
            )}
        </Row>
        <h3>Non réparable</h3>
        <Row>
          {Object.keys(unrepairableIndicator)
            .filter(
              (key) =>
                unrepairableIndicator[key] &&
                !["indicator12", "indicator12"].includes(key)
            )
            .map((key) =>
              this.getChart(
                unrepairableIndicator[key].data,
                this.getIsolateData(
                  unrepairableIndicator[key].x1,
                  unrepairableIndicator[key].x2
                ),
                unrepairableIndicator[key].name
              )
            )}
        </Row>
      </Card>
    );
  }
}

export default TableDataGraphics;
