import React, { Component } from "react";

import { Modal } from "ui-kit-ck-consultant";

export default class ModalMapGarage extends Component {
  render() {
    return (
      <Modal
        title={`Garage agréé ${this.props.folder.companyName}`}
        show={this.props.isGarageModal}
        onClose={() => this.props.onClose()}
      >
        <p>
          Nom : <b>{this.props.currentGarage.name}</b>
        </p>
        <p>
          Ville : <b>{this.props.currentGarage.city}</b>
        </p>
        <p>
          Adresse : <b>{this.props.currentGarage.address1}</b>
        </p>
        <p>
          Distance : <b>{this.props.distance} Km</b>
        </p>
      </Modal>
    );
  }
}
