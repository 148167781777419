import React, { Component } from "react";
import { Card, Table, Thead, Tbody, Tr, Th, Td } from "ui-kit-ck-consultant";

import { getPercentageValue, getValue } from "../../utils/general";
import handleSortClick from "../../utils/handleSortClickV2";

export default class TablePart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "average",
      desc: false,
    };

    this.sortRef = [React.createRef(), React.createRef(), React.createRef()];
  }

  onSort = (desc, column) => {
    const { name } = handleSortClick(this.sortRef, desc, column);

    this.setState({ name, desc });
  };

  compareValues = (a, b, key, desc = false) => {
    let valueA = a[key];
    let valueB = b[key];

    // Convertir les pourcentages en nombres pour la comparaison
    if (typeof valueA === "string" && valueA.endsWith("%")) {
      valueA = parseFloat(valueA.slice(0, -1));
    }
    if (typeof valueB === "string" && valueB.endsWith("%")) {
      valueB = parseFloat(valueB.slice(0, -1));
    }

    // Convertir les chaînes de caractères contenant des nombres en nombres
    if (typeof valueA === "string" && !isNaN(valueA)) {
      valueA = parseFloat(valueA);
    }
    if (typeof valueB === "string" && !isNaN(valueB)) {
      valueB = parseFloat(valueB);
    }

    // Comparaison des chaînes de caractères
    if (typeof valueA === "string" && typeof valueB === "string") {
      return desc ? valueB.localeCompare(valueA) : valueA.localeCompare(valueB);
    }

    // Comparaison des nombres (entiers et flottants)
    if (typeof valueA === "number" && typeof valueB === "number") {
      return desc ? valueB - valueA : valueA - valueB;
    }

    // Comparaison par défaut si les types ne correspondent pas
    return 0;
  };

  render() {
    let tmpData = this.props.data
      .map((part) => ({
        ...part,
        averagePRE: (part.countPRE / part.count) * 100,
        averageR: (part.countR / part.count) * 100,
        averageE: (part.countE / part.count) * 100,
        averageEmissionE: part.emissionE / part.countE,
        averageEmissionPRE: part.emissionPRE / part.countPRE,
      }))
      .sort((a, b) =>
        this.compareValues(a, b, this.state.name, this.state.desc)
      );

    tmpData = JSON.parse(JSON.stringify(tmpData));

    return (
      <Card
        title="Liste des pièces"
        className="mb-10 mb-20-xl"
        classNameChildren="overflow-x-auto"
      >
        <Table>
          <Thead>
            <Tr>
              <Th></Th>
              <Th colSpan={3}>Neuf</Th>
              <Th colSpan={3}>PRE</Th>
              <Th colSpan={2}>Réparable</Th>
              <Th>Total</Th>
            </Tr>
            <Tr>
              <Th>Nom</Th>
              <Th
                sort
                onSort={(desc) => this.onSort(desc, 0)}
                nameSort="averageE"
                ref={(ref) => (this.sortRef[0] = ref)}
              >
                tx
              </Th>
              <Th
                sort
                onSort={(desc) => this.onSort(desc, 1)}
                nameSort="countE"
                ref={(ref) => (this.sortRef[1] = ref)}
              >
                nb
              </Th>
              <Th
                sort
                onSort={(desc) => this.onSort(desc, 2)}
                nameSort="averageEmissionE"
                ref={(ref) => (this.sortRef[2] = ref)}
              >
                ém. moyenne (kg CO2e)
              </Th>
              <Th
                sort
                onSort={(desc) => this.onSort(desc, 3)}
                nameSort="averagePRE"
                ref={(ref) => (this.sortRef[3] = ref)}
              >
                tx
              </Th>
              <Th
                sort
                onSort={(desc) => this.onSort(desc, 5)}
                nameSort="countPRE"
                ref={(ref) => (this.sortRef[5] = ref)}
              >
                nb
              </Th>
              <Th
                sort
                onSort={(desc) => this.onSort(desc, 4)}
                nameSort="averageEmissionPRE"
                ref={(ref) => (this.sortRef[4] = ref)}
              >
                ém. moyenne (kg CO2e)
              </Th>
              <Th
                sort
                onSort={(desc) => this.onSort(desc, 5)}
                nameSort="averageR"
                ref={(ref) => (this.sortRef[5] = ref)}
              >
                tx
              </Th>
              <Th
                sort
                onSort={(desc) => this.onSort(desc, 6)}
                nameSort="countR"
                ref={(ref) => (this.sortRef[6] = ref)}
              >
                nb
              </Th>
              <Th
                sort
                onSort={(desc) => this.onSort(desc, 7)}
                nameSort="count"
                ref={(ref) => (this.sortRef[7] = ref)}
              >
                nb
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {tmpData.map((part, idx) => (
              <Tr key={idx}>
                <Th>{part.name}</Th>
                <Td>{getPercentageValue(part.averageE)}</Td>
                <Td>{part.countE}</Td>
                <Td>{getValue(part.averageEmissionE)}</Td>
                <Td>{getPercentageValue(part.averagePRE)}</Td>
                <Td>{part.countPRE}</Td>
                <Td>{getValue(part.averageEmissionPRE)}</Td>
                <Td>{getPercentageValue(part.averageR)}</Td>
                <Td>{part.countR}</Td>
                <Td>{part.count}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Card>
    );
  }
}
