import React, { Component } from "react";
import FadeIn from "react-fade-in/lib/FadeIn";
import {
  Nav,
  TabsCustom,
  TabsCustomBody,
  TabsCustomHeader,
  TabsCustomItem,
  TabsCustomMenu,
  Loader,
} from "ui-kit-ck-consultant";

import axiosConfig from "../../../utils/axiosConfig";

import ShowOptionsNGRA from "../../../components/general/ShowOptionsNGRA-v2";
import TableBike from "../../../components/performances/bike/TableBike";
import PrintClass from "../../../components/general/Print";

export class Bike extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      dataR: [],
      dataNR: [],
      labelsR: [],
      labelsN: [],
      tabsMenu: ["Réparables", "Non réparables"],
      tabsIndex: 0,
      tabsMenuType: ["<50cm3", "50-125cm3", ">125 cm3"],
      tabsIndexType: 0,
      network: 0,
      region: 0,
      group: 0,
      agency: 0,
      user: 0,
      dateStart: "",
      dateEnd: "",
      company: 0,
      natureEvent: null,
      natureExpertise: null,
    };

    this.refPrintTabsBike = React.createRef();
  }

  handleDataChange = (data) => {
    this.setState(
      {
        network: data.network,
        group: data.group,
        region: data.region,
        agency: data.agency,
        user: data.user,
        dateStart: data.dateStart,
        dateEnd: data.dateEnd,
        company: data.company,
        natureEvent: data.natureEvent,
        natureExpertise: data.natureExpertise,
      },
      () => {
        this.getData();
      }
    );
  };

  getData = () => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        axiosConfig("performances/bike/get-data", {
          data: {
            network: this.state.network,
            group: this.state.group,
            region: this.state.region,
            agency: this.state.agency,
            user: this.state.user,
            dateStart: this.state.dateStart,
            dateEnd: this.state.dateEnd,
            company: this.state.company,
            natureEvent: this.state.natureEvent,
            natureExpertise: this.state.natureExpertise,
          },
        })
          .then((res) => {
            if (res.data.success) {
              this.setState({
                isLoad: false,
                dataR: res.data.dataR,
                dataNR: res.data.dataNR,
                labelsR: res.data.labelsR,
                labelsNR: res.data.labelsNR,
              });
            } else {
              this.setState(
                {
                  isLoad: false,
                  dataR: [],
                  dataNR: [],
                  labelsR: [],
                  labelsNR: [],
                },
                () => alert("Une erreur est survenue")
              );
            }
          })
          .catch(() => {
            this.setState(
              {
                isLoad: false,
                dataR: [],
                dataNR: [],
                labelsR: [],
                labelsNR: [],
              },
              () => alert("Une erreur est survenue")
            );
          });
      }
    );
  };

  render() {
    return (
      <>
        <FadeIn>
          <Nav title="2 Roues" className="mb-10 mb-20-xl" />
          <ShowOptionsNGRA
            isChange={true}
            className="mb-10 mb-20-xl"
            onDataChange={this.handleDataChange}
            isCompany={true}
            isUser={true}
            isDateStart={true}
            isDateEnd={true}
            isNatureEvent={true}
            isNatureExpertise={true}
            defaultNatureEvent={[8, 9, 15]}
            defaultNatureExpertise={[1, 2, 3, 5, 7, 9]}
            visibilityRole={this.props.visibilityRole}
            visibilityType={this.props.visibilityType}
            preloadRole={this.props.preloadRole}
            preloadType={this.props.preloadType}
            limitUserType={this.props.limitUserType}
            limitUserRole={this.props.limitUserRole}
            isAll={true}
            userType={[1, 2, 3]}
          />
          <TabsCustom>
            <TabsCustomHeader noCard className={"mb-10 mb-20-xl"}>
              {this.state.tabsMenu.map((element, key) => (
                <TabsCustomMenu
                  noCard
                  disabled={this.props.isLoader}
                  key={key}
                  title={element}
                  active={this.state.tabsIndex === key}
                  onClick={(e) => {
                    this.setState({ tabsIndex: key });
                  }}
                />
              ))}
            </TabsCustomHeader>
            <TabsCustomBody noCard>
              <TabsCustomItem active={true}>
                <TabsCustom>
                  <TabsCustomHeader noCard className={"mb-10 mb-20-xl"}>
                    {this.state.tabsMenuType.map((element, key) => (
                      <TabsCustomMenu
                        noCard
                        disabled={this.props.isLoader}
                        key={key}
                        title={element}
                        active={this.state.tabsIndexType === key}
                        onClick={(e) => {
                          this.setState({ tabsIndexType: key });
                        }}
                      />
                    ))}
                  </TabsCustomHeader>
                  <TabsCustomBody noCard>
                    <TabsCustomItem active={true}>
                      <div ref={this.refPrintTabsBike}>
                        {this.state.tabsIndex === 0 ? (
                          <>
                            {this.state.dataR.length &&
                            Object.keys(
                              this.state.dataR[this.state.tabsIndexType]
                            ).length ? (
                              <TableBike
                                isPrint={this.state.isPrint}
                                name={
                                  this.state.tabsMenuType[
                                    this.state.tabsIndexType
                                  ]
                                }
                                data={
                                  this.state.dataR[this.state.tabsIndexType]
                                }
                                labels={this.state.labelsR}
                                dateEnd={this.state.dateEnd}
                              />
                            ) : null}
                          </>
                        ) : (
                          <>
                            {this.state.dataNR.length &&
                            Object.keys(
                              this.state.dataNR[this.state.tabsIndexType]
                            ).length ? (
                              <TableBike
                                isPrint={this.state.isPrint}
                                name={
                                  this.state.tabsMenuType[
                                    this.state.tabsIndexType
                                  ]
                                }
                                data={
                                  this.state.dataNR[this.state.tabsIndexType]
                                }
                                labels={this.state.labelsNR}
                                dateEnd={this.state.dateEnd}
                              />
                            ) : null}
                          </>
                        )}
                      </div>
                    </TabsCustomItem>
                  </TabsCustomBody>
                </TabsCustom>
              </TabsCustomItem>
            </TabsCustomBody>
          </TabsCustom>
          <PrintClass
            refs={this.state.isPrint ? [this.refPrintTabsBike] : []}
            title={"2 Roues"}
            fileName={"2 Roues"}
            onPrint={(print) =>
              this.setState({ isPrint: print, isLoad: print })
            }
            width={1500}
          />
        </FadeIn>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}

export default Bike;
