import React from "react";
import { Card, FullModal, Status } from "ui-kit-ck-consultant";

const ModalAudit = (props) => {
  const style = {
    div: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      margin: "10",
    },
    span: {
      paddingLeft: "5px",
    },
  };
  let displayAudit;
  let details = [];

  if (props.item) {
    for (const [key, value] of Object.entries(props.item)) {
      details.push({ name: key, value: value });
    }
    details = details.filter(
      (it) =>
        it.name !== "data" &&
        it.name !== "length" &&
        it.name !== "active" &&
        it.name !== "id" &&
        it.name !== "shortComment"
    );
    details = details.map((it) => {
      if (it.name === "date" || it.name === "user") {
        it.name = it.name === "date" ? "Date: " : "Expert: ";
      } else if (it.name === "type" || it.name === "reference") {
        it.name = it.name === "type" ? "Type d'Audit: " : "Référence dossier: ";
      } else if (it.name === "comment") {
        it.name = "Commentaire: ";
      } else if (it.name === "value") {
        it.name = "Pourcentage d'atteinte: ";
        it.value = it.value + " %";
      }
      return it;
    });

    let tabData = [];
    let data = JSON.parse(props.item.data);

    for (const [key, value] of Object.entries(data)) {
      tabData.push({ name: key, value: value });
    }
    let type = tabData.find((o) => o.name === "type");
    let file = require(`../../../../../data/audit/${"audit-" + type.value + ".json"
      }`);
    let idx = 1;
    displayAudit = file.group.map((el) => {
      return (
        <div key={el.title}>
          <h2>{el.title}</h2>
          {el.data.map((question) => {
            let answer = tabData.find((o) => o.name === `radio-${idx}`) ? tabData.find((o) => o.name === `radio-${idx}`).value : null;
            if (answer === "0" || answer === "1") {
              answer = answer === "0" ? "Non" : "Oui";
            } else answer = "N.C";
            idx++;
            return (
              <Card
                key={question[0]}
                className="bg-gray m-10"
                style={style.div}
              >
                <span>{question[0]}</span>
                <Status className={(answer === "Non" ? "bg-red" : answer === "Oui" ?  "bg-green" : "bg-blue") + " ml-10 nowrap"} text={answer} />
              </Card>
            );
          })}
        </div>
      );
    });
  }

  const component = (
    <>
      {props.item && details ? (
        <>
          {details.map((it) => {
            return (
              <div key={it.name}>
                <h2>{it.name}</h2>
                <Card className="bg-gray m-10">
                  <span>{it.value}</span>
                </Card>
              </div>
            );
          })}
        </>
      ) : null}
      {displayAudit ? displayAudit : null}
    </>
  );

  return (
    <FullModal title="Détails Audit" show={props.show} onClose={props.hide}>
      {component}
    </FullModal>
  );
};

export default ModalAudit;
