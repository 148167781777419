import React from "react";
import {
  Card,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  TabsCustom,
  TabsCustomHeader,
  TabsCustomItem,
  TabsCustomMenu,
} from "ui-kit-ck-consultant";
import moment from "moment";

import handleSortClick from "../../../utils/handleSortClick";

export default class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabsIndex: 0,
      tabsMenu: [
        {
          title:
            'Liste des dossiers avec au moins de 2 lignes de suivi "SD RECU A TRAITER **"',
          name: "test",
        },
        {
          title: 'Liste des dossiers avec au moins de 2 lignes de suivi "**"',
          name: "test",
        },
        {
          title: 'Liste des dossiers avec au moins de 2 lignes de suivi "$$"',
          name: "test",
        },
      ],
      order: null,
    };

    this.sortRef = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
    ];
  }

  handleSortClick = (desc, column) => {
    this.setState({ order: handleSortClick(this.sortRef, desc, column) });
  };

  getCard = (title) => {
    let folders = this.props.data[this.state.tabsIndex];

    if (this.state.order) {
      folders.sort((a, b) => {
        let aX = a[this.state.order.name] || "";
        let bX = b[this.state.order.name] || "";

        if (typeof aX == "number" && typeof bX == "number") {
          return this.state.order.desc ? aX - bX : bX - aX;
        } else {
          aX = aX.toString();
          bX = bX.toString();
          return this.state.order.desc
            ? aX.normalize().localeCompare(bX.normalize())
            : bX.normalize().localeCompare(aX.normalize());
        }
      });
    }

    return (
      <Card
        className="mb-10 mb-20-xl overflow-x-auto"
        title={`${title} (${
          this.props.data[this.state.tabsIndex].length
        } dossiers)`}
      >
        <Table isCSV>
          <Thead>
            <Tr>
              <Th
                ref={this.sortRef[0]}
                sort={true}
                onSort={(desc) => this.handleSortClick(desc, 0)}
                nameSort={"reference"}
              >
                Numéro de dossier
              </Th>
              <Th
                ref={this.sortRef[1]}
                sort={true}
                onSort={(desc) => this.handleSortClick(desc, 1)}
                nameSort={"dateMission"}
              >
                Date mission
              </Th>
              <Th
                ref={this.sortRef[2]}
                sort={true}
                onSort={(desc) => this.handleSortClick(desc, 2)}
                nameSort={"sinister"}
              >
                Numéro de sinistre
              </Th>
              <Th
                ref={this.sortRef[3]}
                sort={true}
                onSort={(desc) => this.handleSortClick(desc, 3)}
                nameSort={"agencyName"}
              >
                Agence
              </Th>
              <Th
                ref={this.sortRef[4]}
                sort={true}
                onSort={(desc) => this.handleSortClick(desc, 4)}
                nameSort={"regionName"}
              >
                Région
              </Th>
              <Th
                ref={this.sortRef[5]}
                sort={true}
                onSort={(desc) => this.handleSortClick(desc, 5)}
                nameSort={"expertName"}
              >
                Expert
              </Th>
              <Th
                ref={this.sortRef[6]}
                sort={true}
                onSort={(desc) => this.handleSortClick(desc, 6)}
                nameSort={"assistantName"}
              >
                Assistante
              </Th>
              <Th
                ref={this.sortRef[7]}
                sort={true}
                onSort={(desc) => this.handleSortClick(desc, 7)}
                nameSort={"companyName"}
              >
                Compagnie
              </Th>
              <Th
                ref={this.sortRef[8]}
                sort={true}
                onSort={(desc) => this.handleSortClick(desc, 8)}
                nameSort={"minDate"}
              >
                Date premier suivi
              </Th>
              <Th
                ref={this.sortRef[9]}
                sort={true}
                onSort={(desc) => this.handleSortClick(desc, 9)}
                nameSort={"maxDate"}
              >
                Date dernier suivi
              </Th>
              <Th
                ref={this.sortRef[10]}
                sort={true}
                onSort={(desc) => this.handleSortClick(desc, 10)}
                nameSort={"count"}
              >
                Nombre de ligne de suivi
              </Th>
              <Th
                ref={this.sortRef[11]}
                sort={true}
                onSort={(desc) => this.handleSortClick(desc, 11)}
                nameSort={"natureExpertise"}
              >
                Nature expertise
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {folders.map((element, idx) => (
              <Tr key={idx}>
                <Td>{element.reference}</Td>
                <Td>{moment(element.dateMission).format("DD/MM/YYYY")}</Td>
                <Td>{element.sinister}</Td>
                <Td>{element.agencyName}</Td>
                <Td>{element.regionName}</Td>
                <Td>{element.expertName}</Td>
                <Td>{element.assistantName}</Td>
                <Td>{element.companyName}</Td>
                <Td>{moment(element.minDate).format("DD/MM/YYYY")}</Td>
                <Td>{moment(element.maxDate).format("DD/MM/YYYY")}</Td>
                <Td>{element.count}</Td>
                <Td>{element.natureExpertise}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Card>
    );
  };

  render() {
    if (!this.props.data.length) {
      return null;
    }

    return (
      <>
        <TabsCustom>
          <TabsCustomHeader>
            {this.state.tabsMenu.map((element, idx) => (
              <TabsCustomMenu
                key={idx}
                title={element.title}
                active={this.state.tabsIndex === idx}
                onClick={() =>
                  this.setState({
                    tabsIndex: idx,
                  })
                }
              />
            ))}
          </TabsCustomHeader>

          <TabsCustomItem active>
            {this.getCard(this.state.tabsMenu[this.state.tabsIndex].title)}
          </TabsCustomItem>
        </TabsCustom>
      </>
    );
  }
}
