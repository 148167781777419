import React, { Component } from "react";
import { BarChart } from "ui-kit-ck-consultant";

export class BarChartAverageProfit extends Component {
  render() {
    return (
      <BarChart
        height={this.props.height}
        title={"Gain Moyen"}
        digits={2}
        format="currency"
        legendDisplay={false}
        dataSet={[
          {
            label: "en € (H.T)",
            data: this.props.data.averageGain,
            type: "bar",
            color: "#6067AE",
            dataLabelsOptions: { display: true },
          },
        ]}
        datalabels2={[
          {
            display: true,
            align: "top",
            anchor: "end",
            clamp: true,
            color: "#0162AD",
            font: {
              size: "9",
            },
            formatter: (value) => Math.round(value),
          },
        ]}
      />
    );
  }
}

export default BarChartAverageProfit;
