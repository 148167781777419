import React, { Component } from "react";
import {
  FormInput,
  FormTextarea,
  FormCheckbox,
  Button,
  Loader,
} from "ui-kit-ck-consultant";
import moment from "moment";
import { Row, Col } from "react-flexbox-grid";

import axiosConfig from "../../utils/axiosConfig";

export default class FormUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      data: [],
      isModal: false,
      date: "",
      title: "",
      content: "",
      networks: [],
      groups: [],
      id: "",
    };
  }

  componentDidMount() {
    this.getNetworks();
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (idx, type, e, id) => {
    let { checked } = e.target;
    let groups = this.state.groups;
    let networks = this.state.networks;
    //If a network is checked
    if (type) {
      networks[idx].checked = checked;
      groups.forEach((group) => {
        if (group.network === id) group.checked = checked;
      });
    }
    //If a Group is checked
    else {
      groups[idx].checked = checked;
      //If all groups of a network are NOT checked
      if (
        !checked &&
        groups.filter(
          (group) => group.checked && group.network === groups[idx].network
        ).length === 0
      ) {
        networks.forEach((network) => {
          if (network.id === id) {
            network.checked = checked;
          }
        });
      }
      let network = networks.filter(network => network.id === id)[0]
      //If a group is checked and the network he belongs isn't
      if(checked && !network.checked) {
        networks.forEach((network) => {
          if (network.id === id) {
            network.checked = checked;
          }
        });
      }
      
    }
    this.setState({ networks, groups });
  };

  getNetworks() {
    this.setState({ isLoad: true });
    axiosConfig("updates/get-networks").then((res) => {
      if (res.data.success) {
        this.setState(
          {
            isLoad: false,
            networks: res.data.data.network,
            groups: res.data.data.group,
          },
          () => this.setOldData()
        );
      }
    });
  }

  handleValidClick() {
    const networks = this.state.networks
      .filter((el) => el.checked)
      .map((el) => el.id);
    const groups = this.state.groups
      .filter((el) => el.checked)
      .map((el) => el.id);
    if (
      this.state.date &&
      this.state.title &&
      this.state.content &&
      networks.length
    ) {
      if (this.props.oldUpdate) {
        axiosConfig("updates/update-update", {
          data: {
            date: this.state.date,
            title: this.state.title,
            content: this.state.content,
            groups: groups,
            networks: networks,
            id: this.state.id,
          },
        }).then((res) => {
          if (res.data.success) {
            this.props.refresh();
            this.props.onClose();
          }
        });
      } else {
        axiosConfig("updates/add-update", {
          data: {
            date: this.state.date,
            title: this.state.title,
            content: this.state.content,
            groups: groups,
            networks: networks,
          },
        }).then((res) => {
          if (res.data.success) {
            this.props.refresh();
            this.props.onClose();
          }
        });
      }
    } else alert("Veuillez remplir tous les champs");
  }

  setOldData() {
    if (this.props.oldUpdate) {
      const groups = this.props.oldUpdate.u_fk_group_id
        .split(",")
        .map((group) => parseInt(group));
      const networks = this.props.oldUpdate.u_fk_network_id
        .split(",")
        .map((network) => parseInt(network));
      const groupState = this.state.groups;
      const networkState = this.state.networks;

      groupState.forEach((group) => {
        if (groups.includes(group.id)) group.checked = true;
      });
      networkState.forEach((network) => {
        if (networks.includes(network.id)) network.checked = true;
      });

      this.setState({
        content: this.props.oldUpdate.content,
        date: moment(this.props.oldUpdate.date, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
        groups: groupState,
        networks: networkState,
        id: this.props.oldUpdate.id,
        title: this.props.oldUpdate.title,
      });
    }
  }

  render() {
    return (
      <>
        <Loader show={this.state.isLoad} />
        <FormInput
          title={"Titre"}
          placeholder={"Titre"}
          name="title"
          value={this.state.title}
          onChange={this.handleInputChange}
        />
        <FormInput
          className={"mt-20"}
          type="date"
          title={"Date"}
          name="date"
          value={this.state.date}
          onChange={this.handleInputChange}
          max={moment().format("YYYY-MM-DD")}
        />
        <FormTextarea
          className={"mt-20"}
          title="Contenu"
          placeholder={"Contenu de la mise à jour"}
          name="content"
          value={this.state.content}
          onChange={this.handleInputChange}
        />
        <Row>
          <Col lg={12} className={"mt-20"}>
            <span style={{ fontSize: 14, fontWeight: "bold" }}>Réseaux</span>
          </Col>
          {this.state.networks.map((element, idx) => {
            return (
              <Col key={idx} lg={4}>
                <FormCheckbox
                  className={"checkBoxFont mt-20"}
                  checked={element.checked}
                  onChange={(e) =>
                    this.handleCheckboxChange(idx, true, e, element.id)
                  }
                  text={element.name}
                />
              </Col>
            );
          })}
          <Col lg={12} className={"mt-20"}>
            <span style={{ fontSize: 14, fontWeight: "bold" }}>Groupes</span>
          </Col>
          {this.state.groups.map((element, idx) => {
            return (
              <Col key={idx} lg={4}>
                <FormCheckbox
                  className={"checkBoxFont mt-20"}
                  checked={element.checked}
                  onChange={(e) =>
                    this.handleCheckboxChange(idx, false, e, element.network)
                  }
                  text={element.name}
                />
              </Col>
            );
          })}
        </Row>

        <Button
          className={"mt-20"}
          text={"Valider"}
          onClick={() => this.handleValidClick()}
        />
      </>
    );
  }
}
