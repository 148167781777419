import React, { Component } from "react";
import { BarChart } from "ui-kit-ck-consultant";

export class LineChartNbFileExpert extends Component {
  render() {
    return (
      <BarChart
        height={this.props.height}
        title={"Nombre de dossiers / expert"}
        digits={0}
        legendDisplay={false}
        dataSet={this.props.data.foldersByExpert}
      />
    );
  }
}

export default LineChartNbFileExpert;
