import React from "react";
import {
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  ButtonIcon,
  Modal,
  TabsCustom,
  TabsCustomHeader,
  TabsCustomMenu,
  TabsCustomBody,
  TabsCustomItem,
  Loader,
  Status,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import {
  getCurrencyValue,
  getPercentageValue,
} from "../../../../utils/general";
import axiosConfig from "../../../../utils/axiosConfig";

export default class CardALLIANZ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      // Tabs
      tabsMenu: ["Standard", "Évènement climatiques", "RC-PJ", "BONUS"],
      tabsIndex: 0,
      isLoad: false,
    };
  }

  componentDidMount() {
    this.getPerformance();
  }

  componentDidUpdate = (prevProps) => {
    if (
      JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data) &&
      parseInt(this.props.data.company) === 1
    ) {
      this.getPerformance();
    }
  };

  getPerformance = () => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("/company-performance/allianz", {
        data: {
          dateStart: this.props.data.dateStart,
          dateEnd: this.props.data.dateEnd,
          network: this.props.data.network,
          group: this.props.data.group,
          region: this.props.data.region,
          agency: this.props.data.agency,
          user: this.props.data.user,
          company: this.props.data.company,
        },
      }).then((res, err) => {
        if (res.data.success) {
          this.setState({
            data: res.data.data,
            isLoad: false,
          });
        } else {
          this.setState(
            {
              isLoad: false,
            },
            () => alert("Une erreur est survenue")
          );
        }
      });
    });
  };
  getHead = () => {
    return (
      <Tr>
        <Th></Th>
        <Th>Janv.</Th>
        <Th>Fév.</Th>
        <Th>Mars</Th>
        <Th>Avril</Th>
        <Th>Mai</Th>
        <Th>Juin</Th>
        <Th>Juil.</Th>
        <Th>Août</Th>
        <Th>Sept.</Th>
        <Th>Oct.</Th>
        <Th>Nov.</Th>
        <Th>Déc.</Th>
        <Th className="nowrap">M-1</Th>
        <Th className="nowrap">N-1</Th>
      </Tr>
    );
  };

  getValue = (value, type) => {
    if (type === "currency") {
      return getCurrencyValue(value, 0);
    } else if (type === "percentage") {
      return getPercentageValue(value, 0);
    } else if (type === "percentage_2") {
      return getPercentageValue(value, 2);
    } else {
      return parseFloat(value).toFixed(0);
    }
  };

  getColor = (value, isEvolution) => {
    if (value > 0) {
      if (isEvolution) {
        return "bg-green-light black";
      } else {
        return "bg-red-light black";
      }
    } else if (value < 0) {
      if (isEvolution) {
        return "bg-red-light black";
      } else {
        return "bg-green-light black";
      }
    } else {
      return "bg-blue-light black";
    }
  };

  getLine = (
    name,
    key,
    message,
    type = null,
    isEvolution = true,
    goal = null
  ) => {
    if (!this.state.data) {
      return null;
    }

    return (
      <Tr>
        <Th>
          <div className="d-flex" style={{ width: "250px" }}>
            <span className="mt-auto mb-auto">{name}</span>
            <ButtonIcon
              small
              className="blue mt-auto mb-auto"
              onClick={() => this.setState({ isModal: true, message })}
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </ButtonIcon>
          </div>
        </Th>
        {this.getCol(0, key, type, goal, false)}
        {this.getCol(1, key, type, goal, key === "countR_Ecr_no_cumulate")}
        {this.getCol(2, key, type, goal, key === "countR_Ecr_no_cumulate")}
        {this.getCol(3, key, type, goal, key === "countR_Ecr_no_cumulate")}
        {this.getCol(4, key, type, goal, key === "countR_Ecr_no_cumulate")}
        {this.getCol(5, key, type, goal, key === "countR_Ecr_no_cumulate")}
        {this.getCol(6, key, type, goal, key === "countR_Ecr_no_cumulate")}
        {this.getCol(7, key, type, goal, key === "countR_Ecr_no_cumulate")}
        {this.getCol(8, key, type, goal, key === "countR_Ecr_no_cumulate")}
        {this.getCol(9, key, type, goal, key === "countR_Ecr_no_cumulate")}
        {this.getCol(10, key, type, goal, key === "countR_Ecr_no_cumulate")}
        {this.getCol(11, key, type, goal, key === "countR_Ecr_no_cumulate")}
        <Td>
          <Status
            className={this.getColor(
              this.state.data[12].data[key],
              isEvolution
            )}
            text={getPercentageValue(this.state.data[12].data[key], 0)}
          />
        </Td>
        <Td>
          <Status
            className={this.getColor(
              this.state.data[13].data[key],
              isEvolution
            )}
            text={getPercentageValue(this.state.data[13].data[key], 0)}
          />
        </Td>
      </Tr>
    );
  };

  downloadCSV = () => {
    this.setState({ isLoad: true }, () => {
      let data = `
      ${this.getDataCSV(this.dataTable1)}\n\n
      ${this.getDataCSV(this.dataTable2)}\n\n
      ${this.getDataCSV(this.dataTable3)}\n\n
      ${this.getDataCSV(this.dataTable4)}\n\n
      ${this.getDataCSV(this.dataTable5)}\n\n
      ${this.getDataCSV(this.dataTable6)}\n\n
      ${this.getDataCSV(this.dataTable7)}\n\n
      ${this.getDataCSV(this.dataTable8)}\n\n
      ${this.getDataCSV(this.dataTable9)}\n\n
      ${this.getDataCSV(this.dataTable10)}\n\n
      ${this.getDataCSV(this.dataTable11)}\n\n
      ${this.getDataCSV(this.dataTable12)}\n\n
      ${this.getDataCSV(this.dataTable13)}\n\n
      ${this.getDataCSV(this.dataTable14)}\n\n
      `;

      const element = document.createElement("a");
      // eslint-disable-next-line no-undef
      const file = new Blob([data], {
        type: "text/csv;charset=utf-8",
      });
      element.href = URL.createObjectURL(file);
      element.download = "extract.csv";
      document.body.appendChild(element);
      element.click();

      setTimeout(() => {
        this.setState({ isLoad: false });
      }, 4000);
    });
  };

  getCol = (idx, key, type, goal, isNoCumulate = false) => {
    const isValid =
      parseInt(moment(this.props.data.dateEnd).format("YYYY")) !==
        parseInt(moment().format("YYYY")) ||
      (parseInt(moment(this.props.data.dateEnd).format("YYYY")) ===
        parseInt(moment().format("YYYY")) &&
        parseInt(moment().format("M")) - 1 >= idx)
        ? true
        : false;

    return (
      <Td
        className={
          isValid ? this.getGoal(this.state.data[idx].data[key], goal) : ""
        }
      >
        {isValid
          ? this.getValue(
              isNoCumulate
                ? this.state.data[idx].data[key] -
                    this.state.data[idx - 1].data[key]
                : this.state.data[idx].data[key],
              type
            )
          : null}
      </Td>
    );
  };

  getGoal = (value, goal) => {
    return goal
      ? goal.type === ">="
        ? value >= goal.value
          ? "bg-green-light"
          : "bg-red-light"
        : value <= goal.value
        ? "bg-green-light"
        : "bg-red-light"
      : "";
  };

  getDataCSV = (table) => {
    let data = "";

    table.forEach((line) => {
      data += `${line[0]};${
        parseInt(this.state.year) !== parseInt(moment().format("YYYY")) ||
        (parseInt(this.state.year) === parseInt(moment().format("YYYY")) &&
          parseInt(moment().format("M")) - 1 >= 0)
          ? this.getValue(this.state.data[0].data[line[1]], null)
          : ""
      };${
        parseInt(this.state.year) !== parseInt(moment().format("YYYY")) ||
        (parseInt(this.state.year) === parseInt(moment().format("YYYY")) &&
          parseInt(moment().format("M")) - 1 >= 1)
          ? this.getValue(this.state.data[1].data[line[1]], null)
          : ""
      };${
        parseInt(this.state.year) !== parseInt(moment().format("YYYY")) ||
        (parseInt(this.state.year) === parseInt(moment().format("YYYY")) &&
          parseInt(moment().format("M")) - 1 >= 2)
          ? this.getValue(this.state.data[2].data[line[1]], null)
          : ""
      };${
        parseInt(this.state.year) !== parseInt(moment().format("YYYY")) ||
        (parseInt(this.state.year) === parseInt(moment().format("YYYY")) &&
          parseInt(moment().format("M")) - 1 >= 3)
          ? this.getValue(this.state.data[3].data[line[1]], null)
          : ""
      };${
        parseInt(this.state.year) !== parseInt(moment().format("YYYY")) ||
        (parseInt(this.state.year) === parseInt(moment().format("YYYY")) &&
          parseInt(moment().format("M")) - 1 >= 4)
          ? this.getValue(this.state.data[4].data[line[1]], null)
          : ""
      };${
        parseInt(this.state.year) !== parseInt(moment().format("YYYY")) ||
        (parseInt(this.state.year) === parseInt(moment().format("YYYY")) &&
          parseInt(moment().format("M")) - 1 >= 5)
          ? this.getValue(this.state.data[5].data[line[1]])
          : ""
      };${
        parseInt(this.state.year) !== parseInt(moment().format("YYYY")) ||
        (parseInt(this.state.year) === parseInt(moment().format("YYYY")) &&
          parseInt(moment().format("M")) - 1 >= 6)
          ? this.getValue(this.state.data[6].data[line[1]], null)
          : ""
      };${
        parseInt(this.state.year) !== parseInt(moment().format("YYYY")) ||
        (parseInt(this.state.year) === parseInt(moment().format("YYYY")) &&
          parseInt(moment().format("M")) - 1 >= 7)
          ? this.getValue(this.state.data[7].data[line[1]], null)
          : ""
      };${
        parseInt(this.state.year) !== parseInt(moment().format("YYYY")) ||
        (parseInt(this.state.year) === parseInt(moment().format("YYYY")) &&
          parseInt(moment().format("M")) - 1 >= 8)
          ? this.getValue(this.state.data[8].data[line[1]], null)
          : ""
      };${
        parseInt(this.state.year) !== parseInt(moment().format("YYYY")) ||
        (parseInt(this.state.year) === parseInt(moment().format("YYYY")) &&
          parseInt(moment().format("M")) - 1 >= 9)
          ? this.getValue(this.state.data[9].data[line[1]], null)
          : ""
      };${
        parseInt(this.state.year) !== parseInt(moment().format("YYYY")) ||
        (parseInt(this.state.year) === parseInt(moment().format("YYYY")) &&
          parseInt(moment().format("M")) - 1 >= 10)
          ? this.getValue(this.state.data[10].data[line[1]], null)
          : ""
      };${
        parseInt(this.state.year) !== parseInt(moment().format("YYYY")) ||
        (parseInt(this.state.year) === parseInt(moment().format("YYYY")) &&
          parseInt(moment().format("M")) - 1 >= 11)
          ? this.getValue(this.state.data[11].data[line[1]], null)
          : ""
      };${this.getValue(
        this.state.data[12].data[line[1]],
        null
      )};${this.getValue(this.state.data[13].data[line[1]], null)}\n`;
    });

    return data;
  };

  render() {
    this.dataTable1 = [
      [
        "Nb rapports réparables",
        "countR_Ecr",
        "Rapports économiquement réparables",
        null,
        true,
      ],
      [
        "Nb rapports réparables isolé",
        "countR_Ecr_no_cumulate",
        "Rapports économiquement réparables",
        null,
        true,
      ],
      [
        "Nb rapports en estimation",
        "countR_estimate",
        `Rapports économiquement réparables avec nature expertise="estimation à dire d'experts"`,
        null,
        false,
      ],
      [
        "Nbre de rapports avec Garages Agrées (Capsauto/autonéo/fivestar/acoat selected..)",
        "countR_Ecr_approved",
        `"Rapports économiquement réparables 
                        avec facture établie par les garages agrées cités
                        -> rapports en estimation exclus
                        "`,
        null,
        true,
      ],
      [
        "Nbre de rapports avec garages non agrées",
        "countR_Ecr_noApproved",
        `"Rapports économiquement réparables 
      avec facture établie par les garages non agrées
      -> rapports en estimation exclus
      "`,
        null,
        false,
      ],
      ["% rapports réparables", "percentageR_Ecr", `A/B`, "percentage", true],
      [
        "Montant moyen dommages HT non écrêté",
        "costR_totalReport",
        `"Dommages tous postes de couts chiffrés dans le rapport (montant expertise, hors sous réserve de garantie)
      "`,
        "currency",
      ],
      [
        "Montant moyen dommages HT - écrêté -  ≥50 à ≤ 1500€",
        "costR_Ecr_totalReport50_1500",
        `"Dommages: tous postes de couts chiffrés dans le rapport 
      + ventilation selon tranches ci après
     ≥50 à ≤ 1500€
     ≥50 à ≤ 2500€
     ≥50 à ≤5000€"`,
        "currency",
        false,
      ],
      [
        "Montant moyen dommages HT - écrêté -  ≥50 à ≤ 2500€",
        "costR_Ecr_totalReport50_2500",
        `"Dommages: tous postes de couts chiffrés dans le rapport 
      + ventilation selon tranches ci après
     ≥50 à ≤ 1500€
     ≥50 à ≤ 2500€
     ≥50 à ≤5000€"`,
        "currency",
        false,
      ],
      [
        "Montant moyen dommages HT - écrêté -  ≥50 à ≤ 5000€",
        "costR_Ecr_totalReport50_5000",
        `"Dommages: tous postes de couts chiffrés dans le rapport 
      + ventilation selon tranches ci après
     ≥50 à ≤ 1500€
     ≥50 à ≤ 2500€
     ≥50 à ≤5000€"`,
        "currency",
        false,
      ],
      [
        "Délai moyen remise rapport",
        "delayR_Ecr_report",
        `delai entre Date réception Ordre Mission et Date de dépôt du dernier rapport d'expertise`,
        null,
        false,
      ],
      [
        "Délai moyen mission/date expertise",
        "delayR_Ecr_missionExpertise",
        `Délai moyen entre la date de réception de l'Ordre de Mission  et la date de réalisation de la 1ère expertise`,
        null,
        false,
      ],
      [
        "Délai moyen entre réception facture / dépôt de rapport",
        "delayR_Ecr_factureReport",
        `"Délai moyen entre la date de réception de la facture et la date de dépôt du dernier rapport d'expertise
      (si date de récpetion de facture non topée = date de facture)"`,
        null,
        false,
      ],
      [
        "% de délais remis (OM-RE) en <= 28 jours calendaires",
        "delayR_Ecr_report28",
        `"Nbre de dossiers avec Délai moyen remise rapport /Nbre total de rapport d'expertise réparables (yc EAD)déposés
      ≤ 28 jours calendaires
      ≤ 56 jours calendaires
      ≤ 74 jours calendaires"`,
        "percentage",
        false,
      ],
      [
        "% de délais remis (OM-RE) en <= 56 jours calendaires",
        "delayR_Ecr_report56",
        `"Nbre de dossiers avec Délai moyen remise rapport /Nbre total de rapport d'expertise réparables (yc EAD)déposés
      ≤ 28 jours calendaires
      ≤ 56 jours calendaires
      ≤ 74 jours calendaires"`,
        "percentage",
        false,
      ],
      [
        "% de délais remis (OM-RE) en <= 74 jours calendaires",
        "delayR_Ecr_report74",
        `"Nbre de dossiers avec Délai moyen remise rapport /Nbre total de rapport d'expertise réparables (yc EAD)déposés
      ≤ 28 jours calendaires
      ≤ 56 jours calendaires
      ≤ 74 jours calendaires"`,
        "percentage",
        false,
      ],
      [
        "% de RE avec PRE",
        "percentageR_Ecr_PRE",
        `Nombre de RE avec pièces de réemploi/A`,
        "percentage_2",
        true,
      ],
      [
        "% de RE avec PRE dont le panier tarif des PRE > 100€ HT",
        "percentageR_Ecr_costPRE",
        `(Nombre de RE avec pièces de réemploi dont panier de l'ensemble des PRE du dossier  ≥ 100€ HT /A`,
        "percentage_2",
        true,
      ],
    ];

    this.dataTable2 = [
      [
        "Nb rapports EAD",
        "countEAD_Ecr",
        `Nombre de rapports émis suite à expertise faite en EAD (réparateur ou assuré)`,
        null,
        true,
      ],
      [
        "Montant moyen EAD HT",
        "costEAD_Ecr_totalReport",
        `"Dommages tous postes de couts chiffrés dans le rapport (montant expertise, hors sous réserve de garantie)
"`,
        "currency",
        false,
      ],
      [
        "% EAD / total réparables",
        "percentageEAD_Ecr",
        `C/A`,
        "percentage",
        true,
      ],
      [
        "Délai moyen de remise rapport",
        "delayEAD_Ecr_report",
        `delai entre Date réception Ordre Mission et Date du dernier dépôt du rapport d'expertise`,
        null,
        false,
      ],
      [
        "% de délais remis (OM-RE) <= 28 jours calendaires",
        "delayEAD_Ecr_report28",
        `"Nbre de dossiers EAD avec Délai moyen remise rapport / Nbre total de rapports d'expertise EAD déposés
≤ 28 jours calendaires
≤ 56 jours calendaires
≤ 74 jours calendaires"`,
        "percentage",
        false,
      ],
      [
        "% de délais remis (OM-RE) <= 56 jours calendaires",
        "delayEAD_Ecr_report56",
        `"Nbre de dossiers EAD avec Délai moyen remise rapport / Nbre total de rapports d'expertise EAD déposés
≤ 28 jours calendaires
≤ 56 jours calendaires
≤ 74 jours calendaires"`,
        "percentage",
        false,
      ],
      [
        "% de délais remis (OM-RE) <= 74 jours calendaires",
        "delayEAD_Ecr_report74",
        `"Nbre de dossiers EAD avec Délai moyen remise rapport / Nbre total de rapports d'expertise EAD déposés
≤ 28 jours calendaires
≤ 56 jours calendaires
≤ 74 jours calendaires"`,
        "percentage",
        false,
      ],
    ];

    this.dataTable3 = [
      [
        "Nb rapports irréparables",
        "countNR_Ecr",
        `Nbre de RE Economiquement  Irréparables issus des VEI + RPV`,
        null,
        false,
      ],
      ["% VEI/RPV", "percentageNR_Ecr", `F/B`, "percentage", false],
      [
        "Montant moyen vrade TTC non écrêté",
        "costNR_VRADE",
        `"Estimation VRADE retenue dans le rapport 
    "`,
        "currency",
        false,
      ],
      [
        "Montant moyen VRADE TTC par Tranche avec volumétrie ≥50 à ≤700€",
        "costNR_Ecr_VRADE50_700",
        `"VRADE chiffrée dans le rapport 
    + ventilation selon tranches ci après
    
    ≥50 à ≤700€
    ≥50 à ≤1500€
    ≥50 à ≤3000€
    ≥700 à ≤3000€
    ≥700 à ≤5000€
    ≥700 à ≤10.000€
    >10.000€"`,
        "currency",
        false,
      ],
      [
        "Montant moyen VRADE TTC par Tranche avec volumétrie ≥50 à ≤1500€",
        "costNR_Ecr_VRADE50_1500",
        `"VRADE chiffrée dans le rapport 
    + ventilation selon tranches ci après
    
    ≥50 à ≤700€
    ≥50 à ≤1500€
    ≥50 à ≤3000€
    ≥700 à ≤3000€
    ≥700 à ≤5000€
    ≥700 à ≤10.000€
    >10.000€"`,
        "currency",
        false,
      ],
      [
        "Montant moyen VRADE TTC par Tranche avec volumétrie ≥50 à ≤3000€",
        "costNR_Ecr_VRADE50_3000",
        `"VRADE chiffrée dans le rapport 
    + ventilation selon tranches ci après
    
    ≥50 à ≤700€
    ≥50 à ≤1500€
    ≥50 à ≤3000€
    ≥700 à ≤3000€
    ≥700 à ≤5000€
    ≥700 à ≤10.000€
    >10.000€"`,
        "currency",
        false,
      ],
      [
        "Montant moyen VRADE TTC par Tranche avec volumétrie ≥700 à ≤3000€",
        "costNR_Ecr_VRADE700_3000",
        `"VRADE chiffrée dans le rapport 
    + ventilation selon tranches ci après
    
    ≥50 à ≤700€
    ≥50 à ≤1500€
    ≥50 à ≤3000€
    ≥700 à ≤3000€
    ≥700 à ≤5000€
    ≥700 à ≤10.000€
    >10.000€"`,
        "currency",
        false,
      ],
      [
        "Montant moyen VRADE TTC par Tranche avec volumétrie ≥700 à ≤5000€",
        "costNR_Ecr_VRADE700_5000",
        `"VRADE chiffrée dans le rapport 
    + ventilation selon tranches ci après
    
    ≥50 à ≤700€
    ≥50 à ≤1500€
    ≥50 à ≤3000€
    ≥700 à ≤3000€
    ≥700 à ≤5000€
    ≥700 à ≤10.000€
    >10.000€"`,
        "currency",
        false,
      ],
      [
        "Montant moyen VRADE TTC par Tranche avec volumétrie ≥700 à ≤10.000€",
        "costNR_Ecr_VRADE700_10000",
        `"VRADE chiffrée dans le rapport 
    + ventilation selon tranches ci après

    ≥50 à ≤700€
    ≥50 à ≤1500€
    ≥50 à ≤3000€
    ≥700 à ≤3000€
    ≥700 à ≤5000€
    ≥700 à ≤10.000€
    >10.000€"`,
        "currency",
        false,
      ],
      [
        "Montant moyen VRADE TTC par Tranche avec volumétrie >10.000€",
        "costNR_Ecr_VRADE10000",
        `"VRADE chiffrée dans le rapport 
    + ventilation selon tranches ci après
    
    ≥50 à ≤700€
    ≥50 à ≤1500€
    ≥50 à ≤3000€
    ≥700 à ≤3000€
    ≥700 à ≤5000€
    ≥700 à ≤10.000€
    >10.000€"`,
        "currency",
        false,
      ],
      [
        "Taux de cession acceptée (VEI/RPV)",
        "percentageNR_Ecr_cession",
        `Nbre de RE VEI/RPV avec Cession à Allianz acceptée par l'assuré`,
        "percentage",
        false,
      ],
      [
        "Age moyen véhicule en nombre d'année pleine.",
        "ageNR_Ecr_vehicle",
        `Calculé à partir de la date de 1ere mise en circulation du véhicule à date de sinistre`,
        null,
        false,
      ],
      [
        "Délai moyen remise rapport",
        "delayNR_Ecr_report",
        `delai entre Date réception Ordre Mission et Date de dépôt du dernier rapport d'expertise`,
        null,
        false,
      ],
      [
        "Délai SD 29",
        "delayNR_Ecr_missionSD29",
        `delai entre Date réception Ordre Mission et Date d'émission du 1er SD29`,
        null,
        false,
      ],
    ];

    this.dataTable4 = [
      ["Nb rapports", "countAll", `Nbre total de RE émis`, null, false],
      [
        "Cout Global du sinistre TTC",
        "costAll_total",
        `[Totalité des couts des RE réparables (montant des dommages)+ VRADE RE irréparables (VEI/RPV)] / B`,
        "currency",
        false,
      ],
      [
        "Délai moyen remise rapport",
        "delayAll_report",
        `(Somme des delays entre Date réception Ordre Mission et Date de dépôt du dernier rapport d'expertise pour l'ensemble des RE)/B`,
        null,
        false,
      ],
      [
        "% de délais remise des RE en jours calendaires",
        "delayAll_report42_74",
        `Nbre de dossiers avec Délai moyen remise rapport  ≥ 42 et ≤ 74 jours calendaires / B`,
        "percentage",
        false,
      ],
      [
        "% de délais remis en jours calendaires",
        "delayAll_report74",
        `Nbre de dossiers avec Délai moyen remise rapport ≤ 74 jours calendaires /B`,
        "percentage",
        false,
      ],
    ];

    this.dataTable5 = [
      ["Nb rapports en carence", "countCarence", "", false],
      [
        "% de rapports en carence",
        "percentageCarence",
        `G/B`,
        "percentage",
        false,
      ],
    ];

    this.dataTable6 = [
      [
        "Nb d'heures total moyen (hors peinture)",
        "countR_Ecr_totalHour",
        `" -Somme des heures de tous les postes de main d'œuvre des RE/A
      -ventilation T1,T2,T3  et M1,M2,M3"`,
        null,
        false,
      ],
      [
        "Nb d'heures de peinture moyen",
        "countR_Ecr_paintHour",
        `Montant des heures de peinture des RE /A`,
        null,
        false,
      ],
      [
        "tarif horaire ingrédient de peinture",
        "costR_Ecr_ingredHourly",
        `Somme des tarifs horaires par RE/A`,
        "currency",
        false,
      ],
      [
        "Taux horaire moyen",
        "costR_Ecr_hourly",
        `"[Somme des couts totaux de MO/somme des heures de MO pour l'ensemble des rapports ]/A"`,
        "currency",
        false,
      ],
    ];

    this.dataTable7 = [
      [
        "Coût moyen pièces détachées (yc petites fournitures)/hors forfaits",
        "costR_Ecr_parts",
        `Cout total des pièces détachées (pour l'ensemble des rapports)/ réparables avec pièces détachées`,
        "currency",
        false,
      ],
      [
        "Nombre RE avec pieces sous réserve de garantie",
        "countR_Ecr_guaranteedParts",
        `"Volume de RE concerné
      Montant €"`,
        null,
        false,
      ],
      [
        "Pieces sous réserve de garantie dans RE",
        "costR_Ecr_guaranteedParts",
        `"Volume de RE concerné
      Montant €"`,
        "currency",
        false,
      ],
      [
        "Coût moyen main d'œuvre",
        "costR_Ecr_workforce",
        `Cout total de l'ensemble des postes main d'œuvre T1,T2, T3 (yc peinture mais hors ingrédient de peinture) (pour l'ensemble des RE)/ réparables avec de la main d'œuvre`,
        "currency",
        false,
      ],
      [
        "Coût moyen peinture",
        "costR_Ecr_paint",
        `Cout total du Poste main d'oeuvre peinture et ingrédient de peinture (pour l'ensemble des RE)/réparables avec peinture`,
        "currency",
        false,
      ],
      [
        "Cout moyen des forfaits (par ex: requalibrage caméra/rétro…)",
        "costR_Ecr_packages",
        `Cout total des forfaits pour l'ensemble des RE/ réparables avec forfaits`,
        "currency",
        false,
      ],
      [
        "% de cout des pièces détachées (yc petites fournitures)/ hors forfaits",
        "percentageR_Ecr_parts",
        `Cout total des pièces détachées de l'ensemble des rapports /Cout total de la réparation des rapports avec Pièces détachées`,
        "percentage",
        false,
      ],
      [
        "% de cout de la main d'œuvre",
        "percentageR_Ecr_workforce",
        `Cout total de la MO de l'ensemble des rapports /Cout total de la réparation des rapports avec main d'œuvre`,
        "percentage",
        false,
      ],
      [
        "% de cout de la peinture",
        "percentageR_Ecr_paint",
        `Cout total de la MO du poste MO peinture + ingrédient peinture de l'ensemble des rapports /Cout total de la réparation des rapports avec peinture`,
        "percentage",
        false,
      ],
      [
        "% de cout des forfaits",
        "percentageR_Ecr_packages",
        `Cout total des forfaits de l'ensemble des rapports /Cout total de la réparation des rapports avec Forfaits`,
        "percentage",
        false,
      ],
    ];

    this.dataTable8 = [
      [
        "Stock de dossiers avec un OM avec action de visite expert réalisée mais sans dépôt de RE",
        "count_stock_visit",
        ``,
      ],
      [
        "Volume Reparable: volume total (nature expertise différent de I et 3)",
        "countR_stock",
        ``,
      ],
      [
        "% 0  à ≤ 21 jours calendaires",
        "countR_stock_report0_21",
        ``,
        "percentage",
      ],
      [
        "% > 21 à ≤ 42 jours calendaires",
        "countR_stock_report21_42",
        ``,
        "percentage",
      ],
      [
        "% >42  à ≤ 63 jours calendaires",
        "countR_stock_report42_63",
        ``,
        "percentage",
      ],
      [
        "volume attente facture (code état commence par ATTFAC)",
        "countR_stock_ATTFAC",
        ``,
      ],
      [
        "volume factures reçues à traiter (code état commence par CFAC)",
        "countR_stock_CFAC",
        ``,
      ],
      [
        "volume en estimation (dossier en etude rep et irrep) (code état commence par CESTIM)",
        "countR_stock_CESTIM",
        ``,
      ],
      ["Délai moyen Stock à l'étude", "delayR_stock_CESTIM", ``],
      [
        "volume suivi VGE et VEI (nature expertise O et Q)",
        "countR_stock_VGE_VEI",
        ``,
      ],
      [
        "volume En cours de reparation (code état commence par ATTREP et ATTFAC)",
        "countR_stock_ATTREP_ATTFAC",
        ``,
      ],
      [
        "Volume Irreparable: volume total (nature expertise I et 3)",
        "countNR_stock",
        ``,
      ],
      [
        "% 0  à ≤ 21 jours calendaires",
        "countNR_stock_report0_21",
        ``,
        "percentage",
      ],
      [
        "% 21 à ≤ 42 jours calendaires",
        "countNR_stock_report21_42",
        ``,
        "percentage",
      ],
      [
        "% >42  à ≤ 63 jours calendaires",
        "countNR_stock_report42_63",
        ``,
        "percentage",
      ],
      [
        "volume attente cession (code état commence par ATTDECIS)",
        "countNR_stock_ATTDECIS",
        ``,
      ],
      [
        "volume cessions non conformes (zone libre rubrique 2 date 4 renseignée)",
        "countNR_stock_cession",
        ``,
      ],
      [
        "volume en attente appel d'offres (code état commence par AO)",
        "countNR_stock_AO",
        ``,
      ],
      [
        "volume mission OM sans expertise (tous les dossiers)",
        "count_stock",
        ``,
      ],
      [
        "% 0  à ≤ 21 jours calendaires",
        "count_stock_report0_21",
        ``,
        "percentage",
      ],
      [
        "% > 21 à ≤ 42 jours calendaires",
        "count_stock_report21_42",
        ``,
        "percentage",
      ],
      ["% >42 jours calendaires", "count_stock_report42", ``, "percentage"],
    ];

    this.dataTable9 = [
      [
        "Nb rapports grèle réparables",
        "countR_G",
        `Nombre de rapports réparables émis `,
        null,
        false,
      ],
      [
        "% rapports grèles réparables/rapports grèles",
        "percentageR_G",
        `D/(D+E)`,
        "percentage",
        false,
      ],
      [
        "Montant moyen dommages HT non écrêté",
        "costR_G_total",
        `"Dommages: tous postes de couts chiffrés dans le rapport 
"`,
        "currency",
        false,
      ],
      [
        "Délai moyen remise rapport",
        "delayR_G_report",
        `delai entre Date réception Ordre Mission et Date du dernier dépôt du rapport d'expertise`,
        null,
        false,
      ],
    ];

    this.dataTable10 = [
      [
        "Nb rapports grèle  non réparables",
        "countNR_G",
        `Nombre de rapports non réparables émis `,
        null,
        false,
      ],
      [
        "% rapports grèles irréparables/rapports grèles",
        "percentageNR_G",
        `E/(D+E)`,
        "percentage",
        false,
      ],
      [
        "Montant moyen vrade TTC non écrêté",
        "costNR_G_total",
        `"Estimation VRADE retenue dans le rapport 
      "`,
        "currency",
        false,
      ],
      [
        "Délai moyen remise rapport",
        "delayNR_G_report",
        `delai entre Date réception Ordre Mission et Date de dépôt du dernier rapport d'expertise`,
        null,
        false,
      ],
    ];

    this.dataTable11 = [
      [
        "Nb rapports inondation  non réparables",
        "countNR_I",
        `Nombre de rapports non réparables émis avec une nature d'événement= inondation`,
        null,
        false,
      ],
      [
        "Montant moyen vrade TTC non écrêté",
        "costNR_I_total",
        `"Estimation VRADE retenue dans le rapport 
     "`,
        "currency",
        false,
      ],
      [
        "Délai moyen remise rapport",
        "delayNR_I_report",
        `delai entre Date réception Ordre Mission et Date de dépôt du dernier rapport d'expertise`,
        null,
        false,
      ],
    ];

    this.dataTable12 = [
      [
        "Volume de rapports clos",
        "countRCPJ",
        `Nbre de rapports clos`,
        null,
        false,
      ],
      [
        "Délai moyen de dépôt des rapports",
        "delayRCPJ_report",
        `delai entre Date réception Ordre Mission et Date d'émission du rapport/ Nbre de rapports`,
        null,
        false,
      ],
    ];

    this.dataTable13 = [
      [
        "Volume de dossiers en stock ",
        "countRCPJ_stock",
        `Nbre de dossiers en stock `,
      ],
      [
        "% dossiers en cours entre 42 et 74 jours calendaires /Nombre de dossiers en cours",
        "percentageRCPJ_stock_progress42_74",
        `"Nbre de dossiers en cours entre 42 et 74 jours calendaires /Nombre de dossiers en cours
Nbre de dossiers en cours > 74 jours calendaires/Nombre de dossiers en cours"`,
        "percentage",
      ],
      [
        "% dossiers en cours > 74 jours calendaires/Nombre de dossiers en cours",
        "percentageRCPJ_stock_progress74",
        `"Nbre de dossiers en cours entre 42 et 74 jours calendaires /Nombre de dossiers en cours
Nbre de dossiers en cours > 74 jours calendaires/Nombre de dossiers en cours"`,
        "percentage",
      ],
      [
        "volume RDV d'expertise en attente",
        "countRCPJ_stock_wait",
        `Nbre de RDV d expertise en attente`,
      ],
    ];

    this.dataTable14 = [
      [
        "Cout Moyen",
        "percentage_BONUS_report",
        `évolution CM global < ou = 2%
      base de référence 2022 communiquée par AZ au contrat:`,
        "percentage",
        false,
        { value: 2, type: "<=" },
      ],
      [
        "PRE",
        "percentage_BONUS_PRE",
        `≥ 8,5 % de dossiers dont économies réalisées pour AZ par rapport au tarif constructeur par dossier est ≥ à 85€ HT `,
        "percentage",
        true,
        { value: 8.5, type: ">=" },
      ],
      [
        "EAD",
        "percentage_BONUS_EAD",
        "≥ 35%",
        "percentage",
        true,
        { value: 35, type: ">=" },
      ],
      [
        "Tx VEI",
        "percentage_BONUS_VEI",
        "≤ 11%",
        "percentage",
        false,
        { value: 11, type: "<=" },
      ],
      [
        "TAUX / delai VEI 50% des dossiers en VEI « vrade accélérée » déposés  ≤10 jours ouvré",
        "percentage_BONUS_VEI_10",
        `"50% des dossiers en VEI « vrade accélérée » déposés  ≤10 jours ouvrés 
     80% des dossiers VEI ≤ 20 jours ouvrés"`,
        "percentage",
        true,
        { value: 50, type: ">=" },
      ],
      [
        "TAUX / delai VEI 80% des dossiers en VEI « vrade accélérée » déposés  ≤20 jours ouvré",
        "percentage_BONUS_VEI_20",
        `"50% des dossiers en VEI « vrade accélérée » déposés  ≤10 jours ouvrés 
     80% des dossiers VEI ≤ 20 jours ouvrés"`,
        "percentage",
        true,
        { value: 80, type: ">=" },
      ],
    ];

    return (
      <>
        <TabsCustom>
          <TabsCustomHeader>
            {this.state.tabsMenu.map((menu, index) => (
              <TabsCustomMenu
                key={index}
                title={menu}
                active={this.state.tabsIndex === index}
                onClick={() => this.setState({ tabsIndex: index })}
              />
            ))}
          </TabsCustomHeader>

          <TabsCustomBody noCard>
            <TabsCustomItem active={this.state.tabsIndex === 0}>
              <Card
                title="Volumétries & coûts moyens - Sur dossier clos avec RE"
                className="mb-20"
                classNameChildren="overflow-x-auto"
              >
                <Table>
                  <Thead>{this.getHead()}</Thead>
                  <Tbody>
                    <Tr>
                      <Th colSpan="15">
                        <p style={{ fontSize: "11px" }}>Réparables sauf RPV</p>
                        <p style={{ fontSize: "11px" }}>
                          Hors EGA, bris de glace
                        </p>
                        <p style={{ fontSize: "11px" }}>
                          avec écrêtement des dossiers de moins de 50€ et de
                          plus de 10 000€ TTC
                        </p>
                      </Th>
                    </Tr>
                  </Tbody>
                  <Tbody>
                    {this.dataTable1.map((element, idx) =>
                      this.getLine(
                        element[0],
                        element[1],
                        element[2],
                        element[3],
                        element[4],
                        element[5]
                      )
                    )}
                  </Tbody>
                  <Thead>{this.getHead()}</Thead>
                  <Tbody>
                    <Tr>
                      <Th colSpan="15">
                        <p style={{ fontSize: "11px" }}>
                          Dont EAD (sans les estimations)
                        </p>
                        <p style={{ fontSize: "11px" }}>
                          Hors EGA et bris de glace
                        </p>
                        <p style={{ fontSize: "11px" }}>
                          avec écrêtement des dossiers de moins de 50€ et de
                          plus de 10 000€ TTC
                        </p>
                      </Th>
                    </Tr>
                  </Tbody>
                  <Tbody>
                    {this.dataTable2.map((element) =>
                      this.getLine(
                        element[0],
                        element[1],
                        element[2],
                        element[3],
                        element[4],
                        element[5]
                      )
                    )}
                  </Tbody>
                  <Thead>{this.getHead()}</Thead>
                  <Tbody>
                    <Tr>
                      <Th colSpan="15">
                        <p style={{ fontSize: "11px" }}>
                          Irréparables (Economiquement Irréparables issus des
                          VEI + RPV)
                        </p>
                        <p style={{ fontSize: "11px" }}>
                          hors EGA et bris de glace
                        </p>
                        <p style={{ fontSize: "11px" }}>
                          avec écrêtement des dossiers de moins de 50€ et de
                          plus de 10 000€ TTC
                        </p>
                      </Th>
                    </Tr>
                  </Tbody>
                  <Tbody>
                    {this.dataTable3.map((element) =>
                      this.getLine(
                        element[0],
                        element[1],
                        element[2],
                        element[3],
                        element[4],
                        element[5]
                      )
                    )}
                  </Tbody>
                  <Thead>{this.getHead()}</Thead>
                  <Tbody>
                    <Tr>
                      <Th colSpan="15">
                        <p style={{ fontSize: "11px" }}>Tous rapports </p>
                        <p style={{ fontSize: "11px" }}>
                          (hors malfaçon, carence, RCPJ, bris de glace, EGA)
                        </p>
                        <p style={{ fontSize: "11px" }}>
                          avec écrêtement des dossiers de moins de 50€ et de
                          plus de 10 000€ TTC
                        </p>
                      </Th>
                    </Tr>
                  </Tbody>
                  <Tbody>
                    {this.dataTable4.map((element) =>
                      this.getLine(
                        element[0],
                        element[1],
                        element[2],
                        element[3],
                        element[4],
                        element[5]
                      )
                    )}
                  </Tbody>
                  <Thead>{this.getHead()}</Thead>
                  <Tbody>
                    <Tr>
                      <Th colSpan="15">
                        <p style={{ fontSize: "11px" }}>
                          Rapports en carence sans ecretement
                        </p>
                      </Th>
                    </Tr>
                  </Tbody>
                  <Tbody>
                    {this.dataTable5.map((element) =>
                      this.getLine(
                        element[0],
                        element[1],
                        element[2],
                        element[3],
                        element[4],
                        element[5]
                      )
                    )}
                  </Tbody>
                </Table>
              </Card>

              <Card
                title="Pièces et main d'œuvre - Sur dossier clos avec RE"
                className="mb-20"
                classNameChildren="overflow-x-auto"
              >
                <Table>
                  <Thead>{this.getHead()}</Thead>
                  <Tbody>
                    <Tr>
                      <Th colSpan="15">
                        <p style={{ fontSize: "11px" }}>Heures</p>
                        <p style={{ fontSize: "11px" }}>
                          RE réparables (hors: malfaçon, carence, RCPJ, bris de
                          glace, EGA){" "}
                        </p>
                        <p style={{ fontSize: "11px" }}>
                          avec écrêtement des dossiers de moins de 50€ et de
                          plus de 10 000€ TTC
                        </p>
                      </Th>
                    </Tr>
                  </Tbody>
                  <Tbody>
                    {this.dataTable6.map((element) =>
                      this.getLine(
                        element[0],
                        element[1],
                        element[2],
                        element[3],
                        element[4],
                        element[5]
                      )
                    )}
                  </Tbody>
                  <Thead>{this.getHead()}</Thead>
                  <Tbody>
                    <Tr>
                      <Th colSpan="15">
                        <p style={{ fontSize: "11px" }}>Pièces / peinture</p>
                        <p style={{ fontSize: "11px" }}>
                          RE réparables (hors: malfaçon, carence, RCPJ, bris de
                          glace, EGA){" "}
                        </p>
                        <p style={{ fontSize: "11px" }}>
                          avec écrêtement des dossiers de moins de 50€ et de
                          plus de 10 000€ TTC
                        </p>
                      </Th>
                    </Tr>
                  </Tbody>
                  <Tbody>
                    {this.dataTable7.map((element) =>
                      this.getLine(
                        element[0],
                        element[1],
                        element[2],
                        element[3],
                        element[4],
                        element[5]
                      )
                    )}
                  </Tbody>
                </Table>
              </Card>

              <Card
                title="STOCK"
                className="mb-20"
                classNameChildren="overflow-x-auto"
              >
                <Table>
                  <Thead>{this.getHead()}</Thead>
                  <Tbody>
                    <Tr>
                      <Th colSpan="15">
                        <p style={{ fontSize: "11px" }}>
                          Dossiers ouverts sans notion ecrêtement (hors:carence,
                          RCPJ, bris de glace, EGA)
                        </p>
                      </Th>
                    </Tr>
                  </Tbody>
                  <Tbody>
                    {this.dataTable8.map((element) =>
                      this.getLine(
                        element[0],
                        element[1],
                        element[2],
                        element[3],
                        element[4],
                        element[5]
                      )
                    )}
                  </Tbody>
                </Table>
              </Card>
            </TabsCustomItem>
            <TabsCustomItem active={this.state.tabsIndex === 1}>
              <Card
                title="onglet spécifique Grèle"
                className="mb-20"
                classNameChildren="overflow-x-auto"
              >
                <p style={{ fontSize: "11px" }}>
                  Grèle Réparable 50€ et de plus de 10 000€ TTC
                </p>
                <Table>
                  <Thead>{this.getHead()}</Thead>
                  <Tbody>
                    {this.dataTable9.map((element) =>
                      this.getLine(
                        element[0],
                        element[1],
                        element[2],
                        element[3],
                        element[4],
                        element[5]
                      )
                    )}
                  </Tbody>
                </Table>

                <p style={{ fontSize: "11px" }}>
                  Grèle Non Réparable 50€ et de plus de 10 000€ HT
                </p>
                <Table>
                  <Thead>{this.getHead()}</Thead>
                  <Tbody>
                    {this.dataTable10.map((element) =>
                      this.getLine(
                        element[0],
                        element[1],
                        element[2],
                        element[3],
                        element[4],
                        element[5]
                      )
                    )}
                  </Tbody>
                </Table>
              </Card>
              <Card
                title="onglet spécifique Inondation"
                className="mb-20"
                classNameChildren="overflow-x-auto"
              >
                <p style={{ fontSize: "11px" }}>
                  Inondation Non Réparable 50€ et de plus de 10 000€ HT
                </p>
                <Table>
                  <Thead>{this.getHead()}</Thead>
                  <Tbody>
                    {this.dataTable11.map((element) =>
                      this.getLine(
                        element[0],
                        element[1],
                        element[2],
                        element[3],
                        element[4],
                        element[5]
                      )
                    )}
                  </Tbody>
                </Table>
              </Card>
            </TabsCustomItem>
            <TabsCustomItem active={this.state.tabsIndex === 2}>
              <Card
                title="Dossiers clos"
                className="mb-20"
                classNameChildren="overflow-x-auto"
              >
                <Table>
                  <Thead>{this.getHead()}</Thead>
                  <Tbody>
                    {this.dataTable12.map((element) =>
                      this.getLine(
                        element[0],
                        element[1],
                        element[2],
                        element[3],
                        element[4],
                        element[5]
                      )
                    )}
                  </Tbody>
                </Table>
              </Card>
              <Card
                title="Dossiers en cours"
                className="mb-20"
                classNameChildren="overflow-x-auto"
              >
                <Table>
                  <Thead>{this.getHead()}</Thead>
                  <Tbody>
                    {this.dataTable13.map((element) =>
                      this.getLine(
                        element[0],
                        element[1],
                        element[2],
                        element[3],
                        element[4],
                        element[5]
                      )
                    )}
                  </Tbody>
                </Table>
              </Card>
            </TabsCustomItem>
            <TabsCustomItem active={this.state.tabsIndex === 3}>
              <Card
                title="Bonus"
                className="mb-20"
                classNameChildren="overflow-x-auto"
              >
                <p style={{ fontSize: "11px" }}>
                  - Genre véhicule : VP uniquement +CTTE
                </p>
                <p style={{ fontSize: "11px" }}>
                  - Hors événements naturels, BDG(multi garantie ABS) et
                  rapports VVNR
                </p>
                <p style={{ fontSize: "11px" }}>
                  - Coût moyen global : montant total TTC des expertises
                  réparées et irréparables réalisées en N / nb total
                  d'expertises correspondantes réalisées.
                </p>
                <p style={{ fontSize: "11px" }}>
                  - Ecrêtement : jusqu’à 10 000 euros TTC pour les rapports
                  réparables et jusqu’à 10 000 euros TTC pour les rapports VEI
                </p>
                <p style={{ fontSize: "11px" }}>
                  - Suppression des sinistres de moins de 50€ et de plus de 100
                  000€. (TTC irréparable et HT si réparable)
                </p>
                <p style={{ fontSize: "11px" }}>
                  - sinistre DARVA avec un numero de sinistre Allianz existant
                </p>
                <p style={{ fontSize: "11px" }}>- Délais : jours ouvrés</p>
                <Table>
                  <Thead>{this.getHead()}</Thead>
                  <Tbody>
                    {this.dataTable14.map((element) =>
                      this.getLine(
                        element[0],
                        element[1],
                        element[2],
                        element[3],
                        element[4],
                        element[5]
                      )
                    )}
                  </Tbody>
                </Table>
              </Card>
            </TabsCustomItem>
          </TabsCustomBody>
        </TabsCustom>
        <Modal
          show={this.state.isModal}
          onClose={() => this.setState({ isModal: false, message: "" })}
        >
          <p>{this.state.message}</p>
        </Modal>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
