import React, { PureComponent } from "react";
import { Loader } from "ui-kit-ck-consultant";
import { Col, Row } from "react-flexbox-grid";
import moment from "moment";
import * as XLSX from "xlsx/";

import ShowOptionsNGRA from "../../../components/general/ShowOptionsNGRA-v2";

import Declare from "../../../components/planning/planning/Declare";
import List from "../../../components/planning/planning/List";
import Export from "../../../components/planning/planning/Export";
import CalendarLeave from "../../../components/planning/planning/Calendar";
import DetailsDeclare from "../../../components/planning/planning/DetailsDeclare";

import { getUsers } from "../../../utils/general";
import axiosConfig from "../../../utils/axiosConfig";

import AuthContext from "../../../context/AuthContext";

export default class Planning extends PureComponent {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      // ShowOption state
      userOptions: [],
      network: 0,
      group: 0,
      region: 0,
      agency: 0,
      user: [],
      year: 0,
      // Current personn data
      dataPerPersonn: [],
      // Calendar state
      dataCalendar: [],
      dataHolidays: [],
      // List state
      //Download file
      dataDownload: [],
      isDownload: false,
      filename: "",
      header: [],
      dataValidate: [],
      dataPending: [],
      dataWaiting: [],
      //Pagination
      activePage: 1,
      tabLength: 20,
      total: 0,
    };
  }

  componentDidMount() {
    this.getUsers();
  }

  getUsers = () => {
    let userType = [0, 1, 2, 3, 4, 5];
    if (this.context.role === 1) {
      userType = [1, 2, 3];
    } else if (this.context.role === 2) {
      userType = [4, 5];
    }

    getUsers(
      this.context.network,
      this.context.group,
      this.context.region,
      null,
      userType,
      true,
      (result) => {
        if (this.context.role < 1) {
          result = result.filter(
            (element) => element.value === this.context.id
          );
        }
        this.setState({
          userOptions: result,
          user: this.context.id,
        });
      },
      this.context.manageAgency
    );
  };

  handleDataChange = (data) => {
    this.setState(
      {
        network: data.network,
        group: data.group,
        region: data.region,
        agency: data.agency,
        user: data.userMultiples,
        year: data.year,
      },
      () => {
        this.getPlannings(true);
      }
    );
  };

  getPlannings(isValidate) {
    this.setState({ isLoad: true }, () => {
      axiosConfig("planning/get-planning", {
        data: {
          isValidate: isValidate,
          network: this.state.network,
          group: this.state.group,
          region: this.state.region,
          agency: this.state.agency,
          user: this.state.user,
          year: this.state.year,
          orderName: this.state.order ? this.state.order.name : null,
          orderDesc: this.state.order ? this.state.order.desc : null,
        },
      }).then((res) => {
        if (res.data.success) {
          if (isValidate) {
            let dataPending = [];
            let dataWaiting = [];

            if (this.context.role === 1) {
              // RESP TECHNIQUE
              dataWaiting = res.data.data.filter((el) => {
                return (
                  [0].includes(el.status) && [1, 2, 3].includes(el.userType)
                );
              });
            } else if (this.context.role === 2) {
              // RESP ADMINISTRATIF
              dataWaiting = res.data.data.filter((el) => {
                return [0].includes(el.status) && [4, 5].includes(el.userType);
              });
            } else if (this.context.role > 2) {
              dataPending = res.data.data.filter((el) => {
                return [0].includes(el.status);
              });
              // DRH - DIRIGEANT
              dataWaiting = res.data.data.filter((el) => {
                return [1].includes(el.status);
              });
            }

            const dataValidate = res.data.data.filter((el) => {
              return [2, 3].includes(el.status);
            });

            this.setState({
              dataValidate,
              dataPending,
              dataWaiting,
              dataCalendar: dataValidate.filter((el) => el.status === 2),
              dataHolidays: res.data.holidays,
              dataPerPersonn: res.data.data.filter(
                (el) => el.userId === this.context.id
              ),
            });
          }
        } else {
        }
        this.setState({ isLoad: false });
      });
    });
  }

  getDataXLSX(month, absenceType) {
    this.setState({ isLoad: true }, () => {
      axiosConfig("planning/get-data-XLSX", {
        data: { ...this.getData(), month, absenceType },
      }).then((res) => {
        if (res.data.success) {
          if (res.data.data.length) {
            const header = [];
            if (absenceType !== "Formation") {
              header.push({
                dateInsert: "Date de la demande",
                userName: "Utilisateur",
                agency: "Agence",
                type: " Type de congés",
                monthOfDeparture: "Mois de départ",
                dateStart: "Date de départ",
                typeStart: "Type de départ",
                dateEnd: "Date de retour",
                typeEnd: "Type de retour",
                absenceDuration: "Durée",
                file: "Piéce jointe",
                status: "État",
                managerName: "Responsable",
                RH: "RH",
              });
            } else {
              header.push({
                dateInsert: "Date de la demande",
                userName: "Utilisateur",
                agency: "Agence",
                monthOfDeparture: "Mois de départ",
                dateStart: "Date de départ",
                dateEnd: "Date de retour",
                absenceDuration: "Durée",
                organization: "Organisme",
                type: "Type",
                RH: "Lieu",
                status: "État",
              });
            }

            const workBook = XLSX.utils.book_new();
            const workSheet = XLSX.utils.json_to_sheet([]);
            XLSX.utils.sheet_add_json(workSheet, header, {
              skipHeader: true,
              origin: "A1",
            });
            XLSX.utils.sheet_add_json(
              workSheet,
              res.data.data
                .map((element) =>
                  absenceType !== "Formation"
                    ? {
                        dateInsert: element.dateInsert,
                        userName: element.userName,
                        agency: element.agency,
                        type: element.type,
                        monthOfDeparture: element.monthOfDeparture,
                        dateStart: element.dateStart,
                        typeStart: element.typeStart,
                        dateEnd: element.dateEnd,
                        typeEnd: element.typeEnd,
                        absenceDuration: element.absenceDuration,
                        file: element.file,
                        status: element.status,
                        managerName: element.managerName,
                        RH: element.RH,
                      }
                    : {
                        dateInsert: element.dateInsert,
                        userName: element.userName,
                        agency: element.agency,
                        monthOfDeparture: element.monthOfDeparture,
                        dateStart: element.dateStart,
                        dateEnd: element.dateEnd,
                        absenceDuration: element.absenceDuration,
                        organization: element.organization,
                        type: element.type,
                        RH: element.RH,
                        status: element.status,
                      }
                )
                .filter((element) => element.status !== "Rejetée"),
              {
                skipHeader: true,
                origin: "A2",
              }
            );
            XLSX.utils.book_append_sheet(workBook, workSheet, "");
            XLSX.writeFile(
              workBook,
              `${absenceType ? absenceType : "absence"}_${moment().format(
                "YYYYMMDDHHmmss"
              )}.xlsx`
            );
            this.setState({ isLoad: false });
          } else {
            this.setState({ isLoad: false });
            alert(
              `${
                absenceType
                  ? "Pas de formation enregistrée pour ce mois-ci"
                  : "Pas d'absence enregistrée pour ce mois-ci"
              }`
            );
          }
        } else {
          this.setState({ isLoad: false });
        }
      });
    });
  }

  getDataPerUserXLSX(month, absenceType) {
    this.setState({ isLoad: true }, () => {
      axiosConfig("planning/get-data-per-user-XLSX", {
        data: { ...this.getData(), month, absenceType },
      }).then((res) => {
        if (res.data.success) {
          if (res.data.data) {
            const workBook = XLSX.utils.book_new();
            const workSheet = XLSX.utils.json_to_sheet([]);

            if (absenceType !== "Formation") {
              // Liste de tous les mois de l'année
              const months = [
                "Janvier",
                "Janvier Maladie",
                "Février",
                "Février Maladie",
                "Mars",
                "Mars Maladie",
                "Avril",
                "Avril Maladie",
                "Mai",
                "Mai Maladie",
                "Juin",
                "Juin Maladie",
                "Juillet",
                "Juillet Maladie",
                "Août",
                "Août Maladie",
                "Septembre",
                "Septembre Maladie",
                "Octobre",
                "Octobre Maladie",
                "Novembre",
                "Novembre Maladie",
                "Décembre",
                "Décembre Maladie",
              ];

              const header = [
                {
                  userName: "Utilisateur",
                  annualLeave: " Jours de congées annuel/sans solde",
                  sickLeave: "Jours d'arret maladie",
                  ...Object.fromEntries(months.map((month) => [month, month])),
                },
              ];

              XLSX.utils.sheet_add_json(workSheet, header, {
                skipHeader: true,
                origin: "A1",
              });

              const monthOrder = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ];

              res.data.data.forEach((data, index) => {
                const sortedMonthlyData = {};
                monthOrder.forEach((month) => {
                  sortedMonthlyData[month] = data.monthly[month];
                  sortedMonthlyData[`${month}Maladie`] =
                    data.monthly[`${month}Maladie`];
                });

                XLSX.utils.sheet_add_json(
                  workSheet,
                  [{ value: data.userName }],
                  {
                    skipHeader: true,
                    origin: `A${index + 3}`,
                  }
                );

                XLSX.utils.sheet_add_json(
                  workSheet,
                  [{ value: data.annualLeave }],
                  {
                    skipHeader: true,
                    origin: `B${index + 3}`,
                  }
                );

                XLSX.utils.sheet_add_json(
                  workSheet,
                  [{ value: data.sickLeave }],
                  {
                    skipHeader: true,
                    origin: `C${index + 3}`,
                  }
                );

                XLSX.utils.sheet_add_json(workSheet, [sortedMonthlyData], {
                  skipHeader: true,
                  origin: `D${index + 3}`,
                });
              });
            } else {
              const header = [
                "Utilisateur",
                "Jours de formation",
                "Janvier",
                "Fevrier",
                "Mars",
                "Avril",
                "Mai",
                "Juin",
                "Juillet",
                "Aout",
                "Septembre",
                "Octobre",
                "Novembre",
                "Decembre",
              ];

              XLSX.utils.sheet_add_json(
                workSheet,
                [{ ...Object.fromEntries(header.map((val) => [val, val])) }],
                {
                  skipHeader: true,
                  origin: "A1",
                }
              );

              res.data.data.forEach((data, index) => {

                XLSX.utils.sheet_add_json(
                  workSheet,
                  [
                    {
                      userName: data.userName,
                      total: data.total,
                      ...data.month,
                    },
                  ],
                  {
                    skipHeader: true,
                    origin: `A${index + 3}`,
                  }
                );
              });
            }

            XLSX.utils.book_append_sheet(workBook, workSheet, "Absences");

            XLSX.writeFile(
              workBook,
              `${absenceType ? absenceType : "absence"}_${moment().format(
                "YYYYMMDDHHmmss"
              )}.xlsx`
            );
            this.setState({ isLoad: false });
          } else {
            this.setState({ isLoad: false });
            alert("Pas d'absence par personnes enregistrée pour ce mois-ci");
          }
        } else {
          this.setState({ isLoad: false });
        }
      });
    });
  }

  setData(result) {
    if (result.success === true) {
      this.setState({ dataList: result.tabData, isLoad: false });
    } else {
      this.setState({ dataList: [], isLoad: false });
    }
  }

  handleTabClick = (idx) => {
    let dataList = this.state.dataList;
    if (dataList[idx].active === false) {
      dataList[idx].active = true;
    } else {
      dataList[idx].active = false;
    }
    this.setState({ dataList: dataList });
  };

  getData() {
    return {
      network: this.state.network,
      group: this.state.group,
      region: this.state.region,
      agency: this.state.agency,
      user: this.state.user,
      year: this.state.year,
      orderName: this.state.order ? this.state.order.name : null,
      orderDesc: this.state.order ? this.state.order.desc : null,
    };
  }

  onSortClick = (order) => {
    this.setState({ order }, () => {
      this.getPlannings(true);
    });
  };

  updateDataList = (key, value) => {
    this.setState((prevState) => ({
      inputs: {
        ...prevState.dataList,
        [key]: value,
      },
    }));
  };

  render() {
    return (
      <>
        <Loader show={this.state.isLoad} />
        <DetailsDeclare title="Détail de l'absence" getData={this.getData} />
        <Col>
          <Row className="d-flex w-100 m-0">
            <Declare
              userOptions={this.state.userOptions}
              className={"mb-10 mb-20-xl w-100"}
            />
          </Row>
        </Col>
        <ShowOptionsNGRA
          isChange={true}
          className={"mb-10 mb-20-xl"}
          onDataChange={this.handleDataChange}
          isYear={true}
          isUser={true}
          isUserMultiples={true}
          visibilityRole={this.props.visibilityRole}
          visibilityType={this.props.visibilityType}
          preloadRole={this.props.preloadRole}
          preloadType={this.props.preloadType}
          limitUserType={this.props.limitUserType}
          limitUserRole={this.props.limitUserRole}
          allUserRole={this.props.allUserRole}
          isAll={true}
          userType={[1, 2, 3, 4, 5]}
        />
        {this.state.dataPerPersonn.length > 0 && (
          <List
            onSortClick={(order) => this.onSortClick(order)}
            title={"Vos demandes de congés"}
            data={this.state.dataPerPersonn}
            className={"mb-10 mb-20-xl"}
            updatePlannings={() => this.getPlannings(true)}
            updateDataList={(key, value) => this.updateDataList(key, value)}
          />
        )}

        {[1, 2, 3, 4].includes(this.context.role) && (
          <List
            onSortClick={(order) => this.onSortClick(order)}
            title={"En attente de validation responsable"}
            isValidate={true}
            data={
              [3, 4].includes(this.context.role)
                ? this.state.dataPending
                : this.state.dataWaiting
            }
            updatePlannings={() => this.getPlannings(true)}
            className={"mb-10 mb-20-xl"}
            updateDataList={(key, value) => this.updateDataList(key, value)}
          />
        )}
        {[3, 4].includes(this.context.role) && (
          <List
            onSortClick={(order) => this.onSortClick(order)}
            updatePlannings={() => this.getPlannings(true)}
            title={"En attente de validation RH"}
            data={this.state.dataWaiting}
            className={"mb-10 mb-20-xl"}
            updateDataList={(key, value) => this.updateDataList(key, value)}
          />
        )}
        <CalendarLeave
          className={"mb-10 mb-20-xl"}
          dataCalendar={this.state.dataCalendar}
          dataHolidays={this.state.dataHolidays}
        />
        {[1, 2, 3, 4].includes(this.context.role) ? (
          <Export
            getDataXLSX={(month, absenceType) =>
              this.getDataXLSX(month, absenceType)
            }
            getDataPerUserXLSX={(month, absenceType) =>
              this.getDataPerUserXLSX(month, absenceType)
            }
          />
        ) : null}
        {this.state.dataValidate.length > 0 && (
          <List
            userOptions={this.state.userOptions}
            onSortClick={(order) => this.onSortClick(order)}
            title={"Liste"}
            data={this.state.dataValidate.slice(
              (this.state.activePage - 1) * this.state.tabLength,
              this.state.activePage * this.state.tabLength
            )}
            updatePlannings={() => this.getPlannings(true)}
            className={"mb-10 mb-20-xl"}
            updateDataList={(key, value) => this.updateDataList(key, value)}
            //Pagination
            activePage={this.state.activePage}
            tabLength={this.state.tabLength}
            total={this.state.dataValidate.length}
            onPageChange={(page) => {
              this.setState({
                activePage: page,
              });
            }}
          />
        )}
      </>
    );
  }
}
