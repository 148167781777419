const alertError = (error) => {
  if (!error.status) {
    alert("Erreur serveur. Veuillez réactualiser la page.");
  } else {
    alert(`Erreur ${error.status}. Veuillez réactualiser la page.`);
  }
}

export {
  alertError
}