import React, { Component } from "react";
import FadeIn from "react-fade-in/lib/FadeIn";
import { Col, Row } from "react-flexbox-grid";
import {
  Card,
  Loader,
  Nav,
  TabsCustom,
  TabsCustomBody,
  TabsCustomHeader,
  TabsCustomItem,
  TabsCustomMenu,
} from "ui-kit-ck-consultant";

import PrintClass from "../../../components/general/Print";
import ShowOptionsNGRA from "../../../components/general/ShowOptionsNGRA-v2";
import AuditCard from "../../../components/performances/dashboardEAD/AuditCard";
import PodiumComplete from "../../../components/performances/dashboardEAD/PodiumComplete";
import SatisfactionCard from "../../../components/performances/dashboardEAD/SatisfactionCard";
import TableFolders from "../../../components/performances/dashboardEAD/TableFolders";
import Carrousel from "../../../components/general/Carrousel";
import LineChartNbFileExpert from "../../../components/performances/dashboardEAD/LineChartNbFileExpert";
import LineChartFieldExpertise from "../../../components/performances/dashboardEAD/LineChartFieldExpertise";
import LineChartRPRate from "../../../components/performances/dashboardEAD/LineChartRPRate";
import LineChartPRERate from "../../../components/performances/dashboardEAD/LineChartPRERate";
import BarChartAverageCost from "../../../components/performances/dashboardEAD/BarChartAverageCost";
import BarChartAverageProfit from "../../../components/performances/dashboardEAD/BarChartAverageProfit";
import BarChartMonthlyEfficiency from "../../../components/performances/dashboardEAD/BarChartMonthlyEfficiency";
import TableEADbyCompany from "../../../components/performances/dashboardEAD/TableEADbyCompany";

import axiosConfig from "../../../utils/axiosConfig";

import "../../../style/DashboardEAD.css";

import AuthContext from "../../../context/AuthContext";

export class DashboardEAD extends Component {
  constructor(props) {
    super(props);
    this.state = {
      network: null,
      group: null,
      region: null,
      agency: null,
      year: null,
      company: null,
      data: null,
      isLoad: false,
      menuTabs: ["Tableau de Bord EAD", "Suivi tx d'EAD"],
      tabsIndex: 0,
    };

    this.refPrintStatistics = React.createRef();
    this.refPrintSatisfaction = React.createRef();
    this.refPrintAudit = React.createRef();
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.interval = setInterval(() => {
      this.getData();
    }, 15 * 60 * 1000);
  }

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  handleDataChange = (data) => {
    this.setState(
      {
        network: data.network,
        group: data.group,
        region: data.region,
        agency: data.agency,
        company: data.company,
        year: data.year,
      },
      () => {
        this.getData();
      }
    );
  };

  getData = () => {
    this.setState({ isLoad: true, data: null }, () => {
      axiosConfig("/performances/dashboardEAD/get-data", {
        data: {
          network: this.state.network,
          group: this.state.group,
          region: this.state.region,
          agency: this.state.agency,
          year: this.state.year,
          company: this.state.company,
        },
      }).then((res) => {
        if (res.data.success === true) {
          let data = {
            chartsData: res.data.chartsData,
            podiumData: res.data.podiumData,
            modifiedMission: res.data.modifiedMission,
            satisfactionData: res.data.satisfactionData,
            auditData: res.data.auditData,
            tableData: res.data.tableData,
            tableEADbyCompany: res.data.tableEADbyCompany,
          };
          this.setState({ data, isLoad: false });
        } else {
          this.setState({ isLoad: false });
        }
      });
    });
  };

  render() {
    return (
      <>
        <FadeIn>
          <Nav title="Tableau de Bord EAD" className="mb-10 mb-20-xl" />
          <ShowOptionsNGRA
            isChange={true}
            className="mb-10 mb-20-xl"
            onDataChange={this.handleDataChange}
            isYear={true}
            isCompany={true}
            visibilityRole={this.props.visibilityRole}
            visibilityType={this.props.visibilityType}
            preloadRole={this.props.preloadRole}
            preloadType={this.props.preloadType}
            limitUserType={this.props.limitUserType}
            limitUserRole={this.props.limitUserRole}
            isAll={true}
            userType={[1, 2, 3]}
          />
          {this.state.data ? (
            <TabsCustom>
              <TabsCustomHeader noCard className={"mt-10 mt-30-xl"}>
                {this.state.menuTabs.map((element, key) => (
                  <TabsCustomMenu
                    noCard
                    disabled={this.props.isLoader}
                    key={key}
                    title={element}
                    active={this.state.tabsIndex === key}
                    onClick={(e) => {
                      this.setState({ tabsIndex: key });
                    }}
                  />
                ))}
              </TabsCustomHeader>
              <TabsCustomBody noCard>
                <TabsCustomItem active={this.state.tabsIndex === 0}>
                  <FadeIn>
                    {this.state.data.chartsData.barCharts ||
                    (this.state.data.chartsData.lineCharts &&
                      this.state.data.modifiedMission) ||
                    this.state.data.tableData ||
                    (this.state.data.podiumData && this.state.year) ? (
                      <div ref={this.refPrintStatistics}>
                        <Card
                          className="overflow-auto mb-20"
                          title={"Statistiques"}
                        >
                          <Row className="mb-20">
                            <Col xs={12} md={12} lg={3}>
                              <PodiumComplete
                                data={this.state.data.podiumData}
                                year={this.state.year}
                              />
                            </Col>
                            <Col xs={12} md={12} lg={9}>
                              <TableFolders data={this.state.data.tableData} />
                            </Col>
                          </Row>
                        </Card>
                        <div className="carrousel">
                          <Card>
                            <Carrousel
                              show={2}
                              infiniteLoop={true}
                              autoSlide={true}
                              interval={5000}
                            >
                              <div>
                                <LineChartNbFileExpert
                                  height={280}
                                  data={this.state.data.chartsData.lineCharts}
                                  modifiedMission={
                                    this.state.data.modifiedMission
                                  }
                                ></LineChartNbFileExpert>
                              </div>
                              <div>
                                <LineChartFieldExpertise
                                  height={280}
                                  data={this.state.data.chartsData.lineCharts}
                                  modifiedMission={
                                    this.state.data.modifiedMission
                                  }
                                ></LineChartFieldExpertise>
                              </div>
                              <div>
                                <LineChartRPRate
                                  height={280}
                                  data={this.state.data.chartsData.lineCharts}
                                  modifiedMission={
                                    this.state.data.modifiedMission
                                  }
                                ></LineChartRPRate>
                              </div>
                              <div>
                                <LineChartPRERate
                                  height={280}
                                  data={this.state.data.chartsData.lineCharts}
                                  modifiedMission={
                                    this.state.data.modifiedMission
                                  }
                                ></LineChartPRERate>
                              </div>
                              <div>
                                <BarChartAverageCost
                                  height={280}
                                  data={this.state.data.chartsData.barCharts}
                                ></BarChartAverageCost>
                              </div>
                              <div>
                                <BarChartAverageProfit
                                  height={280}
                                  data={this.state.data.chartsData.barCharts}
                                ></BarChartAverageProfit>
                              </div>
                              <div>
                                <BarChartMonthlyEfficiency
                                  height={280}
                                  data={this.state.data.chartsData.barCharts}
                                ></BarChartMonthlyEfficiency>
                              </div>
                              <div>
                                <SatisfactionCard
                                  height={280}
                                  data={this.state.data.satisfactionData}
                                />
                              </div>
                              <div>
                                <AuditCard
                                  height={280}
                                  data={this.state.data.auditData}
                                />
                              </div>
                            </Carrousel>
                          </Card>
                        </div>
                      </div>
                    ) : null}
                  </FadeIn>
                </TabsCustomItem>
                <TabsCustomItem active={this.state.tabsIndex === 1}>
                  <Card
                    className="overflow-auto mb-20"
                    title={"Taux d'EAD par Compagnie"}
                  >
                    <TableEADbyCompany
                      data={this.state.data.tableEADbyCompany}
                    />
                  </Card>
                </TabsCustomItem>
              </TabsCustomBody>
            </TabsCustom>
          ) : null}
          <Loader show={this.state.isLoad} />
          <PrintClass
            refs={
              this.state.isPrint
                ? [
                    this.refPrintStatistics,
                    this.refPrintSatisfaction,
                    this.refPrintAudit,
                  ]
                : []
            }
            title={"Tableau de Bord EAD"}
            fileName={"Tableau de Bord EAD"}
            onPrint={(print) =>
              this.setState({ isPrint: print, isLoad: print })
            }
            width={1500}
          />
        </FadeIn>
      </>
    );
  }
}

export default DashboardEAD;
