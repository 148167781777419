import React, { Component } from "react";
import './Map.css'
import MyMarker from './MyMarker'
import MyBuilding from './MyBuilding'

 
export default class MapMarkerCustom extends Component {
  render() {
    return (
      <div className='border-marker' style={{backgroundColor: `${this.props.expertColor}88`}}>
        {this.props.type && this.props.type === 2 
          ? (
            <MyMarker 
              nb={'+'} 
              color={this.props.color}
              width={32}
              onClick={() => this.props.onOpenModal(this.props.data)}

            />
            )
          : this.props.type && this.props.type === 3
          ? (
            <MyBuilding 
              color={this.props.color}
              width={30}
              onClick={() => this.props.onOpenModal(this.props.data)}
            />
          )
          : null
        }
          {this.props.text}
      </div>
    )
  }
}
