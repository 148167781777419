import React, { Component } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Card,
  Pagination,
  ButtonIcon,
  Loader,
} from "ui-kit-ck-consultant";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import natureExpertise from "../../../utils/natureExpertise";
import handleSortClick from "../../../utils/handleSortClick";

export default class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: null,
    };

    this.sortRef = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
    ];
  }

  handleSortClick = (desc, column) => {
    this.setState(
      { order: handleSortClick(this.sortRef, desc, column) },
      () => {
        this.props.onSortClick(this.state.order);
      }
    );
  };

  render() {
    const tableHead = [
      { value: "date", name: "Date", nameSort: "date", sort: true },
      {
        value: "dateClosing",
        name: "Date de cloture",
        nameSort: "dateClosing",
        sort: true,
      },
      { value: "agency", name: "Agence", nameSort: "agency", sort: true },
      {
        value: "reference",
        name: "N° dossier",
        nameSort: "reference",
        sort: true,
      },
      { value: "expert", name: "Expert", nameSort: "expert", sort: true },
      {
        value: "assistant",
        name: "Assistant(e)",
        nameSort: "assistant",
        sort: true,
      },
      {
        value: "indicator",
        name: "Indicateur",
        nameSort: "indicator",
        sort: true,
      },
      {
        value: "natureExpertise",
        name: "Nature d'expertise",
        nameSort: "natureExpertise",
        sort: true,
        format: natureExpertise,
      },
      {
        value: "totalReport",
        title: "Total rép. HT",
        nameSort: "totalReport",
        sort: true,
      },
      { value: "VRADE", name: "VRADE TTC", nameSort: "VRADE", sort: true },
      {
        value: "comment",
        name: "Commentaire",
        nameSort: "comment",
        sort: true,
      },
      { value: "valueStr", name: "Conforme", nameSort: "value", sort: true },
    ];

    return (
      <Card
        title={"Liste des Audits"}
        className={this.props.className}
        classNameChildren="overflow-x-auto"
      >
        <Table
          empty={
            this.props.data.length > 0 ? "" : "Aucune donnée n'est disponible"
          }
        >
          <Thead>
            <Tr>
              {tableHead.map((elem, idx) => (
                <Th
                  className={this.props.isPrint ? "pl-5 pr-5" : ""}
                  key={idx}
                  ref={this.sortRef[idx]}
                  sort={elem.sort ? true : false}
                  onSort={
                    elem.sort ? (desc) => this.handleSortClick(desc, idx) : null
                  }
                  nameSort={elem.nameSort}
                >
                  {elem.name}
                </Th>
              ))}
              {this.props.isPrint ? null : <Th>Actions</Th>}
            </Tr>
          </Thead>
          <Tbody>
            {this.props.data.map((element, idx) => (
              <Tr
                key={idx}
                className={element.length === true ? "cursor-pointer " : ""}
                onClick={(e) =>
                  element.length === true ? this.props.onTabClick(idx) : ""
                }
              >
                {tableHead.map((elemHead, idxHead) => (
                  <Td
                    key={idxHead}
                    className={`
                      ${this.props.isPrint ? "pl-5 pr-5" : ""} ${
                      element.value === 1 ? "bg-green-light" : "bg-red-light"
                    }`}
                  >
                    {elemHead.format
                      ? elemHead.format(element[elemHead.value])
                      : element[elemHead.value]}
                  </Td>
                ))}
                {this.props.isPrint ? null : (
                  <Td
                    className={
                      element.value === 1 ? "bg-green-light" : "bg-red-light"
                    }
                  >
                    <ButtonIcon
                      className={"red"}
                      onClick={() => this.props.onDeleteClick(element.id)}
                      small
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </ButtonIcon>
                  </Td>
                )}
              </Tr>
            ))}
          </Tbody>
        </Table>
        {this.props.isPrint ? null : (
          <Pagination
            activePage={this.props.activePage}
            itemsCountPerPage={this.props.tabLength}
            totalItemsCount={this.props.total}
            pageRangeDisplayed={4}
            onChange={this.props.onPageChange.bind(this)}
            firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
            lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
            prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
            nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
          />
        )}
        <Loader show={this.props.loader} />
      </Card>
    );
  }
}
