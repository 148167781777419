import React, { Component } from "react";
import { BarChart, Card } from "ui-kit-ck-consultant";

export default class MonthBarChart extends Component {
  render() {
    return (
      <Card title={this.props.title}>
        <BarChart
          format={this.props.format}
          labels={this.props.labels}
          digits={this.props.digits || 0}
          widthBar={this.props.widthBar || 30}
          dataSet={[
            ...(this.props.dataLine
              ? [
                  {
                    label: this.props.labelLine,
                    type: "line",
                    data: this.props.dataLine,
                    color: this.props.color || "#E68A00",
                    yAxisID: "A",
                  },
                ]
              : []),
            {
              label: this.props.label,
              type: this.props.type || "line",
              data: this.props.data,
              color: this.props.color || "#026fc2",
              yAxisID: "B",
            },
          ]}
          yAxes={[
            ...(this.props.dataLine
              ? [
                  {
                    id: "A",
                    ticks: {
                      display: false,
                    },
                  },
                ]
              : []),
            {
              id: "B",
              ticks: {
                display: false,
              },
            },
          ]}
        />
      </Card>
    );
  }
}
