import React, { Component } from "react";
import {
  Nav,
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  BarChart,
  PieChart,
  Loader,
  Pagination,
  TabsCustom,
  TabsCustomHeader,
  TabsCustomMenu,
  TabsCustomBody,
  TabsCustomItem,
} from "ui-kit-ck-consultant";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "react-flexbox-grid";
import moment from "moment";

import {
  getCurrencyValue,
  getPercentageValue,
  getValue,
} from "../../../utils/general";

import ShowOptionsNGRA from "../../../components/general/ShowOptionsNGRA-v2";

import axiosConfig from "../../../utils/axiosConfig";
import handleSortClick from "../../../utils/handleSortClick";

const colors = [
  "#1ABC9C",
  "#2ECC71",
  "#3498DB",
  "#9B59B6",
  "#34495E",
  "#16A085",
  "#27AE60",
  "#2980B9",
  "#8E44AD",
  "#2C3E50",
  "#F1C40F",
  "#E67E22",
  "#E74C3C",
  "#95A5A6",
  "#F39C12",
  "#D35400",
  "#C0392B",
  "#C0392B",
  "#1ABC9C",
  "#665191",
  "#1c4daa",
  "#f7828c",
  "#a05196",
  "#103f5c",
  "#db7e9d",
  "#74a070",
  "#356061",
  "#1abc9c",
  "#2ecc71",
  "#3498db",
  "#9b59b6",
  "#34495e",
  "#16a085",
  "#27ae60",
  "#2980b9",
  "#8e44ad",
  "#2c3e50",
  "#f1c40f",
  "#e67e22",
  "#e74c3c",
  "#ecf0f1",
  "#95a5a6",
  "#f39c12",
  "#d35400",
  "#c0392b",
  "#bdc3c7",
  "#7f8c8d",
];

export class BrokenGlass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      activePage: 1,
      pageLength: 20,
      accessAllowed: false,
      data: null,
      dateStart: moment().format("YYYY-01-01"),
      dateEnd: moment().format("YYYY-MM-DD"),
      network: 1,
      group: 1,
      region: 1,
      agency: 120,
      kind: [],
      order: null,
      tabsData: [
        "Général",
        "Référence PARE BRISE",
        "123 PARE BRISE",
        "GLOBAL PARE BRISE",
        "QUALITY PARE BRISE",
        "EURO PARE BRISE",
        "ELITE PARE BRISE",
        "HELLOGLASS",
        "INITIAL PARE BRISE",
      ],
      subTabsData: ["Global", "À postériori", "À priori"],
      tabsIndex: 0,
      subTabsIndex: 0,
    };

    this.sortRef = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
    ];
  }

  handleSortClick = (desc, column) => {
    this.setState({ order: handleSortClick(this.sortRef, desc, column) });
  };

  handleDataChange = (data) => {
    this.setState(
      {
        dateStart: data.dateStart,
        dateEnd: data.dateEnd,
        network: data.network,
        group: data.group,
        region: data.region,
        agency: data.agency,
        user: data.user,
        company: data.company,
        kind: data.kind,
      },
      this.getData
    );
  };

  getData = () => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        axiosConfig("specialties/broken-glass/get-data", {
          data: {
            network: this.state.network,
            group: this.state.group,
            region: this.state.region,
            agency: this.state.agency,
            dateStart: this.state.dateStart,
            dateEnd: this.state.dateEnd,
            company: this.state.company,
            kind: this.state.kind,
          },
        })
          .then((res) => {
            if (res.data.success) {
              this.setState({
                isLoad: false,
                data: res.data.data,
              });
            } else {
              this.setState(
                {
                  isLoad: false,
                  data: null,
                },
                () => alert("Une erreur est survenue")
              );
            }
          })
          .catch(() =>
            this.setState(
              {
                isLoad: false,
                data: null,
              },
              () => alert("Une erreur est survenue")
            )
          );
      }
    );
  };

  getStatus = (value1, value2, idx) => {
    let value = 0;

    if (value1 && value2) {
      value = value1 / value2;
    } else if (value1) {
      value = 100;
    }

    let color = "";

    if (value > 0) {
      color = "bg-green-light";
    } else if (value < 0) {
      color = "bg-red-light";
    }

    return (
      <Td key={idx} className={color}>
        {getPercentageValue(value)}
      </Td>
    );
  };

  getValueFormat = (value, format, digit) => {
    if (format === "currency") {
      return getCurrencyValue(value);
    } else if (format === "percentage") {
      return getPercentageValue(value);
    } else {
      return getValue(value, digit);
    }
  };

  getLine = (name, format = null, digit = 2) => {
    return this.getDataTable()[this.state.tabsIndex].map((element, idx) =>
      idx === 2 ? (
        this.getStatus(
          this.getDataTable()[this.state.tabsIndex][0][name],
          this.getDataTable()[this.state.tabsIndex][1][name],
          idx
        )
      ) : (
        <Td key={`${name}-${idx}`}>
          {element[name]
            ? this.getValueFormat(element[name], format, digit)
            : element["total"]
            ? this.getValueFormat(0, format, digit)
            : ""}
        </Td>
      )
    );
  };

  getDataTable = () => {
    if (this.state.subTabsIndex === 0) {
      return this.state.data.dataTable;
    } else if (this.state.subTabsIndex === 1) {
      return this.state.data.dataTable1;
    } else {
      return this.state.data.dataTable2;
    }
  };

  render() {
    let folders = this.state.data ? this.state.data.folders : [];

    if (this.state.order) {
      folders.sort((a, b) => {
        let aX = a[this.state.order.name] || "";
        let bX = b[this.state.order.name] || "";

        if (typeof aX == "number" && typeof bX == "number") {
          return this.state.order.desc ? aX - bX : bX - aX;
        } else {
          aX = aX.toString();
          bX = bX.toString();
          return this.state.order.desc
            ? aX.normalize().localeCompare(bX.normalize())
            : bX.normalize().localeCompare(aX.normalize());
        }
      });
    }

    return (
      <>
        <Nav title="Bris de glace" className="mb-10 mb-20-xl" />
        <ShowOptionsNGRA
          isChange={true}
          className="mb-10 mb-20-xl"
          onDataChange={this.handleDataChange}
          isDateStart={true}
          isDateEnd={true}
          isCompany={true}
          visibilityRole={this.props.visibilityRole}
          visibilityType={this.props.visibilityType}
          preloadRole={this.props.preloadRole}
          preloadType={this.props.preloadType}
          limitUserType={this.props.limitUserType}
          limitUserRole={this.props.limitUserRole}
          isKind
          // userType={[1, 2, 3]} disable for display if no user in agency
          isDefaultAllUser={true}
        />
        {!this.state.data ? null : (
          <>
            <Card
              className={"mt-20"}
              classNameChildren="overflow-x-auto"
              title="Dossiers controlés"
            >
              <Row>
                <Col xs={12} md={6}>
                  {this.getBarChart("Nombre de dossiers traités / mois", [
                    {
                      data: this.state.data.folderStockMonth,
                      label: "Nombre de dossiers en cours",
                      color: colors,
                      type: "line",
                    },
                    {
                      data: this.state.data.folderMonth,
                      label: "Nombre dossiers",
                      color: colors,
                    },
                    {
                      data: this.state.data.folderOpenMonth,
                      label: "Nombre de missions reçues",
                      color: colors,
                    },
                  ])}
                </Col>
                <Col xs={12} md={6}>
                  <div className="d-flex flex-column h-100">
                    <PieChart
                      height={160}
                      className="m-auto"
                      digits={0}
                      title="Nombre de véhicule / catégorie"
                      legendDisplay={true}
                      data={this.state.data.countCarType.map(
                        (element, idx) => ({
                          data: element.data,
                          labels: element.label,
                          color: colors[idx],
                        })
                      )}
                      positionLegend="right"
                      alignLegend="left"
                    />
                    <PieChart
                      height={160}
                      className="m-auto"
                      digits={0}
                      title="Nombre de véhicule / tranche d'âge"
                      legendDisplay={true}
                      data={this.state.data.countAge.map((element, idx) => ({
                        data: element.data,
                        labels: element.label,
                        color: colors[idx],
                      }))}
                      positionLegend="right"
                      alignLegend="left"
                    />
                  </div>
                </Col>
                <Col xs={12}>
                  <BarChart
                    height={400}
                    title="Nombre de dossier / marque"
                    digits={0}
                    labels={this.state.data.countBrand.map(
                      (element) => element.label
                    )}
                    dataSet={[
                      {
                        type: "bar",
                        label: "Nombre de dossier",
                        data: this.state.data.countBrand.map(
                          (element) => element.total
                        ),
                        color: colors,
                      },
                    ]}
                    xAxes={[
                      {
                        gridLines: {
                          display: false,
                        },
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Card>
            <Card
              className={"mt-20"}
              classNameChildren="overflow-x-auto"
              title="Montant gain"
            >
              <Row>
                <Col xs={12}>
                  {this.getBarChart(
                    "Gain HT (isolé) / mois",
                    [
                      {
                        data: this.state.data.gainMonth,
                        label: "Gain",
                        color: colors,
                      },
                    ],
                    "currency"
                  )}
                </Col>
                <Col xs={12} md={6}>
                  <BarChart
                    height={400}
                    format="currency"
                    digits={0}
                    isLegend={true}
                    title="Montant gain HT / réseau"
                    labels={this.state.data.gainNetwork.map(
                      (element) => element.label
                    )}
                    dataSet={[
                      {
                        data: this.state.data.gainNetwork.map(
                          (element) => element.data
                        ),
                        label: "Gain",
                        color: colors,
                      },
                    ]}
                    xAxes={[
                      {
                        gridLines: {
                          display: false,
                        },
                      },
                    ]}
                  />
                </Col>
                <Col xs={12} md={6}>
                  <div className="d-flex flex-column h-100">
                    <PieChart
                      height={200}
                      className="m-auto"
                      digits={0}
                      format="currency"
                      title="Réparation montant gain HT / type"
                      legendDisplay={true}
                      data={this.state.data.gainType.map((element, idx) => ({
                        data: element.data,
                        labels: element.label,
                        color: colors[idx],
                      }))}
                      positionLegend="right"
                      alignLegend="left"
                    />
                  </div>
                </Col>
              </Row>
            </Card>
            <Card
              className={"mt-20"}
              classNameChildren="overflow-x-auto"
              title="Taux horaires HT moyen / région"
            >
              <Row>
                <Col xs={12}>
                  <BarChart
                    height={400}
                    title=""
                    digits={2}
                    format="currency"
                    labels={this.state.data.regionHourlyRateT2.map(
                      (element) => element.label
                    )}
                    dataSet={[
                      {
                        type: "bar",
                        label: "Taux horaire HT",
                        data: this.state.data.regionHourlyRateT2.map(
                          (element) => element.value
                        ),
                        color: colors,
                      },
                    ]}
                    xAxes={[
                      {
                        gridLines: {
                          display: false,
                        },
                      },
                    ]}
                  />
                </Col>
              </Row>
            </Card>
            <Card
              className={"mt-20"}
              classNameChildren="overflow-x-auto"
              title="Taux horaires moyens T2"
            >
              <Row>
                <Col xs={12}>
                  {this.getBarChart(
                    "Taux horaires moyens T2 / mois",
                    [
                      {
                        data: this.state.data.rateT2_1,
                        label: "Vitriers",
                        color: colors,
                        type: "line",
                      },
                      {
                        data: this.state.data.rateT2_2,
                        label: "Autre",
                        color: colors,
                        type: "line",
                      },
                      {
                        data: this.state.data.rateT2_3,
                        label: "Agent",
                        color: colors,
                        type: "line",
                      },
                      {
                        data: this.state.data.rateT2_4,
                        label: "Concession",
                        color: colors,
                        type: "line",
                      },
                    ],
                    "currency"
                  )}
                </Col>
              </Row>
            </Card>
            <Card
              className={"mt-20"}
              classNameChildren="overflow-x-auto"
              title="Autres"
            >
              <Row>
                <Col xs={12}>
                  {this.getBarChart(
                    "Coûts moyens réseaux / mois",
                    Object.keys(this.state.data.averageCost).map((key) => ({
                      data: this.state.data.averageCost[key].total,
                      label: key,
                      color: colors,
                      type: "line",
                    })),
                    "currency"
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  {this.getBarChart(
                    "Taux horaires moyens réseaux / mois",
                    Object.keys(this.state.data.averageRate).map((key) => ({
                      data: this.state.data.averageRate[key].total,
                      label: key,
                      color: colors,
                      type: "line",
                    })),
                    "currency"
                  )}
                </Col>
              </Row>
            </Card>
            <TabsCustom className={"mt-20"}>
              <TabsCustomHeader>
                {this.state.tabsData.map((element, idx) => (
                  <TabsCustomMenu
                    key={idx}
                    title={element}
                    active={this.state.tabsIndex === idx}
                    onClick={(e) => {
                      if (this.state.tabsIndex !== idx) {
                        this.setState({ tabsIndex: idx });
                      }
                    }}
                  />
                ))}
              </TabsCustomHeader>
              <TabsCustomBody noCard>
                <TabsCustomItem active={true}>
                  <TabsCustom>
                    <TabsCustomHeader>
                      {this.state.subTabsData.map((subElement, subIdx) => (
                        <TabsCustomMenu
                          key={subIdx}
                          title={subElement}
                          active={this.state.subTabsIndex === subIdx}
                          onClick={(e) => {
                            if (this.state.subTabsIndex !== subIdx) {
                              this.setState({ subTabsIndex: subIdx });
                            }
                          }}
                        />
                      ))}
                    </TabsCustomHeader>
                    <TabsCustomBody noCard>
                      <TabsCustomItem active={true}>
                        <Card
                          classNameChildren="overflow-x-auto"
                          title="Statistiques"
                        >
                          <Table isCSV>
                            <Thead>
                              <Tr>
                                <Th>#</Th>
                                <Th>N</Th>
                                <Th>N-1</Th>
                                <Th>N/N-1</Th>
                                <Th>Janvier</Th>
                                <Th>Février</Th>
                                <Th>Mars</Th>
                                <Th>Avril</Th>
                                <Th>Mai</Th>
                                <Th>Juin</Th>
                                <Th>Juillet</Th>
                                <Th>Août</Th>
                                <Th>Septembre</Th>
                                <Th>Octobre</Th>
                                <Th>Novembre</Th>
                                <Th>Décembre</Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              <Tr>
                                {[
                                  <Th>Nombre de missions reçues</Th>,
                                  ...this.getLine("totalOpen", null, 0),
                                ]}
                              </Tr>
                              <Tr>
                                {[
                                  <Th>Nombre de dossiers déposés</Th>,
                                  ...this.getLine("total", null, 0),
                                ]}
                              </Tr>
                              <Tr>
                                {[
                                  <Th>Coût moyen HT</Th>,
                                  ...this.getLine("averageCost", "currency"),
                                ]}
                              </Tr>
                              <Tr>
                                {[
                                  <Th>Gain moyen</Th>,
                                  ...this.getLine("averageGain", "currency"),
                                ]}
                              </Tr>
                              <Tr>
                                {[
                                  <Th>% gain</Th>,
                                  ...this.getLine(
                                    "percentageGain",
                                    "percentage"
                                  ),
                                ]}
                              </Tr>
                              <Tr>
                                {[
                                  <Th>Honoraire HT moyen</Th>,
                                  ...this.getLine(
                                    "averageHonorary",
                                    "currency"
                                  ),
                                ]}
                              </Tr>
                              <Tr>
                                {[
                                  <Th>Âge moyen véhicule</Th>,
                                  ...this.getLine("averageAge"),
                                ]}
                              </Tr>
                              <Tr>
                                {[
                                  <Th>% réorientation partenaires agréés</Th>,
                                  ...this.getLine(
                                    "percentageSD12",
                                    "percentage"
                                  ),
                                ]}
                              </Tr>
                              <Tr>
                                {[
                                  <Th>
                                    Prix HT moyen kit colle (forfait : collage)
                                  </Th>,
                                  ...this.getLine(
                                    "averagePriceGlue",
                                    "currency"
                                  ),
                                ]}
                              </Tr>
                              <Tr>
                                {[
                                  <Th>
                                    Prix HT moyen pare-brise (pièces : pb, pare
                                    brise, pare-brise)
                                  </Th>,
                                  ...this.getLine(
                                    "averagePriceWindshield",
                                    "currency"
                                  ),
                                ]}
                              </Tr>
                              <Tr>
                                {[
                                  <Th>
                                    Prix HT moyen calibrage adas (forfait :
                                    adas)
                                  </Th>,
                                  ...this.getLine(
                                    "averagePriceCalibration",
                                    "currency"
                                  ),
                                ]}
                              </Tr>
                              <Tr>
                                {[
                                  <Th>
                                    Prix HT moyen forfaits (COLLAGE, COLLE, ERD,
                                    FONGIBLES, GEL)
                                  </Th>,
                                  ...this.getLine(
                                    "averagePricePackage",
                                    "currency"
                                  ),
                                ]}
                              </Tr>
                              <Tr>
                                {[
                                  <Th>Taux horaire HT moyen T2 M.O.</Th>,
                                  ...this.getLine(
                                    "averageHourlyRateT2",
                                    "currency"
                                  ),
                                ]}
                              </Tr>
                              <Tr>
                                {[
                                  <Th>Temps moyen T2 M.O.</Th>,
                                  ...this.getLine("averageTimeT2"),
                                ]}
                              </Tr>
                              <Tr>
                                {[
                                  <Th>Délai Mission – Dépôt</Th>,
                                  ...this.getLine("averageDelayMissionClosing"),
                                ]}
                              </Tr>
                              <Tr>
                                {[
                                  <Th>
                                    Taux de dossiers avec désaccord chiffrage
                                    initial
                                  </Th>,
                                  ...this.getLine(
                                    "rateInitialCostDisagreement",
                                    "percentage"
                                  ),
                                ]}
                              </Tr>
                            </Tbody>
                          </Table>
                        </Card>
                      </TabsCustomItem>
                    </TabsCustomBody>
                  </TabsCustom>
                </TabsCustomItem>
              </TabsCustomBody>
            </TabsCustom>
            <Card
              className={"mt-20"}
              classNameChildren="overflow-x-auto"
              title="Liste des dossiers traités"
            >
              <Table
                isCSV
                getAllData={(callback) =>
                  this.setState(
                    { pageLength: folders.length, activePage: 1 },
                    callback
                  )
                }
                csvCallback={() => this.setState({ pageLength: 20 })}
              >
                <Thead>
                  <Tr>
                    <Th
                      ref={this.sortRef[0]}
                      sort={true}
                      onSort={(desc) => this.handleSortClick(desc, 0)}
                      nameSort={"reference"}
                    >
                      N° dossier
                    </Th>
                    <Th
                      ref={this.sortRef[1]}
                      sort={true}
                      onSort={(desc) => this.handleSortClick(desc, 1)}
                      nameSort={"brand"}
                    >
                      Marque
                    </Th>
                    <Th
                      ref={this.sortRef[2]}
                      sort={true}
                      onSort={(desc) => this.handleSortClick(desc, 2)}
                      nameSort={"model"}
                    >
                      Modèle
                    </Th>
                    <Th
                      ref={this.sortRef[3]}
                      sort={true}
                      onSort={(desc) => this.handleSortClick(desc, 3)}
                      nameSort={"sinister"}
                    >
                      N° sinistre
                    </Th>
                    <Th
                      ref={this.sortRef[4]}
                      sort={true}
                      onSort={(desc) => this.handleSortClick(desc, 4)}
                      nameSort={"network"}
                    >
                      Réseau
                    </Th>
                    <Th
                      ref={this.sortRef[5]}
                      sort={true}
                      onSort={(desc) => this.handleSortClick(desc, 5)}
                      nameSort={"gain"}
                    >
                      Gain HT
                    </Th>
                    <Th
                      ref={this.sortRef[6]}
                      sort={true}
                      onSort={(desc) => this.handleSortClick(desc, 6)}
                      nameSort={"typeGain"}
                    >
                      Type de gain
                    </Th>
                    <Th
                      ref={this.sortRef[7]}
                      sort={true}
                      onSort={(desc) => this.handleSortClick(desc, 7)}
                      nameSort={"totalReportDutyFree"}
                    >
                      Coût moyen HT
                    </Th>
                    <Th style={{ display: "none" }}>
                      Montant du chiffrage initial du réparateur
                    </Th>
                    <Th style={{ display: "none" }}>Montant de l’honoraire</Th>
                    <Th style={{ display: "none" }}>Genre du véhicule</Th>
                    <Th style={{ display: "none" }}>Carrosserie du véhicule</Th>
                    <Th style={{ display: "none" }}>Délais de dépôt du RE</Th>
                    <Th style={{ display: "none" }}>Nbr d’heure T1</Th>
                    <Th style={{ display: "none" }}>Nbr d’heure T2</Th>
                    <Th style={{ display: "none" }}>
                      RE déposé en désaccord économique pour les A PRIORI
                    </Th>
                    <Th style={{ display: "none" }}>Âge du véhicule</Th>
                    <Th style={{ display: "none" }}>Montant de la franchise</Th>
                    <Th style={{ display: "none" }}>
                      Date de réception mission
                    </Th>
                    <Th style={{ display: "none" }}>
                      Date de dépôt de dossier
                    </Th>
                    <Th style={{ display: "none" }}>Gain</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {folders
                    .slice(
                      (this.state.activePage - 1) * this.state.pageLength,
                      this.state.activePage * this.state.pageLength
                    )
                    .map((element, idx) => (
                      <Tr key={idx}>
                        <Td>{element.reference}</Td>
                        <Td>{element.brand}</Td>
                        <Td>{element.model}</Td>
                        <Td>{element.sinister}</Td>
                        <Td>{element.network}</Td>
                        <Td>{getCurrencyValue(element.gain)}</Td>
                        <Td>{element.typeGain}</Td>
                        <Td>{getCurrencyValue(element.totalReportDutyFree)}</Td>
                        <Td style={{ display: "none" }}>
                          {getCurrencyValue(element.initialRate)}
                        </Td>
                        <Td style={{ display: "none" }}>
                          {getCurrencyValue(element.honoraryDutyFree)}
                        </Td>
                        <Td style={{ display: "none" }}>{element.kind}</Td>
                        <Td style={{ display: "none" }}>{element.carType}</Td>
                        <Td style={{ display: "none" }}>
                          {element.dateMissionClosing}
                        </Td>
                        <Td style={{ display: "none" }}>{element.hourT1}</Td>
                        <Td style={{ display: "none" }}>{element.hourT2}</Td>
                        <Td style={{ display: "none" }}></Td>
                        <Td style={{ display: "none" }}>{element.age}</Td>
                        <Td style={{ display: "none" }}>
                          {getCurrencyValue(element.franchise)}
                        </Td>
                        <Td style={{ display: "none" }}>
                          {moment(element.dateMission).format("YYYY-MM-DD")}
                        </Td>
                        <Td style={{ display: "none" }}>
                          {moment(element.dateClosing).format("YYYY-MM-DD")}
                        </Td>
                        <Td style={{ display: "none" }}>
                          {element.gain && !element.invoiceAllTaxes
                            ? "Oui"
                            : "Non"}
                        </Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.pageLength}
                totalItemsCount={
                  this.state.data ? this.state.data.folders.length : 0
                }
                pageRangeDisplayed={4}
                onChange={(activePage) => {
                  this.setState({ activePage: activePage });
                }}
                firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
                lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
                prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
                nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
              />
            </Card>
          </>
        )}
        <Loader show={this.state.isLoad} />
      </>
    );
  }

  getBarChart = (title, dataSet, format = null) => {
    return (
      <BarChart
        title={title}
        height={400}
        digits={0}
        isLegend={true}
        format={format}
        dataSet={dataSet.map((element, idx) => {
          return {
            type: element.type || "bar",
            label: element.label,
            data: element.data,
            color: colors[idx],
          };
        })}
        xAxes={[
          {
            gridLines: {
              display: false,
            },
          },
        ]}
        yAxes={[
          {
            ticks: {
              display: true,
              min: 0,
              beginAtZero: true,
            },
          },
        ]}
      />
    );
  };
}

export default BrokenGlass;
