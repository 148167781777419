import React, { Component } from "react";
import {
  Modal,
  FormInput,
  FormRadio,
  Button,
  FormSelect,
  FormTextarea,
  Loader,
} from "ui-kit-ck-consultant";

import axiosConfig from "../../../utils/axiosConfig";

export class ModalVVNR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      reference: "",
      licensePlate: "",
      brand: "",
      folderId: null,
      isCertificatNonGageValide: null,
      isCoherenceDeclaration: null,
      isCoherenceQuestionnaireVol: null,
      isNombreInformationsClesCoherents: null,
      isFactureAchatJustificatifAchatCoherent: null,
      natureVendeurOptions: [
        { value: "1", text: "Achat concession" },
        { value: "2", text: "Achat particulier ou professionnel sans marque" },
        { value: "3", text: "Achat à l'étranger ou aux enchères" },
        { value: "4", text: "Achat auprès d'une société de leasing" },
      ],
      natureVendeur: "DEFAULT",
      isConditionReglement: null,
      paysOrigineVehiculeOptions: [
        { value: "1", text: "France" },
        { value: "2", text: "Allemagne" },
        { value: "3", text: "Italie" },
        { value: "4", text: "Espagne" },
        { value: "5", text: "Pays de l'Est" },
        { value: "6", text: "États-Unis" },
      ],
      paysOrigineVehicule: "DEFAULT",
      typeOwnerOptions: [
        { value: "1", text: "Particulier" },
        { value: "2", text: "Société" },
      ],
      typeVehicule: null,
      typeOwner: "DEFAULT",
      isAntivolAgreeSRA: null,
      isFactureAchatAntivol: null,
      isCleAntivol: null,
      isBilanSocieteCoherent: null,
      is_N_SIRET_valide: null,
      isChangementGerantRecent: null,
      isPrix_TVS_1500: null,
      isPerteFinanciere: null,
      isAchatSuperieur1An: null,
      isAnomaliesMajeures: null,
      isInterventionsRecentes: null,
      isRetardEntretien: null,
      isProblemesConnus: null,
      is_Historique_STAN_ANEA_HISTOVEC: null,
      avisDossier: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.show &&
      this.props.show !== prevProps.show &&
      this.props.isUpdate
    ) {
      let tmpState = this.state;
      Object.keys(this.props.data).map((key) => {
        tmpState[key] = this.props.data[key];
        return null;
      });
      this.setState(tmpState);
    }
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  getFolder = () => {
    if (this.state.reference) {
      this.setState(
        {
          isLoad: true,
        },
        () => {
          this.resetState(true, () => {
            axiosConfig("specialties/VVNR_VVR/get-folder", {
              data: {
                reference: this.state.reference,
              },
            }).then((res) => {
              if (res.data.success) {
                this.setState({
                  folderId: res.data.data.id,
                  typeVehicule: this.getTypeVehicule(res.data.data.kind),
                  licensePlate: res.data.data.licensePlate,
                  brand: res.data.data.brand,
                  isLoad: false,
                });
              } else {
                this.setState(
                  {
                    typeVehicule: null,
                    folderId: null,
                    licensePlate: "",
                    brand: "",
                    isLoad: false,
                  },
                  () => {
                    alert("dossier non trouvé");
                  }
                );
              }
            });
          });
        }
      );
    }
  };

  getTypeVehicule = (kind) => {
    if (["MTL", "MTT1", "MTT2", "CYCL", "CL"].includes(kind)) {
      return 1;
    } else {
      return 0;
    }
  };

  checkData = () => {
    if (
      this.state.reference &&
      this.state.isCertificatNonGageValide &&
      this.state.isCoherenceDeclaration &&
      this.state.isCoherenceQuestionnaireVol &&
      this.state.isNombreInformationsClesCoherents &&
      this.state.isFactureAchatJustificatifAchatCoherent &&
      this.state.natureVendeur !== "DEFAULT" &&
      this.state.isConditionReglement &&
      this.state.paysOrigineVehicule !== "DEFAULT" &&
      this.state.typeOwner !== "DEFAULT" &&
      (this.state.typeVehicule !== 1 || this.state.isAntivolAgreeSRA) &&
      (this.state.typeVehicule !== 1 || this.state.isFactureAchatAntivol) &&
      (this.state.typeVehicule !== 1 || this.state.isCleAntivol) &&
      (!["2"].includes(this.state.typeOwner) ||
        this.state.isBilanSocieteCoherent) &&
      (!["2"].includes(this.state.typeOwner) || this.state.is_N_SIRET_valide) &&
      (!["2"].includes(this.state.typeOwner) ||
        this.state.isChangementGerantRecent) &&
      (!["2"].includes(this.state.typeOwner) ||
        this.state.typeVehicule !== 0 ||
        this.state.isPrix_TVS_1500) &&
      this.state.isPerteFinanciere &&
      this.state.isAchatSuperieur1An &&
      (this.state.typeVehicule !== 0 || this.state.isAnomaliesMajeures) &&
      this.state.isInterventionsRecentes &&
      this.state.isRetardEntretien &&
      this.state.isProblemesConnus &&
      this.state.is_Historique_STAN_ANEA_HISTOVEC
    ) {
      return true;
    } else {
      return false;
    }
  };

  getData = () => {
    return {
      reference: this.state.reference,
      isCertificatNonGageValide: this.state.isCertificatNonGageValide,
      isCoherenceDeclaration: this.state.isCoherenceDeclaration,
      isCoherenceQuestionnaireVol: this.state.isCoherenceQuestionnaireVol,
      isNombreInformationsClesCoherents:
        this.state.isNombreInformationsClesCoherents,
      isFactureAchatJustificatifAchatCoherent:
        this.state.isFactureAchatJustificatifAchatCoherent,
      natureVendeur: this.state.natureVendeur,
      isConditionReglement: this.state.isConditionReglement,
      paysOrigineVehicule: this.state.paysOrigineVehicule,
      typeOwner: this.state.typeOwner,
      isAntivolAgreeSRA: this.state.isAntivolAgreeSRA,
      isFactureAchatAntivol: this.state.isFactureAchatAntivol,
      isCleAntivol: this.state.isCleAntivol,
      isBilanSocieteCoherent: this.state.isBilanSocieteCoherent,
      is_N_SIRET_valide: this.state.is_N_SIRET_valide,
      isChangementGerantRecent: this.state.isChangementGerantRecent,
      isPrix_TVS_1500: this.state.isPrix_TVS_1500,
      isCategorieVehicule: this.state.isCategorieVehicule,
      isPerteFinanciere: this.state.isPerteFinanciere,
      isAchatSuperieur1An: this.state.isAchatSuperieur1An,
      isAnomaliesMajeures: this.state.isAnomaliesMajeures,
      isInterventionsRecentes: this.state.isInterventionsRecentes,
      isRetardEntretien: this.state.isRetardEntretien,
      isProblemesConnus: this.state.isProblemesConnus,
      is_Historique_STAN_ANEA_HISTOVEC:
        this.state.is_Historique_STAN_ANEA_HISTOVEC,
      avisDossier: this.state.avisDossier,
    };
  };

  resetState = (isReference, callback) => {
    this.setState(
      {
        folderId: isReference ? this.state.folderId : null,
        reference: isReference ? this.state.reference : "",
        isCertificatNonGageValide: isReference
          ? this.state.isCertificatNonGageValide
          : null,
        isCoherenceDeclaration: isReference
          ? this.state.isCoherenceDeclaration
          : null,
        isCoherenceQuestionnaireVol: isReference
          ? this.state.isCoherenceQuestionnaireVol
          : null,
        isNombreInformationsClesCoherents: isReference
          ? this.state.isNombreInformationsClesCoherents
          : null,
        isFactureAchatJustificatifAchatCoherent: isReference
          ? this.state.isFactureAchatJustificatifAchatCoherent
          : null,
        natureVendeur: isReference ? this.state.natureVendeur : "DEFAULT",
        isConditionReglement: isReference
          ? this.state.isConditionReglement
          : null,
        typeVehicule: null,
        paysOrigineVehicule: isReference
          ? this.state.paysOrigineVehicule
          : "DEFAULT",
        typeOnwer: isReference ? this.state.typeOnwer : "DEFAULT",
        isAntivolAgreeSRA: null,
        isFactureAchatAntivol: null,
        isCleAntivol: null,
        isBilanSocieteCoherent: null,
        is_N_SIRET_valide: null,
        isChangementGerantRecent: null,
        isPrix_TVS_1500: null,
        isPerteFinanciere: null,
        isAchatSuperieur1An: null,
        isAnomaliesMajeures: null,
        isInterventionsRecentes: null,
        isRetardEntretien: null,
        isProblemesConnus: null,
        is_Historique_STAN_ANEA_HISTOVEC: null,
        avisDossier: "",
      },
      callback
    );
  };

  closeModal = () => {
    this.resetState(false, this.props.onClose);
  };

  validate = () => {
    if (this.checkData()) {
      this.setState(
        {
          isLoad: true,
        },
        () => {
          if (this.props.isUpdate) {
            axiosConfig("specialties/VVNR_VVR/update-VVNR", {
              data: {
                id: this.props.id,
                data: this.getData(),
              },
            }).then((res) => {
              if (res.data.success) {
                this.setState(
                  {
                    isLoad: false,
                  },
                  this.props.onClose
                );
              } else {
                this.setState(
                  {
                    isLoad: false,
                  },
                  () => {
                    alert("Une erreur est survenue");
                  }
                );
              }
            });
          } else {
            axiosConfig("specialties/VVNR_VVR/insert-VVNR", {
              data: {
                folder: this.state.folderId,
                data: this.getData(),
              },
            }).then((res) => {
              if (res.data.success) {
                this.setState(
                  {
                    isLoad: false,
                  },
                  this.props.onClose
                );
              } else {
                this.setState(
                  {
                    isLoad: false,
                  },
                  () => {
                    alert("Une erreur est survenue");
                  }
                );
              }
            });
          }
        }
      );
    } else {
      alert("Veuillez remplir tous les champs obligatoires");
    }
  };

  render() {
    return (
      <>
        <Modal
          title={`Notation VVNR ${
            this.props.isUpdate ? this.state.reference : ""
          }`}
          show={this.props.show}
          onClose={this.closeModal}
        >
          {/* */}
          <FormInput
            className="my-10"
            type="text"
            placeholder="Référence"
            title="Référence"
            name="reference"
            value={this.state.reference}
            onChange={this.handleInputChange}
            required={true}
            onBlur={this.getFolder}
            disabled={this.props.isUpdate}
          />
          <FormInput
            className="my-10"
            type="text"
            placeholder="Immatriculation"
            title="Immatriculation"
            name="licensePlate"
            value={this.state.licensePlate}
            onChange={this.handleInputChange}
            disabled={true}
          />
          <FormInput
            className="my-10"
            type="text"
            placeholder="Marque"
            title="Marque"
            name="brand"
            value={this.state.brand}
            onChange={this.handleInputChange}
            disabled={true}
          />
          <FormSelect
            className="my-10"
            title="Statut propriétaire"
            init="Veuillez sélectionner une type"
            name="typeOwner"
            ignore={true}
            value={this.state.typeOwner}
            options={this.state.typeOwnerOptions}
            onChange={this.handleInputChange}
            required={true}
          />
          {/* */}
          <h2>Analyse administrative</h2>
          <div style={{ marginLeft: "2rem" }}>
            <h3>
              Certificat de non gage valide <span className="red">*</span>
            </h3>
            <FormRadio
              className="mb-10"
              checked={this.state.isCertificatNonGageValide === "1"}
              name="isCertificatNonGageValide"
              text="Oui"
              value="1"
              onChange={this.handleInputChange}
            />
            <FormRadio
              className="mb-10"
              checked={this.state.isCertificatNonGageValide === "2"}
              name="isCertificatNonGageValide"
              text="Non"
              value="2"
              onChange={this.handleInputChange}
            />
            <h3>
              Cohérence déclaration ? <span className="red">*</span>
            </h3>
            <FormRadio
              className="mb-10"
              checked={this.state.isCoherenceDeclaration === "1"}
              name="isCoherenceDeclaration"
              text="Oui"
              value="1"
              onChange={this.handleInputChange}
            />
            <FormRadio
              className="mb-10"
              checked={this.state.isCoherenceDeclaration === "2"}
              name="isCoherenceDeclaration"
              text="Non"
              value="2"
              onChange={this.handleInputChange}
            />
            <h3>
              Cohérence questionnaire VOL ? <span className="red">*</span>
            </h3>
            <FormRadio
              className="mb-10"
              checked={this.state.isCoherenceQuestionnaireVol === "1"}
              name="isCoherenceQuestionnaireVol"
              text="Oui"
              value="1"
              onChange={this.handleInputChange}
            />
            <FormRadio
              className="mb-10"
              checked={this.state.isCoherenceQuestionnaireVol === "2"}
              name="isCoherenceQuestionnaireVol"
              text="Non"
              value="2"
              onChange={this.handleInputChange}
            />
            <FormRadio
              className="mb-10"
              checked={this.state.isCoherenceQuestionnaireVol === "3"}
              name="isCoherenceQuestionnaireVol"
              text="NON TRANSMIS"
              value="3"
              onChange={this.handleInputChange}
            />
            <FormRadio
              className="mb-10"
              checked={this.state.isCoherenceQuestionnaireVol === "4"}
              name="isCoherenceQuestionnaireVol"
              text="Complètement renseigné"
              value="4"
              onChange={this.handleInputChange}
            />
            <FormRadio
              className="mb-10"
              checked={this.state.isCoherenceQuestionnaireVol === "5"}
              name="isCoherenceQuestionnaireVol"
              text="Partiellement renseigné"
              value="5"
              onChange={this.handleInputChange}
            />
          </div>
          {/* */}
          <h2>Conditions d'acquisition</h2>
          <div style={{ marginLeft: "2rem" }}>
            <h3>
              Facture d'achat ou justificatif d'achat cohérent ?{" "}
              <span className="red">*</span>
            </h3>
            <FormRadio
              className="mb-10"
              checked={
                this.state.isFactureAchatJustificatifAchatCoherent === "1"
              }
              name="isFactureAchatJustificatifAchatCoherent"
              text="Oui"
              value="1"
              onChange={this.handleInputChange}
            />
            <FormRadio
              className="mb-10"
              checked={
                this.state.isFactureAchatJustificatifAchatCoherent === "2"
              }
              name="isFactureAchatJustificatifAchatCoherent"
              text="Non"
              value="2"
              onChange={this.handleInputChange}
            />
            <FormSelect
              className="my-10"
              title="Nature du vendeur"
              init="Veuillez sélectionner une nature"
              name="natureVendeur"
              ignore={true}
              value={this.state.natureVendeur}
              options={this.state.natureVendeurOptions}
              onChange={this.handleInputChange}
              required={true}
            />
            <h3>
              Condition de règlement <span className="red">*</span>
            </h3>
            <FormRadio
              className="mb-10"
              checked={this.state.isConditionReglement === "1"}
              name="isConditionReglement"
              text="Virement bancaire"
              value="1"
              onChange={this.handleInputChange}
            />
            <FormRadio
              className="mb-10"
              checked={this.state.isConditionReglement === "2"}
              name={"isConditionReglement"}
              text="Chèque"
              value="2"
              onChange={this.handleInputChange}
            />
            <FormRadio
              className="mb-10"
              checked={this.state.isConditionReglement === "3"}
              name="isConditionReglement"
              text="Espèce >1500 € entre particuliers ?"
              value="3"
              onChange={this.handleInputChange}
            />
            <FormRadio
              className="mb-10"
              checked={this.state.isConditionReglement === "4"}
              name="isConditionReglement"
              text="Espèce >1000 € à un professionnel ?"
              value="4"
              onChange={this.handleInputChange}
            />
            <FormRadio
              className="mb-10"
              checked={this.state.isConditionReglement === "6"}
              name="isConditionReglement"
              text="Carte bleue"
              value="6"
              onChange={this.handleInputChange}
            />
            <FormRadio
              className="mb-10"
              checked={this.state.isConditionReglement === "7"}
              name="isConditionReglement"
              text="Autre"
              value="7"
              onChange={this.handleInputChange}
            />
            <h3>
              Achat {">"} à un an ? <span className="red">*</span>
            </h3>
            <FormRadio
              className="mb-10"
              checked={this.state.isAchatSuperieur1An === "1"}
              name="isAchatSuperieur1An"
              text="Oui"
              value="1"
              onChange={this.handleInputChange}
            />
            <FormRadio
              className="mb-10"
              checked={this.state.isAchatSuperieur1An === "2"}
              name="isAchatSuperieur1An"
              text="Non"
              value="2"
              onChange={this.handleInputChange}
            />
            <h3>
              Perte financière ? <span className="red">*</span>
            </h3>
            <FormRadio
              className="mb-10"
              checked={this.state.isPerteFinanciere === "1"}
              name="isPerteFinanciere"
              text="Oui"
              value="1"
              onChange={this.handleInputChange}
            />
            <FormRadio
              className="mb-10"
              checked={this.state.isPerteFinanciere === "2"}
              name="isPerteFinanciere"
              text="Non"
              value="2"
              onChange={this.handleInputChange}
            />
          </div>
          {/* */}
          {this.state.typeVehicule === 1 ? <h2>Spécificité 2 roues</h2> : null}
          <div style={{ marginLeft: "2rem" }}>
            {this.state.typeVehicule === 1 ? (
              <>
                <h3>
                  Antivol agréé SRA ?<span className="red">*</span>
                </h3>
                <FormRadio
                  className="mb-10"
                  checked={this.state.isAntivolAgreeSRA === "1"}
                  name="isAntivolAgreeSRA"
                  text="Oui"
                  value="1"
                  onChange={this.handleInputChange}
                />
                <FormRadio
                  className="mb-10"
                  checked={this.state.isAntivolAgreeSRA === "2"}
                  name="isAntivolAgreeSRA"
                  text="Non"
                  value="2"
                  onChange={this.handleInputChange}
                />
              </>
            ) : null}
            {this.state.typeVehicule === 1 ? (
              <>
                <h3>
                  Facture achat antivol ? <span className="red">*</span>
                </h3>
                <FormRadio
                  className="mb-10"
                  checked={this.state.isFactureAchatAntivol === "1"}
                  name="isFactureAchatAntivol"
                  text="Oui"
                  value="1"
                  onChange={this.handleInputChange}
                />
                <FormRadio
                  className="mb-10"
                  checked={this.state.isFactureAchatAntivol === "2"}
                  name="isFactureAchatAntivol"
                  text="Non"
                  value="2"
                  onChange={this.handleInputChange}
                />
              </>
            ) : null}
            {this.state.typeVehicule === 1 ? (
              <>
                <h3>
                  Clé antivol ? <span className="red">*</span>
                </h3>
                <FormRadio
                  className="mb-10"
                  checked={this.state.isCleAntivol === "1"}
                  name="isCleAntivol"
                  text="Oui"
                  value="1"
                  onChange={this.handleInputChange}
                />
                <FormRadio
                  className="mb-10"
                  checked={this.state.isCleAntivol === "2"}
                  name="isCleAntivol"
                  text="Non"
                  value="2"
                  onChange={this.handleInputChange}
                />
              </>
            ) : null}
          </div>
          {/* */}
          {["2"].includes(this.state.typeOwner) ? (
            <h2>Spécificité statut société</h2>
          ) : null}
          <div style={{ marginLeft: "2rem" }}>
            {["2"].includes(this.state.typeOwner) ? (
              <>
                <h3>
                  Bilan société cohérent ? (infogreffe.fr et societe.com){" "}
                  <span className="red">*</span>
                </h3>
                <FormRadio
                  className="mb-10"
                  checked={this.state.isBilanSocieteCoherent === "1"}
                  name="isBilanSocieteCoherent"
                  text="Oui"
                  value="1"
                  onChange={this.handleInputChange}
                />
                <FormRadio
                  className="mb-10"
                  checked={this.state.isBilanSocieteCoherent === "2"}
                  name="isBilanSocieteCoherent"
                  text="Non"
                  value="2"
                  onChange={this.handleInputChange}
                />
              </>
            ) : null}
            {["2"].includes(this.state.typeOwner) ? (
              <>
                <h3>
                  N° de SIRET valide ? <span className="red">*</span>
                </h3>
                <FormRadio
                  className="mb-10"
                  checked={this.state.is_N_SIRET_valide === "1"}
                  name="is_N_SIRET_valide"
                  text="Oui"
                  value="1"
                  onChange={this.handleInputChange}
                />
                <FormRadio
                  className="mb-10"
                  checked={this.state.is_N_SIRET_valide === "2"}
                  name="is_N_SIRET_valide"
                  text="Non"
                  value="2"
                  onChange={this.handleInputChange}
                />
              </>
            ) : null}
            {["2"].includes(this.state.typeOwner) ? (
              <>
                <h3>
                  Changement de gérant récent ? <span className="red">*</span>
                </h3>
                <FormRadio
                  className="mb-10"
                  checked={this.state.isChangementGerantRecent === "1"}
                  name="isChangementGerantRecent"
                  text="Oui"
                  value="1"
                  onChange={this.handleInputChange}
                />
                <FormRadio
                  className="mb-10"
                  checked={this.state.isChangementGerantRecent === "2"}
                  name="isChangementGerantRecent"
                  text="Non"
                  value="2"
                  onChange={this.handleInputChange}
                />
              </>
            ) : null}
            {["2"].includes(this.state.typeOwner) &&
            this.state.typeVehicule === 0 ? (
              <>
                <h3>
                  Prix TVS {">"} à 1500 € ?
                  (kilometresentreprise.com/spip.php?page=calcultvs ){" "}
                  <span className="red">*</span>
                </h3>
                <FormRadio
                  className="mb-10"
                  checked={this.state.isPrix_TVS_1500 === "1"}
                  name="isPrix_TVS_1500"
                  text="Oui"
                  value="1"
                  onChange={this.handleInputChange}
                />
                <FormRadio
                  className="mb-10"
                  checked={this.state.isPrix_TVS_1500 === "2"}
                  name="isPrix_TVS_1500"
                  text="Non"
                  value="2"
                  onChange={this.handleInputChange}
                />
              </>
            ) : null}
          </div>
          {/* */}
          <h2>Analyse documents techniques</h2>
          <div style={{ marginLeft: "2rem" }}>
            <FormSelect
              className="my-10"
              title="Pays d'origine du véhicule"
              init="Veuillez sélectionner un pays"
              name="paysOrigineVehicule"
              ignore={true}
              value={this.state.paysOrigineVehicule}
              options={this.state.paysOrigineVehiculeOptions}
              onChange={this.handleInputChange}
              required={true}
            />
            <h3>
              Nombre et informations clés cohérents ?{" "}
              <span className="red">*</span>
            </h3>
            <FormRadio
              className="mb-10"
              checked={this.state.isNombreInformationsClesCoherents === "1"}
              name="isNombreInformationsClesCoherents"
              text="Oui"
              value="1"
              onChange={this.handleInputChange}
            />
            <FormRadio
              className="mb-10"
              checked={this.state.isNombreInformationsClesCoherents === "2"}
              name="isNombreInformationsClesCoherents"
              text="Non"
              value="2"
              onChange={this.handleInputChange}
            />
            {this.state.typeVehicule === 0 ? (
              <>
                <h3>
                  Contrôle technique <span className="red">*</span>
                </h3>
                <FormRadio
                  className="mb-10"
                  checked={this.state.isAnomaliesMajeures === "1"}
                  name="isAnomaliesMajeures"
                  text="Moins de 5 anomalies majeures"
                  value="1"
                  onChange={this.handleInputChange}
                />
                <FormRadio
                  className="mb-10"
                  checked={this.state.isAnomaliesMajeures === "2"}
                  name="isAnomaliesMajeures"
                  text="Plus de 5 anomalies majeures"
                  value="2"
                  onChange={this.handleInputChange}
                />
              </>
            ) : null}
            <>
              <h3>
                Interventions récentes ? <span className="red">*</span>
              </h3>
              <FormRadio
                className="mb-10"
                checked={this.state.isInterventionsRecentes === "1"}
                name="isInterventionsRecentes"
                text="Oui"
                value="1"
                onChange={this.handleInputChange}
              />
              <FormRadio
                className="mb-10"
                checked={this.state.isInterventionsRecentes === "2"}
                name="isInterventionsRecentes"
                text="Non"
                value="2"
                onChange={this.handleInputChange}
              />
            </>
            <>
              <h3>
                Retard entretien ? <span className="red">*</span>
              </h3>
              <FormRadio
                className="mb-10"
                checked={this.state.isRetardEntretien === "1"}
                name="isRetardEntretien"
                text="Oui"
                value="1"
                onChange={this.handleInputChange}
              />
              <FormRadio
                className="mb-10"
                checked={this.state.isRetardEntretien === "2"}
                name="isRetardEntretien"
                text="Non"
                value="2"
                onChange={this.handleInputChange}
              />
            </>
            <>
              <h3>
                Problèmes connus (fiches-auto.fr) <span className="red">*</span>
              </h3>
              <FormRadio
                className="mb-10"
                checked={this.state.isProblemesConnus === "1"}
                name="isProblemesConnus"
                text="Oui"
                value="1"
                onChange={this.handleInputChange}
              />
              <FormRadio
                className="mb-10"
                checked={this.state.isProblemesConnus === "2"}
                name="isProblemesConnus"
                text="Non"
                value="2"
                onChange={this.handleInputChange}
              />
            </>
            <>
              <h3>
                Historique STAN, ANEA, HISTOVEC ok ?{" "}
                <span className="red">*</span>
              </h3>
              <FormRadio
                className="mb-10"
                checked={this.state.is_Historique_STAN_ANEA_HISTOVEC === "1"}
                name="is_Historique_STAN_ANEA_HISTOVEC"
                text="Oui"
                value="1"
                onChange={this.handleInputChange}
              />
              <FormRadio
                className="mb-10"
                checked={this.state.is_Historique_STAN_ANEA_HISTOVEC === "2"}
                name="is_Historique_STAN_ANEA_HISTOVEC"
                text="Non"
                value="2"
                onChange={this.handleInputChange}
              />
            </>
          </div>
          <FormTextarea
            className="my-10"
            rows={5}
            title="Avis dossier"
            name="avisDossier"
            placeholder="Saisir votre texte ici"
            value={this.state.avisDossier}
            onChange={this.handleInputChange}
          />
          <Button text="Valider" onClick={this.validate} />
        </Modal>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}

export default ModalVVNR;
