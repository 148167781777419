import React, { Component } from "react";
import { Card, Table, Thead, Tr, Th, Tbody, Td } from "ui-kit-ck-consultant";

export default class StatisticalMonitoring extends Component {
  render() {
    const data =
      this.props.typeSearch !== 4
        ? this.props.data
        : this.props.data.map((arrayData) => {
            return arrayData.slice(0, 4);
          });
    return (
      <div className={this.props.className}>
        <Card
          className={this.props.print === true ? "for-print-color" : " "}
          title={this.props.cardTitle}
          style={{ height: "400px" }}
          classNameChildren={"overflow-x-auto"}
        >
          <Table>
            <Thead>
              {this.props.typeSearch !== 4 ? (
                <Tr>
                  <Th hide></Th>
                  <Th spaceRight radiusTopLeft colSpan={3}>
                    {this.props.isUser
                      ? this.props.type === 3
                        ? "Assistant"
                        : "Expert"
                      : this.props.typeSearch === 1
                      ? "Agence"
                      : "Région"}
                  </Th>
                  <Th spaceRight spaceLeft colSpan={3}>
                    {this.props.isUser ? "Agence" : this.props.typeSearch === 1
                      ? "Région"
                      : "Groupe"}
                  </Th>
                  <Th cols={2} spaceLeft radiusTopRight colSpan={2}>
                    {this.props.isUser
                      ? this.props.type === 3
                        ? "Assistant/Agence"
                        : "Expert/Agence"
                      : this.props.typeSearch === 1
                      ? "Agence/Région"
                      : "Région/Groupe"}
                  </Th>
                </Tr>
              ) : (
                <Tr>
                  <Th hide></Th>
                  <Th radiusTopLeft radiusTopRight colSpan={3}>
                    {"Groupe"}
                  </Th>
                </Tr>
              )}
              <Tr>
                <Th spaceRight radiusTopLeft hide />
                <Th>N-1</Th>
                <Th>N</Th>
                <Th spaceRight>N/N-1</Th>
                {this.props.typeSearch !== 4 ? (
                  <>
                    <Th spaceLeft>N-1</Th>
                    <Th>N</Th>
                    <Th spaceRight>N/N-1</Th>
                    <Th spaceLeft>N-1</Th>
                    <Th>N</Th>
                  </>
                ) : null}
              </Tr>
            </Thead>
            <Tbody
              className={
                this.props.print === true
                  ? " p-0 text-center height-print"
                  : " "
              }
            >
              {data.map((element, idx) => (
                <Tr
                  key={idx}
                  className={
                    this.props.print === true
                      ? " p-0 text-center height-print"
                      : " "
                  }
                >
                  {element.map((value, id) => (
                    <Td
                      key={id}
                      spaceRight={[3, 6].includes(id) ? true : false}
                      spaceLeft={[4, 7].includes(id) ? true : false}
                      className={
                        this.props.print === true
                          ? " p-0 text-center height-print"
                          : " "
                      }
                    >
                      {Array.isArray(value) ? value[0] : value}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Card>
      </div>
    );
  }
}
