import React, { Component } from "react";
import { Col } from "react-flexbox-grid";
import { Bar } from "react-chartjs-2";

import Chart from "chart.js/auto";

const colors = [
  "#4FBC9D",
  "#E85D7D",
  "#F19A3E",
  "#E6DB3F",
  "#C45AF6",
  "#56C3EC",
  "#4AD95F",
  "#00c0ba",
];

const getColor = (color) => {
  if (color === "green") {
    return "#4FBC9D";
  } else if (color === "red") {
    return "#E85D7D";
  } else if (color === "orange") {
    return "#F19A3E";
  } else if (color === "yellow") {
    return "#E6DB3F";
  } else if (color === "purple") {
    return "#C45AF6";
  } else if (color === "blue") {
    return "#56C3EC";
  } else if (color === "light-green") {
    return "#4AD95F";
  } else if (color === "cyan") {
    return "#00c0ba";
  }
};
export default class CardChart extends Component {
  render() {
    const { title, axis, data, isFull, height, labels } = this.props;

    return (
      <Col xs={12} md={12} lg={isFull ? 12 : 6} className="col_full_height">
        <div
          className="mb-10 mb-20-xl h-100"
          style={{
            position: "relative",
            borderRadius: "8px",
            backgroundColor: "var(--white)",
            color: "var(--black)",
            boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
            minHeight: "85px",
            display: "flex",
            overflow: "hidden",
            flexDirection: "column",
            padding: 15,
          }}
        >
          <div
            className="d-flex"
            style={{
              justifyContent: "space-between",
            }}
          >
            <h2
              style={{
                color: "#535374",
                fontSize: "13px",
                fontWeight: "700",
                margin: 0,
              }}
            >
              {title}
            </h2>
            <div
              style={{
                display: "inline-flex",
                flexDirection: "column",
              }}
            >
              {data.map((element, idx) => (
                <div
                  key={idx}
                  className="d-flex"
                  style={{
                    alignItems: "center",
                    color: "#535374",
                    fontSize: "13px",
                  }}
                >
                  <div
                    className="mr-5"
                    style={{
                      width: 8,
                      height: 8,
                      borderRadius: 4,
                      backgroundColor: element.color
                        ? getColor(element.color)
                        : colors[idx],
                    }}
                  />
                  <span>{element.label}</span>
                </div>
              ))}
            </div>
          </div>
          <Bar
            className="mt-10"
            height={height}
            data={{
              labels: labels || [
                "Janvier",
                "Février",
                "Mars",
                "Avril",
                "Mai",
                "Juin",
                "Juillet",
                "Août",
                "Septembre",
                "Octobre",
                "Novembre",
                "Décembre",
              ],
              datasets: data.map((element, idx) => ({
                type: "line",
                yAxisID: element.yAxisID,
                label: element.label,
                data: element.data.map((value) => (value ? value : null)),
                borderWidth: 1.5,
                borderColor: element.color
                  ? getColor(element.color)
                  : colors[idx],
              })),
            }}
            options={{
              bezierCurve: true,
              elements: {
                line: {
                  tension: 0.5,
                },
                point: {
                  radius: 2,
                  pointRadius: 2,
                },
              },
              plugins: {
                legend: { display: false },
              },
              scales: {
                y1: {
                  min: axis && axis.y1 && axis.y1.min,
                  max: axis && axis.y1 && axis.y1.max,
                  beginAtZero: false,
                  ticks: {
                    display: false,
                  },
                },
                y2: {
                  min: axis && axis.y2 && axis.y2.min,
                  max: axis && axis.y2 && axis.y2.max,
                  beginAtZero: false,
                  ticks: {
                    display: false,
                  },
                  grid: {
                    display: false,
                    drawBorder: false,
                  },
                },
                y3: {
                  min: axis && axis.y3 && axis.y3.min,
                  max: axis && axis.y3 && axis.y3.max,
                  beginAtZero: false,
                  ticks: {
                    display: false,
                  },
                  grid: {
                    display: false,
                    drawBorder: false,
                  },
                },
                y4: {
                  min: axis && axis.y4 && axis.y4.min,
                  max: axis && axis.y4 && axis.y4.max,
                  beginAtZero: false,
                  ticks: {
                    display: false,
                  },
                  grid: {
                    display: false,
                    drawBorder: false,
                  },
                },
                x: {
                  grid: {
                    display: false,
                  },
                },
              },
            }}
          />
        </div>
      </Col>
    );
  }
}
