import React, { Component } from "react";
import {
  TabsCustom,
  TabsCustomBody,
  TabsCustomHeader,
  TabsCustomItem,
  TabsCustomMenu,
  Nav,
  Loader,
  BarChart,
  Card,
  Button,
  FormCheckbox,
} from "ui-kit-ck-consultant";
import moment from "moment";
import FadeIn from "react-fade-in/lib/FadeIn";
import { Row, Col } from "react-flexbox-grid";

import axiosConfig from "../../../utils/axiosConfig";

import AuthContext from "../../../context/AuthContext";

import ShowOptionsNGRA from "../../../components/general/ShowOptionsNGRA-v2";
import TableRank from "../../../components/projectE/TableRank";
import TablePart from "../../../components/projectE/TablePart";
import CardScore from "../../../components/projectE/CardScore";
import CardProjectE from "../../../components/projectE/CardProjectE";
import CardRound from "../../../components/projectE/CardRound";
import CardChart from "../../../components/projectE/CardChart";

import carSvg from "../../../images/car.svg";
import truckSvg from "../../../images/truck.svg";
import carDoorPreSvg from "../../../images/car_door_pre.svg";
import carDoorPqeSvg from "../../../images/car_door_pqe.svg";
import paintSvg from "../../../images/paint.svg";
import toolsSvg from "../../../images/tools.svg";
import buildingSvg from "../../../images/building.svg";
import CountryMap from "../../../components/projectE/CountryMap";

import { getCurrencyValue, getValue } from "../../../utils/general";

const randomColor = () => {
  const colors = [
    "#4FBC9D",
    "#E85D7D",
    "#F19A3E",
    "#E6DB3F",
    "#C45AF6",
    "#56C3EC",
    "#00c0ba",
  ];
  const randomIdx = Math.floor(Math.random() * colors.length);

  return colors[randomIdx];
};
export default class ProjectE extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      data: null,
      menuTabs: [
        "Général",
        "Expertise terrain",
        "Expertise EAD",
        "Expertise selfcare",
      ],
      rankTabs: [
        "Région",
        "Agence",
        "Expert",
        "Compagnie",
        "Réparateur",
        "Marque",
        "Modèle",
        "Pièces pilotes",
        "Dossiers",
      ],
      index: 0,
      rankIndex: 0,
      // filters
      dateStart: null,
      dateEnd: null,
      network: 0,
      region: 0,
      group: 0,
      agency: 0,
      companyMultiples: [],
      builder: 0,
      brand: [],
      pre: true,
      change: true,
      changeConst: true,
      repair: true,
      paint: true,
      travelingClient: true,
      travelingExpert: true,
      local: true,
      car0: true,
      car1: true,
    };
  }

  static contextType = AuthContext;

  componentDidUpdate(prevProps, prevState) {
    if (prevState.index !== this.state.index) {
      this.getData();
    }
  }

  getData() {
    this.setState({ isLoad: true }, () => {
      let natureExpertise = [];
      let isEadSoc = false;

      if (this.state.index === 1) {
        natureExpertise = ["1", "Q", "A", "C"];
      } else if (this.state.index > 1) {
        natureExpertise = ["J"];
      }

      if (this.state.index === 3) {
        isEadSoc = true;
      }

      axiosConfig("project-e/get-data", {
        data: {
          dateStart: this.state.dateStart,
          dateEnd: this.state.dateEnd,
          network: this.state.network,
          group: this.state.group,
          region: this.state.region,
          agency: this.state.agency,
          companyMultiples: this.state.companyMultiples,
          builder: this.state.builder,
          brand: this.state.brand,
          pre: this.state.pre,
          change: this.state.change,
          changeConst: this.state.changeConst,
          repair: this.state.repair,
          paint: this.state.paint,
          travelingClient: this.state.travelingClient,
          travelingExpert: this.state.travelingExpert,
          local: this.state.local,
          car0: this.state.car0,
          car1: this.state.car1,
          natureExpertise,
          isEadSoc,
        },
      }).then((res) => {
        if (res.data.success === true) {
          this.setState({ data: res.data.data, isLoad: false });
        } else {
          this.setState({ data: null, isLoad: false });
        }
      });
    });
  }

  getDataCSV() {
    this.setState({ isLoad: true }, () => {
      let natureExpertise = [];
      let isEadSoc = false;

      if (this.state.index === 1) {
        natureExpertise = ["1", "Q", "A", "C"];
      } else if (this.state.index > 1) {
        natureExpertise = ["J"];
      }

      if (this.state.index === 3) {
        isEadSoc = true;
      }

      axiosConfig("project-e/get-data-csv", {
        data: {
          dateStart: this.state.dateStart,
          dateEnd: this.state.dateEnd,
          network: this.state.network,
          group: this.state.group,
          region: this.state.region,
          agency: this.state.agency,
          companyMultiples: this.state.companyMultiples,
          builder: this.state.builder,
          brand: this.state.brand,
          natureExpertise,
          car0: this.state.car0,
          car1: this.state.car1,
          isEadSoc,
        },
      }).then((res) => {
        if (res.data.success === true) {
          this.setState({ isLoad: false }, () => {
            const header = [
              "Numéro de dossier",
              "Numéro de sinistre",
              "Assurance",
              "Région",
              "Agence",
              "Expert",
              "Nature expertise",
              "Marque",
              "Modèle",
              "Genre",
              "Energie",
              "Kilométrage",
              "Mise en circulation",
              "Total HT rapport",
              "Ém. CO2e du dossier (kg)",
              "Nb de pièces à réparer",
              "Nb d'heures M.O.",
              "Ém. CO2e M.O (kg)",
              "Gain CO2e réparation",
              "Nb pièces à peindre",
              "Ém. CO2e ingrédients peinture (kg)",
              "Nb de pièces à changer neuves",
              "Poids pièces neuves (kg)",
              "Ém. CO2e pièces neuves (kg)",
              "Nb de pièces à changer réemploi",
              "Poids pièces réemploi (kg)",
              "Ém. CO2e pièces réemploi (kg)",
              "Gain CO2e réemploi",
              "km Expert",
              "Nb trajets Expert",
              "Ém. CO2e Expert (kg)",
              "km client",
              "Nb trajets client",
              "Ém. CO2e client (kg)",
              "Coût prédit",
              "Selfcare",
            ];

            let texts = "";

            header.forEach((column) => {
              texts += `${column};`;
            });
            texts += "\n";

            res.data.data.forEach((folder) => {
              texts += `${folder.reference};${folder.sinister};${
                folder.companyName
              };${folder.regionName};${folder.agencyName};${
                folder.expertName
              };${folder.natureExpertise};${folder.brand};${folder.model};${
                folder.kind
              };${folder.fuel};${getValue(folder.mileage, 0)};${moment(
                folder.dateVehicle
              ).format("DD/MM/YYYY")};${getCurrencyValue(
                folder.totalReportDutyFree
              )};${getValue(folder.total)};${getValue(
                folder.countPartR,
                0
              )};${getValue(
                folder.hourI + folder.hourM + folder.hourP + folder.hourT
              )};${getValue(folder.repair)};${getValue(
                folder.changeR
              )};${getValue(folder.countPaint, 0)};${getValue(
                folder.paint
              )};${getValue(folder.countPartE_EP_O, 0)};${getValue(
                folder.weightE
              )};${folder.change};${getValue(folder.countPartS, 0)};${getValue(
                folder.weightPRE
              )};${getValue(folder.pre)};${getValue(
                folder.changePRE
              )};${getValue(folder.distanceExpert)};${getValue(
                folder.multiplierExpert,
                0
              )};${getValue(folder.travelingExpert)};${getValue(
                folder.distanceClient
              )};${getValue(folder.multiplierClient, 0)};${getValue(
                folder.travelingClient
              )};${getCurrencyValue(folder.predict)};${folder.selfcare};\n`;
            });

            let blob = new Blob(["\ufeff", texts], { type: "text/csv" });
            let link = document.createElement("a");

            link.href = URL.createObjectURL(blob);
            link.download = `extract_cri_${moment().format(
              "YYYY_MM_DD_HH_mm"
            )}.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        } else {
          this.setState({ isLoad: false });
        }
      });
    });
  }

  handleDataChange = (data) => {
    this.setState(
      {
        dateStart: data.dateStart,
        dateEnd: data.dateEnd,
        network: data.network,
        group: data.group,
        region: data.region,
        agency: data.agency,
        companyMultiples: data.companyMultiples,
        builder: data.builder,
        brand: data.projetEBrand,
      },
      () => {
        this.getData();
      }
    );
  };

  getChartData = (data) => {
    const tmpData = data;
    const value = Object.keys(tmpData)
      .map((key) => ({ ...tmpData[key] }))
      .sort((a, b) => b.count - a.count)
      .slice(0, 20);

    return {
      labels: value.map((el) => el.name),
      dataset: [
        {
          label: "Émission CO2e moyenne (kg)",
          data: value.map((el) => el.average),
          color: value.map(() => randomColor()),
        },
      ],
    };
  };

  render() {
    const currentIdMonth = moment(this.state.dateEnd).format("M") - 1;
    const oldIdMonth = moment(this.state.dateEnd).format("M") - 2;

    return (
      <>
        <FadeIn>
          <Nav title="Projet E" className="mb-10 mb-20-xl" />
          <ShowOptionsNGRA
            isChange={true}
            className="mb-10 mb-20-xl"
            onDataChange={this.handleDataChange}
            isCompanyMultiples={true}
            visibilityRole={this.props.visibilityRole}
            visibilityType={this.props.visibilityType}
            preloadRole={this.props.preloadRole}
            preloadType={this.props.preloadType}
            limitUserType={this.props.limitUserType}
            limitUserRole={this.props.limitUserRole}
            isDateStart={true}
            isDateEnd={true}
            isProjetE={true}
            others={
              <>
                <div
                  className="d-flex"
                  style={{
                    alignItems: "center",
                    gap: 10,
                    whiteSpace: "nowrap",
                    flexWrap: "wrap",
                  }}
                >
                  <FormCheckbox
                    onChange={() => this.setState({ pre: !this.state.pre })}
                    checked={this.state.pre}
                    text="PRE"
                  />
                  <FormCheckbox
                    onChange={() =>
                      this.setState({ change: !this.state.change })
                    }
                    checked={this.state.change}
                    text="Remplacement fabrication"
                  />
                  <FormCheckbox
                    onChange={() =>
                      this.setState({ changeConst: !this.state.changeConst })
                    }
                    checked={this.state.changeConst}
                    text="Remplacement transport"
                  />
                  <FormCheckbox
                    onChange={() =>
                      this.setState({ repair: !this.state.repair })
                    }
                    checked={this.state.repair}
                    text="Réparation"
                  />
                  <FormCheckbox
                    onChange={() => this.setState({ paint: !this.state.paint })}
                    checked={this.state.paint}
                    text="Peinture"
                  />
                  <FormCheckbox
                    onChange={() =>
                      this.setState({
                        travelingClient: !this.state.travelingClient,
                      })
                    }
                    checked={this.state.travelingClient}
                    text="Trajet client"
                  />
                  <FormCheckbox
                    onChange={() =>
                      this.setState({
                        travelingExpert: !this.state.travelingExpert,
                      })
                    }
                    checked={this.state.travelingExpert}
                    text="Trajet expert"
                  />
                  <FormCheckbox
                    onChange={() => this.setState({ local: !this.state.local })}
                    checked={this.state.local}
                    text="Locaux + Informatique"
                  />
                  <FormCheckbox
                    onChange={() => this.setState({ car0: !this.state.car0 })}
                    checked={this.state.car0}
                    text="Véhicules non identifiés"
                  />
                  <FormCheckbox
                    onChange={() => this.setState({ car1: !this.state.car1 })}
                    checked={this.state.car1}
                    text="Véhicules identifiés"
                  />
                </div>
                {this.state.dateStart ? (
                  <Button
                    text="Télécharger les données"
                    className="mt-10"
                    onClick={() => {
                      this.getDataCSV();
                    }}
                  />
                ) : null}
              </>
            }
          />
          <TabsCustom>
            <TabsCustomHeader>
              {this.state.menuTabs.map((element, key) => (
                <TabsCustomMenu
                  noCard
                  disabled={this.props.isLoad}
                  key={key}
                  title={element}
                  active={this.state.index === key}
                  onClick={(e) => {
                    this.setState({ index: key });
                  }}
                />
              ))}
            </TabsCustomHeader>
            <TabsCustomBody noCard>
              {this.state.menuTabs.map((element, idx) => {
                return (
                  <TabsCustomItem active={this.state.index === idx} key={idx}>
                    {this.state.data ? (
                      <Row>
                        <CardScore
                          reverse
                          title="Gain moyen CO2e PRE"
                          value={
                            this.state.data.monthsGainPRE_cumulAverage[
                              currentIdMonth
                            ]
                          }
                          prevValue={
                            this.state.data.monthsGainPRE_cumulAverage[
                              oldIdMonth
                            ]
                          }
                          digits={2}
                        />
                        <CardScore
                          reverse
                          large
                          title="Gain cumul CO2e PRE"
                          value={
                            this.state.data.monthsGainPRE_cumul[currentIdMonth]
                          }
                          currentValue={
                            this.state.data.monthsGainPRE[currentIdMonth]
                          }
                          prevValue={this.state.data.monthsGainPRE[oldIdMonth]}
                          digits={2}
                        />
                        <CardScore
                          reverse
                          title="Gain moyen CO2e réparation pièces"
                          value={
                            this.state.data.monthsGainR_cumulAverage[
                              currentIdMonth
                            ]
                          }
                          prevValue={
                            this.state.data.monthsGainR_cumulAverage[oldIdMonth]
                          }
                          digits={2}
                        />
                        <CardScore
                          reverse
                          large
                          title="Gain cumul CO2e réparation pièces"
                          value={
                            this.state.data.monthsGainR_cumul[currentIdMonth]
                          }
                          currentValue={
                            this.state.data.monthsGainR[currentIdMonth]
                          }
                          prevValue={this.state.data.monthsGainR[oldIdMonth]}
                          digits={2}
                        />
                        <CardProjectE
                          total={this.state.data.months_cumul[currentIdMonth]}
                          currentAverage={
                            this.state.data.months_cumulAverage[currentIdMonth]
                          }
                          oldAverage={
                            this.state.data.months_cumulAverage[oldIdMonth]
                          }
                        />
                        <CardRound
                          total={this.state.data.months_cumul[currentIdMonth]}
                          data={[
                            ...(this.state.pre
                              ? [
                                  {
                                    label: "Échange PRE",
                                    value:
                                      this.state.data.monthsPRE_cumul[
                                        currentIdMonth
                                      ],
                                    average:
                                      this.state.data.monthsPRE_cumulAverage[
                                        currentIdMonth
                                      ],
                                    color: "#4FBC9D",
                                    icon: carDoorPreSvg,
                                  },
                                ]
                              : []),
                            ...(this.state.change
                              ? [
                                  {
                                    label:
                                      "Échange pièces neuves / PQE (Extraction matières)",
                                    value:
                                      this.state.data.monthsChange_cumul[
                                        currentIdMonth
                                      ],
                                    average:
                                      this.state.data.monthsChange_cumulAverage[
                                        currentIdMonth
                                      ],
                                    color: "#56C3EC",
                                    icon: carDoorPqeSvg,
                                  },
                                ]
                              : []),
                            ...(this.state.changeConst
                              ? [
                                  {
                                    label:
                                      "Échange pièces neuves / PQE (Fabrication et transport)",
                                    value:
                                      this.state.data.monthsChangeConst_cumul[
                                        currentIdMonth
                                      ],
                                    average:
                                      this.state.data
                                        .monthsChangeConst_cumulAverage[
                                        currentIdMonth
                                      ],
                                    color: "#00c0ba",
                                    icon: carDoorPqeSvg,
                                  },
                                ]
                              : []),
                            ...(this.state.repair
                              ? [
                                  {
                                    label: "M.O. Réparation / peinture pièces",
                                    value:
                                      this.state.data.monthsR_cumul[
                                        currentIdMonth
                                      ],
                                    average:
                                      this.state.data.monthsR_cumulAverage[
                                        currentIdMonth
                                      ],
                                    color: "#E85D7D",
                                    icon: toolsSvg,
                                  },
                                ]
                              : []),
                            ...(this.state.paint
                              ? [
                                  {
                                    label: "Ing. peinture",
                                    value:
                                      this.state.data.monthsPaint_cumul[
                                        currentIdMonth
                                      ],
                                    average:
                                      this.state.data.monthsPaint_cumulAverage[
                                        currentIdMonth
                                      ],
                                    color: "#F19A3E",
                                    icon: paintSvg,
                                  },
                                ]
                              : []),
                            ...(this.state.travelingClient
                              ? [
                                  {
                                    label: "Trajet client",
                                    value:
                                      this.state.data
                                        .monthsTravelingClient_cumul[
                                        currentIdMonth
                                      ],
                                    average:
                                      this.state.data
                                        .monthsTravelingClient_cumulAverage[
                                        currentIdMonth
                                      ],
                                    color: "#4AD95F",
                                    icon: carSvg,
                                  },
                                ]
                              : []),
                            ...(this.state.travelingExpert
                              ? [
                                  {
                                    label: "Trajet expert",
                                    value:
                                      this.state.data
                                        .monthsTravelingExpert_cumul[
                                        currentIdMonth
                                      ],
                                    average:
                                      this.state.data
                                        .monthsTravelingExpert_cumulAverage[
                                        currentIdMonth
                                      ],
                                    color: "#E6DB3F",
                                    icon: truckSvg,
                                  },
                                ]
                              : []),
                            ...(this.state.local
                              ? [
                                  {
                                    label: "Locaux + Informatique",
                                    value:
                                      this.state.data.monthsLocal_cumul[
                                        currentIdMonth
                                      ],
                                    average:
                                      this.state.data.monthsLocal_cumulAverage[
                                        currentIdMonth
                                      ],
                                    color: "#C45AF6",
                                    icon: buildingSvg,
                                  },
                                ]
                              : []),
                          ]}
                        />
                        <CardChart
                          title="Émissions de CO2e moy (cumulé) / Coût moy (cumulé)"
                          axis={{
                            y1: { min: 40 },
                            y2: { max: 2400 },
                          }}
                          data={[
                            {
                              yAxisID: "y1",
                              data: this.state.data.months_cumulAverage,
                              label: "Émissions de CO2e Moy. cumulé",
                            },
                            {
                              yAxisID: "y2",
                              data: this.state.data.monthsCost_cumulAverage,
                              label: "Coût moyen cumulé",
                            },
                          ]}
                        />
                        <CardChart
                          title="Gain moyen cumulé / Coût moyen cumulé"
                          axis={{
                            y1: { min: 20, max: 60 },
                            y2: { min: 30 },
                            y3: { max: 2400 },
                          }}
                          data={[
                            {
                              yAxisID: "y1",
                              data: this.state.data.monthsGainPRE_cumulAverage,
                              label: "Gain PRE cumulé",
                              color: "blue",
                            },
                            {
                              yAxisID: "y2",
                              data: this.state.data.monthsGainR_cumulAverage,
                              label: "Gain Réparations cumulé",
                              color: "purple",
                            },
                            {
                              yAxisID: "y3",
                              data: this.state.data.monthsCost_cumulAverage,
                              label: "Coût moyen cumulé",
                              color: "red",
                            },
                          ]}
                        />
                        <CardChart
                          title="Émissions de CO2e moy (cumulé) / Taux de PRE (cumulé) / Taux de réparation des pièces (cumulé)"
                          axis={{
                            y1: { min: 40 },
                            y2: { min: 10, max: 20 },
                            y3: { min: 60, max: 80 },
                          }}
                          data={[
                            {
                              yAxisID: "y1",
                              data: this.state.data.months_cumulAverage,
                              label: "Émissions de CO2e Moy. cumulé",
                            },
                            {
                              yAxisID: "y2",
                              data: this.state.data.monthsTxPRE_cumulAverage.map(
                                (element) => element * 100
                              ),
                              label: "Taux de PRE cumulé",
                              color: "blue",
                            },
                            {
                              yAxisID: "y3",
                              data: this.state.data.monthsTxR_cumulAverage.map(
                                (element) => element * 100
                              ),
                              label: "Taux de réparation cumulé",
                              color: "purple",
                            },
                          ]}
                        />
                        {this.state.index === 0 ? (
                          <CardChart
                            title="Comparaison des émissions de CO2e Moy"
                            data={[
                              {
                                yAxisID: "y1",
                                data: this.state.data.months_cumulAverage,
                                label: "Émissions de CO2e Moy. cumulé",
                              },
                              {
                                yAxisID: "y1",
                                data: this.state.data
                                  .monthsTERRAIN_cumulAverage,
                                label: "Émissions de CO2e Moy TERRAIN cumulé",
                              },
                              {
                                yAxisID: "y1",
                                data: this.state.data.monthsEAD_cumulAverage,
                                label: "Émissions de CO2e Moy EAD cumulé",
                              },
                              {
                                yAxisID: "y1",
                                data: this.state.data
                                  .monthsSELFCARE_cumulAverage,
                                label: "Émissions de CO2e Moy SELFCARE cumulé",
                              },
                            ]}
                          />
                        ) : null}
                        {Object.keys(this.state.data.brands).length > 0 ? (
                          <Col xs={12} md={6} className={"col_full_height"}>
                            <Card className="mb-10 mb-20-xl">
                              <BarChart
                                title={
                                  "Émissions de CO2e moyenne en (kg) par marque"
                                }
                                labels={
                                  this.getChartData(this.state.data.brands)
                                    .labels
                                }
                                dataSet={
                                  this.getChartData(this.state.data.brands)
                                    .dataset
                                }
                                widthBar={30}
                                yAxes={[
                                  {
                                    display: true,
                                  },
                                ]}
                              />
                            </Card>
                          </Col>
                        ) : null}
                        {Object.keys(this.state.data.models).length > 0 ? (
                          <Col xs={12} md={6} className={"col_full_height"}>
                            <Card className="mb-10 mb-20-xl">
                              <BarChart
                                title={
                                  "Émissions de CO2e moyenne en (kg) par modèle"
                                }
                                labels={
                                  this.getChartData(this.state.data.models)
                                    .labels
                                }
                                dataSet={
                                  this.getChartData(this.state.data.models)
                                    .dataset
                                }
                                widthBar={30}
                                yAxes={[
                                  {
                                    display: true,
                                  },
                                ]}
                              />
                            </Card>
                          </Col>
                        ) : null}
                        {Object.keys(this.state.data.fuels).length > 0 ? (
                          <Col xs={12} md={6} className={"col_full_height"}>
                            <Card className="mb-10 mb-20-xl">
                              <BarChart
                                title={
                                  "Émissions de CO2e moyenne en (kg) par énergie"
                                }
                                labels={
                                  this.getChartData(this.state.data.fuels)
                                    .labels
                                }
                                dataSet={
                                  this.getChartData(this.state.data.fuels)
                                    .dataset
                                }
                                widthBar={30}
                                yAxes={[
                                  {
                                    display: true,
                                  },
                                ]}
                              />
                            </Card>
                          </Col>
                        ) : null}
                        <Col xs={12} md={6} className={"col_full_height"}>
                          <Card className="mb-10 mb-20-xl">
                            <BarChart
                              title={
                                "Émissions de CO2e moyenne en (kg) par poste"
                              }
                              labels={[
                                "Échange PRE",
                                "Échange pièces neuves / PQE (Extraction matières)",
                                "Échange pièces neuves / PQE (Fabrication et transport)",
                                "M.O. Réparation / peinture pièces",
                                "Ing. peinture",
                                "Trajet client",
                                "Trajet expert",
                                "Locaux + Informatique",
                              ]}
                              dataSet={[
                                {
                                  label: "Émission CO2e moyenne (kg)",
                                  data: [
                                    this.state.data.monthsPRE_cumulAverage[
                                      currentIdMonth
                                    ],
                                    this.state.data.monthsChange_cumulAverage[
                                      currentIdMonth
                                    ],
                                    this.state.data
                                      .monthsChangeConst_cumulAverage[
                                      currentIdMonth
                                    ],
                                    this.state.data.monthsR_cumulAverage[
                                      currentIdMonth
                                    ],
                                    this.state.data.monthsPaint_cumulAverage[
                                      currentIdMonth
                                    ],
                                    this.state.data
                                      .monthsTravelingClient_cumulAverage[
                                      currentIdMonth
                                    ],
                                    this.state.data
                                      .monthsTravelingExpert_cumulAverage[
                                      currentIdMonth
                                    ],
                                    this.state.data.monthsLocal_cumulAverage[
                                      currentIdMonth
                                    ],
                                  ],
                                  color: [
                                    "#4FBC9D",
                                    "#56C3EC",
                                    "#E85D7D",
                                    "#F19A3E",
                                    "#4AD95F",
                                    "#E6DB3F",
                                    "#C45AF6",
                                  ],
                                },
                              ]}
                              widthBar={30}
                              yAxes={[
                                {
                                  display: true,
                                },
                              ]}
                            />
                          </Card>
                        </Col>
                        {Object.keys(this.state.data.ages).length > 0 ? (
                          <Col xs={12} md={6} className={"col_full_height"}>
                            <Card className="mb-10 mb-20-xl">
                              <BarChart
                                title={
                                  "Émissions de CO2e moyenne en (kg) par age du véhicule"
                                }
                                labels={Object.keys(this.state.data.ages).map(
                                  (key) => key
                                )}
                                dataSet={[
                                  {
                                    label: "Émission CO2e moyenne (kg)",
                                    data: Object.keys(this.state.data.ages).map(
                                      (key) => this.state.data.ages[key].average
                                    ),
                                    color: Object.keys(
                                      this.state.data.ages
                                    ).map(() => randomColor()),
                                  },
                                ]}
                                widthBar={30}
                                yAxes={[
                                  {
                                    display: true,
                                  },
                                ]}
                              />
                            </Card>
                          </Col>
                        ) : null}
                        <Col xs={12} md={6}>
                          {this.state.data && this.state.data.dataChart ? (
                            <CardChart
                              height={100}
                              isFull
                              title="Évolution cumulée des postes d'émissions de CO2e"
                              labels={this.state.data.dataChart.map((element) =>
                                moment(element.month, "YYYY-MM").format(
                                  "MM/YYYY"
                                )
                              )}
                              data={[
                                {
                                  yAxisID: "y1",
                                  data: this.state.data.dataChart.map(
                                    (element) => {
                                      return element.average_pre;
                                    }
                                  ),
                                  label: "Échange PRE",
                                },
                                {
                                  yAxisID: "y1",
                                  data: this.state.data.dataChart.map(
                                    (element) => {
                                      return element.average_change;
                                    }
                                  ),
                                  label:
                                    "Échange pièces neuves / PQE (Extraction matières)",
                                },
                                {
                                  yAxisID: "y1",
                                  data: this.state.data.dataChart.map(
                                    (element) => {
                                      return element.average_changeConst;
                                    }
                                  ),
                                  label:
                                    "Échange pièces neuves / PQE (Fabrication et transport)",
                                },
                                {
                                  yAxisID: "y1",
                                  data: this.state.data.dataChart.map(
                                    (element) => {
                                      return element.average_repair;
                                    }
                                  ),
                                  label: "M.O. Réparation / peinture pièces",
                                },
                                {
                                  yAxisID: "y1",
                                  data: this.state.data.dataChart.map(
                                    (element) => {
                                      return element.average_paint;
                                    }
                                  ),
                                  label: "Ing. peinture",
                                },
                                {
                                  yAxisID: "y1",
                                  data: this.state.data.dataChart.map(
                                    (element) => {
                                      return element.average_travelingClient;
                                    }
                                  ),
                                  label: "Trajet client",
                                },
                                {
                                  yAxisID: "y1",
                                  data: this.state.data.dataChart.map(
                                    (element) => {
                                      return element.average_travelingExpert;
                                    }
                                  ),
                                  label: "Trajet expert",
                                },
                                {
                                  yAxisID: "y1",
                                  data: this.state.data.dataChart.map(
                                    (element) => {
                                      return element.average_local;
                                    }
                                  ),
                                  label: "Locaux + Informatique",
                                },
                              ]}
                            />
                          ) : null}
                        </Col>
                        <Col
                          xs={12}
                          className={"col_full_height"}
                          style={{
                            height: 600,
                          }}
                        >
                          <Card
                            title="Réparation région moyenne émissions CO2e (kg)*"
                            className="mb-10 mb-20-xl d-flex"
                          >
                            <CountryMap
                              regions={this.state.data.regions_client}
                            />
                          </Card>
                        </Col>
                      </Row>
                    ) : null}
                    {this.state.data ? (
                      <TabsCustom>
                        <TabsCustomHeader>
                          {this.state.rankTabs.map((element, key) => (
                            <TabsCustomMenu
                              noCard
                              disabled={this.props.isLoad}
                              key={key}
                              title={element}
                              active={this.state.rankIndex === key}
                              onClick={(e) => {
                                this.setState({ rankIndex: key });
                              }}
                            />
                          ))}
                        </TabsCustomHeader>
                        <TabsCustomBody noCard>
                          <TabsCustomItem active={0 === this.state.rankIndex}>
                            <TableRank
                              title="Classement région"
                              data={this.state.data.regions}
                            />
                          </TabsCustomItem>
                          <TabsCustomItem active={1 === this.state.rankIndex}>
                            <TableRank
                              title="Classement agence"
                              data={this.state.data.agencies}
                            />
                          </TabsCustomItem>
                          <TabsCustomItem active={2 === this.state.rankIndex}>
                            <TableRank
                              title="Classement expert"
                              data={this.state.data.experts}
                            />
                          </TabsCustomItem>
                          <TabsCustomItem active={3 === this.state.rankIndex}>
                            <TableRank
                              title="Classement compagnie"
                              data={this.state.data.companies}
                            />
                          </TabsCustomItem>
                          <TabsCustomItem active={4 === this.state.rankIndex}>
                            <TableRank
                              title="Classement réparateur"
                              data={this.state.data.garages}
                            />
                          </TabsCustomItem>
                          <TabsCustomItem active={5 === this.state.rankIndex}>
                            <TableRank
                              title="Classement marque"
                              data={this.state.data.brands}
                            />
                          </TabsCustomItem>
                          <TabsCustomItem active={6 === this.state.rankIndex}>
                            <TableRank
                              title="Classement modèle"
                              data={this.state.data.models}
                            />
                          </TabsCustomItem>
                          <TabsCustomItem active={7 === this.state.rankIndex}>
                            <TablePart data={this.state.data.parts} />
                          </TabsCustomItem>
                        </TabsCustomBody>
                      </TabsCustom>
                    ) : null}
                  </TabsCustomItem>
                );
              })}
            </TabsCustomBody>
          </TabsCustom>
        </FadeIn>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
