import React, { Component } from "react";
import FadeIn from "react-fade-in/lib/FadeIn";
import { Col, Row } from "react-flexbox-grid";
import { Status, Table, Tbody, Td, Th, Thead, Tr } from "ui-kit-ck-consultant";
import moment from "moment";

import { months } from "../../../utils/general";

import Podium from "../../../components/general/Podium";

export class PodiumComplete extends Component {
  tabTitleJSX(id, element) {
    if (element === "") {
      return <Th key={id} hide></Th>;
    } else if (id === 1) {
      return <Th key={id}>{element}</Th>;
    } else return <Th key={id}>{element}</Th>;
  }

  formStatus(value) {
    let color = "";
    if (value >= 100) {
      color = "bg-green-light w-100 black";
    } else if (value >= 90) {
      color = "bg-orange-light w-100 black";
    } else {
      color = "bg-red-light w-100 black";
    }

    return <Status text={value + " %"} className={color} />;
  }

  tabElementJSX(index, value, length, idx) {
    if (index === 0) {
      if (idx === 0) {
        return (
          <Th className="nowrap" key={index}>
            {value}
          </Th>
        );
      } else
        return (
          <Th className="nowrap" key={index}>
            {value}
          </Th>
        );
    } else if (index === length - 1) {
      return (
        <Td className="nowrap" key={index}>
          {this.formStatus(value)}
        </Td>
      );
    } else {
      return (
        <Td className="w-100" key={index}>
          {value}
        </Td>
      );
    }
  }

  creatTab(title, data) {
    return (
      <Table>
        <Thead>
          <Tr>
            {title.map((element, idx, index) => this.tabTitleJSX(idx, element))}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((value, idx) => (
            <Tr key={idx}>
              {value.map((element, index) =>
                this.tabElementJSX(index, element, value.length, idx)
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
    );
  }

  render() {
    let date = new Date();
    let currentDate = moment(date, "YYYY/MM/DD");
    let periode = "";
    if (
      parseInt(this.props.year) === parseInt(moment(currentDate).format("Y"))
    ) {
      periode = `${months[moment(currentDate).format("M") - 1]} ${moment(
        currentDate
      ).format("YYYY")}`;
    } else {
      periode = this.props.year;
    }
    return (
      <>
        {this.props.data && this.props.data.length > 0 ? (
          <FadeIn>
            <h4>{`Expert EAD Performance Globale ${periode}`}</h4>
            <Row>
              <Col xs={4} className={"d-flex"}>
                <Podium
                  className={"mt-auto ml-auto mr-auto mb-30 "}
                  height={50}
                  dataNumber={
                    this.props.data &&
                    this.props.data.length &&
                    this.props.data[1]
                      ? this.props.data[1].value
                      : 0
                  }
                  dataText={
                    this.props.data &&
                    this.props.data.length &&
                    this.props.data[1]
                      ? this.props.data[1].name
                      : "Name"
                  }
                  rank={2}
                />
              </Col>
              <Col xs={4} className={"d-flex"}>
                <Podium
                  className={"mt-auto ml-auto mr-auto mb-30 "}
                  height={60}
                  dataNumber={
                    this.props.data &&
                    this.props.data.length &&
                    this.props.data[0]
                      ? this.props.data[0].value
                      : 0
                  }
                  dataText={
                    this.props.data &&
                    this.props.data.length &&
                    this.props.data[0]
                      ? this.props.data[0].name
                      : "Name"
                  }
                  rank={1}
                />
              </Col>
              <Col xs={4} className={"d-flex"}>
                <Podium
                  className={"mt-auto ml-auto mr-auto mb-30 "}
                  height={40}
                  dataNumber={
                    this.props.data &&
                    this.props.data.length &&
                    this.props.data[2]
                      ? this.props.data[2].value
                      : 0
                  }
                  dataText={
                    this.props.data &&
                    this.props.data.length &&
                    this.props.data[2]
                      ? this.props.data[2].name
                      : "Name"
                  }
                  rank={3}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                {this.creatTab(
                  ["#", "Expert", "Résultat"],
                  [
                    [
                      4,
                      this.props.data &&
                      this.props.data.length &&
                      this.props.data[3]
                        ? this.props.data[3].name
                        : "Name",
                      this.props.data &&
                      this.props.data.length &&
                      this.props.data[3]
                        ? this.props.data[3].value
                        : 0,
                    ],
                    [
                      5,
                      this.props.data &&
                      this.props.data.length &&
                      this.props.data[4]
                        ? this.props.data[4].name
                        : "Name",
                      this.props.data &&
                      this.props.data.length &&
                      this.props.data[4]
                        ? this.props.data[4].value
                        : 0,
                    ],
                    [
                      6,
                      this.props.data &&
                      this.props.data.length &&
                      this.props.data[5]
                        ? this.props.data[5].name
                        : "Name",
                      this.props.data &&
                      this.props.data.length &&
                      this.props.data[5]
                        ? this.props.data[5].value
                        : 0,
                    ],
                  ]
                )}
              </Col>
            </Row>
          </FadeIn>
        ) : null}
      </>
    );
  }
}

export default PodiumComplete;
