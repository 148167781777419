import React from "react";
import { DoughnutChart, Card, Status } from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";

import AuthContext from "../../context/AuthContext";

export default class Summary extends React.Component {
  static contextType = AuthContext;

  setLabel(value) {
    if (value >= 90) {
      return "EXCELLENCE";
    } else if (value >= 80) {
      return "PREMIUM";
    } else {
      return "VIGILANCE";
    }
  }

  setLabelClass(value) {
    if (value >= 90) {
      return "bg-green";
    } else if (value >= 80) {
      return "bg-orange";
    } else {
      return "bg-red";
    }
  }

  render() {
    let label = this.setLabel(this.props.label);
    return (
      <div className={"performance"}>
        <Card
          title="Récapitulatif"
          className={"summary-container mt-10 mt-30-xl"}
          isLoader={this.props.isLoader}
        >
          <Row>
            <Col xs={12} md={6} xl={2}>
              <div className="img m-auto my-10 ">
                <img src={this.props.imgProfil} alt="profil" />
                <p className="summary-container-name mt-2">
                  {this.props.nameProfil}
                </p>
                {this.props.noLabel || this.props.label === null ? (
                  ""
                ) : (
                  <Status
                    className={`mb-10 ${this.setLabelClass(this.props.label)}`}
                    text={`${label}`}
                  />
                )}
                {this.context.group === 1 && this.props.performanceRecap ? (
                  <Status
                    className={`mb-10 ${this.setLabelClass(
                      this.props.performanceRecap.globale_cumulee
                    )}`}
                    text={`Globale cumulée : ${this.props.performanceRecap.globale_cumulee}%`}
                  />
                ) : (
                  <></>
                )}
              </div>
            </Col>
            {this.props.performanceRecap ? (
              <>
                {this.context.group !== 1 ? (
                  <Col xs={12} md={6} xl={2} className="d-flex">
                    <DoughnutChart
                      max={100}
                      className={"m-auto mt-20"}
                      number={this.props.performanceRecap.globale_cumulee}
                      text="Globale cumulée"
                    />
                  </Col>
                ) : (
                  <></>
                )}
                <Col xs={12} md={6} xl={2} className="d-flex">
                  <DoughnutChart
                    max={100}
                    className={"m-auto mt-20"}
                    number={this.props.performanceRecap.cout}
                    text="Coût"
                  />
                </Col>
                <Col xs={12} md={6} xl={2} className="d-flex">
                  <DoughnutChart
                    max={100}
                    className={"m-auto mt-20"}
                    number={this.props.performanceRecap.delai}
                    text="Délai"
                  />
                </Col>
                <Col xs={12} md={6} xl={2} className="d-flex">
                  <DoughnutChart
                    max={100}
                    className={"m-auto mt-20"}
                    number={this.props.performanceRecap.production}
                    text="Production"
                  />
                </Col>
                <Col xs={12} md={6} xl={2} className="d-flex">
                  <DoughnutChart
                    max={100}
                    className={"m-auto mt-20"}
                    number={this.props.performanceRecap.qualite}
                    text="Qualité"
                  />
                </Col>
                {this.context.group === 1 && this.props.labelRSE ? (
                  <Col xs={12} md={6} xl={2} className={"d-flex"}>
                    <DoughnutChart
                      digits={0}
                      className={"m-auto mt-20"}
                      max={100}
                      medium={70}
                      number={this.props.labelRSE.global}
                      text={"Label RSE"}
                    />
                  </Col>
                ) : null}
              </>
            ) : null}
          </Row>
          {this.props.type === 1 ? (
            <div style={{ fontSize: "10px" }}>
              <p>
                * R : Nature expertise : Normale, SUIVI V.G.E., Estimation
                réparation ADE - Genre : VP, CTTE - Nature évènement :
                Circulation, Stationnement, Accident en chaine - Puissance
                fiscale : 1 à 15 - Total rapport HT : 20 € à 5 000 € - Carburant
                : ES, GO, EL, EH, EE, EG, GH, GL, FE, GP - Âge : {"<"} 30 ans -
                Apprentissage : {"<"} 2023
              </p>
              <p>
                * NR : Nature expertise : Véh. économmiquement irréparable
                (VEI), Différence des valeurs - Genre : VP, CTTE - Nature
                évènement : Circulation, Stationnement, Accident en chaine -
                Puissance fiscale : 1 à 15 - VRADE HT : 50 € à 10 000 € -
                Carburant : ES, GO, EL, EH, EE, EG, GH, GL, FE, GP - Âge : {"<"}{" "}
                30 ans
              </p>
            </div>
          ) : this.props.type === 2 ? (
            <div style={{ fontSize: "10px" }}>
              <p>
                * R : Nature expertise : Téléchiffrage - Genre : VP, CTTE -
                Nature évènement : Circulation, Stationnement, Accident en
                chaine - Puissance fiscale : 1 à 15 - Total rapport HT : 20 € à
                5 000 € - Carburant : ES, GO, EL, EH, EE, EG, GH, GL, FE, GP -
                Âge : {"<"} 30 ans
              </p>
            </div>
          ) : this.props.type === 3 ? (
            <div style={{ fontSize: "10px" }}>
              <p>
                * R : Nature expertise : Normale, SUIVI V.G.E., Estimation
                réparation ADE - Genre : VP, CTTE - Nature évènement :
                Circulation, Stationnement, Accident en chaine - Puissance
                fiscale : 1 à 15 - Total rapport HT : 20 € à 5 000 € - Carburant
                : ES, GO, EL, EH, EE, EG, GH, GL, FE, GP - Âge : {"<"} 30 ans -
                Apprentissage : {"<"} 2023
              </p>
              <p>
                * NR : Nature expertise : Véh. économmiquement irréparable
                (VEI), Différence des valeurs - Genre : VP, CTTE - Nature
                évènement : Circulation, Stationnement, Accident en chaine -
                Puissance fiscale : 1 à 15 - VRADE HT : 50 € à 10 000 € -
                Carburant : ES, GO, EL, EH, EE, EG, GH, GL, FE, GP - Âge : {"<"}{" "}
                30 ans
              </p>
            </div>
          ) : null}
        </Card>
      </div>
    );
  }
}
