import React, { Component } from "react";
import FadeIn from "react-fade-in";
import { Card, Table, Thead, Tr, Th, Tbody, Td } from "ui-kit-ck-consultant";

import ToggleTable from "../../../../performances/reparator/ToggleTable";

export default class CardTable extends Component {
  getLabelsQuality = () => {
    const labels = [
      {
        id: "Tx2ndReport",
        label: "Taux de rapports rectificatifs (en %)",
      },
      {
        id: "TxEAD",
        label: "Taux d'EAD - Téléchiffrage (en %)",
      },
      {
        id: "EADDelay",
        label: "Délai moyen EAD (en j.)",
      },
      {
        id: "averageDelayR",
        label: "Délai moyen REPARABLE (en j.)",
      },
      {
        id: "MissionDepotDelay",
        label: "Délai moyen PERTE TOTALE (en j.)",
      },
      {
        id: "TxSD04",
        label: "Taux de SD04 - Contestabilité de l'Expertise (en %)",
      },
      {
        id: "SD04Delay",
        label: "Délai moyen des SD04 (en j.)",
      },
      {
        id: "TxSD15",
        label: "Taux de SD15 - Retard prévisible (en %)",
      },
      {
        id: "SD15Delay",
        label: "Délai moyen du premier SD15 (en j.)",
      },
      {
        id: "TxSD20",
        label: "Taux de SD20 - Relance de l'Expert (en %)",
      },
      {
        id: "TxSD29",
        label: "Taux de SD29 - Informations sur les dommages NR (en %)",
      },
      {
        id: "SD29Delay",
        label: "Délai moyen des SD29 NR (en j.)",
      },
      {
        id: "TxSD99",
        label: "Taux d'échange des SD99 - Commentaires (en %)",
      },
    ];

    return labels;
  };

  getLabelsTechnical = () => {
    const labels = [
      {
        id: "NbFolders",
        label: "Nbre total de rapports d'Expertise",
      },
      {
        id: "honoraryAverageAllTaxes",
        label: "Prix moyen TTC notes d'honoraires (en €)",
      },
      {
        id: "averageCost",
        label: "Réparation non écrété - VTG (en €)",
      },
      {
        id: "EADecrt",
        label: "EAD écrété - VP et CTTE",
      },
      {
        id: "averageAge",
        label: "Age moyen des véhicules NR (en m.)",
      },
      {
        id: "VRADE_VTG",
        label: "VRADE HT moyenne VTG non écrété (en €)",
      },
      {
        id: "VRADE_VP_CTTE",
        label: "VRADE HT moyenne VP et CTTE écrété (en €)",
      },
    ];

    return labels;
  };

  getContent = () => {
    let content = [];

    if (this.props.data) {
      content.push(
        <Tr>
          <Th radiusTopLeft colSpan={11}>
            Qualité de service
          </Th>
        </Tr>
      );

      this.getLabelsQuality().map((elem, idx) => {
        content.push(
          <Tr key={`quality-${idx}`}>
            <Th>{elem.label}</Th>
            {this.props.data[elem.id].map((element, idxExc) => (
              <Td key={idxExc}>{element}</Td>
            ))}
          </Tr>
        );
        return true;
      });

      content.push(
        <Tr>
          <Th colSpan={11}>Résultats techniques</Th>
        </Tr>
      );
      
      this.getLabelsTechnical().map((elem, idx) => {
        content.push(
          <Tr key={`technical-${idx}`}>
            <Th>{elem.label}</Th>
            {this.props.data[elem.id].map((element, idxExc) => (
              <Td key={idxExc}>{element}</Td>
            ))}
          </Tr>
        );
        return true;
      });
    }

    return content;
  };

  render() {
    return (
      <Card
        title={"SUIVI PILOT TRIMESTRIEL"}
        classNameChildren={"overflow-x-auto"}
      >
        <ToggleTable color={"DarkPurple"}>
          <FadeIn>
            <div style={{ fontSize: "10px" }} className="mb-20 mt-30">
              <p>- Origine Darva</p>
            </div>
            <Table>
              <Thead>
                <Tr>
                  <Th className="nowrap" hide></Th>
                  <Th className="nowrap" radiusTopLeft>
                    N - 1
                  </Th>
                  <Th className="nowrap" spaceRight>
                    N
                  </Th>
                  <Th className="nowrap" spaceLeft>
                    N - 1 T1
                  </Th>
                  <Th className="nowrap">N - 1 T2</Th>
                  <Th className="nowrap">N - 1 T3</Th>
                  <Th className="nowrap" spaceRight>
                    N - 1 T4
                  </Th>
                  <Th className="nowrap" spaceLeft>
                    N T1
                  </Th>
                  <Th className="nowrap">N T2</Th>
                  <Th className="nowrap">N T3</Th>
                  <Th className="nowrap">N T4</Th>
                </Tr>
              </Thead>
              <Tbody>{this.getContent()}</Tbody>
            </Table>
          </FadeIn>
        </ToggleTable>
      </Card>
    );
  }
}
