import React, { Component } from "react";

import TableCustom from "./TableCustom";

export class VVR_R extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        {this.props.data.length ? (
          <TableCustom
            data={this.props.data[0]}
            labels={this.props.labelsR}
            dateEnd={this.props.dateEnd}
          />
        ) : null}
      </>
    );
  }
}

export default VVR_R;
