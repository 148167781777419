import React from "react";
import { Status } from "ui-kit-ck-consultant";

export default class NPSSatisfaction extends React.Component {
  render() {
    let data = [0, 0, 0];
    if (this.props.data && this.props.data.length === 3) {
      data = this.props.data;
    }

    const total = data[0] + data[2];

    let score = 0;
    score += data[0] * -100;
    score += data[2] * 100;

    let result = Math.round(score / total);

    if (result > 0) {
      return (
        <Status
          className="bg-green white w-100 nowrap mb-10"
          style={{ fontSize: 14 }}
          text={`Résultat NPS ${this.props.isDay ? "du jour " : ""}: ${result}`}
        />
      );
    } else if (result === 0) {
      return (
        <Status
          className="bg-orange white w-100 nowrap mb-10"
          style={{ fontSize: 14 }}
          text={`Résultat NPS ${this.props.isDay ? "du jour " : ""}: ${result}`}
        />
      );
    } else if (result < 0) {
      return (
        <Status
          className="bg-red white w-100 nowrap mb-10"
          style={{ fontSize: 14 }}
          text={`Résultat NPS ${this.props.isDay ? "du jour " : ""}: ${result}`}
        />
      );
    } else {
      return (
        <Status
          className="bg-blue white w-100 nowrap mb-10"
          style={{ fontSize: 14 }}
          text={`Résultat NPS ${
            this.props.isDay ? "du jour " : ""
          }: Aucune réponse`}
        />
      );
    }
  }
}
