import React, { Component } from "react";
import { Card, Table, Thead, Tbody, Tr, Th, Td } from "ui-kit-ck-consultant";
import { ExportCSV } from "react-export-csv";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class MainFigures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDownload: false,
    };
  }

  render() {
    let data = [];

    if (this.props.data.length) {
      this.props.data[0].forEach((month, idxRow) => {
        data.push({});
        this.props.data.forEach((element, idxCol) => {
          data[idxRow][idxCol] = element[idxRow];
        });
      });
    }

    return (
      <>
        <Card
          title="Principaux chiffres"
          className="mb-10 mb-20-xl"
          classNameChildren="overflow-x-auto"
          action={<FontAwesomeIcon icon={faDownload} />}
          onClick={() => this.setState({ isDownload: true })}
        >
          <Table>
            <Thead>
              <Tr>
                <Th>Mois</Th>
                <Th>Stock en début du mois</Th>
                {this.props.isElectric ? <Th>Bris de glace</Th> : null}
                <Th>Nbre nouveaux dossiers reçus (au cours du mois)</Th>
                <Th>Nbre dossiers clôturés (au cours du mois)</Th>
                <Th>Stock en fin de mois (stock)</Th>
                <Th>
                  Variation nbre nouveaux dossiers reçus (par rapport à N-1)
                </Th>
                <Th>Variation stock en fin de mois (par rapport à N-1)</Th>
              </Tr>
            </Thead>
            <Tbody>
              {this.props.data.length
                ? this.props.data[0].map((month, idxRow) => (
                    <Tr key={idxRow}>
                      {this.props.data.map((element, idxCol) => {
                        if (this.props.isElectric || idxCol !== 2) {
                          return <Td key={idxCol}>{element[idxRow]}</Td>;
                        } else {
                          return null;
                        }
                      })}
                    </Tr>
                  ))
                : null}
            </Tbody>
          </Table>
        </Card>
        {this.state.isDownload ? (
          <ExportCSV
            header={[
              {
                name: "Mois",
                key: "0",
              },
              {
                name: "Stock en début du mois",
                key: "1",
              },
              {
                ...(this.props.isElectric
                  ? [
                      {
                        name: "Bris de glace",
                        key: "2",
                      },
                    ]
                  : []),
              },
              {
                name: "Nbre nouveaux dossiers reçus (au cours du mois)",
                key: "3",
              },
              {
                name: "Nbre dossiers clôturés (au cours du mois)",
                key: "4",
              },
              {
                name: "Stock en fin de mois (stock)",
                key: "5",
              },
              {
                name: "Variation nbre nouveaux dossiers reçus (par rapport à N-1)",
                key: "6",
              },
              {
                name: "Variation stock en fin de mois (par rapport à N-1)",
                key: "7",
              },
            ]}
            data={data}
            separator={";"}
            callback={() =>
              this.setState({
                isDownload: false,
              })
            }
            isDownload={true}
            filename={`Principaux chiffres ${this.props.year}.csv`}
          />
        ) : null}
      </>
    );
  }
}

export default MainFigures;
