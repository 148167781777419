import React, { Component } from "react";
import FadeIn from "react-fade-in";
import { Col, Row } from "react-flexbox-grid";
import {
  Loader,
} from "ui-kit-ck-consultant";

import CardTechnical from "./CardTechnical";
import CardQuality from "./CardQuality";
import CardSpeciality from "./CardSpeciality";
import CardTable from "./CardTable";

import axiosConfig from "../../../../../utils/axiosConfig";

export default class CardACM extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataGeneral: null,
      dataTable: null,
      isLoadGeneral: false,
      isLoadTable: false,
    };
  }

  componentDidMount() {
    this.getPerformanceGeneral();
    this.getPerformanceTable();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data) &&
      parseInt(this.props.data.company) === 5
    ) {
      this.getPerformanceGeneral();
      this.getPerformanceTable();
    }
  };

  getPerformanceGeneral = () => {
    this.setState({ isLoadGeneral: true }, () => {
      axiosConfig("/company-performance/acm-general", {
        data: {
          dateStart: this.props.data.dateStart,
          dateEnd: this.props.data.dateEnd,
          network: this.props.data.network,
          group: this.props.data.group,
          region: this.props.data.region,
          agency: this.props.data.agency,
          user: this.props.data.user,
          company: this.props.data.company,
        },
      }).then((res, err) => {
        if (res.data.success) {
          this.setState({ dataGeneral: res.data.data, isLoadGeneral: false });
        } else {
          this.setState({ dataGeneral: null, isLoadGeneral: false });
        }
      });
    });
  };

  getPerformanceTable = () => {
    this.setState({ isLoadTable: true }, () => {
      axiosConfig("/company-performance/acm-table", {
        data: {
          dateStart: this.props.data.dateStart,
          dateEnd: this.props.data.dateEnd,
          network: this.props.data.network,
          group: this.props.data.group,
          region: this.props.data.region,
          agency: this.props.data.agency,
          user: this.props.data.user,
          company: this.props.data.company,
        },
      }).then((res, err) => {
        if (res.data.success) {
          this.setState({ dataTable: res.data.data, isLoadTable: false });
        } else {
          this.setState({ dataTable: null, isLoadTable: false });
        }
      });
    });
  };
  

  render() {
    return (
      <FadeIn>
        <Row>
          <Col xs={12} lg={6}>
            <CardTechnical data={this.state.dataGeneral} />
          </Col>
          <Col xs={12} lg={6}>
            <CardQuality data={this.state.dataGeneral} />
          </Col>
          <Col xs={12}>
            <CardSpeciality data={this.state.dataGeneral} />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={12} className={"mt-30"}>
            <CardTable data={this.state.dataTable} />
          </Col>
        </Row>
        <Loader
          show={
            this.state.isLoadGeneral || this.state.isLoadTable ? true : false
          }
        />
      </FadeIn>
    );
  }
}
