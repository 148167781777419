import React, { Component } from "react";
import {
  Nav,
  PaginationClass,
  TabsCustom,
  TabsCustomHeader,
  TabsCustomMenu,
  TabsCustomBody,
  TabsCustomItem,
  Card,
  Button,
  Loader,
  FullModal,
  ButtonIcon,
} from "ui-kit-ck-consultant";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FadeIn from "react-fade-in";
import { Buffer } from "buffer";

import List from "../../../components/company/monitoring/List";
import ModalCompanyMonitoring from "../../../components/company/monitoring/ModalCompanyMonitoring";
import ShowOptionsNGRA from "../../../components/general/ShowOptionsNGRA-v2";
import PrintClass from "../../../components/general/Print";

import "../../../style/companyMonitoring.css";

import axiosConfig from "../../../utils/axiosConfig";

import AuthContext from "../../../context/AuthContext";

export default class CompanyMonitoring extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // ShowOption state
      network: 0,
      group: 0,
      region: 0,
      agency: 0,
      company: 0,
      year: 0,
      // TabsCustom
      tabsIdArray: [],
      tabsId: 0,
      // List state
      activePage: 1,
      tabLength: 20,
      total: 0,
      dataList: [],
      order: null,
      // Modal state
      isModal: false,
      isEdit: false,
      idModal: null,
      isLoad: false,
      companyLogo: null,
    };

    this.refPrintList = React.createRef();
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.PaginationClass = new PaginationClass(
      "company-monitoring/count",
      "company-monitoring/pagination",
      this.state.tabLength
    );
    this.setState({ isLoad: true });
    axiosConfig("company-monitoring/get-types").then((res) => {
      if (res.data.success === true) {
        this.setState({ tabsIdArray: res.data.data, isLoad: false });
      } else {
        this.setState({ tabsIdArray: [], isLoad: false });
      }
    });
  }

  // ShowOption data

  handleDataChange = (data) => {
    this.setState(
      {
        network: data.network,
        group: data.group,
        region: data.region,
        agency: data.agency,
        company: data.company,
        user: data.user,
        year: data.year,
      },
      () => {
        this.initSearch();
      }
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.company !== this.state.company && this.state.company !== 0) {
      this.getCompanyLogo();
    }
  }

  getCompanyLogo = () => {
    axiosConfig("/company-performance/get-company-logo", {
      data: {
        id: this.state.company,
      },
    }).then((res, err) => {
      if (res && res.data.logo) {
        const companyLogo = res.data.logo;
        this.setState({ companyLogo });
      } else {
        this.setState({ companyLogo: null });
      }
    });
  };

  // List data

  initSearch = () => {
    this.setState({ activePage: 1 }, () => {
      this.PaginationClass.init(axiosConfig, this.getData(), (result) => {
        if (result.success === true) {
          this.setState(
            {
              total: result.total,
              isLoad: false,
            },
            () => {
              this.PaginationClass.get(
                axiosConfig,
                this.getData(),
                (result) => {
                  this.setData(result);
                }
              );
            }
          );
        }
      });
    });
  };

  setData(result) {
    if (result.success === true) {
      this.setState({ dataList: result.tabData }, () => {});
    } else {
      this.setState({ dataList: [] });
    }
  }

  getData() {
    return {
      type:
        this.state.tabsIdArray.length > 0
          ? this.state.tabsIdArray[this.state.tabsId].id
          : 1,
      network: this.state.network,
      group: this.state.group,
      region: this.state.region,
      agency: this.state.agency,
      company: this.state.company,
      year: this.state.year,
      orderName: this.state.order ? this.state.order.name : null,
      orderDesc: this.state.order ? this.state.order.desc : null,
    };
  }

  handlePageChange = (activePage) => {
    this.setState({ isLoad: true });
    this.PaginationClass.page(activePage, () =>
      this.setState({ activePage: activePage }, () => {
        this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
          if (result.success === true) {
            this.setState({ isLoad: false }, () => {
              this.setData(result);
            });
          }
        });
      })
    );
  };

  handleSortClick = (order) => {
    this.setState({ order }, () => {
      this.initSearch();
    });
  };

  handleOpenModal = (id) => {
    this.setState({
      isModal: true,
      isEdit: true,
      idModal: id,
    });
  };

  handleDeleteClick = (id) => {
    if (
      window.confirm("Êtes-vous sûr de vouloir supprimer ce suivi compagnie")
    ) {
      this.setState({ isLoad: true }, () => {
        axiosConfig("company-monitoring/delete", {
          data: {
            id,
          },
        }).then((res) => {
          if (res.data.success === true) {
            this.initSearch();
          }
        });
      });
    }
  };

  handleDownload = (id, action = "preview") => {
    this.setState({ isLoad: true });
    axiosConfig({
      url: "company-monitoring/get-sample",
      data: {
        id: id,
      },
    }).then((res) => {
      if (res.data.success) {
        let result = { ...res.data.data };
        if (result.file) {
          let base64 = new Buffer.from(result.file).toString("base64");
          if (base64.includes("base64")) {
            result.base64 = base64.includes(";base64,")
              ? base64.split(";base64,")[1]
              : base64.split("base64")[1];
          } else {
            result.base64 = base64;
          }
          if (
            (result.status === 1 && action !== "preview") ||
            result.extension !== "pdf"
          ) {
            const linkSource = `data:${result.type};base64,${result.base64}`;
            const downloadLink = document.createElement("a");
            const fileName = `${result.name}${result.extension}`;
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
            this.setState({ data: null, isLoad: false });
            return;
          }
        }
        this.setState({ data: result, isLoad: false, show: true });
      } else {
        this.setState({ data: null, isLoad: false });
      }
    });
  };

  handleDisplaySample = (id, action = "preview") => {
    let file = this.state.dataList.find((element) => element.id === id);
    if (action === "preview" && file.extension === "pdf") {
      window.open(file.url, "_blank");
    } else {
      this.handleDownload(id, action);
    }
  };

  onClose = () => {
    this.setState({ show: false, urlSample: null });
  };

  handleUncloture = (id) => {
    let dataList = this.state.dataList;
    const index = dataList.findIndex((element) => element.id === id);
    if (index >= 0) {
      dataList[index].status = 0;
      this.setState({ dataList });
    }
  };

  render() {
    let tabsName = "";
    let tabsId = "";
    if (this.state.tabsIdArray.length > 0) {
      tabsName = this.state.tabsIdArray[this.state.tabsId].name;
      tabsId = this.state.tabsIdArray[this.state.tabsId].id;
    }

    return (
      <>
        <FadeIn>
          <Nav className="mb-10 mb-20-xl" title="Suivi compagnie">
            {this.state.companyLogo ? (
              <img
                className="imageCompany"
                src={this.state.companyLogo}
                alt="logo-company"
              />
            ) : null}
          </Nav>
          <ShowOptionsNGRA
            isChange={true}
            className="mb-10 mb-20-xl"
            onDataChange={this.handleDataChange}
            isYear={true}
            isCompany={true}
            isAll={true}
            visibilityRole={this.props.visibilityRole}
            visibilityType={this.props.visibilityType}
            preloadRole={this.props.preloadRole}
            preloadType={this.props.preloadType}
            limitUserType={this.props.limitUserType}
            limitUserRole={this.props.limitUserRole}
          />
          <TabsCustom className="mb-10 mb-20-xl">
            <TabsCustomHeader>
              {this.state.tabsIdArray.map((element, key) => (
                <TabsCustomMenu
                  key={element.id}
                  title={element.name}
                  active={this.state.tabsId === key}
                  onClick={() => {
                    this.setState({ tabsId: key }, () => this.initSearch());
                  }}
                />
              ))}
            </TabsCustomHeader>
            <TabsCustomBody noCard>
              <TabsCustomItem active={true}>
                <div ref={this.refPrintList}>
                  <Card
                    title={tabsName}
                    action={
                      this.context.role !== 0 ? (
                        <Button className="ml-2" text={`Ajouter ${tabsName}`}>
                          <FontAwesomeIcon icon={faPlus} />
                        </Button>
                      ) : null
                    }
                    onClick={() => this.setState({ isModal: true })}
                  >
                    {this.state.dataList ? (
                      <List
                        className="mb-10 mb-20-xl"
                        activePage={this.state.activePage}
                        tabLength={this.state.tabLength}
                        data={this.state.dataList}
                        total={this.state.total}
                        onPageChange={this.handlePageChange}
                        onSortClick={this.handleSortClick}
                        onOpenModal={this.handleOpenModal}
                        onDeleteClick={this.handleDeleteClick}
                        displaySample={this.handleDisplaySample}
                        handleUncloture={this.handleUncloture}
                        isPrint={this.state.isPrint}
                      />
                    ) : null}
                  </Card>
                </div>
              </TabsCustomItem>
            </TabsCustomBody>
          </TabsCustom>
          <ModalCompanyMonitoring
            show={this.state.isModal}
            isEdit={this.state.isEdit}
            id={this.state.idModal}
            type={tabsId}
            name={tabsName}
            onClose={() =>
              this.setState(
                {
                  isModal: false,
                  isEdit: false,
                  idModal: false,
                },
                () => this.initSearch()
              )
            }
          />
        </FadeIn>

        {this.state.dataList ? (
          <FullModal
            show={this.state.show}
            onClose={this.onClose}
            className="container-file-viewer"
            iconClose={
              <ButtonIcon
                big
                style={{ position: "relative", top: "-30px", left: "20px" }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </ButtonIcon>
            }
          >
            <div className={"containerViewer"}>
              {this.state.urlSample ? (
                <iframe
                  title={this.state.idSample}
                  src={`https://docs.google.com/viewer?url=${this.state.urlSample}&embedded=true`}
                  className="d-flex w-100 h-100"
                />
              ) : null}
            </div>
          </FullModal>
        ) : null}
        <Loader show={this.state.isLoad} />
        <PrintClass
          refs={this.state.isPrint ? [this.refPrintList] : []}
          title={"Suivi Compagnie"}
          fileName={"Suivi Compagnie"}
          onPrint={(print) => this.setState({ isPrint: print, isLoad: print })}
          width={1500}
        />
      </>
    );
  }
  onError(e) {
    console.log("Error: ", e);
  }
}
