import React, { Component } from "react";
import EvolutionDisplay from "./EvolutionDisplay";
import AddEvolution from "./AddEvolution";
import { Card, Table, Thead, Tr, Th } from "ui-kit-ck-consultant";

export class Evolution extends Component {
  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.evolutionType !== this.props.evolutionType) {
      let evolutionName =
        this.props.evolutionType === 0
          ? "evolution_fonctionnement_evolution"
          : this.props.evolutionType === 1
          ? "evolution_fonctionnement_information"
          : this.props.evolutionType === 3
          ? "evolution_fonctionnement_process"
          : null;
      this.setState({ evolutionName });
    }
  };

  render() {
    let title;
    if (this.props.evolutionType === 0) title = "Évolutions";
    if (this.props.evolutionType === 1) title = "Informations générales";
    if (this.props.evolutionType === 2) title = "Process internes";
    let evolutionName;
    if (this.props.evolutionType === 0)
      evolutionName = "evolution_fonctionnement_evolution";
    if (this.props.evolutionType === 1)
      evolutionName = "evolution_fonctionnement_information";
    if (this.props.evolutionType === 2)
      evolutionName = "evolution_fonctionnement_process";

    return (
      <Card className={"mt-30"} title={title}>
        {this.props.oldEvolutionData &&
        this.props.oldEvolutionData.length > 0 ? (
          <EvolutionDisplay
            evolutionData={this.props.oldEvolutionData}
            evolutionType={this.props.evolutionType}
            handleRemoveEvolution={(id, evolutionType) =>
              this.props.handleRemoveEvolution(id, evolutionType)
            }
            isPrint={this.props.isPrint}
          />
        ) : this.props.disableEdition ? (
          <Table empty="Aucune donnée n'est disponible">
            <Thead>
              <Tr>
                <Th>Date</Th>
                <Th>Commentaire</Th>
              </Tr>
            </Thead>
          </Table>
        ) : null}
        {this.props.disableEdition ? null : (
          <AddEvolution
            name={evolutionName}
            value={this.props.currentEvolutionData}
            onChange={(keyVal) => this.props.handleChangeEvolution(keyVal)}
          />
        )}
      </Card>
    );
  }
}

export default Evolution;
