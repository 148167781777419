import React, { Component } from "react";
import { Col, Row } from "react-flexbox-grid";
import { Card, Button, ButtonIcon } from "ui-kit-ck-consultant";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axiosConfig from "../../utils/axiosConfig";

export default class CardFiles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [], // Array to store files
      error: null, // Error message
    };
    this.fileInputRef = React.createRef(); // File input reference
  }

  componentDidMount() {
    this.fetchFilesFromServer();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id && this.props.id) {
      this.fetchFilesFromServer();
    }
  }

  // Function to fetch files from the server
  fetchFilesFromServer = () => {
    axiosConfig({
      url: "/files/list", // Replace with the correct server endpoint
      data: { id: this.props.id }, // Send the related id if needed
    })
      .then((response) => {
        console.log(response);
        if (response.data.success) {
          const files = response.data.data.map((file) => ({
            id: file.id,
            name: file.name,
            type: file.extension,
            base64: file.base64, // Assuming the server sends back base64-encoded files
          }));

          this.setState({ files });
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération des fichiers : ", error);
      });
  };

  // Function to handle adding and automatically uploading a file
  addFileAndUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      // Check if file exceeds 1MB size
      if (file.size > 1048576) {
        this.setState({
          error: "Le fichier dépasse la taille maximale de 1 Mo",
        });
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const newFile = {
          id: Date.now(), // Temporary id, to be updated after the upload
          name: file.name,
          type: file.type,
          base64: reader.result, // File content (for images and upload)
        };

        // Add the file to state immediately (UI feedback) and start upload
        this.setState((prevState) => ({
          files: [...prevState.files, newFile],
          error: null, // Reset error if file is valid
        }));

        // Upload the file to the server after adding to local state
        this.uploadFileToServer(newFile);
      };

      reader.readAsDataURL(file); // Convert files to base64 for upload
    }
  };

  // Function to upload file to the server
  uploadFileToServer = (file) => {
    // Send the file to the server with axiosConfig
    axiosConfig({
      method: "POST",
      url: "/files/add",
      data: {
        id: this.props.id,
        base64: file.base64,
        type: file.type,
        name: file.name,
      },
    })
      .then((response) => {
        if (response.data.success) {
          const serverFileId = response.data.id; // Get file ID from backend
          this.updateFileId(file.id, serverFileId); // Update the file's id in state
          alert("Fichier téléchargé avec succès");
        }
      })
      .catch((error) => {
        console.error("Erreur lors du téléchargement du fichier : ", error);
      });
  };

  // Function to update the file ID after receiving the ID from the server
  updateFileId = (tempId, serverFileId) => {
    this.setState((prevState) => ({
      files: prevState.files.map((file) =>
        file.id === tempId ? { ...file, id: serverFileId } : file
      ),
    }));
  };

  // Function to delete a file from the server and local state
  deleteFile = (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce fichier ?")) {
      // POST request to delete the file from the server
      axiosConfig({
        method: "POST",
        url: `/files/delete`, // The deletion is now via POST
        data: { fileId: id }, // Send the file ID in the body
      })
        .then((response) => {
          if (response.data.success) {
            // Remove the file from state after successful deletion
            this.setState((prevState) => ({
              files: prevState.files.filter((file) => file.id !== id),
            }));
            alert("Fichier supprimé avec succès");
          }
        })
        .catch((error) => {
          console.error("Erreur lors de la suppression du fichier : ", error);
        });
    }
  };

  // Trigger file input
  triggerFileSelect = () => {
    this.fileInputRef.current.click();
  };

  // Function to render file: either image preview or filename with download button
  renderFile = (file) => {
    if (file.type.startsWith("image/")) {
      // If file is an image, show preview
      return (
        <>
          <img
            style={{ maxWidth: "100%", maxHeight: "100%" }}
            src={file.base64}
            alt={file.name}
          />
          <a
            href={file.base64}
            download={file.name}
            style={{ position: "absolute", bottom: 10, right: 10 }}
          >
            Télécharger
          </a>
        </>
      );
    } else {
      // For non-images, just show the filename with download button
      return (
        <div style={{ textAlign: "center" }}>
          <p>{file.name}</p>
          <a href={file.base64} download={file.name}>
            Télécharger
          </a>
        </div>
      );
    }
  };

  render() {
    const { files, error } = this.state;
    const { disableEdition } = this.props;

    return (
      <Card title="Fichier(s)" className="mt-10 mt-30-xl">
        <Row>
          {files.map((file) => (
            <Col xs={12} md={6} key={file.id}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                  width: "100%",
                  height: 180,
                  border: "var(--gray) dashed 2px",
                  borderRadius: 8,
                }}
              >
                {!disableEdition && (
                  <ButtonIcon
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      zIndex: 10,
                    }}
                    className="red"
                    small
                    onClick={() => this.deleteFile(file.id)}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </ButtonIcon>
                )}
                {this.renderFile(file)}
              </div>
            </Col>
          ))}
        </Row>

        {!disableEdition && (
          <>
            <Button
              text="Ajouter un fichier"
              className="mt-10 mt-30-xl"
              onClick={this.triggerFileSelect}
            />
            <input
              type="file"
              accept=".jpg,.jpeg,.png,.pdf,.doc,.docx,.txt"
              style={{ display: "none" }}
              ref={this.fileInputRef}
              onChange={this.addFileAndUpload}
            />
          </>
        )}

        {/* Show error message if any */}
        {error && <p style={{ color: "red" }}>{error}</p>}
      </Card>
    );
  }
}
