import React, { Component } from "react";

import { Card, FormSelect, Button } from "ui-kit-ck-consultant";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AuthContext from "../../../context/AuthContext";

export default class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      month: "",
    };
  }
  static contextType = AuthContext;

  render() {
    return (
      <Card title="Export" className="mb-20">
        <div
          className="d-flex overflow-hidden"
          style={{
            flexWrap: "wrap",
          }}
        >
          <FormSelect
            className="mb-10 mr-10"
            value={this.state.month}
            onChange={({ target }) =>
              this.setState({
                month: target.value,
              })
            }
            options={[
              { text: "Tous les mois", value: "" },
              { text: "Janvier", value: 1 },
              { text: "Février", value: 2 },
              { text: "Mars", value: 3 },
              { text: "Avril", value: 4 },
              { text: "Mai", value: 5 },
              { text: "Juin", value: 6 },
              { text: "Juillet", value: 7 },
              { text: "Août", value: 8 },
              { text: "Septembre", value: 9 },
              { text: "Octobre", value: 10 },
              { text: "Novembre", value: 11 },
              { text: "Décembre", value: 12 },
            ]}
          />

          <div className="d-flex" style={{ flexWrap: "wrap" }}>
            <Button
              className="w-auto mb-10 mr-10"
              text="Liste des absences"
              onClick={() => {
                this.props.getDataXLSX(this.state.month);
              }}
            >
              <FontAwesomeIcon icon={faDownload} />
            </Button>
            <Button
              className="w-auto mb-10 mr-10"
              text="Jours d'absence (Congés/Sans solde et arrêt
                maladie) / personne"
              onClick={() => this.props.getDataPerUserXLSX(this.state.month)}
            >
              <FontAwesomeIcon icon={faDownload} />
            </Button>
            <Button
              className="w-auto mb-10 mr-10"
              text="Liste des formations"
              onClick={() =>
                this.props.getDataXLSX(this.state.month, "Formation")
              }
            >
              <FontAwesomeIcon icon={faDownload} />
            </Button>
            <Button
              className="w-auto mb-10 mr-10"
              text="Jours de formation / personne"
              onClick={() =>
                this.props.getDataPerUserXLSX(this.state.month, "Formation")
              }
            >
              <FontAwesomeIcon icon={faDownload} />
            </Button>
          </div>
        </div>
      </Card>
    );
  }
}
