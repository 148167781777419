import React, { Component } from 'react'
import { TabsCustom, TabsCustomBody, TabsCustomHeader, TabsCustomItem, TabsCustomMenu } from 'ui-kit-ck-consultant'
import StatisticalMonitoring from '../general/StatisticalMonitoring'

export class StatisticalMonitoringDisplay extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menuTabs: this.props.dataR && this.props.dataNR
        ? [
          "Suivi statistique R",
          "Suivi statistique NR",
        ]
        : this.props.dataR && !this.props.dataNR
          ? [
            "Suivi statistique R",
          ]
          : [
            "Suivi statistique NR",
          ],
      index: 0,
    }
  }
  render() {
    return (
      <TabsCustom>
        <TabsCustomHeader
          className={"mt-10 mt-30-xl"}
        >
          {this.state.menuTabs.map((element, key) => (
            <TabsCustomMenu
              disabled={this.props.isLoader}
              key={key}
              title={element}
              active={this.state.index === key}
              onClick={(e) => {
                this.setState({ index: key });
              }}
            />
          ))}
        </TabsCustomHeader>
        <TabsCustomBody noCard>
          {this.state.menuTabs.map((element, idx) => {
            return (
              <TabsCustomItem active={this.state.index === idx} key={idx}>
                {element === "Suivi statistique R"
                  ? <StatisticalMonitoring
                    className={"mt-5 mt-5-xl "}
                    data={this.props.dataR && this.props.dataR}
                    typeSearch={this.props.typeSearch}
                    type={this.props.type}
                    isUser={this.props.isUser}
                  />
                  : <StatisticalMonitoring
                    className={"mt-5 mt-5-xl "}
                    data={this.props.dataNR && this.props.dataNR}
                    typeSearch={this.props.typeSearch}
                    type={this.props.type}
                    isUser={this.props.isUser}
                  />}
              </TabsCustomItem>
            )
          })}
        </TabsCustomBody>
      </TabsCustom>
    )
  }
}

export default StatisticalMonitoringDisplay
