import React, { Component } from "react";
import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Status,
  PieChart,
  ButtonIcon,
  Card,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  getCurrencyValue,
  getPercentageValue,
  getArrayAverage,
} from "../../utils/general";
import { getColors } from "../../utils/colors";

export default class CriteriaDetail extends Component {
  getStatusColor = (value) => {
    if (value >= 100) {
      return "bg-green";
    } else if (value === 0) {
      return "bg-blue";
    } else if (value >= 90) {
      return "bg-orange";
    } else {
      return "bg-red";
    }
  };

  render() {
    let dataChart = [];

    this.props.data.forEach((data, idx) => {
      data.averageCost = getArrayAverage(
        data.folders.map((folder) => folder.cost)
      );
      data.averagePredict = getArrayAverage(
        data.folders.map((folder) => folder.predict)
      );
      data.averageDifference =
        data.averagePredict && data.averageCost
          ? (data.averagePredict * 100) / data.averageCost
          : 0;

      if (data.folders.length) {
        dataChart.push({
          labels: data.text,
          color: getColors()[idx],
          data: data.averageDifference,
        });
      }
    });

    return (
      <Card
        title={this.props.title}
        className="mb-10 mb-20-xl"
        classNameChildren="overflow-x-auto"
      >
        <Row>
          <Col xs={12} md={4} className="d-flex">
            <div className="m-auto">
              <PieChart
                height={250}
                digits={0}
                format="percentage"
                data={dataChart}
              />
            </div>
          </Col>
          <Col xs={12} md={8} className="d-flex">
            <div className="m-auto w-100">
              <Table className="w-100">
                <Thead>
                  <Tr>
                    <Th>{this.props.title}</Th>
                    <Th>Dossier</Th>
                    <Th>Coût moyen réel</Th>
                    <Th>Coût moyen prédit</Th>
                    <Th>Performance</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {Object.keys(this.props.data)
                    .sort(
                      (a, b) =>
                        this.props.data[b].averageDifference -
                        this.props.data[a].averageDifference
                    )
                    .map((key) => {
                      if (this.props.data[key].folders.length) {
                        return (
                          <Tr key={key}>
                            <Th>{this.props.data[key].text}</Th>
                            <Td>{this.props.data[key].folders.length}</Td>
                            <Td>
                              {getCurrencyValue(
                                this.props.data[key].averageCost
                              )}
                            </Td>
                            <Td>
                              {getCurrencyValue(
                                this.props.data[key].averagePredict
                              )}
                            </Td>
                            <Td>
                              <Status
                                className={`${this.getStatusColor(
                                  this.props.data[key].averageDifference
                                )} w-100`}
                                text={getPercentageValue(
                                  this.props.data[key].averageDifference,
                                  0
                                )}
                              />
                            </Td>
                            <Td className="text-center">
                              <ButtonIcon
                                small
                                className="blue"
                                onClick={() =>
                                  this.props.setFolders(
                                    this.props.data[key].folders.sort(
                                      (a, b) =>
                                        b.predict -
                                        b.cost -
                                        (a.predict - b.cost)
                                    )
                                  )
                                }
                              >
                                <FontAwesomeIcon icon={faSearch} />
                              </ButtonIcon>
                            </Td>
                          </Tr>
                        );
                      } else {
                        return null;
                      }
                    })}
                </Tbody>
              </Table>
            </div>
          </Col>
        </Row>
      </Card>
    );
  }
}
