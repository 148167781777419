import { Card } from "ui-kit-ck-consultant";
import React, { Component } from "react";

import "../../style/Carrousel.css";

/*  Carrousel:
Properties:
  show: Number;  Number of Items shown at the same time in the carousel (Required)
  infiniteLoop: Bool; Loops to the 1st item after the last one if set to true (Default false)
  autoSlide: Bool; Slides automatically if set to true Required
  interval: Number Interval between AutoSlides (ms) (Default 5000ms)
*/
export class Carrousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
      length: this.props.children.length,
      isRepeating:
        this.props.infiniteLoop && this.props.children.length > this.props.show,
      show: this.props.show,
    };
  }

  componentDidMount() {
    this.checkSize();
    this.resizeEvent = window.addEventListener("resize", this.checkSize);
    this.handleAutoSlide();
  }

  componentWillUnmount() {
    clearInterval(this.autoSlideInterval);
  }

  handleAutoSlide() {
    if (this.props.autoSlide) {
      this.autoSlideInterval = setInterval(
        () => {
          this.next();
        },
        this.props.interval ? this.props.interval : 5000
      );
    }
  }

  wrapItems() {
    let wrappedItems = [];
    this.props.children.map(item => {
      item = <Card>{item}</Card>;
      wrappedItems.push(item);
      return true
    });
    return wrappedItems;
  }

  checkSize = () => {
    if (window.innerWidth < 992) {
      this.setState({
        show: 1,
        isRepeating:
          this.props.infiniteLoop &&
          this.props.children.length > this.state.show,
      });
    } else {
      this.setState({
        show: this.props.show,
        isRepeating:
          this.props.infiniteLoop &&
          this.props.children.length > this.state.show,
      });
    }
  };

  next() {
    let resetcurrentindex = false;
    if (this.state.currentIndex === this.state.length - this.state.show) {
      this.setState({ currentIndex: 0 });
      resetcurrentindex = true;
    }
    if (
      this.state.currentIndex < this.state.length - this.state.show &&
      !resetcurrentindex
    ) {
      this.setState({ currentIndex: this.state.currentIndex + 1 });
    }
    resetcurrentindex = false;
    //Resets Autoslide timer
    clearInterval(this.autoSlideInterval);
    this.handleAutoSlide();
  }

  prev() {
    if (this.state.currentIndex === 0) {
      this.setState({ currentIndex: this.state.length - this.state.show });
    }
    if (this.state.currentIndex > 0) {
      this.setState({ currentIndex: this.state.currentIndex - 1 });
    }
    //Resets Autoslide timer
    clearInterval(this.autoSlideInterval);
    this.handleAutoSlide();
  }

  render() {
    return (
      <div className="carousel-container">
        <div className="carousel-wrapper">
          {(this.state.isRepeating || this.state.currentIndex > 0) && (
            <button className="left-arrow" onClick={() => this.prev()}>
              &lt;
            </button>
          )}
          <div className="carousel-content-wrapper">
            <div
              className={`carousel-content show-${this.state.show}`}
              style={{
                transform: `translateX(-${
                  this.state.currentIndex * (100 / this.state.show)
                }%)`,
              }}
            >
              {this.props.children}
            </div>
          </div>
          {(this.state.isRepeating ||
            this.state.currentIndex < this.state.length - this.state.show) && (
            <button className="right-arrow" onClick={() => this.next()}>
              &gt;
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default Carrousel;
