import React, { Component } from "react";
import {
  Nav,
  Loader,
  Modal,
  Button,
  FloatActionButton,
  FloatActionButtonItem,
  Card,
} from "ui-kit-ck-consultant";
import { faBars, faSave, faFlag } from "@fortawesome/free-solid-svg-icons";
import { Prompt } from "react-router-dom";
import SignatureCanvas from "react-signature-canvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FadeIn from "react-fade-in";

import "../../../style/performance.css";

import Summary from "../../../components/general/Summary";
// import CardFiles from "../../../components/general/CardFiles";
import CardActionPlan from "../../../components/general/CardActionPlan";
import NavPSC from "../../../components/general/NavPSC";
import ShowOptionsNGRA from "../../../components/general/ShowOptionsNGRA-v2";
import PerformanceDisplay from "../../../components/performances/PerformanceDisplay";
import EvolutionDisplay from "../../../components/performances/EvolutionDisplay";
import MonthlyBilan from "../../../components/interviews/MonthlyBilan";
import SignatureCard from "../../../components/interviews/SignatureCard";
import StatisticalMonitoringDisplay from "../../../components/performances/StatisticalMonitoringDisplay";
import InterviewDisplayCloture from "../../../components/interviews/InterviewDisplayCloture";
import AuditDisplay from "../../../components/performances/AuditDisplay";
import PrintClass from "../../../components/general/Print";

import userLogo from "../../../images/user.png";

import axiosConfig from "../../../utils/axiosConfig";
import {
  getNameTypePerformance,
  getUserTypePerformance,
} from "../../../utils/general";
import { alertError } from "../../../utils/errorMessage";
import { formatTable } from "../../../utils/performance";

import AuthContext from "../../../context/AuthContext";

export default class Interview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      managerAnalysisValue: null,
      employeeAnalysisValue: null,
      actionPlan: [],
      actionPlanIndicator: ["Coût", "Délai", "Production", "Qualité"],
      current: null,
      interviewData: null,
      isLoad: false,
      manager: null,
      print: false,
      loader: false,
      signatures: null,
      save: null,
      noResultMessage: false,
      dataStart: "",
    };
    this.signatureRef = React.createRef();

    this.refPrintInterviewCloturees = React.createRef();
    this.refPrintSummary = React.createRef();
    this.refPrintAudit = React.createRef();
    this.refPrintEvolution = React.createRef();
    this.refPrintEvolutionChart = React.createRef();
    this.refPrintMonitoring = React.createRef();
  }

  static contextType = AuthContext;

  handleDataChange = (data) => {
    this.setState(
      {
        year: data.year,
        network: data.network,
        group: data.group,
        region: data.region,
        agency: data.agency,
        company: data.company,
        user: data.user,
        interview: data.interview,
      },
      () => {
        this.getInterviewData();
      }
    );
  };

  getInterviewData() {
    let signatures;
    this.setState({
      isLoad: true,
    });
    axiosConfig({
      url: "/interview/get-interview-data",
      data: {
        network: this.state.network,
        agency: this.state.agency,
        user: this.state.user,
        interview: this.state.interview,
        year: this.state.year,
      },
    })
      .then((res) => {
        if (res.data.success === true) {
          signatures = res.data.data.dataText.current.signature;
          let current = res.data.data.dataText.current;
          let actionPlan = res.data.data.dataText.current.plans_action;
          if (signatures.length === 0)
            signatures.push({ name: res.data.data.data.name, signature: null });
          this.setState(
            {
              interviewData: res.data.data,
              current,
              oldData: res.data.oldData,
              actionPlan,
              userProfilPicture: res.data.userProfilPicture,
              displayHonorary: res.data.honorary,
            },
            () => {
              axiosConfig({
                url: "/interview/get-manager",
                data: {
                  network: this.state.network,
                  group: this.state.group,
                  region: this.state.region,
                  agency: this.state.agency,
                  expertType: getUserTypePerformance(this.props.type),
                },
              }).then((resMana) => {
                if (resMana.data.success) {
                  let signNames = [];
                  let oldSignatures = signatures;
                  oldSignatures.map((sign) => signNames.push(sign.name));
                  if (!signNames.includes(resMana.data.manager[0].name))
                    signatures.push(resMana.data.manager[0]);
                }
                this.setState(
                  {
                    signatures,
                    isLoad: false,
                  },
                  () =>
                    this.setState({ dataStart: JSON.stringify(this.getData()) })
                );
              });
            }
          );
        } else {
          this.setState({
            interviewData: null,
            current: null,
            actionPlan: null,
            isLoad: false,
            noResultMessage: true,
          });
        }
      })
      .catch((err) => {
        this.setState({ isLoad: false });
        alertError(err);
      });
  }

  handleEditOldActionPlan = (interviewId, actionPlan, actionPlanArr) => {
    this.setState(
      {
        oldData: {
          ...this.state.oldData,
          plans_action: actionPlanArr,
        },
      },
      () => {
        let dataText = this.state.oldData.dataText.filter(
          (element) => element.interviewId === interviewId
        )[0].dataText;
        dataText.current.plans_action.forEach((plan, idx) => {
          if (plan.id === actionPlan.id) {
            dataText.current.plans_action[idx] = actionPlan;
          }
        });
        axiosConfig({
          url: "/interview/update-old-interview-data",
          data: {
            oldInterviewDataText: JSON.stringify(dataText),
            oldInterviewId: interviewId,
          },
        }).then((res) => {
          if (res.data.success) {
            alert("Validation du plan d'action modifiée avec succès");
          } else {
            alert("Erreur lors de la validation du plan d'action");
          }
        });
      }
    );
  };

  alertSave() {
    if (this.state.dataStart !== JSON.stringify(this.getData())) {
      return (
        <Prompt message="Les modifications que vous avez apportées ne seront peut-être pas enregistrées" />
      );
    }
  }

  handleRemoveSignatureUser(id) {
    let totalSignatures = this.state.signatures;
    totalSignatures.splice(id, 1);
    this.setState({
      signatures: totalSignatures,
    });
  }

  handleNewSignature(name) {
    let totalSignatures = this.state.signatures ? this.state.signatures : [];
    let item = {};
    item.name = name;
    item.signature = null;
    totalSignatures.push(item);
    this.setState({
      signatures: totalSignatures,
    });
  }

  handleModalSignatureClick(e) {
    if (this.state.modalSignature) {
      this.setState({ modalSignature: false });
    } else {
      this.setState({ modalSignature: true, manager: e });
    }
  }

  handleClearSignatureClick = () => {
    this.signatureRef.current.clear();
  };

  handleValidSignatureClick = () => {
    const id = this.state.manager;
    let signatures = this.state.signatures;
    signatures[id].signature = this.signatureRef.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    this.setState({
      signatures: signatures,
      modalSignature: false,
    });
  };

  handleRemoveSignature = (idx) => {
    let signatures = this.state.signatures;
    signatures.splice(idx, 1);
    this.setState({ signatures });
  };

  handleInterviewData = () => {
    axiosConfig({
      url: "/interview/save-interview-data",
      data: this.getData(),
    }).then((res) => {
      if (res.data.success === true) {
        this.setState({ save: true });
        alert("Entretien enregistré");
      } else {
        alert("Une erreur c'est produite lors de l'enregistrement...");
      }
    });
  };

  handleClotureInterview = () => {
    axiosConfig({
      url: "/interview/save-interview-data",
      data: this.getData(),
    }).then((res) => {
      if (res.data.success === true) {
        axiosConfig({
          url: "/interview/cloture-interview",
          data: {
            id: this.state.interviewData.id,
          },
        }).then((res) => {
          if (res.data.success === true) {
            let interviewData = this.state.interviewData;
            interviewData.status = 1;
            this.setState({ interviewData });
          } else {
            alert("Une erreur c'est produite lors de l'enregistrement...");
          }
        });
      } else {
        alert("Une erreur c'est produite lors de l'enregistrement...");
      }
    });
  };

  getData() {
    return {
      managerAnalysisValue: this.state.managerAnalysisValue,
      employeeAnalysisValue: this.state.employeeAnalysisValue,
      actionPlan: this.state.actionPlan,
      signatures: this.state.signatures,
      id: this.state.interviewData.id,
      dataText: this.state.interviewData.dataText,
      dateEnd: this.state.interviewData.dateEnd,
    };
  }

  render() {
    return (
      <div id={"print-meeting-field-expert"}>
        <Loader show={this.state.loader} />
        {this.state.data === undefined ? (
          <FadeIn>
            <Nav
              title={`Entretien ${getNameTypePerformance(this.props.type)}`}
            />
          </FadeIn>
        ) : (
          <FadeIn>
            <NavPSC
              title={`Entretien ${getNameTypePerformance(this.props.type)}`}
              activate={
                this.state.data && this.state.data.status === 0 ? true : false
              }
              handelClickPrint={(value) => this.handelClickPrint(value)}
              handelClickSave={(value) => this.handelClickSave(value)}
              handelClickClose={(value) => this.handelClickClose(value)}
            />
          </FadeIn>
        )}
        <div className={this.state.print === true ? " for-print" : ""}>
          <div className="print-option">
            <FadeIn>
              <ShowOptionsNGRA
                isChange={true}
                className="mb-10 mb-20-xl"
                onDataChange={this.handleDataChange}
                isYear={true}
                isUser={true}
                isInterview={true}
                visibilityRole={this.props.visibilityRole}
                visibilityType={this.props.visibilityType}
                preloadRole={this.props.preloadRole}
                preloadType={this.props.preloadType}
                limitUserType={this.props.limitUserType}
                limitUserRole={this.props.limitUserRole}
                userType={getUserTypePerformance(this.props.type)}
                type={this.props.type}
                isAll={true}
                notAllUsers={true}
              />
            </FadeIn>
          </div>

          {this.state.interviewData ? (
            <div>
              <FadeIn>
                <div ref={this.refPrintSummary} className="print-summary">
                  <Summary
                    className={"mt-10 mt-30-xl "}
                    imgProfil={
                      this.state.userProfilPicture
                        ? this.state.userProfilPicture
                        : userLogo
                    }
                    nameProfil={
                      this.state.interviewData
                        ? this.state.interviewData.data.name
                        : null
                    }
                    isLoader={this.state.isPrint ? null : this.state.isLoad}
                    performanceRecap={
                      this.state.interviewData &&
                      this.state.interviewData.data.performance
                        ? this.state.interviewData.data.performance
                            .recapitulatif
                        : null
                    }
                    label={
                      this.state.interviewData &&
                      this.state.interviewData.data.performance
                        .performance_qualite.resultat_audit[2]
                    }
                    labelRSE={
                      this.state.interviewData.data.performance.performance_RSE
                    }
                    type={this.props.type}
                  />
                  <PerformanceDisplay
                    perf={
                      this.state.interviewData &&
                      this.state.interviewData.data.performance
                        ? {
                            ...this.state.interviewData.data.performance,
                            displayHonorary: this.state.displayHonorary,
                          }
                        : null
                    }
                    isLoader={this.state.isPrint ? null : this.state.isLoad}
                    type={this.props.type}
                    isPrecisia={
                      this.state.interviewData && this.state.interviewData.data
                        ? this.state.interviewData.data.isPrecisia
                        : false
                    }
                  />
                </div>
                <div ref={this.refPrintAudit}>
                  {this.props.type !== 3 ? (
                    <AuditDisplay
                      auditData={
                        this.state.interviewData &&
                        this.state.interviewData.data.audit_data
                          ? this.state.interviewData.data.audit_data
                          : null
                      }
                      audit={
                        this.state.interviewData &&
                        this.state.interviewData.data.audit
                          ? this.state.interviewData.data.audit
                          : null
                      }
                      auditApData={
                        this.state.interviewData &&
                        this.state.interviewData.data_audit_AP
                      }
                      isPrint={this.state.isPrint}
                    />
                  ) : null}
                </div>
              </FadeIn>

              {this.state.interviewData &&
              this.state.interviewData.status === 1 ? ( //&& this.state.current.bilanMensuel && this.state.actionPlan && this.state.signatures
                <FadeIn>
                  <div ref={this.refPrintInterviewCloturees}>
                    <InterviewDisplayCloture
                      monthlyBilan={this.state.current.bilanMensuel}
                      actionPlan={this.state.actionPlan}
                      signatures={this.state.signatures}
                    />
                  </div>
                </FadeIn>
              ) : (
                <>
                  {this.state.interviewData &&
                  this.state.actionPlan &&
                  this.state.current &&
                  this.state.signatures ? (
                    <>
                      <FadeIn>
                        <div ref={this.refPrintEvolution}>
                          <MonthlyBilan
                            onChangeData={(e) => {
                              this.setState({
                                [e.target.name]: e.target.value,
                              });
                            }}
                            monthlyBilan={this.state.current.bilanMensuel}
                            oldData={this.state.oldData.bilanMensuel}
                          />
                          {/* <CardFiles
                            disableEdition={this.state.disableEdition}
                          /> */}
                          <CardActionPlan
                            indicator={this.state.actionPlanIndicator}
                            old={this.state.oldData.plans_action}
                            current={this.state.actionPlan}
                            handleEditCurrentActionPlan={(actionPlanArr) => {
                              this.setState({ actionPlan: actionPlanArr });
                            }}
                            handleEditOldActionPlan={
                              this.handleEditOldActionPlan
                            }
                          />
                          <SignatureCard
                            signatures={this.state.signatures}
                            onAdd={(e) => this.handleModalSignatureClick(e)}
                            onRemove={(e) => this.handleRemoveSignature(e)}
                            addNewSignature={(name) =>
                              this.handleNewSignature(name)
                            }
                            removeSign={(id) =>
                              this.handleRemoveSignatureUser(id)
                            }
                          />
                        </div>
                      </FadeIn>
                    </>
                  ) : null}
                </>
              )}

              <Modal
                title="Ajouter une signature"
                show={this.state.modalSignature}
                onClose={(e) => this.handleModalSignatureClick(e)}
              >
                <SignatureCanvas
                  ref={this.signatureRef}
                  penColor="black"
                  canvasProps={{
                    height: this.state.height,
                    width: this.state.width,
                    className: "sigCanvas",
                  }}
                />
                <Button
                  text="Rénitialiser"
                  className="m-0 mb-10 outline"
                  onClick={this.handleClearSignatureClick}
                />
                <Button
                  text="Valider"
                  className="m-0"
                  onClick={this.handleValidSignatureClick}
                />
              </Modal>

              {this.state.interviewData &&
              this.state.interviewData.data.table ? (
                <div
                  ref={this.refPrintMonitoring}
                  className="print-suivi-stat-r mt-30"
                >
                  <StatisticalMonitoringDisplay
                    dataR={formatTable(
                      this.state.interviewData.data.table,
                      "r"
                    )}
                    dataNR={
                      this.props.type !== 2
                        ? formatTable(this.state.interviewData.data.table, "nr")
                        : null
                    }
                    isUser={!!this.state.user}
                  />
                </div>
              ) : null}

              <div ref={this.refPrintEvolutionChart}>
                <EvolutionDisplay
                  chart={
                    this.state.interviewData
                      ? this.state.interviewData.data.chart
                      : null
                  }
                  type={this.props.type}
                  isLoader={
                    this.state.isPrint
                      ? null
                      : this.state.isPrint
                      ? null
                      : this.state.isLoad
                  }
                  isPrint={this.state.isPrint}
                  onLabelsLoaded={(isLoad) => this.setState({ isLoad })}
                />
              </div>
              <Loader show={this.state.isLoad} />
              {this.state.save ? null : this.alertSave()}
            </div>
          ) : this.state.noResultMessage ? (
            <Card>Aucun résultat</Card>
          ) : null}
        </div>
        {this.state.interviewData && this.state.interviewData.status === 1 ? (
          <PrintClass
            refs={
              this.state.isPrint && this.state.isLoad
                ? [
                    this.refPrintSummary,
                    this.refPrintAudit,
                    this.refPrintInterviewCloturees,
                    this.refPrintMonitoring,
                    this.refPrintEvolutionChart,
                  ]
                : []
            }
            title={this.state.interviewData && this.state.interviewData.name}
            fileName={this.state.interviewData && this.state.interviewData.name}
            onPrint={(print) =>
              this.setState({ isPrint: print, isLoad: print })
            }
            width={1500}
          />
        ) : (
          <FloatActionButton icon={<FontAwesomeIcon icon={faBars} />}>
            <FloatActionButtonItem
              text={"Sauvegarder"}
              icon={<FontAwesomeIcon icon={faSave} />}
              onClick={this.handleInterviewData}
            />
            <FloatActionButtonItem
              text={"Clôturer"}
              icon={<FontAwesomeIcon icon={faFlag} />}
              onClick={this.handleClotureInterview}
            />
            <PrintClass
              refs={
                this.state.isPrint && this.state.isLoad
                  ? [
                      this.refPrintSummary,
                      this.refPrintAudit,
                      this.refPrintEvolution,
                      this.refPrintMonitoring,
                      this.refPrintEvolutionChart,
                    ]
                  : []
              }
              title={this.state.interviewData && this.state.interviewData.name}
              fileName={
                this.state.interviewData && this.state.interviewData.name
              }
              onPrint={(print) =>
                this.setState({ isPrint: print, isLoad: print })
              }
              width={1500}
              buttonItem
            />
          </FloatActionButton>
        )}
      </div>
    );
  }
}
