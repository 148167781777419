import React, { Component } from "react";
import {
  Loader,
  Nav,
  TabsCustom,
  TabsCustomHeader,
  TabsCustomBody,
  TabsCustomMenu,
  TabsCustomItem,
  FormCheckbox,
} from "ui-kit-ck-consultant";
import FadeIn from "react-fade-in";
import { ExportCSV } from "react-export-csv";
import moment from "moment";

import Indicators from "../../../components/EGA/Indicators";
import Details from "../../../components/EGA/Details";
import Evolution from "../../../components/EGA/Evolution";
import Map from "../../../components/EGA/Map";
import List from "../../../components/EGA/List";
import ListLocation from "../../../components/EGA/ListLocation";
import PrintClass from "../../../components/general/Print";

import ShowOptionsNGRA from "../../../components/general/ShowOptionsNGRA-v2";

import axiosConfig from "../../../utils/axiosConfig";

import AuthContext from "../../../context/AuthContext";

export default class EGA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isReady: false,
      isLoad: false,
      isDownloadCSV: false,
      isIxperience: false,
      // ShowOption state
      network: 0,
      group: 0,
      region: 0,
      agency: 0,
      companyMultiples: [],
      user: 0,
      dateSinisterStart: null,
      dateSinisterEnd: null,
      dateStart: null,
      dateEnd: null,
      natureEvent: null,
      natureExpertise: null,
      garage: null,
      clientZipCode: null,
      isAge: false,
      // filters list
      folderType: "all",
      folderStatus: "all",
      tmpBody: [],
      // getDataResult
      isData: false,
      map: {},
      indicator: {},
      chart: {},
      detail: {},
      list: { head: [], body: [] },
      dataLocation: [],
      // Tabs state
      tabsData: ["Reporting", "Plateforme"],
      tabsIndex: 0,
      dataId: {},
      isPrintTable: false,
    };

    this.refPrintIndicators = React.createRef();
    this.refPrintEvolution = React.createRef();
    this.refPrintDetails = React.createRef();
    this.refPrintMap = React.createRef();
    this.refPrintList = React.createRef();
  }

  static contextType = AuthContext;

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => this.filterBodyCSV(this.state.list.body)
    );
  };

  componentDidMount() {
    if (this.context.network !== 1 && this.context.network) {
      this.setState({ tabsData: ["Reporting"] });
    }
  }

  // ShowOption data

  handleDataChange = (data) => {
    this.setState(
      {
        isReady: true,
        network: data.network,
        group: data.group,
        region: data.region,
        agency: data.agency,
        companyMultiples: data.companyMultiples,
        user: data.user,
        dateSinisterStart: data.dateSinisterStart,
        dateSinisterEnd: data.dateSinisterEnd,
        dateStart: data.dateStart,
        dateEnd: data.dateEnd,
        natureEvent: data.natureEvent,
        natureExpertise: data.natureExpertise,
        garage: data.garage,
        clientZipCode: data.clientZipCode,
      },
      () => {
        this.getData();
      }
    );
  };

  getData = () => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        axiosConfig("EGA/get-data", {
          data: {
            network: this.state.network,
            group: this.state.group,
            region: this.state.region,
            agency: this.state.agency,
            company: this.state.companyMultiples.join(","),
            user: this.state.user,
            dateSinisterStart: this.state.dateSinisterStart,
            dateSinisterEnd: this.state.dateSinisterEnd,
            dateStart: this.state.dateStart,
            dateEnd: this.state.dateEnd,
            natureEvent: this.state.natureEvent,
            natureExpertise: this.state.natureExpertise,
            garage: this.state.garage,
            clientZipCode: this.state.clientZipCode,
            isAge: this.state.isAge,
          },
        }).then((res) => {
          if (res.data.success === true) {
            this.setState(
              {
                map: res.data.data.map,
                indicator: res.data.data.indicator,
                chart: res.data.data.chart,
                detail: res.data.data.detail,
                list: res.data.data.list,
                isData: true,
                isLoad: false,
              },
              () => this.filterBodyCSV(this.state.list.body)
            );
          } else {
            this.setState({
              map: {},
              indicator: {},
              chart: {},
              detail: {},
              list: {
                head: [],
                body: [],
              },
              isData: false,
              isLoad: false,
            });
          }
        });
      }
    );
  };

  filterBodyCSV = (body) => {
    this.setState({ isLoad: true }, () => {
      setTimeout(() => {
        let tmpBody = body.filter(
          (element) =>
            (this.state.folderType === "all" ||
              (this.state.folderType === "R" &&
                element.natureExpertise !== "I" &&
                element.natureExpertise !== "3") ||
              (this.state.folderType === "NR" &&
                element.natureExpertise === "I") ||
              element.natureExpertise === "3") &&
            (this.state.folderStatus === "all" ||
              (this.state.folderStatus === "new" && element.status === 1) ||
              (this.state.folderStatus === "visit" && element.status === 2) ||
              (this.state.folderStatus === "visitOK" && element.status === 3) ||
              (this.state.folderStatus === "noVisited" &&
                element.status === 4) ||
              (this.state.folderStatus === "closing" && element.status === 5) ||
              (this.state.folderStatus === "error" && element.status === 0))
        );
        this.setState({ tmpBody, isLoad: false });
      }, 100);
    });
  };

  render() {
    return (
      <FadeIn>
        <Nav title="Reporting EGA" className="mb-10 mb-20-xl" />
        <ShowOptionsNGRA
          isChange={true}
          className="mb-10 mb-20-xl"
          onDataChange={this.handleDataChange}
          isDateSinisterStart={true}
          isDateSinisterEnd={true}
          isDateStart={true}
          isDateEnd={true}
          dateStartName={"Date de début mission"}
          dateEndName={"Date de fin mission"}
          isCompanyMultiples={true}
          isNatureEvent={true}
          defaultNatureEvent={[4, 6, 14]}
          isNatureExpertise={true}
          isUser={true}
          visibilityRole={this.props.visibilityRole}
          visibilityType={this.props.visibilityType}
          preloadRole={this.props.preloadRole}
          preloadType={this.props.preloadType}
          limitUserType={this.props.limitUserType}
          limitUserRole={this.props.limitUserRole}
          userType={[1, 2, 3, 4, 5]}
          isDefaultAllUser={true}
          isGarage={true}
          isClientZipCode={true}
          isFake={this.context.fake}
          others={
            <>
              <FormCheckbox
                text={"+ de 15 ans"}
                className="mr-10"
                checked={this.state.isAge}
                onChange={({ target }) =>
                  this.setState({ isAge: target.checked })
                }
              />
              <FormCheckbox
                text={"Impression du tableau"}
                className="mr-10"
                checked={this.state.isPrintTable}
                onChange={({ target }) =>
                  this.setState({ isPrintTable: target.checked })
                }
              />
            </>
          }
        />
        <TabsCustom>
          <TabsCustomHeader>
            {this.state.tabsData.map((element, idx) => (
              <TabsCustomMenu
                key={idx}
                title={element}
                active={this.state.tabsIndex === idx}
                disabled={!this.state.tabsData[idx]}
                onClick={(e) => {
                  if (this.state.tabsIndex !== idx) {
                    this.setState({ tabsIndex: idx, folderStatus: "all" }, () =>
                      this.filterBodyCSV(this.state.list.body)
                    );
                  }
                }}
              />
            ))}
          </TabsCustomHeader>
          <TabsCustomBody noCard>
            <TabsCustomItem active={this.state.tabsIndex === 0}>
              {this.state.isData ? (
                <>
                  <div ref={this.refPrintIndicators}>
                    <Indicators data={this.state.indicator} />
                  </div>
                  <div ref={this.refPrintEvolution}>
                    <Evolution data={this.state.chart} />
                  </div>
                  <div ref={this.refPrintDetails}>
                    <Details data={this.state.detail} />
                  </div>
                  <div ref={this.refPrintMap}>
                    <Map map={this.state.map} />
                  </div>
                  <div ref={this.refPrintList}>
                    {this.state.tabsIndex === 0 ? (
                      <List
                        dataId={{}}
                        data={{
                          head: this.state.list.head,
                          body: this.state.tmpBody,
                        }}
                        folderType={this.state.folderType}
                        folderStatus={this.state.folderStatus}
                        handleInputChange={this.handleInputChange}
                        handleDownloadCSV={() =>
                          this.setState({ isDownloadCSV: true, isLoad: false })
                        }
                        getData={this.getData}
                      />
                    ) : null}
                  </div>
                </>
              ) : null}
            </TabsCustomItem>
            <TabsCustomItem active={this.state.tabsIndex === 1}>
              {this.state.isReady ? (
                <ListLocation
                  region={this.state.region}
                  agency={this.state.agency}
                  setManageEGALocation={(data) =>
                    this.setState({ dataLocation: data })
                  }
                  handleUpdateDataId={(dataId) => this.setState({ dataId })}
                />
              ) : null}
              {this.state.tabsIndex === 1 ? (
                <List
                  data={{
                    head: this.state.list.head,
                    body: this.state.tmpBody,
                  }}
                  folderType={this.state.folderType}
                  folderStatus={this.state.folderStatus}
                  handleInputChange={this.handleInputChange}
                  handleDownloadCSV={(isIxperience = false) =>
                    this.setState({
                      isDownloadCSV: true,
                      isLoad: false,
                      isIxperience,
                    })
                  }
                  isSms={true}
                  getData={this.getData}
                  dataLocation={this.state.dataLocation}
                  dataId={this.state.dataId}
                />
              ) : null}
            </TabsCustomItem>
          </TabsCustomBody>
        </TabsCustom>
        <Loader show={this.state.isLoad} />
        {this.state.isDownloadCSV ? (
          <ExportCSV
            header={this.state.list.head.filter((element) =>
              !this.state.isIxperience
                ? element.key !== "levelIxperience"
                : true
            )}
            data={
              this.state.isIxperience
                ? this.state.list.body.filter(
                    (element) =>
                      element.levelIxperience && !element.isAlphaExpert
                  )
                : this.state.list.body
            }
            separator={";"}
            callback={() =>
              this.setState({
                isDownloadCSV: false,
                isLoad: false,
                isIxperience: false,
              })
            }
            isDownload={true}
            filename={`dossiers_EGA_${moment().format("YYYY-MM-DD")}.csv`}
          />
        ) : (
          <></>
        )}
        <PrintClass
          refs={
            this.state.isPrint
              ? this.state.isPrintTable
                ? [
                    this.refPrintIndicators,
                    this.refPrintEvolution,
                    this.refPrintDetails,
                    this.refPrintMap,
                    this.refPrintList,
                  ]
                : [
                    this.refPrintIndicators,
                    this.refPrintEvolution,
                    this.refPrintDetails,
                    this.refPrintMap,
                  ]
              : []
          }
          title={"Reporting EGA"}
          fileName={"Reporting EGA"}
          onPrint={(print) => this.setState({ isPrint: print, isLoad: print })}
          width={this.state.isPrintTable ? 2000 : 1000}
        />
      </FadeIn>
    );
  }
}
