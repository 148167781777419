import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  ButtonIcon,
  Status,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusSquare, faPlusSquare } from "@fortawesome/free-solid-svg-icons";

export default class DataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isQ1Hidden: true,
      isQ2Hidden: true,
      isQ3Hidden: true,
      isQ4Hidden: true,
    };
  }

  getTableData = (data) => {
    let result = data.map((row, rowIdx) => {
      let dataLine = row.data.map((element, idx) => {
        let unit = row.unit;

        if (
          (!this.state.isQ1Hidden ||
            ![0, 1, 2, 3, 4, 5, 6, 7, 8].includes(idx)) &&
          (!this.state.isQ2Hidden ||
            ![12, 13, 14, 15, 16, 17, 18, 19, 20].includes(idx)) &&
          (!this.state.isQ3Hidden ||
            ![24, 25, 26, 27, 28, 29, 30, 31, 32].includes(idx)) &&
          (!this.state.isQ4Hidden ||
            ![36, 37, 38, 39, 40, 41, 42, 43, 44].includes(idx))
        ) {
          // CELL CONTENT
          let dataCell;
          if ((idx + 1) % 3 === 0 || (idx + 1) % 3 === 1) {
            dataCell =
              element === 1 || element === 0 || (element && unit !== "dossier")
                ? `${element} ${unit}`
                : element
                ? `${element} ${unit}s`
                : null;
          } else {
            dataCell =
              element === 0 ? (
                <Status className={"bg-blue"} text={`${element} %`} />
              ) : element ? (
                row.evolReversed ? (
                  <Status
                    className={element < 0 ? "bg-orange" : "bg-green"}
                    text={`${element} %`}
                  />
                ) : (
                  <Status
                    className={element < 0 ? "bg-green" : "bg-orange"}
                    text={`${element} %`}
                  />
                )
              ) : (
                element
              );
          }

          // CELL TYPE
          if (row.title) {
            return (
              <Th
                key={row.id + "-" + idx}
                className={`text-center nowrap`}
                spaceRight={(idx + 1) % 3 === 0 && idx !== row.data.length - 1}
                spaceLeft={(idx + 1) % 3 === 1 && idx !== 0}
              >
                {dataCell}
              </Th>
            );
          } else {
            return (
              <Td
                key={row.id + "-" + idx}
                className={`text-center nowrap`}
                spaceRight={(idx + 1) % 3 === 0 && idx !== row.data.length - 1}
                spaceLeft={(idx + 1) % 3 === 1 && idx !== 0}
              >
                {dataCell}
              </Td>
            );
          }
        }
        return null;
      });

      // LINE CONTENT
      return (
        <Tr key={row.id}>
          {row.title ? (
            <Th radiusTopLeft={rowIdx === 0}>{row.name}</Th>
          ) : (
            <Td radiusTopLeft={rowIdx === 0}>{row.name}</Td>
          )}
          {dataLine}
        </Tr>
      );
    });

    return result;
  };

  toggleHiddenColumns = (quarter) => {
    this.setState((state) => ({
      [`isQ${quarter}Hidden`]: !state[`isQ${quarter}Hidden`],
    }));
  };

  render() {
    return (
      <>
        <Table
          radiusTopLeft={this.props.data && this.props.data.length > 0}
          empty={
            this.props.data && this.props.data.length > 0
              ? ""
              : "Aucune donnée n'est disponible"
          }
        >
          <Thead>
            <Tr>
              <Th
                hide={
                  this.props.data && this.props.data.length > 0 ? true : false
                }
                spaceRight
              ></Th>
              {!this.state.isQ1Hidden ? (
                <>
                  <Th
                    colSpan={3}
                    spaceLeft
                    radiusTopLeft
                    className={"text-center"}
                  >
                    Janvier
                  </Th>
                  <Th colSpan={3} className={"text-center"}>
                    Février
                  </Th>
                  <Th colSpan={3} spaceRight className={"text-center"}>
                    Mars
                  </Th>
                </>
              ) : null}
              <Th
                colSpan={3}
                spaceLeft
                spaceRight
                radiusTopLeft={
                  this.state.isQ1Hidden &&
                  this.props.data &&
                  this.props.data.length > 0
                }
                className={"text-center"}
              >
                <ButtonIcon
                  small
                  style={{ color: "#fff" }}
                  onClick={() => this.toggleHiddenColumns(1)}
                >
                  <FontAwesomeIcon
                    icon={this.state.isQ1Hidden ? faPlusSquare : faMinusSquare}
                  />
                </ButtonIcon>
                {this.props.year} T1 / {this.props.year - 1} T1
              </Th>
              {!this.state.isQ2Hidden ? (
                <>
                  <Th colSpan={3} spaceLeft className={"text-center"}>
                    Avril
                  </Th>
                  <Th colSpan={3} className={"text-center"}>
                    Mai
                  </Th>
                  <Th colSpan={3} spaceRight className={"text-center"}>
                    Juin
                  </Th>
                </>
              ) : null}
              <Th colSpan={3} spaceLeft spaceRight className={"text-center"}>
                <ButtonIcon
                  small
                  style={{ color: "#fff" }}
                  onClick={() => this.toggleHiddenColumns(2)}
                >
                  <FontAwesomeIcon
                    icon={this.state.isQ2Hidden ? faPlusSquare : faMinusSquare}
                  />
                </ButtonIcon>
                {this.props.year} T2 / {this.props.year - 1} T2
              </Th>
              {!this.state.isQ3Hidden ? (
                <>
                  <Th colSpan={3} spaceLeft className={"text-center"}>
                    Juillet
                  </Th>
                  <Th colSpan={3} className={"text-center"}>
                    Août
                  </Th>
                  <Th colSpan={3} spaceRight className={"text-center"}>
                    Septembre
                  </Th>
                </>
              ) : null}
              <Th colSpan={3} spaceLeft spaceRight className={"text-center"}>
                <ButtonIcon
                  small
                  style={{ color: "#fff" }}
                  onClick={() => this.toggleHiddenColumns(3)}
                >
                  <FontAwesomeIcon
                    icon={this.state.isQ3Hidden ? faPlusSquare : faMinusSquare}
                  />
                </ButtonIcon>
                {this.props.year} T3 / {this.props.year - 1} T3
              </Th>
              {!this.state.isQ4Hidden ? (
                <>
                  <Th colSpan={3} spaceLeft className={"text-center"}>
                    Octobre
                  </Th>
                  <Th colSpan={3} className={"text-center"}>
                    Novembre
                  </Th>
                  <Th colSpan={3} spaceRight className={"text-center"}>
                    Décembre
                  </Th>
                </>
              ) : null}
              <Th colSpan={3} spaceLeft className={"text-center"}>
                <ButtonIcon
                  small
                  style={{ color: "#fff" }}
                  onClick={() => this.toggleHiddenColumns(4)}
                >
                  <FontAwesomeIcon
                    icon={this.state.isQ4Hidden ? faPlusSquare : faMinusSquare}
                  />
                </ButtonIcon>
                {this.props.year} T4 / {this.props.year - 1} T4
              </Th>
            </Tr>
            <Tr>
              <Th
                hide={
                  this.props.data && this.props.data.length > 0 ? true : false
                }
                spaceRight
              ></Th>
              {!this.state.isQ1Hidden ? (
                <>
                  <Th spaceLeft className={"text-center"}>
                    janv.-{(this.props.year - 1).toString().slice(2)}
                  </Th>
                  <Th className={"text-center"}>Evol.N-1</Th>
                  <Th spaceRight className={"text-center"}>
                    janv.-{this.props.year.toString().slice(2)}
                  </Th>
                  <Th spaceLeft className={"text-center"}>
                    févr.-{(this.props.year - 1).toString().slice(2)}
                  </Th>
                  <Th className={"text-center"}>Evol.N-1</Th>
                  <Th spaceRight className={"text-center"}>
                    févr.-{this.props.year.toString().slice(2)}
                  </Th>
                  <Th spaceLeft className={"text-center"}>
                    mars-{(this.props.year - 1).toString().slice(2)}
                  </Th>
                  <Th className={"text-center"}>Evol.N-1</Th>
                  <Th spaceRight className={"text-center"}>
                    mars-{this.props.year.toString().slice(2)}
                  </Th>
                </>
              ) : null}
              <Th spaceLeft className={"text-center"}>
                {this.props.year - 1} T1
              </Th>
              <Th className={"text-center"}>Evol.N-1</Th>
              <Th spaceRight className={"text-center"}>
                {this.props.year} T1
              </Th>
              {!this.state.isQ2Hidden ? (
                <>
                  <Th spaceLeft className={"text-center"}>
                    avril-{(this.props.year - 1).toString().slice(2)}
                  </Th>
                  <Th className={"text-center"}>Evol.N-1</Th>
                  <Th spaceRight className={"text-center"}>
                    avril-{this.props.year.toString().slice(2)}
                  </Th>
                  <Th spaceLeft className={"text-center"}>
                    mai-{(this.props.year - 1).toString().slice(2)}
                  </Th>
                  <Th className={"text-center"}>Evol.N-1</Th>
                  <Th spaceRight className={"text-center"}>
                    mai-{this.props.year.toString().slice(2)}
                  </Th>
                  <Th spaceLeft className={"text-center"}>
                    juin-{(this.props.year - 1).toString().slice(2)}
                  </Th>
                  <Th className={"text-center"}>Evol.N-1</Th>
                  <Th spaceRight className={"text-center"}>
                    juin-{this.props.year.toString().slice(2)}
                  </Th>
                </>
              ) : null}
              <Th spaceLeft className={"text-center"}>
                {this.props.year - 1} T2
              </Th>
              <Th className={"text-center"}>Evol.N-1</Th>
              <Th spaceRight className={"text-center"}>
                {this.props.year} T2
              </Th>
              {!this.state.isQ3Hidden ? (
                <>
                  <Th spaceLeft className={"text-center"}>
                    juil.-{(this.props.year - 1).toString().slice(2)}
                  </Th>
                  <Th className={"text-center"}>Evol.N-1</Th>
                  <Th spaceRight className={"text-center"}>
                    juil.-{this.props.year.toString().slice(2)}
                  </Th>
                  <Th spaceLeft className={"text-center"}>
                    août-{(this.props.year - 1).toString().slice(2)}
                  </Th>
                  <Th className={"text-center"}>Evol.N-1</Th>
                  <Th spaceRight className={"text-center"}>
                    août-{this.props.year.toString().slice(2)}
                  </Th>
                  <Th spaceLeft className={"text-center"}>
                    sept.-{(this.props.year - 1).toString().slice(2)}
                  </Th>
                  <Th className={"text-center"}>Evol.N-1</Th>
                  <Th spaceRight className={"text-center"}>
                    sept.-{this.props.year.toString().slice(2)}
                  </Th>
                </>
              ) : null}
              <Th spaceLeft className={"text-center"}>
                {this.props.year - 1} T3
              </Th>
              <Th className={"text-center"}>Evol.N-1</Th>
              <Th spaceRight className={"text-center"}>
                {this.props.year} T3
              </Th>
              {!this.state.isQ4Hidden ? (
                <>
                  <Th spaceLeft className={"text-center"}>
                    oct.-{(this.props.year - 1).toString().slice(2)}
                  </Th>
                  <Th className={"text-center"}>Evol.N-1</Th>
                  <Th spaceRight className={"text-center"}>
                    oct.-{this.props.year.toString().slice(2)}
                  </Th>
                  <Th spaceLeft className={"text-center"}>
                    nov.-{(this.props.year - 1).toString().slice(2)}
                  </Th>
                  <Th className={"text-center"}>Evol.N-1</Th>
                  <Th spaceRight className={"text-center"}>
                    nov.-{this.props.year.toString().slice(2)}
                  </Th>
                  <Th spaceLeft className={"text-center"}>
                    déc-{(this.props.year - 1).toString().slice(2)}
                  </Th>
                  <Th className={"text-center"}>Evol.N-1</Th>
                  <Th spaceRight className={"text-center"}>
                    déc-{this.props.year.toString().slice(2)}
                  </Th>
                </>
              ) : null}
              <Th spaceLeft className={"text-center"}>
                {this.props.year - 1} T4
              </Th>
              <Th className={"text-center"}>Evol.N-1</Th>
              <Th className={"text-center"}>{this.props.year} T4</Th>
            </Tr>
          </Thead>

          <Tbody>
            {this.props.data ? this.getTableData(this.props.data) : null}
          </Tbody>
        </Table>

        {this.props.name === "repairTime" ? (
          <div style={{ fontSize: "10px" }} className="mb-20">
            <p>* Ead + Rep : Nature expertise exclure "I" et "3"</p>
            <p>* Rep : Nature expertise exclure "I", "J" et "3"</p>
            <p>
              * Ead Global : Nature expertise exclure "I" et "3", inclure "J"
            </p>
            <p>
              * Ead Agréé : Nature expertise exclure "I" et "3", inclure "J" ;
              Agréés
            </p>
            <p>
              * Ead Non Agréé : Nature expertise exclure "I" et "3", inclure "J"
              ; Approuvé faux
            </p>
          </div>
        ) : this.props.name === "totalLossTime" ? (
          <div style={{ fontSize: "10px" }} className="mb-20">
            <p>* Non Rep : Nature expertise inclure "I" ou "3"</p>
            <p>{`* Convention : Nature expertise inclure "I" ou "3" ; VRADE < 4500 € TTC`}</p>
            <p>{`* Appel d'Offre : Nature expertise inclure "I" ou "3" ; VRADE >= 4500 € TTC`}</p>
          </div>
        ) : this.props.name === "technique" ? (
          <div style={{ fontSize: "10px" }} className="mb-20">
            <p>* Rep : Nature expertise exclure "I", "J" et "3"</p>
            <p>
              * Rep Agréé : Nature expertise exclure "I", "J" et "3" ; Approuvé
              vrai
            </p>
            <p>
              * Rep Non Agréé : Nature expertise exclure "I", "J" et "3" ;
              Approuvé faux
            </p>
          </div>
        ) : null}
      </>
    );
  }
}
