import React from "react";
import { Modal, Status } from "ui-kit-ck-consultant";

export default class ModalDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getNPS = (value) => {
    if (value >= 9) {
      return <Status className="bg-green" text={`Promoteur (${value})`} />;
    } else if (value >= 7) {
      return <Status className="bg-orange" text={`Passif (${value})`} />;
    } else if (value >= 0 && value !== null) {
      return <Status className="bg-red" text={`Détracteur (${value})`} />;
    } else {
      return null;
    }
  };

  getStatus = (value, text = null) => {
    if (!value) {
      return null;
    }

    if (value) {
      value = value.toString();
    }

    switch (value) {
      case "0":
        return <Status className="bg-red" text={text ? text : "Mauvais"} />;
      case "1":
        return <Status className="bg-orange" text={text ? text : "Moyen"} />;
      case "2":
        return <Status className="bg-yellow" text={text ? text : "Bien"} />;
      case "3":
        return <Status className="bg-green" text={text ? text : "Très bien"} />;
      default:
        return <Status className="bg-blue" text={value} />;
    }
  };

  getValueData = (name) => {
    if (this.props.data && this.props.data.data && name) {
      return this.getStatus(this.props.data.data[name]);
    } else {
      return null;
    }
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onClose={this.props.onClose}
        title="Détails de la réponse"
      >
        <h3 className="text-center">Global</h3>
        <p>Référence : {this.getStatus(this.props.data.reference)}</p>
        <p>Agence : {this.getStatus(this.props.data.agency)}</p>
        <p>Expert : {this.getStatus(this.props.data.expert)}</p>
        <p>Date d'envoi : {this.getStatus(this.props.data.dateInsert)}</p>
        <p>Date de réponse : {this.getStatus(this.props.data.date)}</p>
        <p>Note : {this.getStatus(this.props.data.value)}</p>
        {this.props.data.company !== "ALLIANZ" ? (
          <p>NPS : {this.getNPS(this.props.data.nps)}</p>
        ) : null}
        <p>
          Commentaire : <b>{this.props.data.comment}</b>
        </p>
        <p>
          Analyse : <b>{this.props.data.analyze}</b>
        </p>
        {this.props.data.company !== "ALLIANZ" ? (
          <>
            <p>
              Pertinence des supports de communication :{" "}
              {this.getValueData("radio-cabinet-pertinence")}
            </p>
            <p>
              Facilité de contact : {this.getValueData("radio-cabinet-contact")}
            </p>
            <h3 className="text-center">Accueil</h3>
            <p>
              Réponses apportées : {this.getValueData("radio-accueil-reponse")}
            </p>
            <p>
              Amabilité de l'interlocuteur :{" "}
              {this.getValueData("radio-accueil-amabilite")}
            </p>
            <p>
              Délai d'attente : {this.getValueData("radio-accueil-attente")}
            </p>
            <p>
              Qualité d'écoute : {this.getValueData("radio-accueil-qualite")}
            </p>
            <p>
              Compréhension : {this.getValueData("radio-accueil-comprehension")}
            </p>
            <h3 className="text-center">Gestion du dossier</h3>
            <p>
              Clarté / pertinence :{" "}
              {this.getValueData("radio-gestion-information")}
            </p>
            <p>
              Délai de réponse : {this.getValueData("radio-gestion-reponse")}
            </p>
            <p>Amabilité : {this.getValueData("radio-gestion-amabilite")}</p>
            <h3 className="text-center">Identification</h3>
            <p>
              Réparabilité économique :{" "}
              {this.getStatus(
                this.props.data.reparable === "Oui" ? 3 : 0,
                this.props.data.reparable
              )}
            </p>
          </>
        ) : (
          <>
            <p>
              Dans quelle mesure êtes-vous satisfait de l'intervention de
              l'expert ? :{" "}
              {parseInt(
                this.props.data.data["button-star-intervention-expert"]
              ) + 1}{" "}
              étoile(s)
            </p>
            <p>
              Disponibilité et réactivité de l'expert ? :{" "}
              {parseInt(this.props.data.data["button-star-reactivite-expert"]) +
                1}{" "}
              étoile(s)
            </p>
            <p>
              Clarté des informations apportées ? :{" "}
              {parseInt(this.props.data.data["button-star-information"]) + 1}{" "}
              étoile(s)
            </p>
            <p>
              Simplicité des démarches :{" "}
              {parseInt(this.props.data.data["button-star-simplicite"]) + 1}{" "}
              étoile(s)
            </p>
            <p>
              Délai de traitement de votre dossier :{" "}
              {parseInt(this.props.data.data["button-star-traitement"]) + 1}{" "}
              étoile(s)
            </p>
            <p>
              Service proposés par le portail client :{" "}
              {parseInt(this.props.data.data["button-star-portail"]) + 1}{" "}
              étoile(s)
            </p>
          </>
        )}
        <p>Type de sinistre : {this.getStatus(this.props.data.type)}</p>
        <p>Lieu d'expertise : {this.getStatus(this.props.data.location)}</p>
        <p>Compagnie : {this.getStatus(this.props.data.company)}</p>
      </Modal>
    );
  }
}
