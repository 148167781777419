import React, { Component } from "react";
import { Status, Table, Tbody, Td, Th, Thead, Tr } from "ui-kit-ck-consultant";

export class TableEADbyCompany extends Component {
  render() {
    const months = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];

    function getEvol(N, N_1) {
      return N > 0 ? Math.round(((N - N_1) / N_1) * 100) : 0;
    }

    function color(value, key) {
      if (value === 0) {
        return "bg-blue-light black w-100";
      }
      if (value > 0) {
        return "bg-green-light black w-100";
      } else if (value < 0) {
        return "bg-red-light black w-100";
      }
    }

    return (
      <Table>
        <Thead>
          <Tr className={"nowrap"}>
            <Th hide></Th>
            <Th radiusTopLeft>N-1</Th>
            <Th>N</Th>
            <Th spaceRight>N / N-1</Th>
            {months.map((month, idx) =>
              idx === 0 ? (
                <Th key={idx} spaceLeft>
                  {month}
                </Th>
              ) : (
                <Th key={idx}>{month}</Th>
              )
            )}
          </Tr>
        </Thead>
        <Tbody>
          {this.props.data.map((company, idx) => {
            return (
              <Tr key={idx} className={"nowrap"}>
                <Th radiusTopLeft={idx === 0}>{company["name"]}</Th>
                <Td>{company["N-1"] + " %"}</Td>
                <Td>{company["N"] + " %"}</Td>
                <Td spaceRight>
                  <Status
                    className={
                      color(getEvol(company["N"], company["N-1"])) + " nowrap"
                    }
                    text={`${getEvol(company["N"], company["N-1"])} %`}
                  />
                </Td>
                {company.data.map((month, id) => (
                  <Td key={id} spaceLeft={id === 0}>
                    {month + " %"}
                  </Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    );
  }
}

export default TableEADbyCompany;
