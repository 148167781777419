import React, { Component } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Modal,
  FormInput,
  FormSelect,
  Button,
  ButtonIcon,
  Card,
  Loader,
  FormCheckbox,
  Pagination,
  FormTextarea,
} from "ui-kit-ck-consultant";
import {
  faPlus,
  faTimes,
  faCheck,
  faPen,
  faTrash,
  faInfoCircle,
  faSync,
  faEye,
  faEyeSlash,
  faDownload,
  faSearch,
  faCalendarAlt,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "react-flexbox-grid";
import moment from "moment";
import { ExportCSV } from "react-export-csv";

import axiosConfig from "../../utils/axiosConfig";
import { generateString } from "../../utils/general";
import handleSortClick from "../../utils/handleSortClick";

import AuthContext from "../../context/AuthContext";

export default class ListLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      pageLength: 10,
      isLoad: false,
      isModalAdd: false,
      modalAddId: null,
      isModalInfo: false,
      modalInfoIdx: null,
      isModalSearch: false,
      modalSearchIdx: null,
      modalSearchReference: "",
      isModalCalendar: false,
      modalCalendarIdx: null,
      modalCalendarDate: null,
      modalCalendarReference: "",
      region: "",
      agency: "",
      garage: "",
      name: "",
      address: "",
      zipCode: "",
      city: "",
      latitude: "",
      longitude: "",
      phone: "",
      interval: "",
      information: "",
      dates: [],
      data: [],
      dataCSV: [],
      isDownload: false,
      regionOptions: [],
      agencyOptions: [],
      dataId: {},
      order: null,
      agenciesPlatform: [],
    };

    this.sortRef = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
    ];
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.getManageEGALocation();
    this.getRegions();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.region !== this.props.region ||
      prevProps.agency !== this.props.agency
    ) {
      this.getManageEGALocation();
    }
  }

  getManageEGALocation = () => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        axiosConfig("EGA/get-manage-ega-location", {
          data: { region: this.props.region, agency: this.props.agency },
        }).then((res) => {
          if (res.data.success) {
            this.setState(
              {
                isLoad: false,
                data: res.data.data,
                agenciesPlatform: [],
              },
              () => this.props.setManageEGALocation(res.data.data)
            );
          } else {
            this.setState(
              {
                isLoad: false,
                data: [],
                agenciesPlatform: [],
              },
              () => {
                this.props.setManageEGALocation([]);
                alert("Une erreur est survenue");
              }
            );
          }
        });
      }
    );
  };

  addManageEGALocation = () => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        axiosConfig("EGA/add-manage-ega-location", {
          data: {
            region: this.state.region,
            agency: this.state.agency,
            garage: this.state.garage,
            name: this.state.name,
            address: this.state.address,
            zipCode: this.state.zipCode,
            city: this.state.city,
            latitude: this.state.latitude,
            longitude: this.state.longitude,
            phone: this.state.phone,
            interval: this.state.interval,
            information: this.state.information,
            dates: this.state.dates,
          },
        }).then((res) => {
          if (res.data.success) {
            this.setState(
              {
                isLoad: false,
                isModalAdd: false,
                region: "",
                agency: "",
                garage: "",
                name: "",
                address: "",
                zipCode: "",
                latitude: "",
                longitude: "",
                phone: "",
                interval: "",
                information: "",
                dates: [],
                modalAddId: null,
              },
              () => {
                this.getManageEGALocation();
              }
            );
          } else {
            this.setState(
              {
                isLoad: false,
              },
              () => {
                alert("Une erreur est survenue");
              }
            );
          }
        });
      }
    );
  };

  updateManageEGALocation = () => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        axiosConfig("EGA/update-manage-ega-location", {
          data: {
            region: this.state.region,
            agency: this.state.agency,
            garage: this.state.garage,
            name: this.state.name,
            address: this.state.address,
            zipCode: this.state.zipCode,
            city: this.state.city,
            latitude: this.state.latitude,
            longitude: this.state.longitude,
            phone: this.state.phone,
            interval: this.state.interval,
            information: this.state.information,
            dates: this.state.dates,
            id: this.state.modalAddId,
          },
        }).then((res) => {
          if (res.data.success) {
            this.setState(
              {
                isLoad: false,
                isModalAdd: false,
                region: "",
                agency: "",
                garage: "",
                name: "",
                address: "",
                zipCode: "",
                city: "",
                latitude: "",
                longitude: "",
                phone: "",
                interval: "",
                information: "",
                dates: [],
                modalAddId: null,
              },
              () => {
                this.getManageEGALocation();
              }
            );
          } else {
            this.setState(
              {
                isLoad: false,
              },
              () => {
                alert("Une erreur est survenue");
              }
            );
          }
        });
      }
    );
  };

  updateStatusManageEGALocation = (id, status) => {
    if (
      window.confirm(
        "Êtes-vous sûr de vouloir changer l'état de cette plateforme ?"
      )
    ) {
      this.setState(
        {
          isLoad: true,
        },
        () => {
          axiosConfig("EGA/update-status-manage-ega-location", {
            data: {
              status,
              id,
            },
          }).then((res) => {
            if (res.data.success) {
              this.setState(
                {
                  isLoad: false,
                },
                () => {
                  this.getManageEGALocation();
                }
              );
            } else {
              this.setState(
                {
                  isLoad: false,
                },
                () => {
                  alert("Une erreur est survenue");
                }
              );
            }
          });
        }
      );
    }
  };

  deleteManageEGALocation = (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cette ligne ?")) {
      this.setState(
        {
          isLoad: true,
        },
        () => {
          axiosConfig("EGA/delete-manage-ega-location", {
            data: {
              id,
            },
          }).then((res) => {
            if (res.data.success) {
              this.setState(
                {
                  isLoad: false,
                },
                () => {
                  this.getManageEGALocation();
                }
              );
            } else {
              this.setState(
                {
                  isLoad: false,
                },
                () => {
                  alert("Une erreur est survenue");
                }
              );
            }
          });
        }
      );
    }
  };

  getAppointmentEga = (manageEgaLocationId, isCSV = true) => {
    this.setState(
      {
        isLoad: true,
      },
      () => {
        axiosConfig("EGA/get-appointment-ega", {
          data: {
            manageEgaLocationId,
          },
        }).then((res) => {
          if (res.data.success) {
            this.setState(
              {
                isLoad: false,
                isDownload: isCSV ? true : false,
                isModalCalendar: isCSV ? false : true,
                dataCSV: res.data.data,
              },
              () => {}
            );
          } else {
            this.setState(
              {
                isLoad: false,
              },
              () => {
                alert("Une erreur est survenue");
              }
            );
          }
        });
      }
    );
  };

  getStatus = (status, isSlots) => {
    if (status === 0) {
      return (
        <Td className="bg-red-light black text-center pl-10 pr-10">Inactif</Td>
      );
    } else if (isSlots <= 0) {
      return (
        <Td className="bg-orange-light black text-center pl-10 pr-10">
          Complet
        </Td>
      );
    } else if (status === 1) {
      return (
        <Td className="bg-green-light black text-center pl-10 pr-10">Actif</Td>
      );
    }
  };

  getRegions = () => {
    axiosConfig({
      url: "/general/get-regions",
    }).then((res) => {
      if (res.data.success === true) {
        this.setState({
          regionOptions: res.data.regions,
        });
      }
    });
  };

  getAgencies = () => {
    axiosConfig({
      url: "/general/get-agencies",
      data: {
        region: this.state.region,
        manageAgency: this.context.manageAgency,
      },
    }).then((res) => {
      if (res.data.success === true) {
        this.setState({
          agencyOptions: res.data.agencies,
        });
      }
    });
  };

  searchFolder = () => {
    if (this.state.modalSearchReference) {
      this.setState({ isLoad: true }, () => {
        axiosConfig("search/folder", {
          data: {
            reference: this.state.modalSearchReference,
          },
        }).then((res) => {
          if (res.data.success === true) {
            const locationId = this.state.data[this.state.modalSearchIdx].id;
            this.setState(
              {
                isLoad: false,
                isModalSearch: false,
                modalSearchIdx: null,
                modalSearchReference: "",
              },
              () => {
                window.open(
                  `https://ega.pilotagecabinet.fr/${res.data.data[0].id}/${locationId}/${this.context.id}`
                );
              }
            );
          } else {
            this.setState(
              {
                isLoad: false,
                isModalSearch: false,
                modalSearchIdx: null,
                modalSearchReference: "",
              },
              () => alert("Aucun dossier trouvé")
            );
          }
        });
      });
    }
  };

  getDisabledTime = () => {
    let disabledTime = [];

    if (this.state.modalCalendarDate !== null) {
      this.state.dataCSV.forEach((element) => {
        if (
          this.state.data[this.state.modalCalendarIdx].dates[
            this.state.modalCalendarDate
          ].date === element.date &&
          (this.state.data[this.state.modalCalendarIdx].dates[
            this.state.modalCalendarDate
          ].token === element.token ||
            !element.token)
        ) {
          disabledTime.push(element.hour);
        }
      });
    }

    return disabledTime;
  };

  getArrayTime = (timesRange, timeStep) => {
    const disabledTime = this.getDisabledTime();

    let times = [];

    timesRange.forEach((range) => {
      const start = moment(range[0], "HH:mm");
      const end = moment(range[1], "HH:mm");
      let old = start;

      while (old.isBefore(end)) {
        times.push({
          value: old.format("HH:mm"),
          status: !disabledTime.includes(old.format("HH:mm")),
        });
        old = old.add(timeStep, "minutes");
      }
    });

    return times;
  };

  deleteAppointment = () => {
    if (this.state.modalCalendarReference) {
      if (window.confirm("Êtes-vous sûr de vouloir supprimer ce RDV ?")) {
        this.setState({ isLoad: true }, () => {
          axiosConfig("ega/delete-appointment-ega", {
            data: {
              reference: this.state.modalCalendarReference,
            },
          }).then((res) => {
            if (res.data.success) {
              this.getAppointmentEga(
                this.state.data[this.state.modalCalendarIdx].id,
                false
              );
            } else {
              this.setState({ isLoad: false }, () => {
                alert("Une erreur est survenue");
              });
            }
          });
        });
      }
    }
  };

  handleSortClick = (desc, column) => {
    this.setState({ order: handleSortClick(this.sortRef, desc, column) });
  };

  render() {
    let data = this.state.data;
    if (this.state.order) {
      data.sort((a, b) => {
        let aX = a[this.state.order.name] || "";
        let bX = b[this.state.order.name] || "";

        if (Number.isInteger(aX) && Number.isInteger(bX)) {
          return this.state.order.desc ? aX - bX : bX - aX;
        } else {
          aX = aX.toString();
          bX = bX.toString();
          return this.state.order.desc
            ? aX.normalize().localeCompare(bX.normalize())
            : bX.normalize().localeCompare(aX.normalize());
        }
      });
    }

    const agencies = ["Pas d'agence"];
    data.forEach((platform) => {
      if (platform.agencyName && !agencies.includes(platform.agencyName)) {
        agencies.push(platform.agencyName);
      }
    });

    return (
      <>
        <Card
          title="Liste des plateformes d'expertise"
          className="mb-10 mb-20-xl"
          classNameChildren="overflow-x-auto"
          action={
            <>
              <ButtonIcon
                className="blue"
                small
                onClick={() => this.getAppointmentEga()}
              >
                <FontAwesomeIcon icon={faSync} />
              </ButtonIcon>
              <ButtonIcon
                className="blue"
                small
                onClick={() => this.setState({ isModalAdd: true })}
              >
                <FontAwesomeIcon icon={faPlus} />
              </ButtonIcon>
            </>
          }
          disableStyleAction={true}
        >
          {agencies.map((agency) => (
            <FormCheckbox
              className="mb-10 mr-10"
              text={agency}
              checked={this.state.agenciesPlatform.includes(agency)}
              onChange={() => {
                let agenciesPlatform = this.state.agenciesPlatform;

                if (agenciesPlatform.includes(agency)) {
                  agenciesPlatform.splice(agenciesPlatform.indexOf(agency), 1);
                } else {
                  agenciesPlatform.push(agency);
                }

                this.setState({
                  agenciesPlatform,
                  pageLength: !agenciesPlatform.length ? 10 : data.length,
                  activePage: 1,
                });
              }}
            />
          ))}
          <Table>
            <Thead>
              <Tr>
                <Th className="pl-10 pr-10">#</Th>
                <Th
                  ref={this.sortRef[0]}
                  sort={true}
                  onSort={(desc) => this.handleSortClick(desc, 0)}
                  nameSort={"dateInsert"}
                  className="pl-10 pr-10"
                >
                  Date de création
                </Th>
                <Th
                  ref={this.sortRef[1]}
                  sort={true}
                  onSort={(desc) => this.handleSortClick(desc, 1)}
                  nameSort={"regionName"}
                  className="pl-10 pr-10"
                >
                  Région
                </Th>
                <Th
                  ref={this.sortRef[2]}
                  sort={true}
                  onSort={(desc) => this.handleSortClick(desc, 2)}
                  nameSort={"agencyName"}
                  className="pl-10 pr-10"
                >
                  Agence
                </Th>
                <Th
                  ref={this.sortRef[3]}
                  sort={true}
                  onSort={(desc) => this.handleSortClick(desc, 3)}
                  nameSort={"name"}
                  className="pl-10 pr-10"
                >
                  Nom
                </Th>
                <Th
                  ref={this.sortRef[4]}
                  sort={true}
                  onSort={(desc) => this.handleSortClick(desc, 4)}
                  nameSort={"address"}
                  className="pl-10 pr-10"
                >
                  Adresse
                </Th>
                <Th
                  ref={this.sortRef[5]}
                  sort={true}
                  onSort={(desc) => this.handleSortClick(desc, 5)}
                  nameSort={"zipCode"}
                  className="pl-10 pr-10"
                >
                  Code postal
                </Th>
                <Th
                  ref={this.sortRef[6]}
                  sort={true}
                  onSort={(desc) => this.handleSortClick(desc, 6)}
                  nameSort={"city"}
                  className="pl-10 pr-10"
                >
                  Ville
                </Th>
                <Th className="pl-10 pr-10">Latitude</Th>
                <Th
                  ref={this.sortRef[6]}
                  sort={true}
                  onSort={(desc) => this.handleSortClick(desc, 6)}
                  nameSort={"city"}
                  className="pl-10 pr-10"
                >
                  Longitude
                </Th>
                <Th
                  ref={this.sortRef[7]}
                  sort={true}
                  onSort={(desc) => this.handleSortClick(desc, 7)}
                  nameSort={"phone"}
                  className="pl-10 pr-10"
                >
                  Téléphone
                </Th>
                <Th
                  ref={this.sortRef[8]}
                  sort={true}
                  onSort={(desc) => this.handleSortClick(desc, 8)}
                  nameSort={"appointmentOk"}
                  className="pl-10 pr-10"
                >
                  RDV pris
                </Th>
                <Th
                  ref={this.sortRef[9]}
                  sort={true}
                  onSort={(desc) => this.handleSortClick(desc, 9)}
                  nameSort={"appointmentWait"}
                  className="pl-10 pr-10"
                >
                  RDV restant
                </Th>
                <Th
                  ref={this.sortRef[10]}
                  sort={true}
                  onSort={(desc) => this.handleSortClick(desc, 10)}
                  nameSort={"information"}
                  className="pl-10 pr-10"
                >
                  Informations internes
                </Th>
                <Th className="pl-10 pr-10">État</Th>
                <Th className="pl-10 pr-10">Actions</Th>
                <Th className="pl-10 pr-10">Select.</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data
                .slice(
                  (this.state.activePage - 1) * this.state.pageLength,
                  this.state.activePage * this.state.pageLength
                )
                .map((data, idx) => (
                  <Tr
                    key={idx}
                    style={
                      this.state.agenciesPlatform.length &&
                      !this.state.agenciesPlatform.includes(data.agencyName)
                        ? { display: "none" }
                        : {}
                    }
                  >
                    <Th className="pl-10 pr-10">
                      {(this.state.activePage - 1) * this.state.pageLength +
                        idx +
                        1}
                    </Th>
                    <Td className="pl-10 pr-10">{data.dateInsert}</Td>
                    <Td className="pl-10 pr-10">{data.regionName}</Td>
                    <Td className="pl-10 pr-10">{data.agencyName}</Td>
                    <Td className="pl-10 pr-10">{data.name}</Td>
                    <Td className="pl-10 pr-10">{data.address}</Td>
                    <Td className="pl-10 pr-10">{data.zipCode}</Td>
                    <Td className="pl-10 pr-10">{data.city}</Td>
                    <Td className="pl-10 pr-10">{data.latitude}</Td>
                    <Td className="pl-10 pr-10">{data.longitude}</Td>
                    <Td className="pl-10 pr-10">{data.phone}</Td>
                    <Td className="pl-10 pr-10">{data.appointmentOk}</Td>
                    <Td className="pl-10 pr-10">{data.appointmentWait}</Td>
                    <Td className="pl-10 pr-10">{data.information}</Td>
                    {this.getStatus(data.status, data.appointmentWait)}
                    <Td className="nowrap p-0">
                      <div className="d-flex">
                        <ButtonIcon
                          className="blue"
                          small
                          onClick={() =>
                            this.setState({
                              isModalInfo: true,
                              modalInfoIdx:
                                (this.state.activePage - 1) *
                                  this.state.pageLength +
                                idx,
                            })
                          }
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </ButtonIcon>
                        <ButtonIcon
                          className="blue"
                          small
                          onClick={() =>
                            this.setState(
                              {
                                isModalAdd: true,
                                modalAddId: data.id,
                                region: data.region,
                                agency: data.agency,
                                garage: data.garage,
                                name: data.name,
                                address: data.address,
                                zipCode: data.zipCode,
                                city: data.city,
                                latitude: data.latitude,
                                longitude: data.longitude,
                                phone: data.phone,
                                interval: data.interval,
                                information: data.information,
                                dates: data.dates,
                              },
                              () => {
                                if (data.region) {
                                  this.getAgencies();
                                }
                              }
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faPen} />
                        </ButtonIcon>
                        {this.context.role !== 0 ? (
                          <ButtonIcon
                            className="blue"
                            small
                            onClick={() =>
                              this.updateStatusManageEGALocation(
                                data.id,
                                data.status ? 0 : 1
                              )
                            }
                          >
                            <FontAwesomeIcon
                              icon={data.status ? faEye : faEyeSlash}
                            />
                          </ButtonIcon>
                        ) : null}
                        <ButtonIcon
                          className="blue"
                          small
                          onClick={() =>
                            this.setState(
                              {
                                modalCalendarIdx:
                                  (this.state.activePage - 1) *
                                    this.state.pageLength +
                                  idx,
                              },
                              () => this.getAppointmentEga(data.id, false)
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </ButtonIcon>
                        <ButtonIcon
                          className="blue"
                          small
                          onClick={() =>
                            this.setState({
                              isModalSearch: true,
                              modalSearchIdx:
                                (this.state.activePage - 1) *
                                  this.state.pageLength +
                                idx,
                            })
                          }
                        >
                          <FontAwesomeIcon icon={faSearch} />
                        </ButtonIcon>
                        <ButtonIcon
                          className="blue"
                          small
                          onClick={() => this.getAppointmentEga(data.id)}
                        >
                          <FontAwesomeIcon icon={faDownload} />
                        </ButtonIcon>
                        <ButtonIcon
                          className="red"
                          small
                          onClick={() => this.deleteManageEGALocation(data.id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </ButtonIcon>
                      </div>
                    </Td>
                    <Td>
                      <div className="d-flex">
                        <FormCheckbox
                          className="m-auto"
                          checked={
                            this.state.dataId[data.id]
                              ? this.state.dataId[data.id]
                              : false
                          }
                          onChange={({ target }) => {
                            let dataId = this.state.dataId;
                            if (target.checked) {
                              dataId[data.id] = target.checked;
                            } else {
                              delete dataId[data.id];
                            }
                            this.setState({ dataId }, () =>
                              this.props.handleUpdateDataId(dataId)
                            );
                          }}
                        />
                      </div>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
          <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={this.state.pageLength}
            totalItemsCount={this.state.data.length}
            pageRangeDisplayed={4}
            onChange={(activePage) => {
              this.setState({ activePage: activePage });
            }}
            firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
            lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
            prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
            nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
          />
        </Card>
        <Modal
          show={this.state.isModalInfo}
          title="Informations"
          onClose={() =>
            this.setState({ isModalInfo: false, modalInfoIdx: null })
          }
        >
          {this.state.modalInfoIdx !== null ? (
            <>
              <p className="mb-5 mt-0">
                Date de création :{" "}
                <b>{this.state.data[this.state.modalInfoIdx].dateInsert}</b>
              </p>
              <p className="mb-5 mt-0">
                Nom : <b>{this.state.data[this.state.modalInfoIdx].name}</b>
              </p>
              <p className="mb-5 mt-0">
                Adresse :{" "}
                <b>{this.state.data[this.state.modalInfoIdx].address}</b>
              </p>
              <p className="mb-5 mt-0">
                Code postal :{" "}
                <b>{this.state.data[this.state.modalInfoIdx].zipCode}</b>
              </p>
              <p className="mb-5 mt-0">
                Ville : <b>{this.state.data[this.state.modalInfoIdx].city}</b>
              </p>
              <p className="mb-5 mt-0">
                Téléphone :{" "}
                <b>{this.state.data[this.state.modalInfoIdx].phone}</b>
              </p>
              <p className="d-flex mb-5 mt-0">
                <span className="mt-auto mb-auto">État :</span>
                <span className="mt-auto mb-auto ml-10">
                  {this.getStatus(
                    this.state.data[this.state.modalInfoIdx].status
                  )}
                </span>
              </p>
              {this.state.data[this.state.modalInfoIdx].information ? (
                <p className="mb-5 mt-0">
                  Informations :{" "}
                  <b>{this.state.data[this.state.modalInfoIdx].information}</b>
                </p>
              ) : null}
              <p className="mb-5 mt-0">
                Interval :{" "}
                <b>{this.state.data[this.state.modalInfoIdx].interval}</b>
              </p>
              <h3 className="mb-5 mt-5">Créneaux</h3>
              {this.state.data[this.state.modalInfoIdx].dates.map((date) => (
                <p className="mb-5 mt-0">
                  {moment(date.date).format("DD/MM/YYYY")} -
                  {date.start1 ? ` ${date.start1} à ${date.end1}` : ""}
                  {date.start1 && date.start2 ? " - " : ""}
                  {date.start2 ? `${date.start2} à ${date.end2}` : ""}
                </p>
              ))}
            </>
          ) : null}
        </Modal>
        <Modal
          show={this.state.isModalAdd}
          onClose={() =>
            this.setState({ isModalAdd: false, modalAddId: null, agency: "" })
          }
          title={`${
            this.state.modalAddId ? "Modifier" : "Ajouter"
          } une zone d'expertise`}
        >
          <FormSelect
            className={"mb-10"}
            title="Région"
            init="Veuillez sélectionner une région"
            ignore={true}
            value={this.state.region}
            options={this.state.regionOptions}
            onChange={({ target }) =>
              this.setState(
                {
                  region: target.value,
                  agency: "",
                },
                () => {
                  this.getAgencies();
                }
              )
            }
          />
          <FormSelect
            className={"mb-10"}
            title="Agence"
            init="Veuillez sélectionner une agence"
            ignore={true}
            value={this.state.agency ? this.state.agency : "DEFAULT"}
            options={[
              { value: "", text: "Toutes les agences" },
              ...this.state.agencyOptions,
            ]}
            onChange={({ target }) =>
              this.setState({
                agency: target.value,
              })
            }
            disabled={!this.state.region}
          />

          <FormInput
            title="Code réparateur (,)"
            placeholder="xxxxx,xxxxx,xxxxx"
            className="mb-10"
            value={this.state.garage}
            onChange={({ target }) =>
              this.setState({
                garage: target.value,
              })
            }
          />
          <span
            style={{
              display: "inline-block",
              fontSize: "14px",
              fontWeight: "bold",
              color: "var(--black)",
              marginBottom: "8px",
            }}
          >
            Lieu
          </span>
          <FormInput
            placeholder="Nom"
            className="mb-10"
            value={this.state.name}
            onChange={({ target }) =>
              this.setState({
                name: target.value,
              })
            }
          />
          <FormInput
            placeholder="Adresse"
            className="mb-10"
            value={this.state.address}
            onChange={({ target }) =>
              this.setState({
                address: target.value,
              })
            }
          />
          <FormInput
            placeholder="Code postal"
            className="mb-10"
            value={this.state.zipCode}
            onChange={({ target }) =>
              this.setState({
                zipCode: target.value,
              })
            }
          />
          <FormInput
            placeholder="Ville"
            className="mb-10"
            value={this.state.city}
            onChange={({ target }) =>
              this.setState({
                city: target.value,
              })
            }
          />
          <FormInput
            placeholder="Latitude"
            className="mb-10"
            value={this.state.latitude}
            onChange={({ target }) =>
              this.setState({
                latitude: target.value,
              })
            }
          />
          <FormInput
            placeholder="Longitude"
            className="mb-10"
            value={this.state.longitude}
            onChange={({ target }) =>
              this.setState({
                longitude: target.value,
              })
            }
          />
          <FormInput
            placeholder="Téléphone"
            className="mb-10"
            value={this.state.phone}
            onChange={({ target }) =>
              this.setState({
                phone: target.value,
              })
            }
          />
          <FormTextarea
            placeholder="Informations"
            className="mb-10"
            value={this.state.information}
            onChange={({ target }) =>
              this.setState({
                information: target.value,
              })
            }
          />
          <FormInput
            type="number"
            placeholder="Interval"
            className="mb-10"
            value={this.state.interval}
            onChange={({ target }) =>
              this.setState({
                interval: target.value,
              })
            }
          />
          {this.state.dates.map((date, idx) => (
            <div>
              <div key={idx} className="d-flex mb-10">
                <FormInput
                  placeholder="Date"
                  type="date"
                  value={date.date}
                  onChange={({ target }) => {
                    let dates = this.state.dates;
                    dates[idx].date = target.value;
                    this.setState({ dates });
                  }}
                  className="w-100"
                />
                {!this.state.modalAddId ? (
                  <ButtonIcon
                    isBackground
                    className="ml-10"
                    onClick={() => {
                      let dates = this.state.dates;
                      if (dates.length > 1) {
                        dates.splice(idx, 1);
                        this.setState({ dates });
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </ButtonIcon>
                ) : null}
              </div>
              <Row>
                <Col xs={12} md={6} className="mb-10">
                  <FormInput
                    title="Début AM"
                    type="time"
                    max={date.end1}
                    value={date.start1}
                    onChange={({ target }) => {
                      let dates = this.state.dates;
                      dates[idx].start1 = target.value;
                      this.setState({ dates });
                    }}
                  />
                </Col>
                <Col xs={12} md={6} className="mb-10">
                  <FormInput
                    title="Fin AM"
                    type="time"
                    min={date.start1}
                    value={date.end1}
                    onChange={({ target }) => {
                      let dates = this.state.dates;
                      dates[idx].end1 = target.value;
                      this.setState({ dates });
                    }}
                  />
                </Col>
                <Col xs={12} md={6} className="mb-10">
                  <FormInput
                    title="Début PM"
                    type="time"
                    max={date.end2}
                    value={date.start2}
                    onChange={({ target }) => {
                      let dates = this.state.dates;
                      dates[idx].start2 = target.value;
                      this.setState({ dates });
                    }}
                  />
                </Col>
                <Col xs={12} md={6} className="mb-10">
                  <FormInput
                    title="Fin PM"
                    type="time"
                    min={date.start2}
                    value={date.end2}
                    onChange={({ target }) => {
                      let dates = this.state.dates;
                      dates[idx].end2 = target.value;
                      this.setState({ dates });
                    }}
                  />
                </Col>
              </Row>
            </div>
          ))}
          <div className="d-flex mb-10">
            <Button
              text="Ajouter une date"
              className="w-auto"
              onClick={() => {
                let dates = this.state.dates;
                dates.push({
                  date: "",
                  start1: "",
                  start2: "",
                  end1: "",
                  end2: "",
                  token: generateString(),
                });
                this.setState({ dates });
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
          <div className="d-flex mb-10">
            <Button
              text="Valider"
              className="w-auto ml-auto"
              onClick={
                this.state.modalAddId
                  ? this.updateManageEGALocation
                  : this.addManageEGALocation
              }
            >
              <FontAwesomeIcon icon={faCheck} />
            </Button>
          </div>
        </Modal>
        <Modal
          show={this.state.isModalSearch}
          onClose={() =>
            this.setState({
              isModalSearch: false,
              modalSearchIdx: null,
              modalSearchReference: "",
            })
          }
          onValid={() => this.searchFolder()}
          title="Rechercher un dossier"
        >
          <FormInput
            placeholder="Référence"
            className="mb-10"
            value={this.state.modalSearchReference}
            onChange={({ target }) =>
              this.setState({
                modalSearchReference: target.value,
              })
            }
          />
        </Modal>
        <Modal
          show={this.state.isModalCalendar}
          onClose={() =>
            this.setState({
              isModalCalendar: false,
              modalCalendarIdx: null,
              modalCalendarDate: null,
              modalCalendarInput: "",
            })
          }
          title="Créneaux"
        >
          {this.state.modalCalendarIdx !== null ? (
            <>
              <h4>Date</h4>
              <div className="d-flex" style={{ flexWrap: "wrap" }}>
                {this.state.data[this.state.modalCalendarIdx].dates.map(
                  (date, idx) => (
                    <Button
                      key={idx}
                      className="w-auto mb-10 mr-10"
                      outline={idx !== this.state.modalCalendarDate}
                      text={`${moment(date.date).format("DD/MM/YYYY")} (${
                        [...this.state.data[this.state.modalCalendarIdx].dates]
                          .splice(0, idx + 1)
                          .filter((subElement) => subElement.date === date.date)
                          .length
                      })`}
                      onClick={() => this.setState({ modalCalendarDate: idx })}
                    />
                  )
                )}
              </div>
              <h4>Heure</h4>
              <div className="d-flex" style={{ flexWrap: "wrap" }}>
                {this.state.modalCalendarDate !== null ? (
                  this.getArrayTime(
                    this.state.modalCalendarDate !== null
                      ? [
                          [
                            this.state.data[this.state.modalCalendarIdx].dates[
                              this.state.modalCalendarDate
                            ].start1,
                            this.state.data[this.state.modalCalendarIdx].dates[
                              this.state.modalCalendarDate
                            ].end1,
                          ],
                          [
                            this.state.data[this.state.modalCalendarIdx].dates[
                              this.state.modalCalendarDate
                            ].start2,
                            this.state.data[this.state.modalCalendarIdx].dates[
                              this.state.modalCalendarDate
                            ].end2,
                          ],
                        ]
                      : [],
                    this.state.data[this.state.modalCalendarIdx].interval
                  ).map((element, idx) => (
                    <Button
                      key={idx}
                      className="w-auto mb-10 mr-10"
                      disabled={!element.status}
                      text={element.value}
                      onClick={() => {}}
                    />
                  ))
                ) : (
                  <p>Veuillez sélectionner une date</p>
                )}
              </div>
            </>
          ) : null}
          <h4>Retirer un RDV</h4>
          <Row>
            <Col xs={12} md={7}>
              <FormInput
                className="mb-10"
                title="Référence"
                value={this.state.modalCalendarReference}
                onChange={({ target }) =>
                  this.setState({
                    modalCalendarReference: target.value,
                  })
                }
              />
            </Col>
            <Col xs={12} md={5} className="d-flex">
              <Button
                className="mt-auto mb-10"
                text="Supprimer"
                disabled={!this.state.modalCalendarReference}
                onClick={this.deleteAppointment}
              />
            </Col>
          </Row>
        </Modal>
        <Loader show={this.state.isLoad} />
        {this.state.isDownload ? (
          <>
            <ExportCSV
              header={[
                {
                  name: "Date",
                  key: "date",
                },
                {
                  name: "Heure",
                  key: "hour",
                },
                {
                  name: "N° dossier",
                  key: "reference",
                },
                {
                  name: "Client",
                  key: "client",
                },
                {
                  name: "Marque",
                  key: "brand",
                },
                {
                  name: "Modèle",
                  key: "model",
                },
                {
                  name: "Immatriculation",
                  key: "licensePlate",
                },
              ]}
              data={this.state.dataCSV}
              separator={";"}
              callback={() =>
                this.setState({
                  isDownload: !this.state.isDownload,
                })
              }
              isDownload={true}
              filename={`EGA-planning.csv`}
            />
          </>
        ) : null}
      </>
    );
  }
}
