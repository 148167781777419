import React from "react";

import { Loader } from "ui-kit-ck-consultant";

import CardIdea from "../../components/Ideas/CardIdea";
import FiltersIdea from "../../components/Ideas/FiltersIdea";
import ShowOptionsNGRA from "../../components/general/ShowOptionsNGRA-v2";
import ModalForm from "../../components/Ideas/ModalForm";

import axiosConfig from "../../utils/axiosConfig";

import AuthContext from "../../context/AuthContext";
export default class Ideas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // NGRA
      network: null,
      group: null,
      region: null,
      agency: null,
      user: null,
      year: null,
      category: null,
      // Ideas
      ideas: [],
      // Form fields
      title: null,
      content: null,
      type: null,
      typeForm: null,
      categoryForm: null,
      categoryDisabled: true,
      categoryFormDisabled: true,
      priority: 0,
      status0: true,
      status1: true,
      status2: false,
      // Modal
      isOpenModal: false,
      // Select Options
      typeOptions: [],
      categoryOptions: [],
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.getIdeas();
    this.getTypes();
  }

  handleDataChange = (data) => {
    this.setState(
      {
        network: data.network,
        group: data.group,
        region: data.region,
        agency: data.agency,
        user: data.user,
        year: data.year,
      },
      () => this.getIdeas()
    );
  };

  handleCheckboxChange = (e) => {
    let { name, checked } = e.target;
    this.setState(
      {
        [name]: checked,
      },
      () => this.getIdeas()
    );
  };

  getNGRA() {
    return {
      network: this.state.network,
      group: this.state.group,
      region: this.state.region,
      agency: this.state.agency,
      user: this.state.user,
      year: this.state.year,
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;

    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name === "type") {
          if (value && value !== "0") {
            this.setState(
              { categoryDisabled: false },
              this.getCategories(value),
              this.getIdeas()
            );
          } else {
            this.setState({ categoryDisabled: true, category: null }, () =>
              this.getIdeas()
            );
          }
        }
        if (name === "typeForm") {
          if (value && value !== "0")
            this.setState(
              { categoryFormDisabled: null },
              this.getCategories(value)
            );
          else
            this.setState({ categoryFormDisabled: true, categoryForm: null });
        }
        if (name === "category") this.getIdeas();
      }
    );
  };

  handleAdd = () => {
    if (
      ![
        this.state.title,
        this.state.content,
        this.state.typeForm,
        this.state.categoryForm,
      ].some((field) => field === 0 || field === "0" || field === null)
    ) {
      this.setState({ isLoad: true, data: null }, () => {
        axiosConfig("/support/add", {
          data: {
            title: this.state.title,
            content: this.state.content,
            type: this.state.typeForm,
            categoryForm: this.state.categoryForm,
            priority: this.state.priority,
          },
        }).then((res) => {
          this.setState(
            {
              isLoad: false,
              isOpenModal: false,
            },
            () => {
              if (res.data.success) {
                this.getIdeas();
              } else {
                alert("Une erreur est survenue");
              }
            }
          );
        });
      });
    } else {
      alert("Veuillez compléter tous les champs");
    }
  };

  getIdeas() {
    this.setState({ isLoad: true, data: null }, () => {
      axiosConfig("/support/get", {
        data: {
          NGRA: this.getNGRA(),
          type: this.state.type ? this.state.type : null,
          category: this.state.category ? this.state.category : null,
          status0: this.state.status0,
          status1: this.state.status1,
          status2: this.state.status2,
        },
      }).then((res) => {
        if (res.data.success) {
          this.setState({
            ideas: res.data.result,
            isLoad: false,
          });
        } else {
          this.setState({
            ideas: [],
            isLoad: false,
          });
        }
      });
    });
  }

  getCategories(type) {
    this.setState({ isLoad: true }, () => {
      axiosConfig("/support/get-category", { data: { type } }).then((res) => {
        if (res.data.result.success) {
          res.data.result.result.unshift({
            value: 0,
            text: "Veuillez sélectionner une catégorie",
          });
          this.setState({
            isLoad: false,
            categoryOptions: res.data.result.result,
          });
        } else
          this.setState({
            isLoad: false,
            categoryOptions: [],
          });
      });
    });
  }

  getTypes() {
    this.setState({ isLoad: true }, () => {
      axiosConfig("/support/get-type").then((res) => {
        if (res.data.success) {
          res.data.result.result.unshift({
            value: 0,
            text: "Veuillez sélectionner un type",
          });
          this.setState({
            isLoad: false,
            typeOptions: res.data.result.result,
            isOpenModal: false,
          });
        } else
          this.setState({
            isLoad: false,
            typeOptions: [],
            isOpenModal: false,
          });
      });
    });
  }

  closeIdea(id, status) {
    this.setState({ isLoad: true }, () => {
      axiosConfig("/support/close-idea", { data: { id, status } }).then(() => {
        this.setState(
          {
            isLoad: false,
          },
          this.getIdeas
        );
      });
    });
  }

  deleteIdea(id) {
    this.setState({ isLoad: true }, () => {
      axiosConfig("/support/delete-idea", { data: { id } }).then(() => {
        this.setState(
          {
            isLoad: false,
          },
          this.getIdeas
        );
      });
    });
  }

  render() {
    return (
      <>
        <Loader show={this.state.isLoad} />
        <ShowOptionsNGRA
          isChange={true}
          className="mb-10 mb-20-xl"
          onDataChange={this.handleDataChange}
          isAll={true}
          isUser={true}
          isYear={true}
          visibilityRole={this.props.visibilityRole}
          visibilityType={this.props.visibilityType}
          preloadRole={this.props.preloadRole}
          preloadType={this.props.preloadType}
          limitUserType={this.props.limitUserType}
          limitUserRole={this.props.limitUserRole}
          others={
            <FiltersIdea
              categoryDisabled={this.state.categoryDisabled}
              category={this.state.category}
              type={this.state.type}
              onChange={this.handleInputChange}
              handleCheckboxChange={this.handleCheckboxChange}
              onChangeType={this.onChangeType}
              typeOptions={this.state.typeOptions}
              categoryOptions={this.state.categoryOptions}
              status0={this.state.status0}
              status1={this.state.status1}
              status2={this.state.status2}
              handleOpenModal={() =>
                this.setState({
                  isOpenModal: !this.state.isOpenModal,
                })
              }
            />
          }
        />

        <ModalForm
          show={this.state.isOpenModal}
          onClose={() =>
            this.setState({
              isOpenModal: !this.state.isOpenModal,
            })
          }
          fields={{ title: this.state.title, content: this.state.content }}
          typeOptions={this.state.typeOptions}
          type={this.state.typeForm}
          category={this.state.categoryForm}
          priority={this.state.priority}
          categoryOptions={this.state.categoryOptions}
          categoryDisabled={this.state.categoryFormDisabled}
          onChange={this.handleInputChange}
          valid={this.handleAdd}
        />
        {this.state.ideas.length
          ? this.state.ideas.map((idea, idx) => {
              return (
                <CardIdea
                  onClickClose={(id, status) => this.closeIdea(id, status)}
                  onClickDelete={(id) => this.deleteIdea(id)}
                  network={this.context.network}
                  key={idx}
                  idea={idea}
                  reload={() => this.getIdeas()}
                />
              );
            })
          : null}
      </>
    );
  }
}
