import React, { Component } from "react";
import { FormTextarea } from "ui-kit-ck-consultant";

export class AddEvolution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: null,
    };
    this.initialState = this.state;
  }

  componentDidMount = () => {
    this.setState({ comment: this.props.value });
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.value !== this.props.value &&
      this.props.value !== this.state.comment
    ) {
      this.setState({ comment: this.props.value });
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () =>
      this.props.onChange({ name: this.props.name, value: this.state.comment })
    );
  };

  render() {
    return (
      <div className={"mt-20"}>
        <FormTextarea
          type="text"
          name={"comment"}
          placeholder="Commentaire..."
          value={this.state.comment ? this.state.comment : ""}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

export default AddEvolution;
