
const natureExpertise = (nature) => {
  let label;
  switch (nature) {
    case '3':
      label = 'RIV';
      break;
    case 'I':
      label = 'VEI';
      break;
    case 'J':
      label = 'EAD';
      break;
    case 'Q':
      label = 'SUIVI VE';
      break;
    case '1':
      label = 'NORMALE';
      break;
    default:
      label = nature
      break;
  }
  return label
} 

export default natureExpertise