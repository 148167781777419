import React, { Component } from "react";
import {
  Nav,
  TabsCustom,
  TabsCustomHeader,
  TabsCustomMenu,
  TabsCustomBody,
  TabsCustomItem,
  Loader,
  Card,
  BarChart,
} from "ui-kit-ck-consultant";
import FadeIn from "react-fade-in";
import moment from "moment";

import ShowOptionsNGRA from "../../../components/general/ShowOptionsNGRA-v2";
import CardPodiumTable from "../../../components/general/CardPodiumTable";

import axiosConfig from "../../../utils/axiosConfig";
import {
  getCurrencyValue,
  getPercentageValue,
  getValue,
} from "../../../utils/general";
import { getColors } from "../../../utils/colors";

import AuthContext from "../../../context/AuthContext";

export default class CompanyRanking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Loader state
      isLoad: false,
      // ShowOption state
      year: 0,
      network: 0,
      group: 0,
      region: 0,
      agency: 0,
      companyMultiples: [],
      user: 0,
      natureExpertise: null,
      natureEvent: null,
      // Data state
      companiesData: [],
      // Tabs state
      tabsType: [
        { start: 0, name: "Réparable" },
        { start: 8, name: "Non réparable" },
        { start: 12, name: "RSE" },
        { start: 13, name: "Honoraire moyen" },
        { start: 14, name: "Taux d'actions" },
      ],
      tabsDetail: [
        {
          name: "Coût moyen",
          type: 0,
          key: "costReport20",
          count: "countReport20",
          format: "currency",
        },
        { name: "M.O.", type: 0, key: "hourWorkforce", format: "" },
        { name: "Pièces", type: 0, key: "costParts", format: "currency" },
        {
          name: "Tx PRE",
          type: 0,
          key: "percentagePRE",
          format: "percentage",
          reverse: true,
        },
        {
          name: "Tx PQE",
          type: 0,
          key: "percentagePQE",
          format: "percentage",
          reverse: true,
        },
        {
          name: "Tx PRN",
          type: 0,
          key: "percentagePRN",
          format: "percentage",
        },
        {
          name: "Tx pièces réparées",
          type: 0,
          key: "percentageRepair",
          format: "percentage",
          reverse: true,
        },
        {
          name: "Tx EAD",
          type: 0,
          key: "percentageEAD",
          format: "percentage",
          reverse: true,
        },
        {
          name: "Tx SELFCARE",
          type: 0,
          key: "percentageSelfcare",
          format: "percentage",
          reverse: true,
        },
        {
          name: "délai mission-dépot",
          type: 0,
          key: "delayMissionClosing",
          count: "countMissionClosing",
          format: "",
        },
        {
          name: "Qualité client",
          type: 0,
          key: "satisfaction",
          count: "countSatisfaction",
          format: "",
          reverse: true,
        },
        {
          name: "VRADE",
          type: 1,
          key: "costVRADE20",
          count: "countVRADE20",
          format: "currency",
        },
        {
          name: "délai mission-SD29",
          type: 1,
          key: "delayMissionSD29",
          count: "countMissionSD29",
          format: "",
        },
        {
          name: "délai mission-dépot",
          type: 1,
          key: "delayMissionClosing",
          count: "countMissionClosing",
          format: "",
        },
        {
          name: "Qualité client",
          type: 1,
          key: "satisfaction",
          count: "countSatisfaction",
          format: "",
          reverse: true,
        },
        {
          name: "RSE",
          type: 2,
          key: "RSE",
          format: "percentage",
          reverse: true,
        },
        {
          name: "Honoraire moyen",
          type: 3,
          key: "averageHonorary",
          format: "currency",
          reverse: true,
        },
        {
          name: "Taux d'actions",
          type: 4,
          key: "averageActions",
          format: "",
        },
      ],
      tabsIndexType: 0,
      tabsIndexDetail: 0,
    };

    this.refPrintGlobal = React.createRef();
  }

  static contextType = AuthContext;

  handleDataChange = (data) => {
    this.setState(
      {
        year: data.year,
        network: data.network,
        group: data.group,
        region: data.region,
        agency: data.agency,
        companyMultiples: data.companyMultiples,
        user: data.user,
        dateStart: data.dateStart,
        dateEnd: data.dateEnd,
        natureExpertise: data.natureExpertise,
        natureEvent: data.natureEvent,
      },
      () => {
        this.getData();
      }
    );
  };

  getData = () => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("/company-ranking/get-data", {
        data: {
          dateStart: this.state.dateStart,
          dateEnd: this.state.dateEnd,
          network: this.state.network,
          group: this.state.group,
          region: this.state.region,
          agency: this.state.agency,
          companies: this.state.companyMultiples,
          user: this.state.user,
          natureExpertise: this.state.natureExpertise,
          natureEvent: this.state.natureEvent,
        },
      })
        .then((res) => {
          if (res.data.success) {
            this.setState({
              companiesData: res.data.companies,
              isLoad: false,
            });
          } else {
            this.setState({ isLoad: false, companiesData: [] }, () => {
              alert("Une erreur est survenue");
            });
          }
        })
        .catch((err) => {
          this.setState({ isLoad: false }, () => {
            alert("Une erreur est survenue");
          });
        });
    });
  };

  getValue = (value, format) => {
    switch (format) {
      case "currency":
        return getCurrencyValue(value);
      case "percentage":
        return getPercentageValue(value);
      default:
        return getValue(value);
    }
  };

  getType = () => {
    if (this.state.tabsIndexType === 0) {
      return "dataR";
    } else if (this.state.tabsIndexType === 1) {
      return "dataNR";
    } else if (this.state.tabsIndexType === 2) {
      return "dataRSE";
    } else if (this.state.tabsIndexType === 3) {
      return "dataHonorary";
    } else if (this.state.tabsIndexType === 4) {
      return "dataActions";
    }
  };

  render() {
    let now = moment(this.state.dateEnd).subtract(11, "months").clone();
    let months = [];
    while (now.isSameOrBefore(moment(this.state.dateEnd))) {
      months.push(now.format("MM/YYYY"));
      now.add(1, "months");
    }

    return (
      <>
        <FadeIn>
          <div ref={this.refPrintGlobal}>
            <Nav className="mb-10 mb-20-xl nowrap" title="Classement compagnie">
              {this.state.companyLogo ? (
                <img
                  className="imageCompany"
                  src={this.state.companyLogo}
                  alt="logo-company"
                />
              ) : null}
            </Nav>
            <ShowOptionsNGRA
              isChange={true}
              className="mb-10 mb-20-xl"
              onDataChange={this.handleDataChange}
              isUser={true}
              isDateStart={true}
              isDateEnd={true}
              isAll={true}
              visibilityRole={this.props.visibilityRole}
              visibilityType={this.props.visibilityType}
              preloadRole={this.props.preloadRole}
              preloadType={this.props.preloadType}
              limitUserType={this.props.limitUserType}
              limitUserRole={this.props.limitUserRole}
              allUserRole={this.props.allUserRole}
              userType={this.context.type === 5 ? [5] : [2, 3]}
              isNatureExpertise={true}
              isNatureEvent={true}
              isCompanyMultiples={true}
              defaultNatureExpertise={[1, 3, 10, 2, 9]}
              defaultNatureEvent={[8, 9]}
            />
            <TabsCustom>
              <TabsCustomHeader>
                {this.state.tabsType.map((element, idxType) => (
                  <TabsCustomMenu
                    key={idxType}
                    title={element.name}
                    active={this.state.tabsIndexType === idxType}
                    onClick={(e) => {
                      if (this.state.tabsIndexType !== idxType) {
                        this.setState({
                          tabsIndexType: idxType,
                          tabsIndexDetail: element.start,
                        });
                      }
                    }}
                  />
                ))}
              </TabsCustomHeader>
              <TabsCustomBody noCard>
                <TabsCustomItem active={true}>
                  <TabsCustom>
                    <TabsCustomHeader>
                      {this.state.tabsDetail.map((element, idxDetail) => (
                        <>
                          {element.type === this.state.tabsIndexType ? (
                            <TabsCustomMenu
                              key={idxDetail}
                              title={element.name}
                              active={this.state.tabsIndexDetail === idxDetail}
                              onClick={(e) => {
                                if (this.state.tabsIndexDetail !== idxDetail) {
                                  this.setState({
                                    tabsIndexDetail: idxDetail,
                                  });
                                }
                              }}
                            />
                          ) : null}
                        </>
                      ))}
                    </TabsCustomHeader>
                    <TabsCustomBody noCard>
                      {this.state.tabsDetail.map((element, idxDetail) => (
                        <TabsCustomItem
                          key={idxDetail}
                          active={this.state.tabsIndexDetail === idxDetail}
                        >
                          <Card title="Graphique" className="mb-10 mb-20-xl">
                            <p style={{ fontSize: "10px" }}>
                              Genre : VP et CTTE
                            </p>
                            <BarChart
                              isLegend
                              type={"line"}
                              digits={2}
                              title={"Évolution"}
                              labels={months}
                              format={
                                this.state.tabsDetail[
                                  this.state.tabsIndexDetail
                                ].format
                              }
                              dataSet={Object.keys(
                                this.state.companiesData
                              ).map((key) => {
                                return {
                                  type: "line",
                                  label: this.state.companiesData[key].name,
                                  data: this.state.companiesData[
                                    key
                                  ].months.map(
                                    (element) =>
                                      element[this.getType()][
                                        this.state.tabsDetail[
                                          this.state.tabsIndexDetail
                                        ].key
                                      ]
                                  ),
                                  color:
                                    this.state.companiesData[key].color ||
                                    getColors()[0],
                                };
                              })}
                              height={380}
                            />
                          </Card>
                          <CardPodiumTable
                            title={"Classement"}
                            isLoad={false}
                            other={"Genre : VP et CTTE"}
                            isSpecial={true}
                            data={Object.keys(this.state.companiesData)
                              .sort((a, b) =>
                                this.state.tabsDetail[
                                  this.state.tabsIndexDetail
                                ].reverse
                                  ? this.state.companiesData[b][this.getType()][
                                      this.state.tabsDetail[
                                        this.state.tabsIndexDetail
                                      ].key
                                    ] -
                                    this.state.companiesData[a][this.getType()][
                                      this.state.tabsDetail[
                                        this.state.tabsIndexDetail
                                      ].key
                                    ]
                                  : this.state.companiesData[a][this.getType()][
                                      this.state.tabsDetail[
                                        this.state.tabsIndexDetail
                                      ].key
                                    ] -
                                    this.state.companiesData[b][this.getType()][
                                      this.state.tabsDetail[
                                        this.state.tabsIndexDetail
                                      ].key
                                    ]
                              )
                              .filter((key) => {
                                if (
                                  this.state.companiesData[key][this.getType()]
                                    .total &&
                                  this.state.companiesData[key][this.getType()][
                                    this.state.tabsDetail[
                                      this.state.tabsIndexDetail
                                    ].key
                                  ]
                                ) {
                                  return true;
                                } else {
                                  return false;
                                }
                              })
                              .map((key) => {
                                return {
                                  name: this.state.companiesData[key].name,
                                  value: this.getValue(
                                    this.state.companiesData[key][
                                      this.getType()
                                    ][
                                      this.state.tabsDetail[
                                        this.state.tabsIndexDetail
                                      ].key
                                    ],
                                    this.state.tabsDetail[
                                      this.state.tabsIndexDetail
                                    ].format
                                  ),
                                  total:
                                    this.state.companiesData[key][
                                      this.getType()
                                    ][
                                      this.state.tabsDetail[
                                        this.state.tabsIndexDetail
                                      ].count
                                        ? this.state.tabsDetail[
                                            this.state.tabsIndexDetail
                                          ].count
                                        : "total"
                                    ],
                                  year:
                                    (this.state.companiesData[key][
                                      this.getType()
                                    ][
                                      this.state.tabsDetail[
                                        this.state.tabsIndexDetail
                                      ].key
                                    ] /
                                      this.state.companiesData[key][
                                        `${this.getType()}Year`
                                      ][
                                        this.state.tabsDetail[
                                          this.state.tabsIndexDetail
                                        ].key
                                      ]) *
                                      100 -
                                    100,
                                  reverse:
                                    this.state.tabsDetail[
                                      this.state.tabsIndexDetail
                                    ].reverse,
                                };
                              })}
                          />
                        </TabsCustomItem>
                      ))}
                    </TabsCustomBody>
                  </TabsCustom>
                </TabsCustomItem>
              </TabsCustomBody>
            </TabsCustom>
          </div>
        </FadeIn>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
