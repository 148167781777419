import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import "./Reparator.css";

export default class ToggleTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          isOpen: this.props.isOpen
        };
    }

    handleDropdownClick = () => {
        this.setState(state => ({ isOpen: !state.isOpen }));
    }

    render() {
        return (
            <div>
                {/* TITLE */}
                <div className="toggled-card-title" onClick={this.handleDropdownClick} >
                    <span>
                        {
                            this.props.title 
                            ? this.props.title
                            : this.state.isOpen 
                                ?  'Cacher'
                                : 'Afficher'
                        }
                    </span>
                    {this.state.isOpen ? (
                      <FontAwesomeIcon icon={faChevronDown} />
                    ) : (
                      <FontAwesomeIcon icon={faChevronUp} />
                    )}
                </div>

                {/* CONTENT */}
                <div className={`toggled-card-content ${this.state.isOpen ? "" : "hidden"}`}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}