import React, { Component } from "react";
import FadeIn from "react-fade-in";
import { Card, Table, Thead, Tr, Th, Tbody, Td } from "ui-kit-ck-consultant";

import ToggleTable from "../../../../performances/reparator/ToggleTable";

export default class CardSpeciality extends Component {
  getGeneralValue = (id) => {
    if (this.props.data && this.props.data.hasOwnProperty(id)) {
      return this.props.data[id];
    } else {
      return "";
    }
  };

  render() {
    const dataSpecialitiesMonitoring = [
      {
        title: "INCENDIE - Véhicules incendiés",
        special: true,
      },
      {
        title: "Nombre de dossiers INCENDIE",
        expert: this.getGeneralValue("count_INCENDIE"),
      },
      {
        title: "Délai moyen OM - EXAMEN",
        expert: this.getGeneralValue("averageDelayMissionExpertise_INCENDIE"),
      },
      {
        title: "Délai moyen EXAMEN - RE",
        expert: this.getGeneralValue("averageDelayExpertiseReport_INCENDIE"),
      },
      {
        title: "Délai moyen OM - RE",
        expert: this.getGeneralValue("averageDelayMissionReport_INCENDIE"),
      },
      {
        title: "dont nombre de dossiers en PERTE TOTALE",
        expert: this.getGeneralValue("count_NR_INCENDIE"),
      },
      {
        title: "VRADE HT moyenne (1)",
        expert: this.getGeneralValue("averageVRADE_NR_INCENDIE"),
      },
      {
        title: "dont nombre de dossiers REPARABLE",
        expert: this.getGeneralValue("count_R_INCENDIE"),
      },
      {
        title: "Coût moyen réparation HT (1)",
        expert: this.getGeneralValue("averageReport_R_INCENDIE"),
      },
      {
        title: "dont nombre de dossiers AUTRES (2)",
        expert: this.getGeneralValue("null"),
      },
      {
        title: "Nombre d'anomalies Note 17",
        expert: this.getGeneralValue("null"),
      },
      {
        title: "Montant moyen TTC des notes d'honoraires",
        expert: this.getGeneralValue("averageHonorary_INCENDIE"),
      },
      {
        title: "VVR - Véhicules volés retrouvés",
        special: true,
      },
      {
        title: "Nombre de dossiers VVR",
        expert: this.getGeneralValue("count_VVR"),
      },
      {
        title: "Délai moyen OM - EXAMEN",
        expert: this.getGeneralValue("averageDelayMissionExpertise_VVR"),
      },
      {
        title: "Délai moyen EXAMEN - RE",
        expert: this.getGeneralValue("averageDelayExpertiseReport_VVR"),
      },
      {
        title: "Délai moyen OM - RE",
        expert: this.getGeneralValue("averageDelayMissionReport_VVR"),
      },
      {
        title: "dont nombre de dossiers PERTE TOTALE",
        expert: this.getGeneralValue("count_NR_VVR"),
      },
      {
        title: "VRADE HT moyenne (1)",
        expert: this.getGeneralValue("averageVRADE_NR_VVR"),
      },
      {
        title: "dont nombre de dossiers REPARABLE",
        expert: this.getGeneralValue("count_R_VVR"),
      },
      {
        title: "Coût moyen réparation HT (1)",
        expert: this.getGeneralValue("averageReport_R_VVR"),
      },
      {
        title: "dont nombre de dossiers AUTRES (2)",
        expert: this.getGeneralValue("null"),
      },
      {
        title: "Nombre d'anomalies Note 15",
        expert: this.getGeneralValue("null"),
      },
      {
        title: "Montant moyen TTC des note d'honoraires",
        expert: this.getGeneralValue("averageHonorary_VVR"),
      },
      {
        title: "VVNR - Véhicules volés non retrouvés",
        special: true,
      },
      {
        title: "Nombre de dossiers VVNR",
        expert: this.getGeneralValue("count_VVNR"),
      },
      {
        title: "Délai moyen OM - EXAMEN",
        expert: this.getGeneralValue("averageDelayMissionExpertise_VVNR"),
      },
      {
        title: "Délai moyen EXAMEN - RE",
        expert: this.getGeneralValue("averageDelayExpertiseReport_VVNR"),
      },
      {
        title: "Délai moyen OM - RE",
        expert: this.getGeneralValue("averageDelayMissionReport_VVNR"),
      },
      {
        title: "VRADE HT moyenne HT (1)",
        expert: this.getGeneralValue("averageVRADE_NR_VVNR"),
      },
      {
        title: "Montant moyen TTC des note d'honoraires",
        expert: this.getGeneralValue("averageHonorary_VVNR"),
      },
    ];

    return (
      <Card title={"Suivi des Spécialités"} className={"mt-10 mt-30-xl "}>
        <ToggleTable color={"DarkPurple"}>
          <FadeIn>
            <div style={{ fontSize: "10px" }} className="mb-20 mt-30">
              <p>(1) = VTG non écrêté = Véhicules tout genre non écrêté</p>
              <p>
                (2) Rapports d'Expertises autres que PERTE TOTALE ou REPARABLE
              </p>
              <p>Délais calculés sur le dernier RE reçu de chaque dossier</p>
            </div>
            <Table>
              <Thead>
                <Tr>
                  <Th hide />
                  <Th radiusTopLeft>Expert</Th>
                </Tr>
              </Thead>
              <Tbody>
                {dataSpecialitiesMonitoring.map((element, idx) => (
                  <Tr key={idx}>
                    <Th
                      colSpan={element.special ? 2 : 1}
                      radiusTopLeft={idx === 0}
                    >
                      {element.title}
                    </Th>
                    {element.special ? "" : <Td>{element.expert}</Td>}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </FadeIn>
        </ToggleTable>
      </Card>
    );
  }
}
