import React, { Component } from "react";
import { Modal, Loader } from "ui-kit-ck-consultant";

import PlanningForm from "./PlanningForm";

import axiosConfig from "../../../utils/axiosConfig";

export default class PlanningModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organizationTypeOptions: [],
      organizationOptions: [],
      isLoad: false,
      user: "",
      dateStart: "",
      typeStart: "",
      dateEnd: "",
      typeEnd: "",
      address: "",
      zipCode: "",
      organization: "",
      typeOrganization: "",
      nameOrganization: "",
      nameFormation: "",
      city: "",
      type: "",
      comment: "",
      file: "",
      impact: "",
      nbMessageNow: "",
      nbMessageLeave: "",
      nbFilesNow: "",
      nbFilesLeave: "",
      impactIndicator: "",
      management: "",
      colleagueInitial: "",
      specificFile: "",
      colleagueId: "",
      currentPage: 0,
      error: false,
    };

    this.initialState = { ...this.state };
  }

  componentDidMount() {
    this.getOrganizations();
  }

  handleCloseClick = () => {
    this.initialState.organizationOptions = this.state.organizationOptions;
    this.setState(this.initialState, this.props.onClose());
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.state.organization !== prevState.organization) {
      this.getTypeOrganizations({ organizationId: this.state.organization });
    }
  }

  handleValidClick = (formData) => {
    this.setState({ isLoad: true }, () => {
      const {
        type,
        dateStart,
        dateEnd,
        comment,
        userId,
        typeStart,
        typeEnd,
        address,
        zipCode,
        city,
        organization,
        typeOrganization,
        nameOrganization,
        nameFormation,
        file,
      } = formData;

      const data =
        this.state.type !== "Formation"
          ? {
              type,
              dateStart,
              dateEnd,
              comment,
              userId:
                userId && typeof userId === "string"
                  ? userId.split(" - ")[0]
                  : userId,
              typeStart,
              typeEnd,
              file,
            }
          : {
              type,
              dateStart,
              dateEnd,
              userId:
                userId && typeof userId === "string"
                  ? userId.split(" - ")[0]
                  : userId,
              address,
              organization,
              ...(organization === "4"
                ? { nameOrganization, nameFormation }
                : { typeOrganization }),
              zipCode,
              city,
            };

      const areAllValuesNotEmpty = Object.keys(data).every(
        (key) => key === "comment" || key === "file" || !!data[key]
      );

      if (areAllValuesNotEmpty) {
        axiosConfig("/planning/add-planning", { data }).then((res) => {
          if (res.data.success) {
            this.setState({ isLoad: false }, () => {
              this.handleCloseClick();
            });
          }
        });
      } else {
        this.setState({ isLoad: false }, () => {
          alert("Une erreur est survenue !");
        });
      }
    });
  };

  getOrganizations() {
    this.setState({ isLoad: true }, () => {
      axiosConfig("/planning/get-organizations").then((res) => {
        if (res.data.success) {
          this.setState({
            isLoad: false,
            organizationOptions: res.data.organizations,
          });
        } else {
          this.setState({ isLoad: false, organizationOptions: [] });
        }
      });
    });
  }

  getTypeOrganizations(data) {
    this.setState({ isLoad: true }, () => {
      axiosConfig("/planning/get-type-organizations", {
        data,
      }).then((res) => {
        if (res.data.success) {
          this.setState({
            isLoad: false,
            organizationTypeOptions: res.data.organizations,
          });
        } else {
          this.setState({
            isLoad: false,
            organizationTypeOptions: [],
          });
        }
      });
    });
  }

  render() {
    return (
      <>
        <Modal
          show={this.props.show}
          onClose={this.handleCloseClick}
          title={this.props.title}
        >
          <PlanningForm
            inputErrors={this.state.inputErrors}
            user={this.state.user}
            userOptions={this.props.userOptions}
            type={this.state.type}
            address={this.state.address}
            zipCode={this.state.zipCode}
            city={this.state.city}
            organization={this.state.organization}
            typeOrganization={this.state.typeOrganization}
            nameOrganization={this.state.nameOrganization}
            nameFormation={this.state.nameFormation}
            organizationOptions={this.state.organizationOptions}
            organizationTypeOptions={this.state.organizationTypeOptions}
            dateStart={this.state.dateStart}
            typeStart={this.state.typeStart}
            dateEnd={this.state.dateEnd}
            typeEnd={this.state.typeEnd}
            comment={this.state.comment}
            error={this.state.error}
            file={this.state.file}
            getFile={(file) => {
              this.setState({
                file,
              });
            }}
            onError={(value) => {
              this.setState({
                error: value,
              });
            }}
            handleInputChange={(e) => this.handleInputChange(e)}
            handleValidClick={(data) => this.handleValidClick(data)}
          />
        </Modal>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
