import React from "react";

import { Row, Col } from "react-flexbox-grid";
import { FormSelect, FormCheckbox, Button } from "ui-kit-ck-consultant";

export default class FiltersIdea extends React.Component {
  render() {
    return (
      <Row>
        <Col xs={12} md={3}>
          <FormSelect
            title="Type"
            name="type"
            ignore={true}
            value={this.props.type ? this.props.type : 0}
            options={this.props.typeOptions}
            onChange={this.props.onChange}
          />
        </Col>
        <Col xs={12} md={3}>
          <FormSelect
            title="Catégorie"
            name="category"
            ignore={true}
            value={this.props.category ? this.props.category : 0}
            options={this.props.categoryOptions}
            onChange={this.props.onChange}
            disabled={this.props.categoryDisabled}
          />
        </Col>
        <Col xs={12} md={3} className="d-flex">
          <FormCheckbox
            text="Nouveau"
            className="mt-auto ml-10 mb-10"
            name="status0"
            checked={this.props.status0}
            onChange={this.props.handleCheckboxChange}
          />
          <FormCheckbox
            text="En cours"
            className="mt-auto ml-10 mb-10"
            name="status1"
            checked={this.props.status1}
            onChange={this.props.handleCheckboxChange}
          />
          <FormCheckbox
            text="Cloturé"
            className="mt-auto ml-10 mb-10"
            name="status2"
            checked={this.props.status2}
            onChange={this.props.handleCheckboxChange}
          />
        </Col>
        <Col xs={12} md={3} className="mt-auto">
          <Button
            text={"Ajouter une demande"}
            onClick={() => this.props.handleOpenModal()}
          />
        </Col>
      </Row>
    );
  }
}
