import React from "react";
import { Col, Row } from "react-flexbox-grid";
import { Card } from "ui-kit-ck-consultant";

import PieChartSatisfaction from "./others/PieChartSatisfaction";

export default class Allianz extends React.Component {
  render() {
    return (
      <Card className={this.props.className} title="Graphiques">
        <Row>
          <Col xs={12} lg={4} className="d-flex">
            <PieChartSatisfaction
              star
              title={
                "Dans quelle mesure êtes-vous satisfait de l'intervention de l'expert ?"
              }
              data={
                this.props.generalSatisfactionData
                  ? this.props.generalSatisfactionData[
                      "button_star_intervention_expert"
                    ]
                  : []
              }
            />
          </Col>
          <Col xs={12} lg={4} className="d-flex">
            <PieChartSatisfaction
              star
              title={"Disponibilité et réactivité de l'expert ?"}
              data={
                this.props.generalSatisfactionData
                  ? this.props.generalSatisfactionData[
                      "button_star_reactivite_expert"
                    ]
                  : []
              }
            />
          </Col>
          <Col xs={12} lg={4} className="d-flex">
            <PieChartSatisfaction
              star
              title={"Clarté des informations apportées ?"}
              data={
                this.props.generalSatisfactionData
                  ? this.props.generalSatisfactionData[
                      "button_star_information"
                    ]
                  : []
              }
            />
          </Col>
          <Col xs={12} lg={4} className="d-flex">
            <PieChartSatisfaction
              star
              title={"Simplicité des démarches"}
              data={
                this.props.generalSatisfactionData
                  ? this.props.generalSatisfactionData["button_star_simplicite"]
                  : []
              }
            />
          </Col>
          <Col xs={12} lg={4} className="d-flex">
            <PieChartSatisfaction
              star
              title={"Délai de traitement de votre dossier "}
              data={
                this.props.generalSatisfactionData
                  ? this.props.generalSatisfactionData["button_star_traitement"]
                  : []
              }
            />
          </Col>
          <Col xs={12} lg={4} className="d-flex">
            <PieChartSatisfaction
              star
              title={"Service proposés par le portail client "}
              data={
                this.props.generalSatisfactionData
                  ? this.props.generalSatisfactionData["button_star_portail"]
                  : []
              }
            />
          </Col>
        </Row>
      </Card>
    );
  }
}
