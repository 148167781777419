const handleSortClick = (sortRef, desc, column) => {
  sortRef.map((elem, idx) => {
    if (sortRef[idx] && sortRef[idx].current && idx !== column) {
      sortRef[idx].current.setState({ desc: false, sort: false });
    }
    return null;
  });

  let order = { name: sortRef[column].current.props.nameSort, desc };

  return order;
};

export default handleSortClick;
