import React, { Component } from "react";
import { Card } from "ui-kit-ck-consultant";
import GoogleMapReact from "google-map-react";

import MapCircle from "./MapCircle";

import AuthContext from "../../context/AuthContext";

export default class EGA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultLat: 48.8534,
      defaultLong: 2.3488,
      defaultZoom: 6,
    };
  }

  static contextType = AuthContext;

  handleApiLoaded = (map, maps) => {
    this.setState({ googleMaps: maps });
  };

  render() {
    return (
      <Card title="Carte" className="mb-10 mb-20-xl">
        <div style={{ height: "600px" }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              // key: "AIzaSyCu0lZie6pY5R3TzHadpLy3AGJ0c616TOk",
              key: "AIzaSyDxQiiLJaitFNPs4NCNbjB3DTaY_rWd_Fs",
            }}
            center={{
              lat: this.state.defaultLat,
              lng: this.state.defaultLong,
            }}
            zoom={this.state.defaultZoom ? this.state.defaultZoom : 6}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) =>
              this.handleApiLoaded(map, maps)
            }
          >
            {Object.keys(this.props.map).map((key) => (
              <MapCircle
                ref={key}
                lat={this.props.map[key].latitude}
                lng={this.props.map[key].longitude}
                coefficient={this.props.map[key].coefficient}
              />
            ))}
          </GoogleMapReact>
        </div>
      </Card>
    );
  }
}
