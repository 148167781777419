import React, { Component } from "react"
import {
  Card,
  Button
} from "ui-kit-ck-consultant"
import { Row, Col } from "react-flexbox-grid";

import AuthContext from "../../context/AuthContext"

export default class Declare extends Component {
  static contextType = AuthContext;

  render() {
    return <Card className={this.props.className}>
      <Row>
        <Col xs={12} md={6} className={"mb-10"}>
          <Button
            outline
            text="Déclarer une anomalie expert"
            onClick={() => window.open(this.context.urlForm + '/anomalie-expert.php', '_blank')}
          />
        </Col>
        <Col xs={12} md={6} className={"mb-10"}>
          <Button
            outline
            text="Déclarer une anomalie assistant(e)"
            onClick={() => alert("NON DISPONIBLE")}
          />
        </Col>
      </Row>
    </Card>
  }
}