import React from 'react'
import { Card, Modal } from 'ui-kit-ck-consultant'

const ModalAnomaly = (props) => {
        const style = {
            div: {
                display: 'flex', 
                flexDirection: 'row', 
                alignItems: 'center',
                margin: '10'
            },
            span: {
                paddingLeft: '5px'
            }
        }
    
    let displayAnomalyData    
    let displayAnomaly
    let details = []

    if (props.item ) {
        for (const [key, value] of Object.entries(props.item)) {
            details.push({name: key, value: value})
        }
        let type = details.find(o => o.name === "type")
        details = details.filter(it => (it.name !== "data" && it.name !== "type"  && it.name !== "id" ))
        details = details.map(it => {
            if (it.name === "date" || it.name === "agency") {
                it.name = it.name === "date" ? "Date: " : "Agence: "
            } else if (it.name === "company" || it.name === "folder") {
                it.name = it.name === "company" ? "Compagnie: " : "Référence dossier: "
            } else if (it.name === "user" || it.name === "typeExpertise") {
                it.name = it.name === "user" ? "Expert: " : "Type d'expertise: "
            } else if (it.name === "origine" || it.name === "anomaly") {
                it.name = it.name === "origine" ? "Origine: " : "Anomalie relevée: "
            } else if (it.name === "incident" ) {
                it.name = "Incident materiel expert: "
            }
            if (it.name === 'Expert: ') {
                it.value = `${it.value} - ${type.value}`
            }
            return it
        })
        

        displayAnomaly = details.map(el => {
            return (
                <div key={el.name}>
                    <h2>{el.name}</h2>
                            <Card className="bg-gray m-10" style={style.div} >
                                <span className=" ml-10 nowrap" >{el.value}</span>
                            </Card>
                </div>
            )
        })


        let tabData = []
        let data = JSON.parse(props.item.data)

        for (const [key, value] of Object.entries(data)) {
            tabData.push({name: key, value: value})
        }   
        tabData = tabData.filter(it => (it.name === "solution" || it.name === "commentaire" || it.name === "impact-financier-compagnie"))
        tabData = tabData.map(it => {
            if (it.name === "solution" || it.name === "commentaire") {
                it.name = it.name === "solution" ? "Solution: " : "Commentaire: "
            } else {
                it.name = "Impact financier compagnie: " 
            }
            return it
        })   
        displayAnomalyData = tabData.map(el => {
            return (
                <div key={el.name}>
                    <h2>{el.name}</h2>
                            <Card className="bg-gray m-10">
                                <span className=" ml-10" >{el.value}</span>
                            </Card>
                </div>
            )
        })   
    }


    return (
        <Modal 
            title="Détails Anomalie"
            show={props.show}
            onClose={props.hide}
        >
                {props.item ? 
            <> 
                {displayAnomaly}
                {displayAnomalyData}
            </>  : null}
        </Modal>
    )
}

export default ModalAnomaly
