import React, { Component } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Card,
  Pagination,
  ButtonIcon,
} from "ui-kit-ck-consultant";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
  faSearchPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Modal from "./Modal";

import handleSortClick from "../../utils/handleSortClick";
export default class list extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: null,
      modalIsOpen: false,
      dataItem: null,
    };

    this.sortRef = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
    ];
  }

  handleSortClick = (desc, column) => {
    this.setState(
      { order: handleSortClick(this.sortRef, desc, column) },
      () => {
        this.props.onSortClick(this.state.order);
      }
    );
  };

  onLoadData() {
    this.props.onLoadData(this.state.dataId);
  }

  getType = (type) => {
    switch (type) {
      case "0":
        return "Aucun";
      case "1":
        return "Expert Masqué";
      case "2":
        return "Expert Terrain";
      case "3":
        return "Expert EAD";
      case "4":
        return "Assistant Masqué";
      case "5":
        return "Assistant";
      default:
        return;
    }
  };

  render() {
    const tableHead = [
      { name: "date", title: "Date", nameSort: "date", sort: true },
      { name: "user", title: "Utilisateur", nameSort: "user", sort: true },
      {
        name: "folder",
        title: "N° dossier",
        nameSort: "reference",
        sort: true,
      },
      { name: "agency", title: "Agence", nameSort: "agency", sort: true },
      { name: "company", title: "Compagnie", nameSort: "company", sort: true },
      {
        name: "anomaly",
        title: "Anomalie relevée",
        nameSort: "anomaly",
        sort: true,
      },
      {
        name: "incident",
        title: "Incident matériel",
        nameSort: "incident",
        sort: true,
      },
    ];

    return (
      <Card
        title="Liste"
        className={this.props.className}
        classNameChildren="overflow-x-auto"
      >
        <Modal
          show={this.state.modalIsOpen}
          hide={() =>
            this.setState((prevState) => ({
              modalIsOpen: !prevState.modalIsOpen,
              dataItem: null,
            }))
          }
          item={this.state.dataItem}
        />
        <Table
          empty={
            this.props.data.length > 0 ? "" : "Aucune donnée n'est disponible"
          }
        >
          <Thead>
            <Tr>
              {tableHead.map((elem, idx) => (
                <Th
                  key={idx}
                  ref={this.sortRef[idx]}
                  sort={elem.sort ? true : false}
                  onSort={
                    elem.sort ? (desc) => this.handleSortClick(desc, idx) : null
                  }
                  nameSort={elem.nameSort}
                >
                  {elem.name}
                </Th>
              ))}
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {this.props.data.map((element, idx) => (
              <Tr key={idx}>
                {tableHead.map((elemHead, idxHead) => (
                  <Td key={idxHead}>
                    {elemHead.format
                      ? elemHead.format(element[elemHead.name])
                      : element[elemHead.name]}
                  </Td>
                ))}
                <Td>
                  <ButtonIcon
                    className={"black"}
                    onClick={(event) => {
                      this.setState((prevState) => ({
                        modalIsOpen: !prevState.modalIsOpen,
                        dataItem: element,
                      }));
                    }}
                    small
                  >
                    <FontAwesomeIcon icon={faSearchPlus} />
                  </ButtonIcon>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        {this.props.isPrint ? null : (
          <Pagination
            activePage={this.props.activePage}
            itemsCountPerPage={this.props.tabLength}
            totalItemsCount={this.props.total}
            pageRangeDisplayed={4}
            onChange={this.props.onPageChange.bind(this)}
            firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
            lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
            prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
            nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
          />
        )}
      </Card>
    );
  }
}
