import React, { Component } from "react";
import moment from "moment";
import FadeIn from "react-fade-in/lib/FadeIn";
import {
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Status,
} from "ui-kit-ck-consultant";

export class TableBike extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getDataMonth = (id) => {
    let tmpDataMonth = [];

    for (let index = 0; index < 12; index++) {
      if (parseInt(moment(this.props.dateEnd).format("MM")) > index) {
        tmpDataMonth.push(<Td idx={index}>{this.props.data[index][id]}</Td>);
      } else {
        tmpDataMonth.push(<Td idx={index}></Td>);
      }
    }

    return tmpDataMonth;
  };

  getColorStatus(value, reverse) {
    if ((value > 0 && !reverse) || (reverse && value < -0)) {
      return <Status className="bg-green w-100" text={`${value} %`} />;
    } else if ((value < 0 && !reverse) || (reverse && value > -0)) {
      return <Status className="bg-red w-100" text={`${value} %`} />;
    } else {
      return <Status className="bg-blue w-100" text={`${value} %`} />;
    }
  }

  render() {
    return (
      <FadeIn>
        <Card title={this.props.name} classNameChildren="overflow-x-auto">
          <Table>
            <Thead>
              <Tr>
                <Th></Th>
                <Th className="nowrap">N-1</Th>
                <Th>N</Th>
                <Th className="nowrap">N / N-1</Th>
                <Th>Janvier</Th>
                <Th>Février</Th>
                <Th>Mars</Th>
                <Th>Avril</Th>
                <Th>Mai</Th>
                <Th>Juin</Th>
                <Th>Juillet</Th>
                <Th>Août</Th>
                <Th>Septembre</Th>
                <Th>Octobre</Th>
                <Th>Novembre</Th>
                <Th>Décembre</Th>
              </Tr>
            </Thead>
            <Tbody>
              {this.props.labels.map((element, idx) => (
                <Tr key={idx}>
                  <Th className={this.props.isPrint ? "" : "nowrap"}>
                    {element.name}
                  </Th>
                  <Td>{this.props.data["N_1"][element.id]}</Td>
                  <Td>{this.props.data["N"][element.id]}</Td>
                  <Td className="nowrap">
                    {this.getColorStatus(
                      (this.props.data["N_1"][element.id] === 0 ||
                        !this.props.data["N_1"][element.id]) &&
                        this.props.data["N"][element.id] > 0
                        ? 100
                        : this.props.data["N"][element.id] &&
                          this.props.data["N_1"][element.id]
                        ? parseInt(
                            (this.props.data["N"][element.id] /
                              this.props.data["N_1"][element.id]) *
                              100 -
                              100
                          )
                        : 0,
                      element.reverse
                    )}
                  </Td>
                  {this.getDataMonth(element.id)}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Card>
      </FadeIn>
    );
  }
}

export default TableBike;
