import React, { Component } from 'react'
import { Card } from 'ui-kit-ck-consultant'
import ActionPlanDisplayCloture from '../general/ActionPlanDisplayCloture'
import SignaturesDisplayCloture from '../general/SignaturesDisplayCloture'
import MonthlyBilanDisplay from './MonthlyBilanDisplay'


export class InterviewDisplayCloture extends Component {
  render() {
    return (
      <div>
        {this.props.monthlyBilan && Array.isArray(this.props.monthlyBilan)
        ? 
          (<Card title="Bilan Mensuel" className={'mt-30'}>
            <MonthlyBilanDisplay 
              monthlyBilan={this.props.monthlyBilan}
            />
          </Card>)
        : null }
        {this.props.actionPlan && this.props.actionPlan.length > 0
        ? 
          (<ActionPlanDisplayCloture 
          actionData={this.props.actionPlan}
        />)
        : null }
        {this.props.signatures
        ? 
          (<SignaturesDisplayCloture 
          signatureData={this.props.signatures}
        />)
        : null }
      </div>
    )
  }
}

export default InterviewDisplayCloture
