import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import { Card, DoughnutChart } from "ui-kit-ck-consultant";

export default class Train extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Card className="mb-10 mb-20-xl" title="Apprentissage et prédiction">
        <Row>
          <Col xs={12} md={6} lg={4} className="d-flex">
            <div className="m-auto text-center">
              <DoughnutChart
                className="m-auto"
                number={this.props.precision}
                text="Précision (%)"
              />
            </div>
          </Col>
          <Col xs={12} md={6} lg={4} className="d-flex">
            <div className="mt-auto mb-auto">
              <h3 className="mt-0 bm-5">Critères</h3>
              {this.props.data.map((element, idx) => (
                <p key={idx} className="m-0" style={{ fontSize: "12px" }}>
                  {element.name} : <b>{element.value}</b>
                </p>
              ))}
            </div>
          </Col>
          <Col xs={12} md={6} lg={4} className="d-flex">
            <div className="mt-auto mb-auto">
              <p className="m-0" style={{ fontSize: "12px" }}>
                Date de prédiction : <b>{this.props.date}</b>
              </p>
              <p className="m-0 mb-10" style={{ fontSize: "12px" }}>
                Heure de prédiction : <b>{this.props.time}</b>
              </p>
            </div>
          </Col>
        </Row>
      </Card>
    );
  }
}
