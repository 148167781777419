import React, { Component } from "react";
import { ButtonIcon, Card, FormInput, Loader } from "ui-kit-ck-consultant";
import GoogleMapReact from "google-map-react";
import {
  faUser,
  faBuilding,
  faMapMarkerAlt,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import axiosConfig from "../../../utils/axiosConfig";
import MapMarker from "./MapMarker";
import { Col, Row } from "react-flexbox-grid";
import Legend from "./Legend";
import MapMarkerCustom from "./MapMarkerCustom";
import ModalMap from "./ModalMap";

import AuthContext from "../../../context/AuthContext";

export default class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      isModal: false,
      modalName: "",
      modalReference: "",
      modalAgency: "",
      modalExpert: "",
      modalCoord: null,
      modalLieu: null,
      modalNatureExpertise: "",
      modalMulti: false,
      modalFolders: null,
      tabsId: 0,
      defaultLat: 48.8534,
      defaultLong: 2.3488,
      defaultZoom: 6,
      folderSearch: null,
      folderSearchData: null,
      error: null,
      immatSearch: null,
      agencies: null,
      garages: null,
      customers: null,
    };
  }

  static contextType = AuthContext;

  handleOpenModal = (id) => {
    axiosConfig("planning/tour/get-data-folder", {
      data: {
        id: id,
        region: this.props.region,
        agency: Number(this.props.agency),
      },
    }).then((res) => {
      if (res.data.success === true) {
        this.setState({
          isModal: true,
          modalName: res.data.data.name,
          modalReference: res.data.data.reference,
          modalAgency: res.data.data.agency,
          modalExpert: res.data.data.expert,
          modalCoord: [1, 2].includes(parseInt(res.data.data.locationCode))
            ? {
                agency: res.data.data.agencyCoord,
                garage: res.data.data.garageCoord,
              }
            : null,
          modalLieu:
            parseInt(res.data.data.locationCode) === 1
              ? "Entre l'agence et le client"
              : parseInt(res.data.data.locationCode) === 2
              ? " Entre l'agence et le garage"
              : "L'expertise se déroule à l'agence",
          modalNatureExpertise: res.data.data.natureExpertise,
        });
      }
    });
  };

  handleOpenMultipleModal = (folders) => {
    this.setState({ modalMulti: true, modalFolders: folders }, () =>
      this.handleOpenModal(this.state.modalFolders[0].id)
    );
  };

  handleChangeSearch = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleClickSearch = () => {
    this.setState({ folderSearchData: null, isLoad: true }, () => {
      axiosConfig("planning/tour/get-data-folder-search", {
        data: {
          folderReference: this.state.folderSearch,
          immatReference: this.state.immatSearch,
          region: this.props.region,
          agency: Number(this.props.agency),
        },
      })
        .then((res) => {
          if (res.data.success === true) {
            this.setState({
              isLoad: false,
              folderSearchData: res.data.data,
              defaultLat:
                parseInt(res.data.data.locationCode) === 1
                  ? res.data.data.clientLatitude
                  : parseInt(res.data.data.locationCode) === 2
                  ? res.data.data.garageLatitude
                  : res.data.data.agencyLatitude,
              defaultLong:
                parseInt(res.data.data.locationCode) === 1
                  ? res.data.data.clientLongitude
                  : parseInt(res.data.data.locationCode) === 2
                  ? res.data.data.garageLongitude
                  : res.data.data.agencyLongitude,
              defaultZoom: 9,
              isModal: true,
              modalName: res.data.data.name,
              modalReference: res.data.data.reference,
              modalAgency: res.data.data.agencyName,
              modalExpert: res.data.data.expert,
              modalCoord:
                parseInt(res.data.data.locationCode) === 1
                  ? {
                      agency: res.data.data.agencyCoord,
                      garage: res.data.data.clientCoord,
                    }
                  : parseInt(res.data.data.locationCode) === 2
                  ? {
                      agency: res.data.data.agencyCoord,
                      garage: res.data.data.garageCoord,
                    }
                  : null,
              modalLieu:
                parseInt(res.data.data.locationCode) === 1
                  ? "Entre l'agence et le client"
                  : parseInt(res.data.data.locationCode) === 2
                  ? " Entre l'agence et le garage"
                  : "L'expertise se déroule à l'agence",
              modalNatureExpertise: res.data.data.natureExpertise,
            });
          } else {
            this.setState({
              isLoad: false,
              error: "Pas de résultats pour cette référence de dossier",
              isModal: true,
            });
          }
        })
        .catch(() => {
          this.setState({
            isLoad: false,
            error: "Pas de résultats pour cette référence de dossier",
            isModal: true,
          });
        });
    });
  };

  componentDidMount() {
    if (this.context.agencyLatitude && this.context.agencyLongitude) {
      this.setState({
        defaultLat: this.context.agencyLatitude,
        defaultLong: this.context.agencyLongitude,
      });
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.data !== this.props.data && this.props.data) {
      let garages = this.props.data.filter(
        (element) => parseInt(element.locationCode) === 2
      );
      let agencies = this.props.data.filter(
        (element) => parseInt(element.locationCode) === 3
      );
      let customers = this.props.data.filter(
        (element) => parseInt(element.locationCode) === 1
      );

      let garagesRes = garages.reduce(function (r, a) {
        r[a.garageLatitude] = r[a.garageLatitude] || [];
        r[a.garageLatitude].push(a);
        return r;
      }, Object.create(null));

      let agenciesRes = agencies.reduce(function (r, a) {
        r[a.agencyLatitude] = r[a.agencyLatitude] || [];
        r[a.agencyLatitude].push(a);
        return r;
      }, Object.create(null));

      agencies = [];
      for (const [key, value] of Object.entries(agenciesRes)) {
        if (key && value.length > 1) {
          agencies.push(value);
        } else if (value.length === 1) {
          agencies.push(value[0]);
        }
      }

      garages = [];
      for (const [key, value] of Object.entries(garagesRes)) {
        if (key && value.length > 1) {
          garages.push(value);
        } else if (value.length === 1) {
          garages.push(value[0]);
        }
      }

      this.setState({
        agencies,
        garages,
        customers,
      });
    }
  };

  handleApiLoaded = (map, maps) => {
    this.setState({ googleMaps: maps });
  };

  render() {
    return (
      <Card
        title={`Carte des tournées (${
          this.props.total ? this.props.total : 0
        } / ${this.props.totalDailyAvailability})`}
        className={this.props.className}
        isLoader={this.props.isLoader}
      >
        <div className={"m-20 d-flex"}>
          <FormInput
            className={"m-10"}
            name={"folderSearch"}
            placeholder={"Nº de dossier"}
            onChange={this.handleChangeSearch}
          />
          <FormInput
            className={"m-10"}
            name={"immatSearch"}
            placeholder={"Nº d'immatriculation"}
            onChange={this.handleChangeSearch}
          />
          <ButtonIcon onClick={this.handleClickSearch} className={"m-10"}>
            <FontAwesomeIcon color={"#6067AE"} icon={faSearch} />
          </ButtonIcon>
        </div>
        <Row>
          <Col xs={12} md={8} lg={10}>
            <div style={{ height: "600px" }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyDxQiiLJaitFNPs4NCNbjB3DTaY_rWd_Fs",
                }}
                center={{
                  lat: this.state.defaultLat,
                  lng: this.state.defaultLong,
                }}
                zoom={this.state.defaultZoom ? this.state.defaultZoom : 6}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) =>
                  this.handleApiLoaded(map, maps)
                }
              >
                {this.state.folderSearchData ? (
                  <MapMarker
                    lat={this.state.defaultLat}
                    lng={this.state.defaultLong}
                    color={this.state.folderSearchData.agencyColor}
                    type={parseInt(this.state.folderSearchData.locationCode)}
                    icon={faSearch}
                    id={this.state.folderSearchData.id}
                    onOpenModal={this.handleOpenModal}
                  />
                ) : null}
                {this.state.customers &&
                  this.state.customers.map((element, idx) => (
                    <MapMarker
                      key={idx}
                      lat={element.clientLatitude}
                      lng={element.clientLongitude}
                      color={element.agencyColor}
                      expertColor={element.expertColor}
                      type={parseInt(element.locationCode)}
                      icon={faUser}
                      id={element.id}
                      onOpenModal={this.handleOpenModal}
                    />
                  ))}
                {this.state.garages &&
                  this.state.garages.map((element, idx) => {
                    if (!Array.isArray(element)) {
                      return (
                        <MapMarker
                          key={idx}
                          lat={element.garageLatitude}
                          lng={element.garageLongitude}
                          color={element.agencyColor}
                          expertColor={element.expertColor}
                          type={parseInt(element.locationCode)}
                          icon={faMapMarkerAlt}
                          id={element.id}
                          onOpenModal={this.handleOpenModal}
                        />
                      );
                    } else {
                      if (element.length > 1) {
                        return (
                          <MapMarkerCustom
                            key={idx}
                            data={element}
                            type={2}
                            color={element[0].agencyColor}
                            lat={element[0].garageLatitude}
                            lng={element[0].garageLongitude}
                            onOpenModal={this.handleOpenMultipleModal}
                          />
                        );
                      }
                      return null;
                    }
                  })}
                {this.state.agencies &&
                  this.state.agencies.map((element, idx) => {
                    if (!Array.isArray(element)) {
                      return (
                        <MapMarker
                          key={idx}
                          lat={element.agencyLatitude}
                          lng={element.agencyLongitude}
                          color={element.agencyColor}
                          expertColor={element.expertColor}
                          type={parseInt(element.locationCode)}
                          icon={faBuilding}
                          id={element.id}
                          onOpenModal={this.handleOpenModal}
                        />
                      );
                    } else {
                      if (element.length > 1) {
                        return (
                          <MapMarkerCustom
                            key={idx}
                            data={element}
                            type={3}
                            color={element[0].agencyColor}
                            lat={element[0].agencyLatitude}
                            lng={element[0].agencyLongitude}
                            onOpenModal={this.handleOpenMultipleModal}
                          />
                        );
                      }
                      return null;
                    }
                  })}
              </GoogleMapReact>
            </div>
          </Col>
          <Col xs={12} md={4} lg={2}>
            <Legend data={this.props.data} />
          </Col>
        </Row>
        <ModalMap
          show={this.state.isModal}
          onCloseModal={() =>
            this.setState({
              isModal: false,
              error: null,
              modalMulti: false,
              modalFolders: null,
            })
          }
          title={this.state.error ? "Erreur" : "Détails expertise"}
          error={this.state.error}
          modalMulti={this.state.modalMulti}
          modalFolders={this.state.modalFolders}
          modalName={this.state.modalName}
          modalReference={this.state.modalReference}
          modalAgency={this.state.modalAgency}
          modalExpert={this.state.modalExpert}
          modalNatureExpertise={this.state.modalNatureExpertise}
          modalCoord={this.state.modalCoord}
          modalLieu={this.state.modalLieu}
          googleApi={this.state.googleMaps}
          handleOpenModal={this.handleOpenModal}
        />
        <Loader show={this.state.isLoad} />
      </Card>
    );
  }
}
