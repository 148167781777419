import React, { Component } from "react";
import {
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Status,
  Pagination,
} from "ui-kit-ck-consultant";
import { Col, Row } from "react-flexbox-grid";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getCurrencyValue } from "../../utils/general";

import AuthContext from "../../context/AuthContext";

export default class Data extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePageRegion: 1,
      activePageAgency: 1,
      activePageExpert: 1,
      pageLength: 20,
    };
  }

  static contextType = AuthContext;

  getStatusColor = (value) => {
    if (value >= 100) {
      return "bg-green";
    } else if (value === 0) {
      return "bg-blue";
    } else if (value >= 90) {
      return "bg-orange";
    } else {
      return "bg-red";
    }
  };

  render() {
    return (
      <Card
        className="mb-10 mb-20-xl"
        title="Classement général"
        classNameChildren="overflow-x-auto"
      >
        <Row>
          <Col xs={12} md={12} lg={12} className="mb-20">
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>Cabinet</Th>
                  <Th>Nb.</Th>
                  <Th>Réel</Th>
                  <Th>Prédit</Th>
                  <Th>Perf.</Th>
                </Tr>
              </Thead>
              <Tbody>
                {this.props.dataRegion
                  .slice(
                    (this.state.activePageRegion - 1) * this.state.pageLength,
                    this.state.activePageRegion * this.state.pageLength
                  )
                  .map((item, idx) => (
                    <Tr key={idx}>
                      <Td>
                        {(this.state.activePageRegion - 1) *
                          this.state.pageLength +
                          idx +
                          1}
                      </Td>
                      <Td>{item.name}</Td>
                      <Td>{item.count}</Td>
                      <Td>
                        {item.averageCost
                          ? getCurrencyValue(item.averageCost)
                          : "N.C."}
                      </Td>
                      <Td>
                        {item.averagePredict
                          ? getCurrencyValue(item.averagePredict)
                          : "N.C."}
                      </Td>
                      <Td>
                        <Status
                          className={
                            this.getStatusColor(item.averageDifference) +
                            " w-100"
                          }
                          text={parseInt(item.averageDifference) + "%"}
                        />
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
            <Pagination
              activePage={this.state.activePageRegion}
              itemsCountPerPage={this.state.pageLength}
              totalItemsCount={this.props.dataRegion.length}
              pageRangeDisplayed={4}
              onChange={(activePageRegion) => {
                this.setState({ activePageRegion: activePageRegion });
              }}
              firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
              lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
              prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
              nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
            />
          </Col>
          <Col xs={12} md={12} lg={6} className="mb-20">
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>Cabinet</Th>
                  <Th>Nb.</Th>
                  <Th>Réel</Th>
                  <Th>Prédit</Th>
                  <Th>Perf.</Th>
                </Tr>
              </Thead>
              <Tbody>
                {this.props.dataAgency
                  .slice(
                    (this.state.activePageAgency - 1) * this.state.pageLength,
                    this.state.activePageAgency * this.state.pageLength
                  )
                  .map((item, idx) => (
                    <Tr key={idx}>
                      <Td>
                        {(this.state.activePageAgency - 1) *
                          this.state.pageLength +
                          idx +
                          1}
                      </Td>
                      <Td>{item.name}</Td>
                      <Td>{item.count}</Td>
                      <Td>
                        {item.averageCost
                          ? getCurrencyValue(item.averageCost)
                          : "N.C."}
                      </Td>
                      <Td>
                        {item.averagePredict
                          ? getCurrencyValue(item.averagePredict)
                          : "N.C."}
                      </Td>
                      <Td>
                        <Status
                          className={
                            this.getStatusColor(item.averageDifference) +
                            " w-100"
                          }
                          text={parseInt(item.averageDifference) + "%"}
                        />
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
            <Pagination
              activePage={this.state.activePageAgency}
              itemsCountPerPage={this.state.pageLength}
              totalItemsCount={this.props.dataAgency.length}
              pageRangeDisplayed={4}
              onChange={(activePageAgency) => {
                this.setState({ activePageAgency: activePageAgency });
              }}
              firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
              lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
              prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
              nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
            />
          </Col>
          <Col xs={12} md={6} className="mb-20">
            <Table>
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>Expert</Th>
                  <Th>Nb.</Th>
                  <Th>Réel</Th>
                  <Th>Prédit</Th>
                  <Th>Perf.</Th>
                </Tr>
              </Thead>
              <Tbody>
                {this.props.dataExpert
                  .slice(
                    (this.state.activePageExpert - 1) * this.state.pageLength,
                    this.state.activePageExpert * this.state.pageLength
                  )
                  .map((item, idx) => (
                    <Tr key={idx}>
                      <Td>
                        {(this.state.activePageExpert - 1) *
                          this.state.pageLength +
                          idx +
                          1}
                      </Td>
                      <Td>{item.name}</Td>
                      <Td>{item.count}</Td>
                      <Td>
                        {item.averageCost
                          ? getCurrencyValue(item.averageCost)
                          : "N.C."}
                      </Td>
                      <Td>
                        {item.averagePredict
                          ? getCurrencyValue(item.averagePredict)
                          : "N.C."}
                      </Td>
                      <Td>
                        <Status
                          className={
                            this.getStatusColor(item.averageDifference) +
                            " w-100"
                          }
                          text={parseInt(item.averageDifference) + "%"}
                        />
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            </Table>
            <Pagination
              activePage={this.state.activePageExpert}
              itemsCountPerPage={this.state.pageLength}
              totalItemsCount={this.props.dataExpert.length}
              pageRangeDisplayed={4}
              onChange={(activePageExpert) => {
                this.setState({ activePageExpert: activePageExpert });
              }}
              firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
              lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
              prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
              nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
            />
          </Col>
        </Row>
      </Card>
    );
  }
}
