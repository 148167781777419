import React, { Component } from "react";
import { Nav, Loader, Card } from "ui-kit-ck-consultant";
import FadeIn from "react-fade-in";
import moment from "moment";

import "../../../style/performance.css";

import ShowOptionsNGRA from "../../../components/general/ShowOptionsNGRA-v2";
import Summary from "../../../components/general/Summary";
import PerformanceDisplay from "../../../components/performances/PerformanceDisplay";
import AuditDisplay from "../../../components/performances/AuditDisplay";
import EvolutionDisplay from "../../../components/performances/EvolutionDisplay";
import StatisticalMonitoringDisplay from "../../../components/performances/StatisticalMonitoringDisplay";
import PrintClass from "../../../components/general/Print";
import Agencies from "../../../components/general/Agencies";

import userLogo from "../../../images/user.png";

import axiosConfig from "../../../utils/axiosConfig";
import {
  getNameTypePerformance,
  getUserTypePerformance,
} from "../../../utils/general";
import { alertError } from "../../../utils/errorMessage";
import { formatTable } from "../../../utils/performance";

import AuthContext from "../../../context/AuthContext";

export default class Performance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // LOADER
      isLoad: false,
      // DATA
      name: null,
      auditData: null,
      performance: null,
      table: null,
      chart: null,
      isPrecisia: false,
      //typeSearch depends on wether group / region / agency / user is selected.
      typeSearch: null,
      // ShowOption state
      network: 0,
      group: 0,
      region: 0,
      agency: 0,
      user: 0,
      company: 0,
      // agencies to ban form query
      isFake: false,
      users: "",
      agenciesNotIn: [],
    };
    this.refPrintSummary = React.createRef();
    this.refPrintAudit = React.createRef();
    this.refPrintSuivi = React.createRef();
    this.refPrintSuiviChart = React.createRef();
  }

  static contextType = AuthContext;

  handleDataChange = (data) => {
    this.setState(
      {
        network: data.network,
        group: data.group,
        region: data.region,
        agency: data.agency,
        company: data.company,
        user: data.user,
        dateStart: data.dateStart,
        dateEnd: data.dateEnd,
      },
      () => {
        /// GET NEW DATA AFTER CHANGES
        this.getData();
        this.getDataAudit();
        this.getAgencyOrUserName();
      }
    );
  };

  getAgencyOrUserName = () => {
    const {
      network,
      group,
      region,
      agency,
      user,
      company,
      dateStart,
      dateEnd,
    } = this.state;
    axiosConfig({
      url: `/performances/1/get-name`,
      data: {
        network,
        group,
        region,
        agency,
        user,
        company,
        dateStart,
        dateEnd,
      },
    }).then((res) => {
      if (res.data.success === true) {
        this.setState({
          name: res.data.data.name,
          users: res.data.data.users,
          isFake: res.data.data.type === 2,
        });
      }
    });
  };

  getData = () => {
    const {
      network,
      group,
      region,
      agency,
      user,
      company,
      dateStart,
      dateEnd,
      agenciesNotIn,
    } = this.state;
    this.setState({ isLoad: true });
    axiosConfig({
      url: `/performances/${this.props.type}/data-agency`,
      data: {
        network,
        group,
        region,
        agency,
        user,
        company,
        dateStart,
        dateEnd,
        agenciesNotIn,
      },
    })
      .then((res) => {
        if (res.data.result === true) {
          let performance = {
            ...res.data.array_performance,
            displayHonorary: res.data.display_honorary,
          };
          let userPicture = res.data.user_picture;
          this.setState({
            performance,
            isPrecisia: res.data.isPrecisia,
            chart: res.data.array_chart,
            table: res.data.array_table,
            userPicture,
            isLoad: false,
            typeSearch: res.data.type,
          });
        } else {
          this.setState({ isLoad: false });
        }
      })
      .catch((err) => {
        this.setState({ isLoad: false });
        alertError(err);
      });
  };

  getDataAudit = () => {
    const {
      network,
      group,
      region,
      agency,
      user,
      company,
      dateStart,
      dateEnd,
      agenciesNotIn,
    } = this.state;
    this.setState({ isLoad: true });
    axiosConfig({
      url: `/performances/${this.props.type}/data-agency-audit`,
      data: {
        network,
        group,
        region,
        agency,
        user,
        company,
        dateStart,
        dateEnd,
        agenciesNotIn,
      },
    }).then((res) => {
      if (res.data.result === true) {
        let auditData = res.data;
        this.setState({
          auditData,
        });
      }
    });
  };

  setLabelAudit(value) {
    if (value >= 90) {
      return "EXCELLENCE";
    } else if (value >= 80) {
      return "PREMIUM";
    } else {
      return "VIGILANCE";
    }
  }

  render() {
    return (
      <div>
        <FadeIn>
          <Nav
            title={`Performance ${getNameTypePerformance(this.props.type)}`}
            className="mb-10 mb-20-xl"
          />
          <ShowOptionsNGRA
            isChange={true}
            className="mb-10 mb-20-xl"
            onDataChange={this.handleDataChange}
            isDateStart={true}
            isDateEnd={true}
            isCompany={true}
            isUser={true}
            visibilityRole={this.props.visibilityRole}
            visibilityType={this.props.visibilityType}
            preloadRole={this.props.preloadRole}
            preloadType={this.props.preloadType}
            limitUserType={this.props.limitUserType}
            limitUserRole={this.props.limitUserRole}
            allUserRole={this.props.allUserRole}
            userType={getUserTypePerformance(this.props.type)}
            isAll={this.props.type === 1 ? false : true}
            isFake={this.props.type === 1 ? false : true}
            isPerformance={true}
            isPerfEAD={this.props.type === 2 ? true : false}
            isPerfAssistant={this.props.type === 3 ? true : false}
          />
          {this.state.isFake ? (
            <Card title="Sélection des agences" className="mb-10 mb-20-xl">
              <Agencies
                type={this.props.type}
                year={parseInt(moment(this.state.dateStart).format("YYYY"))}
                users={this.state.users ? this.state.users : ""}
                handleAgenciesNotIn={(agenciesNotIn) =>
                  this.setState({ agenciesNotIn }, () => {
                    /// GET NEW DATA AFTER CHANGES
                    this.getData();
                    this.getDataAudit();
                    this.getAgencyOrUserName();
                  })
                }
              />
            </Card>
          ) : (
            <></>
          )}
          {this.state.chart &&
          this.state.performance &&
          this.state.auditData ? (
            <>
              <div ref={this.refPrintSummary}>
                <Summary
                  className={"mt-10 mt-30-xl "}
                  imgProfil={
                    this.state.userPicture ? this.state.userPicture : userLogo
                  }
                  nameProfil={this.state.name}
                  isLoader={this.state.isPrint ? null : this.state.isLoad}
                  performanceRecap={
                    this.state.performance
                      ? this.state.performance.recapitulatif
                      : null
                  }
                  label={
                    this.state.performance &&
                    this.state.performance.performance_qualite.resultat_audit[2]
                  }
                  labelRSE={this.state.performance.performance_RSE}
                  type={this.props.type}
                />
                <PerformanceDisplay
                  group={this.context.group}
                  perf={this.state.performance ? this.state.performance : null}
                  isLoader={this.state.isPrint ? null : this.state.isLoad}
                  type={this.props.type}
                  typeSearch={this.state.typeSearch}
                  isPrecisia={this.state.isPrecisia}
                />
              </div>
              <div ref={this.refPrintAudit}>
                {this.props.type !== 3 ? (
                  <AuditDisplay
                    auditData={
                      this.state.auditData && this.state.auditData.data_audit
                    }
                    audit={this.state.auditData && this.state.auditData.data}
                    auditApData={
                      this.state.auditData && this.state.auditData.data_audit_AP
                    }
                    isPrint={this.state.isPrint}
                  />
                ) : null}
              </div>
              <div ref={this.refPrintSuivi}>
                <StatisticalMonitoringDisplay
                  dataR={formatTable(this.state.table, "r")}
                  dataNR={
                    this.props.type !== 2
                      ? formatTable(this.state.table, "nr")
                      : null
                  }
                  type={this.props.type}
                  typeSearch={this.state.typeSearch}
                  isUser={!!this.state.user}
                />
              </div>
              <div ref={this.refPrintSuiviChart}>
                <EvolutionDisplay
                  chart={this.state.performance ? this.state.chart : null}
                  isLoader={this.state.isPrint ? null : this.state.isLoad}
                  type={this.props.type}
                  isPrint={this.state.isPrint}
                  onLabelsLoaded={(isLoad) => this.setState({ isLoad })}
                />
              </div>
            </>
          ) : null}
          <Loader show={this.state.isLoad} />
          <PrintClass
            refs={
              this.state.isPrint && this.state.isLoad
                ? [
                    this.refPrintSummary,
                    this.refPrintAudit,
                    this.refPrintSuivi,
                    this.refPrintSuiviChart,
                  ]
                : []
            }
            title={`Performance ${getNameTypePerformance(this.props.type)}`}
            fileName={`Performance ${getNameTypePerformance(this.props.type)}`}
            onPrint={(print) =>
              this.setState({ isPrint: print, isLoad: print })
            }
            width={1500}
          />
        </FadeIn>
      </div>
    );
  }
}
