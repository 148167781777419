import React from "react";
import { Col, Row } from "react-flexbox-grid";
import { Card, ProgressBar, Status } from "ui-kit-ck-consultant";

import NPSSatisfaction from "./others/NPSSatisfaction";
import PieChartSatisfaction from "./others/PieChartSatisfaction";

export default class Global extends React.Component {
  getDataDay = () => {
    return (
      <>
        <h3 className="text-center">Données journalières</h3>
        {this.props.hideNPS ? null : (
          <NPSSatisfaction data={this.props.NPSDayData} isDay={true} />
        )}
        {this.props.responseCountDayData ? (
          <Status
            className="bg-blue white w-100 nowrap mb-10"
            style={{ fontSize: 14 }}
            text={`Nombre de réponse du jour : ${this.props.responseCountDayData}`}
          />
        ) : null}
        {this.props.responseRateDayData ? (
          <ProgressBar
            className="mt-10"
            title="Taux de réponse du jour"
            text={this.props.responseRateDayData + "%"}
            value={this.props.responseRateDayData}
          />
        ) : null}
        {this.props.averageGradeDayData ? (
          <ProgressBar
            className="mt-10"
            title="Note moyenne du jour"
            text={this.props.averageGradeDayData + "/20"}
            value={(this.props.averageGradeDayData * 100) / 20}
          />
        ) : null}
      </>
    );
  };

  getDataCumulate = () => {
    return (
      <>
        <h3 className="text-center">Données cumulées</h3>
        {this.props.hideNPS ? null : (
          <NPSSatisfaction data={this.props.NPSData} />
        )}
        {this.props.responseCountData ? (
          <Status
            className="bg-blue white w-100 nowrap mb-10"
            style={{ fontSize: 14 }}
            text={`Nombre de réponse : ${this.props.responseCountData}`}
          />
        ) : null}
        {this.props.responseRateData ? (
          <ProgressBar
            className="mt-10"
            title="Taux de réponse"
            text={this.props.responseRateData + "%"}
            value={this.props.responseRateData}
          />
        ) : null}
        {this.props.averageGradeData ? (
          <ProgressBar
            className="mt-10"
            title="Note moyenne"
            text={this.props.averageGradeData + "/20"}
            value={(this.props.averageGradeData * 100) / 20}
          />
        ) : null}
      </>
    );
  };

  render() {
    return (
      <Card
        className={this.props.className}
        title={`Global ${
          this.props.count ? `(${this.props.count} réponses)` : ""
        }`}
      >
        <Row>
          <Col xs={12} lg={this.props.hideNPS ? 6 : 4}>
            {this.props.hideNPS ? null : this.getDataDay()}
            {this.getDataCumulate()}
          </Col>
          {this.props.hideNPS ? (
            <Col xs={12} lg={this.props.hideNPS ? 6 : 4}>
              {this.getDataDay()}
            </Col>
          ) : (
            <Col xs={12} lg={4} className="d-flex">
              <PieChartSatisfaction
                title={"Pertinence des supports de communication"}
                data={
                  this.props.generalSatisfactionData
                    ? this.props.generalSatisfactionData["agency_pertinence"]
                    : []
                }
              />
            </Col>
          )}
          {this.props.hideNPS ? null : (
            <Col xs={12} lg={4} className="d-flex">
              <PieChartSatisfaction
                title={"Facilité de contact"}
                data={
                  this.props.generalSatisfactionData
                    ? this.props.generalSatisfactionData["agency_contact"]
                    : []
                }
              />
            </Col>
          )}
        </Row>
      </Card>
    );
  }
}
