import React from "react";
import { Modal, Button, FormTextarea } from "ui-kit-ck-consultant";

import { updateAnalyze } from "../../requests/satisfaction";

export default class ModalAnalyse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      analyse: "",
    };
  }

  handleChangeSearch = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.data &&
      this.props.idx !== null &&
      this.props.data[this.props.idx] &&
      prevProps.idx !== this.props.idx
    ) {
      this.setState({
        analyse: this.props.data[this.props.idx].analyse
          ? this.props.data[this.props.idx].analyse
          : "",
      });
    }
  }

  updateAnalyze = () => {
    let tmpData = this.props.data;

    tmpData[this.props.idx].analyse = this.state.analyse;

    updateAnalyze(
      tmpData[this.props.idx].id,
      this.state.analyse,
      this.props.onClose
    );
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onClose={this.props.onClose}
        title="Détails de la réponse"
      >
        <FormTextarea
          name="analyse"
          placeholder="Analyse"
          value={this.state.analyse}
          onChange={this.handleChangeSearch}
        />
        <Button
          className="mt-10"
          text="Confirmer"
          onClick={this.updateAnalyze}
        />
      </Modal>
    );
  }
}
