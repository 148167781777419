import React, { Component } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  Th,
  Pagination,
  ButtonIcon
} from "ui-kit-ck-consultant";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
  faPen,
  faTrash,
  faDownload,
  faEye,
  faFlag
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axiosConfig from "../../../utils/axiosConfig"

import AuthContext from "../../../context/AuthContext"

export default class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: null
    };

    this.refColumnName = React.createRef();
    this.refColumnCompany = React.createRef();
    this.refColumnAgency = React.createRef();
    this.refColumnDate = React.createRef();
  }

  static contextType = AuthContext;

  handleSortClick = (desc, column) => {
    if (column !== 0) {
      this.refColumnName.current.setState({ desc: false, sort: false });
    }
    if (column !== 1) {
      this.refColumnCompany.current.setState({ desc: false, sort: false });
    }
    if (column !== 2) {
      this.refColumnAgency.current.setState({ desc: false, sort: false });
    }
    if (column !== 3) {
      this.refColumnDate.current.setState({ desc: false, sort: false });
    }

    let order = null;
    switch (column) {
      case 0:
        order = { name: "name", desc: desc };
        break;
      case 1:
        order = { name: "company", desc: desc };
        break;
      case 2:
        order = { name: "agency", desc: desc };
        break;
      case 3:
        order = { name: "date", desc: desc };
        break;
      default:
        order = { name: "id", desc: desc };
        break;
    }
    this.setState({ order: order }, () => {
      this.props.onSortClick(order);
    });
  };

  handleEyeClick = (element, action = 'preview') => {
    if (element.status === 1) {
      this.props.displaySample(element.id, action)
    } else {
      window.open(this.context.urlPdf + `?document=${element.id}`)
    }
  }

  handleCloture = (element) => {
    axiosConfig('/company-monitoring/update-element', {
      data: {
        id: element.id
      }
    }).then(res => {
      if (res.data.success === true) {
        this.props.handleUncloture(element.id)
      }
    })
  }

  render() {
    return (
      <>
        <Table
          empty={
            this.props.data.length > 0
              ? ""
              : "Aucune donnée n'est disponible"
          }
        >
          <Thead>
            <Tr>
              <Th
                ref={this.refColumnName}
                sort
                onSort={(desc) => this.handleSortClick(desc, 0)}
              >Nom</Th>
              <Th
                ref={this.refColumnCompany}
                sort
                onSort={(desc) => this.handleSortClick(desc, 1)}
              >Compagnie</Th>
              <Th
                ref={this.refColumnAgency}
                sort
                onSort={(desc) => this.handleSortClick(desc, 2)}
              >GRA</Th>
              <Th
                ref={this.refColumnDate}
                sort
                onSort={(desc) => this.handleSortClick(desc, 3)}
              >Date</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {this.props.data.map((element, idx) => (
              <Tr
                key={idx}
                className={element.status === 1 ? "cursor-pointer" : ''}
              >
                {element.status === 1
                  ?
                  <>
                    <Td onClick={() => this.props.displaySample(element.id)} >{element.name}</Td>
                    <Td onClick={() => this.props.displaySample(element.id)} >{element.company}</Td>
                    <Td onClick={() => this.props.displaySample(element.id)} >{element.agency}</Td>
                    <Td onClick={() => this.props.displaySample(element.id)} >{element.date}</Td>
                  </>
                  :
                    <>
                      <Td  >{ element.status === 1 ? element.name + ' (cloturé)' : element.name}</Td>
                      <Td  >{element.company}</Td>
                      <Td  >{element.agency}</Td>
                      <Td  >{element.date}</Td>
                    </>
                }
                <Td className="nowrap">
                  {this.context.role !== 0 
                  ? 
                    (<>
                      <ButtonIcon
                        small
                        onClick={() => this.props.onDeleteClick(element.id)}
                      >
                        <FontAwesomeIcon className={"red"} icon={faTrash} />
                      </ButtonIcon>
                      <ButtonIcon
                        small
                        onClick={() => this.props.onOpenModal(element.id)}
                      >
                        <FontAwesomeIcon className={"blue"} icon={faPen} />
                      </ButtonIcon>
                      <ButtonIcon
                          small
                          onClick={() => this.handleEyeClick(element)}
                        >
                          <FontAwesomeIcon className={"green"} icon={faEye} />
                        </ButtonIcon>
                      {element.type !== 1 && element.status === 1 
                      ? <ButtonIcon
                          small
                          onClick={() => this.handleEyeClick(element, 'download')}
                        >
                          <FontAwesomeIcon className={"green"} icon={faDownload} />
                        </ButtonIcon> 
                      : null
                      }
                      {element.type !== 1 && element.status === 1 
                      ? <ButtonIcon
                          small
                          onClick={() => this.handleCloture(element)}
                        >
                          <FontAwesomeIcon className={"green"} icon={faFlag} />
                        </ButtonIcon> 
                      : null} 
                    </>)
                  : (<>
                      {element.status === 1 
                      ? (<ButtonIcon
                          small
                          onClick={() => this.handleEyeClick(element)}
                        >
                          <FontAwesomeIcon className={"green"} icon={faEye} />
                        </ButtonIcon>)
                      : null }
                    </>) 
                  }
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        {this.props.isPrint
        ? null 
        : 
          <Pagination
            activePage={this.props.activePage}
            itemsCountPerPage={this.props.tabLength}
            totalItemsCount={this.props.total}
            pageRangeDisplayed={4}
            onChange={this.props.onPageChange.bind(this)}
            firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
            lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
            prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
            nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
          />
        }
      </>
    );
  }
}
