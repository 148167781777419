import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import { BarChart, Card } from "ui-kit-ck-consultant";

export default class CardEvolutionSixValue extends Component {
  render() {
    function getMax(data) {
      if (data) {
        let max = 0;
        data.map((value) => {
          if (value && parseInt(value) > max) {
            max = parseInt(value);
          }
          return true;
        });
        return max;
      } else return 0;
    }

    let max1Line = 0;
    let max1Bar = 0;
    this.props.data &&
      this.props.data.data.map((label, id) => {
        if (id === 0) max1Line = getMax(label.data);
        else max1Bar = getMax(label.data);
        return label;
      });
    let max2Line = 0;
    let max2Bar = 0;
    this.props.dataTwo &&
      this.props.dataTwo.data.map((label, id) => {
        if (id === 0) max2Line = getMax(label.data);
        else max2Bar = getMax(label.data);
        return label;
      });

    let max3Line = 0;
    let max3Bar = 0;
    this.props.dataThree &&
      this.props.dataThree.data.map((label, id) => {
        if (id === 0) max3Line = getMax(label.data);
        else max3Bar = getMax(label.data);
        return label;
      });
    let max3 = max3Line + max3Bar;

    let max4Line = 0;
    let max4Bar1 = 0;
    let max4Bar2 = 0;
    this.props.dataFour &&
      this.props.dataFour.data.map((label, id) => {
        if (id === 0) max4Line = getMax(label.data);
        else if (id === 1) max4Bar1 = getMax(label.data);
        else max4Bar2 = getMax(label.data);
        return label;
      });
    let max4Bar = max4Bar1 + max4Bar2;

    let max5Line = 0;
    let max5Bar = 0;
    this.props.dataFive &&
      this.props.dataFive.data.map((label, id) => {
        if (id === 0) max5Line = getMax(label.data);
        else max5Bar = getMax(label.data);
        return label;
      });

    let max6Line = 0;
    let max6Bar1 = 0;
    let max6Bar2 = 0;
    this.props.dataSix &&
      this.props.dataSix.data.map((label, id) => {
        if (id === 0) max6Line = getMax(label.data);
        else if (id === 1) max6Bar1 = getMax(label.data);
        else max6Bar2 = getMax(label.data);
        return label;
      });
    let max6Bar = max6Bar1 + max6Bar2;

    return (
      <div>
        <Row>
          {this.props.data ? (
            <Col xs={12} md={6} xl={4}>
              <Card className={"min-tab-size-xs mt-10"}>
                <h3>{this.props.data.title}</h3>
                <BarChart
                  digits={0}
                  format={this.props.data.format}
                  notAlign={this.props.data.notAlign === true ? true : false}
                  legendDisplay={this.props.data.legendDisplay}
                  dataSet={this.props.data.data}
                  yAxes={[
                    {
                      id: "A",
                      ticks: {
                        display: false,
                        suggestedMax: max1Line + (max1Line / 100) * 40,
                        min: 0,
                      },
                      gridLines: {
                        drawBorder: false,
                      },
                    },
                    {
                      id: "B",
                      ticks: {
                        display: false,
                        suggestedMax: max1Bar + (max1Bar / 100) * 40,
                        beginAtZero: true,
                      },
                    },
                  ]}
                  datalabels2={
                    this.props.isPrint
                      ? [
                          {
                            display: true,
                            align: "top",
                            anchor: "end",
                            clamp: true,
                            color: "#db2828",
                            backgroundColor: "white",
                            borderColor: "#db2828",
                            borderWidth: 1,
                            borderRadius: 50,
                            font: {
                              size: "10",
                            },
                            formatter: (value) => Math.round(value),
                          },
                          {
                            display: true,
                            align: "center",
                            anchor: "center",
                            clamp: true,
                            color: "#0162AD",
                            backgroundColor: "rgba(255, 255, 255, 0.5",
                            borderColor: "#0162AD",
                            borderWidth: 1,
                            borderRadius: 50,
                            font: {
                              size: "10",
                            },
                            formatter: (value) => Math.round(value),
                          },
                        ]
                      : null
                  }
                />
              </Card>
            </Col>
          ) : null}
          {this.props.dataTwo ? (
            <Col xs={12} md={6} xl={4}>
              <Card className={"min-tab-size-xs mt-10"}>
                <h3>{this.props.dataTwo.title}</h3>
                <BarChart
                  digits={0}
                  notAlign={this.props.dataTwo.notAlign === true ? true : false}
                  legendDisplay={this.props.dataTwo.legendDisplay}
                  dataSet={this.props.dataTwo.data}
                  xAxes={[
                    {
                      stacked: true,
                    },
                  ]}
                  yAxes={[
                    {
                      id: "B",
                      stacked: true,
                      ticks: {
                        suggestedMax: max2Bar + (max2Bar / 100) * 40,
                      },
                      gridLines: {
                        display: false,
                      },
                      display: false,
                    },
                    {
                      id: "A",
                      display: false,
                      stacked: false,
                      ticks: {
                        suggestedMax: max2Line + (max2Line / 100) * 40,
                        min: 0,
                        beginAtZero: true,
                      },
                    },
                  ]}
                  datalabels2={
                    this.props.isPrint
                      ? [
                          {
                            display: true,
                            align: "top",
                            anchor: "end",
                            clamp: true,
                            color: "#db2828",
                            backgroundColor: "white",
                            borderColor: "#db2828",
                            borderWidth: 1,
                            borderRadius: 50,
                            font: {
                              size: "10",
                            },
                            formatter: (value) => Math.round(value),
                          },
                          {
                            display: true,
                            align: "center",
                            anchor: "center",
                            clamp: true,
                            color: "#0162AD",
                            backgroundColor: "rgba(255, 255, 255, 0.5",
                            borderColor: "#0162AD",
                            borderWidth: 1,
                            borderRadius: 50,
                            font: {
                              size: "10",
                            },
                            formatter: (value) => Math.round(value),
                          },
                        ]
                      : null
                  }
                />
              </Card>
            </Col>
          ) : null}
          {this.props.dataThree ? (
            <Col xs={12} md={6} xl={4}>
              <Card className={"min-tab-size-xs mt-10"}>
                <h3>{this.props.dataThree.title}</h3>
                <BarChart
                  digits={0}
                  notAlign={
                    this.props.dataThree.notAlign === true ? true : false
                  }
                  legendDisplay={this.props.dataThree.legendDisplay}
                  dataSet={this.props.dataThree.data}
                  xAxes={[
                    {
                      stacked: true,
                    },
                  ]}
                  yAxes={[
                    {
                      id: "B",
                      stacked: true,
                      display: false,
                      ticks: {
                        suggestedMax: max3 + (max3 / 100) * 30,
                        min: 0,
                        beginAtZero: true,
                      },
                    },
                  ]}
                  datalabels2={
                    this.props.isPrint
                      ? [
                          {
                            display: true,
                            align: "right",
                            anchor: "right",
                            clamp: true,
                            color: "#db2828",

                            font: {
                              size: "10",
                            },
                            formatter: (value) => Math.round(value),
                          },
                          {
                            display: true,
                            align: "right",
                            anchor: "right",
                            clamp: true,
                            color: "#0162AD",

                            font: {
                              size: "10",
                            },
                            formatter: (value) => Math.round(value),
                          },
                        ]
                      : null
                  }
                />
              </Card>
            </Col>
          ) : null}
          {this.props.dataFour ? (
            <Col xs={12} md={6} xl={4}>
              <Card className={"min-tab-size-xs mt-10"}>
                <h3>{this.props.dataFour.title}</h3>
                <BarChart
                  digits={0}
                  notAlign={
                    this.props.dataFour.notAlign === true ? true : false
                  }
                  legendDisplay={this.props.dataFour.legendDisplay}
                  xAxes={[
                    {
                      stacked: true,
                    },
                  ]}
                  yAxes={[
                    {
                      id: "B",
                      stacked: true,
                      ticks: {
                        display: false,
                        suggestedMax: max4Line + (max4Line / 100) * 30,
                        beginAtZero: true,
                      },
                      gridLines: {
                        display: false,
                      },
                      display: false,
                    },
                    {
                      id: "A",
                      stacked: false,
                      ticks: {
                        display: false,
                        suggestedMax: max4Bar + (max4Bar / 100) * 30,
                        beginAtZero: true,
                      },
                    },
                  ]}
                  dataSet={this.props.dataFour.data}
                  datalabels2={
                    this.props.isPrint
                      ? [
                          {
                            display: true,
                            align: "top",
                            anchor: "end",
                            clamp: true,
                            color: "#db2828",
                            backgroundColor: "white",
                            borderColor: "#db2828",
                            borderWidth: 1,
                            borderRadius: 50,
                            formatter: (value) => Math.round(value),
                          },
                          {
                            display: true,
                            align: "center",
                            anchor: "center",
                            clamp: true,
                            color: "#026fc2",
                            backgroundColor: "rgba(255, 255, 255, 0.5",
                            borderColor: "#026fc2",
                            borderWidth: 1,
                            borderRadius: 50,
                            formatter: (value) => Math.round(value),
                          },
                          {
                            display: true,
                            align: "center",
                            anchor: "center",
                            clamp: true,
                            color: "#0c8540",
                            backgroundColor: "rgba(255, 255, 255, 0.5",
                            borderColor: "#0c8540",
                            borderWidth: 1,
                            borderRadius: 50,
                            formatter: (value) => Math.round(value),
                          },
                        ]
                      : null
                  }
                />
              </Card>
            </Col>
          ) : null}
          {this.props.dataFive ? (
            <Col xs={12} md={6} xl={4}>
              <Card className={"min-tab-size-xs mt-10"}>
                <h3>{this.props.dataFive.title}</h3>
                <BarChart
                  digits={0}
                  format={this.props.dataFive.format}
                  notAlign={
                    this.props.dataFive.notAlign === true ? true : false
                  }
                  legendDisplay={this.props.dataFive.legendDisplay}
                  dataSet={this.props.dataFive.data}
                  yAxes={[
                    {
                      id: "A",
                      ticks: {
                        display: false,
                        beginAtZero: true,
                        suggestedMax: max5Line + (max5Line / 100) * 30,
                      },
                    },
                    {
                      id: "B",
                      ticks: {
                        display: false,
                        beginAtZero: true,
                        suggestedMax: max5Bar + (max5Bar / 100) * 30,
                      },
                    },
                  ]}
                  datalabels2={
                    this.props.isPrint
                      ? [
                          {
                            display: true,
                            align: "top",
                            anchor: "end",
                            clamp: true,
                            color: "#db2828",
                            backgroundColor: "white",
                            borderColor: "#db2828",
                            borderWidth: 1,
                            borderRadius: 50,
                            formatter: (value) => Math.round(value),
                          },
                          {
                            display: true,
                            align: "center",
                            anchor: "center",
                            clamp: true,
                            color: "#026fc2",
                            backgroundColor: "rgba(255, 255, 255, 0.5",
                            borderColor: "#026fc2",
                            borderWidth: 1,
                            borderRadius: 50,
                            formatter: (value) => Math.round(value),
                          },
                        ]
                      : null
                  }
                />
              </Card>
            </Col>
          ) : null}
          {this.props.dataSix ? (
            <Col xs={12} md={6} xl={4}>
              <Card className={"min-tab-size-xs mt-10"}>
                <h3>{this.props.dataSix.title}</h3>
                <BarChart
                  digits={0}
                  notAlign={this.props.dataSix.notAlign === true ? true : false}
                  legendDisplay={this.props.dataSix.legendDisplay}
                  dataSet={this.props.dataSix.data}
                  xAxes={[
                    {
                      stacked: true,
                    },
                  ]}
                  yAxes={[
                    {
                      id: "A",
                      display: false,
                      ticks: {
                        suggestedMax: max6Line + (max6Line / 100) * 30,
                        beginAtZero: true,
                      },
                      gridLines: {
                        drawBorder: false,
                      },
                      stacked: false,
                    },
                    {
                      id: "B",
                      display: false,
                      ticks: {
                        suggestedMax: max6Bar + (max6Bar / 100) * 30,
                        beginAtZero: true,
                      },
                      stacked: true,
                    },
                  ]}
                  datalabels2={
                    this.props.isPrint
                      ? [
                          {
                            display: true,
                            align: "top",
                            anchor: "end",
                            clamp: true,
                            color: "#db2828",
                            backgroundColor: "rgba(255, 255, 255, 0.5",
                            borderColor: "#db2828",
                            borderWidth: 1,
                            borderRadius: 50,
                            formatter: (value) => Math.round(value),
                          },
                          {
                            display: true,
                            align: "center",
                            anchor: "center",
                            clamp: true,
                            color: "#0c8540",
                            backgroundColor: "rgba(255, 255, 255, 0.5",
                            borderColor: "#0c8540",
                            borderWidth: 1,
                            borderRadius: 50,
                            formatter: (value) => Math.round(value),
                          },
                          {
                            display: true,
                            align: "center",
                            anchor: "center",
                            clamp: true,
                            color: "#026fc2",
                            backgroundColor: "rgba(255, 255, 255, 0.5",
                            borderColor: "#026fc2",
                            borderWidth: 1,
                            borderRadius: 50,
                            formatter: (value) => Math.round(value),
                          },
                        ]
                      : null
                  }
                />
              </Card>
            </Col>
          ) : null}
        </Row>
      </div>
    );
  }
}
