import React, { Component } from "react";
import {
  FloatActionButton,
  Loader,
  Nav,
  PaginationClass,
  FormRadio,
} from "ui-kit-ck-consultant";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";

import TableData from "../../../components/audit/action-plan/TableData";
import TableDataGraphics from "../../../components/audit/action-plan/TableDataGraphics";
import List from "../../../components/audit/action-plan/List";
import ModalActionPlan from "../../../components/audit/action-plan/ModalActionPlan";
import ShowOptionsNGRA from "../../../components/general/ShowOptionsNGRA-v2";
import Statistics from "../../../components/audit/action-plan/Statistics";

import axiosConfig from "../../../utils/axiosConfig";
import html2canvasInstance from "../../../utils/html2canvasInstance";
import { alertError } from "../../../utils/errorMessage";

import AuthContext from "../../../context/AuthContext";

export default class ActionPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // ShowOption state
      network: 0,
      group: 0,
      region: 0,
      agency: 0,
      user: 0,
      dateStart: null,
      dateEnd: null,
      filter: "1",
      // TableData state
      dataTable: {
        trackingIndicator: [],
        repairableIndicator: [],
        unrepairableIndicator: [],
        dataCSV: [],
      },
      isLoadTable: false,
      dataTableYear: {
        trackingIndicator: [],
        repairableIndicator: [],
        unrepairableIndicator: [],
        dataCSV: [],
      },
      isLoadTableYear: false,
      // List state
      activePage: 1,
      tabLength: 20,
      total: 0,
      dataList: [],
      order: null,
      isLoadList: false,
      // Modal state
      isModal: false,
      dataModal: null,
      typeModal: null,
      nameModal: null,
      // Charts data
      dataChart: null,
      isLoadChart: false,
      // PRINT
      isPrint: false,
      isLoad: false,
      logo: null,
    };

    this.dataPrint = {
      count: 0,
      table: null,
      stats: null,
      list: null,
      logo: null,
    };

    this.refTableData = React.createRef();
    this.refTableData2 = React.createRef();
    this.refStatistics = React.createRef();
    this.refList = React.createRef();
    this.refHeader = React.createRef();
  }
  static contextType = AuthContext;

  handleRadioChange = (e) => {
    let { name, value } = e.target;
    this.setState(
      {
        [name]: value,
      },
      () => {
        this.getTableData();
        this.getChartData();
        this.initSearch();
      }
    );
  };

  getGroupIcon = () => {
    if (this.context.group) {
      let logo = localStorage.getItem("logoGroup");
      let group = localStorage.getItem("idGroup");
      if (!logo || parseInt(group) !== parseInt(this.context.group)) {
        axiosConfig("/auth/get-logo").then((res) => {
          if (res.data.success === true) {
            localStorage.setItem("logoGroup", res.data.data);
            localStorage.setItem("idGroup", this.context.group);
            this.setState({ logo: res.data.data });
          }
        });
      } else if (logo) {
        this.setState({ logo });
      }
    }
  };

  handlePrintClick(close = false) {
    this.dataPrint.count = 0;
    let max = 0;
    if (this.state.logo) max++;
    if (this.state.dataTable) max++;
    if (this.state.dataChart) max++;
    if (this.state.dataList) max++;
    this.setState(
      {
        isPrint: true,
        isLoad: true,
      },
      () => {
        if (this.state.logo) {
          html2canvasInstance(this.refHeader.current, (result) => {
            if (result.success === true) {
              this.dataPrint.logo = result.base64;
              this.checkPrint(max, close);
            }
          });
        }
        if (this.state.dataTable) {
          html2canvasInstance(this.refTableData.current, (result) => {
            if (result.success === true) {
              this.dataPrint.table = result.base64;
              this.checkPrint(max, close);
            }
          });
        }
        if (this.state.dataTable) {
          html2canvasInstance(this.refTableData2.current, (result) => {
            if (result.success === true) {
              this.dataPrint.table = result.base64;
              this.checkPrint(max, close);
            }
          });
        }
        if (this.state.dataChart) {
          html2canvasInstance(this.refStatistics.current, (result) => {
            if (result.success === true) {
              this.dataPrint.stats = result.base64;
              this.checkPrint(max, close);
            }
          });
        }
        if (this.state.dataList) {
          html2canvasInstance(this.refList.current, (result) => {
            if (result.success === true) {
              this.dataPrint.list = result.base64;
              this.checkPrint(max, close);
            }
          });
        }
      }
    );
  }

  checkPrint(max, close) {
    this.dataPrint.count++;
    if (this.dataPrint.count === max) {
      const data = {
        dataPrint: this.dataPrint,
      };
      if (close) {
      } else {
        axiosConfig("audit/action-plan/print", {
          data,
        })
          .then((res) => {
            this.setState(
              {
                isPrint: false,
                isLoad: false,
              },
              () => {
                if (res.data && res.data.success === true) {
                  const link = document.createElement("a");
                  link.href = res.data.file;
                  link.download = "Plan de resultat Macif.pdf";
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }
              }
            );
          })
          .catch((err) => alertError(err));
      }
    }
  }

  componentDidMount() {
    this.PaginationClass = new PaginationClass(
      "audit/action-plan/count",
      "audit/action-plan/pagination",
      this.state.tabLength
    );
    this.getGroupIcon();
  }

  // ShowOption data
  handleDataChange = (data) => {
    this.setState(
      {
        network: data.network,
        group: data.group,
        region: data.region,
        agency: data.agency,
        company: data.company,
        user: data.user,
        dateStart: data.dateStart,
        dateEnd: data.dateEnd,
      },
      () => {
        this.getTableData();
        this.getTableDataYear();
        this.getChartData();
        this.initSearch();
      }
    );
  };

  getChartData() {
    this.setState({ isLoadChart: true });
    axiosConfig("audit/action-plan/get-data-chart", {
      data: this.getData(),
    })
      .then((res) => {
        if (res.data.success === true) {
          this.setState({
            dataChart: res.data.data,
            isLoadChart: false,
          });
        } else {
          this.setState({
            dataChart: null,
            isLoadChart: false,
          });
        }
      })
      .catch((err) => {
        this.setState({
          dataChart: null,
          isLoadChart: false,
        });
      });
  }

  // TableData Data

  getTableData() {
    this.setState(
      {
        isLoadTable: true,
      },
      () => {
        axiosConfig("audit/action-plan/get-data-table", {
          data: this.getData(),
        })
          .then((res) => {
            if (res.data.success === true) {
              this.setState({
                dataTable: res.data.data,
                isLoadTable: false,
              });
            } else {
              this.setState({
                dataTable: {
                  trackingIndicator: [],
                  repairableIndicator: [],
                  unrepairableIndicator: [],
                  dataCSV: [],
                },
                isLoadTable: false,
              });
            }
          })
          .catch((err) => {
            this.setState({ isLoadTable: false }, () => {
              alertError(err);
            });
          });
      }
    );
  }

  getTableDataYear() {
    this.setState(
      {
        isLoadTableYear: true,
        dataTableYear: {
          trackingIndicator: [],
          repairableIndicator: [],
          unrepairableIndicator: [],
          dataCSV: [],
        },
      },
      () => {
        axiosConfig("audit/action-plan/get-data-table", {
          data: {
            ...this.getData(),
            isYear: true,
          },
        })
          .then((res) => {
            if (res.data.success === true) {
              this.setState({
                dataTableYear: res.data.data,
                isLoadTableYear: false,
              });
            } else {
              this.setState({
                dataTableYear: {
                  trackingIndicator: [],
                  repairableIndicator: [],
                  unrepairableIndicator: [],
                  dataCSV: [],
                },
                isLoadTableYear: false,
              });
            }
          })
          .catch((err) => {
            this.setState({ isLoadTableYear: false }, () => {});
          });
      }
    );
  }

  // List data

  initSearch = () => {
    this.setState({ activePage: 1, isLoadList: true }, () => {
      this.PaginationClass.init(axiosConfig, this.getData(), (result) => {
        if (result.success === true) {
          this.setState({ total: result.total, isLoadList: true }, () => {
            this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
              this.setData(result);
            });
          });
        } else {
          this.setState({ isLoadList: false });
        }
      });
    });
  };

  setData(result) {
    if (result.success === true) {
      result.tabData.map((element, idx) => {
        if (element.comment && element.comment.length > 60) {
          result.tabData[idx].shortComment =
            element.comment.substring(0, 60) + "...";
          result.tabData[idx].length = true;
          result.tabData[idx].active = false;
        } else {
          result.tabData[idx].length = false;
          result.tabData[idx].active = true;
        }
        return null;
      });
      this.setState({ dataList: result.tabData, isLoadList: false });
    } else {
      this.setState({ dataList: [], isLoadList: false }, () => {
        if (result.error) alertError(result.error);
      });
    }
  }

  getData() {
    return {
      network: this.state.network,
      group: this.state.group,
      region: this.state.region,
      agency: this.state.agency,
      user: this.state.user,
      dateStart: this.state.dateStart,
      dateEnd: this.state.dateEnd,
      company: this.state.company,
      filter: this.state.filter,
      orderName: this.state.order ? this.state.order.name : null,
      orderDesc: this.state.order ? this.state.order.desc : null,
    };
  }

  handlePageChange = (activePage) => {
    this.PaginationClass.page(activePage, () =>
      this.setState({ activePage: activePage, isLoadList: true }, () => {
        this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
          if (result.success === true) {
            this.setData(result);
          }
        });
      })
    );
  };

  handleSortClick = (order) => {
    this.setState({ order }, () => {
      this.initSearch();
    });
  };

  handleTabClick = (idx) => {
    let dataList = this.state.dataList;
    if (dataList[idx].active === false) {
      dataList[idx].active = true;
    } else {
      dataList[idx].active = false;
    }
    this.setState({ dataList: dataList });
  };

  handleOpenModalUnRepairable = (key, month, name) => {
    let tmpData = this.state.dataTable.unrepairableIndicator;
    for (const key in this.state.dataTable.unrepairableIndicator) {
      if (this.state.dataTable.unrepairableIndicator.hasOwnProperty(key)) {
        const element = this.state.dataTable.unrepairableIndicator[key];
        tmpData[key] = element;
      }
    }

    if (tmpData[key].folders[month].length > 0) {
      this.setState({
        isModal: true,
        dataModal: tmpData[key].folders[month],
        typeModal: tmpData[key].type,
        nameModal: name,
      });
    } else {
      alert("Aucun dossier disponible");
    }
  };

  handleOpenModalRepairable = (key, month, name) => {
    let tmpData = this.state.dataTable.repairableIndicator;
    for (const key in this.state.dataTable.repairableIndicator) {
      if (this.state.dataTable.repairableIndicator.hasOwnProperty(key)) {
        const element = this.state.dataTable.repairableIndicator[key];
        tmpData[key] = element;
      }
    }

    if (tmpData[key].folders[month].length > 0) {
      this.setState({
        isModal: true,
        dataModal: [
          ...tmpData[key].folders[month],
          ...tmpData[key].foldersForce[month],
        ],
        typeModal: tmpData[key].type,
        nameModal: name,
      });
    } else {
      alert("Aucun dossier disponible");
    }
  };

  handleCloseModal = () => {
    this.setState(
      {
        isModal: false,
        dataModal: null,
        typeModal: null,
      },
      () => {
        this.getTableData();
        this.initSearch();
      }
    );
  };

  handleDeleteClick = (id) => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer cet audit ?")) {
      axiosConfig("audit/action-plan/delete-folder", {
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.success === true) {
          this.getTableData();
          this.getChartData();
          this.initSearch();
        }
      });
    }
  };

  getWidthPrint() {
    return {
      width: this.state.isPrint ? "1500px" : "auto",
    };
  }

  getFilters = () => {
    switch (parseInt(this.state.company)) {
      case 1: // ALLIANZ
        return (
          <div style={{ fontSize: "12px" }} className="mb-20">
            <p>* Genre : VP</p>
            <p>
              * Nature évènement : exclure "événement climatique" et "vol non
              retrouvé"
            </p>
            <p>* Réparations écrêtement : 20 à 6 000€ TTC</p>
            <p>* VRADE écrêtement : 20 à 10 000€ TTC</p>
          </div>
        );
      case 3: // MACIF
        return (
          <div style={{ fontSize: "12px" }} className="mb-20">
            <p>* Genre : VP, CTTE</p>
            <p>* Nature évènement : circulation et stationnement</p>
            <p>* Réparations écrêtement : 20 à 4000 € HT</p>
            <p>* VRADE écrêtement : 0 à 10000 € HT </p>
          </div>
        );
      case 8: // MAIF
        return (
          <div style={{ fontSize: "12px" }} className="mb-20">
            <p>* Genre : VP, CTTE </p>
            <p>* Nature évènement : collision et stationnement</p>
            <p>* Réparations : Montant HT</p>
          </div>
        );
      case 9: // MATMUT
        return (
          <div style={{ fontSize: "12px" }} className="mb-20">
            <p>* Genre : VP, CTTE </p>
            <p>
              * Nature évènement : collision, stationnement, vol et incendie
            </p>
          </div>
        );
      case 10: // GAN
      case 11: // GROUPAMA
        return (
          <div style={{ fontSize: "12px" }} className="mb-20">
            <p>* Genre : VP, CTTE</p>
            <p>
              * Nature évènement : circulation, incendie, stationnement, vol et
              vandalisme
            </p>
          </div>
        );
      case 13: // ABEILLE
      case 20: // AXA
        return (
          <div style={{ fontSize: "12px" }} className="mb-20">
            <p>* Genre : VP, CTTE </p>
            <p>
              * Nature évènement : exclure "évènements climatiques" et "vol"
            </p>
          </div>
        );
      case 2: // COVEA
      case 5: // ACM
      default:
        return (
          <div style={{ fontSize: "12px" }} className="mb-20">
            <p>* Genre : VP, CTTE </p>
          </div>
        );
    }
  };

  render() {
    return (
      <>
        <Nav title="Plan d'Actions" className="mb-10 mb-20-xl" />
        <ShowOptionsNGRA
          isChange={true}
          className="mb-10 mb-20-xl"
          onDataChange={this.handleDataChange}
          isDateStart={true}
          isDateEnd={true}
          isCompany={true}
          isUser={true}
          visibilityRole={this.props.visibilityRole}
          visibilityType={this.props.visibilityType}
          preloadRole={this.props.preloadRole}
          preloadType={this.props.preloadType}
          limitUserType={this.props.limitUserType}
          limitUserRole={this.props.limitUserRole}
          allUserRole={this.props.allUserRole}
          userType={[1, 2, 3]}
          isAll={true}
          others={
            <>
              {this.state.company === 8 ? (
                <>
                  <FormRadio
                    className="mr-10"
                    name="filter"
                    value={"1"}
                    checked={this.state.filter === "1"}
                    onChange={this.handleRadioChange}
                    text="NR < 10 ans - 1 000 à 8 500 € HT"
                  />
                  <FormRadio
                    className="mr-10"
                    name="filter"
                    value={"2"}
                    checked={this.state.filter === "2"}
                    onChange={this.handleRadioChange}
                    text="NR >= 10 ans - 0 à 5 000 € HT"
                  />
                </>
              ) : null}
            </>
          }
        />
        {this.state.isPrint ? (
          <div ref={this.refHeader} style={this.getWidthPrint()}>
            <p>{moment(new Date()).format("DD-MM-YYYY")}</p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h3 style={{ fontSize: 28 }}>PLAN D'ACTIONS'</h3>
              <div>
                <img
                  alt={"logo"}
                  style={{ maxHeight: "100px", maxWidth: "100%" }}
                  src={this.state.logo}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div ref={this.refTableData} style={this.getWidthPrint()}>
          <TableData
            className="mb-10 mb-20-xl"
            data={this.state.dataTable}
            dataYear={this.state.dataTableYear}
            onOpenModalRepairable={this.handleOpenModalRepairable}
            onOpenModalUnRepairable={this.handleOpenModalUnRepairable}
            agency={this.state.agency}
            loader={this.state.isLoadTable}
            filters={this.getFilters()}
          />
        </div>
        <div ref={this.refTableData2} style={this.getWidthPrint()}>
          <TableDataGraphics
            className="mb-10 mb-20-xl"
            data={this.state.dataTable}
            agency={this.state.agency}
            loader={this.state.isLoadTable}
          />
        </div>
        {this.state.dataChart ? (
          <div ref={this.refStatistics} style={this.getWidthPrint()}>
            <Statistics
              data={this.state.dataChart}
              loader={this.state.isLoadChart}
              filters={this.getFilters()}
            />
          </div>
        ) : null}
        {this.state.dataList ? (
          <div ref={this.refList} style={this.getWidthPrint()}>
            <List
              className="mb-10 mb-20-xl"
              activePage={this.state.activePage}
              tabLength={this.state.tabLength}
              data={this.state.dataList}
              total={this.state.total}
              onPageChange={this.handlePageChange}
              onSortClick={this.handleSortClick}
              onTabClick={this.handleTabClick}
              onDeleteClick={this.handleDeleteClick}
              loader={this.state.isLoadList}
              role={this.context && this.context.role}
              isPrint={this.state.isPrint}
            />
          </div>
        ) : null}
        {this.state.dataModal ? (
          <ModalActionPlan
            show={this.state.isModal}
            onClose={this.handleCloseModal}
            data={this.state.dataModal}
            type={this.state.typeModal}
            name={this.state.nameModal}
          />
        ) : null}
        <FloatActionButton
          icon={<FontAwesomeIcon icon={faPrint} />}
          onClick={() => this.handlePrintClick()}
        ></FloatActionButton>
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
