import React, { Component } from "react";
import {
  TabsCustom,
  TabsCustomBody,
  TabsCustomHeader,
  TabsCustomItem,
  TabsCustomMenu,
} from "ui-kit-ck-consultant";

import VVR_R from "./VVR_R";
import VVR_NR from "./VVR_NR";

export class VVR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabsMenu: ["Réparable", "Non réparable"],
      tabsIndex: 0,
    };
  }

  render() {
    return (
      <TabsCustom>
        <TabsCustomHeader noCard className={"mb-10 mb-20-xl"}>
          {this.state.tabsMenu.map((element, key) => (
            <TabsCustomMenu
              noCard
              disabled={this.props.isLoader}
              key={key}
              title={element}
              active={this.state.tabsIndex === key}
              onClick={(e) => {
                this.setState({ tabsIndex: key });
              }}
            />
          ))}
        </TabsCustomHeader>
        <TabsCustomBody noCard>
          <TabsCustomItem active={true}>
            {this.state.tabsIndex === 0 ? (
              <VVR_R
                data={this.props.data}
                labelsR={this.props.labelsR}
                dateEnd={this.props.dateEnd}
              />
            ) : (
              <VVR_NR
                data={this.props.data}
                labelsNR={this.props.labelsNR}
                dateEnd={this.props.dateEnd}
              />
            )}
          </TabsCustomItem>
        </TabsCustomBody>
      </TabsCustom>
    );
  }
}

export default VVR;
