import React, { Component } from "react";
import { Card, Table, Tbody, Td, Th, Thead, Tr } from "ui-kit-ck-consultant";

export class EvolutionDisplayCloture extends Component {
  render() {
    let title;
    if (this.props.evolutionType === 0) title = "Évolutions";
    if (this.props.evolutionType === 1) title = "Informations générales";
    if (this.props.evolutionType === 2) title = "Process internes";
    return (
      <Card className={"mt-30"} title={title}>
        <Table>
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th radiusTopRight>Commentaire</Th>
            </Tr>
          </Thead>
          <Tbody>
            {this.props.evolutionData.map((element, idx) => {
              return (
                <Tr key={idx}>
                  <Td>{element.date}</Td>
                  <Td
                    className={"w-100"}
                    dangerouslySetInnerHTML={{
                      __html: element.commentaire.replace(/\n/g, "<br/>"),
                    }}
                  />
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Card>
    );
  }
}

export default EvolutionDisplayCloture;
