import React from "react";
import { Card, BarChart } from "ui-kit-ck-consultant";

export default class Global extends React.Component {
  render() {
    if (!this.props.generalDataChart) {
      return null;
    }

    return (
      <Card className={this.props.className} title="Évolution">
        <BarChart
          height={350}
          digits={2}
          title={"Note de satisfaction moyenne annuelle / région"}
          labels={this.props.generalDataChart.years_labels}
          dataSet={this.props.generalDataChart.years.map((element) => {
            return {
              type: "line",
              label: element.name,
              data: element.average,
              color: element.color,
            };
          })}
          isLegend={true}
          yAxes={[
            {
              ticks: {},
            },
          ]}
        />
        <BarChart
          height={350}
          digits={2}
          title={"Note de satisfaction moyenne mensuelle / région"}
          labels={this.props.generalDataChart.months_labels}
          dataSet={this.props.generalDataChart.months.map((element) => {
            return {
              type: "line",
              label: element.name,
              data: element.average,
              color: element.color,
            };
          })}
          isLegend={true}
          yAxes={[
            {
              ticks: {
                beginAtZero: false,
              },
            },
          ]}
        />
      </Card>
    );
  }
}
