import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import {
  BarChart,
  Card,
  HorizontalBarChart,
} from "ui-kit-ck-consultant";

import MonthBarChart from "./MonthBarChart";

export default class OverallAgency extends Component {
  render() {
    return (
      <Row>
        <Col xs={12} className={"mb-10 mb-20-xl"}>
          <Card title="CA mois / mois">
            <BarChart
              format="currency"
              labels={this.props.data.month.labels}
              digits={0}
              widthBar={30}
              dataSet={[
                {
                  label: "CA",
                  data: this.props.data.month.CA.total,
                  color: "#026fc2",
                },
                {
                  label: "Atteinte",
                  data: this.props.data.month.CA.total.map(
                    (element, idx) =>
                      element - this.props.data.month.CA.goal[idx]
                  ),
                  color: this.props.data.month.CA.total.map((element, idx) =>
                    element - this.props.data.month.CA.goal[idx] > 0
                      ? "#238037"
                      : "#ea0000"
                  ),
                },
                {
                  label: "Objectif",
                  type: "line",
                  data: this.props.data.month.CA.goal,
                  color: "#026fc2",
                },
              ]}
              xAxes={[
                {
                  stacked: true,
                },
              ]}
              yAxes={[
                {
                  stacked: true,
                  ticks: {
                    display: false,
                  },
                },
              ]}
            />
          </Card>
        </Col>
        <Col xs={12} md={6} lg={8} className={"mb-10 mb-20-xl"}>
          <Card title="Rendement mois / mois">
            <BarChart
              type="bar"
              height={260}
              labels={this.props.data.month.labels}
              digits={0}
              widthBar={30}
              datalabels2={[
                {
                  display: true,
                  align: "bottom",
                  anchor: "start",
                  clamp: true,
                  color: "#0162AD",
                  backgroundColor: "white",
                  borderColor: "#0162AD",
                  borderWidth: 1,
                  borderRadius: 4,
                  font: {
                    size: "10",
                  },
                  formatter: (value) => Math.round(value) + "%",
                },
              ]}
              dataSet={[
                {
                  label: "Rendement (en %)",
                  type: "line",
                  data: this.props.data.month.yield.yield,
                  color: "#026fc2",
                  yAxisID: "A",
                },
                {
                  label: "Dossiers déposés",
                  data: this.props.data.month.yield.closing,
                  type: "bar",
                  color: "#51AE50",
                  yAxisID: "B",
                  xAxisID: "xB",
                },
                {
                  label: "Dossiers reçus",
                  data: this.props.data.month.yield.mission,
                  type: "bar",
                  color: "#E68A00",
                  yAxisID: "B",
                  xAxisID: "xA",
                },
              ]}
              xAxes={[
                {
                  id: "xA",
                  stacked: true,
                  display: true,
                  barThickness: 45,
                },
                {
                  id: "xB",
                  display: false,
                  stacked: true,
                  barThickness: 30,
                  type: "category",
                  categoryPercentage: 0.6,
                  barPercentage: 0.9,
                  gridLines: {
                    display: false,
                    offsetGridLines: true,
                  },
                  offset: true,
                },
              ]}
              yAxes={[
                {
                  id: "A",
                  display: false,
                  ticks: {
                    beginAtZero: true,
                  },
                },
                {
                  id: "B",
                  display: false,
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ]}
            />
          </Card>
        </Col>
        <Col xs={12} md={6} lg={4} className={"mb-10 mb-20-xl"}>
          <Card title={`${this.props.year} - Dossiers en retard / expert`}>
            <HorizontalBarChart
              height={260}
              digits={0}
              format="percentage"
              align
              isLegend={true}
              xAxes={[
                {
                  ticks: {
                    display: false,
                    suggestedMax: 10,
                    min: 0,
                    beginAtZero: true,
                  },
                  offset: false,
                  scaleLabel: {
                    fontSize: 8,
                  },
                },
              ]}
              yAxes={[
                {
                  ticks: {
                    display: false,
                    suggestedMax: 10,
                    min: 0,
                    beginAtZero: true,
                  },
                  offset: false,
                  scaleLabel: {
                    fontSize: 8,
                  },
                },
              ]}
              labels={this.props.data.year.overdueFolderExpert.map(
                (agency) => agency.name
              )}
              dataSet={[
                {
                  label: "30j - 60j",
                  data: this.props.data.year.overdueFolderExpert.map(
                    (agency) => agency.data[30]
                  ),
                  color: "#268540",
                },
                {
                  label: "60j - 120j",
                  data: this.props.data.year.overdueFolderExpert.map(
                    (agency) => agency.data[60]
                  ),
                  color: "#facc02",
                },
                {
                  label: "+ 120j",
                  data: this.props.data.year.overdueFolderExpert.map(
                    (agency) => agency.data[120]
                  ),
                  color: "#db2828",
                },
              ]}
            />
          </Card>
        </Col>
        <Col xs={12} lg={4} className={"mb-10 mb-20-xl"}>
          <Card title={`${this.props.year} - Coût réparation / expert`}>
            <BarChart
              format="currency"
              labels={this.props.data.year.totalReportExpert.map(
                (expert) => expert.name
              )}
              digits={0}
              dataSet={[
                {
                  label: "Coût réparation",
                  data: this.props.data.year.totalReportExpert.map(
                    (expert) => expert.data
                  ),
                  color: this.props.data.year.totalReportExpert.map(
                    (expert) => expert.color
                  ),
                },
              ]}
            />
          </Card>
        </Col>
        <Col xs={12} lg={4} className={"mb-10 mb-20-xl"}>
          <Card title={`${this.props.year} - Coût VRADE / expert`}>
            <BarChart
              format="currency"
              labels={this.props.data.year.VRADEExpert.map(
                (expert) => expert.name
              )}
              digits={0}
              dataSet={[
                {
                  label: "VRADE",
                  data: this.props.data.year.VRADEExpert.map(
                    (expert) => expert.data
                  ),
                  color: this.props.data.year.VRADEExpert.map(
                    (expert) => expert.color
                  ),
                },
              ]}
            />
          </Card>
        </Col>
        <Col xs={12} lg={4} className={"mb-10 mb-20-xl"}>
          <Card title={`${this.props.year} - CA / expert`}>
            <BarChart
              format="currency"
              labels={this.props.data.year.CAExpert.map(
                (expert) => expert.name
              )}
              digits={0}
              dataSet={[
                {
                  label: "CA",
                  data: this.props.data.year.CAExpert.map(
                    (expert) => expert.data
                  ),
                  color: this.props.data.year.CAExpert.map(
                    (expert) => expert.color
                  ),
                },
              ]}
            />
          </Card>
        </Col>
        <Col xs={12} className={"mb-10 mb-20-xl"}>
          <Card title="Évolution stock dossiers">
            <BarChart
              height={260}
              labels={this.props.data.month.labels.map((label) => label)}
              digits={0}
              dataSet={this.props.data.month.stockExpert.map((expert) => ({
                type: "line",
                label: `stock ${expert.name}`,
                data: expert.data,
                color: expert.color,
              }))}
            />
          </Card>
        </Col>
        {this.props.data.month.CAExpert.map((expert) => (
          <Col xs={12} md={6} lg={4} className={"mb-10 mb-20-xl"}>
            <MonthBarChart
              title={`CA : ${expert.name}`}
              format="currency"
              label="CA"
              type="bar"
              data={expert.data}
              color={expert.color}
              widthBar={15}
              labels={this.props.data.month.labels}
            />
          </Col>
        ))}
      </Row>
    );
  }
}
