import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faHome,
  faSmile,
  faBook,
  faCalendar,
  faClock,
  faSearch,
  faBug,
  faWarehouse,
  faCarCrash,
  faUsers,
  faFile,
  faPercentage,
  faChartBar,
  faHandsHelping,
  faDesktop,
  faTools,
  faChartLine,
  faSnowflake,
  faInfoCircle,
  faMotorcycle,
  faMobileAlt,
  faSearchLocation,
  faPhone,
  faRoad,
  faCrosshairs,
  faUserTag,
  faWindowClose,
  faCaravan,
  faExclamationTriangle,
  faListCheck,
  faBolt,
  faLeaf,
  faEuroSign,
} from "@fortawesome/free-solid-svg-icons";

// Layout Types
import DashboardLayout from "../layouts/Dashboard";

// Route Views
import Home from "../views/dashboard/Home";

import OverallPerformance from "../views/dashboard/performances/OverallPerformance";
import DashboardEAD from "../views/dashboard/performances/DashboardEAD";
import ReparatorPerformance from "../views/dashboard/performances/ReparatorPerformance";
import Performance from "../views/dashboard/performances/Performance";
import Bike from "../views/dashboard/performances/Bike";
import Selfcare from "../views/dashboard/performances/Selfcare";

import VVNR_VVR from "../views/dashboard/specialties/VVNR_VVR";
import Caravaning from "../views/dashboard/specialties/Caravaning";
import Electric from "../views/dashboard/specialties/Electric";
import BrokenGlass from "../views/dashboard/specialties/BrokenGlass";
import Inconsistency from "../views/dashboard/specialties/Inconsistency";

import CompanyMonitoring from "../views/dashboard/company/CompanyMonitoring";
import CompanyPerformance from "../views/dashboard/company/CompanyPerformance";
import CompanyRanking from "../views/dashboard/company/CompanyRanking";

import Interview from "../views/dashboard/interview/Interview";

import Sim from "../views/dashboard/sim/Sim";

import Precisia from "../views/dashboard/Precisia";

import Satisfaction from "../views/dashboard/Satisfaction";
import Management from "../views/dashboard/quality/Management";
import PrecisiaPilotage from "../views/dashboard/quality/PrecisiaPilotage";

import Library from "../views/dashboard/Library";

import Tour from "../views/dashboard/planning/Tour";

import Technical from "../views/dashboard/audit/Technical";
import ActionPlan from "../views/dashboard/audit/ActionPlan";

import Anomaly from "../views/dashboard/Anomaly";

import EGA from "../views/dashboard/EGA/EGA";

import ProjectE from "../views/dashboard/ProjectE/ProjectE";
import FolderProjectE from "../views/dashboard/ProjectE/FolderProjectE";

import Tracking from "../views/dashboard/Tracking";

import Search from "../views/dashboard/Search";
import CompetitiveCost from "../views/dashboard/CompetitiveCost";

import Update from "../views/dashboard/Update";

import Ideas from "../views/dashboard/Ideas";
import Planning from "../views/dashboard/planning/Planning";

const forceGroup = {
  isForceNetwork: false,
  isForceGroup: true,
  isForceRegion: true,
  isForceAgency: true,
};

const forceRegion = {
  isForceNetwork: false,
  isForceGroup: false,
  isForceRegion: true,
  isForceAgency: true,
};

const forceAgency = {
  isForceNetwork: false,
  isForceGroup: false,
  isForceRegion: false,
  isForceAgency: true,
};

const forceNothing = {
  isForceNetwork: false,
  isForceGroup: false,
  isForceRegion: false,
  isForceAgency: false,
};

const getRoutesSidebar = (
  type,
  role,
  network,
  group,
  region,
  agency,
  fake,
  hiddenPages
) => {
  const views = [
    {
      path: "/",
      exact: true,
      layout: DashboardLayout,
      component: () => <Redirect to="/home" />,
      notAllowedType: [0],
      notAllowedRole: [],
    },
    {
      icon: <FontAwesomeIcon icon={faHome} />,
      name: "Tableau de bord",
      path: "/home",
      exact: true,
      layout: DashboardLayout,
      component: Home,
      notAllowedType: [0],
      notAllowedRole: [],
      visibilityType: [
        forceNothing,
        forceRegion,
        forceRegion,
        forceRegion,
        forceRegion,
        forceRegion,
      ],
      visibilityRole: [
        forceNothing,
        forceRegion,
        forceRegion,
        forceRegion,
        forceRegion,
      ],
      preloadType: [null, null, null, null, null, null],
      preloadRole: [null, null, null, null, null],
      limitUserType: [true, true, true, true, true, true],
      limitUserRole: [true, false, false, false, false, false],
      allUserRole: [false, false, false, false, false],
    },
    {
      icon: <FontAwesomeIcon icon={faSmile} />,
      name: "Qualité",
      notAllowedType: [0],
      notAllowedRole: [],
      views: [
        {
          icon: <FontAwesomeIcon icon={faSmile} />,
          name: "Qualité Client",
          path: "/quality/satisfaction",
          exact: true,
          layout: DashboardLayout,
          component: Satisfaction,
          notAllowedType: [0],
          notAllowedRole: [],
          visibilityType: [
            forceNothing,
            forceRegion,
            forceRegion,
            forceRegion,
            forceRegion,
            forceRegion,
          ],
          visibilityRole: [
            forceNothing,
            forceRegion,
            forceRegion,
            forceRegion,
            forceRegion,
          ],
          preloadType: [
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
          ],
          preloadRole: [null, "agence", "agence", "region", "group"],
          limitUserType: [true, true, true, true, true, true],
          limitUserRole: [true, false, false, false, false, false],
          allUserRole: [false, false, false, false, false],
        },
        {
          icon: <FontAwesomeIcon icon={faListCheck} />,
          name: "Management",
          path: "/quality/management",
          exact: true,
          layout: DashboardLayout,
          component: Management,
          notAllowedType: [0],
          notAllowedRole: [],
          visibilityType: [
            forceNothing,
            forceRegion,
            forceRegion,
            forceRegion,
            forceRegion,
            forceRegion,
          ],
          visibilityRole: [
            forceNothing,
            forceRegion,
            forceRegion,
            forceRegion,
            forceRegion,
          ],
          preloadType: [
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
          ],
          preloadRole: [null, "agence", "agence", "region", "group"],
          limitUserType: [true, true, true, true, true, true],
          limitUserRole: [true, false, false, false, false, false],
          restrictNetwork: [null, 1],
          restrictGroup: [null, 1],
          allUserRole: [false, false, false, false, false],
        },
        {
          icon: <FontAwesomeIcon icon={faListCheck} />,
          name: "PrécisIA Pilotage",
          path: "/quality/precisia-pilotage",
          exact: true,
          layout: DashboardLayout,
          component: PrecisiaPilotage,
          notAllowedType: [0],
          notAllowedRole: [0],
          visibilityType: [
            forceNothing,
            forceRegion,
            forceRegion,
            forceRegion,
            forceRegion,
            forceRegion,
          ],
          visibilityRole: [
            forceNothing,
            forceRegion,
            forceRegion,
            forceRegion,
            forceRegion,
          ],
          preloadType: [
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
          ],
          preloadRole: [null, "agence", "agence", "region", "group"],
          limitUserType: [true, true, true, true, true, true],
          limitUserRole: [true, false, false, false, false, false],
          restrictNetwork: [null, 1],
          restrictGroup: [null, 1],
          allUserRole: [false, false, false, false, false],
        },
      ],
    },
    {
      icon: <FontAwesomeIcon icon={faBook} />,
      name: "Biblio'tech",
      path: "/library",
      exact: true,
      layout: DashboardLayout,
      component: Library,
      notAllowedType: [0],
      notAllowedRole: [],
      visibilityType: [
        forceNothing,
        forceGroup,
        forceGroup,
        forceGroup,
        forceGroup,
        forceGroup,
      ],
      visibilityRole: [
        forceNothing,
        forceGroup,
        forceGroup,
        forceGroup,
        forceGroup,
      ],
      preloadType: ["group", "group", "group", "group", "group", "group"],
      preloadRole: [null, "group", "group", "group", "group"],
      limitUserType: [true, true, true, true, true, true],
      limitUserRole: [true, false, false, false, false, false],
      allUserRole: [false, false, false, false, false],
    },
    {
      icon: <FontAwesomeIcon icon={faCalendar} />,
      name: "Planning",
      notAllowedType: [0],
      notAllowedRole: [],
      views: [
        {
          icon: <FontAwesomeIcon icon={faUser} />,
          name: "Formation / Congés",
          path: "/planning/:id?",
          exact: true,
          layout: DashboardLayout,
          component: Planning,
          notAllowedType: [0],
          notAllowedRole: [],
          visibilityType: [
            forceNothing,
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
          ],
          visibilityRole: [
            forceNothing,
            forceAgency,
            forceAgency,
            forceRegion,
            forceRegion,
          ],
          preloadType: [
            "region",
            "region",
            "region",
            "region",
            "region",
            "region",
          ],
          preloadRole: [null, "region", "region", "region", "group"],
          limitUserType: [true, true, true, true, true, true],
          limitUserRole: [true, false, false, false, false, false],
          allUserRole: [false, true, true, false, false],
        },
        {
          icon: <FontAwesomeIcon icon={faClock} />,
          name: "Tournées",
          path: "/tour",
          exact: true,
          layout: DashboardLayout,
          component: Tour,
          notAllowedType: [0],
          notAllowedRole: [],
          visibilityType: [
            forceNothing,
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
          ],
          visibilityRole: [
            forceNothing,
            forceAgency,
            forceRegion,
            forceRegion,
            forceRegion,
          ],
          preloadType: [
            "region",
            "region",
            "region",
            "region",
            "region",
            "region",
          ],
          preloadRole: [null, "region", "region", "region", "group"],
          limitUserType: [true, true, true, false, false, false],
          limitUserRole: [true, false, false, false, false, false],
          allUserRole: [false, true, true, false, false],
        },
      ],
    },
    {
      icon: <FontAwesomeIcon icon={faCarCrash} />,
      name: "Compagnie",
      notAllowedType: [0],
      notAllowedRole: [],
      views: [
        {
          icon: <FontAwesomeIcon icon={faFile} />,
          name: "Suivi",
          path: "/company-monitoring",
          exact: true,
          layout: DashboardLayout,
          component: CompanyMonitoring,
          notAllowedType: [0],
          notAllowedRole: [],
          visibilityType: [
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
          ],
          visibilityRole: [
            forceNothing,
            forceRegion,
            forceRegion,
            forceRegion,
            forceRegion,
          ],
          preloadType: [
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
          ],
          preloadRole: [null, "agence", "agence", "region", "group"],
          limitUserType: [true, true, true, true, true, true],
          limitUserRole: [true, false, false, false, false, false],
          allUserRole: [false, false, false, false, false],
        },
        {
          icon: <FontAwesomeIcon icon={faPercentage} />,
          name: "Performances",
          path: "/company-performance",
          exact: true,
          layout: DashboardLayout,
          component: CompanyPerformance,
          notAllowedType: [0],
          notAllowedRole: [],
          visibilityType: [
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
          ],
          visibilityRole: [
            forceNothing,
            forceRegion,
            forceRegion,
            forceRegion,
            forceRegion,
          ],
          preloadType: [
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
          ],
          preloadRole: [null, "agence", "agence", "region", "group"],
          limitUserType: [true, true, true, true, true, true],
          limitUserRole: [true, false, false, false, false, false],
          allUserRole: [true, true, true, true, true],
        },
        {
          icon: <FontAwesomeIcon icon={faCrosshairs} />,
          name: "Classement",
          path: "/company-ranking",
          exact: true,
          layout: DashboardLayout,
          component: CompanyRanking,
          notAllowedType: [0],
          notAllowedRole: [],
          visibilityType: [
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
          ],
          visibilityRole: [
            forceNothing,
            forceRegion,
            forceRegion,
            forceRegion,
            forceRegion,
          ],
          preloadType: [
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
          ],
          preloadRole: [null, "agence", "agence", "region", "group"],
          restrictNetwork: [null, 1],
          restrictGroup: [null, 1],
          limitUserType: [true, true, true, true, true, true],
          limitUserRole: [true, false, false, false, false, false],
          allUserRole: [true, true, true, true, true],
        },
      ],
    },
    {
      icon: <FontAwesomeIcon icon={faSearch} />,
      name: "Audit",
      notAllowedType: [0, 4, 5],
      notAllowedRole: [],
      views: [
        {
          icon: <FontAwesomeIcon icon={faWarehouse} />,
          name: "Interne technique",
          path: "/audit-technical",
          exact: true,
          layout: DashboardLayout,
          component: Technical,
          notAllowedType: [0, 4, 5],
          notAllowedRole: [0, 2],
          visibilityType: [
            forceNothing,
            forceNothing,
            forceNothing,
            forceNothing,
            forceNothing,
            forceNothing,
          ],
          visibilityRole: [
            forceNothing,
            forceAgency,
            forceNothing,
            forceRegion,
            forceRegion,
          ],
          preloadType: ["user", "user", "user", "user", "user", "user"],
          preloadRole: [null, "agence", "agence", "region", "group"],
          limitUserType: [true, true, true, true, true, true],
          limitUserRole: [true, false, false, false, false, false],
          allUserRole: [true, true, true, true, true],
        },
        {
          icon: <FontAwesomeIcon icon={faSearch} />,
          name: "Plan d'actions",
          path: "/result-plan-actions",
          exact: true,
          layout: DashboardLayout,
          component: ActionPlan,
          notAllowedType: [0, 4, 5],
          notAllowedRole: [],
          visibilityType: [
            forceNothing,
            forceNothing,
            forceAgency,
            forceAgency,
            forceNothing,
            forceNothing,
          ],
          visibilityRole: [
            forceNothing,
            forceAgency,
            forceNothing,
            forceRegion,
            forceRegion,
          ],
          preloadType: ["user", "user", "user", "user", "user", "user"],
          preloadRole: [null, "agence", "agence", "region", "group"],
          limitUserType: [true, false, false, false, true, true],
          limitUserRole: [true, false, false, false, false, false],
          allUserRole: [true, true, true, true, true],
        },
        {
          icon: <FontAwesomeIcon icon={faCrosshairs} />,
          name: "PrécisIA",
          path: "/precisia",
          exact: true,
          layout: DashboardLayout,
          component: Precisia,
          notAllowedType: [0],
          notAllowedRole: [],
          visibilityType: [
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
          ],
          visibilityRole: [
            forceNothing,
            fake ? forceGroup : forceNothing,
            fake ? forceGroup : forceNothing,
            forceRegion,
            forceRegion,
          ],
          preloadType: ["user", "user", "user", "user", "user", "user"],
          preloadRole: [null, "agence", "agence", "region", "group"],
          restrictNetwork: [null, 1],
          restrictGroup: [null, 1],
          limitUserType: [true, true, true, true, true, true],
          limitUserRole: [true, false, false, false, false, false],
          allUserRole: [false, false, false, false, false],
        },
      ],
    },
    {
      icon: <FontAwesomeIcon icon={faBug} />,
      name: "Anomalie",
      path: "/anomaly",
      exact: true,
      layout: DashboardLayout,
      component: Anomaly,
      notAllowedType: [0],
      notAllowedRole: [
        forceNothing,
        forceNothing,
        forceNothing,
        forceNothing,
        forceNothing,
        forceNothing,
      ],
      visibilityRole: [
        forceNothing,
        forceRegion,
        forceRegion,
        forceRegion,
        forceRegion,
      ],
      preloadType: ["user", "user", "user", "user", "agenusercy", "user"],
      preloadRole: [null, "agence", "agence", "region", "group"],
      limitUserType: [true, true, true, true, true, true],
      limitUserRole: [true, false, false, false, false, false],
      allUserRole: [false, false, false, false, false],
    },
    {
      icon: <FontAwesomeIcon icon={faPercentage} />,
      name: "Performance",
      notAllowedType: [0],
      notAllowedRole: [],
      views: [
        {
          icon: <FontAwesomeIcon icon={faChartBar} />,
          name: "Globale",
          path: "/global-performance",
          exact: true,
          layout: DashboardLayout,
          component: OverallPerformance,
          notAllowedType: [0, 1, 2, 3, 4, 5],
          notAllowedRole: [],
          visibilityType: [
            forceNothing,
            forceNothing,
            forceNothing,
            forceNothing,
            forceNothing,
            forceNothing,
          ],
          visibilityRole: [
            forceNothing,
            forceRegion,
            forceRegion,
            forceRegion,
            forceRegion,
          ],
          preloadType: [
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
          ],
          preloadRole: [null, "agence", "agence", "region", "group"],
          limitUserType: [true, true, true, true, true, true],
          limitUserRole: [true, false, false, false, false, false],
          allUserRole: [false, false, false, false, false],
        },
        {
          icon: <FontAwesomeIcon icon={faChartLine} />,
          name: "Tableau de bord EAD",
          path: "/EAD-dashboard",
          exact: true,
          layout: DashboardLayout,
          component: DashboardEAD,
          notAllowedType: [0, 1, 2, 4, 5],
          notAllowedRole: [],
          visibilityType: [
            forceNothing,
            forceNothing,
            forceNothing,
            forceRegion,
            forceNothing,
            forceNothing,
          ],
          visibilityRole: [
            forceNothing,
            forceGroup,
            forceGroup,
            forceGroup,
            forceGroup,
          ],
          preloadType: [
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
          ],
          preloadRole: [null, "agence", "agence", "region", "group"],
          restrictNetwork: [null, 1],
          limitUserType: [true, true, true, true, true, true],
          limitUserRole: [true, false, false, false, false, false],
          allUserRole: [false, false, false, false, false],
        },
        {
          icon: <FontAwesomeIcon icon={faMotorcycle} />,
          name: "2 Roues",
          path: "/bike",
          exact: true,
          layout: DashboardLayout,
          component: Bike,
          notAllowedType: [0],
          notAllowedRole: [],
          visibilityType: [
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
          ],
          visibilityRole: [
            forceNothing,
            fake ? forceGroup : forceNothing,
            fake ? forceGroup : forceNothing,
            forceRegion,
            forceRegion,
          ],
          preloadType: [
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
          ],
          preloadRole: [null, "agence", "agence", "region", "group"],
          restrictNetwork: [null, 1],
          restrictGroup: [null, 1],
          limitUserType: [true, true, true, true, true, true],
          limitUserRole: [true, false, false, false, false, false],
          allUserRole: [false, false, false, false, false],
        },
        {
          icon: <FontAwesomeIcon icon={faMobileAlt} />,
          name: "Selfcare",
          path: "/selfcare",
          exact: true,
          layout: DashboardLayout,
          component: Selfcare,
          notAllowedType: [0],
          notAllowedRole: [],
          visibilityType: [
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
          ],
          visibilityRole: [
            forceNothing,
            fake ? forceGroup : forceNothing,
            fake ? forceGroup : forceNothing,
            forceRegion,
            forceRegion,
          ],
          preloadType: [
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
          ],
          preloadRole: [null, "agence", "agence", "region", "group"],
          restrictNetwork: [null, 1],
          restrictGroup: [null, 1],
          limitUserType: [true, true, true, true, true, true],
          limitUserRole: [true, false, false, false, false, false],
          allUserRole: [false, false, false, false, false],
        },
        {
          icon: <FontAwesomeIcon icon={faTools} />,
          name: "Réparateurs",
          path: "/performance-reparateur",
          exact: true,
          layout: DashboardLayout,
          component: ReparatorPerformance,
          notAllowedType: [0],
          notAllowedRole: [],
          visibilityType: [
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
          ],
          visibilityRole: [
            forceNothing,
            fake ? forceGroup : forceNothing,
            fake ? forceGroup : forceNothing,
            forceRegion,
            forceRegion,
          ],
          preloadType: [
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
          ],
          preloadRole: [null, "agence", "agence", "region", "group"],
          restrictNetwork: [null, 1],
          limitUserType: [true, true, true, true, true, true],
          limitUserRole: [true, false, false, false, false, false],
          allUserRole: [false, false, false, false, false],
        },
        {
          icon: <FontAwesomeIcon icon={faDesktop} />,
          name: "Expert EAD",
          path: "/performance-expert-EAD",
          exact: true,
          layout: DashboardLayout,
          component: Performance,
          type: 2,
          notAllowedType: hiddenPages.ead
            ? [0, 1, 2, 3, 4, 5]
            : [0, 1, 2, 4, 5],
          notAllowedRole: hiddenPages.ead ? [1, 2] : [2],
          visibilityType: [
            forceNothing,
            forceNothing,
            forceNothing,
            forceAgency,
            forceNothing,
            forceNothing,
          ],
          visibilityRole: [
            forceNothing,
            forceNothing,
            forceNothing,
            forceRegion,
            forceRegion,
          ],
          preloadType: [
            "agency",
            "agency",
            "agency",
            "user",
            "agency",
            "agency",
          ],
          preloadRole: [null, "agence", "agence", "region", "group"],
          limitUserType: [true, true, true, true, true, true],
          limitUserRole: [true, false, false, false, false, false],
          allUserRole: [true, true, false, false, false],
        },
        {
          icon: <FontAwesomeIcon icon={faWarehouse} />,
          name: "Expert terrain",
          path: "/performance-expert-terrain",
          exact: true,
          layout: DashboardLayout,
          component: Performance,
          type: 1,
          notAllowedType: hiddenPages.expert
            ? [0, 1, 2, 3, 4, 5]
            : [0, 3, 4, 5],
          notAllowedRole: hiddenPages.expert ? [1, 2] : [2],
          visibilityType: [
            forceNothing,
            forceAgency,
            forceAgency,
            forceNothing,
            forceNothing,
            forceNothing,
          ],
          visibilityRole: [
            forceNothing,
            forceNothing,
            forceNothing,
            forceRegion,
            forceRegion,
          ],
          preloadType: [
            "agency",
            "agency",
            "user",
            "agency",
            "agency",
            "agency",
          ],
          preloadRole: [null, "agence", "agence", "region", "group"],
          limitUserType: [true, true, true, true, true, true],
          limitUserRole: [true, false, false, false, false, false],
          allUserRole: [true, true, false, false, false],
        },
        {
          icon: <FontAwesomeIcon icon={faHandsHelping} />,
          name: "Assistant(e)",
          path: "/performance-assistant",
          exact: true,
          layout: DashboardLayout,
          component: Performance,
          type: 3,
          notAllowedType: [0, 1, 2, 3],
          notAllowedRole: [1],
          visibilityType: [
            forceNothing,
            forceNothing,
            forceNothing,
            forceNothing,
            forceAgency,
            forceAgency,
          ],
          visibilityRole: [
            forceNothing,
            forceNothing,
            forceNothing,
            forceRegion,
            forceRegion,
          ],
          preloadType: [
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
            "user",
          ],
          preloadRole: [null, "agence", "agence", "region", "group"],
          limitUserType: [true, true, true, true, true, true],
          limitUserRole: [true, false, false, false, false, false],
          allUserRole: [true, false, true, false, false],
        },
      ],
    },
    {
      icon: <FontAwesomeIcon icon={faUser} />,
      name: "Entretien",
      notAllowedType: [0],
      notAllowedRole: [],
      views: [
        {
          icon: <FontAwesomeIcon icon={faDesktop} />,
          name: "Expert EAD",
          path: "/entretien-expert-EAD",
          exact: true,
          layout: DashboardLayout,
          component: Interview,
          type: 2,
          notAllowedType: hiddenPages.ead
            ? [0, 1, 2, 3, 4, 5]
            : [0, 1, 2, 4, 5],
          notAllowedRole: hiddenPages.ead ? [1, 2] : [2],
          visibilityType: [
            forceNothing,
            forceNothing,
            forceNothing,
            forceNothing,
            forceNothing,
            forceNothing,
          ],
          visibilityRole: [
            forceNothing,
            forceNothing,
            forceNothing,
            forceRegion,
            forceRegion,
          ],
          preloadType: [
            "agency",
            "agency",
            "agency",
            "user",
            "agency",
            "agency",
          ],
          preloadRole: [null, "agence", "agence", "region", "group"],
          limitUserType: [true, true, true, true, true, true],
          limitUserRole: [true, false, false, false, false, false],
          allUserRole: [false, false, false, false, false],
        },
        {
          icon: <FontAwesomeIcon icon={faWarehouse} />,
          name: "Expert terrain",
          path: "/entretien-expert",
          exact: true,
          layout: DashboardLayout,
          component: Interview,
          type: 1,
          notAllowedType: hiddenPages.expert
            ? [0, 1, 2, 3, 4, 5]
            : [0, 3, 4, 5],
          notAllowedRole: hiddenPages.expert ? [1, 2] : [2],
          visibilityType: [
            forceNothing,
            forceNothing,
            forceNothing,
            forceNothing,
            forceNothing,
            forceNothing,
          ],
          visibilityRole: [
            forceNothing,
            forceNothing,
            forceNothing,
            forceRegion,
            forceRegion,
          ],
          preloadType: [
            "agency",
            "agency",
            "user",
            "agency",
            "agency",
            "agency",
          ],
          preloadRole: [null, "agence", "agence", "region", "group"],
          limitUserType: [true, true, true, true, true, true],
          limitUserRole: [true, false, false, false, false, false],
          allUserRole: [false, false, false, false, false],
        },
        {
          icon: <FontAwesomeIcon icon={faHandsHelping} />,
          name: "Assistant(e)",
          path: "/entretien-assistant",
          exact: true,
          layout: DashboardLayout,
          component: Interview,
          type: 3,
          notAllowedType: [0, 1, 2, 3],
          notAllowedRole: [1],
          visibilityType: [
            forceNothing,
            forceNothing,
            forceNothing,
            forceNothing,
            forceNothing,
            forceNothing,
          ],
          visibilityRole: [
            forceNothing,
            forceNothing,
            forceNothing,
            forceRegion,
            forceRegion,
          ],
          preloadType: [
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
            "user",
          ],
          preloadRole: [null, "agence", "agence", "region", "group"],
          limitUserType: [true, true, true, true, true, true],
          limitUserRole: [true, false, false, false, false, false],
          allUserRole: [false, false, false, false, false],
        },
      ],
    },
    {
      icon: <FontAwesomeIcon icon={faUsers} />,
      name: "SIM1",
      notAllowedType: [0],
      notAllowedRole: [],
      views: [
        {
          icon: <FontAwesomeIcon icon={faDesktop} />,
          name: "Expert EAD",
          path: "/sim1-expert-EAD",
          exact: true,
          layout: DashboardLayout,
          component: Sim,
          type: 2,
          notAllowedType: hiddenPages.ead
            ? [0, 1, 2, 3, 4, 5]
            : [0, 1, 2, 4, 5],
          notAllowedRole: hiddenPages.ead ? [1, 2] : [2],
          visibilityType: [
            forceNothing,
            forceNothing,
            forceNothing,
            forceNothing,
            forceNothing,
            forceNothing,
          ],
          visibilityRole: [
            forceNothing,
            forceNothing,
            forceNothing,
            forceRegion,
            forceRegion,
          ],
          preloadType: [
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
          ],
          preloadRole: [null, "agence", "agence", "region", "group"],
          limitUserType: [true, true, true, true, true, true],
          limitUserRole: [true, false, false, false, false, false],
          allUserRole: [false, false, false, false, false],
        },
        {
          icon: <FontAwesomeIcon icon={faWarehouse} />,
          name: "Expert terrain",
          path: "/sim1-expert",
          exact: true,
          layout: DashboardLayout,
          component: Sim,
          type: 1,
          notAllowedType: hiddenPages.expert
            ? [0, 1, 2, 3, 4, 5]
            : [0, 3, 4, 5],
          notAllowedRole: hiddenPages.expert ? [1, 2] : [2],
          visibilityType: [
            forceNothing,
            forceNothing,
            forceNothing,
            forceNothing,
            forceNothing,
            forceNothing,
          ],
          visibilityRole: [
            forceNothing,
            forceNothing,
            forceNothing,
            forceRegion,
            forceRegion,
          ],
          preloadType: [
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
          ],
          preloadRole: [null, "agence", "agence", "region", "group"],
          limitUserType: [true, true, true, true, true, true],
          limitUserRole: [true, false, false, false, false, false],
          allUserRole: [false, false, false, false, false],
        },
        {
          icon: <FontAwesomeIcon icon={faHandsHelping} />,
          name: "Assistant(e)",
          path: "/sim1-assistant",
          exact: true,
          layout: DashboardLayout,
          component: Sim,
          type: 3,
          notAllowedType: [0, 1, 2, 3],
          notAllowedRole: [1],
          visibilityType: [
            forceNothing,
            forceNothing,
            forceNothing,
            forceNothing,
            forceNothing,
            forceAgency,
          ],
          visibilityRole: [
            forceNothing,
            forceNothing,
            forceNothing,
            forceRegion,
            forceRegion,
          ],
          preloadType: [
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
          ],
          preloadRole: [null, "agence", "agence", "region", "group"],
          limitUserType: [true, true, true, true, true, true],
          limitUserRole: [true, false, false, false, false, false],
          allUserRole: [false, false, false, false, false],
        },
      ],
    },
    {
      icon: <FontAwesomeIcon icon={faUserTag} />,
      name: "Spécialités",
      notAllowedType: [0],
      notAllowedRole: [],
      views: [
        {
          icon: <FontAwesomeIcon icon={faSearchLocation} />,
          name: "VVNR VVR",
          path: "/specialties/VVNR_VVR",
          exact: true,
          layout: DashboardLayout,
          component: VVNR_VVR,
          notAllowedType: [0],
          notAllowedRole: [],
          visibilityType: [
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
          ],
          visibilityRole: [
            forceNothing,
            fake ? forceGroup : forceNothing,
            fake ? forceGroup : forceNothing,
            forceRegion,
            forceRegion,
          ],
          preloadType: ["user", "user", "user", "user", "user", "user"],
          preloadRole: [null, "agence", "agence", "region", "group"],
          restrictNetwork: [null, 1],
          restrictGroup: [null, 1],
          limitUserType: [true, true, true, true, true, true],
          limitUserRole: [true, false, false, false, false, false],
          allUserRole: [false, false, false, false, false],
        },
        {
          icon: <FontAwesomeIcon icon={faCaravan} />,
          name: "Caravaning",
          path: "/specialties/caravaning",
          exact: true,
          layout: DashboardLayout,
          component: Caravaning,
          notAllowedType: [0],
          notAllowedRole: [],
          visibilityType: [
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
          ],
          visibilityRole: [
            forceNothing,
            fake ? forceGroup : forceNothing,
            fake ? forceGroup : forceNothing,
            forceRegion,
            forceRegion,
          ],
          preloadType: ["user", "user", "user", "user", "user", "user"],
          preloadRole: [null, "agence", "agence", "region", "group"],
          restrictNetwork: [null, 1],
          restrictGroup: [null, 1],
          limitUserType: [true, true, true, true, true, true],
          limitUserRole: [true, false, false, false, false, false],
          allUserRole: [false, false, false, false, false],
        },
        {
          icon: <FontAwesomeIcon icon={faBolt} />,
          name: "Électrique",
          path: "/specialties/electric",
          exact: true,
          layout: DashboardLayout,
          component: Electric,
          notAllowedType: [0],
          notAllowedRole: [],
          visibilityType: [
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
          ],
          visibilityRole: [
            forceNothing,
            fake ? forceGroup : forceNothing,
            fake ? forceGroup : forceNothing,
            forceRegion,
            forceRegion,
          ],
          preloadType: ["user", "user", "user", "user", "user", "user"],
          preloadRole: [null, "agence", "agence", "region", "group"],
          restrictNetwork: [null, 1],
          limitUserType: [true, true, true, true, true, true],
          limitUserRole: [true, false, false, false, false, false],
          allUserRole: [false, false, false, false, false],
        },
        {
          icon: <FontAwesomeIcon icon={faWindowClose} />,
          name: "Bris de glace",
          path: "/specialties/broken-glass",
          exact: true,
          layout: DashboardLayout,
          component: BrokenGlass,
          notAllowedType: [0],
          notAllowedRole: [],
          visibilityType: [
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
          ],
          visibilityRole: [
            forceNothing,
            fake ? forceGroup : forceNothing,
            fake ? forceGroup : forceNothing,
            forceRegion,
            forceRegion,
          ],
          preloadType: ["user", "user", "user", "user", "user", "user"],
          preloadRole: [null, "agence", "agence", "region", "group"],
          restrictNetwork: [null, 1],
          restrictGroup: [null, 1],
          limitUserType: [true, true, true, true, true, true],
          limitUserRole: [true, false, false, false, false, false],
          allUserRole: [false, false, false, false, false],
        },
        {
          icon: <FontAwesomeIcon icon={faExclamationTriangle} />,
          name: "Incohérence",
          path: "/specialties/inconsistency",
          exact: true,
          layout: DashboardLayout,
          component: Inconsistency,
          notAllowedType: [0],
          notAllowedRole: [],
          visibilityType: [
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
            forceAgency,
          ],
          visibilityRole: [
            forceNothing,
            fake ? forceGroup : forceNothing,
            fake ? forceGroup : forceNothing,
            forceRegion,
            forceRegion,
          ],
          preloadType: ["user", "user", "user", "user", "user", "user"],
          preloadRole: [null, "agence", "agence", "region", "group"],
          restrictNetwork: [null, 1],
          restrictGroup: [null, 1],
          limitUserType: [true, true, true, true, true, true],
          limitUserRole: [true, false, false, false, false, false],
          allUserRole: [false, false, false, false, false],
        },
      ],
    },
    {
      icon: <FontAwesomeIcon icon={faSnowflake} />,
      name: "EGA",
      path: "/EGA",
      exact: true,
      layout: DashboardLayout,
      component: EGA,
      notAllowedType: [0],
      notAllowedRole: [],
      visibilityType: [
        forceNothing,
        forceNothing,
        forceNothing,
        forceNothing,
        forceNothing,
        forceNothing,
      ],
      visibilityRole: [
        forceNothing,
        forceNothing,
        forceNothing,
        forceRegion,
        forceRegion,
      ],
      preloadType: ["agency", "agency", "agency", "agency", "agency", "agency"],
      preloadRole: [null, "agence", "agence", "region", "group"],
      limitUserType: [true, true, true, true, true, true],
      limitUserRole: [true, false, false, false, false, false],
      allUserRole: [false, false, false, false, false],
    },
    {
      icon: <FontAwesomeIcon icon={faLeaf} />,
      name: "Projet E",
      notAllowedType: [0],
      notAllowedRole: [],
      restrictNetwork: [null],
      limitUserType: [true, true, true, true, true, true],
      limitUserRole: [true, false, false, false, false, false],
      allUserRole: [false, false, false, false, false],
      views: [
        {
          icon: <FontAwesomeIcon icon={faLeaf} />,
          name: "Projet E",
          path: "/project-e",
          exact: true,
          layout: DashboardLayout,
          component: ProjectE,
          notAllowedType: [0],
          notAllowedRole: [],
          visibilityType: [
            forceNothing,
            forceNothing,
            forceNothing,
            forceNothing,
            forceNothing,
            forceNothing,
          ],
          visibilityRole: [
            forceNothing,
            forceNothing,
            forceNothing,
            forceRegion,
            forceRegion,
          ],
          preloadType: [
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
          ],
          preloadRole: [null, "agence", "agence", "region", "group"],
          restrictNetwork: [null],
          limitUserType: [true, true, true, true, true, true],
          limitUserRole: [true, false, false, false, false, false],
          allUserRole: [false, false, false, false, false],
        },
        {
          icon: <FontAwesomeIcon icon={faLeaf} />,
          name: "Analyse dossier",
          path: "/folder-project-e",
          exact: true,
          layout: DashboardLayout,
          component: FolderProjectE,
          notAllowedType: [0],
          notAllowedRole: [],
          visibilityType: [
            forceNothing,
            forceNothing,
            forceNothing,
            forceNothing,
            forceNothing,
            forceNothing,
          ],
          visibilityRole: [
            forceNothing,
            forceNothing,
            forceNothing,
            forceRegion,
            forceRegion,
          ],
          preloadType: [
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
            "agency",
          ],
          preloadRole: [null, "agence", "agence", "region", "group"],
          restrictNetwork: [null],
          limitUserType: [true, true, true, true, true, true],
          limitUserRole: [true, false, false, false, false, false],
          allUserRole: [false, false, false, false, false],
        },
      ],
    },
    {
      icon: <FontAwesomeIcon icon={faSearch} />,
      name: "Recherche",
      notAllowedType: [0],
      notAllowedRole: [],
      views: [
        {
          icon: <FontAwesomeIcon icon={faSearch} />,
          name: "Recherche dossier",
          path: "/search",
          exact: true,
          layout: DashboardLayout,
          component: Search,
          notAllowedType: [0],
          notAllowedRole: [],
          visibilityType: [
            forceGroup,
            forceGroup,
            forceGroup,
            forceGroup,
            forceGroup,
            forceGroup,
          ],
          visibilityRole: [
            forceGroup,
            forceGroup,
            forceGroup,
            forceGroup,
            forceGroup,
          ],
          preloadType: [null, null, null, null, null, null],
          preloadRole: [null, null, null, null, null],
          restrictNetwork: [null, 1],
          limitUserType: [false, false, false, false, false, false],
          limitUserRole: [false, false, false, false, false, false],
          allUserRole: [false, false, false, false, false],
        },
        {
          icon: <FontAwesomeIcon icon={faEuroSign} />,
          name: "Coût concurrentiel",
          path: "/competitive-cost",
          exact: true,
          layout: DashboardLayout,
          component: CompetitiveCost,
          notAllowedType: [0],
          notAllowedRole: [],
          visibilityType: [
            forceGroup,
            forceGroup,
            forceGroup,
            forceGroup,
            forceGroup,
            forceGroup,
          ],
          visibilityRole: [
            forceGroup,
            forceGroup,
            forceGroup,
            forceGroup,
            forceGroup,
          ],
          preloadType: [null, null, null, null, null, null],
          preloadRole: [null, null, null, null, null],
          restrictNetwork: [null, 1],
          limitUserType: [false, false, false, false, false, false],
          limitUserRole: [false, false, false, false, false, false],
          allUserRole: [false, false, false, false, false],
        },
      ],
    },
    {
      icon: <FontAwesomeIcon icon={faInfoCircle} />,
      isRed: true,
      name: "Mises à jour",
      path: "/update",
      exact: true,
      layout: DashboardLayout,
      component: Update,
      notAllowedType: [0],
      notAllowedRole: [],
      visibilityType: [
        forceNothing,
        forceNothing,
        forceNothing,
        forceNothing,
        forceNothing,
        forceNothing,
      ],
      visibilityRole: [
        forceNothing,
        forceNothing,
        forceNothing,
        forceRegion,
        forceRegion,
      ],
      preloadType: [null, null, null, null, null, null],
      preloadRole: [null, null, null, null, null],
      limitUserType: [true, true, true, true, true, true],
      limitUserRole: [true, false, false, false, false, false],
      allUserRole: [false, false, false, false, false],
    },
    {
      icon: <FontAwesomeIcon icon={faPhone} />,
      name: "Support",
      path: "/support",
      exact: true,
      layout: DashboardLayout,
      component: Ideas,
      notAllowedType: [],
      notAllowedRole: [],
      visibilityType: [
        forceNothing,
        forceGroup,
        forceGroup,
        forceGroup,
        forceGroup,
        forceGroup,
      ],
      visibilityRole: [
        forceNothing,
        forceGroup,
        forceGroup,
        forceGroup,
        forceGroup,
      ],
      preloadType: [null, null, null, null, null, null],
      preloadRole: [null, null, null, null, null],
      limitUserType: [true, true, true, true, true, true],
      limitUserRole: [true, false, false, false, false, false],
      allUserRole: [false, false, false, false, false],
    },
    {
      icon: <FontAwesomeIcon icon={faRoad} />,
      name: "Où est mon expert",
      path: "/tracking",
      exact: true,
      layout: DashboardLayout,
      component: Tracking,
      notAllowedType: [0, 1, 2, 3, 4, 5],
      notAllowedRole: [0, 1, 2, 3],
      visibilityType: [
        forceAgency,
        forceAgency,
        forceAgency,
        forceAgency,
        forceAgency,
        forceAgency,
      ],
      visibilityRole: [
        forceNothing,
        fake ? forceGroup : forceNothing,
        fake ? forceGroup : forceNothing,
        forceRegion,
        forceRegion,
      ],
      preloadType: ["agency", "agency", "agency", "agency", "agency", "agency"],
      preloadRole: [null, "agence", "agence", "region", "group"],
      restrictNetwork: [null],
      limitUserType: [true, true, true, true, true, true],
      limitUserRole: [true, false, false, false, false, false],
      allUserRole: [false, false, false, false, false],
    },
  ];

  let routes = [];
  let sidebar = [];

  views.map((elem, idx) => {
    if (
      check(
        type,
        role,
        network,
        group,
        elem.notAllowedType,
        elem.notAllowedRole,
        elem.restrictNetwork,
        elem.restrictGroup,
        elem.name
      )
    ) {
      if (elem.path) {
        if (elem.name) {
          sidebar.push({
            icon: elem.icon,
            name: elem.name,
            path: elem.path,
            isRed: elem.isRed,
          });
        }

        // if agency and fake agency replace by region
        if (elem.preloadRole && elem.preloadRole[2] === "agency" && fake) {
          elem.preloadRole[2] = "region";
        }
        routes.push({
          path: elem.path,
          exact: elem.exact,
          layout: elem.layout,
          component: elem.component,
          visibilityType: elem.visibilityType,
          visibilityRole: elem.visibilityRole,
          preloadType: elem.preloadType,
          preloadRole: elem.preloadRole,
          limitUserType: elem.limitUserType,
          limitUserRole: elem.limitUserRole,
          allUserRole: elem.allUserRole,
          type: elem.type,
        });
      } else {
        sidebar.push({
          icon: elem.icon,
          name: elem.name,
          views: [],
          isRed: elem.isRed,
        });

        elem.views.map((subElem) => {
          if (
            check(
              type,
              role,
              network,
              group,
              subElem.notAllowedType,
              subElem.notAllowedRole,
              subElem.restrictNetwork,
              subElem.restrictGroup,
              subElem.name
            )
          ) {
            sidebar[getId(elem.name, sidebar)].views.push({
              icon: subElem.icon,
              name: subElem.name,
              path: subElem.path,
            });

            // if agency and fake agency replace by region
            if (
              subElem.preloadRole &&
              subElem.preloadRole[2] === "agency" &&
              fake
            ) {
              subElem.preloadRole[2] = "region";
            }

            routes.push({
              path: subElem.path,
              exact: subElem.exact,
              layout: subElem.layout,
              component: subElem.component,
              visibilityType: subElem.visibilityType,
              visibilityRole: subElem.visibilityRole,
              preloadType: subElem.preloadType,
              preloadRole: subElem.preloadRole,
              limitUserType: subElem.limitUserType,
              limitUserRole: subElem.limitUserRole,
              allUserRole: subElem.allUserRole,
              type: subElem.type,
            });
          }
          return null;
        });
      }
    }
    return null;
  });

  return { sidebar, routes };
};

const getId = (name, sidebar) => {
  let id = null;

  sidebar.map((elem, idx) => {
    if (name === elem.name) {
      id = idx;
    }
    return null;
  });

  return id;
};

const check = (
  type,
  role,
  network,
  group,
  notAllowedType,
  notAllowedRole,
  restrictNetwork,
  restrictGroup,
  name
) => {
  if (
    (!network || !restrictNetwork || restrictNetwork.includes(network)) &&
    (!network || !restrictGroup || restrictGroup.includes(group)) &&
    !notAllowedType.includes(type)
  ) {
    if (notAllowedRole.includes(role)) {
      return false;
    } else {
      return true;
    }
  } else {
    return true
  }
};

export default getRoutesSidebar;
