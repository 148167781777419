import React from "react";
import {
  Card,
  Table,
  Tbody,
  Thead,
  Tr,
  Th,
  Td,
  Status,
  ButtonIcon,
  Pagination,
  FormInput,
  Button,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
  faSearch,
  faPen,
  faStream,
} from "@fortawesome/free-solid-svg-icons";

import ModalDetails from "./ModalDetails";
import ModalAnalyse from "./ModalAnalyse";

import handleSortClick from "../../utils/handleSortClickV2";

import AuthContext from "../../context/AuthContext";

export default class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalData: false,
      modalDataIdx: null,
      isModalAnalyse: false,
      modalAnalyseIdx: null,
      reference: "",
    };
    this.sortRef = [];
  }

  static contextType = AuthContext;

  getColor = (value) => {
    if (value > 18) {
      return "bg-green-light";
    } else if (value < 10) {
      return "bg-red-light";
    } else {
      return "";
    }
  };

  handleSortClick = (desc, column) => {
    this.setState(
      { order: handleSortClick(this.sortRef, desc, column) },
      () => {
        this.props.handleSortClick(this.state.order);
      }
    );
  };

  getNPS = (nps) => {
    if (nps >= 9) {
      return (
        <Status
          className="bg-green-light black w-100 nowrap"
          text="Promoteur"
        />
      );
    } else if (nps >= 7) {
      return (
        <Status className="bg-orange-light black w-100 nowrap" text="Passif" />
      );
    } else if (nps >= 0 && nps !== null) {
      return (
        <Status className="bg-red-light black w-100 nowrap" text="Détracteur" />
      );
    } else {
      return null;
    }
  };

  render() {
    const tableHead = [
      { name: "Date", key: "date", isSort: true },
      {
        name: "Référence",
        key: "reference",
        isSort: true,
      },
      { name: "Agence", key: "agency", isSort: true },
      { name: "Expert", key: "expert", isSort: true },
      {
        name: "Commentaire",
        key: "comment",
        isMultiLine: true,
        minWidth: "360px",
      },
      {
        name: "Réparable",
        key: "reparable",
      },
      { name: "Note", key: "value", isSort: true },
      { name: "NPS", key: "nps", format: this.getNPS, isSort: true },
      { name: "Analyse", key: "analyse", isMultiLine: true, minWidth: "360px" },
    ];

    return (
      <>
        <Card
          className={this.props.className}
          classNameChildren="overflow-x-auto"
          title="Liste des réponses"
          action={<FontAwesomeIcon icon={faStream} />}
          onClick={this.props.onListAll}
        >
          <div className="d-flex">
            <FormInput
              style={{ width: "200px" }}
              className={"mb-10"}
              placeholder="Référence"
              title="Référence"
              name="reference"
              value={this.state.reference}
              onChange={({ target }) =>
                this.setState({
                  reference: target.value,
                })
              }
            />
            <Button
              className="ml-10 mb-10 mt-auto w-auto"
              text="Rechercher"
              onClick={() => this.props.initSearch(this.state.reference)}
            >
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </div>
          <Table>
            <Thead>
              <Tr>
                {tableHead.map((element, idx) => (
                  <Th
                    className="pl-10 pr-10"
                    key={idx}
                    ref={(ref) => (this.sortRef[idx] = ref)}
                    sort={element.isSort}
                    onSort={
                      element.isSort
                        ? (desc) => this.handleSortClick(desc, idx)
                        : null
                    }
                    nameSort={element.key}
                  >
                    {element.name}
                  </Th>
                ))}
                <Th className="pl-10 pr-10">Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {this.props.listData.map((element, idx) => (
                <Tr key={idx}>
                  {tableHead.map((elementBody, idxBody) => (
                    <Td
                      style={
                        elementBody.minWidth
                          ? { minWidth: elementBody.minWidth }
                          : {}
                      }
                      className={`pl-10 pr-10 ${
                        elementBody.isMultiLine ? "" : "nowrap"
                      } ${this.getColor(element.value)}`}
                      key={`${idx}-${idxBody}`}
                    >
                      {elementBody.format
                        ? elementBody.format(element[elementBody.key])
                        : element[elementBody.key]}
                    </Td>
                  ))}
                  <Td
                    className={`pl-10 pr-10 nowrap ${this.getColor(
                      element.value
                    )}`}
                  >
                    <ButtonIcon
                      className={"blue"}
                      onClick={() =>
                        this.setState({ isModalData: true, modalDataIdx: idx })
                      }
                      small
                    >
                      <FontAwesomeIcon icon={faSearch} />
                    </ButtonIcon>
                    {this.context.role === 1 ||
                    this.context.role === 2 ||
                    this.context.role === 4 ? (
                      <ButtonIcon
                        className={"blue"}
                        onClick={() =>
                          this.setState({
                            isModalAnalyse: true,
                            modalAnalyseIdx: idx,
                          })
                        }
                        small
                      >
                        <FontAwesomeIcon icon={faPen} />
                      </ButtonIcon>
                    ) : null}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          {this.props.isAll ? null : (
            <Pagination
              activePage={this.props.activePage}
              itemsCountPerPage={this.props.tabLength}
              totalItemsCount={this.props.total}
              pageRangeDisplayed={4}
              onChange={this.props.handlePageChange.bind(this)}
              firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
              lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
              prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
              nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
            />
          )}
        </Card>
        <ModalDetails
          show={this.state.isModalData}
          onClose={() =>
            this.setState({
              isModalData: false,
              modalDataIdx: null,
            })
          }
          data={
            this.state.modalDataIdx !== null
              ? this.props.listData[this.state.modalDataIdx]
              : {}
          }
        />
        <ModalAnalyse
          show={this.state.isModalAnalyse}
          onClose={() =>
            this.setState({
              isModalAnalyse: false,
              modalAnalyseIdx: null,
            })
          }
          data={this.props.listData}
          idx={this.state.modalAnalyseIdx}
        />
      </>
    );
  }
}
