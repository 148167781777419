import React, { Component } from "react";
import {
  Card,
  FormInput,
  ButtonIcon,
  Modal,
  Loader,
} from "ui-kit-ck-consultant";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import MapGarage from "./MapGarage";

import axiosConfig from "../../../utils/axiosConfig";

export default class GarageSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      folderReference: null,
      immatReference: null,
      sinisterReference: null,
      garages: [],
      folder: {},
      isOpenModal: false,
    };
  }

  handleChangeSearch = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleClickSearch = () => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("planning/tour/get-folder-location", {
        data: {
          reference: this.state.folderSearch,
          licensePlate: this.state.immatSearch,
          sinister: this.state.sinisterSearch,
        },
      }).then((res) => {
        if (res.data.success) {
          this.setState({
            isLoad: false,
            garages: res.data.garages,
            folder: res.data.data,
            isOpenModal: true,
          });
        } else {
          this.setState(
            {
              isLoad: false,
              garages: [],
              folder: {},
              isOpenModal: false,
            },
            () => {
              alert("Aucun réparateur trouvé");
            }
          );
        }
      });
    });
  };

  onCloseModal() {
    this.setState({ isOpenModal: !this.state.isOpenModal });
  }

  render() {
    return (
      <Card
        title={`Rechercher un garage agrée`}
        className={this.props.className}
        classNameChildren="overflow-x-auto"
      >
        <Loader show={this.state.isLoad} />
        <div className={"m-20 d-flex"}>
          <FormInput
            className={"m-10"}
            name={"folderSearch"}
            placeholder={"Nº de dossier"}
            onChange={this.handleChangeSearch}
          />
          <FormInput
            className={"m-10"}
            name={"immatSearch"}
            placeholder={"Nº d'immatriculation"}
            onChange={this.handleChangeSearch}
          />
          <FormInput
            className={"m-10"}
            name={"sinisterSearch"}
            placeholder={"Nº de sinistre"}
            onChange={this.handleChangeSearch}
          />
          <ButtonIcon onClick={this.handleClickSearch} className={"m-10"}>
            <FontAwesomeIcon color={"#6067AE"} icon={faSearch} />
          </ButtonIcon>
        </div>
        <Modal
          title={`Carte des garages agréés ${this.state.folder.companyName}`}
          large
          show={this.state.isOpenModal}
          onClose={(e) => this.onCloseModal(e)}
        >
          {this.state.garages.length ? (
            <MapGarage
              folder={this.state.folder}
              garages={this.state.garages}
              defaultZoom={this.state.garages.length ? 10 : 6}
              defaultLat={this.state.folder.latitudeAgency}
              defaultLong={this.state.folder.longitudeAgency}
            />
          ) : null}
        </Modal>
      </Card>
    );
  }
}
