import React from "react";
import { PieChart } from "ui-kit-ck-consultant";

import { colors } from "../../../utils/colors";

export default class PieChartSatisfaction extends React.Component {
  render() {
    if (this.props.data) {
      return (
        <PieChart
          className="m-auto"
          digits={0}
          title={this.props.title}
          legendDisplay={true}
          data={
            this.props.star
              ? [
                  {
                    data: this.props.data[0],
                    labels: "1 étoile",
                    color: colors.red[0],
                  },
                  {
                    data: this.props.data[1],
                    labels: "2 étoiles",
                    color: colors.orange[0],
                  },
                  {
                    data: this.props.data[2],
                    labels: "3 étoiles",
                    color: colors.yellow[0],
                  },
                  {
                    data: this.props.data[3],
                    labels: "4 étoiles",
                    color: colors.green[1],
                  },
                  {
                    data: this.props.data[4],
                    labels: "5 étoiles",
                    color: colors.green[0],
                  },
                ]
              : [
                  {
                    data: this.props.data[0],
                    labels: "Mauvais",
                    color: colors.red[0],
                  },
                  {
                    data: this.props.data[1],
                    labels: "Moyen",
                    color: colors.orange[0],
                  },
                  {
                    data: this.props.data[2],
                    labels: "Bien",
                    color: colors.yellow[0],
                  },
                  {
                    data: this.props.data[3],
                    labels: "Très bien",
                    color: colors.green[0],
                  },
                ]
          }
        />
      );
    } else {
      return <></>;
    }
  }
}
