import React from "react";
import {
  Nav,
  Loader,
  TabsCustom,
  TabsCustomBody,
  TabsCustomHeader,
  TabsCustomItem,
  TabsCustomMenu,
} from "ui-kit-ck-consultant";
import moment from "moment";

import ShowOptionsNGRA from "../../../components/general/ShowOptionsNGRA-v2";
import Main from "../../../components/quality/management/Main";
import List from "../../../components/quality/management/List";

import axiosConfig from "../../../utils/axiosConfig";

export default class Management extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: false,
      isLoadSuivi: false,
      data: {},
      dataSuivi: [],
      network: null,
      group: null,
      region: null,
      agency: null,
      dateStart: null,
      dateEnd: null,
      companyMultiples: [],
      tabsIndex: 0,
      tabsMenu: [
        "Dossiers à traiter en retard",
        `Actions, Messages et SD à traiter en retard (Mise à jour ${moment()
          .subtract(1, "days")
          .format("DD/MM/YYYY 23:00:00")})`,
      ],
    };
  }

  getData = () => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("quality/management/get-data", {
        data: this.getFilters(),
      })
        .then((res) => {
          if (res.data.success) {
            this.setState({
              data: res.data.data,
              isLoad: false,
            });
          } else {
            this.setState({ isLoad: false, data: {} }, () => {
              alert("Une erreur est survenue");
            });
          }
        })
        .catch(() => {
          this.setState({ isLoad: false, data: {} }, () => {
            alert("Une erreur est survenue");
          });
        });
    });
  };

  getDataSuivi = () => {
    this.setState({ isLoadSuivi: true }, () => {
      axiosConfig("quality/management/get-data-suivi", {
        data: this.getFilters(),
      })
        .then((res) => {
          if (res.data.success) {
            this.setState({
              dataSuivi: res.data.data,
              isLoadSuivi: false,
            });
          } else {
            this.setState({ isLoadSuivi: false, dataSuivi: [] }, () => {
              alert("Une erreur est survenue");
            });
          }
        })
        .catch(() => {
          this.setState({ isLoadSuivi: false, dataSuivi: [] }, () => {
            alert("Une erreur est survenue");
          });
        });
    });
  };

  getFilters = () => {
    return {
      network: this.state.network,
      group: this.state.group,
      region: this.state.region,
      agency: this.state.agency,
      dateStart: this.state.dateStart,
      dateEnd: this.state.dateEnd,
      companies: this.state.companyMultiples,
    };
  };

  handleDataChange = (data) => {
    this.setState(
      {
        network: data.network,
        group: data.group,
        region: data.region,
        agency: data.agency,
        dateStart: data.dateStart,
        dateEnd: data.dateEnd,
        companyMultiples: data.companyMultiples,
      },
      () => {
        if (this.state.tabsIndex === 0) {
          this.getData();
        } else {
          this.getDataSuivi();
        }
      }
    );
  };

  render() {
    return (
      <>
        <Nav className="mb-10 mb-20-xl" title="Management" />
        <ShowOptionsNGRA
          isChange={true}
          className="mb-10 mb-20-xl"
          onDataChange={this.handleDataChange}
          visibilityRole={this.props.visibilityRole}
          visibilityType={this.props.visibilityType}
          preloadRole={this.props.preloadRole}
          preloadType={this.props.preloadType}
          limitUserType={this.props.limitUserType}
          limitUserRole={this.props.limitUserRole}
          isCompanyMultiples={true}
          isDateStart={true}
          isDateEnd={true}
        />

        <TabsCustom>
          <TabsCustomHeader>
            {this.state.tabsMenu.map((element, idx) => (
              <TabsCustomMenu
                key={idx}
                title={element}
                active={this.state.tabsIndex === idx}
                onClick={() =>
                  this.setState({
                    tabsIndex: idx,
                  })
                }
              />
            ))}
          </TabsCustomHeader>
          <TabsCustomBody noCard>
            <TabsCustomItem active={this.state.tabsIndex === 0}>
              <Main data={this.state.data} />
            </TabsCustomItem>
            <TabsCustomItem active={this.state.tabsIndex === 1}>
              <List data={this.state.dataSuivi} />
            </TabsCustomItem>
          </TabsCustomBody>
        </TabsCustom>
        <Loader
          show={
            this.state.tabsIndex === 0
              ? this.state.isLoad
              : this.state.isLoadSuivi
          }
        />
      </>
    );
  }
}
