import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import { BarChart, Card } from "ui-kit-ck-consultant";

export default class CardEvolution extends Component {
  render() {
    function getMax(data) {
      let max = 0
      data.map(value => {
        if (value && parseInt(value) > max) {
          max = parseInt(value)
        }
        return true
      })
      return max
    }

    let max1Line = 0
    let max1Bar = 0
    this.props.data.data.map((label, id) => {
      if (id === 0) max1Line = getMax(label.data)
      else max1Bar = getMax(label.data)
      return label
    })
    let max2Line = 0
    let max2Bar = 0
    this.props.dataTwo.data.map((label, id) => {
      if (id === 0) max2Line = getMax(label.data)
      else max2Bar += getMax(label.data)
      return label
    })

    let max3Line = 0
    let max3Bar = 0
    this.props.dataThree.data.map((label, id) => {
      if (id === 0) max3Line = getMax(label.data)
      else max3Bar += getMax(label.data)
      return label
    })


    return (
      <div>
        <Row>
          <Col xs={12} md={this.props.dataThree ? 6 : 12}>
            <Card className={"min-tab-size-md mt-10"}>
              <h3>{this.props.data.title}</h3>
              <BarChart
                digits={0}
                widthBar={this.props.dataThree ? 20 : 40}
                notAlign={this.props.data.notAlign === true ? true : false}
                isLegend={this.props.data.legendDisplay}
                dataSet={this.props.data.data}
                xAxes={[
                  {
                    stacked: true,
                  }
                ]}
                yAxes={[
                  {
                    id: 'A',
                    stacked: true,
                    ticks: {
                      display: false,
                      suggestedMax: max1Line + ((max1Line / 100) * 30),
                      min: 0,
                      beginAtZero: true
                    },
                  },
                  {
                    id: 'B',
                    stacked: true,
                    ticks: {
                      display: false,
                      suggestedMax: max1Bar + ((max1Bar / 100) * 30),
                      beginAtZero: true
                    }
                  }
                ]}
                datalabels2={this.props.isPrint ? [
                  {
                    display: true,
                    align: 'top',
                    anchor: 'end',
                    clamp: true,
                    color: "#db2828",
                    backgroundColor: 'white',
                    borderColor:"#db2828",
                    borderWidth: 1,
                    borderRadius: 50,
                    formatter: (value) => Math.round(value)
                  },
                  {
                    display: true,
                    align: 'center',
                    anchor: 'center',
                    clamp: true,
                    color: '#0162AD',
                    backgroundColor: 'rgba(255, 255, 255, 0.5',
                    borderColor:'#0162AD',
                    borderWidth: 1,
                    borderRadius: 50,
                    formatter: (value) => Math.round(value)
                  },
                  {
                    display: true,
                    align: 'bottom',
                    anchor: 'start',
                    clamp: true,
                    color: '#0162AD',
                    
                    formatter: (value) => Math.round(value)
                  }] : null}
              />
            </Card>
          </Col>

          <Col xs={12} md={this.props.dataThree ? 6 : 12}>

            <Card className={"min-tab-size-md mt-10"}>
              <h3>{this.props.dataTwo.title}</h3>
              <BarChart
                digits={0}
                widthBar={this.props.dataThree ? 20 : 40}
                notAlign={this.props.dataTwo.notAlign === true ? true : false}
                legendDisplay={this.props.dataTwo.legendDisplay}
                dataSet={this.props.dataTwo.data}
                yAxes={[
                  {
                    id: 'A',
                    stacked: true,
                    ticks: {
                      display: false,
                      suggestedMax: max2Line + ((max2Line / 100) * 30),
                      min: 0,
                      beginAtZero: true
                    },
                  },
                  {
                    id: 'B',
                    display: false,
                    stacked: true,
                    ticks: {
                      suggestedMax: max2Bar + ((max2Bar / 100) * 30),
                      beginAtZero: true
                    }
                  }
                ]}
                xAxes={[
                  {
                    stacked: true,
                  },
                ]}
                datalabels2={this.props.isPrint ? [
                  {
                    display: true,
                    align: 'top',
                    anchor: 'end',
                    clamp: true,
                    color: "#db2828",
                    backgroundColor: 'white',
                    borderColor:"#db2828",
                    borderWidth: 1,
                    borderRadius: 50,
                    formatter: (value) => Math.round(value)
                  },
                  {
                    display: true,
                    align: 'center',
                    anchor: 'center',
                    clamp: true,
                    color: "#0c8540",
                    backgroundColor: 'rgba(255, 255, 255, 0.5',
                    borderColor:"#0c8540",
                    borderWidth: 1,
                    borderRadius: 50,
                    formatter: (value) => Math.round(value)
                  },
                  {
                    display: true,
                    align: 'center',
                    anchor: 'center',
                    clamp: true,
                    color: "#026fc2",
                    backgroundColor: 'rgba(255, 255, 255, 0.5',
                    borderColor:"#026fc2",
                    borderWidth: 1,
                    borderRadius: 50,
                    formatter: (value) => Math.round(value)
                  }] : null}
              />
            </Card>
          </Col>

          {this.props.dataThree && (
            <Col xs={12} md={this.props.dataFour ? 6 : 12}>

              <Card className={"min-tab-size-md mt-10"}>
                <h3>{this.props.dataThree.title}</h3>
                <BarChart
                  digits={0}
                  widthBar={this.props.dataFour ? 20 : 40}
                  notAlign={this.props.dataThree.notAlign ? true : false}
                  legendDisplay={this.props.dataThree.legendDisplay}
                  dataSet={this.props.dataThree.data}
                  yAxes={[
                    {
                      id: 'A',
                      display: false,
                      stacked: true,
                      ticks: {
                        suggestedMax: max3Line + ((max3Line / 100) * 30),
                        min: 0,
                        beginAtZero: true
                      },
                    },
                    {
                      id: 'B',
                      display: false,
                      stacked: true,
                      ticks: {
                        suggestedMax: max3Bar + ((max3Bar / 100) * 30),
                        beginAtZero: true
                      }
                    }
                  ]}
                  xAxes={[
                    {
                      stacked: true,
                    },
                  ]}
                  datalabels2={this.props.isPrint ? [
                    {
                      display: true,
                      align: 'top',
                      anchor: 'end',
                      clamp: true,
                      color: "#db2828",
                      backgroundColor: 'white',
                      borderColor:"#db2828",
                      borderWidth: 1,
                      borderRadius: 50,
                      formatter: (value) => Math.round(value)
                    },
                    {
                      display: true,
                      align: 'center',
                      anchor: 'center',
                      clamp: true,
                      color: "#026fc2",
                      backgroundColor: 'rgba(255, 255, 255, 0.5',
                      borderColor:"#026fc2",
                      borderWidth: 1,
                      borderRadius: 50,
                      formatter: (value) => Math.round(value)
                    },
                    {
                      display: true,
                      align: 'center',
                      anchor: 'center',
                      clamp: true,
                      color: "#0c8540",
                      backgroundColor: 'rgba(255, 255, 255, 0.5',
                      borderColor:"#0c8540",
                      borderWidth: 1,
                      borderRadius: 50,
                      formatter: (value) => Math.round(value)
                    }] : null}
                />
              </Card>
            </Col>
          )}
          {this.props.dataFour && (
            <Col xs={12} md={6}>

              <Card className={"min-tab-size-md mt-10"}>
                <h3>{this.props.dataFour.title}</h3>
                <BarChart
                  digits={0}
                  notAlign={
                    this.props.dataFour.notAlign === true ? true : false
                  }
                  legendDisplay={this.props.dataFour.legendDisplay}
                  dataSet={this.props.dataFour.data}
                  yAxes={[
                    {
                      id: 'A',
                      display: false,
                      ticks: {
                        suggestedMax: 100,
                        min: 0
                      },
                      gridLines: {
                        drawBorder: false,
                      },
                    },
                    {
                      id: 'B',
                      display: false,
                      ticks: {
                        beginAtZero: true
                      }
                    }
                  ]}
                />
              </Card>
            </Col>
          )}

        </Row>
      </div>
    );
  }
}
