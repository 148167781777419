import React, { Component } from "react";
import { BarChart } from "ui-kit-ck-consultant";

export class BarChartMonthlyEfficiency extends Component {
  render() {
    return (
      <BarChart
        height={this.props.height}
        title={"Rendement Mois / Mois"}
        digits={0}
        legendDisplay={false}
        dataSet={[
          {
            label: "Rendement (en %)",
            data: this.props.data.rendementByMonth,
            type: "line",
            color: "#6067AE",
            yAxisID: "A",
          },
          {
            label: "Dossiers reçus",
            data: this.props.data.dossiersRecusByMonths,
            type: "bar",
            color: "#E68A00",
            yAxisID: "B",
          },
          {
            label: "Dossiers déposés",
            data: this.props.data.dossiersDeposesByMonth,
            type: "bar",
            color: "#51AE50",
            yAxisID: "B",
          },
        ]}
        datalabels2={[
          {
            display: true,
            align: "bottom",
            anchor: "end",
            clamp: true,
            color: "#0162AD",
            font: {
              size: "10",
            },
            formatter: (value) => Math.round(value) + "%",
          },
        ]}
        yAxes={[
          {
            id: "A",
            display: false,
            dataLabelsOptions: { display: true },
            ticks: {
              beginAtZero: true,
            },
          },
          {
            id: "B",
            display: false,
            ticks: {
              beginAtZero: true,
            },
          },
        ]}
      />
    );
  }
}

export default BarChartMonthlyEfficiency;
