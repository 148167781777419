import * as React from "react";

function SvgComponent(props) {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 750 750" 
      width={props.width ? props.width : '100'}
      {...props}
    >
      <circle
        cx={550.9 - 200}
        cy={333.5}
        r={178.5}
        fill={props.color ? props.color : "#000"}
        strokeMiterlimit={10}
      />
      <circle
        cx={550.9 - 200}
        cy={333.5}
        r={84.8}
        fill="rgba(255, 255, 255, 0.9)"
        stroke="rgba(255, 255, 255, 0.9)"
        strokeMiterlimit={10}
      />
      <path
        fill={props.color ? props.color : "#000"}
        strokeMiterlimit={10}
        d="M 351.3 685.5
          l-72-124.5-72-124.4 143.7-.1 143.8-.2
          L 423 560.9
          z"
      />
      <circle
        cx={705.9 - 200}
        cy={217.7}
        r={130}
        fill="#ed1c24"
        stroke="#ffffff"
        strokeMiterlimit={10}
        style={{
          display:'flex',
          justifyContent:'center',
          alignItems: 'center'
        }}
      />
      <text
        transform="translate(445.192 270.817)"
        fontSize={200}
        fontFamily="MyriadPro-Regular"
        fill="#fff"
      >
        {props.nb && props.nb > 9 ? '+' : props.nb}
      </text>
    </svg>
  );
}

export default SvgComponent;

