import React from "react";

export default class Corse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <g transform="translate(500, 500)">
        <path
          fill={this.props.fill || "none"}
          stroke="#232323"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M2.73775 38.147L3.0469 37.3335L5.01397 35.908L5.33849 35.6729L5.17961 35.305L4.65919 34.0998L4.99736 31.4983L6.0598 31.0637L6.31739 30.9583L6.36356 30.6838L6.72893 28.5118L7.00109 28.8349L7.13243 28.9909L7.33539 29.0106L9.21155 29.1921L9.59765 29.2295L9.7298 28.8648L10.441 26.9018L14.022 23.8652L18.7951 22.9518L19.1103 22.8914L19.1868 22.5798L19.9378 19.5178L21.7417 17.8466H23.6606L24.1079 18.1526L24.2537 18.2524L24.4299 18.2384L26.8131 18.0488L28.1614 19.9095L28.3986 20.2369L28.7684 20.0735L30.0696 19.4985L30.2407 19.4229L30.3202 19.2535L31.4701 16.8024L31.5174 16.7015V16.59V13.9573V13.7354L31.3528 13.5865L29.611 12.0106V10.7239L30.6393 9.53313L30.8571 9.28086L30.7081 8.98272L30.136 7.83864L31.5035 6.78863L31.9065 6.4792L31.5906 6.08123L30.151 4.26736L29.8511 2.83698L32.1619 1.28826L33.4859 2.02117L34.1519 2.99453L35.0688 7.04711L35.0724 7.063L35.0771 7.07862L36.5438 12.036L35.449 15.0898L35.4148 15.1853L35.4205 15.2865L35.8138 22.3071L35.8267 22.5365L36.0091 22.6762L38.3902 24.4986L39.603 26.4006L40.1713 37.6165L40.1768 37.7255L40.2272 37.8222L42.0469 41.3147V51.6246L37.5995 57.9101L37.5042 58.0448L37.5078 58.2098L37.6229 63.5321L34.6713 65.4818L31.3369 65.61L31.0839 63.5409L31.48 63.0051L31.785 62.5925L31.3647 62.2982L30.3491 61.5874L30.0333 57.1947L30.0247 57.0743L29.962 56.9711L29.4475 56.1238L29.3015 55.8833H29.0201H27.1418L26.4335 55.3V52.2678V51.99L26.1977 51.8433L24.8359 50.9959L24.8162 50.9837L24.7954 50.9733L23.8647 50.5079L22.0026 48.1803L22.1341 46.8393L22.1879 46.2905H21.6365H19.4523L18.6902 44.0569L18.5747 43.7184H18.217H14.8701L13.3401 41.6208L13.6815 41.0583L13.9444 40.6254L13.5081 40.3682L12.328 39.6722L12.1665 39.5769L11.9822 39.6113L9.42446 40.0892L8.56301 39.5318L8.43904 39.4515H8.29138H4.89275L4.64733 38.7562L4.55118 38.4838L4.26714 38.4311L2.73775 38.147Z"
        />
        <path
          stroke="black"
          fill={this.props.fill || "none"}
          d="M0.544678 38.5293V40.6173L2.45111 41.9488L5.65875 43.825L5.87061 45.338L3.96417 45.913L0.938049 46.488V47.7892L2.05774 48.9391L2.26953 52.7217L6.41528 54.0532L7.92834 54.4164L9.25983 56.5044L8.32178 57.8358L6.80872 58.3806L5.65875 60.4685L4.53912 61.8L5.0838 65.1893L7.92834 65.0077L8.68488 65.5827L11.3478 64.2512L12.1044 65.0077L10.7729 67.8523L12.1044 69.1837L9.83478 70.8784L8.32178 74.2676L12.4675 75.236L18.3381 75.7806L15.887 78.6252C15.887 78.6252 14.7353 78.1809 14.2227 78.4133C14.2075 78.4208 14.176 78.4348 14.1621 78.4436C14.1577 78.4468 14.1361 78.4705 14.1319 78.4739C14.1278 78.4774 14.1055 78.5005 14.1016 78.5042C14.0981 78.5082 14.0748 78.5302 14.0714 78.5344C14.0649 78.5431 14.0468 78.5552 14.0411 78.5646C14.0384 78.5695 14.0436 78.5898 14.0411 78.5949C14.0364 78.6054 14.0147 78.6442 14.0108 78.6555C14.0091 78.6613 14.0123 78.6797 14.0108 78.6857C14.0082 78.698 13.9824 78.7331 13.9806 78.7462C13.9792 78.7598 13.9811 78.7924 13.9806 78.8068C13.9806 79.7524 12.6793 82.0447 12.6793 82.0447L14.5555 84.1024L17.975 86.1904L24.3904 87.8851L26.2665 88.6415L27.9914 89.3981L26.8415 91.4861L29.8676 91.3046L30.4426 92.636H33.4687L34.2252 89.035L32.3187 88.6415L34.9817 85.8273L34.0436 84.8589L34.2252 83.1643L37.6447 81.2881L37.8262 79.2001L35.5566 79.0186L34.0436 80.3198V78.4436L37.0697 78.262L38.0078 75.9924L38.7643 69.3653L38.1894 66.5208L38.1288 63.7973L34.8304 65.9761L30.8965 66.1274L30.5636 63.4039L31.0781 62.7079L29.8676 61.8606L29.5347 57.2306L29.0203 56.3833H26.9625L25.9337 55.536V52.2678L24.5719 51.4205L23.543 50.9061L21.4853 48.3339L21.6366 46.7906H19.0947L18.2171 44.2184H14.616L12.7399 41.6462L13.2543 40.7989L12.0741 40.1029L9.32037 40.6173L8.2915 39.9516H4.53912L4.17596 38.9227L2.05774 38.5293H0.544678Z"
        />
      </g>
    );
  }
}
