import React, { Component } from "react";
import {
  Card,
  BarChart,
  DoughnutChart,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";

export default class Statistics extends Component {

  getLabel(value) {
    if (value >= 90) {
      return "EXCELLENCE";
    } else if (value >= 80) {
      return "PREMIUM";
    } else {
      return "VIGILANCE";
    }
  }

  getClass(value) {
    if (value >= 90) {
      return "bg-green";
    } else if (value >= 80) {
      return "bg-orange";
    } else {
      return "bg-red";
    }
  }

  componentDidMount() {
      this.getPercentage()
      this.getPerformance()
  }
  
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.getPercentage()
      this.getPerformance()
    }
    if (prevProps.isPrint !== this.props.isPrint) {
      setTimeout(() => this.setState({chartLabelsLoaded :  this.props.isPrint}, () => this.props.onLabelsLoaded(this.state.chartLabelsLoaded)), 50)
    }
  }

  getPercentage = () => {
    let countAuditTypePercentage = []
    let countAuditTypeLabels = []
    let total = []
    let perf = []
    if (this.props.data.countAuditType) {
      total['technique-expert-terrain'] = 0
      total['technique-ve'] = 0
      total['technique-riv-vei'] = 0
      total['technique-ead'] = 0
      perf['technique-expert-terrain'] = 0
      perf['technique-ve'] = 0
      perf['technique-riv-vei'] = 0
      perf['technique-ead'] = 0
      this.props.data.countAuditType.label.map((label, idx) => total[label] = this.props.data.countAuditType.data[idx])
      this.props.data.performance.map((element) => perf[element.name] += element.value)
      for (const label in total) {
        if (total[label] && perf[label]) {
          countAuditTypeLabels.push(label)
          countAuditTypePercentage.push(Math.round(perf[label] / total[label]))
        }
      }
      this.setState({countAuditTypePercentage, countAuditTypeLabels})
    }
  }

  getPerformance = () => {
    let perf = this.props.data.performance.map(element => element.value)
    let performance = Math.round(perf.reduce((a, b) => a + b, 0) / perf.length)
    this.setState({performance})
  }
  
  render() {
    
    const auditData = this.props.data.countAuditMonthType.data
    auditData.map(audit => 
      audit.label === 'technique-expert-terrain' 
      ? audit.color = '#51ae50'
      : audit.label === 'technique-ve'
      ? audit.color = '#6067ae'
      : audit.label === 'technique-riv-vei'
      ? audit.color = '#e68a00'
      :  audit.color = '#9da9ba')
    const auditType = this.props.data.countAuditType
    auditType.label.map((label, idx) => 
      label === 'technique-expert-terrain' 
      ? auditType.color[idx] = '#51ae50'
      : label === 'technique-ve'
      ? auditType.color[idx] = '#6067ae'
      : label === 'technique-riv-vei'
      ? auditType.color[idx] = '#e68a00'
      :  auditType.color[idx] = '#9da9ba'
    )

    let maxValue = 0
    this.props.data.countAuditCompany.data.map(value => {
      if (value > maxValue) maxValue = value
      return true
    })


    return (
      <Card
        className={this.props.className}
        title={"Statistiques"}
      >
        <Row>
          {this.props.data.countAuditMonthType ?
            <Col xs={12} md={6} className={"mt-10"}>
              <BarChart
                digits={0}
                title={"Nombre d'audits / type de dossier"}
                labels={this.props.data.countAuditMonthType.label}
                dataSet={this.props.data.countAuditMonthType.data}
                xAxes={[
                  {
                    stacked: true,
                  },
                ]}
                yAxes={[
                  {
                    stacked: true,
                    display: false
                  },
                ]}
                datalabels2={this.props.isPrint && this.props.data.countAuditMonthType.data.map(type => {
                  return {
                    display: true,
                    align: 'right',
                    anchor: 'right',
                    clamp: true,
                    color: type.color,
                    
                    formatter: (value) => value > 0 ? Math.round(value) : null
                  }
                }) }
              />
            </Col> : null
          }
          {this.props.data.countAuditType ?
            <Col xs={12} md={6} className={"mt-10"}>
              <BarChart
                digits={0}
                format="percentage"
                title={"Pourcentage d'atteinte / type de dossier"}
                labels={this.state && this.state.countAuditTypeLabels}
                dataSet={[
                  {
                    label: "Pourcentage d'atteinte / type de dossier",
                    data: this.state && this.state.countAuditTypePercentage,
                    color: this.props.data.countAuditType.color,
                  }
                ]}
                yAxes={[
                  {
                    ticks: {
                      display: false,
                      suggestedMax: 120,
                      min: 0,
                      beginAtZero: true
                    },
                  },
                ]}
                datalabels2={ this.props.isPrint ?[
                  {
                    display: true,
                    align: 'top',
                    anchor: 'end',
                    clamp: true,
                    color: '#0162AD',
                    
                    formatter: (value) => Math.round(value) + '%'
                  }
                ] : null}
              />
            </Col> : null
          }
          {this.props.data.countAuditCompany ?
            <Col xs={12} md={8} className={"mt-10"}>
              <BarChart
                digits={0}
                title={"Nombre d'audits / compagnie"}
                labels={this.props.data.countAuditCompany.label}
                dataSet={[
                  {
                    label: "Nombre d'audits / compagnie",
                    data: this.props.data.countAuditCompany.data,
                    color: this.props.data.countAuditCompany.color,
                  }
                ]}
                yAxes={[
                  {
                    ticks: {
                      display: false,
                      suggestedMax: maxValue + ((maxValue / 100) * 30),
                      min: 0,
                      beginAtZero: true
                    },
                  },
                ]}
                datalabels2={ this.props.isPrint ?[
                  {
                    display: true,
                    align: 'top',
                    anchor: 'end',
                    clamp: true,
                    color: '#0162AD',
                    
                    formatter: (value) => Math.round(value)
                  }
                ] : null}
              />
            </Col> : null
          }
          {this.state && this.props.data.performance ?
            <Col xs={12} md={4} className={"mt-10 d-flex"}>
              <DoughnutChart
                className={`m-auto`}
                number={this.state.performance}
                text={this.getLabel(this.state.performance)} />
            </Col> : null}
        </Row>
      </Card>
    );
  }
}
