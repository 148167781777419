const getYears = (minYear, maxYear) => {
  const start = minYear || 2018;
  const end = maxYear || new Date().getFullYear() + 1;

  let years = [];
  for (let index = end; index >= start; index--) {
    years.push({ value: index, text: index });
  }

  return years;
};

export default getYears;
