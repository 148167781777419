import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="prefix__Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 1280 1024"
      width={props.width ? props.width : '20%'}
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".prefix__st2{font-family:&apos;ArialMT&apos;}.prefix__st3{font-size:293.5332px}.prefix__st5{display:none;fill:none}"
        }
      </style>
      <path 
        d="M1009.5 994.7v37.4H223.2c.6-9.3 1.2-18.6 1.7-27.9 1-17.1 9.4-26.8 27.1-26.6 29.3.5 28.9.3 28.9-31 0-249.6.3-499.2-.4-748.8-.1-29.8 6.8-52.7 36.3-64.4h599.1c29.5 11.7 36.6 34.6 36.3 64.4-1.1 101-.4 202.1-.4 303.2 0 151 .2 301.9-.3 452.9-.1 17.6 5.1 25.8 23.3 23.6 15.1-1.8 26.8 4.1 34.7 17.2zm-335.7-99.3c0-21.2.1-42.4 0-63.6-.1-16.6-8.4-25.6-25.2-25.8-21.2-.2-42.4-.1-63.6 0-17.7.1-26.3 9.2-26.3 26.7 0 42.4.3 84.8-.1 127.2-.1 13 5.4 17.7 18 17.5 26.2-.4 52.4-.5 78.5.1 13.4.3 19.3-4.7 18.9-18.5-.6-21.2-.1-42.4-.2-63.6zm-112.2-592c0-10 .2-19.9 0-29.9-.5-21.9-7.4-28.8-29.4-29.2-19.3-.3-38.6-.4-57.9.1-19.8.4-27 7.6-27.8 27-.4 10-.1 19.9-.1 29.9 0 58.3 0 58.3 58.5 58.3 56.7 0 56.7 0 56.7-56.2zm165.5 56.2c10 0 19.9.2 29.9 0 21.8-.5 28.8-7.5 29.1-29.5.3-19.3.4-38.6 0-57.9-.4-19.9-7.6-27-27-27.7-10-.4-19.9-.1-29.9-.1-58.3 0-58.3 0-58.3 58.5 0 56.7 0 56.7 56.2 56.7zM561.6 472c0-10 .2-19.9 0-29.9-.5-21.9-7.4-28.9-29.4-29.2-18.7-.3-37.4-.3-56.1 0-22.2.4-29.1 7.2-29.7 28.9-.3 9.3-.1 18.7-.1 28 0 58.3 0 58.3 58.5 58.3 56.8 0 56.8 0 56.8-56.1zm168.5 55.9c0 .1 0 .1 0 0 10 .1 19.9.1 29.9.1 16.5-.1 25.8-8.1 26.1-24.9.3-21.8.3-43.6 0-65.4-.2-15.7-8.4-24.4-24.3-24.6-22.4-.3-44.9-.4-67.3.1-14.8.4-23 8.9-23.3 23.7-.4 22.4-.4 44.9 0 67.3.3 14.8 8.5 23.1 23.3 23.7 11.9.4 23.8 0 35.6 0zm-228 168.5c11.8 0 23.7.3 35.5-.1 14.8-.5 23.4-8.5 23.7-23.4.5-23 .5-46.1-.1-69.1-.4-13.9-8.7-21.9-22.7-22.2-23-.5-46.1-.6-69.1.1-14.9.4-22.5 9.4-22.9 24.1-.3 11.8-.2 23.7-.2 35.5 0 55.4 0 55.4 55.8 55.1zm284.1-56.2c0-11.2.2-22.4 0-33.6-.3-16.7-9.1-25.1-25.8-25.2-21.2-.1-42.4-.1-63.5 0-16.6.1-25.4 8.6-25.7 25.4-.2 10.6-.2 21.2-.2 31.8 0 58.1 0 58.1 58.7 58.1 56.6-.1 56.6-.1 56.5-56.5z" 
        fill={props.color ? props.color : 'black'}
      />

      <circle cx={952.9} cy={195.5} r={195.5} fill="red" />
      <text
        transform="translate(838.349 297.093)"
        fontSize={382.272}
        fontFamily="MyriadPro-Regular"
        fill="#fefefe"
      >
        {"+"}
      </text>
    </svg>
  );
}

export default SvgComponent;

