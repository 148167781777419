import React, { Component } from "react";
import { Col, Row } from "react-flexbox-grid";

export class UserTab extends Component {
  render() {
    return (
      <Row>
        <Col xs={12} md={12}></Col>
        <Col xs={12} md={6}></Col>
        <Col xs={12} md={6}></Col>
        <Col xs={12} md={6}></Col>
      </Row>
    );
  }
}
