import React, { Component } from 'react'
import { Col, Row } from 'react-flexbox-grid'
import { Card, FormTextarea } from 'ui-kit-ck-consultant'
import MonthlyBilanDisplay from './MonthlyBilanDisplay'

export class MonthlyBilan extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null
    }
  }

  componentDidMount() {
    if (this.props.oldData ) {
      if (this.props.monthlyBilan) {
        this.setState({data: [...this.props.oldData, ...this.props.monthlyBilan]})
      } else {
        this.setState({data: this.props.oldData})
      }
    } else if (this.props.monthlyBilan) {
      this.setState({data: this.props.monthlyBilan})
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.oldData && prevProps.oldData !== this.props.oldData) {
      if (this.props.monthlyBilan) {
        this.setState({data: [...this.props.oldData, ...this.props.monthlyBilan]})
      } else {
        this.setState({data: this.props.oldData})
      }
    } else if (this.props.monthlyBilan && prevProps.monthlyBilan !== this.props.monthlyBilan) {
      this.setState({data: this.props.monthlyBilan})
    }
  }

  render() {
    return (
      <Card title="Bilan mensuel" className={"mt-10 mt-30-xl "}>
        <Row>
          <Col xs={12} md={12}>
            {this.state.data && this.state.data.length > 0 
            ?
             ( <MonthlyBilanDisplay
                monthlyBilan={this.state.data}
              />)
            : null
          }
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <FormTextarea
              title={"Analyse manager"}
              placeholder={"Saisir texte ici"}
              rows={15}
              name='managerAnalysisValue'
              onChange={(e) => this.props.onChangeData(e)}
            />
          </Col>
          <Col xs={12} md={6}>
            <FormTextarea
              title={"Analyse collaborateur"}
              placeholder={"Saisir texte ici"}
              rows={15}
              name='employeeAnalysisValue'
              onChange={(e) => this.props.onChangeData(e)}
            />
          </Col>
        </Row>
      </Card>
    )
  }
}

export default MonthlyBilan
