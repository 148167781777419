import React, { Component } from "react";
import {
  Card,
  BarChart,
  DoughnutChart,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";
import AuditList from "./AuditList";

export default class CardPerformanceAudit extends Component {

  render() {
    function getMax(data) {
      let max = 0
      data.map(value => {
        if (value && parseInt(value) > max) {
          max = parseInt(value)
        }
        return true
      })
      return max
    }
    let maxAudit = this.props.dataTabPercentageAudit ? getMax(this.props.dataTabPercentageAudit[0].data ) : 0
    return (
      <div>
          <>
            <Card
              title={"Performance audit"}
              className={"mt-10 mt-30-xl " + this.props.className}
            >
              <Row>
                <Col md={12} xl={5} className={"my-10 "}>
                  <h3>Nombre d'audits / type de dossier</h3>

                  <div className={"min-tab-size-md"}>
                    <BarChart
                      digits={0}
                      notAlign
                      legendDisplay={true}
                      dataSet={this.props.dataTabNbAudit ? this.props.dataTabNbAudit : []}
                      yAxes={[{
                        stacked: true,
                        ticks: {
                          display: false,
                          suggestedMax: 50
                        }
                      }]}
                      xAxes={[{
                        stacked: true,
                      }]}
                      datalabels2={this.props.isPrint ? 
                        this.props.dataTabNbAudit.map(label => {
                          return {
                            display: true,
                            align: 'right',
                            anchor: 'right',
                            clamp: true,
                            color: label.color,
                            
                            formatter: (value) => value > 0 ?  Math.round(value) : null
                          }
                        }) : null}
                    />
                  </div>
                </Col>
                <Col md={12} xl={5} className={"my-10"}>
                  <h3>Pourcentage d'atteinte / type de dossier</h3>

                  <div className={"min-tab-size-md"}>
                    <BarChart
                      digits={0}
                      format={"percentage"}
                      legendDisplay={false}
                      labels={this.props.dataTabLabelsAudit ? this.props.dataTabLabelsAudit : []}
                      dataSet={this.props.dataTabPercentageAudit ? this.props.dataTabPercentageAudit : []}
                      yAxes={[{
                        stacked: true,
                        ticks: {
                          display: false,
                          suggestedMax: maxAudit + ((maxAudit / 100) * 30)
                        }
                      }]}
                      datalabels2={this.props.isPrint ? 
                        this.props.dataTabPercentageAudit.map(label => {
                          return {
                            display: true,
                            align: 'top',
                            anchor: 'end',
                            clamp: true,
                            color: label.color,
                            formatter: (value) => value > 0 ?  Math.round(value) : null
                          }
                        }) : null}
                    />
                  </div>
                </Col>
                <Col md={12} xl={2} className={"my-10"}>
                  <h3>{this.props.dataDoughnut && this.props.dataDoughnut.title}</h3>
                  <div className={"d-flex"}>
                    <DoughnutChart
                      digits={0}
                      className={"m-auto"}
                      number={this.props.dataDoughnut && this.props.dataDoughnut.number}
                      text={this.props.dataDoughnut && this.props.dataDoughnut.text}
                    />
                  </div>
                </Col>
                {this.props.dataTab && (
                  <Col xl={12} className={"my-10 overflow-x-auto"}>
                    <AuditList
                      dataTab={this.props.dataTab}
                      isPrint={this.props.isPrint}
                    />
                  </Col>
                )}
              </Row>
            </Card>
          </>
      </div>
    );
  }
}
