import React, { Component } from "react";

import TableCustom from "./TableCustom";
import ChartVVNR from "./ChartVVNR";
import ListVVNR from "./ListVVNR";

export class VVNR extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        {this.props.data.length ? (
          <>
            <TableCustom
              data={this.props.data[0]}
              labels={this.props.labelsNR}
              dateEnd={this.props.dateEnd}
            />
            {this.props.dataChart.hasOwnProperty("dataChart") ? (
              <ChartVVNR
                data={this.props.dataChart.dataChart}
                dataChartTypeOwner={this.props.dataChart.dataChartTypeOwner}
                dataChartKind={this.props.dataChart.dataChartKind}
                score={this.props.dataChart.score}
              />
            ) : null}
            <ListVVNR
              data={this.props.dataList}
              getDataList={this.props.getDataList}
              //Pagination
              activePage={this.props.activePage}
              tabLength={this.props.tabLength}
              total={this.props.total}
              onPageChange={this.props.onPageChange}
              onSortClick={this.props.onSortClick}
            />
          </>
        ) : null}
      </>
    );
  }
}

export default VVNR;
