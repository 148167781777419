import React, { Component } from "react";
import {
  Card,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  BarChart,
  Loader,
  Status,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";

import axiosConfig from "../../../../utils/axiosConfig";

import {
  getCurrencyValue,
  getPercentageValue,
} from "../../../../utils/general";
export default class CardMAIF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataPerformance: null,
      search: null,
      isLoad: false,
    };
  }

  componentDidMount() {
    this.getPerformance();
  }

  componentDidUpdate = (prevProps) => {
    if (
      JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data) &&
      parseInt(this.props.data.company) === 8
    ) {
      this.getPerformance();
    }
  };

  getData = () => {
    return {
      dateStart: this.props.data.dateStart,
      dateEnd: this.props.data.dateEnd,
      network: this.props.data.network,
      group: this.props.data.group,
      region: this.props.data.region,
      agency: this.props.data.agency,
      user: this.props.data.user,
      company: this.props.data.company,
      filter: this.props.data.filter,
    };
  };

  getPerformance = () => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("/company-performance/maif", {
        data: this.getData(),
      }).then((res) => {
        if (res.data.success) {
          this.setState({
            dataPerformance: this.formatData(res.data.data),
            search: res.data.search,
            isLoad: false,
          });
        } else {
          this.setState({
            dataPerformance: null,
            search: null,
            isLoad: false,
          });
        }
      });
    });
  };

  formatData(data) {
    for (const folder in data) {
      if (folder.includes("ByMonth")) {
      } else {
        for (const row in data[folder]) {
          if (
            (row.includes("average") &&
              !row.includes("ours") &&
              !row.includes("Vehicle")) ||
            row === "VRADE" ||
            row.includes("Honorary")
          ) {
            data[folder][row][0] = getCurrencyValue(data[folder][row][0]);
            data[folder][row][1] = getCurrencyValue(data[folder][row][1]);
            data[folder][row][2] = getCurrencyValue(data[folder][row][2]);
          }
          if (row.includes("ours")) {
            data[folder][row][0] = data[folder][row][0]
              ? `${data[folder][row][0]} heures`
              : "";
            data[folder][row][1] = data[folder][row][1]
              ? `${data[folder][row][1]} heures`
              : "";
            data[folder][row][2] = data[folder][row][2]
              ? `${data[folder][row][2]} heures`
              : "";
          }
          if (row.includes("IPPrecent") || row.includes("tx")) {
            data[folder][row][0] = getPercentageValue(data[folder][row][0], 0);
            data[folder][row][1] = getPercentageValue(data[folder][row][1], 0);
            data[folder][row][2] = getPercentageValue(data[folder][row][2], 0);
          }
          if (row.includes("delay")) {
            data[folder][row][0] = data[folder][row][0]
              ? `${data[folder][row][0]} jours`
              : "";
            data[folder][row][1] = data[folder][row][1]
              ? `${data[folder][row][1]} jours`
              : "";
            data[folder][row][2] = data[folder][row][2]
              ? `${data[folder][row][2]} jours`
              : "";
          }
          if (row.includes("AgeVehicle")) {
            data[folder][row][0] = data[folder][row][0]
              ? `${data[folder][row][0]} ans`
              : "";
            data[folder][row][1] = data[folder][row][1]
              ? `${data[folder][row][1]} ans`
              : "";
            data[folder][row][2] = data[folder][row][2]
              ? `${data[folder][row][2]} ans`
              : "";
          }
          data[folder][row][3] = getPercentageValue(data[folder][row][3], 0);
        }
      }
    }
    return data;
  }

  getStatusColor(label, column) {
    const invertedStatus = [
      "Taux EAD",
      "Taux de réparation Plastique",
      "Taux d'intervention PRE",
      "Age moyen",
    ];
    let className = "";
    if (!invertedStatus.includes(label)) {
      parseInt(column) < 0
        ? (className = "bg-green")
        : parseInt(column) > 0
        ? (className = "bg-red")
        : (className = "bg-blue");
    } else {
      parseInt(column) > 0
        ? (className = "bg-green")
        : parseInt(column) < 0
        ? (className = "bg-red")
        : (className = "bg-blue");
    }

    return className;
  }

  render() {
    const subLabels = [
      "Trimestre en cours",
      "Cumul début d'année",
      "A-1 (2)",
      "Evol A/A-1",
    ];
    const dataR = {
      tabs: this.state.dataPerformance
        ? [
            [
              ["Coût Moyen Brut HT"].concat(
                this.state.dataPerformance.dataR.averageCostR
              ),
              ["Nombre d'heures MO"].concat(
                this.state.dataPerformance.dataR.averageHoursMO
              ),
              ["dont nombre d'heures IP"].concat(
                this.state.dataPerformance.dataR.averageHoursIP
              ),
              ["Cout moyen PR"].concat(
                this.state.dataPerformance.dataR.averageCostPieces
              ),
            ],
            [
              ["Délai dépôt Rapport"].concat(
                this.state.dataPerformance.dataR.delayFile
              ),
              ["Taux EAD"].concat(this.state.dataPerformance.dataR.txEAD),
              ["Taux d'intervention PRE"].concat(
                this.state.dataPerformance.dataR.txPRE
              ),
              ["Taux de réparation Plastique"].concat(
                this.state.dataPerformance.dataR.txRP
              ),
            ],
          ]
        : [],
      charts: [
        {
          title: "Coût Moyen Réparation",
          data: this.state.dataPerformance
            ? [
                {
                  type: "line",
                  color: "#026fc2",
                  label: "",
                  data: this.state.dataPerformance.dataByMonthR.averageCostR,
                },
              ]
            : [],
        },
        {
          title: "Nombres d'heures MO",
          data: this.state.dataPerformance
            ? [
                {
                  type: "line",
                  color: "#026fc2",
                  label: "",
                  data: this.state.dataPerformance.dataByMonthR.averageHoursMO,
                },
              ]
            : [],
        },
        {
          title: "Coût Moyen PR",
          data: this.state.dataPerformance
            ? [
                {
                  type: "line",
                  color: "#026fc2",
                  label: "",
                  data: this.state.dataPerformance.dataByMonthR.averageCostPR,
                },
              ]
            : [],
        },
        {
          title: "Nombre d'heures IP",
          data: this.state.dataPerformance
            ? [
                {
                  type: "line",
                  color: "#026fc2",
                  label: "",
                  data: this.state.dataPerformance.dataByMonthR.averageHoursIP,
                },
              ]
            : [],
        },
      ],
    };
    const dataNR = {
      tabs: this.state.dataPerformance
        ? [
            [
              ["Coût Moyen VRADE TTC"].concat(
                this.state.dataPerformance.dataNR.averageVRADE
              ),
              ["Age moyen"].concat(
                this.state.dataPerformance.dataNR.averageAgeVehicle
              ),
            ],
            [
              ["Coût Moyen VRADE TTC"].concat(
                this.state.dataPerformance.dataNR.averageVRADE10
              ),
              ["Age moyen"].concat(
                this.state.dataPerformance.dataNR.averageAgeVehicle10
              ),
            ],
            [
              ["Délai OM-SD29"].concat(
                this.state.dataPerformance.dataNR.delaySD29
              ),
              ["Délai OM-RE"].concat(this.state.dataPerformance.dataNR.delay),
              ["Taux de VRADE"].concat(
                this.state.dataPerformance.dataNR.txVRADE
              ),
            ],
          ]
        : [],
      charts: this.state.dataPerformance
        ? [
            {
              title: "Coût Moyen VRADE",
              data: [
                {
                  type: "line",
                  color: "#026fc2",
                  label: "",
                  data: this.state.dataPerformance.dataByMonthNR
                    .averageCostVRADE,
                },
              ],
            },
            {
              title: "Age moyen",
              data: [
                {
                  type: "line",
                  color: "#026fc2",
                  label: "",
                  data: this.state.dataPerformance.dataByMonthNR
                    .averageAgeVehicle,
                },
              ],
            },
            {
              title: "Coût Moyen VRADE",
              data: [
                {
                  type: "line",
                  color: "#026fc2",
                  label: "",
                  data: this.state.dataPerformance.dataByMonthNR
                    .averageCostVRADE5000,
                },
              ],
            },
            {
              title: "Age moyen",
              data: [
                {
                  type: "line",
                  color: "#026fc2",
                  label: "",
                  data: this.state.dataPerformance.dataByMonthNR
                    .averageAgeVehicle5000,
                },
              ],
            },
          ]
        : [],
    };
    const perimeterNR = [
      "Périmètre : VP/CTTE - Collision, Voies de faits (hors CAT NAT), Vol, tentative de vol et vandalisme - véhicules >= 10 ans - tranche 0 - 5 000 € HT ou véhicules < 10 ans : Tranche 1000 € à 8500 €",
      "Périmètre : VP/CTTE - Collision, Voies de faits (hors CAT NAT), Vol, tentative de vol et vandalisme - véhicules >= 10 ans - tranche 0 - 5 000 € HT ou véhicules < 10 ans : Tranche 1000 € à 8500 €",
      "",
    ];

    return (
      <>
        {this.state.dataPerformance ? (
          <>
            <Card
              title={"Réparable"}
              className="mb-10 mb-30-xl"
              classNameChildren="overflow-x-auto"
            >
              <b>
                Périmètre VP/CTTE - Collision, Voies de faits (hors CAT NAT),
                Vol, tentative de vol et vandalisme - Réparations : Montant HT
              </b>
              <Table className={" my-10 my-30-xl"}>
                <Thead>
                  <Tr>
                    <Th hide></Th>
                    {subLabels.map((label, idx) => {
                      return (
                        <Th
                          radiusTopLeft={idx === 0 ? true : false}
                          spaceLeft
                          className={"text-center"}
                          spaceRight
                          key={idx}
                        >
                          {label}
                        </Th>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody>
                  {dataR.tabs[0].map((row, idx) => {
                    return (
                      <Tr key={idx}>
                        {row.map((item, id) =>
                          id === 0 ? (
                            <Th
                              key={id}
                              radiusTopLeft={idx === 0 ? true : false}
                              className={"nowrap"}
                            >
                              {item}
                            </Th>
                          ) : id === 4 ? (
                            <Td
                              key={id}
                              style={{
                                width: "100px",
                                maxWidth: "500px",
                              }}
                            >
                              <Status
                                className={`mx-10 ${this.getStatusColor(
                                  row[0],
                                  item
                                )}`}
                                text={item}
                              />
                            </Td>
                          ) : (
                            <Td key={id} className={"nowrap"}>
                              {item}
                            </Td>
                          )
                        )}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
              <Row className={"my-30"}>
                {dataR.charts.map((chart, idx) => {
                  return (
                    <Col xs={12} md={6} lg={6} key={idx} className={"my-30"}>
                      <BarChart
                        yAxes={[
                          {
                            ticks: {
                              display: true,
                              min: 0,
                              beginAtZero: true,
                            },
                          },
                        ]}
                        title={chart.title}
                        labels={chart.labels}
                        dataSet={chart.data}
                      />
                    </Col>
                  );
                })}
              </Row>
              <b>
                Périmètre VP/CTTE - Collision, Voies de faits (hors CAT NAT),
                Vol, tentative de vol et vandalisme - Réparations : Montant HT
              </b>
              <Table className={" my-10 my-30-xl"}>
                <Thead>
                  <Tr>
                    <Th hide></Th>
                    {subLabels.map((label, idx) => {
                      return (
                        <Th
                          radiusTopLeft={idx === 0 ? true : false}
                          spaceLeft
                          className={"text-center"}
                          spaceRight
                          key={idx}
                        >
                          {label}
                        </Th>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody>
                  {dataR.tabs[1].map((row, idx) => {
                    return (
                      <Tr key={idx}>
                        {row.map((item, id) =>
                          id === 0 ? (
                            <Th
                              key={id}
                              radiusTopLeft={idx === 0 ? true : false}
                              className={"nowrap"}
                            >
                              {item}
                            </Th>
                          ) : id === 4 ? (
                            <Td
                              key={id}
                              style={{
                                width: "100px",
                                maxWidth: "500px",
                              }}
                            >
                              <Status
                                className={`mx-10 ${this.getStatusColor(
                                  row[0],
                                  item
                                )}`}
                                text={item}
                              />
                            </Td>
                          ) : (
                            <Td key={id} className={"nowrap"}>
                              {item}
                            </Td>
                          )
                        )}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
              <div style={{ fontSize: "10px" }} className="mb-20 my-30">
                <p>
                  Périmètre : VP/CTTE - Collision, Voies de faits (hors CAT
                  NAT), Vol, tentative de vol et vandalisme - Réparations :
                  Montant HT
                </p>
                <p>
                  (1) : évolution du cumul début d'année par rapport à l'année
                  précédente vue à même période
                </p>
                <p>
                  (2) : A-1 sur la même période l'an précédant celui de l'année
                  sélectionnée.
                </p>
              </div>
            </Card>
            <Card
              title={"Non Réparable"}
              className={"my-10 my-30-xl "}
              classNameChildren="overflow-x-auto"
            >
              {dataNR.tabs.map((tab, index) => {
                return (
                  <>
                    <Table className={" my-10 my-30-xl"} key={index}>
                      <Thead>
                        <Tr>
                          <Th hide></Th>
                          {subLabels.map((label, idx) => {
                            return (
                              <Th
                                radiusTopLeft={idx === 0 ? true : false}
                                spaceLeft
                                className={"text-center"}
                                spaceRight
                                key={idx}
                              >
                                {label}
                              </Th>
                            );
                          })}
                        </Tr>
                      </Thead>
                      <Tbody>
                        {dataNR.tabs[index].map((row, idx) => {
                          return (
                            <Tr key={idx}>
                              {row.map((item, id) =>
                                id === 0 ? (
                                  <Th
                                    key={id}
                                    radiusTopLeft={idx === 0 ? true : false}
                                    className={"nowrap"}
                                  >
                                    {item}
                                  </Th>
                                ) : id === 4 ? (
                                  <Td
                                    key={id}
                                    style={{
                                      width: "100px",
                                      maxWidth: "500px",
                                    }}
                                  >
                                    <Status
                                      className={`mx-10 ${this.getStatusColor(
                                        row[0],
                                        item
                                      )}`}
                                      text={item}
                                    />
                                  </Td>
                                ) : (
                                  <Td key={id} className={"nowrap"}>
                                    {item}
                                  </Td>
                                )
                              )}
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>

                    <Row className={"my-30"}>
                      {dataNR.charts.map((chart, idx) => {
                        if (index === 0 && (idx === 0 || idx === 1)) {
                          return (
                            <Col
                              xs={12}
                              md={6}
                              lg={6}
                              key={idx}
                              className={"my-30"}
                            >
                              <BarChart
                                title={chart.title}
                                dataSet={chart.data}
                                yAxes={[
                                  {
                                    ticks: {
                                      display: false,
                                      min: 0,
                                      beginAtZero: true,
                                    },
                                  },
                                ]}
                              />
                            </Col>
                          );
                        } else if (index === 1 && (idx === 2 || idx === 3)) {
                          return (
                            <Col
                              xs={12}
                              md={6}
                              lg={6}
                              key={idx}
                              className={"my-30"}
                            >
                              <BarChart
                                yAxes={[
                                  {
                                    ticks: {
                                      display: true,
                                      min: 0,
                                      beginAtZero: true,
                                    },
                                  },
                                ]}
                                title={chart.title}
                                labels={chart.labels}
                                dataSet={chart.data}
                              />
                            </Col>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </Row>
                    <b className={"m-10"}>{perimeterNR[index]}</b>
                  </>
                );
              })}

              <div style={{ fontSize: "10px" }} className="mb-20  my-30">
                <p>
                  (1) : évolution du cumul début d'année par rapport à l'année
                  précédente vue à même période
                </p>
                <p>
                  (2) : A-1 sur la même période l'an précédant celui de l'année
                  sélectionnée.
                </p>
              </div>
            </Card>
          </>
        ) : null}
        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
