import React, { Component } from "react";
import FadeIn from "react-fade-in";
import { Card, Table, Thead, Tr, Th, Tbody, Td } from "ui-kit-ck-consultant";

import ToggleTable from "../../../../performances/reparator/ToggleTable";

export default class CardQuality extends Component {
  getGeneralValue = (id) => {
    if (this.props.data && this.props.data.hasOwnProperty(id)) {
      return this.props.data[id];
    } else {
      return "";
    }
  };

  render() {
    const dataQualityServices = [
      {
        title: "Taux de rapports rectificatifs",
        expert: this.getGeneralValue("rateReportEdited"),
        objectifs: null,
        ecart: null,
      },
      {
        title: "Taux d'EAD - Téléchiffrage (2)",
        expert: this.getGeneralValue("rate_EAD"),
        objectifs: null,
        ecart: null,
      },
      {
        title: "Taux d'EAD modifié",
        expert: this.getGeneralValue("rate_EAD_edit"),
        objectifs: null,
        ecart: null,
      },
      {
        title: "Délai moyen EAD (2) (3)",
        expert: this.getGeneralValue("averageDelay_EAD"),
        objectifs: null,
        ecart: null,
      },
      {
        title: "Délai moyen REPARABLE (3)",
        expert: this.getGeneralValue("averageDelay_R"),
        objectifs: null,
        ecart: null,
      },
      {
        title: "Délai moyen PERTE TOTALE (3)",
        expert: this.getGeneralValue("averageDelay_NR"),
        objectifs: null,
        ecart: null,
      },
      {
        title: "Nombre de dossiers EAD (1) (2)",
        expert: this.getGeneralValue("count_EAD"),
        objectifs: null,
        ecart: null,
      },
      {
        title: "Nombre de dossiers REPARABLE (1) (4)",
        expert: this.getGeneralValue("count_R"),
        objectifs: null,
        ecart: null,
      },
      {
        title: "Nombre de dossiers PERTE TOTALE (1)",
        expert: this.getGeneralValue("count_NR"),
        objectifs: null,
        ecart: null,
      },
      {
        title: "Nombre de dossiers CATNAT (1)",
        expert: this.getGeneralValue("count_CATNAT"),
        objectifs: null,
        ecart: null,
      },
      {
        title: "Nombre de dossiers AUTRE (1)",
        expert: this.getGeneralValue("count_OTHER"),
        objectifs: null,
        ecart: null,
      },
      {
        title: "Nombre de rapports d'expertise (1)",
        expert: this.getGeneralValue("countReport"),
        objectifs: null,
        ecart: null,
      },
      {
        title: "Nombre de rapports rectificatifs",
        expert: this.getGeneralValue("countReportEdited"),
        objectifs: null,
        ecart: null,
      },
      {
        title: "Délai moyen CATNAT (3)",
        expert: this.getGeneralValue("averageDelay_CATNAT"),
        objectifs: null,
        ecart: null,
      },
      {
        title: "Délai moyen AUTRE (3)",
        expert: this.getGeneralValue("averageDelay_OTHER"),
        objectifs: null,
        ecart: null,
      },
      {
        title: "Taux de SD04 - Contestabilité de l'expertise",
        expert: this.getGeneralValue("rateSD04"),
        objectifs: null,
        ecart: null,
      },
      {
        title: "Délai moyen des SD04 (5)",
        expert: this.getGeneralValue("averageDelaySD04"),
        objectifs: null,
        ecart: null,
      },
      {
        title: "Taux de SD15 - Retard prévisible",
        expert: this.getGeneralValue("rateSD15"),
        objectifs: null,
        ecart: null,
      },
      {
        title: "Délai moyen du premier SD15 (6)",
        expert: this.getGeneralValue("averageDelaySD15"),
        objectifs: null,
        ecart: null,
      },
      {
        title: "Taux de SD20 - Relance de l'expert",
        expert: this.getGeneralValue("rateSD20"),
        objectifs: null,
        ecart: null,
      },
      {
        title: "Taux de SD29 - Informations sur les dommages",
        expert: this.getGeneralValue("rateSD29"),
        objectifs: null,
        ecart: null,
      },
      {
        title: "Délai moyen des SD29 (7)",
        expert: this.getGeneralValue("averageDelaySD29"),
        objectifs: null,
        ecart: null,
      },
      {
        title: "Taux d'échange des SD99 - Commentaires (9)",
        expert: this.getGeneralValue(""),
        objectifs: null,
        ecart: null,
      },
      {
        title: "Taux de SD99 contestation VRADE (10)",
        expert: this.getGeneralValue("rateSD99"),
        objectifs: null,
        ecart: null,
      },
      {
        title: "Nombre de SD04 (8)",
        expert: this.getGeneralValue("azerty"),
        objectifs: null,
        ecart: null,
      },
      {
        title: "Nombre de dossiers éligibles aux SD04 (1)",
        expert: this.getGeneralValue("countSD04"),
        objectifs: null,
        ecart: null,
      },
      {
        title: "Nombre de SD15 (8)",
        expert: this.getGeneralValue("countSD15"),
        objectifs: null,
        ecart: null,
      },
      {
        title: "Nombre de SD20",
        expert: this.getGeneralValue("countSD20"),
        objectifs: null,
        ecart: null,
      },
      {
        title: "Nombre de SD29 (8)",
        expert: this.getGeneralValue("countSD29"),
        objectifs: null,
        ecart: null,
      },
      {
        title: "Nombre de dossiers éligibles aux SD29 (1)",
        expert: this.getGeneralValue("azerty"),
        objectifs: null,
        ecart: null,
      },
      {
        title: "Nombre de SD99 envoyés par l'expert",
        expert: this.getGeneralValue("azerty"),
        objectifs: null,
        ecart: null,
      },
      {
        title: "Nombre de SD99 envoyés par le GACM",
        expert: this.getGeneralValue("azerty"),
        objectifs: null,
        ecart: null,
      },
      {
        title: "Nombre de SD99 contestation VRADE",
        expert: this.getGeneralValue("countSD99"),
        objectifs: null,
        ecart: null,
      },
    ];

    return (
      <Card title={"Qualité de Service"} className={"mt-10 mt-30-xl "}>
        <ToggleTable color={"DarkPurple"}>
          <FadeIn>
            <div style={{ fontSize: "10px" }} className="mb-20 mt-30">
              <p>(1) dernier RE reçu </p>
              <p>(2) moyenne France comprend ACM Vérifications, BCA et BCE </p>
              <p>(3) date dépôt dernier RE - date examen RE</p>
              <p>(4) hors CATNAT et EAD</p>
              <p>(5) date 1er SD04 - date examen RE </p>
              <p>(6) date 1er SD15 - date examen RE </p>
              <p>(7) date 1er SD29 - date examen RE </p>
              <p>(8) le 1er reçu sur une mission</p>
              <p>
                (9) Nbre de SD99 envoyés par l'expert / Nbre de SD99 envoyés par
                le GACM{" "}
              </p>
              <p>
                (10) Nbre de SD99 contestation VRADE / Nbre de dossiers en PERTE
                TOTALE
              </p>
            </div>
            <Table>
              <Thead>
                <Tr>
                  <Th hide></Th>
                  <Th radiusTopLeft>Expert</Th>
                </Tr>
              </Thead>
              <Tbody>
                {dataQualityServices.map((element, idx) => (
                  <Tr key={idx}>
                    <Th
                      colSpan={element.special ? 2 : 1}
                      radiusTopLeft={idx === 0}
                    >
                      {element.title}
                    </Th>
                    {element.special ? "" : <Td>{element.expert}</Td>}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </FadeIn>
        </ToggleTable>
      </Card>
    );
  }
}
