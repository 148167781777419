import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Map.css";

export default class MapMarker extends Component {
  render() {
    return (
      <div
        className="border-marker"
        style={{ backgroundColor: `${this.props.expertColor}88` }}
      >
        <FontAwesomeIcon
          style={{
            fontSize: this.props.size ? `${this.props.size}px` : "18px",
            color: this.props.color,
            cursor: "pointer",
            opacity: "1",
          }}
          icon={this.props.icon}
          onClick={() =>
            this.props.onOpenModal
              ? this.props.onOpenModal(this.props.id)
              : null
          }
        />
        {this.props.text}
      </div>
    );
  }
}
