import React, { Component } from "react";

import { Dropzone } from "ui-kit-ck-consultant";

export default class DropZoneValid extends Component {
  getFile = (e) => {
    this.props.getFile({
      base64: e.base64,
      extension: e.mime.split("/")[1],
      name: e.name,
      mime: e.mime,
    });
  };

  render() {
    return (
      <>
        <Dropzone
          className={"m-auto my-10"}
          typename="Ajouter un fichier ?"
          name="file"
          typeName={[
            "docx",
            "doc",
            "xlsx",
            "ppt",
            "pdf",
            "odt",
            "jpeg",
            "png",
            "mp4",
          ]}
          typeAccept={[
            "application/vnd.ms-powerpoint",
            "application/pdf",
            "image/jpeg",
            "video/mp4",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/msword",
            "application/vnd.oasis.opendocument.text",
            "image/png",
          ]}
          dropHandler={this.getFile}
        />
      </>
    );
  }
}
