import React, { Component } from "react";
import {
  Card,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Loader,
  Pagination,
  ButtonIcon,
} from "ui-kit-ck-consultant";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExportCSV } from "react-export-csv";

import { getCurrencyValue } from "../../../utils/general";
import handleSortClick from "../../../utils/handleSortClickV2";

import AuthContext from "../../../context/AuthContext";

export class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      tabLength: 20,
      isDownload: false,
    };

    this.sortRef = [];
  }

  handleSortClick = (desc, column) => {
    this.setState({ order: handleSortClick(this.sortRef, desc, column) });
  };

  sortData = () => {
    let data = this.props.data;

    if (this.state.order && this.state.order.name) {
      data = data.sort((a, b) => {
        return this.state.order.desc
          ? b[this.state.order.name] - a[this.state.order.name]
          : a[this.state.order.name] - b[this.state.order.name];
      });
    }

    return data;
  };

  static contextType = AuthContext;

  render() {
    const tableHead = [
      {
        title: "#",
      },
      {
        name: "date",
        title: "Date mission",
        nameSort: "dateMissionOrigin",
      },
      {
        name: "name",
        title: "Agence",
        nameSort: "",
      },
      {
        name: "expert",
        title: "Expert",
        nameSort: "",
      },
      {
        name: "reference",
        title: "Référence",
        nameSort: "reference",
      },
      {
        name: "natureExpertise",
        title: "Nature d'expertise",
        nameSort: "",
      },
      {
        name: "natureEvent",
        title: "Nature d'évènement",
        nameSort: "",
      },
      {
        name: "brand",
        title: "Marque",
        nameSort: "",
      },
      {
        name: "model",
        title: "Modèle",
        nameSort: "",
      },
      {
        name: "fiscalPower",
        title: "Puissance fiscale",
        nameSort: "fiscalPower",
      },
      {
        name: "client",
        title: "Lésé",
        nameSort: "",
      },
      {
        name: "gainDutyFree",
        title: "Gain",
        nameSort: "gainDutyFree",
      },
    ];

    return (
      <>
        <Card
          title="Liste"
          className="mb-10 mb-20-xl"
          classNameChildren="overflow-x-auto"
          action={
            <ButtonIcon
              small
              className="blue"
              onClick={() => {
                this.setState({ isDownload: !this.state.isDownload });
              }}
            >
              <FontAwesomeIcon icon={faDownload} />
            </ButtonIcon>
          }
        >
          <Table>
            <Thead>
              <Tr>
                {tableHead.map((elem, idx) => (
                  <Th
                    ref={(ref) => {
                      this.sortRef[idx] = ref;
                    }}
                    sort={!!elem.nameSort}
                    onSort={(desc) => this.handleSortClick(desc, idx)}
                    nameSort={elem.nameSort}
                    key={idx}
                  >
                    {elem.title}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {(!this.props.isPrint
                ? this.sortData().slice(
                    (this.state.activePage - 1) * this.state.tabLength,
                    this.state.activePage * this.state.tabLength
                  )
                : this.sortData()
              ).map((element, idx) => (
                <Tr key={idx}>
                  <Th className="nowrap">
                    {idx +
                      1 +
                      (this.state.activePage - 1) * this.state.tabLength}
                  </Th>
                  <Td className="nowrap">{element.dateMission}</Td>
                  <Td className="nowrap">{element.name}</Td>
                  <Td className="nowrap">{element.user}</Td>
                  <Td className="nowrap">{element.reference}</Td>
                  <Td>{element.natureExpertise}</Td>
                  <Td>{element.natureEvent}</Td>
                  <Td className="nowrap">{element.brand}</Td>
                  <Td>{element.model}</Td>
                  <Td className="nowrap">{element.fiscalPower}</Td>
                  <Td>{element.client}</Td>
                  <Td>{getCurrencyValue(element.gainDutyFree)}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          {!this.props.isPrint ? (
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.tabLength}
              totalItemsCount={this.props.data.length}
              pageRangeDisplayed={4}
              onChange={(activePage) => {
                this.setState({ activePage: activePage });
              }}
              firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
              lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
              prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
              nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
            />
          ) : null}
        </Card>
        <Loader show={this.state.isLoad} />
        {this.state.isDownload ? (
          <>
            <ExportCSV
              header={[
                {
                  name: "Agence",
                  key: "name",
                },
                {
                  name: "Expert",
                  key: "user",
                },
                {
                  name: "Date mission",
                  key: "dateMission",
                },
                {
                  name: "Référence",
                  key: "reference",
                },
                {
                  name: "Marque",
                  key: "brand",
                },
                {
                  name: "Nature d'expertise",
                  key: "natureExpertise",
                },
                {
                  name: "Nature d'évènement",
                  key: "natureEvent",
                },
                {
                  name: "Modèle",
                  key: "model",
                },
                {
                  name: "Gain HT",
                  key: "gainDutyFree",
                },
              ]}
              data={this.props.data}
              separator={";"}
              callback={() =>
                this.setState({
                  isDownload: !this.state.isDownload,
                })
              }
              isDownload={true}
              filename={`Incohérence.csv`}
            />
          </>
        ) : null}
      </>
    );
  }
}

export default List;
