import React, { Component } from "react";
import {
  Card,
  TabsCustom,
  TabsCustomHeader,
  TabsCustomMenu,
  TabsCustomBody,
  TabsCustomItem,
  BarChart,
  DoughnutChart,
  PieChart,
  ProgressBar,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";

export class ChartVVNR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabsIndex: 0,
    };
  }

  render() {
    let datasets = [];
    let names = [];
    let labels = [];

    let scores = [];

    this.props.data.map((element, idx) => {
      datasets.push([]);
      names.push([]);
      labels.push([]);

      Object.keys(element.data).map((key) => {
        Object.keys(element.data[key].data).map((key2) => {
          if (!names[idx].includes(key2)) {
            names[idx].push(key2);
            datasets[idx].push({
              label: element.data[key].data[key2].label,
              data: [],
              color: element.data[key].data[key2].color,
              borderColor: "#000000",
              borderWidth: 1,
            });
          }

          return null;
        });

        return null;
      });

      Object.keys(element.data).map((key) => {
        names[idx].map((name, idx2) => {
          if (element.data[key].data.hasOwnProperty(name)) {
            datasets[idx][idx2].data.push(element.data[key].data[name].data);
          } else {
            datasets[idx][idx2].data.push(0);
          }

          return null;
        });
        labels[idx].push(element.data[key].name);

        return null;
      });

      scores.push(element.score);
      return null;
    });

    return (
      <TabsCustom className="mb-10 mb-20-xl">
        <TabsCustomHeader>
          {[{ name: "Global" }, ...this.props.data].map((element, idx) => (
            <TabsCustomMenu
              key={idx}
              title={element.name}
              active={this.state.tabsIndex === idx}
              onClick={(e) => {
                this.setState({ tabsIndex: idx });
              }}
            />
          ))}
        </TabsCustomHeader>
        <TabsCustomBody noCard>
          <TabsCustomItem active={true}>
            <Card title="Graphique">
              {this.state.tabsIndex === 0 ? (
                <Row>
                  <Col xs={12} md={3} className="mb-10 d-flex">
                    <div className="m-auto">
                      <h2 className="text-center">Général</h2>
                      <DoughnutChart
                        max={100}
                        className={"m-auto mt-20"}
                        number={this.props.score.value}
                        text={this.props.score.label}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={3} className="mb-10">
                    {this.props.data.map((element, idx) => {
                      if (scores[idx].value) {
                        return (
                          <ProgressBar
                            key={idx}
                            title={element.name}
                            text={scores[idx].value + "%"}
                            value={scores[idx].value}
                            className="my-10"
                          />
                        );
                      } else {
                        return null;
                      }
                    })}
                  </Col>
                  <Col xs={12} md={3} className="mb-10 d-flex">
                    <PieChart
                      digits={0}
                      title={"Type de véhicule"}
                      className="m-auto"
                      legendDisplay={true}
                      data={this.props.dataChartKind}
                    />
                  </Col>
                  <Col xs={12} md={3} className="mb-10 d-flex">
                    <PieChart
                      digits={0}
                      title={"Statut du propriétaire"}
                      className="m-auto"
                      legendDisplay={true}
                      data={this.props.dataChartTypeOwner}
                    />
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col xs={12} md={3}>
                    <DoughnutChart
                      max={100}
                      className={"m-auto mt-20"}
                      number={scores[this.state.tabsIndex - 1].value}
                      text={"Cohérence"}
                    />
                  </Col>
                  <Col xs={12} md={9}>
                    <BarChart
                      digits={0}
                      align
                      widthBar={25}
                      isLegend={false}
                      xAxes={[
                        {
                          stacked: true,
                        },
                      ]}
                      yAxes={[
                        {
                          stacked: true,
                          gridLines: {
                            display: false,
                          },
                          display: false,
                        },
                      ]}
                      labels={labels[this.state.tabsIndex - 1]}
                      dataSet={datasets[this.state.tabsIndex - 1]}
                    />
                  </Col>
                </Row>
              )}
            </Card>
          </TabsCustomItem>
        </TabsCustomBody>
      </TabsCustom>
    );
  }
}

export default ChartVVNR;
