import React, { Component } from "react";
import FadeIn from "react-fade-in";
import {
  Card,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from "ui-kit-ck-consultant";

import ToggleTable from "../../../../performances/reparator/ToggleTable";

export default class CardTechnical extends Component {
  getGeneralValue = (id) => {
    if (this.props.data && this.props.data.hasOwnProperty(id)) {
      return this.props.data[id];
    } else {
      return "";
    }
  };

  render() {
    const dataTechnicalResult = [
      {
        title: "Nbre total de rapports d'expertise",
        expert: this.getGeneralValue("countReport"),
      },
      {
        title: "Prix moyen TTC notes d'honoraires",
        expert: this.getGeneralValue("averagePriceHonorary"),
      },
      {
        title: "% intégration des garages agréés",
        expert: this.getGeneralValue("rateApproved"),
      },
      {
        title: "Réparation non écrêté - VTG",
        special: true,
      },
      {
        title: "Nbre de véhicules réparables",
        expert: this.getGeneralValue("countReport_R_VTG"),
      },
      {
        title: "Taux PRE - Pièces de Réemploi",
        expert: this.getGeneralValue("ratePRE_R_VTG"),
      },
      {
        title: "VTG non écrêté - Coût moyen réparation HT non pondéré",
        expert: this.getGeneralValue("averagePriceTotalReport_R_VTG"),
      },
      {
        title: "Nombre de réparations VTG avec pièces de réemploi - PRE",
        expert: this.getGeneralValue("countPRE_R_VTG"),
      },
      {
        title: "Réparation écrêté (1) - VP et CTTE - hors EAD",
        special: true,
      },
      {
        title: "Nbre de véhicules réparables",
        expert: this.getGeneralValue("countReport_R_ECR"),
      },
      {
        title: "Coût moyen pièces HT",
        expert: this.getGeneralValue("averagePricePart_R_ECR"),
      },
      {
        title: "Coût horaire moyen HT",
        expert: this.getGeneralValue("averagePriceHourly_R_ECR"),
      },
      {
        title: "Nbre moyen d'heures de main d'oeuvre",
        expert: this.getGeneralValue("averageHourlyMO_R_ECR"),
      },
      {
        title: "VP et CTTE écrêté - Coût moyen réparation HT non pondéré",
        expert: this.getGeneralValue("averagePriceTotalReport_R_ECR"),
      },
      {
        title: "VP et CTTE écrêté - Coût horaire moyen ingrédient peinture",
        expert: this.getGeneralValue("averagePriceIP_R_ECR"),
      },
      {
        title: "VP et CTTE écrêté - Nbre moyen d'heures ingrédient peinture",
        expert: this.getGeneralValue("averageHourlyIP_R_ECR"),
      },
      {
        title: "EAD écrêté (1) - VP et CTTE",
        special: true,
      },
      {
        title: "Nbre de véhicules réparables",
        expert: this.getGeneralValue("countReport_R_ECR"),
      },
      {
        title: "Coût moyen pièces HT",
        expert: this.getGeneralValue("averagePricePart_R_ECR"),
      },
      {
        title: "Coût horaire moyen HT",
        expert: this.getGeneralValue("averagePriceMO_R_ECR"),
      },
      {
        title: "Nbre moyen d'heures de main d'oeuvre",
        expert: this.getGeneralValue("averageHourlyMO_R_ECR"),
      },
      // {
      //   title: "Taux d'EAD modifié",
      //   expert: this.getGeneralValue("rateEdited_EAD_ECR"),
      // },
      {
        title: "EAD écrêté - Coût moyen réparation HT non pondéré",
        expert: this.getGeneralValue("averagePricePart_EAD_ECR"),
      },
      {
        title: "EAD écrêté - Coût horaire moyen ingrédient peinture",
        expert: this.getGeneralValue("averagePriceIP_EAD_ECR"),
      },
      {
        title: "EAD écrêté - Nbre moyen d'heures ingrédient peinture",
        expert: this.getGeneralValue("averageHourlyIP_EAD_ECR"),
      },
      {
        title: "Perte totale - hors CATNAT",
        special: true,
      },
      {
        title: "Nbre de véhicules en perte totale",
        expert: this.getGeneralValue("countReport_NR"),
      },
      {
        title: "Taux de rapports déposés en Perte Totale",
        expert: this.getGeneralValue("rateReport_NR"),
      },
      {
        title: "Age moyen des véhicules en mois",
        expert: this.getGeneralValue("averageAge_NR"),
      },
      {
        title: "VRADE HT moyenne VTG non écrêté",
        expert: this.getGeneralValue("averageVRADE_NR"),
      },
      {
        title: "VRADE HT moyenne VP et CTTE écrêté (4)",
        expert: this.getGeneralValue("averageVRADE_NR_ECR"),
      },
    ];

    return (
      <Card title={"Résultats Techniques"} className={"mt-10 mt-30-xl "}>
        <ToggleTable color={"DarkPurple"}>
          <FadeIn>
            <div style={{ fontSize: "10px" }} className="mb-20 mt-30">
              <p>(1) Ecrêté = réparations HT entre 200 € et 4 600 €</p>
              <p>
                (2) Pondéré = recalculé avec le coût horaire moyen main d'oeuvre
                et ingrédient peinture France
              </p>
              <p>
                (3) pour VP et CTTE en perte totale = somme réparations
                pondérées / (somme VRADE + somme réparations pondérées)
              </p>
              <p> (4) Ecrêtement 0 € à 13 000 € HT</p>
            </div>
            <Table>
              <Thead>
                <Tr>
                  <Th hide />
                  <Th radiusTopLeft>Expert</Th>
                </Tr>
              </Thead>
              <Tbody>
                {dataTechnicalResult.map((element, idx) => (
                  <Tr key={idx}>
                    <Th
                      colSpan={element.special ? 2 : 1}
                      radiusTopLeft={idx === 0}
                    >
                      {element.title}
                    </Th>
                    {element.special ? "" : <Td>{element.expert}</Td>}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </FadeIn>
        </ToggleTable>
      </Card>
    );
  }
}
