import React, { Component } from "react";
import natureExpertise from "../../../utils/natureExpertise";
import {
  Modal,
  TabsCustom,
  TabsCustomBody,
  TabsCustomHeader,
  TabsCustomItem,
  TabsCustomMenu,
} from "ui-kit-ck-consultant";

export class ModalMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabsId: 0,
      distance: null,
    };
  }

  handleDistance(lat1, lng1, lat2, lng2) {
    let maps = this.props.googleApi;
    let directionsService = new maps.DirectionsService();
    let request = {
      origin: { lat: lat1, lng: lng1 },
      destination: { lat: lat2, lng: lng2 },
      travelMode: maps.DirectionsTravelMode.DRIVING,
    };
    directionsService.route(request, (response, status) => {
      if (status === maps.DirectionsStatus.OK) {
        let distance = Math.round(
          response.routes[0].legs[0].distance.value / 1000
        );
        this.setState({ distance });
      }
    });
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.show && prevProps.show !== this.props.show) {
      if (this.props.modalCoord) {
        this.handleDistance(
          this.props.modalCoord.agency.lat,
          this.props.modalCoord.agency.long,
          this.props.modalCoord.garage.lat,
          this.props.modalCoord.garage.long
        );
      }
    }
  };

  render() {
    return (
      <Modal
        show={this.props.show}
        onClose={this.props.onCloseModal}
        title={this.props.error ? "Erreur" : "Détails expertise"}
      >
        {this.props.error ? (
          <p>{this.props.error}</p>
        ) : !this.props.modalMulti ? (
          <>
            <p>
              Nom : <b>{this.props.modalName}</b>
            </p>
            <p>
              Référence : <b>{this.props.modalReference}</b>
            </p>
            <p>
              Agence : <b>{this.props.modalAgency}</b>
            </p>
            <p>
              Expert : <b>{this.props.modalExpert}</b>
            </p>
            <p>
              Nature d'expertise :{" "}
              <b>{natureExpertise(this.props.modalNatureExpertise)}</b>
            </p>
            {this.props.modalCoord ? (
              <p>
                Distance :{" "}
                <b>{this.state.distance + "Km " + this.props.modalLieu}</b>
              </p>
            ) : (
              this.props.modalLieu
            )}
          </>
        ) : (
          <TabsCustom>
            <TabsCustomHeader>
              {this.props.modalFolders.map((element, key) => (
                <TabsCustomMenu
                  key={key}
                  title={element.reference}
                  active={this.state.tabsId === key}
                  onClick={(e) => {
                    this.setState({ tabsId: key }, () => {
                      this.props.handleOpenModal(element.id);
                    });
                  }}
                />
              ))}
            </TabsCustomHeader>
            <TabsCustomBody noCard>
              {this.props.modalFolders.map((element, idx) => {
                return (
                  <TabsCustomItem key={idx} active={this.state.tabsId === idx}>
                    <>
                      <p>
                        Nom : <b>{this.props.modalName}</b>
                      </p>
                      <p>
                        Référence : <b>{this.props.modalReference}</b>
                      </p>
                      <p>
                        Agence : <b>{this.props.modalAgency}</b>
                      </p>
                      <p>
                        Expert : <b>{this.props.modalExpert}</b>
                      </p>
                      <p>
                        Nature d'expertise :{" "}
                        <b>
                          {natureExpertise(this.props.modalNatureExpertise)}
                        </b>
                      </p>
                      {this.props.modalCoord ? (
                        <p>
                          Distance :{" "}
                          <b>
                            {this.state.distance + "Km " + this.props.modalLieu}
                          </b>
                        </p>
                      ) : (
                        this.props.modalLieu
                      )}
                    </>
                  </TabsCustomItem>
                );
              })}
            </TabsCustomBody>
          </TabsCustom>
        )}
        {/* <script src="https://maps.googleapis.com/maps/api/js?v=3.exp"></script> */}
      </Modal>
    );
  }
}

export default ModalMap;
