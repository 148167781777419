import React from "react";
import { Col, Row } from "react-flexbox-grid";
import { Card } from "ui-kit-ck-consultant";

import PieChartSatisfaction from "./others/PieChartSatisfaction";

export default class Home extends React.Component {
  render() {
    return (
      <Card className={this.props.className} title="Accueil">
        <Row>
          <Col xs={12} lg={4} className="d-flex">
            <PieChartSatisfaction
              title={"Réponses apportées"}
              data={
                this.props.generalSatisfactionData
                  ? this.props.generalSatisfactionData["accueil_reponse"]
                  : []
              }
            />
          </Col>
          <Col xs={12} lg={4} className="d-flex">
            <PieChartSatisfaction
              title={"Amabilité de l'interlocuteur"}
              data={
                this.props.generalSatisfactionData
                  ? this.props.generalSatisfactionData["accueil_amabilite"]
                  : []
              }
            />
          </Col>
          <Col xs={12} lg={4} className="d-flex">
            <PieChartSatisfaction
              title={"Délai d'attente"}
              data={
                this.props.generalSatisfactionData
                  ? this.props.generalSatisfactionData["accueil_attente"]
                  : []
              }
            />
          </Col>
          <Col xs={12} lg={6} className="d-flex">
            <PieChartSatisfaction
              title={"Qualité d'écoute"}
              data={
                this.props.generalSatisfactionData
                  ? this.props.generalSatisfactionData["accueil_qualite"]
                  : []
              }
            />
          </Col>
          <Col xs={12} lg={6} className="d-flex">
            <PieChartSatisfaction
              title={"Compréhension"}
              data={
                this.props.generalSatisfactionData
                  ? this.props.generalSatisfactionData["accueil_comprehension"]
                  : []
              }
            />
          </Col>
        </Row>
      </Card>
    );
  }
}
