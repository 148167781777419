import React, { Component } from "react";
import {
  Loader,
  Nav,
  PaginationClass,
  FormCheckbox,
} from "ui-kit-ck-consultant";
import FadeIn from "react-fade-in";

import Declare from "../../../components/audit/technical/Declare";
import Statistics from "../../../components/audit/technical/Statistics";
import List from "../../../components/audit/technical/List";
import ShowOptionsNGRA from "../../../components/general/ShowOptionsNGRA-v2";
import PrintClass from "../../../components/general/Print";

import axiosConfig from "../../../utils/axiosConfig";

import AuthContext from "../../../context/AuthContext";

export default class Technical extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // ShowOption state
      isLoad: false,
      network: 0,
      group: 0,
      region: 0,
      agency: 0,
      user: 0,
      company: 0,
      filterCreator: false,
      // Statistics state
      data: null,
      // List state
      activePage: 1,
      tabLength: 20,
      total: 0,
      dataList: [],
      order: null,
      dateStart: null,
      dateEnd: null,
    };

    this.refPrintStatistics = React.createRef();
    this.refPrintList = React.createRef();
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.PaginationClass = new PaginationClass(
      "audit/technical/count",
      "audit/technical/pagination",
      this.state.tabLength
    );
  }

  // ShowOption data

  handleDataChange = (data) => {
    this.setState(
      {
        network: data.network,
        group: data.group,
        region: data.region,
        agency: data.agency,
        company: data.company,
        user: data.user,
        dateStart: data.dateStart,
        dateEnd: data.dateEnd,
      },
      () => {
        this.getAnomalyData();
        this.initSearch();
      }
    );
  };

  // Statistics data

  getAnomalyData() {
    this.setState({ isLoad: true });
    axiosConfig("audit/technical/get-data", {
      data: this.getData(),
    }).then((res) => {
      if (res.data.success === true) {
        this.setState({ data: res.data.data, isLoad: false });
      } else {
        this.setState({ data: null, isLoad: false });
      }
    });
  }

  // List data

  initSearch = () => {
    this.setState({ activePage: 1 }, () => {
      this.PaginationClass.init(axiosConfig, this.getData(), (result) => {
        if (result.success === true) {
          this.setState({ total: result.total }, () => {
            this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
              this.setData(result);
            });
          });
        }
      });
    });
  };

  setData(result) {
    if (result.success === true) {
      result.tabData.map((element, idx) => {
        if (element.comment && element.comment.length > 60) {
          result.tabData[idx].shortComment =
            element.comment.substring(0, 60) + "...";
          result.tabData[idx].length = true;
          result.tabData[idx].active = false;
        } else {
          result.tabData[idx].length = false;
          result.tabData[idx].active = true;
        }
        return null;
      });
      this.setState({ dataList: result.tabData });
    } else {
      this.setState({ dataList: [] });
    }
  }

  getData() {
    return {
      network: this.state.network,
      group: this.state.group,
      region: this.state.region,
      agency: this.state.agency,
      user: this.state.user,
      company: this.state.company,
      filterCreator: this.state.filterCreator,
      dateStart: this.state.dateStart,
      dateEnd: this.state.dateEnd,
      orderName: this.state.order ? this.state.order.name : null,
      orderDesc: this.state.order ? this.state.order.desc : null,
    };
  }

  handlePageChange = (activePage) => {
    this.setState({ isLoad: true });
    this.PaginationClass.page(activePage, () =>
      this.setState({ activePage: activePage }, () => {
        this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
          if (result.success === true) {
            this.setState({ isLoad: false }, () => {
              this.setData(result);
            });
          }
        });
      })
    );
  };

  handleSortClick = (order) => {
    this.setState({ order }, () => {
      this.initSearch();
    });
  };

  handleTabClick = (idx) => {
    let dataList = this.state.dataList;
    if (dataList[idx].active === false) {
      dataList[idx].active = true;
    } else {
      dataList[idx].active = false;
    }
    this.setState({ dataList: dataList });
  };

  handleDeleteAudit = (id) => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("audit/technical/delete-audit", {
        data: { id },
      }).then((res) => {
        if (res.data.success === true) {
          this.setState({ data: res.data.data, isLoad: false }, () => {
            this.getAnomalyData();
            this.initSearch();
          });
        } else {
          this.setState({ data: null, isLoad: false }, () => {
            alert("Une erreur est survenue");
          });
        }
      });
    });
  };

  render() {
    return (
      <>
        <FadeIn>
          <Nav title="Audit interne technique" className="mb-10 mb-20-xl" />
          {this.context.type === 1 ||
          this.context.type === 2 ||
          this.context.type === 3 ||
          this.context.role === 1 ||
          this.context.role === 4 ? (
            <Declare className="mb-10 mb-20-xl" />
          ) : (
            <></>
          )}
          <ShowOptionsNGRA
            isChange={true}
            className="mb-10 mb-20-xl"
            onDataChange={this.handleDataChange}
            isDateStart={true}
            isDateEnd={true}
            isCompany={true}
            isUser={true}
            visibilityRole={this.props.visibilityRole}
            visibilityType={this.props.visibilityType}
            preloadRole={this.props.preloadRole}
            preloadType={this.props.preloadType}
            limitUserType={this.props.limitUserType}
            limitUserRole={this.props.limitUserRole}
            allUserRole={this.props.allUserRole}
            isAll={true}
            userType={[1, 2, 3]}
            others={
              <>
                {[1, 4].includes(this.context.role) ? (
                  <FormCheckbox
                    text="Filtrer sur l'auditeur"
                    className="m-auto ml-0"
                    name="checked"
                    checked={this.state.filterCreator}
                    onChange={({ target }) => {
                      this.setState({
                        filterCreator: target.checked,
                      });
                    }}
                  />
                ) : null}
              </>
            }
          />
          {this.state.data ? (
            <FadeIn>
              <div ref={this.refPrintStatistics}>
                <Statistics
                  className="mb-10 mb-20-xl"
                  data={this.state.data}
                  isPrint={this.state.isPrint}
                  onLabelsLoaded={(isLoad) => this.setState({ isLoad })}
                />
              </div>
            </FadeIn>
          ) : null}
          {this.state.dataList.length > 0 ? (
            <FadeIn>
              <div ref={this.refPrintList}>
                <List
                  className="mb-10 mb-20-xl"
                  activePage={this.state.activePage}
                  tabLength={this.state.tabLength}
                  data={this.state.dataList}
                  total={this.state.total}
                  onPageChange={this.handlePageChange}
                  onSortClick={this.handleSortClick}
                  onTabClick={this.handleTabClick}
                  isPrint={this.state.isPrint}
                  onDeleteAudit={this.handleDeleteAudit}
                />
              </div>
            </FadeIn>
          ) : null}
        </FadeIn>
        <Loader show={this.state.isLoad} />
        <PrintClass
          refs={
            this.state.isPrint && this.state.isLoad
              ? [this.refPrintStatistics, this.refPrintList]
              : []
          }
          title={"Audit Interne Technique"}
          fileName={"Audit Interne Technique"}
          onPrint={(print) => this.setState({ isPrint: print, isLoad: print })}
          width={1000}
        />
      </>
    );
  }
}
