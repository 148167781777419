import React, { Component } from "react";
import { Nav, ButtonIcon, Button } from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint, faSave, faUnlock } from "@fortawesome/free-solid-svg-icons";

export default class NavPSC extends Component {
  render() {
    return (
      <div className={this.props.className}>
        {window.innerWidth < 770 && (
          <div className={"d-flex mb-20" }>
            {this.props.activate === false && (
              <h2 className={"mr-30"}>Clôturé</h2>
            )}
            <ButtonIcon
              small
              className={"mr-20 ml-auto "}
              onClick={() => this.props.handelClickPrint()}
              style={{ borderRadius: 15 }}
            >
              <FontAwesomeIcon className={"black"} icon={faPrint} />
            </ButtonIcon>
            {this.props.activate !== false && (
              <ButtonIcon
                className={"mr-20"}
                onClick={() => this.props.handelClickSave()}
              >
                <FontAwesomeIcon className={"black"} icon={faSave} />
              </ButtonIcon>
            )}
            {this.props.activate !== false && (
              <ButtonIcon onClick={() => this.props.handelClickClose()}>
                <FontAwesomeIcon className={"black"} icon={faUnlock} />
              </ButtonIcon>
            )}
          </div>
        )}
        <Nav title={this.props.title && this.props.title}>
          {window.innerWidth > 770 && (
            <div className={"d-flex"}>
              {this.props.activate === false && (
                <h2 className={"mr-20"}>Clôturé</h2>
              )}
              <ButtonIcon
                small
                className={"mr-10"}
                onClick={() => this.props.handelClickPrint()}
                style={{ backgroundColor: "#5c5c5c", borderRadius: 15 }}
                white
              >
                <FontAwesomeIcon icon={faPrint} />
              </ButtonIcon>
              {this.props.activate !== false && (
                <Button
                  className={"mr-10"}
                  onClick={() => this.props.handelClickSave()}
                  text={"ENREGISTRER"}
                >
                  <FontAwesomeIcon icon={faSave} />
                </Button>
              )}
              {this.props.activate !== false && (
                <Button
                  onClick={() => this.props.handelClickClose()}
                  text={"CLOTURER"}
                >
                  <FontAwesomeIcon icon={faUnlock} />
                </Button>
              )}
            </div>
          )}
        </Nav>
      </div>
    );
  }
}
