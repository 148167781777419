import React, { PureComponent } from "react";
import { Card } from "ui-kit-ck-consultant";
import moment from "moment";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import frLocale from "@fullcalendar/core/locales/fr";
import $ from "jquery";

moment.updateLocale("fr");

export default class CalendarLeave extends PureComponent {
  constructor(props) {
    super(props);
    this.localizer = Date.now();
    this.state = {
      data: 0,
      holidays: [],
    };
  }

  componentDidUpdate(prevProps) {
    setTimeout(() => {
      $(".remove-relative").removeClass("remove-relative");
      $(".weekend-event").parent().addClass("remove-relative");
      $(".weekend-event").parent().parent().addClass("remove-relative");
      $(".holiday-event").parent().addClass("remove-relative");
      $(".holiday-event").parent().parent().addClass("remove-relative");
    }, 1500);
  }

  render() {
    return (
      <Card title="Calendrier" className={this.props.className}>
        <FullCalendar
          initialDate={this.localizer}
          locale={frLocale}
          plugins={[dayGridPlugin, timeGridPlugin]}
          initialView="dayGridWeek"
          datesSet={() => {
            setTimeout(() => {
              $(".remove-relative").removeClass("remove-relative");
              $(".weekend-event").parent().addClass("remove-relative");
              $(".weekend-event").parent().parent().addClass("remove-relative");
              $(".holiday-event").parent().addClass("remove-relative");
              $(".holiday-event").parent().parent().addClass("remove-relative");
            }, 500);
          }}
          events={[
            {
              title: "WEEK-END",
              allDay: true,
              daysOfWeek: ["6", "0"],
              color: "rgba(25, 25, 25, 0.7)",
              className: "weekend-event",
            },
            ...this.props.dataHolidays,
            ...this.props.dataCalendar,
          ]}
          headerToolbar={{
            left: "prev,next",
            center: "title",
            right: "dayGridMonth,dayGridWeek,dayGridDay",
          }}
          contentHeight={"auto"}
          showNonCurrentDates={false}
          displayEventTime={false}
        />
      </Card>
    );
  }
}
