import React, { Component } from "react";
import FadeIn from "react-fade-in";
import {
  Card,
  TabsCustom,
  TabsCustomBody,
  TabsCustomHeader,
  TabsCustomItem,
  TabsCustomMenu,
} from "ui-kit-ck-consultant";
import TableReparator from "./TableReparator";
import ToggleTable from "./ToggleTable";
import AuthContext from "../../../context/AuthContext";

export class TargetedMonitoring extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabsMenu: ["Réparateurs Expert", "Réparateurs Assistant"],
      tabsIndex: 0,
    };
  }
  static contextType = AuthContext;

  render() {
    const filters = (
      <div style={{ fontSize: "10px" }} className={"mb-20"}>
        <p>
          Origine dossier "DARVA"; Genre véhicule "VP, CTTE"; Nature evenement :
          exclure "Force de la nature" et "Bris de glace"
        </p>
        <p>
          Total HT rapport "compris entre 20 et 5000"; Nature expertise exclure
          "I", "J" et "3"
        </p>
      </div>
    );
    return (
      <FadeIn>
        <TabsCustom className={"mt-10 mt-20-xl"}>
          <TabsCustomHeader>
            {this.state.tabsMenu.map((element, idx) => (
              <TabsCustomMenu
                key={idx}
                noCard
                title={element}
                active={this.state.tabsIndex === idx}
                onClick={() => {
                  this.setState({ tabsIndex: idx });
                }}
              />
            ))}
          </TabsCustomHeader>
          <TabsCustomBody noCard>
            <TabsCustomItem active={this.state.tabsIndex === 0}>
              <Card classNameChildren="overflow-x-auto">
                {filters}
                {this.props.followedData_expert ? (
                  this.props.followedData_expert.map((element, idx) => (
                    <ToggleTable key={idx} title={element.name}>
                      <TableReparator garage={element} />
                    </ToggleTable>
                  ))
                ) : (
                  <span>
                    Vous devez suivre un réparateur dans la section "Suivi
                    Global" pour qu'il s'affiche ici
                  </span>
                )}
              </Card>
            </TabsCustomItem>
            <TabsCustomItem active={this.state.tabsIndex === 1}>
              <Card classNameChildren="overflow-x-auto">
                {filters}
                {this.props.followedData_assistant ? (
                  this.props.followedData_assistant.map((element, idx) => (
                    <ToggleTable key={idx} title={element.name}>
                      <TableReparator garage={element} />
                    </ToggleTable>
                  ))
                ) : (
                  <span>
                    Vous devez suivre un réparateur dans la section "Suivi
                    Global" pour qu'il s'affiche ici
                  </span>
                )}
              </Card>
            </TabsCustomItem>
          </TabsCustomBody>
        </TabsCustom>
      </FadeIn>
    );
  }
}

export default TargetedMonitoring;
