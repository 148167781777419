import React, { Component } from "react";
import { Modal, Button, FormTextarea, Loader } from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";

import axiosConfig from "../../../utils/axiosConfig";

export default class ModalActionPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      comment: "",
      isLoad: false,
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "comment") {
      this.props.data[this.state.count].comment = value;
    }
    this.setState({
      [name]: value,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.show !== this.props.show) {
      this.setState({ count: 0 });
    }
    if (
      prevState.count !== this.state.count &&
      this.props.data[this.state.count].hasOwnProperty("comment")
    ) {
      this.setState({
        comment: this.props.data[this.state.count].comment,
      });
    }
  }

  handleValidFolder = () => {
    if (
      !this.props.data[this.state.count].hasOwnProperty("conforme") ||
      window.confirm(
        "Ce dossier a déjà été audité, êtes vous sûr de vouloir le modifier"
      )
    ) {
      this.setState({ isLoad: true }, () => {
        axiosConfig("audit/action-plan/valid-folder", {
          data: {
            id: this.props.data[this.state.count].id,
            type: this.props.type,
            comment: this.state.comment,
            exist: this.props.data[this.state.count].hasOwnProperty("auditId")
              ? this.props.data[this.state.count].auditId
              : null,
          },
        }).then((res) => {
          if (res.data.success === true) {
            if (this.props.data.length > this.state.count + 1) {
              this.setState((prevState) => ({
                count: prevState.count + 1,
                comment: "",
                isLoad: false,
              }));
            } else {
              this.setState({ isLoad: false }, () => this.props.onClose());
            }
          }
        });
      });
    }
  };

  handleRejectFolder = () => {
    if (
      !this.props.data[this.state.count].hasOwnProperty("conforme") ||
      window.confirm(
        "Ce dossier a déjà été audité, êtes vous sûr de vouloir le modifier"
      )
    ) {
      this.setState({ isLoad: true }, () => {
        axiosConfig("audit/action-plan/reject-folder", {
          data: {
            id: this.props.data[this.state.count].id,
            type: this.props.type,
            comment: this.state.comment,
            exist: this.props.data[this.state.count].hasOwnProperty("auditId")
              ? this.props.data[this.state.count].auditId
              : null,
          },
        }).then((res) => {
          if (res.data.success === true) {
            if (this.props.data.length > this.state.count + 1) {
              this.setState((prevState) => ({
                count: prevState.count + 1,
                comment: "",
                isLoad: false,
              }));
            } else {
              this.setState({ isLoad: false }, () => this.props.onClose());
            }
          }
        });
      });
    }
  };

  render() {
    let conforme = this.props.data[this.state.count].hasOwnProperty("conforme")
      ? this.props.data[this.state.count].conforme === 1
        ? "(Conforme)"
        : "(Non conforme)"
      : "(Nouveau)";

    console.log(this.props.data[this.state.count]);

    return (
      <>
        <Modal
          show={this.props.show}
          onClose={this.props.onClose}
          title={`${this.props.name} - Conformité dossier`}
        >
          <div
            className="d-flex"
            style={{
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p>
              Réf. expert : {this.props.data[this.state.count].reference}{" "}
              {conforme}
            </p>

            <Button
              className="w-auto"
              small
              text="Copier"
              onClick={() =>
                navigator.clipboard.writeText(
                  this.props.data[this.state.count].reference
                )
              }
            />
          </div>

          <p>Expert : {this.props.data[this.state.count].initialExpert}</p>

          <FormTextarea
            value={this.state.comment}
            name={"comment"}
            title="Commentaire"
            onChange={this.handleInputChange}
          />
          <p>
            {this.state.count + 1}/{this.props.data.length}
          </p>
          <Row>
            <Col xs={12} md={3}>
              <Button
                disabled={this.state.count === 0}
                text="Retour"
                onClick={() =>
                  this.setState({
                    count: this.state.count - 1,
                    comment: "",
                  })
                }
              />
            </Col>
            <Col xs={12} md={3}>
              <Button
                disabled={this.state.count + 1 === this.props.data.length}
                text="Suivant"
                onClick={() =>
                  this.setState({
                    count: this.state.count + 1,
                    comment: "",
                  })
                }
              />
            </Col>
            <Col xs={12} md={3}>
              <Button text="Non Conforme" onClick={this.handleRejectFolder} />
            </Col>
            <Col xs={12} md={3}>
              <Button text="Conforme" onClick={this.handleValidFolder} />
            </Col>
          </Row>
          <Loader show={this.state.isLoad} />
        </Modal>
      </>
    );
  }
}
