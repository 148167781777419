import React, { Component } from "react";
import FadeIn from "react-fade-in";
import { Col, Row } from "react-flexbox-grid";
import {
  Card,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  BarChart,
  DoughnutChart,
  Loader,
  Status,
  HorizontalBarChart,
  TabsCustom,
  TabsCustomHeader,
  TabsCustomBody,
  TabsCustomMenu,
  TabsCustomItem,
  FormRadio,
} from "ui-kit-ck-consultant";
import moment from "moment";

import axiosConfig from "../../../../utils/axiosConfig";

import { colors } from "../../../../utils/colors";

import AlphaScale from "./AXA/AlphaScale";

import AuthContext from "../../../../context/AuthContext";

export default class CardAXA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataPerformance: null,
      dataDNI: [],
      dataAlphaScale: null,
      dataAlphaScaleOld: null,
      dataAlphaScaleMonth: null,
      search: null,
      isLoad: false,
      classe: "",
      classeColor: "",
      approved: null,
      // Tabs state
      tabsData: [
        "ScoreCard",
        "AlphaScale Agence",
        "AlphaScale Expert",
        "AlphaScale Réparateur",
        "AlphaScale Département",
        "DNI",
      ],
      tabsIndex: 0,
    };
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.getPerformance();

    if (this.context.group !== 1 && this.context.network) {
      this.setState({ tabsData: ["ScoreCard"] });
    }
  }

  componentDidUpdate = (prevProps) => {
    if (
      JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data) &&
      parseInt(this.props.data.company) === 20
    ) {
      this.setState(
        {
          dataPerformance: null,
          dataAlphaScale: null,
          dataAlphaScaleOld: null,
          tabsIndex: 0,
        },
        () => {
          this.getPerformance();
        }
      );
    }
  };

  getData = () => {
    return {
      dateStart: this.props.data.dateStart,
      dateEnd: this.props.data.dateEnd,
      network: this.props.data.network,
      group: this.props.data.group,
      region: this.props.data.region,
      agency: this.props.data.agency,
      user: this.props.data.user,
      company: this.props.data.company,
      approved: this.state.approved,
    };
  };

  getPerformance = () => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("/company-performance/axa", {
        data: this.getData(),
      })
        .then((res) => {
          if (res.data.success) {
            this.setState({ data: null }, () => {
              this.setState(
                {
                  dataPerformance: res.data.result.result,
                  dataDNI: res.data.dataDNI,
                  search: res.data.search,
                  isLoad: false,
                },
                () => {
                  this.getClass();
                }
              );
            });
          } else {
            this.setState({
              dataPerformance: null,
              search: null,
              isLoad: false,
            });
          }
        })
        .catch(() => {
          this.setState({
            dataPerformance: null,
            search: null,
            isLoad: false,
          });
        });
    });
  };

  getAlphaScale = () => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("/company-performance/axa-alphascale", {
        data: this.getData(),
      })
        .then((res) => {
          if (res.data.success) {
            this.setState({
              dataAlphaScale: res.data.data,
              dataAlphaScaleOld: res.data.dataOld,
              dataAlphaScaleMonth: res.data.dataMonth,
              isLoad: false,
            });
          } else {
            this.setState({
              dataAlphaScale: null,
              dataAlphaScaleOld: null,
              isLoad: false,
            });
          }
        })
        .catch((err) => {
          this.setState({
            dataAlphaScale: null,
            dataAlphaScaleOld: null,
            isLoad: false,
          });
        });
    });
  };

  getClass() {
    const score = parseFloat(this.state.dataPerformance.scoreCard[2]);
    let classe = "";
    let classeColor = "";
    if (score >= 80) {
      classe = "A";
      classeColor = "bg-green";
    } else if (score < 80 && score >= 60) {
      classe = "B";
      classeColor = "bg-orange";
    } else {
      classe = "C";
      classeColor = "bg-red";
    }

    this.setState({ classe, classeColor });
  }

  filterMonths(arrayMonth) {
    const monthStart =
      parseInt(moment(this.props.data.dateStart).format("M")) - 1;
    const monthEnd = parseInt(moment(this.props.data.dateEnd).format("M")) - 1;

    return arrayMonth.map((month, idx) => {
      if (idx < monthStart || idx > monthEnd) {
        month = null;
      }
      return month;
    });
  }

  perimeter() {
    return (
      <div style={{ fontSize: "10px" }} className="mb-20">
        <p>{"Véhicules légers <3T5"}</p>
        <p>Hors dossiers sur évènements climatiques</p>
        <p>Hors dossiers vol total non retrouvés ou propriété compagnie</p>
        <p>- Présence d’un rapport définitif</p>
        <p>
          - Tous les dossiers dont le rapport définitif a été déposé sur l’année
          civile de la publication de la Score Card quelle que soit la date de
          missionnement
        </p>
      </div>
    );
  }

  getEvolution = (value, range = [0, 100]) => {
    if (value === null) {
      return "";
    }

    if (value < range[0]) {
      return (
        <Status
          className="bg-red-light black w-100 nowrap"
          text={`${value} %`}
        />
      );
    } else if (value < range[1]) {
      return (
        <Status
          className="bg-orange-light black w-100 nowrap"
          text={`${value} %`}
        />
      );
    } else {
      return (
        <Status
          className="bg-green-light black w-100 nowrap"
          text={`${value} %`}
        />
      );
    }
  };

  render() {
    const scoreCardData = this.state.dataPerformance
      ? [
          {
            title: "Performance de la ScoreCard",
            data: this.state.dataPerformance.scoreCard[2],
          },
          {
            title: "Performance globale / CM / Délai",
            labels: ["Perf CM (%)", "Perf Délai (%)", "Perf Global (%)"],
            data: [
              {
                color: [colors.red[0], colors.green[0], colors.blue[0]],
                data: this.state.dataPerformance.scoreCard,
              },
            ],
          },
          {
            cm: this.state.dataPerformance.scoreCard[0],
            delai: this.state.dataPerformance.scoreCard[1],
            global: this.state.dataPerformance.scoreCard[2],
            classe: this.state.classe,
            color: this.state.classeColor,
          },
          {
            title: "Performance globale",
            data: [
              {
                type: "line",
                color: colors.blue[0],
                data: this.filterMonths(this.state.dataPerformance.byMonth),
                label: "Performance globale (%)",
              },
            ],
          },
        ]
      : [];

    const CMData = this.state.dataPerformance
      ? [
          {
            NbFolders: this.state.dataPerformance.totalFiles[1],
            averageCostN: this.state.dataPerformance.totalCM[1],
            inflation: this.state.dataPerformance.totalCM[2],
            inflationPerf: this.state.dataPerformance.scoreCard[0],
          },
          {
            NbFoldersNR: this.state.dataPerformance.nbFilesNR[1],
            NbGNP: this.state.dataPerformance.nbGNP[1],
            NbGP: this.state.dataPerformance.nbGP[1],
            NbVEI: this.state.dataPerformance.nbVEI[1],
          },
          {
            title: "Étude CM",
            labels: [
              "CM non Rép",
              "CM rép GNP",
              "CM rép GP",
              "CM Vrade",
              "CM valeur de sauvetage",
            ],
            data: [
              {
                label: "Coût Moyen (€)",
                color: colors.red[0],
                data: [
                  this.state.dataPerformance.averageCostNR[1],
                  this.state.dataPerformance.averageCostGNP[1],
                  this.state.dataPerformance.averageCostGP[1],
                  this.state.dataPerformance.averageCostVRADE[1],
                  this.state.dataPerformance.averageCostResidu[1],
                ],
              },
            ],
          },
        ]
      : [];

    const delayData = this.state.dataPerformance
      ? [
          {
            delaiR: this.state.dataPerformance.delayR[1],
            perfDelaiR: this.state.dataPerformance.scoreCard[3],
            delaiVEI: this.state.dataPerformance.delayNR[1],
            perfDelaiVEI: this.state.dataPerformance.scoreCard[4],
            perfDelai: this.state.dataPerformance.scoreCard[1],
          },
          {
            title: "Étude délai Réparable",
            labels: [""],
            data: [
              {
                label: ["OM/Examen"],
                color: [colors.red[0]],
                data: [this.state.dataPerformance.delayOMExam[1]],
              },
              {
                label: ["Examen/1er rapport"],
                color: [colors.blue[0]],
                data: [this.state.dataPerformance.delayExamSD29[1]],
              },
              {
                label: ["1er rapport/facture"],
                color: [colors.gray[0]],
                data: [this.state.dataPerformance.delaySD29Invoice[1]],
              },
              {
                label: ["facture/dernier rapport"],
                color: [colors.green[0]],
                data: [this.state.dataPerformance.delayInvoiceClosing[1]],
              },
            ],
          },
          {
            MO: this.state.dataPerformance.partMO[1],
            Pieces: this.state.dataPerformance.partPieces[1],
            EAD: this.state.dataPerformance.txEAD[1],
            NR: this.state.dataPerformance.txNR[1],
            VEI: this.state.dataPerformance.txVEI[1],
          },
          {
            title: "Étude Délai VEI",
            labels: [""],
            data: [
              {
                label: ["OM/Examen VEI"],
                color: [colors.red[0]],
                data: [this.state.dataPerformance.delayOMExamVEI[1]],
              },
              {
                label: ["Examen/1er rapport VEI"],
                color: [colors.blue[0]],
                data: [this.state.dataPerformance.delayExamSD29VEI[1]],
              },
              {
                label: ["1er rapport/SD09 VEI"],
                color: [colors.green[0]],
                data: [this.state.dataPerformance.delaySD29SD09VEI[1]],
              },
            ],
          },
        ]
      : [];

    const yAxes = [
      {
        ticks: {
          display: false,
          beginAtZero: true,
          suggestedMax: 100,
        },
      },
    ];

    return (
      <>
        {this.state.tabsIndex !== 0 && this.state.tabsIndex !== 5 ? (
          <Card title="Filtres" className={"mt-10 mb-20-xl"}>
            <div className="d-flex mb-10">
              <FormRadio
                className="mr-10"
                checked={this.state.approved === null ? true : false}
                text={"Tous les réparateurs"}
                onChange={() =>
                  this.setState({ approved: null }, this.getAlphaScale)
                }
              />
              <FormRadio
                className="mr-10"
                checked={this.state.approved === true ? true : false}
                text={"Réparateurs agrés"}
                onChange={() =>
                  this.setState({ approved: true }, this.getAlphaScale)
                }
              />
              <FormRadio
                className="mr-10"
                checked={this.state.approved === false ? true : false}
                text={"Réparateurs non agrés"}
                onChange={() =>
                  this.setState({ approved: false }, this.getAlphaScale)
                }
              />
            </div>
          </Card>
        ) : null}
        <TabsCustom>
          <TabsCustomHeader>
            {this.state.tabsData.map((element, idx) => (
              <TabsCustomMenu
                key={idx}
                title={element}
                active={this.state.tabsIndex === idx}
                disabled={!this.state.tabsData[idx]}
                onClick={(e) => {
                  if (this.state.tabsIndex !== idx) {
                    this.setState({ tabsIndex: idx }, () => {
                      if (
                        this.state.tabsIndex === 0 &&
                        !this.state.dataPerformance
                      ) {
                        this.getPerformance();
                      } else if (
                        (this.state.tabsIndex === 1 ||
                          this.state.tabsIndex === 2 ||
                          this.state.tabsIndex === 3 ||
                          this.state.tabsIndex === 4) &&
                        !this.state.dataAlphaScale
                      ) {
                        this.getAlphaScale();
                      }
                    });
                  }
                }}
              />
            ))}
          </TabsCustomHeader>
          <TabsCustomBody noCard>
            <TabsCustomItem active={this.state.tabsIndex === 0}>
              {this.state.dataPerformance ? (
                <FadeIn>
                  <Card
                    title={"Score Card"}
                    className={"mt-10 mt-20-xl"}
                    classNameChildren={"overflow-x-auto"}
                  >
                    <Row middle="xs">
                      <Col xs={12} md={6} lg={6}>
                        <DoughnutChart
                          max={100}
                          className={"m-auto my-10"}
                          number={scoreCardData[0].data}
                          text={scoreCardData[0].title}
                        />
                      </Col>
                      <Col xs={12} md={6} lg={6}>
                        <BarChart
                          title={scoreCardData[1].title}
                          labels={scoreCardData[1].labels}
                          dataSet={scoreCardData[1].data}
                          yAxes={yAxes}
                        />
                      </Col>
                    </Row>
                    <Row middle="xs" className={"mt-30"}>
                      <Col xs={12} md={12} lg={6}>
                        <Table>
                          <Thead>
                            <Tr>
                              <Th radiusTopLeft>Perf CM (%)</Th>
                              <Th>Perf Délai (%)</Th>
                              <Th>Perf Globale (%)</Th>
                              <Th>Classe</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {[scoreCardData[2]].map((element, index) => (
                              <Tr key={index}>
                                <Td>{element.cm}</Td>
                                <Td>{element.delai}</Td>
                                <Td>{element.global}</Td>
                                <Td>
                                  <Status
                                    className={`${element.color}`}
                                    text={element.classe}
                                  />
                                </Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </Col>
                      <Col xs={12} md={12} lg={6} className={"mt-30"}>
                        <BarChart
                          isLegend
                          title={scoreCardData[3].title}
                          dataSet={scoreCardData[3].data}
                          yAxes={yAxes}
                        />
                      </Col>
                    </Row>
                    <div style={{ fontSize: "10px" }} className="mb-20">
                      <p>{"Véhicules légers <3T5"}</p>
                      <p>Hors dossiers sur évènements climatiques</p>
                      <p>
                        Hors dossiers vol total non retrouvés ou propriété
                        compagnie
                      </p>
                    </div>
                  </Card>

                  <Card
                    title={"CM"}
                    className={"mt-10 mt-30-xl "}
                    classNameChildren={"overflow-x-auto"}
                  >
                    <Row middle="xs">
                      <Col xs={12} md={12} lg={6}>
                        <Row>
                          <Col xs={12} md={12} lg={12} className={"m-20"}>
                            <Table title={"titre"}>
                              <Thead>
                                <Tr>
                                  <Th radiusTopLeft> Nb dossiers</Th>
                                  <Th>CM année N (€)</Th>
                                  <Th>Inflation (%)</Th>
                                  <Th>Perf Inflation (%)</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {[CMData[0]].map((element, index) => (
                                  <Tr key={index}>
                                    <Td>{element.NbFolders}</Td>
                                    <Td>{element.averageCostN}</Td>
                                    <Td>{element.inflation}</Td>
                                    <Td>{element.inflationPerf}</Td>
                                  </Tr>
                                ))}
                              </Tbody>
                            </Table>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={12} lg={12} className={"m-20"}>
                            <Table title={"titre"}>
                              <Thead>
                                <Tr>
                                  <Th radiusTopLeft> Nb Non rép</Th>
                                  <Th>Nb rép GNP</Th>
                                  <Th>Nb rép GP</Th>
                                  <Th>Nb VEI</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {[CMData[1]].map((element, index) => (
                                  <Tr key={index}>
                                    <Td>{element.NbFoldersNR}</Td>
                                    <Td>{element.NbGNP}</Td>
                                    <Td>{element.NbGP}</Td>
                                    <Td>{element.NbVEI}</Td>
                                  </Tr>
                                ))}
                              </Tbody>
                            </Table>
                          </Col>
                        </Row>
                      </Col>
                      <Col xs={12} md={12} lg={6}>
                        <HorizontalBarChart
                          className={"m-20"}
                          digits={0}
                          format="percentage"
                          height={400}
                          align
                          isLegend={true}
                          legendDisplay={true}
                          title={CMData[2].title}
                          labels={CMData[2].labels}
                          dataSet={CMData[2].data}
                        />
                      </Col>
                    </Row>
                    {this.perimeter()}
                  </Card>

                  <Card
                    title={"Délais"}
                    className={"mt-10 mt-30-xl overflow-auto mb-20"}
                  >
                    <Row middle="xs">
                      <Col xs={12} md={12} lg={6}>
                        <Table>
                          <Thead>
                            <Tr>
                              <Th radiusTopLeft> Délai réparables (Jours)</Th>
                              <Th>Perf délai réparables (%)</Th>
                              <Th>Délai VEI (Jours)</Th>
                              <Th>Perf Délai VEI (%)</Th>
                              <Th>Perf Délai (%)</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {[delayData[0]].map((element, index) => (
                              <Tr key={index}>
                                <Td>{element.delaiR}</Td>
                                <Td>{element.perfDelaiR}</Td>
                                <Td>{element.delaiVEI}</Td>
                                <Td>{element.perfDelaiVEI}</Td>
                                <Td>{element.perfDelai}</Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </Col>
                      <Col xs={12} md={12} lg={5}>
                        <HorizontalBarChart
                          digits={0}
                          format="percentage"
                          align
                          isLegend={true}
                          legendDisplay={true}
                          title={delayData[1].title}
                          labels={delayData[1].labels}
                          dataSet={delayData[1].data}
                        />
                      </Col>
                    </Row>
                    <Row middle="xs">
                      <Col xs={12} md={12} lg={6}>
                        <Table>
                          <Thead>
                            <Tr>
                              <Th radiusTopLeft> Part MO (%)</Th>
                              <Th>Part Pieces (%)</Th>
                              <Th>Taux EAD (%)</Th>
                              <Th>Taux NR (%)</Th>
                              <Th>Taux VEI (%)</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {[delayData[2]].map((element, index) => (
                              <Tr key={index}>
                                <Td>{element.MO}</Td>
                                <Td>{element.Pieces}</Td>
                                <Td>{element.EAD}</Td>
                                <Td>{element.NR}</Td>
                                <Td>{element.VEI}</Td>
                              </Tr>
                            ))}
                          </Tbody>
                        </Table>
                      </Col>
                      <Col xs={12} md={12} lg={5}>
                        <HorizontalBarChart
                          digits={0}
                          format="percentage"
                          align
                          isLegend={true}
                          legendDisplay={true}
                          title={delayData[3].title}
                          labels={delayData[3].labels}
                          dataSet={delayData[3].data}
                        />
                      </Col>
                    </Row>
                    {this.perimeter()}
                  </Card>
                </FadeIn>
              ) : null}
            </TabsCustomItem>
            <TabsCustomItem active={this.state.tabsIndex === 1}>
              <AlphaScale
                total={
                  this.state.dataAlphaScale &&
                  this.state.dataAlphaScale.total[0]
                    ? this.state.dataAlphaScale.total[0]
                    : {}
                }
                totalCSV={
                  this.state.dataAlphaScale
                    ? this.state.dataAlphaScale.totalCSV
                    : {}
                }
                data={
                  this.state.dataAlphaScale
                    ? this.state.dataAlphaScale.agency
                    : []
                }
                dataOld={
                  this.state.dataAlphaScaleOld
                    ? this.state.dataAlphaScaleOld.agency
                    : []
                }
                dataMonth={this.state.dataAlphaScaleMonth}
                isPrint={this.props.isPrint}
              />
            </TabsCustomItem>
            <TabsCustomItem active={this.state.tabsIndex === 2}>
              <AlphaScale
                total={
                  this.state.dataAlphaScale &&
                  this.state.dataAlphaScale.total[0]
                    ? this.state.dataAlphaScale.total[0]
                    : {}
                }
                totalCSV={
                  this.state.dataAlphaScale
                    ? this.state.dataAlphaScale.totalCSV
                    : {}
                }
                data={
                  this.state.dataAlphaScale
                    ? this.state.dataAlphaScale.expert
                    : []
                }
                dataOld={
                  this.state.dataAlphaScaleOld
                    ? this.state.dataAlphaScaleOld.expert
                    : []
                }
                dataMonth={this.state.dataAlphaScaleMonth}
                isPrint={this.props.isPrint}
              />
            </TabsCustomItem>
            <TabsCustomItem active={this.state.tabsIndex === 3}>
              <AlphaScale
                total={
                  this.state.dataAlphaScale &&
                  this.state.dataAlphaScale.total[0]
                    ? this.state.dataAlphaScale.total[0]
                    : {}
                }
                totalCSV={
                  this.state.dataAlphaScale
                    ? this.state.dataAlphaScale.totalCSV
                    : {}
                }
                data={
                  this.state.dataAlphaScale
                    ? this.state.dataAlphaScale.garage
                    : []
                }
                dataOld={
                  this.state.dataAlphaScaleOld
                    ? this.state.dataAlphaScaleOld.garage
                    : []
                }
                dataMonth={this.state.dataAlphaScaleMonth}
                isPrint={this.props.isPrint}
              />
            </TabsCustomItem>
            <TabsCustomItem active={this.state.tabsIndex === 4}>
              <AlphaScale
                total={
                  this.state.dataAlphaScale &&
                  this.state.dataAlphaScale.total[0]
                    ? this.state.dataAlphaScale.total[0]
                    : {}
                }
                totalCSV={
                  this.state.dataAlphaScale
                    ? this.state.dataAlphaScale.totalCSV
                    : {}
                }
                data={
                  this.state.dataAlphaScale
                    ? this.state.dataAlphaScale.department
                    : []
                }
                dataOld={
                  this.state.dataAlphaScaleOld
                    ? this.state.dataAlphaScaleOld.department
                    : []
                }
                dataMonth={this.state.dataAlphaScaleMonth}
                isPrint={this.props.isPrint}
              />
            </TabsCustomItem>
            <TabsCustomItem active={this.state.tabsIndex === 5}>
              <Card>
                <Table>
                  <Thead>
                    <Tr>
                      <Th>#</Th>
                      <Th>Nom</Th>
                      <Th>Tx. de DNI</Th>
                      <Th>Évol. N / N - 1</Th>
                      <Th>Nbr. dossiers DNI </Th>
                      <Th>Nbr. dossiers total</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {this.state.dataDNI.map((line, idx) => (
                      <Tr key={idx}>
                        <Td>{idx + 1}</Td>
                        <Td>{line.name}</Td>
                        <Td>
                          {this.getEvolution(line.rate)}
                        </Td>
                        <Td>
                          {this.getEvolution(line.evolution)}
                        </Td>
                        <Td>{line.DNI}</Td>
                        <Td>{line.total}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Card>
            </TabsCustomItem>
          </TabsCustomBody>
        </TabsCustom>

        <Loader show={this.state.isLoad} />
      </>
    );
  }
}
