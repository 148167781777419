import React, { Component } from "react";
import {
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Loader,
  ButtonIcon,
} from "ui-kit-ck-consultant";

import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AuthContext from "../../../context/AuthContext";
import ModalExportCSV from "../../general/ModalExportCSV";

export default class TableData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalExportCSV: false,
    };
  }

  static contextType = AuthContext;

  getDataTrackingIndicator = (data) => {
    let result = [];
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        let dataMonth = [];
        data[key].data.map((element, idx) => {
          dataMonth.push(<Td key={key + "-" + idx}>{element}</Td>);
          return null;
        });
        result.push(
          <Tr key={key}>
            <Th>{data[key].name}</Th>
            {dataMonth}
          </Tr>
        );
      }
    }
    return result;
  };

  getDataRepairableIndicator = (data, dataYear) => {
    let result = [];
    for (const key in data) {
      if (data.hasOwnProperty(key) && data[key]) {
        let _dataYear = [];
        let dataMonth = [];

        if (dataYear.hasOwnProperty(key) && dataYear[key]) {
          dataYear[key].data.forEach((element, idx) => {
            if (idx < 1) {
              _dataYear.push(<Td key={key + "-" + idx}>{element}</Td>);
            }
          });
        } else {
          _dataYear.push(<Td></Td>);
        }

        data[key].data.forEach((element, idx) => {
          dataMonth.push(
            this.context.role === 0 || this.context.role === 3 ? (
              <Td
                className={
                  data[key].foldersForce[idx].length > 5
                    ? "bg-green-light "
                    : data[key].foldersForce[idx].length > 0
                    ? "bg-orange-light "
                    : "bg-red-light "
                }
                key={key + "-" + idx}
              >
                {element}
              </Td>
            ) : (
              <Td
                onClick={() =>
                  element &&
                  this.props.agency &&
                  this.props.onOpenModalRepairable(key, idx, data[key].name)
                }
                className={
                  (element && this.props.agency ? "cursor-pointer " : "") +
                  (data[key].foldersForce[idx].length > 5
                    ? "bg-green-light "
                    : data[key].foldersForce[idx].length > 0
                    ? "bg-orange-light "
                    : "bg-red-light ")
                }
                key={key + "-" + idx}
              >
                {element}
              </Td>
            )
          );
          return null;
        });
        result.push(
          <Tr key={key}>
            <Th>{data[key].name}</Th>
            {_dataYear}
            {dataMonth}
          </Tr>
        );
      }
    }
    return result;
  };

  getDataUnrepairableIndicator = (data, dataYear) => {
    let result = [];
    for (const key in data) {
      if (data.hasOwnProperty(key) && data[key]) {
        let _dataYear = [];
        let dataMonth = [];

        if (dataYear.hasOwnProperty(key) && dataYear[key]) {
          dataYear[key].data.forEach((element, idx) => {
            if (idx < 1) {
              _dataYear.push(<Td key={key + "-" + idx}>{element}</Td>);
            }
          });
        } else {
          _dataYear.push(<Td></Td>);
        }

        data[key].data &&
          data[key].data.map((element, idx) => {
            dataMonth.push(
              this.context.role === 0 ||
                this.context.role === 3 ? (
                <Td
                  className={
                    data[key].foldersForce[idx].length > 5
                      ? "bg-green-light "
                      : data[key].foldersForce[idx].length > 0
                      ? "bg-orange-light "
                      : "bg-red-light "
                  }
                  key={key + "-" + idx}
                >
                  {element}
                </Td>
              ) : (
                <Td
                  onClick={() => {
                    element &&
                      this.props.agency &&
                      this.props.onOpenModalUnRepairable(
                        key,
                        idx,
                        data[key].name
                      );
                  }}
                  className={
                    (element && this.props.agency ? "cursor-pointer " : "") +
                    (data[key].foldersForce[idx].length > 5
                      ? "bg-green-light "
                      : data[key].foldersForce[idx].length > 0
                      ? "bg-orange-light "
                      : "bg-red-light ")
                  }
                  key={key + "-" + idx}
                >
                  {element}
                </Td>
              )
            );
            return null;
          });
        result.push(
          <Tr key={key}>
            <Th>{data[key].name}</Th>
            {_dataYear}
            {dataMonth}
          </Tr>
        );
      }
    }
    return result;
  };

  getNotNull = (data, rate) => {
    let result =
      data === null
        ? ""
        : data
            .toString()
            .replace(".", ",")
            .replace("€", "")
            .replace("%", "")
            .replace(/\s/g, "");
    if (rate === true)
      result = result ? (parseInt(result) / 100).toFixed(2) : "";
    return result;
  };

  getRowObject = (row, rate) => {
    if (row) {
      return {
        name: row.name,
        "01": this.getNotNull(row.data[0], rate),
        "02": this.getNotNull(row.data[1], rate),
        "03": this.getNotNull(row.data[2], rate),
        "04": this.getNotNull(row.data[3], rate),
        "05": this.getNotNull(row.data[4], rate),
        "06": this.getNotNull(row.data[5], rate),
        "07": this.getNotNull(row.data[6], rate),
        "08": this.getNotNull(row.data[7], rate),
        "09": this.getNotNull(row.data[8], rate),
        10: this.getNotNull(row.data[9], rate),
        11: this.getNotNull(row.data[10], rate),
        12: this.getNotNull(row.data[11], rate),
      };
    } else return null;
  };

  render() {
    let dataCSV = [];
    if (this.props.data.trackingIndicator) {
      Object.keys(this.props.data.trackingIndicator).map((element) => {
        const tmpData = this.props.data.trackingIndicator[element];
        if (tmpData && element !== "percentageWrecks")
          dataCSV.push(this.getRowObject(tmpData, false));
        if (tmpData && element === "percentageWrecks")
          dataCSV.push(this.getRowObject(tmpData, true));
        return null;
      });
    }
    if (this.props.data.repairableIndicator) {
      Object.keys(this.props.data.repairableIndicator).map((element) => {
        const tmpData = this.props.data.repairableIndicator[element];
        if (tmpData) dataCSV.push(this.getRowObject(tmpData, true));
        return null;
      });
    }
    if (this.props.data.unrepairableIndicator) {
      Object.keys(this.props.data.unrepairableIndicator).map((element) => {
        const tmpData = this.props.data.unrepairableIndicator[element];
        if (tmpData) dataCSV.push(this.getRowObject(tmpData, true));
        return null;
      });
    }

    return (
      <Card
        className={this.props.className}
        title="Tableaux"
        classNameChildren="overflow-x-auto"
        action={
          <ButtonIcon
            className="blue p-0"
            onClick={() => this.setState({ isModalExportCSV: true })}
          >
            <FontAwesomeIcon icon={faDownload} />
          </ButtonIcon>
        }
      >
        {this.props.filters}
        <Table
          className="mb-10"
          empty={
            Object.keys(this.props.data.trackingIndicator).length > 0
              ? ""
              : "Aucune donnée n'est disponible"
          }
        >
          <Thead>
            <Tr>
              <Th>Indicateurs de suivi</Th>
              <Th>Janvier</Th>
              <Th>Février</Th>
              <Th>Mars</Th>
              <Th>Avril</Th>
              <Th>Mai</Th>
              <Th>Juin</Th>
              <Th>Juillet</Th>
              <Th>Août</Th>
              <Th>Septembre</Th>
              <Th>Octobre</Th>
              <Th>Novembre</Th>
              <Th>Décembre</Th>
            </Tr>
          </Thead>
          <Tbody>
            {this.getDataTrackingIndicator(this.props.data.trackingIndicator)}
          </Tbody>
        </Table>

        <Table
          className="mb-10"
          empty={
            Object.keys(this.props.data.repairableIndicator).length > 0
              ? ""
              : "Aucune donnée n'est disponible"
          }
        >
          <Thead>
            <Tr>
              <Th>Indicateurs de pilotage réparable</Th>
              <Th>N-1</Th>
              <Th>Janvier</Th>
              <Th>Février</Th>
              <Th>Mars</Th>
              <Th>Avril</Th>
              <Th>Mai</Th>
              <Th>Juin</Th>
              <Th>Juillet</Th>
              <Th>Août</Th>
              <Th>Septembre</Th>
              <Th>Octobre</Th>
              <Th>Novembre</Th>
              <Th>Décembre</Th>
            </Tr>
          </Thead>
          <Tbody>
            {this.getDataRepairableIndicator(
              this.props.data.repairableIndicator,
              this.props.dataYear.repairableIndicator
            )}
          </Tbody>
          <Thead>
            <Tr>
              <Th>Indicateurs de pilotage non réparable </Th>
              <Th>N-1</Th>
              <Th>Janvier</Th>
              <Th>Février</Th>
              <Th>Mars</Th>
              <Th>Avril</Th>
              <Th>Mai</Th>
              <Th>Juin</Th>
              <Th>Juillet</Th>
              <Th>Août</Th>
              <Th>Septembre</Th>
              <Th>Octobre</Th>
              <Th>Novembre</Th>
              <Th>Décembre</Th>
            </Tr>
          </Thead>
          <Tbody>
            {this.getDataUnrepairableIndicator(
              this.props.data.unrepairableIndicator,
              this.props.dataYear.unrepairableIndicator
            )}
          </Tbody>
        </Table>
        <Loader show={this.props.loader} />

        <ModalExportCSV
          show={this.state.isModalExportCSV}
          onClose={() => this.setState({ isModalExportCSV: false })}
          header={[
            {
              name: "Indicateur de suivi 1",
              key: "name",
            },
            {
              name: "Janvier",
              key: "01",
            },
            {
              name: "Fevrier",
              key: "02",
            },
            {
              name: "Mars",
              key: "03",
            },
            {
              name: "Avril",
              key: "04",
            },
            {
              name: "Mai",
              key: "05",
            },
            {
              name: "Juin",
              key: "06",
            },
            {
              name: "Juillet",
              key: "07",
            },
            {
              name: "Aout",
              key: "08",
            },
            {
              name: "Septembre",
              key: "09",
            },
            {
              name: "Octobre",
              key: "10",
            },
            {
              name: "Novembre",
              key: "11",
            },
            {
              name: "Decembre",
              key: "12",
            },
          ]}
          data={dataCSV}
          filename={"Plan de résultat MACIF.csv"}
        />
      </Card>
    );
  }
}
