import React from "react";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPowerOff,
  faUser,
  faEllipsisV,
  faTools,
  faSearchPlus,
  faSearchMinus,
} from "@fortawesome/free-solid-svg-icons";
import { Header, ButtonIcon, Sidebar } from "ui-kit-ck-consultant";

import pilotagecabinetLogo from "../images/icon-color-pilotage-cabinet.svg";

import axiosConfig from "../utils/axiosConfig";

import AuthContext from "../context/AuthContext";

export default class DashboardLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      logo: null,
    };
    this.sidebarRef = React.createRef();
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.getGroupIcon();
  }

  handleSignOutClick() {
    var url = window.location.href;
    var arr = url.split("/");
    axiosConfig("/auth/signout", {
      data: {
        url: arr[0] + "//" + arr[2],
      },
    }).then((res) => {
      if (res.data.success === true) {
        document.location.href = res.data.redirect;
      }
    });
  }

  getGroupIcon = () => {
    if (this.context.group) {
      let logo = localStorage.getItem("logoGroup");
      let group = localStorage.getItem("idGroup");
      if (!logo || parseInt(group) !== parseInt(this.context.group)) {
        axiosConfig("/auth/get-logo").then((res) => {
          if (res.data.success === true) {
            localStorage.setItem("logoGroup", res.data.data);
            localStorage.setItem("idGroup", this.context.group);
            this.setState({ logo: res.data.data });
          }
        });
      } else if (logo) {
        this.setState({ logo });
      }
    }
  };

  render() {
    let zoom = 100;

    if (this.props.connected === false) {
      return <Redirect to="/signin" />;
    } else {
      return (
        <div className="Dashboard-container">
          <Sidebar
            icon={pilotagecabinetLogo}
            picture={this.context.picture ? this.context.picture : null}
            ref={this.sidebarRef}
            location={this.props.location}
            views={this.props.sidebar}
            onViewChange={(result) => {
              this.props.history.push(result.path.replace(/\/:id\?$/, ""));
            }}
            title={this.context.name}
            subtitle={this.context.roleStr}
            checkIfMobile={(isMobile) => {
              if (this.state.isMobile !== isMobile) {
                this.setState({ isMobile });
              }
            }}
          />
          <div className="Dashboard-container-body">
            <Header
              logo={this.state.logo}
              height="42"
              onClick={() => {
                document.location.href = document.location.origin + "/home";
              }}
            >
              {this.state.isMobile ? (
                <ButtonIcon
                  onClick={() => {
                    this.sidebarRef.current.handleHideClick();
                  }}
                >
                  <FontAwesomeIcon icon={faEllipsisV} />
                </ButtonIcon>
              ) : null}
              <ButtonIcon
                onClick={() => {
                  zoom -= 5;
                  document.body.style.zoom = `${zoom}%`;
                }}
              >
                <FontAwesomeIcon icon={faSearchMinus} />
              </ButtonIcon>
              <ButtonIcon
                onClick={() => {
                  zoom += 5;
                  document.body.style.zoom = `${zoom}%`;
                }}
              >
                <FontAwesomeIcon icon={faSearchPlus} />
              </ButtonIcon>
              <ButtonIcon
                style={{ color: "#ffffff" }}
                onClick={() => (document.location.href = this.context.url)}
              >
                <FontAwesomeIcon icon={faUser} />
              </ButtonIcon>
              {this.context.administration ? (
                <ButtonIcon
                  style={{ color: "#ffffff" }}
                  onClick={() =>
                    (document.location.href = this.context.urlAdmin)
                  }
                >
                  <FontAwesomeIcon icon={faTools} />
                </ButtonIcon>
              ) : null}
              <ButtonIcon
                style={{ color: "#ffffff" }}
                onClick={() => this.handleSignOutClick()}
              >
                <FontAwesomeIcon icon={faPowerOff} />
              </ButtonIcon>
            </Header>
            <div className="Dashboard-content">
              {this.props.connected === true ? <>{this.props.children}</> : ""}
            </div>
          </div>
        </div>
      );
    }
  }
}
