import React, { Component } from "react";
import FadeIn from "react-fade-in";
import CardPerformanceAudit from "../general/CardPerformanceAudit";
import CardPerformanceAuditActionPlan from "../general/CardPerformanceAuditActionPlan";
import moment from "moment";
import { indicatorsColors } from "../../utils/colors";

export default class AuditDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  setData = () => {
    let dataTabNbAudit,
      dataTabPercentageAudit,
      dataDoughnut,
      dataTab,
      dataTabAuditAp,
      dataTabNbAuditAp;

    if (this.props.audit) {
      dataTabNbAudit = this.props.audit.performance_audit.count_type_audit[
        "technique-expert-terrain"
      ]
        ? [
            {
              type: "bar",
              label: "Réparable",
              data:
                this.props.audit.performance_audit.count_type_audit[
                  "technique-expert-terrain"
                ] &&
                this.props.audit.performance_audit.count_type_audit[
                  "technique-expert-terrain"
                ].data,
              color: "#FF6384",
            },
            {
              type: "bar",
              label: "Incoherence",
              data:
                this.props.audit.performance_audit.count_type_audit[
                  "technique-incoherence"
                ] &&
                this.props.audit.performance_audit.count_type_audit[
                  "technique-incoherence"
                ].data,
              color: "#36A2EB",
            },
            {
              type: "bar",
              label: "VEI",
              data:
                this.props.audit.performance_audit.count_type_audit[
                  "technique-riv-vei"
                ] &&
                this.props.audit.performance_audit.count_type_audit[
                  "technique-riv-vei"
                ].data,
              color: "#FFCE56",
            },
            {
              type: "bar",
              label: "Incendie",
              data:
                this.props.audit.performance_audit.count_type_audit[
                  "technique-incendie"
                ] &&
                this.props.audit.performance_audit.count_type_audit[
                  "technique-incendie"
                ].data,
              color: "#4BC0C0",
            },
            {
              type: "bar",
              label: "VE",
              data:
                this.props.audit.performance_audit.count_type_audit[
                  "technique-ve"
                ] &&
                this.props.audit.performance_audit.count_type_audit[
                  "technique-ve"
                ].data,
              color: "#9966FF",
            },
            {
              type: "bar",
              label: "EAD",
              data:
                this.props.audit.performance_audit.count_type_audit[
                  "technique-ead"
                ] &&
                this.props.audit.performance_audit.count_type_audit[
                  "technique-ead"
                ].data,
              color: "#FF9F40",
            },
          ]
        : this.props.audit.performance_audit.count_type_audit["technique-ead"]
        ? [
            {
              type: "bar",
              label: "EAD",
              data: this.props.audit.performance_audit.count_type_audit[
                "technique-ead"
              ].data,
              color: "#FF9F40",
            },
          ]
        : [];

      dataTabPercentageAudit = [
        {
          data: [],
          color: [],
        },
      ];

      dataDoughnut = {
        number: this.props.audit.performance_audit.value,
        text: `${this.setLabelAudit(this.props.audit.performance_audit.value)}`,
      };

      dataTab = [];
      if (this.props.auditData && this.props.auditData.dossier) {
        this.props.auditData &&
          this.props.auditData.date &&
          this.props.auditData.date.map((date, idx) =>
            dataTab.push({
              date: date,
              folder: this.props.auditData.dossier
                ? this.props.auditData.dossier[idx]
                : "",
              type: this.props.auditData.type
                ? this.props.auditData.type[idx]
                : "",
              comment: this.props.auditData.commentaire
                ? this.props.auditData.commentaire[idx]
                : "",
              value: this.props.auditData.value
                ? this.props.auditData.value[idx]
                : "",
              data: this.props.auditData.data
                ? this.props.auditData.data[idx]
                : "",
            })
          );
      } else if (this.props.auditData && this.props.auditData.fs_reference) {
        this.props.auditData &&
          this.props.auditData.a_date &&
          this.props.auditData.a_date.map((date, idx) =>
            dataTab.push({
              date: date,
              user: this.props.auditData.u_name
                ? this.props.auditData.u_name[idx]
                : "",
              agency: this.props.auditData.a_name
                ? this.props.auditData.a_name[idx]
                : "",
              folder: this.props.auditData.fs_reference
                ? this.props.auditData.fs_reference[idx]
                : "",
              type: this.props.auditData.at_name
                ? this.props.auditData.at_name[idx]
                : "",
              comment: this.props.auditData.a_comment
                ? this.props.auditData.a_comment[idx]
                : "",
              value: this.props.auditData.a_value
                ? this.props.auditData.a_value[idx]
                : "",
              data: this.props.auditData.a_data
                ? this.props.auditData.a_data[idx]
                : "",
            })
          );
      } else if (this.props.auditData && this.props.auditData.fg_reference) {
        this.props.auditData &&
          this.props.auditData.a_date &&
          this.props.auditData.a_date.map((date, idx) =>
            dataTab.push({
              date: date,
              user: this.props.auditData.u_name
                ? this.props.auditData.u_name[idx]
                : "",
              agency: this.props.auditData.a_name
                ? this.props.auditData.a_name[idx]
                : "",
              folder: this.props.auditData.fg_reference
                ? this.props.auditData.fg_reference[idx]
                : "",
              type: this.props.auditData.at_name
                ? this.props.auditData.at_name[idx]
                : "",
              comment: this.props.auditData.a_comment
                ? this.props.auditData.a_comment[idx]
                : "",
              value: this.props.auditData.a_value
                ? this.props.auditData.a_value[idx]
                : "",
              data: this.props.auditData.a_data
                ? this.props.auditData.a_data[idx]
                : "",
            })
          );
      }
    }

    let dataTabLabelsAudit = [];
    if (
      this.props.audit &&
      this.props.audit.performance_audit.count_type_audit[
        "technique-expert-terrain"
      ]
    ) {
      dataTabLabelsAudit = [
        "Réparable",
        "Incoherence",
        "VEI",
        "Incendie",
        "VE",
        "EAD",
      ];
      dataTabPercentageAudit[0].data.push(
        this.props.audit.performance_audit.value_type_audit[
          "technique-expert-terrain"
        ]
          ? this.props.audit.performance_audit.value_type_audit[
              "technique-expert-terrain"
            ].data
          : 0
      );
      dataTabPercentageAudit[0].data.push(
        this.props.audit.performance_audit.value_type_audit[
          "technique-incoherence"
        ]
          ? this.props.audit.performance_audit.value_type_audit[
              "technique-incoherence"
            ].data
          : 0
      );
      dataTabPercentageAudit[0].data.push(
        this.props.audit.performance_audit.value_type_audit["technique-riv-vei"]
          ? this.props.audit.performance_audit.value_type_audit[
              "technique-riv-vei"
            ].data
          : 0
      );
      dataTabPercentageAudit[0].data.push(
        this.props.audit.performance_audit.value_type_audit["technique-ve"]
          ? this.props.audit.performance_audit.value_type_audit["technique-ve"]
              .data
          : 0
      );
      dataTabPercentageAudit[0].data.push(
        this.props.audit.performance_audit.value_type_audit["technique-vvr"]
          ? this.props.audit.performance_audit.value_type_audit["technique-vvr"]
              .data
          : 0
      );
      dataTabPercentageAudit[0].data.push(
        this.props.audit.performance_audit.value_type_audit["technique-ead"]
          ? this.props.audit.performance_audit.value_type_audit["technique-ead"]
              .data
          : 0
      );

      dataTabPercentageAudit[0].color = [
        "#FF6384",
        "#36A2EB",
        "#FFCE56",
        "#4BC0C0",
        "#9966FF",
        "#FF9F40",
      ];
    } else if (
      this.props.audit &&
      this.props.audit.performance_audit.count_type_audit["technique-ead"]
    ) {
      dataTabLabelsAudit = ["EAD"];
      dataTabPercentageAudit[0].data = [
        this.props.audit.performance_audit.value_type_audit["technique-ead"]
          .data,
      ];
      dataTabPercentageAudit[0].color = ["#FF9F40"];
    }

    dataTabAuditAp = [];
    dataTabNbAuditAp = {};

    let dougTotal = 0;
    let dougCount = 0;

    if (this.props.auditApData) {
      this.props.auditApData.fm_date.forEach((date, idx) => {
        /* CHART */
        const monthIndex = moment(date).month();
        const indicNumber = this.props.auditApData.fm_type[idx];

        if (!dataTabNbAuditAp.hasOwnProperty(indicNumber)) {
          dataTabNbAuditAp[indicNumber] = {
            type: "bar",
            label: `INDICATEUR ${indicNumber}`,
            data: Array(12).fill(0),
            total: 0,
            count: 0,
            color: indicatorsColors[indicNumber],
          };
        }

        dataTabNbAuditAp[indicNumber].data[monthIndex]++;
        dataTabNbAuditAp[indicNumber].total +=
          this.props.auditApData.fm_value[idx];
        dataTabNbAuditAp[indicNumber].count++;

        dougTotal += this.props.auditApData.fm_value[idx];
        dougCount++;

        /* TABLE */
        dataTabAuditAp.push({
          date: moment(date).format("DD/MM/YYYY HH:mm"),
          closingDate: this.props.auditApData.fd_date_closing
            ? moment(this.props.auditApData.fd_date_closing[idx]).format(
                "DD/MM/YYYY"
              )
            : "",
          assistant: this.props.auditApData.u_assistant
            ? this.props.auditApData.u_assistant[idx]
            : "",
          expert: this.props.auditApData.u_expert
            ? this.props.auditApData.u_expert[idx]
            : "",
          natureExpertise: this.props.auditApData.fi_nature_expertise
            ? this.props.auditApData.fi_nature_expertise[idx]
            : "",
          totalReportDutyFree: this.props.auditApData.fr_total_report_duty_free
            ? this.props.auditApData.fr_total_report_duty_free[idx]
            : "",
          vrade: this.props.auditApData.fr_VRADE_duty_free
            ? this.props.auditApData.fr_VRADE_duty_free[idx]
            : "",
          user: this.props.auditApData.u_name
            ? this.props.auditApData.u_name[idx]
            : "",
          agency: this.props.auditApData.a_name
            ? this.props.auditApData.a_name[idx]
            : "",
          folder: this.props.auditApData.fg_reference
            ? this.props.auditApData.fg_reference[idx]
            : "",
          type: this.props.auditApData.fm_type
            ? this.props.auditApData.fm_type[idx]
            : "",
          comment: this.props.auditApData.fm_comment
            ? this.props.auditApData.fm_comment[idx]
            : "",
          value: this.props.auditApData.fm_value
            ? this.props.auditApData.fm_value[idx]
            : "",
        });
      });
    }


    const dougResult = (dougTotal / dougCount) * 100;

    const dataDoughnutAp = {
      number: Math.round(dougResult),
      text: `${this.setLabelAudit(dougResult)}`,
    };

    let chartApData = [];

    Object.keys(dataTabNbAuditAp).forEach((monthIndex) => {
      chartApData.push(dataTabNbAuditAp[monthIndex]);
    });

    return {
      dataTabNbAudit,
      dataTabPercentageAudit,
      dataTabLabelsAudit,
      dataDoughnut,
      dataTab,
      dataTabAuditAp,
      dataTabNbAuditAp: chartApData,
      dataDoughnutAp,
    };
  };

  setLabelAudit(value) {
    if (value >= 90) {
      return "EXCELLENCE";
    } else if (value >= 80) {
      return "PREMIUM";
    } else {
      return "VIGILANCE";
    }
  }

  render() {
    const result = this.setData();

    return (
      <FadeIn className="print-audit ">
        <CardPerformanceAudit
          className={this.props.print === true ? "for-print-color " : " "}
          dataTabNbAudit={result.dataTabNbAudit}
          dataTabLabelsAudit={result.dataTabLabelsAudit}
          dataTabPercentageAudit={result.dataTabPercentageAudit}
          dataDoughnut={result.dataDoughnut}
          dataTab={result.dataTab}
          isPrint={this.props.isPrint}
        />
        <CardPerformanceAuditActionPlan
          className={this.props.print === true ? "for-print-color " : " "}
          dataTabNbAudit={result.dataTabNbAuditAp}
          dataTab={result.dataTabAuditAp}
          dataDoughnut={result.dataDoughnutAp}
          isPrint={this.props.isPrint}
        />
      </FadeIn>
    );
  }
}
