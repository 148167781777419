import React, { Component } from "react";
import { Loader, Nav, PaginationClass } from "ui-kit-ck-consultant";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FadeIn from "react-fade-in";

import Availability from "../../../components/planning/tour/Availability";
import AvailabilityModal from "../../../components/planning/tour/AvailabilityModal";
import Map from "../../../components/planning/tour/Map";
import GarageSearch from "../../../components/planning/tour/GarageSearch";
import List from "../../../components/planning/tour/List";
import ShowOptionsNGRA from "../../../components/general/ShowOptionsNGRA-v2";
import PrintClass from "../../../components/general/Print";

import axiosConfig from "../../../utils/axiosConfig";

import AuthContext from "../../../context/AuthContext";

export default class Tour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // ShowOption state
      date: 0,
      network: 0,
      group: 0,
      region: 0,
      agency: 0,
      user: 0,
      // Availability state
      isAvailability:
        localStorage.getItem("isAvailabilityTour") === "1" ? true : false,
      dataAvailability: [],
      dataAvailabilityExpert: [],
      daysAvailability: [],
      isAvailabilityModal: false,
      availabilityModalId: null,
      totalDailyAvailability: null,
      // Map state
      dataMap: [],
      isLoaderMap: false,
      // List state
      activePage: 1,
      tabLength: 20,
      total: 0,
      dataList: [],
      order: null,
      isLoad: false,
      isLoadAvailability: false,
    };

    this.refPrintDispo = React.createRef();
    this.refPrintMap = React.createRef();
    this.refPrintList = React.createRef();
  }

  static contextType = AuthContext;

  componentDidMount() {
    this.PaginationClass = new PaginationClass(
      "planning/tour/count",
      "planning/tour/pagination",
      this.state.tabLength
    );
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevState.dataAvailability !== this.state.dataAvailability ||
      prevState.daysAvailability !== this.state.daysAvailability
    ) {
      const interval = setInterval(() => {
        this.getAvailabilityData(false);
      }, 15 * 60 * 1000);
      this.setState({ interval });
    }
  };

  componentWillUnmount = () => {
    clearInterval(this.state.interval);
  };

  // ShowOption data

  handleDataChange = (data) => {
    this.setState(
      {
        date: data.dateEnd,
        network: data.network,
        group: data.group,
        region: data.region,
        agency: data.agencyMultiples,
        company: data.company,
        user: data.userMultiples,
        year: data.year,
      },
      () => {
        if (this.state.isAvailability === true) {
          this.getAvailabilityData();
        }
        this.getMapData();
        this.initSearch();
        this.getAvailabilityData();
      }
    );
  };

  // Availability data

  getAvailabilityData(loader = true) {
    this.setState({ isLoadAvailability: true }, () => {
      axiosConfig("planning/tour/get-data-availability", {
        data: {
          date: this.state.date,
          network: this.state.network,
          group: this.state.group,
          region: this.state.region,
          agency: this.state.agency,
        },
      }).then((res) => {
        if (res.data.success === true) {
          let totalDailyAvailability = 0;
          res.data.data.map(
            (element) => (totalDailyAvailability += element.count[0])
          );
          this.setState(
            {
              dataAvailability: res.data.data,
              dataAvailabilityExpert: res.data.dataExpert,
              daysAvailability: res.data.days,
              totalDailyAvailability,
              isLoadAvailability: false,
            },
            () => {
              clearInterval(this.state.interval);
            }
          );
        } else {
          this.setState({
            dataAvailability: [],
            dataAvailabilityExpert: [],
            daysAvailability: [],
            isLoadAvailability: false,
          });
        }
      });
    });
  }

  handleValidAvailabilityModal = (success) => {
    if (success === true) {
      this.getAvailabilityData();
    }
  };

  // Map data

  getMapData() {
    this.setState({ isLoaderMap: true, isLoad: true }, () => {
      axiosConfig("planning/tour/get-data-map", {
        data: {
          date: this.state.date,
          network: this.state.network,
          group: this.state.group,
          region: this.state.region,
          agency: this.state.agency,
          user: this.state.user,
        },
      }).then((res) => {
        if (res.data.success === true) {
          this.setState({
            dataMap: res.data.data,
            isLoaderMap: false,
            isLoad: false,
          });
        } else {
          this.setState({
            dataMap: [],
            isLoaderMap: false,
            isLoad: false,
          });
        }
      });
    });
  }

  // List data

  initSearch = () => {
    this.setState({ activePage: 1 }, () => {
      this.PaginationClass.init(axiosConfig, this.getData(), (result) => {
        if (result.success === true) {
          this.setState({ total: result.total }, () => {
            this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
              this.setData(result);
            });
          });
        }
      });
    });
  };

  setData(result) {
    if (result.success === true) {
      this.setState({ dataList: result.tabData });
    } else {
      this.setState({ dataList: [] });
    }
  }

  getData() {
    return {
      date: this.state.date,
      network: this.state.network,
      group: this.state.group,
      region: this.state.region,
      agency: this.state.agency,
      user: this.state.user,
      orderName: this.state.order ? this.state.order.name : null,
      orderDesc: this.state.order ? this.state.order.desc : null,
    };
  }

  handlePageChange = (activePage) => {
    this.setState({ isLoad: true });
    this.PaginationClass.page(activePage, () =>
      this.setState({ activePage: activePage }, () => {
        this.PaginationClass.get(axiosConfig, this.getData(), (result) => {
          if (result.success === true) {
            this.setState({ isLoad: false }, () => {
              this.setData(result);
            });
          }
        });
      })
    );
  };

  handleSortClick = (order) => {
    this.setState({ order }, () => {
      this.initSearch();
    });
  };

  render() {
    return (
      <>
        <FadeIn>
          <Nav title="Tournées" className="mb-10 mb-20-xl" />
          <ShowOptionsNGRA
            isChange={true}
            className="mb-10 mb-20-xl"
            onDataChange={this.handleDataChange}
            isDateEnd={true}
            dateEndName={"Date"}
            isUser={true}
            isAgencyMultiples={true}
            isUserMultiples={true}
            visibilityRole={this.props.visibilityRole}
            visibilityType={this.props.visibilityType}
            preloadRole={this.props.preloadRole}
            preloadType={this.props.preloadType}
            limitUserType={this.props.limitUserType}
            limitUserRole={this.props.limitUserRole}
            allUserRole={this.props.allUserRole}
            userType={this.context.role === 2 ? [2] : [2, 3, 5]}
            isAll={this.context.role === 2}
            notBlocked={this.context.manageAgency ? ["agency"] : []}
            isDateNoLimit={true}
          />
          {this.state.dataAvailability && this.state.daysAvailability ? (
            <>
              <FadeIn>
                <div ref={this.refPrintDispo}>
                  <Availability
                    isLoad={this.state.isLoadAvailability}
                    action={
                      this.state.isAvailability === true ? (
                        <FontAwesomeIcon icon={faTimes} />
                      ) : (
                        <FontAwesomeIcon icon={faCheck} />
                      )
                    }
                    className="mb-10 mb-20-xl"
                    data={this.state.dataAvailability}
                    dataExpert={this.state.dataAvailabilityExpert}
                    days={this.state.daysAvailability}
                    totalDailyAvailability={this.state.totalDailyAvailability}
                    onClick={() => {
                      if (this.state.isAvailability === true) {
                        localStorage.setItem("isAvailabilityTour", "0");
                        this.setState({ isAvailability: false });
                      } else {
                        localStorage.setItem("isAvailabilityTour", "1");
                        this.setState({ isAvailability: true }, () => {
                          this.getAvailabilityData();
                        });
                      }
                    }}
                    isAvailability={this.state.isAvailability}
                    onOpenModal={(id) =>
                      this.setState({
                        isAvailabilityModal: true,
                        availabilityModalId: id,
                      })
                    }
                    total={this.state.total}
                  />
                </div>
                <AvailabilityModal
                  show={this.state.isAvailabilityModal}
                  id={this.state.availabilityModalId}
                  onValidClick={this.handleValidAvailabilityModal}
                  onClose={() =>
                    this.setState({
                      isAvailabilityModal: false,
                      availabilityModalId: null,
                    })
                  }
                />
              </FadeIn>
            </>
          ) : null}
          <FadeIn>
            <GarageSearch
              className="mb-10 mb-20-xl"
              region={this.state.region}
              agency={this.state.agency}
            />
          </FadeIn>
          {this.state.dataMap ? (
            <FadeIn>
              <div ref={this.refPrintMap}>
                <Map
                  data={this.state.dataMap}
                  className="mb-10 mb-20-xl"
                  isLoader={this.state.isLoaderMap}
                  total={this.state.total}
                  totalDailyAvailability={this.state.totalDailyAvailability}
                  region={this.state.region}
                />
              </div>
            </FadeIn>
          ) : null}
          {this.state.dataList ? (
            <FadeIn>
              <div ref={this.refPrintList}>
                <List
                  className="mb-10 mb-20-xl"
                  activePage={this.state.activePage}
                  tabLength={this.state.tabLength}
                  data={this.state.dataList}
                  total={this.state.total}
                  totalDailyAvailability={this.state.totalDailyAvailability}
                  onPageChange={this.handlePageChange}
                  onSortClick={this.handleSortClick}
                  onDeleteLeaveClick={this.handleDeleteLeaveClick}
                  isPrint={this.state.isPrint}
                />
              </div>
            </FadeIn>
          ) : null}
        </FadeIn>
        <Loader show={this.state.isLoad} />
        <PrintClass
          refs={
            this.state.isPrint
              ? [this.refPrintDispo, this.refPrintMap, this.refPrintList]
              : []
          }
          title={"Tournées"}
          fileName={"Tournées"}
          onPrint={(print) => this.setState({ isPrint: print, isLoad: print })}
          width={1500}
        />
      </>
    );
  }
}
