import React, { Component } from "react";
import { PieChart } from "ui-kit-ck-consultant";

import { getPercentageValue } from "../../../utils/general";

export class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <PieChart
        digits={0}
        title={this.props.title}
        legendDisplay={false}
        data={this.props.data}
        label={(tooltipItem, data) => {
          const name = data.labels[tooltipItem.index];
          const value = `${
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
          } - ${getPercentageValue(
            data.datasets[tooltipItem.datasetIndex].percentage[
              tooltipItem.index
            ]
          )}`;

          return `${name} : ${value}`;
        }}
      />
    );
  }
}

export default Chart;
