import React, { Component } from "react";

import {
  Card,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Status,
  DoughnutChart,
  BarChart,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";

import {colors} from "../../utils/colors";

export default class CardLabelRSE extends Component {
  render() {
    const headers = ["Score", "Objectif", "Coefficient"];
    return (
      <Card className={"mt-30"} title={this.props.title}>
        {this.props.label.length ? (
          <Row>
            <Col lg={6} xs={12}>
              <Table className={"m-auto"}>
                <Thead>
                  <Tr>
                    <Th hide></Th>
                    {headers.map((header, idx) =>
                      idx !== 0 ? (
                        <Th key={idx}>{header}</Th>
                      ) : (
                        <Th radiusTopLeft key={idx}>
                          {header}
                        </Th>
                      )
                    )}
                  </Tr>
                </Thead>
                <Tbody>
                  {this.props.label.map((criteria, idx) => (
                    <Tr key={idx}>
                      {idx === 0 ? (
                        <Td radiusTopLeft style={{ width: "300px" }}>
                          {criteria.name}
                        </Td>
                      ) : (
                        <Td style={{ width: "300px" }}>{criteria.name}</Td>
                      )}

                      <Td style={{ width: "200px" }}>
                        <Status
                          className={
                            criteria.approved
                              ? "bg-green-light black"
                              : "bg-red-light black"
                          }
                          text={`${criteria.score}${criteria.unit}`}
                        />
                      </Td>
                      <Td
                        style={{ width: "200px" }}
                      >{`${criteria.superior ? ">=" : "<="} ${criteria.goal} ${criteria.unit}`}</Td>
                      <Td style={{ width: "100px" }}>{criteria.coef}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Col>
            {this.props.label.length ? (
              <Col lg={2} xs={12} className={"d-flex"}>
                <DoughnutChart
                  digits={0}
                  className={"m-auto"}
                  max={100}
                  medium={70}
                  number={this.props.label.value}
                  text={`Label RSE : ${this.props.label.gradeName}`}
                />
              </Col>
            ) : null}
            <Col xs={12} xl={4} className="mt-auto mb-auto">
              <BarChart
                title={"Évolutions Label RSE"}
                className={"mt-auto mb-auto"}
                digits={0}
                notAlign
                dataSet={[
                  {
                    type: "line",
                    label: "Évolution performance",
                    data: this.props.label.value_month,
                    color: colors["red"][0],
                  },
                  {
                    type: "line",
                    label: "Évolution performance globale",
                    data: this.props.label.value_month_globale,
                    color: colors["blue"][0],
                  },
                ]}
                format="percentage"
                dataLabelsOptions={{
                  display: true,
                  align: "top",
                  anchor: "start",
                  clamp: true,
                  color: "#000000",
                  font: {
                    size: "9",
                  },
                  formatter: (value) => Math.round(value) + "%",
                }}
                yAxes={[
                  {
                    display: false,
                    dataLabelsOptions: { display: true },
                    ticks: {
                      max: 120,
                      min: 0,
                      beginAtZero: true,
                    },
                  },
                ]}
                medium={70}
              />
            </Col>
          </Row>
        ) : null}
      </Card>
    );
  }
}
