import React, { Component } from "react";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Card,
  Pagination,
} from "ui-kit-ck-consultant";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import PlanningRow from "./PlanningRow";

import handleSortClick from "../../../utils/handleSortClick";

import AuthContext from "../../../context/AuthContext";

export default class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: {},
    };

    this.sortRef = [
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
      React.createRef(),
    ];
  }
  static contextType = AuthContext;

  handleSortClick = (desc, column, sortRef) => {
    this.setState({ order: handleSortClick(sortRef, desc, column) }, () => {
      this.props.onSortClick(this.state.order);
    });
  };

  render() {
    const tableHead = [
      {
        name: "insertDate",
        title: "Date de la demande",
        nameSort: "insertDate",
        sort: true,
      },
      {
        name: "userName",
        title: "Utilisateur",
        nameSort: "userName",
        sort: true,
      },
      {
        name: "agency",
        title: "Agence",
        nameSort: "agency",
        sort: true,
      },
      {
        name: "start",
        title: "Date de départ",
        nameSort: "start",
        sort: true,
      },
      {
        name: "end",
        title: "Date de retour",
        nameSort: "end",
        sort: true,
      },
      {
        name: "typeStart",
        title: "Type de départ",
        nameSort: "typeStart",
        sort: true,
      },
      {
        name: "typeEnd",
        title: "Type de retour",
        nameSort: "typeEnd",
        sort: true,
      },
      { name: "type", title: "Type", nameSort: "type", sort: true },
      {
        name: "managerName",
        title: "Responsable",
        nameSort: "managerName",
        sort: true,
      },
      {
        name: "rhName",
        title: "RH",
        nameSort: "rhName",
        sort: true,
      },
      {
        name: "action",
        title: "Action",
        nameSort: "action",
        sort: false,
      },
    ];

    return (
      <>
        <Card
          title={this.props.title}
          className={this.props.className}
          classNameChildren="overflow-x-auto"
        >
          <Table>
            <Thead>
              <Tr>
                {tableHead.map((elem, idx) => (
                  <Th
                    key={idx}
                    ref={this.sortRef[idx]}
                    sort={elem.sort ? true : false}
                    onSort={
                      elem.sort
                        ? (desc) =>
                            this.handleSortClick(desc, idx, this.sortRef)
                        : null
                    }
                    nameSort={elem.nameSort}
                  >
                    {elem.title}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              <PlanningRow
                isPerPersonn={this.props.isPerPersonn}
                isDataWaiting={this.props.isDataWaiting}
                updatePlannings={() => this.props.updatePlannings()}
                updateDataList={(key, value) =>
                  this.props.updateDataList(key, value)
                }
                data={this.props.data}
                tableHead={tableHead}
              />
            </Tbody>
          </Table>
          {this.props.activePage ? (
            <Pagination
              activePage={this.props.activePage}
              itemsCountPerPage={this.props.tabLength}
              totalItemsCount={this.props.total}
              pageRangeDisplayed={4}
              onChange={this.props.onPageChange.bind(this)}
              firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
              lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
              prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
              nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
            />
          ) : null}
        </Card>
      </>
    );
  }
}
