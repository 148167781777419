import React, { Component } from "react";
import { Table, Tbody, Td, Th, Thead, Tr } from "ui-kit-ck-consultant";

export class EvolutionDisplay extends Component {
  render() {
    return (
      <div>
        <Table>
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th radiusTopRight>Commentaire</Th>
            </Tr>
          </Thead>
          <Tbody>
            {this.props.evolutionData &&
              this.props.evolutionData.map((element, idx) => {
                if (element.commentaire) {
                  return (
                    <Tr key={idx}>
                      <Td>{element.date}</Td>
                      <Td
                        className={"w-100"}
                        dangerouslySetInnerHTML={{
                          __html: element.commentaire.replace(/\n/g, "<br/>"),
                        }}
                      />
                    </Tr>
                  );
                } else {
                  return null;
                }
              })}
          </Tbody>
        </Table>
      </div>
    );
  }
}

export default EvolutionDisplay;
