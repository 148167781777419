import React, { Component } from "react";
import { Col, Row } from "react-flexbox-grid";
import FadeIn from "react-fade-in";
import {
  Card,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Loader,
  Tr,
  ButtonIcon,
} from "ui-kit-ck-consultant";
import { ExportCSV } from "react-export-csv";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import axiosConfig from "../../../../utils/axiosConfig";
export class CardMPOITIER extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      dataCSV: null,
      search: null,
      isLoad: false,
      isDownload: false,
      header: [
        { name: "Statistiques", key: "name" },
        { name: "Semestre 1", key: "s1" },
        { name: "Semestre 2", key: "s2" },
        { name: "Total année", key: "year" },
      ],
    };
    this.label = [
      {
        id: "",
        text: "STATISTIQUES DOSSIERS Véhicules 1ère Catégorie (sans écrétement)",
      },
      {
        id: "nbFiles",
        text: "Nombre total de dossiers analysés HORS PERTE TOTALE",
      },
      {
        id: "nbFilesEAD",
        text: "Dont nombre d'EAD",
      },
      {
        id: "nbFilesApproved",
        text: "Nombre de dossiers analysés (hors Perte Totale) dans les garages agréés Réparmut",
      },
      {
        id: "nbFilesApprovedEAD",
        text: "Dont nombre d'EAD dans les garages agréés Reparmut",
      },
      {
        id: "nbFilesNotApproved",
        text: "Nombre de dossiers analysés (hors Perte Totale) dans les garages non agréés Réparmut",
      },
      {
        id: "nbFilesNotApprovedEAD",
        text: "Dont nombre d'EAD dans les garages non agréés Reparmut",
      },
      {
        id: "delay",
        text: "Délai moyen de rentrée des rapports",
      },
      {
        id: "txCanceled",
        text: 'Taux de rapports "annule et remplace"',
      },
      {
        id: "averageCost",
        text: "Coût moyen total TTC des réparations (hors pertes totales)",
      },
      {
        id: "averageCostApproved",
        text: "Dont coût moyen total TTC des réparations dans garages agréés Reparmut",
      },
      {
        id: "averageCostNotApproved",
        text: "Dont coût moyen total TTC des réparations dans garages non agréés Reparmut",
      },
      {
        id: "nbFilesNR",
        text: "Nombre de dossiers en perte totale",
      },
      {
        id: "averageVRADE",
        text: "VRADE",
      },
      {
        id: "nbFilesFraud",
        text: 'Nombre de dossiers "FRAUDE"',
      },
    ];
    this.title = [
      {
        text: "STATISTIQUES DOSSIERS Véhicules 1ère Catégorie (sans écrétement)",
        idx: 0,
      },
      {
        text: "STATISTIQUES GARAGES AGREES",
        idx: 3,
      },
      {
        text: "STATISTIQUES GARAGES NON AGREES",
        idx: 6,
      },
      {
        text: "STATISTIQUES RAPPORTS",
        idx: 9,
      },
      {
        text: "COUT MOYEN",
        idx: 12,
      },
      {
        text: "PERTE TOTALE",
        idx: 16,
      },
      {
        text: "FRAUDE",
        idx: 19,
      },
    ];
  }

  componentDidMount() {
    this.getPerformance();
  }

  componentDidUpdate = (prevProps) => {
    if (
      JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data) &&
      parseInt(this.props.data.company) === 7
    ) {
      this.getPerformance();
    }
  };

  getPerformance = () => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("/company-performance/mpoitier", {
        data: {
          dateStart: this.props.data.dateStart,
          dateEnd: this.props.data.dateEnd,
          network: this.props.data.network,
          group: this.props.data.group,
          region: this.props.data.region,
          agency: this.props.data.agency,
          user: this.props.data.user,
          company: this.props.data.company,
        },
      }).then((res) => {
        if (res.data.success) {
          this.setState({
            data: res.data.result,
            dataCSV: this.dataToCSV(res.data.result),
            search: res.data.search,
            isLoad: false,
          });
        } else {
          this.setState({
            data: null,
            search: null,
            isLoad: false,
          });
        }
      });
    });
  };

  dataToCSV(data) {
    let result = [];
    for (const row in data) {
      result.push({
        name: this.getIdx(row),
        s1: data[row][0],
        s2: data[row][1],
        year: data[row][2],
      });
    }
    result.sort((a, b) => {
      return parseInt(a.name) - parseInt(b.name);
    });
    this.title.forEach((el) =>
      result.splice(el.idx, 0, {
        name: el.text,
      })
    );

    return result;
  }

  getIdx = (id) => {
    let result = null;

    this.label.forEach((elemement, idx) => {
      if (elemement.id === id) {
        result = `${idx}_${elemement.text}`;
      }
    });

    return result;
  };

  render() {
    const dataFolders = this.state.data
      ? [
          [
            "Nombre total de dossiers analysés HORS PERTE TOTALE",
            ...this.state.data.nbFiles,
          ],
          ["Dont nombre d'EAD", ...this.state.data.nbFilesEAD],
        ]
      : [];

    const dataGaragesApproved = this.state.data
      ? [
          [
            "Nombre de dossiers analysés (hors Perte Totale) dans les garages agréés Réparmut",
            ...this.state.data.nbFilesApproved,
          ],
          [
            "Dont nombre d'EAD dans les garages agréés Reparmut",
            ...this.state.data.nbFilesApprovedEAD,
          ],
        ]
      : [];

    const dataGaragesNotApproved = this.state.data
      ? [
          [
            "Nombre de dossiers analysés (hors Perte Totale) dans les garages non agréés Réparmut",
            ...this.state.data.nbFilesNotApproved,
          ],
          [
            "Dont nombre d'EAD dans les garages non agréés Reparmut",
            ...this.state.data.nbFilesNotApprovedEAD,
          ],
        ]
      : [];

    const dataReport = this.state.data
      ? [
          [
            "Délai moyen de rentrée des rapports (jours)",
            ...this.state.data.delay,
          ],
          [
            'Taux de rapports annule et remplace (%)',
            ...this.state.data.txCanceled,
          ],
        ]
      : [];

    const dataAverageCost = this.state.data
      ? [
          [
            "Coût moyen total TTC des réparations (hors pertes totales) (€)",
            ...this.state.data.averageCost,
          ],
          [
            "Dont coût moyen total TTC des réparations dans garages agréés Reparmut (€)",
            ...this.state.data.averageCostApproved,
          ],
          [
            "Dont coût moyen total TTC des réparations dans garages non agréés Reparmut (€)",
            ...this.state.data.averageCostNotApproved,
          ],
        ]
      : [];

    const dataTotalLost = this.state.data
      ? [
          ["Nombre de dossiers en perte totale", ...this.state.data.nbFilesNR],
          ["VRADE", ...this.state.data.averageVRADE],
        ]
      : [];

    const dataFraude = this.state.data
      ? [['Nombre de dossiers FRAUDE', ...this.state.data.nbFilesFraud]]
      : [];

    const labelWidth = { width: "600px" };

    return (
      <div>
        <Loader show={this.state.isLoad} />
        {this.state.data ? (
          <FadeIn>
            <Card
            title="Performances"
              action={
                <ButtonIcon
                  className={"blue"}
                  onClick={() => {
                    this.setState({ isDownload: !this.state.isDownload });
                  }}
                >
                  <FontAwesomeIcon icon={faDownload} />
                </ButtonIcon>
              }
            >
              <Row>
                <Col xs={12} lg={12}>
                  <h2 className={"mt-10 mt-30-xl"}>Statistiques Dossiers:</h2>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th hide></Th>
                        <Th radiusTopLeft>1er Semestre</Th>
                        <Th>2eme Semestre</Th>
                        <Th>Total Annuel</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {dataFolders.map((row, idx) => {
                        return (
                          <Tr key={idx}>
                            {row.map((column, id) =>
                              id === 0 ? (
                                <Th
                                  radiusTopLeft={idx === 0 ? true : false}
                                  key={id}
                                  style={labelWidth}
                                >
                                  {column}
                                </Th>
                              ) : (
                                <Td key={id}>{column}</Td>
                              )
                            )}
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </Col>

                <Col xs={12} lg={12}>
                  <h2 className={"mt-10 mt-30-xl"}>
                    Statistiques Garages Agréés:
                  </h2>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th hide></Th>
                        <Th radiusTopLeft>1er Semestre</Th>
                        <Th>2eme Semestre</Th>
                        <Th>Total Annuel</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {dataGaragesApproved.map((row, idx) => {
                        return (
                          <Tr key={idx}>
                            {row.map((column, id) =>
                              id === 0 ? (
                                <Th
                                  radiusTopLeft={idx === 0 ? true : false}
                                  key={id}
                                  style={labelWidth}
                                >
                                  {column}
                                </Th>
                              ) : (
                                <Td key={id}>{column}</Td>
                              )
                            )}
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </Col>

                <Col xs={12} lg={12}>
                  <h2 className={"mt-10 mt-30-xl"}>
                    Statistiques Garages Non Agréés:
                  </h2>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th hide></Th>
                        <Th radiusTopLeft>1er Semestre</Th>
                        <Th>2eme Semestre</Th>
                        <Th>Total Annuel</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {dataGaragesNotApproved.map((row, idx) => {
                        return (
                          <Tr key={idx}>
                            {row.map((column, id) =>
                              id === 0 ? (
                                <Th
                                  radiusTopLeft={idx === 0 ? true : false}
                                  key={id}
                                  style={labelWidth}
                                >
                                  {column}
                                </Th>
                              ) : (
                                <Td key={id}>{column}</Td>
                              )
                            )}
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </Col>

                <Col xs={12} lg={12}>
                  <h2 className={"mt-10 mt-30-xl"}>Statistiques Rapports:</h2>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th hide></Th>
                        <Th radiusTopLeft>1er Semestre</Th>
                        <Th>2eme Semestre</Th>
                        <Th>Total Annuel</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {dataReport.map((row, idx) => {
                        return (
                          <Tr key={idx}>
                            {row.map((column, id) =>
                              id === 0 ? (
                                <Th
                                  radiusTopLeft={idx === 0 ? true : false}
                                  key={id}
                                  style={labelWidth}
                                >
                                  {column}
                                </Th>
                              ) : (
                                <Td key={id}>{column}</Td>
                              )
                            )}
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </Col>

                <Col xs={12} lg={12}>
                  <h2 className={"mt-10 mt-30-xl"}>Coût Moyen:</h2>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th hide></Th>
                        <Th radiusTopLeft>1er Semestre</Th>
                        <Th>2eme Semestre</Th>
                        <Th>Total Annuel</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {dataAverageCost.map((row, idx) => {
                        return (
                          <Tr key={idx}>
                            {row.map((column, id) =>
                              id === 0 ? (
                                <Th
                                  radiusTopLeft={idx === 0 ? true : false}
                                  key={id}
                                  style={labelWidth}
                                >
                                  {column}
                                </Th>
                              ) : (
                                <Td key={id}>{column}</Td>
                              )
                            )}
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </Col>

                <Col xs={12} lg={12}>
                  <h2 className={"mt-10 mt-30-xl"}>Perte Totale:</h2>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th hide></Th>
                        <Th radiusTopLeft>1er Semestre</Th>
                        <Th>2eme Semestre</Th>
                        <Th>Total Annuel</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {dataTotalLost.map((row, idx) => {
                        return (
                          <Tr key={idx}>
                            {row.map((column, id) =>
                              id === 0 ? (
                                <Th
                                  radiusTopLeft={idx === 0 ? true : false}
                                  key={id}
                                  style={labelWidth}
                                >
                                  {column}
                                </Th>
                              ) : (
                                <Td key={id}>{column}</Td>
                              )
                            )}
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </Col>

                <Col xs={12} lg={12}>
                  <h2 className={"mt-10 mt-30-xl"}>Fraude:</h2>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th hide></Th>
                        <Th radiusTopLeft>1er Semestre</Th>
                        <Th>2eme Semestre</Th>
                        <Th>Total Annuel</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {dataFraude.map((row, idx) => {
                        return (
                          <Tr key={idx}>
                            {row.map((column, id) =>
                              id === 0 ? (
                                <Th
                                  radiusTopLeft={idx === 0 ? true : false}
                                  key={id}
                                  style={labelWidth}
                                >
                                  {column}
                                </Th>
                              ) : (
                                <Td key={id}>{column}</Td>
                              )
                            )}
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </Col>
              </Row>
            </Card>
            {this.state.isDownload ? (
              <>
                <ExportCSV
                  header={this.state.header}
                  data={this.state.dataCSV}
                  separator={";"}
                  callback={() =>
                    this.setState({
                      isDownload: !this.state.isDownload,
                    })
                  }
                  isDownload={true}
                  filename={`Mutuelle de Poitiers données.csv`}
                />
              </>
            ) : null}
          </FadeIn>
        ) : null}
      </div>
    );
  }
}

export default CardMPOITIER;
