import React, { Component } from "react";
import {
  Card,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  BarChart,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";

export default class CardMACSF extends Component {
  render() {
    const Labels = ['Période 1', 'Période 2', 'N/N-1', 'Objectif', 'Écart']
    const dataQuality = [
      ['Nb missions', 186, 903, '385,5%', null, null],
      ['Nb rapports', 124, 882, '611,3%', null, null],
      [],
      ['Delai moyen de depôt rapport', '24 j', '19 j', '-20,1%', '< 18 j', '-1,4 j'],
      ['Nb rapports', 122, 774, '534,5%', null, null],
    ]
    const dataTechnical = [
      ['Nb missions', 186, 903, '385,5%', null, null],
      ['Nb rapports', 124, 882, '611,3%', null, null],
      [],
      ['Delai moyen de depôt rapport', '24 j', '19 j', '-20,1%', '< 18 j', '-1,4 j'],
      ['Nb rapports', 122, 774, '534,5%', null, null],
    ]
    const dataCharts = [
      {
        title: 'Géneral',
        labels: [],
      },
      {
        title: 'Évolution des coûts moyens',
        data: [
          {
            type: 'line',
            color: 'pink',
            label: 'CM MO (RE < 3050 €)',
            data: []
          },
          {
            type: 'line',
            color: 'DarkBlue',
            label: 'CM Ingrédients (RE < 3050 €)',
            data: []
          },
          {
            type: 'line',
            color: 'DarkRed',
            label: 'CM RE < 3050 €',
            data: []
          },
          {
            type: 'line',
            color: 'LightBlue',
            label: 'CM Pièces (RE < 3050 €)',
            data: []
          }
        ]
      },
      {
        title: 'Tx d\'envoi SD29 / Tx info 4j',
        data: [
          {
            type: 'line',
            color: 'pink',
            label: 'Tx SD29',
            data: []
          },
          {
            type: 'line',
            color: 'DarkBlue',
            label: 'Tx info 4j',
            data: []
          },
        ]
      },
      {
        title: 'Tx EAD / Tx expertises réparateurs agréés',
        data: [
          {
            type: 'line',
            color: 'pink',
            label: 'Tx EAD',
            data: []
          },
          {
            type: 'line',
            color: 'DarkBlue',
            label: 'Tx agréés',
            data: []
          },
        ]
      }
    ]

    return (
      <div>
        <Card title={"Réparable"} className={"mt-10 mt-30-xl "} classNameChildren="overflow-x-auto">
          <Table>
            <Thead>
              <Tr>
                <Th hide></Th>
                {Labels.map((value, index) => (
                  index === 0 ? (
                    <Th key={index} radiusTopLeft>
                      {value}
                    </Th>
                  ) : (
                    <Th key={index}>{value}</Th>
                  )
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {dataQuality.map((element, idx) => (
                <Tr key={idx}>
                  {element.map((value, ide) => ide === 0 ? <Th radiusTopLeft={idx === 0 || dataQuality[idx-1].length === 0 ? true: false} key={ide}>{value}</Th> : <Td key={ide}>{value}</Td>)}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Card>

        <Card title={"Maîtrise technique"} className={"mt-10 mt-30-xl "} classNameChildren="overflow-x-auto">
          <Table>
            <Thead>
              <Tr>
                <Th hide></Th>
                {Labels.map((value, index) => (
                  index === 0 ? (
                    <Th key={index} radiusTopLeft>
                      {value}
                    </Th>
                  ) : (
                    <Th key={index}>{value}</Th>
                  )
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {dataTechnical.map((element, idx) => (
                <Tr key={idx}>
                  {element.map((value, ide) => ide === 0 ? <Th radiusTopLeft={idx === 0 || dataTechnical[idx-1].length === 0 ? true: false} key={ide}>{value}</Th> : <Td key={ide}>{value}</Td>)}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Card>

        <Card title={"Graphiques"} className={"mt-10 mt-30-xl "}>
          <Row>
            {dataCharts.map((chart, idx) => {
              if (idx === 0) {
                // RADAR CHART
                return null
              } else if (idx === 1) {
                return (
                  <Col xs={12} md={12} lg={12} key={idx} className={'mt-30'}>
                    <BarChart 
                      title={chart.title}
                      dataSet={chart.data}
                      isLegend
                    />
                  </Col>
                )
              } else {
                return (
                  <Col xs={12} md={6} lg={6} key={idx} className={'mt-30'}>
                    <BarChart 
                      title={chart.title}
                      dataSet={chart.data}
                      isLegend
                    />
                  </Col>
                )
              }
            })}
          </Row>
        </Card>
      </div>
    );
  }
}
