import React, { Component } from "react";
import {
  TabsCustom,
  TabsCustomBody,
  TabsCustomHeader,
  TabsCustomItem,
  TabsCustomMenu,
} from "ui-kit-ck-consultant";

import CardPerformance from "../general/CardPerformance";
import CardLabelRSE from "../../components/performances/CardLabelRSE";

import { colors } from "../../utils/colors";

import AuthContext from "../../context/AuthContext";

export default class PerformanceDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuTabs: [],
      index: 0,
    };
  }

  static contextType = AuthContext;

  setData = () => {
    let data, dataTwo, dataDoughnut, dataTab;
    if (this.state.index === 0 && this.props.perf) {
      data = {
        title: this.props.isPrecisia
          ? ["Titre", "Statistique", "Objectif PrécisIA", "Atteinte"]
          : ["Titre", "Statistique", "Objectif", "Performance", "Atteinte"],
        data: [[], []],
      };
      this.props.perf.performance_cout.cout_reparable.map((element, key) => {
        if (!this.props.isPrecisia || key !== 2) {
          data.data[0].push(element ? element : "NC");
        }
        return null;
      });
      if (this.props.type === 2) {
        this.props.perf.performance_cout.gain &&
          this.props.perf.performance_cout.gain.map((element, key) => {
            if (!this.props.isPrecisia || key !== 2) {
              data.data[1].push(element ? element : "NC");
            }
            return null;
          });
        data.data[1].unshift("Gain (€HT)");
      } else {
        this.props.perf.performance_cout.cout_vrade &&
          this.props.perf.performance_cout.cout_vrade.map((element, key) => {
            if (!this.props.isPrecisia || key !== 2) {
              data.data[1].push(element ? element : "NC");
            }
            return null;
          });
        data.data[1].unshift("VRADE (€HT)");
      }
      data.data[0].unshift("Réparable (€HT)");
      dataDoughnut = {
        number: this.props.perf.performance_cout.value,
        text:
          this.props.typeSearch !== 4
            ? `Globale ${this.props.perf.performance_cout.value_globale}%`
            : null,
      };
      dataTab = [
        {
          type: "line",
          label: "Évolution performance coût",
          data: this.props.perf.performance_cout.value_month,
          color: colors["red"][0],
          dataLabels2: [
            {
              display: true,
              align: "top",
              anchor: "top",
              clamp: true,
              font: {
                size: "8",
              },
              formatter: (value) => Math.round(value) + "%",
            },
          ],
        },
        {
          type: "line",
          label: "Évolution performance coût globale",
          data:
            this.props.typeSearch !== 4
              ? this.props.perf.performance_cout.value_month_globale
              : null,
          color: colors["blue"][0],
          dataLabels2: [
            {
              display: true,
              align: "top",
              anchor: "top",
              clamp: true,
              font: {
                size: "8",
              },
              formatter: (value) => Math.round(value) + "%",
            },
          ],
        },
      ];
    } else if (this.state.index === 1 && this.props.perf) {
      data = {
        title: ["Titre", "Statistique", "Objectif", "Atteinte"],
        data: [[], [], [], []],
      };
      this.props.perf.performance_delai.delai_depot_r.map((element) =>
        data.data[0].push(element)
      );
      data.data[0].unshift("Délai dépôt R (j)");
      if (this.props.type === 2) {
        this.props.perf.performance_delai.delai_mission_ead.map((element) =>
          data.data[1].push(element)
        );
        data.data[1].unshift("Délai mission/EAD (j)");
      } else {
        this.props.perf.performance_delai.delai_depot_nr &&
          this.props.perf.performance_delai.delai_depot_nr.map((element) =>
            data.data[1].push(element)
          );
        data.data[1].unshift("Délai dépôt NR (j)");
        this.props.perf.performance_delai.delai_sd29 &&
          this.props.perf.performance_delai.delai_sd29.map((element) =>
            data.data[2].push(element)
          );
        data.data[2].unshift("Délai mission-SD29 (j)");
        if (this.props.type === 3) {
          this.props.perf.performance_delai.delai_mission_visite &&
            this.props.perf.performance_delai.delai_mission_visite.map(
              (element) => data.data[3].push(element)
            );
          data.data[3].unshift("Délai mission/visite (j)");
        } else {
          this.props.perf.performance_delai.delai_visite_sd29 &&
            this.props.perf.performance_delai.delai_visite_sd29.map((element) =>
              data.data[3].push(element)
            );
          data.data[3].unshift("Délai visite-SD29 (j)");
        }
      }
      dataDoughnut = {
        number: this.props.perf.performance_delai.value,
        text:
          this.props.typeSearch !== 4
            ? `Globale ${this.props.perf.performance_delai.value_globale}%`
            : null,
      };
      dataTab = [
        {
          type: "line",
          label: "Évolution performance delai",
          data: this.props.perf.performance_delai.value_month,
          color: colors["red"][0],
        },
        {
          type: "line",
          label: "Évolution performance delai globale",
          data:
            this.props.typeSearch !== 4
              ? this.props.perf.performance_delai.value_month_globale
              : null,
          color: colors["blue"][0],
        },
      ];
    } else if (this.state.index === 2 && this.props.perf) {
      data = {
        title: ["Titre", "Reçu", "Déposé", "Atteinte"],
        data: [[]],
      };
      this.props.perf.performance_production.productivite.map((element) =>
        data.data[0].push(element)
      );
      data.data[0].unshift("Productivité (nb)");
      let tmp = data.data[0][2];
      data.data[0][2] = data.data[0][1];
      data.data[0][1] = tmp;
      console.log(this.props.perf);
      
      if (this.props.perf.displayHonorary === 1) {
        dataTwo = {
          title: ["Titre", "A facturer", "Facturé", "Atteinte"],
          data: [[]],
        };
        this.props.perf.performance_production.honoraire.map((element) =>
          dataTwo.data[0].push(element)
        );
        dataTwo.data[0].unshift("Honoraire (€HT)");
        tmp = dataTwo.data[0][2];
        dataTwo.data[0][2] = dataTwo.data[0][1];
        dataTwo.data[0][1] = tmp;
      } else dataTwo = null;
      dataDoughnut = {
        number: this.props.perf.performance_production.value,
        text:
          this.props.typeSearch !== 4
            ? `Globale ${this.props.perf.performance_production.value_globale}%`
            : null,
      };
      dataTab = [
        {
          type: "line",
          label: "Évolution performance production",
          data: this.props.perf.performance_production.value_month,
          color: colors["red"][0],
        },
        {
          type: "line",
          label: "Évolution performance production globale",
          data:
            this.props.typeSearch !== 4
              ? this.props.perf.performance_production.value_month_globale
              : null,
          color: colors["blue"][0],
        },
      ];
    } else if (this.state.index === 3 && this.props.perf) {
      data = {
        title: ["Titre", "Statistique", "Objectif", "Atteinte"],
        data: [[], [], [], [], []],
      };

      this.props.perf.performance_qualite.nombre_anomalie.map((element) =>
        data.data[0].push(element)
      );

      if (
        this.props.type !== 3 &&
        this.props.perf.performance_qualite.resultat_audit
      ) {
        this.props.perf.performance_qualite.resultat_audit.map((element) =>
          data.data[1].push(element)
        );
        data.data[1].unshift("Résultat d'audit (%)");
      }

      if (this.props.perf.performance_qualite.hasOwnProperty("satisfaction")) {
        this.props.perf.performance_qualite.satisfaction.map((element) =>
          data.data[this.props.type === 3 ? 1 : 4].push(element)
        );
        data.data[this.props.type === 3 ? 1 : 4].unshift("Satisfaction");
      }

      if (
        this.props.type !== 3 &&
        this.props.perf.performance_qualite.precision
      ) {
        this.props.perf.performance_qualite.precision.map((element) =>
          data.data[3].push(element)
        );
      }

      data.data[0].unshift("Nombre d'anomalies");
      data.data[2].unshift("Résultat d'audit AP (%)");
      if (this.props.type !== 3) {
        data.data[3].unshift("Précision (%)");
      }

      if (
        this.props.type !== 3 &&
        this.props.perf.performance_qualite.resultat_audit_AP
      ) {
        this.props.perf.performance_qualite.resultat_audit_AP.map((element) =>
          data.data[2].push(element)
        );
      }

      dataDoughnut = {
        number: this.props.perf.performance_qualite.value,
        text:
          this.props.typeSearch !== 4
            ? `Globale ${this.props.perf.performance_qualite.value_globale} %`
            : null,
      };
      dataTab = [
        {
          type: "line",
          label: "Évolution performance qualite",
          data: this.props.perf.performance_qualite.value_month,
          color: colors["red"][0],
        },
        {
          type: "line",
          label: "Évolution performance qualite globale",
          data:
            this.props.typeSearch !== 4
              ? this.props.perf.performance_qualite.value_month_globale
              : null,
          color: colors["blue"][0],
        },
      ];
    } else if (
      ((this.state.index === 5 && this.context.group === 1) ||
        this.state.index === 4) &&
      this.props.perf
    ) {
      dataDoughnut = {
        number: this.props.perf.performance_globale.value,
        text:
          this.props.typeSearch !== 4
            ? `Globale ${this.props.perf.performance_globale.value_globale} %`
            : null,
      };
      dataTab = [
        {
          type: "line",
          label: "Évolution performance",
          data: this.props.perf.performance_globale.value_month,
          color: colors["red"][0],
        },
        {
          type: "line",
          label: "Évolution performance globale",
          data:
            this.props.typeSearch !== 4
              ? this.props.perf.performance_globale.value_month_globale
              : null,
          color: colors["blue"][0],
        },
      ];
    }

    return { data, dataDoughnut, dataTab, dataTwo };
  };

  formatRSEDataEAD(data, commonStandard) {
    let standard = JSON.parse(JSON.stringify(commonStandard));
    standard.push(
      {
        name: "Taux de passage en terrain",
        unit: " %",
        score: data.tx_transit_to_field.value
          ? data.tx_transit_to_field.value
          : 0,
        approved: data.tx_transit_to_field.approved
          ? data.tx_transit_to_field.approved
          : false,
        coef: 2,
        goal: data.tx_transit_to_field.objectif
          ? data.tx_transit_to_field.objectif
          : 0,
        superior: data.tx_transit_to_field.superior
          ? data.tx_transit_to_field.superior
          : false,
      },
      {
        name: "Delai entre la date d'EAD et le dépot du dossier",
        unit: " jours",
        score: data.delay_EAD_to_deposit.value
          ? data.delay_EAD_to_deposit.value
          : 0,
        approved: data.delay_EAD_to_deposit.approved
          ? data.delay_EAD_to_deposit.approved
          : false,
        coef: 1,
        goal: data.delay_EAD_to_deposit.objectif
          ? data.delay_EAD_to_deposit.objectif
          : 0,
        superior: data.delay_EAD_to_deposit.superior
          ? data.delay_EAD_to_deposit.superior
          : false,
      }
    );
    standard.value = data.value;
    standard.value_globale = data.value_globale;
    standard.value_month = data.value_month;
    standard.value_month_globale = data.value_month_globale;
    standard.global = data.global;
    standard.gradeName = data.gradeName;

    return standard;
  }

  formatRSEData(data, commonStandard) {
    let standard = JSON.parse(JSON.stringify(commonStandard));
    standard.push(
      {
        name: "Taux de VEI",
        unit: " %",
        score: data.tx_VEI.value ? data.tx_VEI.value : 0,
        approved: data.tx_VEI.approved ? data.tx_VEI.approved : false,
        coef: 1.5,
        goal: data.tx_VEI.objectif ? data.tx_VEI.objectif : 0,
        superior: data.tx_VEI.superior ? data.tx_VEI.superior : false,
      },
      {
        name: "Delai entre la visite terrain et le dépot du dossier",
        unit: " jours",
        score: data.delay_visit_to_deposit.value
          ? data.delay_visit_to_deposit.value
          : 0,
        approved: data.delay_visit_to_deposit.approved
          ? data.delay_visit_to_deposit.approved
          : false,
        coef: 1,
        goal: data.delay_visit_to_deposit.objectif
          ? data.delay_visit_to_deposit.objectif
          : 0,
        superior: data.delay_visit_to_deposit.superior
          ? data.delay_visit_to_deposit.superior
          : false,
      },
      {
        name: "Taux d'expertise au cabinet",
        unit: " %",
        score: data.tx_expertise_cabinet.value
          ? data.tx_expertise_cabinet.value
          : 0,
        approved: data.tx_expertise_cabinet.approved
          ? data.tx_expertise_cabinet.approved
          : false,
        coef: 1.5,
        goal: data.tx_expertise_cabinet.objectif
          ? data.tx_expertise_cabinet.objectif
          : 0,
        superior: data.tx_expertise_cabinet.superior
          ? data.tx_expertise_cabinet.superior
          : false,
      },
      {
        name: "Taux de dossiers avec visite en cours de travaux",
        unit: " %",
        score: data.tx_visit_works.value ? data.tx_visit_works.value : 0,
        approved: data.tx_visit_works.approved
          ? data.tx_visit_works.approved
          : false,
        coef: 1.5,
        goal: data.tx_visit_works.objectif ? data.tx_visit_works.objectif : 0,
        superior: data.tx_visit_works.superior
          ? data.tx_visit_works.superior
          : false,
      }
    );
    standard.value = data.value;
    standard.value_globale = data.value_globale;
    standard.value_month = data.value_month;
    standard.value_month_globale = data.value_month_globale;
    standard.global = data.global;
    standard.gradeName = data.gradeName;
    return standard;
  }

  getCommonStandard(data) {
    return [
      {
        name: "Taux de réparation / remplacement des pièces",
        unit: " %",
        score: data.tx_repair.value ? data.tx_repair.value : 0,
        approved: data.tx_repair.approved ? data.tx_repair.approved : false,
        coef: 2,
        goal: data.tx_repair.objectif ? data.tx_repair.objectif : 0,
        superior: data.tx_repair.superior,
      },
      {
        name: "Taux de PRE",
        unit: " %",
        score: data.tx_PRE.value ? data.tx_PRE.value : 0,
        approved: data.tx_PRE.approved ? data.tx_PRE.approved : false,
        coef: 1.5,
        goal: data.tx_PRE.objectif ? data.tx_PRE.objectif : 0,
        superior: data.tx_PRE.superior,
      },
      {
        name: "Taux de PQE",
        unit: " %",
        score: data.tx_PQE.value ? data.tx_PQE.value : 0,
        approved: data.tx_PQE.approved ? data.tx_PQE.approved : false,
        coef: 0.5,
        goal: data.tx_PQE.objectif ? data.tx_PQE.objectif : 0,
        superior: data.tx_PQE.superior,
      },
      {
        name: "Jours de formation",
        unit: " jours",
        score: data.nb_formation_days.value ? data.nb_formation_days.value : 0,
        approved: data.nb_formation_days.approved
          ? data.nb_formation_days.approved
          : false,
        coef: 1,
        goal: data.nb_formation_days.objectif
          ? data.nb_formation_days.objectif
          : 0,
        superior: data.nb_formation_days.superior,
      },
    ];
  }

  render() {
    let menuTabs = [];
    if (this.props.perf) {
      if (this.context.group === 1 && this.props.perf.performance_RSE) {
        menuTabs = [
          "Performance coût",
          "Performance délai",
          "Performance production",
          "Performance qualité",
          "Label RSE",
          "Performance globale",
        ];
      } else {
        menuTabs = [
          "Performance coût",
          "Performance délai",
          "Performance production",
          "Performance qualité",
          "Performance globale",
        ];
      }
    } else {
      menuTabs = [];
    }
    let labelRSE = [];
    const properties = [
      "tx_repair",
      "tx_PRE",
      "tx_PQE",
      "nb_formation_days",
      "delay_visit_to_deposit",
      "tx_expertise_cabinet",
      "tx_visit_works",
    ];
    const propertiesEAD = [
      "tx_repair",
      "tx_PRE",
      "tx_PQE",
      "nb_formation_days",
      "tx_transit_to_field",
      "delay_EAD_to_deposit",
    ];
    if (this.props.perf) {
      //Securities for every property
      if (this.props.type === 1 && this.props.perf.performance_RSE) {
        if (
          properties.every((prop) =>
            this.props.perf.performance_RSE.hasOwnProperty(prop)
          )
        ) {
          //labelRSE
          labelRSE = this.formatRSEData(
            this.props.perf.performance_RSE,
            this.getCommonStandard(this.props.perf.performance_RSE)
          );
        } else {
          labelRSE = [];
        }
        //Securities for every property
      } else if (this.props.type === 2 && this.props.perf.performance_RSE) {
        if (
          propertiesEAD.every((prop) =>
            this.props.perf.performance_RSE.hasOwnProperty(prop)
          )
        ) {
          //labelRSE
          labelRSE = this.formatRSEDataEAD(
            this.props.perf.performance_RSE,
            this.getCommonStandard(this.props.perf.performance_RSE)
          );
        } else {
          labelRSE = [];
        }
      }
    }
    const result = this.setData();
    return (
      <TabsCustom>
        {this.props.perf ? (
          <>
            <TabsCustomHeader className={"mt-10 mt-30-xl"}>
              {menuTabs.map((element, key) => (
                <TabsCustomMenu
                  disabled={this.props.isLoader}
                  key={key}
                  title={element}
                  active={this.state.index === key}
                  onClick={(e) => {
                    this.setState({ index: key });
                  }}
                />
              ))}
            </TabsCustomHeader>
            <TabsCustomBody noCard>
              {menuTabs.map((element, idx) => {
                return (
                  <TabsCustomItem active={this.state.index === idx} key={idx}>
                    {this.state.index !== 4 ||
                    this.context.group !== 1 ||
                    this.props.type === 3 ? (
                      <CardPerformance
                        data={this.props.perf && result.data}
                        dataTwo={this.props.perf && result.dataTwo}
                        dataDoughnut={this.props.perf && result.dataDoughnut}
                        dataTab={this.props.perf && result.dataTab}
                        type={this.props.type}
                        tab={element}
                        isPrecisia={this.props.isPrecisia}
                      />
                    ) : (
                      <CardLabelRSE label={labelRSE} />
                    )}
                  </TabsCustomItem>
                );
              })}
            </TabsCustomBody>
          </>
        ) : null}
      </TabsCustom>
    );
  }
}
