import React, { Component } from "react";
import { Card } from "ui-kit-ck-consultant";

export default class Library extends Component {
  render() {
    return (
      <Card className="h-100" classNameChildren="h-100">
        <iframe
          title="biblio'tech"
          src="https://glasoftware.fr/#/library-empty"
          style={{
            borderRadius: "8px",
            border: 0,
            height: "100%",
            width: "100%",
            overflow: "hidden",
          }}
        />
      </Card>
    );
  }
}
