import React, { Component } from 'react'
import { Table, Tbody, Td, Th, Thead, Tr } from 'ui-kit-ck-consultant';
import './MonthlyBilanDisplay.css'

export class MonthlyBilanDisplay extends Component {
  constructor(props) {
    super(props)
    this.refColumn1 = React.createRef()
  }



  handleSortClick(desc, column) {
      this.refColumn1.current.setState({ desc: false, sort: false });
  }



  render() {
    return (
      <div className="mt-30 mb-30">
          <Table>
            <Thead>
              <Tr >
                <Th radiusTopLeft sort onSort={(desc) => this.handleSortClick(desc, 0)} ref={this.refColumn1}>Date</Th>
                <Th className={'tableHeader'}>Analyse Manager</Th>
                <Th className={'tableHeader'}>Analyse Collaborateur</Th>
              </Tr>
            </Thead>
            <Tbody>
              {this.props.monthlyBilan && this.props.monthlyBilan.map((bilan, idx) => {
                const realDate = new Date(bilan.date).toISOString().split("T")[0].split("-")
                const date = realDate[2] + "/" + realDate[1] + "/" + realDate[0]
                return (
                  <Tr key={idx}>
                    <Th>{date}</Th>
                    <Td >{bilan.analyse_manager}</Td>
                    <Td >{bilan.analyse_collaborateur}</Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
      </div>
    )
  }
}

export default MonthlyBilanDisplay
