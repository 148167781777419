import React, { Component } from "react";
import FadeIn from "react-fade-in";
import {
  Card,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Loader,
} from "ui-kit-ck-consultant";

import ToggleTable from "../../../performances/reparator/ToggleTable";

import axiosConfig from "../../../../utils/axiosConfig";

export default class CardGroupamaGan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      search: null,
      isLoad: false,
    };
  }

  componentDidMount() {
    this.getPerformance();
  }

  componentDidUpdate = (prevProps) => {
    if (
      JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data) &&
      (parseInt(this.props.data.company) === 10 ||
        parseInt(this.props.data.company) === 11)
    ) {
      this.getPerformance();
    }
  };

  getPerformance = () => {
    this.setState({ isLoad: true }, () => {
      axiosConfig("/company-performance/groupamaGan", {
        data: {
          dateStart: this.props.data.dateStart,
          dateEnd: this.props.data.dateEnd,
          network: this.props.data.network,
          group: this.props.data.group,
          region: this.props.data.region,
          agency: this.props.data.agency,
          user: this.props.data.user,
          company: this.props.data.company,
        },
      }).then((res) => {
        if (res.data.success) {
          this.setState({
            data: res.data.data,
            search: res.data.search,
            isLoad: false,
          });
        } else {
          this.setState({
            data: null,
            search: null,
            isLoad: false,
          });
        }
      });
    });
  };

  render() {
    const dataR = [
      {
        name: "Nb Rapports",
        id: "NbFoldersR",
      },
      {
        name: "Coût Moyen",
        id: "AverageCost",
      },
      {
        name: "Nb Ecrétés",
        id: "NbFolderWrittenR",
      },
      {
        name: "Coût Moyen Ecr",
        id: "AverageCostWritten",
      },
      {
        name: "Tx EAD réparable",
        id: "TxEAD",
      },
      {
        name: "Coût Moyen EAD",
        id: "AverageCostEAD",
      },
      {
        name: "Part dossiers agréés",
        id: "TxApproved",
      },
      {
        name: "Délai Moy Dossier",
        id: "DelayClosingR",
      },
      {
        name: "Délai Moy Expertise",
        id: "DelayExpertiseR",
      },
      {
        name: "Cout Annule et Remplace",
        id: "CostCancelReplaceR",
      },
      {
        name: "Nb Annule et Remplace",
        id: "CancelReplaceR",
      },
      {
        name: "Tx Annule et remplace",
        id: "TxCancelReplaceR",
      },
      {
        name: "Délai moyen Annule et remplace",
        id: "DelayCancelReplaceR",
      },
    ];

    const dataNR = [
      {
        name: "Nb Rapports",
        id: "NbFoldersNR",
      },
      {
        name: "Delai Moyen Expertise",
        id: "DelayExpertiseNR",
      },
      {
        name: "Delai Moyen SD29",
        id: "DelaySD29",
      },
      {
        name: "Delai NR",
        id: "DelayClosingNR",
      },
      {
        name: "VRADE NR",
        id: "VRADE",
      },
      {
        name: "Âge Moyen NR",
        id: "Age",
      },
      {
        name: "Nb NR Ecrétés",
        id: "NbFolderWrittenNR",
      },
      {
        name: "Vrade ecr NR",
        id: "VRADEWritten",
      },
      {
        name: "Nb Annule et Remplace",
        id: "CancelReplaceNR",
      },
      {
        name: "Tx Annule et remplace",
        id: "TxCancelReplaceNR",
      },
      {
        name: "Délai moyen Annule et remplace",
        id: "DelayCancelReplaceNR",
      },
      {
        name: "Délai NR hors annule et remplace",
        id: "DelayNotCancelReplaceNR",
      },
    ];

    return (
      <FadeIn>
        <Card
          title={"Réparable"}
          className={"mt-10 mt-30-xl "}
          classNameChildren={"overflow-x-auto"}
        >
          <ToggleTable>
            <FadeIn>
              <div style={{ fontSize: "10px" }} className="mb-20">
                <p>Genre: VP / CTTE</p>
                <p>
                  Inclure évènements : circulation hors stationnement, incendie,
                  stationnement, tentative de vol, vandalisme, vol, vol avant
                  delaissement
                </p>
                <p>Écrêtement: Total rapport HT entre 210 € et 3 210 €</p>
              </div>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Mesures</Th>
                    {this.state.search
                      ? this.state.search.map((element) => (
                          <Th>{element.year}</Th>
                        ))
                      : null}
                  </Tr>
                </Thead>
                <Tbody>
                  {this.state.data
                    ? dataR.map((element, idx) => (
                        <Tr key={idx}>
                          <Th>{element.name}</Th>
                          {this.state.data[element.id].map((data) => (
                            <Td>{data}</Td>
                          ))}
                        </Tr>
                      ))
                    : null}
                </Tbody>
              </Table>
            </FadeIn>
          </ToggleTable>
        </Card>

        <Card
          title={"Non réparable"}
          className={"mt-10 mt-30-xl "}
          classNameChildren={"overflow-x-auto"}
        >
          <ToggleTable>
            <FadeIn>
              <div style={{ fontSize: "10px" }} className="mb-20">
                <p>genre: VP / CTTE</p>
                <p>
                  inclure évènements : circulation hors stationnement, incendie,
                  stationnement, tentative de vol, vandalisme, vol, vol avant
                  delaissement
                </p>
                <p>Écrêtement: Total VRADE HT entre 604 € et 8 958 €</p>
              </div>
              <Table>
                <Thead>
                  <Tr>
                    <Th>Mesures</Th>
                    {this.state.search
                      ? this.state.search.map((element) => (
                          <Th>{element.year}</Th>
                        ))
                      : null}
                  </Tr>
                </Thead>
                <Tbody>
                  {this.state.data
                    ? dataNR.map((element, idx) => (
                        <Tr key={idx}>
                          <Th>{element.name}</Th>
                          {this.state.data[element.id].map((data) => (
                            <Td>{data}</Td>
                          ))}
                        </Tr>
                      ))
                    : null}
                </Tbody>
              </Table>
            </FadeIn>
          </ToggleTable>
        </Card>
        <Loader show={this.state.isLoad} />
      </FadeIn>
    );
  }
}
