import React, { Component } from 'react'
import {
  faUser,
  faBuilding,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
class Legend extends Component {
  constructor(props) {
    super(props)
    this.state = {
      agencies: null
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.data !== this.props.data) {
      let agencies = []
      this.props.data.map(element => {
        let agency = agencies.find(agency => agency.name === element.agencyName)
        if (!agency) {
          agencies.push({
            name: element.agencyName,
            color: element.agencyColor ? element.agencyColor : 0
          })
        }
        return null
      })
      agencies.sort(function (a, b) {
        return a.name - b.name;
      });
      this.setState({ agencies })
    }
  }

  render() {
    const style = {
      container: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        borderRadius: '10px',
        height: '600px',
        backgroundColor: 'rgb(236, 236, 236)',
      },
      element: {
        display: 'flex',
        margin: '10px',
        marginBottom: 0,
        justifyContent: 'flex-start'
      },
      icon: {
        marginRight: '10px'
      }
    }

    return (
      <div style={style.container}>
        <h3 style={{ textAlign: 'center' }}>Légende</h3>
        <div style={style.element}>
          <FontAwesomeIcon style={style.icon} icon={faUser} />
          <span>Client</span>
        </div>
        <div style={style.element}>
          <FontAwesomeIcon style={style.icon} icon={faBuilding} />
          <span>Agence</span>
        </div>
        <div style={style.element}>
          <FontAwesomeIcon style={style.icon} icon={faMapMarkerAlt} />
          <span>Garage</span>
        </div>
        {this.state && this.state.agencies && this.state.agencies.map((element, idx) => {
          return (
            <div key={idx} style={style.element}>
              <FontAwesomeIcon style={style.icon} color={element.color} icon={faMapMarkerAlt} />
              <span>
                {element.name}
              </span>
            </div>
          )
        })}
        {/* <MyMarker nb={10} color={'green'}/>
        <MyBuilding /> */}
      </div>
    )
  }
}

export default Legend
