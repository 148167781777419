import React, { Component } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  DoughnutChart,
  BarChart,
  Card,
  Status,
} from "ui-kit-ck-consultant";
import { Row, Col } from "react-flexbox-grid";

export default class CardPerformance extends Component {
  tabElementJSX(index, value) {
    if (index === 0) {
      return <Th key={index * 100}>{value}</Th>;
    } else return <Td key={index * 10000}>{value}</Td>;
  }

  color(value) {
    if (value >= 100) {
      return "bg-green-light black w-100";
    } else if (value >= 90) {
      return "bg-orange-light black w-100";
    } else {
      return "bg-red-light black w-100";
    }
  }

  formatNumber(value, id, length) {
    if (id + 1 === length) {
      return (
        <Status
          className={this.color(value)}
          text={
            Number.isInteger(value)
              ? new Intl.NumberFormat("fr-FR", { style: "percent" }).format(
                  value / 100
                )
              : value
          }
        />
      );
    } else {
      return Number.isInteger(value)
        ? new Intl.NumberFormat("fr-FR").format(value)
        : value;
    }
  }

  render() {
    return (
      <Card className={this.props.className}>
        <Row middle="xs">
          {this.props.data && (
            <Col
              md={12}
              xl={this.props.print === true ? 12 : 6}
              className={"my-10 overflow-x-auto"}
            >
              <Table>
                <Thead>
                  <Tr>
                    {this.props.data.title.map((element, idx) => (
                      <Th
                        hide={idx === 0 ? true : false}
                        radiusTopLeft={idx === 1 ? true : false}
                        key={idx}
                      >
                        {element}
                      </Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {this.props.data.data.map((element, idx) => (
                    <Tr key={idx}>
                      {element.map((value, index) =>
                        this.tabElementJSX(
                          index,
                          this.formatNumber(value, index, element.length)
                        )
                      )}
                    </Tr>
                  ))}
                </Tbody>
              </Table>

              {this.props.dataTwo && (
                <Table className="mt-20">
                  <Thead>
                    <Tr>
                      {this.props.dataTwo.title.map((element, idx) => (
                        <Th
                          hide={idx === 0 ? true : false}
                          radiusTopLeft={idx === 1 ? true : false}
                          key={idx}
                        >
                          {element}
                        </Th>
                      ))}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {this.props.dataTwo.data.map((element, idx) => (
                      <Tr key={idx}>
                        {element.map((value, index) =>
                          this.tabElementJSX(
                            index,
                            this.formatNumber(value, index, element.length)
                          )
                        )}
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              )}
            </Col>
          )}
          <Col md={12} xl={2} className="d-flex">
            <div className=" m-auto">
              <DoughnutChart
                max={100}
                number={this.props.dataDoughnut.number}
                text={this.props.dataDoughnut.text}
              />
            </div>
          </Col>
          <Col
            md={12}
            xl={this.props.print === true ? 10 : this.props.data ? 4 : 10}
          >
            <BarChart
              title={"Évolutions " + this.props.tab}
              digits={0}
              notAlign
              dataSet={this.props.dataTab}
              format="percentage"
              dataLabelsOptions={{
                display: true,
                align: "top",
                anchor: "start",
                clamp: true,
                color: "#000000",
                font: {
                  size: "9",
                },
                formatter: (value) => Math.round(value) + "%",
              }}
              yAxes={[
                {
                  display: false,
                  dataLabelsOptions: { display: true },
                  ticks: {
                    max: 120,
                    min: 0,
                    beginAtZero: true,
                  },
                },
              ]}
            />
          </Col>
        </Row>
        {!this.props.type === 2 && this.props.tab === "Performance coût" ? (
          <>
            {this.props.isPrecisia ? null : (
              <div style={{ fontSize: "12px" }}>
                <p>
                  Performance Coût VRADE (€ HT): Coût VRADE - (5 * Différence
                  moyenne âge véhicule)
                </p>
              </div>
            )}
          </>
        ) : this.props.tab === "Performance coût" ? (
          <>
            {this.props.isPrecisia ? null : (
              <div style={{ fontSize: "12px" }}></div>
            )}
          </>
        ) : null}
      </Card>
    );
  }
}
