import React, { Component } from "react";
import { FloatActionButton, FloatActionButtonItem } from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import AuthContext from "../../context/AuthContext";
import axiosConfig from "../../utils/axiosConfig";
import html2canvasInstance from "../../utils/html2canvasInstance";
import moment from "moment";

class PrintClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPrint: false,
      logo: null,
      refs: null,
    };
    this.dataPrint = {
      count: 0,
    };
    this.refHeader = React.createRef();
  }
  static contextType = AuthContext;

  getGroupIcon = () => {
    if (this.context.group) {
      let logo = localStorage.getItem("logoGroup");
      let group = localStorage.getItem("idGroup");
      if (!logo || parseInt(group) !== parseInt(this.context.group)) {
        axiosConfig("/auth/get-logo").then((res) => {
          if (res.data.success === true) {
            localStorage.setItem("logoGroup", res.data.data);
            localStorage.setItem("idGroup", this.context.group);
            this.setState({ logo: res.data.data });
          }
        });
      } else if (logo) {
        this.setState({ logo });
      }
    }
  };

  handlePrintClick = () => {
    this.setState({ isPrint: true }, () => {
      this.props.onPrint(true);
    });
  };

  handleStartPrint = (close = false) => {
    this.dataPrint.count = 0;
    const max = this.state.refs && this.state.refs.length;
    this.state.refs &&
      this.state.refs.map((ref, idx) => {
        if (ref) {
          ref.current.style = this.getWidthPrint();
        }
        return setTimeout(() => {
          html2canvasInstance(ref.current, (result) => {
            if (result.success === true) {
              this.dataPrint[idx] = result.base64;
              this.checkPrint(max, close);
            }
          });
        }, 2000);
      });
  };

  checkPrint(max, close) {
    this.dataPrint.count++;
    if (this.dataPrint.count === max) {
      const data = {
        dataPrint: this.dataPrint,
        orientation: this.props.orientation
          ? this.props.orientation
          : "portrait",
        width: this.props.width ? this.props.width : "1500px",
      };
      if (close) {
      } else {
        axiosConfig("general/print", {
          data,
        }).then((res, err) => {
          this.state.refs.map((ref) => {
            if (ref.current) {
              return (ref.current.style.width = "auto");
            }
            return null;
          });
          this.setState({ isPrint: false }, () => {
            this.props.onPrint(false);
            if (res.data && res.data.success === true) {
              const link = document.createElement("a");
              link.href = res.data.file;
              link.download = `${this.props.fileName}_${moment().format(
                "YYYY-MM-DD"
              )}.pdf`;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          });
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.refs &&
      prevProps.refs !== this.props.refs &&
      this.state.isPrint &&
      this.refHeader &&
      this.props.refs.length > 0
    ) {
      let isNull = false;
      this.props.refs.map((ref) => {
        if (!ref.current) {
          isNull = true;
        }
        return true;
      });
      if (isNull === false) {
        this.setState({ refs: [this.refHeader, ...this.props.refs] }, () => {
          if (!this.state.refs[0].current && this.refHeader) {
            let refs = this.state.refs;
            refs[0] = this.refHeader;
            this.setState({ refs }, () => this.handleStartPrint());
          } else {
            this.handleStartPrint();
          }
        });
      }
    }
  }

  componentDidMount() {
    this.getGroupIcon();
  }

  getWidthPrint() {
    return `width: ${this.props.width}px;`;
  }

  render() {
    return (
      <div>
        {this.state.isPrint ? (
          <div ref={this.refHeader}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <img
                alt={"logo"}
                style={{ maxHeight: "100px", maxWidth: "100%" }}
                src={this.state.logo}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <h3 style={{ fontSize: 28 }}>
                  {this.props.title.toUpperCase()}
                </h3>
                <p>{moment(new Date()).format("DD/MM/YYYY")}</p>
              </div>
            </div>
          </div>
        ) : null}
        {this.props.buttonItem ? (
          <FloatActionButtonItem
            text={"Télécharger"}
            icon={<FontAwesomeIcon icon={faPrint} />}
            onClick={() => this.handlePrintClick()}
          />
        ) : (
          <FloatActionButton
            icon={<FontAwesomeIcon icon={faPrint} />}
            onClick={() => this.handlePrintClick()}
          />
        )}
      </div>
    );
  }
}

export default PrintClass;
