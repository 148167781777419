import React, { Component } from "react";
import FadeIn from "react-fade-in";
import {
  ButtonIcon,
  Pagination,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "ui-kit-ck-consultant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faChevronLeft,
  faChevronRight,
  faSearchPlus,
} from "@fortawesome/free-solid-svg-icons";
import ModalAudit from "../audit/technical/Modal";

export class AuditList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      tabLength: 10,
      total: 0,
      dataList: [],
      order: null,
      dataItem: null,
      modalIsOpen: false,
    };
  }

  componentDidMount() {
    this.setState({
      total: this.props.dataTab.length,
      dataList: this.props.dataTab.slice(0, this.state.tabLength),
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dataTab.length !== this.props.dataTab.length) {
      this.setState({
        total: this.props.dataTab.length,
        dataList: this.props.dataTab.slice(0, this.state.tabLength),
      });
    }
  }

  onPageChange = (newPage) => {
    let startAt = (newPage - 1) * this.state.tabLength;
    let endAt = (newPage - 1) * this.state.tabLength + this.state.tabLength;
    if (endAt > this.state.total) endAt = this.state.total;
    this.setState({
      activePage: newPage,
      dataList: this.props.dataTab.slice(startAt, endAt),
    });
  };

  getClassLabel = (value) => {
    if (value >= 90) {
      return "bg-green-light";
    } else if (value >= 80) {
      return "bg-orange-light";
    } else {
      return "bg-red-light";
    }
  };

  render() {
    return (
      <FadeIn>
        <ModalAudit
          show={this.state.modalIsOpen}
          hide={() =>
            this.setState((prevState) => ({
              modalIsOpen: !prevState.modalIsOpen,
              dataItem: null,
            }))
          }
          item={this.state.dataItem}
        />
        <Table
          empty={
            this.props.dataTab.length > 0
              ? ""
              : "Aucune donnée n'est disponible"
          }
        >
          <Thead>
            {Array.isArray(this.state.dataList[0]) ? (
              <Tr>
                <Th>Date</Th>
                <Th>N°dossier</Th>
                <Th>Type d'expertise</Th>
                <Th>Commentaire</Th>
                <Th>% d'atteinte</Th>
                <Th></Th>
              </Tr>
            ) : (
              <Tr>
                <Th>Date</Th>
                <Th>Utilisateur</Th>
                <Th>Agence</Th>
                <Th>N°dossier</Th>
                <Th>Type d'expertise</Th>
                <Th>Commentaire</Th>
                <Th>% d'atteinte</Th>
                <Th></Th>
              </Tr>
            )}
          </Thead>
          <Tbody>
            {Array.isArray(this.state.dataList[0])
              ? this.state.dataList.map((element, idx) => (
                  <Tr key={idx}>
                    {element.map((value, id) =>
                      id !== 5 ? (
                        <Td
                          key={id}
                          className={" " + this.getClassLabel(element[4])}
                        >
                          {value}
                        </Td>
                      ) : null
                    )}
                    {element[5] ? (
                      <Td className={" " + this.getClassLabel(element.value)}>
                        <ButtonIcon
                          className={"black"}
                          onClick={(event) => {
                            this.setState((prevState) => ({
                              modalIsOpen: !prevState.modalIsOpen,
                              dataItem: element,
                            }));
                          }}
                          small
                        >
                          <FontAwesomeIcon icon={faSearchPlus} />
                        </ButtonIcon>
                      </Td>
                    ) : null}
                  </Tr>
                ))
              : this.state.dataList.map((element, idx) => (
                  <Tr key={idx}>
                    <Td className={" " + this.getClassLabel(element.value)}>
                      {element.date}
                    </Td>
                    <Td className={" " + this.getClassLabel(element.value)}>
                      {element.user}
                    </Td>
                    <Td className={" " + this.getClassLabel(element.value)}>
                      {element.agency}
                    </Td>
                    <Td className={" " + this.getClassLabel(element.value)}>
                      {element.folder}
                    </Td>
                    <Td className={" " + this.getClassLabel(element.value)}>
                      {element.type}
                    </Td>
                    <Td className={" " + this.getClassLabel(element.value)}>
                      {element.comment}
                    </Td>
                    <Td className={" " + this.getClassLabel(element.value)}>
                      {element.value}
                    </Td>
                    {element.data ? (
                      <Td className={" " + this.getClassLabel(element.value)}>
                        <ButtonIcon
                          className={"black"}
                          onClick={(event) => {
                            this.setState((prevState) => ({
                              modalIsOpen: !prevState.modalIsOpen,
                              dataItem: element,
                            }));
                          }}
                          small
                        >
                          <FontAwesomeIcon icon={faSearchPlus} />
                        </ButtonIcon>
                      </Td>
                    ) : null}
                  </Tr>
                ))}
          </Tbody>
        </Table>
        {this.props.isPrint ? null : (
          <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={this.state.tabLength}
            totalItemsCount={this.state.total}
            pageRangeDisplayed={4}
            onChange={this.onPageChange}
            firstPageText={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
            lastPageText={<FontAwesomeIcon icon={faAngleDoubleRight} />}
            prevPageText={<FontAwesomeIcon icon={faChevronLeft} />}
            nextPageText={<FontAwesomeIcon icon={faChevronRight} />}
          />
        )}
      </FadeIn>
    );
  }
}

export default AuditList;
