import React, { Component } from "react";
import { Col, Row } from "react-flexbox-grid";

import CardSatisfactionGroup from "./CardSatisfactionGroup";

export class GroupTab extends Component {
  render() {
    return (
      <Row>
        <Col xs={12} md={12}>
          <CardSatisfactionGroup
            isLoad={this.props.isLoadSatisfaction}
            data={this.props.dataSatisfaction}
          />
        </Col>
      </Row>
    );
  }
}
