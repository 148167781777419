import React, { Component } from "react";
import { BarChart } from "ui-kit-ck-consultant";

export class LineChartRPRate extends Component {

  render() {
    return (
      <BarChart
        height={this.props.height}
        title={"Taux de RP"}
        digits={0}
        format="percentage"
        legendDisplay={false}
        dataSet={[
          {
            data: this.props.data.RP_RATE,
            type: "line",
            color: "#6067AE",
          },
        ]}
        yAxes={[
          {
            display: false,
            dataLabelsOptions: { display: true },
            ticks: {
              max: 100,
              min: 0,
              beginAtZero: true,
            },
          },
        ]}
        datalabels2={[
          {
            display: true,
            align: "bottom",
            anchor: "end",
            clamp: true,
            color: "#0162AD",
            font: {
              size: "8",
            },
            formatter: (value) => Math.round(value) + "%",
          },
        ]}
      />
    );
  }
}

export default LineChartRPRate;
