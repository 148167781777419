import React from "react";
import {
  Card,
  BarChart,
  FormCheckbox,
} from "ui-kit-ck-consultant";

import { getColors } from "../../../utils/colors";

export default class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPercentage: true,
    };
  }

  render() {
    const colors = getColors();

    return (
      <>
        <Card className="mb-10 mb-20-xl min-height-0">
          <FormCheckbox
            text={"Affichage pourcentage"}
            checked={this.state.isPercentage}
            onChange={({ target }) =>
              this.setState({ isPercentage: target.checked })
            }
          />
        </Card>
        {this.props.data.hasOwnProperty("filters")
          ? this.props.data.filters.map((filter, idx) => (
              <Card className="mb-10 mb-20-xl" key={idx}>
                <BarChart
                  format={this.state.isPercentage ? "percentage" : ""}
                  digits={this.state.isPercentage ? 2 : 0}
                  title={filter.name}
                  labels={[...this.props.data.labels].slice(0, 90).reverse()}
                  dataSet={Object.keys(this.props.data.regions)
                    .reverse()
                    .map((key, idxRegion) => ({
                      type: key === "Toutes les régions" ? "bar" : "line",
                      yAxisID: key === "Toutes les régions" ? "A" : "B",
                      label: key,
                      data: this.props.data.regions[key][
                        this.state.isPercentage ? `p${idx}` : `f${idx}`
                      ]
                        .slice(0, 90)
                        .reverse(),
                      color:
                        key === "Toutes les régions"
                          ? "rgba(120, 12, 70, 0.4)"
                          : colors[idxRegion],
                    }))}
                  height={300}
                  xAxes={[
                    {
                      gridLines: {
                        display: false,
                      },
                    },
                  ]}
                  yAxes={[
                    {
                      id: "A",
                      ticks: {
                        display: false,
                      },
                    },
                    {
                      id: "B",
                      ticks: {
                        display: false,
                      },
                    },
                  ]}
                />
              </Card>
            ))
          : null}
      </>
    );
  }
}
